import { Box, Text } from "grommet";
import styled from "styled-components";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";

interface MaintenanceProps {
  isLogin?: boolean;
  message?: string | null;
}

const MaintenanceMessage = ({
  isLogin = true,
  message = null
}: MaintenanceProps) => {
  return message ? (
    <MaintenanceWrapper login={isLogin}>
      <MaintenanceMessageBox>
        <NotificationsActiveIcon
          style={{
            width: 17,
            height: 17,
            color: "var(--carbon-orange)",
            marginRight: "12px"
          }}
        />{" "}
        <MaintenanceText
          color={"var(--carbon-orange)"}
          style={{ textAlign: "center" }}
        >
          {`${message}`}
        </MaintenanceText>
      </MaintenanceMessageBox>
    </MaintenanceWrapper>
  ) : null;
};

const MaintenanceWrapper = styled(Box)<{ login: boolean }>`
  display: flex;
  margin: ${(props) => (props.login ? "24px 0 0" : "0")};
  padding: ${(props) => (props.login ? "0 24px" : "0")};
  text-align: center;
  align-items: center;
  width: 100%;
`;

const MaintenanceMessageBox = styled(Box)`
  background: rgba(239, 94, 37, 0.01);
  border: 1px solid rgba(239, 94, 37, 0.5);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  max-width: 1009px;
  width: 100%;
`;

const MaintenanceText = styled(Text)`
  font-size: 12px;
  line-height: 1.1;
  @media (min-width: 768px) {
    font-size: 14px;
  }
`;

export default MaintenanceMessage;
