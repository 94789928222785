import { Result, ResultError, NodeItemsExportContract } from "../../types";
import useApi from "../api/useApi";
import { Placeholders, StoreActions } from "../../constants";
import {
  CarbonGenericApiGet,
  CarbonGenericApiPost
} from "../../api/carbonGenericApis";
import {
  nodeExportLookupItemsEndpoint,
  nodeGroupsExportLookupEndpoint
} from "../../constants/endpoints/nodeExports";
import { useStore } from "../../contexts/store";

const useNodeItemsExportApi = () => {
  const { dispatch } = useStore();
  const setNotification = (message: string, type: string) => {
    dispatch({
      type: StoreActions.addNotification,
      payload: {
        message: message,
        messageType: type,
        closable: true
      }
    });
  };

  const { loading: isLoadingNodeListData, request: getNodeList } =
    useApi(CarbonGenericApiGet);

  const { loading: isLoadingNodeGroupData, request: getNodeGroupList } =
    useApi(CarbonGenericApiGet);

  const { loading: isLoadingNodeItemExport, request: exportNodeItems } =
    useApi(CarbonGenericApiPost);

  const getNodeItemsExportData = async (
    companyId: string,
    nodeId?: string
  ): Promise<Result<string>> => {
    let url: string;
    if (nodeId) {
      url = `${nodeExportLookupItemsEndpoint.replace(
        Placeholders.companyID,
        `${companyId}`
      )}/${nodeId}`;
    } else {
      url = `${nodeGroupsExportLookupEndpoint.replace(
        Placeholders.companyID,
        `${companyId}`
      )}`;
    }

    let result: Result<string>;
    try {
      console.log("NodeItemsExport Lookup API: " + url);
      result = await getNodeList(url);
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }

    return result;
  };

  const getNodeGroupData = async (
    companyId: string,
    groupId: number,
    nodeId: string
  ): Promise<Result<string>> => {
    const url = `/odata/${companyId}/${groupId}/gridnodes?filterid=-1&$count=true&$orderby=NodeName&$filter=NodeID ne ${nodeId}`;

    let result: Result<string>;
    try {
      result = await getNodeGroupList(url);
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }

    return result;
  };

  const processNodeItemsExport = async (
    nodeId: string,
    companyId: string,
    payload: NodeItemsExportContract
  ): Promise<Result<string>> => {
    const url = `api/${companyId}/nodeitems/nodeitemsexport/${nodeId}`;

    let result: Result<string>;
    try {
      console.log("NodeItemsExport API: " + url);
      result = await exportNodeItems(url, payload);
      if (result.type === "success") {
        const resultValue: any = result.value;
        const message: string = resultValue.data;
        if (!message.includes("ERROR")) {
          setNotification(message, "success");
        }
      } else {
        console.log("Bad Request:", result.error);
      }
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }

    return result;
  };

  return {
    getNodeItemsExportData,
    isLoadingNodeListData,
    getNodeGroupData,
    isLoadingNodeGroupData,
    processNodeItemsExport,
    isLoadingNodeItemExport
  };
};

export default useNodeItemsExportApi;
