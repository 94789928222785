import { Helmet } from "react-helmet-async";
import { Box } from "grommet";
import { useLocation, useParams } from "react-router-dom";

// Components
import CarbonGrid from "../../components/CarbonGrid/CarbonGrid";
import CarbonTabStrip from "../../components/CarbonTabs/CarbonTabStrip";
import CarbonGridToolbar, {
  GridToolbarActionLabels
} from "../../components/CarbonGrid/Toolbar/CarbonGridToolbar";
import Authorizer from "../../components/Util/Authorizer";

// Types & Constants
import { GridType } from "../../types/grid";
import { gridColumns } from "./PatchCompaniesGridColumns";
import getTabs from "./PatchesTabs";

// User/Permissions
import usePermissions from "../../hooks/auth/usePermissions";
import {
  Placeholders,
  UserPermissions,
  GridIDs,
  EndpointResources,
  CarbonIcons
} from "../../constants";
import CarbonBreadcrumb from "../../components/Breadcrumb/CarbonBreadcrumb";

const PatchCompanies = (): JSX.Element => {
  // Using pathname for grid id for simple screens with a single grid
  const { pathname } = useLocation();
  const { patchId } = useParams();
  const { canUpdate } = usePermissions();
  const gridName = GridIDs.PatchCompanies;
  const pagePermission = UserPermissions.PatchesPerms;
  const tabs = getTabs(EndpointResources.Patches, patchId!);

  const actionLabels: GridToolbarActionLabels = {
    singular: "company",
    plural: "companies",
    titleSingular: "Patch Company",
    titlePlural: "Patch Companies"
  };

  const currentTab = {
    index: tabs.findIndex((tab) => tab.resourceEndpoint === pathname),
    tab: tabs.find((tab) => tab.resourceEndpoint === pathname)
  };

  const gridSettings: GridType = {
    endpoints: {
      gridODataEndpoint: `/odata/${Placeholders.companyID}/${Placeholders.patchTabPatchId}/gridpatchcompanies?$count=true&`,
      gridApiEndpoint: `/api/${Placeholders.patchTabPatchId}/patchcompanies`,
      parentGridApiEndpoint: `/api/${Placeholders.companyID}/${EndpointResources.Patches}/${Placeholders.patchTabPatchId}`,
      lookupEndpoints: [
        {
          lookupField: "CompanyName",
          endpoint: `/api/${Placeholders.companyID}/lookupcompanies`
        }
      ]
    },
    records: [],
    total: 0,
    data: [],
    lookups: {},
    dataState: {
      pageSize: 25,
      take: 50,
      skip: 0,
      sort: [{ field: "CompanyName", dir: "asc" }]
    },
    dataItemKey: "PatchCompanyID",
    singularEntityName: actionLabels.singular,
    pluralEntityName: actionLabels.plural,
    columns: gridColumns,
    showCopyBtn: false,
    showDeleteBtn: canUpdate(pagePermission),
    parentGridId: GridIDs.Patches,
    state: {
      editMode: false,
      insertMode: false,
      selectedState: {},
      selectedRow: -1,
      showQuickFilter: false,
      lastSaveDate: null,
      showSavingIndicator: false,
      lockoutMode: false
    }
  };

  return (
    <>
      <Helmet>
        <title>Carbon | Patch Companies</title>
      </Helmet>
      <Authorizer
        canView={pagePermission}
        auditLabel={`${actionLabels.titlePlural} screen`}
      >
        <Box align="start" fill>
          <CarbonBreadcrumb
            backLinkTitle="Patches"
            backLinkUrl="/app/patches"
            pageTitle={currentTab.tab?.tabTitle ?? ""}
          />
          <CarbonGridToolbar
            gridId={gridName}
            screenIcon={CarbonIcons.Companies}
            getScreenTitleFromParentRow={(rowData: { [key: string]: any }) => {
              return rowData.PatchVersion;
            }}
            permissions={pagePermission}
            actionLabels={actionLabels}
          />
          <CarbonTabStrip carbonTabs={tabs} selectedTab={currentTab.index} />
          <Box fill className="carbon-grid-wrapper">
            <CarbonGrid gridId={gridName} gridSettings={gridSettings} />
          </Box>
        </Box>
      </Authorizer>
    </>
  );
};

export default PatchCompanies;
