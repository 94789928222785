import React, { createContext, useContext, useReducer } from "react";
import { PreviewerActions } from "../../constants";

export interface IPreviewerProps {
  previewType: string;
  companyId: number;
  id: number;
  weekDay?: string;
  date?: Date;
  offset?: number;
  dayPartId?: number;
  refresh?: boolean;
}

type State = {
  previewerProps: IPreviewerProps | undefined;
};

type Action = {
  type: PreviewerActions;
  payload: IPreviewerProps | undefined;
};

type PreviewerContext = {
  previewer: State;
  dispatch: React.Dispatch<Action>;
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case PreviewerActions.setPreviewerProps:
      return {
        ...state,
        previewerProps: action.payload
      };
    default:
      throw new Error(`Unhandled action type: ${(action as any).toString()}`);
  }
}

const initialState: State = {
  previewerProps: undefined
};

const Previewer = createContext<PreviewerContext>({
  previewer: initialState,
  dispatch: () => null
});

// eslint-disable-next-line react/prop-types
const PreviewProvider: React.FC = ({ children }): JSX.Element => {
  const [previewer, dispatch] = useReducer(reducer, initialState);

  return (
    <Previewer.Provider value={{ previewer, dispatch }}>
      {children}
    </Previewer.Provider>
  );
};

export default PreviewProvider;
export const usePreviewer = () => useContext(Previewer);
