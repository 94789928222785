import { Result, ResultError, StartupDetails } from "../../types";
import useApi from "../../hooks/api/useApi";
import { ResultTypes, StoreActions } from "../../constants";
import { useStore } from "../../contexts/store";
import GetStartupDetails from "../../api/WebAppStartup/getStartupDetailsApi";
import { useState } from "react";

/*
 * The useWebAppStartup hook handles startup data fetching and uses the store
 * to make the data available globally.
 */
const useWebAppStartup = () => {
  const { dispatch } = useStore();
  const [startupResult, setStartupResult] = useState<Result<String>>(
    {} as Result<String>
  );

  const setErrorNotification = (message: string) => {
    dispatch({
      type: StoreActions.addNotification,
      payload: {
        message: message,
        messageType: "error",
        closable: true
      }
    });
  };

  const { loading: startupDetailsLoading, request: startupDetailsRequest } =
    useApi(GetStartupDetails);

  const getStartupDetails = async (): Promise<Result<string>> => {
    let result: Result<string>;
    try {
      const startupDetailsResult = (result = await startupDetailsRequest());
      if (startupDetailsResult.type === ResultTypes.Success) {
        const startupDetailsResponse = startupDetailsResult.value;
        const startupDetailsObj = startupDetailsResponse?.data;

        const startupDetails: StartupDetails = {
          maintenanceMessage: startupDetailsObj.MaintenanceMessage,
          preventLogin: startupDetailsObj.PreventLogin
        };

        // Reducer will set details in local store (and in local storage)
        await dispatch({
          type: StoreActions.setStartupDetails,
          payload: startupDetails
        });
      }
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      setStartupResult(result);
      return result;
    }

    // User notification for if API fails due to network error
    const startupErrorMsg: any = result;
    if (startupErrorMsg.error) {
      if (startupErrorMsg.error.message) {
        setErrorNotification(
          `${startupErrorMsg.error.message}: ${startupErrorMsg.error.config.baseURL}${startupErrorMsg.error.config.url}`
        );
      } else {
        setErrorNotification(
          `Unknown Error: ${startupErrorMsg.error.config.baseURL}${startupErrorMsg.error.config.url}`
        );
      }
    }

    setStartupResult(result);
    return result;
  };

  const isLoading = (): boolean => {
    return startupDetailsLoading;
  };

  return {
    getStartupDetails,
    isLoading,
    startupResult
  };
};

export default useWebAppStartup;
