import { Image as GrommetImage } from "grommet";

export const ActionMenuExtras = (
  <span className="material-icons-outlined">more_horiz</span>
);
export const Add = <span className="material-icons">add</span>;
export const AddZoneSingle = (
  <span className="material-icons-outlined">add_box</span>
);
export const AddZoneMultiple = (
  <span className="material-icons-outlined">queue</span>
);
export const AdminMenu = (
  <span className="material-icons-outlined">settings</span>
);
export const AuditTrail = (
  <span className="material-icons">admin_panel_settings</span>
);
export const Campaigns = (
  <span className="material-icons-outlined">campaign</span>
);
export const CampaignsMenu = (
  <span className="material-icons-outlined">campaign</span>
);
export const ChangePassword = (
  <span className="material-icons-outlined">vpn_key</span>
);
export const Check = <span className="material-icons">check</span>;
export const ChevronRight = (
  <span
    className="material-icons-outlined"
    style={{ fontSize: "18px", marginTop: "4px" }}
  >
    chevron_right
  </span>
);
export const Close = <span className="material-icons-outlined">close</span>;
export const Companies = <span className="material-icons">business</span>;
export const CompanyCode = (
  <span className="material-icons-outlined">store</span>
);
export const ContentMenu = (
  <span className="material-icons-outlined">perm_media</span>
);
export const Copy = (
  <span className="material-icons-outlined">content_copy</span>
);
export const Dayparts = (
  <span className="material-icons-outlined">history_toggle_off</span>
);
export const Delete = <span className="material-icons-outlined">delete</span>;
export const Displays = <span className="material-icons-outlined">tv</span>;
export const Done = <span className="material-icons-outlined">done</span>;
export const Download = (
  <span className="material-icons-outlined">file_download</span>
);
export const Edit = <span className="material-icons">edit</span>;
export const ExpandLess = (
  <span className="material-icons-outlined">keyboard_arrow_up</span>
);
export const ExpandMore = (
  <span className="material-icons-outlined">expand_more</span>
);
export const Filter = (
  <span className="material-icons-outlined">filter_alt</span>
);
export const Find = <span className="material-icons-outlined">search</span>;
export const Fonts = (
  <span className="material-icons-outlined">text_fields</span>
);
export const GlobalChange = (
  <span className="material-icons-outlined">language</span>
);
export const History = <span className="material-icons-outlined">history</span>;
export const HelpHome = <span className="material-icons-outlined">school</span>;
export const Image = <span className="material-icons-outlined">image</span>;
export const ImageNotSupported = (
  <span className="material-icons-outlined" title="Thumbnail not available">
    image_not_supported
  </span>
);
export const Items = <span className="material-icons-outlined">sell</span>;
export const InProgress = (
  <GrommetImage
    src="/static/images/line-spinner.svg"
    style={{ height: "24px" }}
  />
);
export const LayoutsMenu = (
  <span className="material-icons-outlined">dashboard</span>
);
export const Lock = <span className="material-icons-outlined">lock</span>;
export const Mail = (
  <span className="material-icons-outlined">mail_outline</span>
);
export const Media = (
  <span className="material-icons-outlined">perm_media</span>
);
export const MediaConverted = (
  <span
    className="material-icons"
    title="Media is ready"
    style={{ color: "var(--carbon-green)" }}
  >
    check
  </span>
);
export const MediaConverting = (
  <span
    className="material-icons"
    title="Media is being converted..."
    style={{ color: "var(--carbon-yellow)" }}
  >
    change_circle
  </span>
);
export const MovieCodes = (
  <span className="material-icons-outlined">movie</span>
);
export const Nodes = <span className="material-icons-round">device_hub</span>;
export const NodesMenu = (
  <span className="material-icons-round">device_hub</span>
);
export const OpenFull = (
  <span className="material-icons-outlined">open_in_new</span>
);
export const Packages = (
  <span className="material-icons-outlined">inventory_2</span>
);
export const Patches = <span className="material-icons-outlined">healing</span>;
export const Permissions = (
  <span className="material-icons-outlined">gpp_good</span>
);
export const Preview = <span className="material-icons">preview</span>;
export const PriceSchedules = (
  <span className="material-icons-outlined">price_change</span>
);
export const Publish = (
  <span className="material-icons">published_with_changes</span>
);
export const Refresh = <span className="material-icons">refresh</span>;
export const Resend = <span className="material-icons-outlined">replay</span>;
export const Restore = <span className="material-icons">undo</span>;
export const Roles = (
  <span className="material-icons-outlined">manage_accounts</span>
);
export const Schedule = (
  <span className="material-icons-outlined">date_range</span>
);
export const Settings = (
  <span className="material-icons-outlined">settings</span>
);
export const SortAsc = (
  <span className="material-icons-outlined flip-vertical">sort</span>
);
export const SortDesc = <span className="material-icons-outlined">sort</span>;
export const Templates = (
  <span className="material-icons-outlined">dashboard</span>
);
export const TermsOfUse = (
  <span className="material-icons-outlined">gavel</span>
);
export const TextStyles = (
  <span className="material-icons-outlined">text_format</span>
);
export const ToolsMenu = (
  <span className="material-icons-outlined">handyman</span>
);
export const Undo = <span className="material-icons-outlined">replay</span>;
export const User = <span className="material-icons-outlined">person</span>;
export const Users = <span className="material-icons-outlined">group</span>;
export const UserDefinedTypes = (
  <span className="material-icons-outlined">edit_note</span>
);
export const Video = <span className="material-icons-outlined">videocam</span>;
export const VideoCards = (
  <span className="material-icons-outlined">video_label</span>
);

export const Visibility = (
  <span
    className="material-icons"
    style={{ color: "darkgrey", alignItems: "end", flexDirection: "row" }}
  >
    visibility
  </span>
);

export const VisibilityOff = (
  <span
    className="material-icons"
    style={{ color: "darkgrey", alignItems: "end", flexDirection: "row" }}
  >
    visibility_off
  </span>
);

export const Warning = <i className="material-icons">report_problem</i>;
export const WarningDialog = (
  <span className="material-icons-outlined">warning_amber</span>
);

export const Stencils = (
  <span className="material-icons-outlined">grid_3x3</span>
);

export const StencilZones = (
  <span className="material-icons-outlined">highlight_alt</span>
);

export const TemplateZones = (
  <span className="material-icons-outlined">highlight_alt</span>
);

export const CopyRight = <span className="material-icons">input</span>;

export const CopyDown = (
  <span className="material-icons">system_update_alt</span>
);

export const Popout = <span className="material-icons-outlined">launch</span>;

export const Expand = (
  <span className="material-icons-outlined">fullscreen</span>
);

export const Play = <span className="material-icons-outlined">play_arrow</span>;

export const Pause = <span className="material-icons-outlined">pause</span>;

export const PrivacyPolicy = (
  <span className="material-icons-outlined">policy</span>
);

export const PopIn = (
  <span className="material-icons-outlined">close_fullscreen</span>
);

export const Reset = <span className="material-icons-outlined">replay</span>;

// code below does not apply size to the icon
// export const DayGroupsHelp = (
//   <span
//     style={{ color: "var(--carbon-blue)", width: 15, height: 15 }}
//     className="material-icons-outlined"
//   >
//     help_outline
//   </span>
// );
