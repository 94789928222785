import { Result, ResultError, NodeProcessUpdatesContract } from "../../types";
import useApi from "../api/useApi";
import {
  CarbonGenericApiGet,
  CarbonGenericApiPost
} from "../../api/carbonGenericApis";
import { nodePublishEndpoints as EP } from "../../constants/endpoints";
import { Placeholders } from "../../constants";
import { useStore } from "../../contexts/store";

const useNodeProcessUpdatesApi = () => {
  const { store } = useStore();

  // Lookup API
  const { loading: isLoadingList, request: getNodeProcessUpdatesList } =
    useApi(CarbonGenericApiGet);

  const getNodeProcessUpdates = async (
    nodeId: number
  ): Promise<Result<string>> => {
    const url = `${EP.nodePublishLookupEndpoint}/${nodeId}`;

    let result: Result<string>;
    try {
      result = await getNodeProcessUpdatesList(url);
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }

    return result;
  };

  // Process Now API
  const { loading: isLoadingProcessNow, request: postNodeProcessNow } =
    useApi(CarbonGenericApiPost);

  const postProcessNow = async (
    nodeId: number,
    companyId: number,
    payload: NodeProcessUpdatesContract
  ): Promise<Result<string>> => {
    const url = `${EP.nodePublishProcessNowEndpoint.replace(
      Placeholders.companyID,
      `${companyId}`
    )}/${nodeId}`;

    let result: Result<string>;
    try {
      result = await postNodeProcessNow(url, payload);
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }

    return result;
  };

  // Process Later API
  const { loading: isLoadingProcessLater, request: postNodeProcessLater } =
    useApi(CarbonGenericApiPost);

  const postProcessLater = async (
    nodeId: number,
    companyId: number,
    payload: NodeProcessUpdatesContract
  ): Promise<Result<string>> => {
    const url = `${EP.nodePublishProcessLaterEndpoint.replace(
      Placeholders.companyID,
      `${companyId}`
    )}/${nodeId}`;

    let result: Result<string>;
    try {
      result = await postNodeProcessLater(url, payload);
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }

    return result;
  };

  // Cancel Update API
  const { loading: isLoadingProcessCancel, request: postNodeProcessCancel } =
    useApi(CarbonGenericApiPost);

  const postCancelUpdates = async (
    nodeId: number,
    companyId: number,
    payload: NodeProcessUpdatesContract
  ): Promise<Result<string>> => {
    const url = `${EP.nodePublishCancelUpdatesEndpoint.replace(
      Placeholders.companyID,
      `${companyId}`
    )}/${nodeId}`;

    let result: Result<string>;
    try {
      result = await postNodeProcessCancel(url, payload);
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }

    return result;
  };

  // Resend Update API
  const { loading: isLoadingProcessResend, request: postNodeProcessResend } =
    useApi(CarbonGenericApiPost);

  const postResendUpdates = async (
    companyId: number,
    payload: NodeProcessUpdatesContract
  ): Promise<Result<string>> => {
    const url = `${EP.nodePublishResendUpdatesEndpoint.replace(
      Placeholders.companyID,
      `${companyId}`
    )}`;

    let result: Result<string>;
    try {
      result = await postNodeProcessResend(url, payload);
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }

    return result;
  };

  // Node Group API
  const { loading: isLoadingNodeGroups, request: getNodeGroupList } =
    useApi(CarbonGenericApiGet);

  const getNodeGroup = async (groupId: number): Promise<Result<string>> => {
    const url = `/odata/${
      store.activeCompany!.companyId
    }/${groupId}/gridnodes?filterid=-1`;

    let result: Result<string>;
    try {
      result = await getNodeGroupList(url);
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }

    return result;
  };

  const isLoading = () => {
    return (
      isLoadingList ||
      isLoadingProcessNow ||
      isLoadingProcessLater ||
      isLoadingProcessCancel ||
      isLoadingProcessResend ||
      isLoadingNodeGroups
    );
  };

  return {
    getNodeProcessUpdates,
    postProcessNow,
    postProcessLater,
    postCancelUpdates,
    postResendUpdates,
    getNodeGroup,
    isLoading
  };
};

export default useNodeProcessUpdatesApi;
