import { UserPermissions } from "../../constants";
import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  {
    field: "ItemName",
    title: "Item",
    width: "300px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "NewPrice",
    title: "Price",
    width: "205px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 256,
    canBeSaved: true,
    overrideColumn: "PriceSchedulePrice,ItemPrice",
    overrideFilterColumn: "OverrideFilterPrice",
    overridePermission: UserPermissions.CanManageNodePricing
  },
  {
    field: "NewLabel",
    title: "Label",
    width: "420px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 256,
    canBeSaved: true,
    overrideColumn: "ItemLabel",
    overrideFilterColumn: "OverrideFilterLabel",
    overridePermission: UserPermissions.CanManageNodePricing
  },
  {
    field: "NewCalories",
    title: "Calories",
    width: "200px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 256,
    canBeSaved: true,
    overrideColumn: "ItemCalories",
    overrideFilterColumn: "OverrideFilterCalories",
    overridePermission: UserPermissions.CanManageNodePricing
  },
  {
    field: "NewDynamic1",
    title: "Text 1",
    width: "375px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 2000,
    canBeSaved: true,
    overrideColumn: "ItemDynamic1",
    overrideFilterColumn: "OverrideFilterDynamic1",
    overridePermission: UserPermissions.CanManageNodePricing
  },
  {
    field: "NewDynamic2",
    title: "Text 2",
    width: "330px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 2000,
    canBeSaved: true,
    overrideColumn: "ItemDynamic2",
    overrideFilterColumn: "OverrideFilterDynamic2",
    overridePermission: UserPermissions.CanManageNodePricing
  },
  {
    field: "ItemID",
    title: "ItemID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "NodeItemID",
    title: "NodeItemID",
    width: "200px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    defaultValue: -1,
    systemHidden: true
  },
  {
    field: "NodeID",
    title: "NodeID",
    width: "200px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "ItemPrice",
    title: "Default Price",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "PriceSchedulePrice",
    title: "Schedule Price",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "ItemLabel",
    title: "Default Label",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    maxLength: 256,
    canBeSaved: false
  },
  {
    field: "ItemCalories",
    title: "Default Calories",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    maxLength: 256,
    canBeSaved: false
  },
  {
    field: "ItemDynamic1",
    title: "Default Text 1",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    maxLength: 2000,
    canBeSaved: false
  },
  {
    field: "ItemDynamic2",
    title: "Default Text 2",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    maxLength: 2000,
    canBeSaved: false
  }
];
