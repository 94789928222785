import React, {
  useState,
  MouseEvent,
  useLayoutEffect,
  useEffect,
  useRef
} from "react";
import { GridActions, CarbonIcons, StoreActions } from "../../../constants";
import { useGrid } from "../../../contexts/grid/useGrid";
import { GridValidationMessagePosType } from "../../../types/grid";
import { unlockGridIfLockedOut } from "../Utility/GridUtility";
import { CellRenderProps, UnMountSaveData } from "./CellRender";
import { useStore } from "../../../contexts/store";

export const TextboxRender = ({
  originalProps,
  td,
  gridId,
  colDefinition,
  exitEdit
}: CellRenderProps) => {
  const { dispatch } = useStore();
  const { grids, setGrid } = useGrid();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [previousDataItem, setPreviousDataItem] = useState<{
    [key: string]: any;
  }>({});
  const [validationMsg, setValidationMsg] = useState<string | null>(null);
  const [validationPos, setValidationPos] =
    useState<GridValidationMessagePosType | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const mounted = useRef(false);
  const saveData = useRef<UnMountSaveData>();
  let inputRef: HTMLInputElement | null;

  // const editFieldName = grids.get(gridId)!.state.editFieldName!;
  const dataItem = originalProps.dataItem;
  const field = originalProps.field!;
  const dataValue = dataItem[field!];
  const isThisRowBeingEdited =
    grids.get(gridId)!.state.editMode &&
    !(grids.get(gridId)!.state.selectedRowIsExpanded ?? false) &&
    originalProps.dataItem[grids.get(gridId)!.dataItemKey] ===
      grids.get(gridId)!.state.selectedRow;

  // jon, 4/3/22: Save data needed for possible save on unmount since this must use a ref because useEffect below only knows values as they were on mount.
  useEffect(() => {
    saveData.current = {
      previousDataItem: previousDataItem,
      dataItem: dataItem,
      isThisRowBeingEdited: isThisRowBeingEdited,
      saving: saving
    };
  }, [previousDataItem, dataItem, isThisRowBeingEdited, saving]);

  // jon, 4/3/22: Added effect to monitor if component is mounted. Save can now be initiated by scrolling off the grid, so need to check for mounted and
  //   initiate a save if value has changed.
  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;

      if (
        saveData.current &&
        saveData.current.isThisRowBeingEdited &&
        !saveData.current.saving &&
        saveData.current.previousDataItem[originalProps.field!] !== undefined &&
        saveData.current.dataItem[originalProps.field!] !==
          saveData.current.previousDataItem[originalProps.field!]
      ) {
        console.log(
          `Saving changes to text field due to control unmounting...`
        );
        setSaving(true);
        doBlur(saveData.current.previousDataItem, saveData.current.dataItem);
      }
    };
  }, []);

  // Future: Convert all of this to a generic mechanism to raise a field-level validation event to the grid and have each control "listen" in
  //  a layout effect to see if the validation error is occurring in its field. If so, focus and show the validation message on the field.

  // Effect runs after validation msg changes. This gives us an opportunity to set the validation message position correctly relative to input.
  useEffect(() => {
    if (validationMsg !== null && inputRef !== null) {
      setValidationPos({
        posTop: inputRef.offsetTop + 36, // Future: Consider compact mode
        posLeft: inputRef.offsetLeft
      });
    }
  }, [validationMsg]);

  // Layout Effect runs after validation position changes. This gives us an opportunity to set focus in the input after all renders.
  useLayoutEffect(() => {
    if (validationPos !== null && inputRef !== null) {
      // set focus in input where validation error occurred
      inputRef.focus();
    }
  }, [validationPos]);

  // Layout Effect runs when the grid's current edit field changes so we can see if we should set the focus if this is that field in insert mode
  useLayoutEffect(() => {
    if (
      grids.get(gridId)!.state.editFieldName === originalProps.field! &&
      grids.get(gridId)!.state.insertMode === true &&
      inputRef !== null
    ) {
      inputRef.focus();
    }
  }, [grids.get(gridId)!.state.editFieldName]);

  const setMaxLength = () => {
    // Set max length on input if one is defined the column definition
    if (inputRef !== null && colDefinition?.maxLength) {
      inputRef.maxLength = colDefinition!.maxLength;
    }
  };

  const doBlur = async (
    prevDataItem: { [key: string]: any },
    curDataItem: { [key: string]: any }
  ) => {
    if (validationMsg !== null) {
      // If this cell is/was invalid, unlock grid. If cell is still invalid, the exitEdit will re-lock the grid after save attempt.
      unlockGridIfLockedOut(gridId, grids, setGrid);
    }
    setValidationPos(null);
    setValidationMsg(null);
    const result = await exitEdit("ON BLUR", field, prevDataItem, curDataItem, [
      field
    ]);

    if (result !== null) {
      // will, 2/16/22: Added mount check for mobile events causing component to unmount before validation
      if (mounted.current) {
        // Validation on this field failed so set the message which fires the effects above to re-focus the input
        setValidationMsg(result);
        enterEdit("ON BLUR VALIDATION FAILED", previousDataItem, field);
      } else {
        handleUnmountedUpdate(result);
      }
    } else {
      setPreviousDataItem(curDataItem);
    }

    if (mounted.current) setSaving(false);
  };

  const enterEdit = (
    type: string,
    dataItem: { [key: string]: any },
    field: string | undefined
  ): void => {
    // console.log(`TEXTBOX CELL ENTER EDIT - ${type}: `, dataItem, field);
    setPreviousDataItem(dataItem);
    setMaxLength();
    setGrid({
      type: GridActions.editFieldName,
      payload: { gridId, gridData: field }
    });
  };

  const additionalProps = {
    ref: (tdElem: HTMLTableCellElement) => {
      inputRef = tdElem && tdElem.querySelector("input");
    },
    onBlur: async () => {
      // jon, 4/11/22: Set saving flag so unmount does not also fire update if input is blurred and then unmounted immediately. Can cause Unique Key violation when none exists.
      setSaving(true);
      await doBlur(previousDataItem, dataItem);
    },
    onFocus: () => {
      // Do not fire focus event if validation message showing since this resets the "previous" value to the new, incorrect one.
      if (validationMsg === null) {
        enterEdit("ON FOCUS", { ...dataItem }, field);
      }
    },
    onKeyDown: (e: KeyboardEvent) => {
      // If user hits ESC, undo any changes made to this cell and do not save
      if (e.key === "Escape") {
        setValidationPos(null);
        setValidationMsg(null);
        unlockGridIfLockedOut(gridId, grids, setGrid);

        const newDataItem = { ...dataItem };
        newDataItem[field] = previousDataItem[field];
        setPreviousDataItem(newDataItem);

        const grid = grids.get(gridId)!;

        if (grid.state.insertMode) {
          setGrid({
            type: GridActions.onInsertItemChange,
            payload: { gridId, gridData: newDataItem }
          });
        } else {
          const editedRecordID = dataItem[grid.dataItemKey!];
          const records = grid.records.map((record) => {
            let newRecord = record;
            if (record[grid.dataItemKey!] === editedRecordID) {
              newRecord = newDataItem;
            }
            return newRecord;
          });

          setGrid({
            type: GridActions.onItemChange,
            payload: { gridId, gridData: records }
          });
        }
      }
    },
    onMouseEnter: (e: MouseEvent<HTMLTableCellElement>) => {
      // Show tooltip only when cell is too small to show all contents
      const target = e.target as HTMLTableCellElement;
      setShowTooltip(target.offsetWidth < target.scrollWidth);
    },
    title: showTooltip ? dataValue : ""
  };

  // will, 2/16/22: Reformatted handleItemChange() without any setState functions to be usable when component is unmounted
  // and to set failure message
  const handleUnmountedUpdate = (failureMessage: string) => {
    const newDataItem = { ...saveData.current?.dataItem };
    newDataItem[field] = saveData.current?.previousDataItem[field];

    const grid = grids.get(gridId)!;

    if (grid.state.insertMode) {
      setGrid({
        type: GridActions.onInsertItemChange,
        payload: { gridId, gridData: newDataItem }
      });
    } else {
      const editedRecordID = saveData.current?.dataItem[grid.dataItemKey!];
      const records = grid.records.map((record) => {
        let newRecord = record;
        if (record[grid.dataItemKey!] === editedRecordID) {
          newRecord = newDataItem;
        }
        return newRecord;
      });

      setGrid({
        type: GridActions.onItemChange,
        payload: { gridId, gridData: records }
      });
    }

    setGrid({
      type: GridActions.toggleGridLockoutMode,
      payload: { gridId, gridData: false }
    });

    dispatch({
      type: StoreActions.addNotification,
      payload: {
        message: "Save failed with the following message:\n" + failureMessage,
        messageType: "warning",
        closable: true
      }
    });
  };

  const validationWrapper =
    validationPos !== null && validationMsg !== null ? (
      <>
        <div
          className="validation-msg"
          style={{
            top: `${validationPos!.posTop}px`,
            left: `${validationPos!.posLeft}px`
          }}
        >
          {CarbonIcons.Warning}
          {validationMsg}
        </div>
        {td.props.children}
      </>
    ) : (
      <>{td.props.children}</>
    );

  const { className, ...tdprops } = td.props;

  const finalProps = {
    ...tdprops,
    className:
      validationMsg !== null
        ? className + " carbon-gridcell-text validation-error-cell"
        : "carbon-gridcell-text " + className
  };

  return React.cloneElement(
    td,
    { ...finalProps, ...additionalProps },
    validationWrapper
  );
};
