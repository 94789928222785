import { ChangePasswordContract, Result, ResultError } from "../../types";
import useApi from "../../hooks/api/useApi";
import ChangePasswordApi from "../../api/credentials/changePasswordApi";

const useChangePassword = () => {
  const { loading: changePasswordLoading, request: changePasswordRequest } =
    useApi(ChangePasswordApi);

  const changePassword = async (
    changePasswordContract: ChangePasswordContract,
    endpoint: string
  ): Promise<Result<string>> => {
    let result: Result<string>;
    try {
      result = await changePasswordRequest(changePasswordContract, endpoint);
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }
    return result;
  };

  const isLoading = (): boolean => {
    return changePasswordLoading;
  };

  return {
    changePassword,
    isLoading
  };
};

export default useChangePassword;
