import { Helmet } from "react-helmet-async";
import { Box } from "grommet";
import { useNavigate } from "react-router";

// Components
import CarbonGridToolbar, {
  GridToolbarActionLabels
} from "../../components/CarbonGrid/Toolbar/CarbonGridToolbar";
import CarbonGrid from "../../components/CarbonGrid/CarbonGrid";
import Authorizer from "../../components/Util/Authorizer";

// Types & Constants
import { GridType } from "../../types/grid";
import { gridColumns } from "./RolesGridColumns";

// User/Permissions
import usePermissions from "../../hooks/auth/usePermissions";
import {
  EndpointResources,
  Placeholders,
  UserPermissions,
  GridIDs,
  CarbonIcons
} from "../../constants";

// Action bar
import {
  GridActionBarEvent,
  GridActionBarItem
} from "../../components/CarbonGrid/Toolbar/CarbonGridRowActionBar";

const Roles = (): JSX.Element => {
  // Using pathname for grid id for simple screens with a single grid
  const { canInsert, canDelete } = usePermissions();
  const navigate = useNavigate();
  const gridName = GridIDs.Roles;

  const pagePermission = UserPermissions.RolesPerms;

  const actionLabels: GridToolbarActionLabels = {
    singular: "role",
    plural: "roles",
    titleSingular: "Role",
    titlePlural: "Roles"
  };

  const actions: GridActionBarItem[] = [
    {
      show: true,
      label: "Role Permissions",
      includeInExtrasMenu: false,
      icon: CarbonIcons.Permissions,
      onClick: (event: GridActionBarEvent) => {
        console.log(`Role Permissions Items onclick: `, event);
        navigate(
          `/app/${EndpointResources.Roles}/${event.rowData.RoleID}/${EndpointResources.RolePermissions}`
        );
      }
    }
  ];

  const gridSettings: GridType = {
    actions,
    endpoints: {
      gridODataEndpoint: `/odata/${Placeholders.companyID}/gridroles?$count=true&`,
      gridApiEndpoint: `/api/${Placeholders.companyID}/roles`
    },
    records: [],
    total: 0,
    data: [],
    lookups: {},
    dataState: {
      pageSize: 25,
      take: 50,
      skip: 0,
      sort: [{ field: "RoleName", dir: "asc" }],
      filter: {
        logic: "and",
        filters: [{ field: "IsActive", operator: "equals", value: true }]
      }
    },
    dataItemKey: "RoleID",
    singularEntityName: actionLabels.singular,
    pluralEntityName: actionLabels.plural,
    columns: gridColumns,
    showCopyBtn: canInsert(pagePermission),
    showDeleteBtn: canDelete(pagePermission),
    state: {
      editMode: false,
      insertMode: false,
      selectedState: {},
      selectedRow: -1,
      showQuickFilter: false,
      lastSaveDate: null,
      showSavingIndicator: false,
      lockoutMode: false
    }
  };

  return (
    <>
      <Helmet>
        <title>Carbon | Roles</title>
      </Helmet>
      <Authorizer
        canView={pagePermission}
        auditLabel={`${actionLabels.titlePlural} screen`}
      >
        <Box align="start" fill>
          <CarbonGridToolbar
            gridId={gridName}
            screenIcon={CarbonIcons.Roles}
            screenTitle={actionLabels.titlePlural}
            permissions={pagePermission}
            actionLabels={actionLabels}
          />
          <Box fill className="carbon-grid-wrapper">
            <CarbonGrid
              gridId={gridName}
              gridSettings={gridSettings}
              gridClassName={"grid-with-actionbar"}
            />
          </Box>
        </Box>
      </Authorizer>
    </>
  );
};

export default Roles;
