import { Box } from "grommet";
import { FC, useEffect, useState } from "react";
import {
  CarbonIcons,
  GridActions,
  PreviewerActions,
  StoreActions
} from "../../constants";
import { usePreviewer } from "../../contexts/previewer/usePreviewer";
import { useStore } from "../../contexts/store";
import { Button } from "@progress/kendo-react-buttons";
import { useGrid } from "../../contexts/grid/useGrid";

// define the interface
declare global {
  interface Window {
    CarbonPreviewer?: {
      setOptions(options: {
        loading?: boolean;
        banner?: boolean;
        playPauseButton?: boolean;
        playerElementId?: string;
      }): void;
      loadXml(xmlUrl: string): void;
      play(): void;
      pause(): void;
      reset(): void;
    };
  }
}

interface IPreviewerProps {
  gridId: string;
}

const Previewer: FC<IPreviewerProps> = ({ gridId }): JSX.Element => {
  const { store, dispatch } = useStore();
  const { setGrid } = useGrid();
  const { dispatch: previewerDispatch } = usePreviewer();
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    if (window.CarbonPreviewer && store.embeddedPreviewerURL) {
      window.CarbonPreviewer?.setOptions({
        loading: false,
        banner: false,
        playPauseButton: false,
        playerElementId: "carbon-previewer"
      });
      if (store.embeddedPreviewerURL !== "")
        window.CarbonPreviewer?.loadXml(store.embeddedPreviewerURL);
    }
  }, [store.embeddedPreviewerURL]);

  return (
    <div
      className={
        store.showPreviewerPopup
          ? "previewer-popout-wrapper"
          : "previewer-wrapper"
      }
      id="adminPlayerWrapper"
    >
      <Box fill>
        <Box
          style={{
            right: 0,
            position: "absolute",
            zIndex: 999,
            width: 50,
            height: 150,
            margin: 4
          }}
          align={"end"}
          justify={"evenly"}
        >
          <Button
            className={"previewer-button-container"}
            onClick={() => {
              dispatch({
                type: StoreActions.showPreviewerPopup,
                payload: { value: false }
              });
              setGrid({
                type: GridActions.togglePreviewer,
                payload: {
                  gridId: gridId,
                  gridData: { value: false }
                }
              });
              // Turn previewer off
              previewerDispatch({
                type: PreviewerActions.setPreviewerProps,
                payload: undefined
              });
            }}
          >
            <div className="previewer-button-icon-container">
              {CarbonIcons.Close}
            </div>
            <div className="previewer-button-text-container">
              <span>Close</span>
            </div>
          </Button>
          {!store.showPreviewerPopup && (
            <Button
              className={"previewer-button-container"}
              onClick={() => {
                setGrid({
                  type: GridActions.togglePreviewer,
                  payload: {
                    gridId: gridId,
                    gridData: { value: false }
                  }
                });
                const newWindow = window.open(
                  `/app/previewer`,
                  "previewerWindow",
                  "width: 500,height: 500"
                );
                dispatch({
                  type: StoreActions.setPreviewerWindow,
                  payload: { value: newWindow }
                });
                window.addEventListener("beforeunload", () => {
                  newWindow?.close();
                });
              }}
            >
              <div className="previewer-button-icon-container">
                {CarbonIcons.Popout}
              </div>
              <div className="previewer-button-text-container">
                <span>Pop Out</span>
              </div>
            </Button>
          )}
          {isPaused ? (
            <Button
              className={"previewer-button-container"}
              onClick={() => {
                window.CarbonPreviewer?.play();
                setIsPaused(!isPaused);
              }}
            >
              <div className="previewer-button-icon-container">
                {CarbonIcons.Play}
              </div>
              <div className="previewer-button-text-container">
                <span>Play</span>
              </div>
            </Button>
          ) : (
            <Button
              className={"previewer-button-container"}
              onClick={() => {
                window.CarbonPreviewer?.pause();
                setIsPaused(!isPaused);
              }}
            >
              <div className="previewer-button-icon-container">
                {CarbonIcons.Pause}
              </div>
              <div className="previewer-button-text-container">
                <span>Pause</span>
              </div>
            </Button>
          )}
          <Button
            className={"previewer-button-container"}
            onClick={() => {
              // jon, 3/1/22: Reverting this change back to calling player's reset since Jeremy fixed the underlying issue in the player so that it now reloads from server.
              window.CarbonPreviewer?.reset();
            }}
          >
            <div className="previewer-button-icon-container">
              {CarbonIcons.Reset}
            </div>
            <div className="previewer-button-text-container">
              <span>Reset</span>
            </div>
          </Button>
        </Box>

        <div id="carbon-previewer" />
        <div id="loader">
          <div className="outer">
            <div className="middle">
              <div className="loadingSpinner spinner-trans previewer-spinner">
                <div className="spinner">
                  <div className="bounce1" />
                  <div className="bounce2" />
                  <div className="bounce3" />
                </div>
              </div>
              <div id="schedule_msg" className="schedule_msg">
                Loading
              </div>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default Previewer;
