import { Placeholders } from "../../constants";
import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  {
    field: "PackageZoneID",
    title: "ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "PackageID",
    title: "PackageID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "CompanyID",
    title: "CompanyID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    company: true,
    defaultValue: Placeholders.companyID,
    systemHidden: true
  },
  {
    field: "ZoneName",
    title: "Zone",
    width: "185px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "ZoneDesc",
    title: "Description",
    width: "310px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "ContentType",
    title: "Content Type",
    width: "310px",
    defaultShow: true,
    filter: "text",
    editor: "dropdown",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: "T",
    dropItems: [
      { value: "T", text: "Static Text" },
      { value: "M", text: "Media" },
      { value: "I", text: "Item" }
    ]
  },
  {
    field: "MediaID",
    title: "MediaID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "ItemID",
    title: "ItemID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "ZoneContent",
    title: "Content",
    width: "435px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "combinedcolumn",
    required: false,
    canBeSaved: false
  },
  {
    field: "MediaName",
    fieldNameInLookup: "MediaName",
    title: "Media",
    titlePlural: "Media",
    width: "250px",
    maxLength: 256,
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    canBeSaved: false,
    valueField: "MediaID",
    valueFieldInLookup: "MediaID",
    combinedCol: "ZoneContent",
    controlField: "ContentType",
    controlValue: "M",
    lookupFilterCol: "FilterMediaTypeID",
    lookupFilterDataName: "MediaTypeID"
  },
  {
    field: "ItemName",
    fieldNameInLookup: "ItemName",
    title: "Item",
    titlePlural: "Items",
    width: "250px",
    maxLength: 256,
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    canBeSaved: false,
    valueField: "ItemID",
    combinedCol: "ZoneContent",
    controlField: "ContentType",
    controlValue: "I",
    lookupFilterCol: "FilterItemTypeID",
    lookupFilterDataName: "ItemTypeID"
  },
  {
    field: "StaticText",
    title: "Static Text",
    titlePlural: "Static Texts",
    width: "250px",
    maxLength: 256,
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true,
    combinedCol: "ZoneContent",
    controlField: "ContentType",
    controlValue: "T"
  },
  {
    field: "StaticDisplayTime",
    title: "Static Display Time",
    width: "185px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    minValue: 0,
    required: true,
    canBeSaved: true,
    defaultValue: 10
  },
  {
    field: "DeferToSchedule",
    title: "Defer to Schedule",
    width: "310px",
    defaultShow: true,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true,
    defaultValue: "N"
  },
  {
    field: "FilterMediaTypeID",
    title: "FilterMediaTypeID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "FilterItemTypeID",
    title: "FilterItemTypeID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "FilterMediaTypeName",
    title: "FilterMediaTypeName",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: true
  }
];
