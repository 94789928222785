import { MenuItem, Menu, MenuSelectEvent } from "@progress/kendo-react-layout";
import styled from "styled-components";
import { Box, Grid } from "grommet";
import { FC } from "react";
import { useGrid } from "../../../contexts/grid/useGrid";
import { GridActions, CarbonIcons } from "../../../constants";
import { replaceParametersWithData } from "../Utility/GridUtility";
import { GridType } from "../../../types/grid";

export type GridActionBarEvent = {
  rowData: { [key: string]: any };
  selectedDayGroupDays?: string;
  activeDayPartId?: number;
  grid: GridType;
};

export type GridActionBarItem = {
  show: boolean;
  label: string;
  icon: any;
  includeInExtrasMenu?: boolean;
  linkUrl?: string;
  onClick?: (event: GridActionBarEvent) => void;
};

export interface GridRowActionBarProps {
  gridId: string;
  rowId: number;
  rowData: { [key: string]: any };
  selectedDayGroupDays?: string;
  activeDayPartId?: number;
  position: number;
  showCopyBtn: boolean;
  showDeleteBtn: boolean;
  actions: GridActionBarItem[];
  gridInsertMode: boolean;
  gridEditMode: boolean;
  isRowSelected: boolean;
}

// Tooltips do not show on disabled buttons so using wrappers below around each button
const GridRowActionBar: FC<GridRowActionBarProps> = (props) => {
  const { setGrid, grids } = useGrid();

  const triggerRowClickEvent = () => {
    // Triggers row click event if row is not already selected
    // jon, 1/15/22: I removed the check to see if row is already selected because we need to always select it regardless. This action set
    //   the selectedRowData so if the user just updated the record, this array would not reflect it if going to another tab and it is checked there.
    setGrid({
      type: GridActions.onRowClick,
      payload: { gridId: props.gridId, gridData: { dataItem: props.rowData } }
    });
  };

  const defaultActions: GridActionBarItem[] = [
    {
      show: props.showCopyBtn,
      label: "Duplicate",
      includeInExtrasMenu: true,
      icon: CarbonIcons.Copy,
      onClick: (event: GridActionBarEvent) => {
        console.log(`Copy row ${props.rowId} onclick: `, event);
        setGrid({
          type: GridActions.toggleCopyRecordDialog,
          payload: {
            gridId: props.gridId,
            gridData: { data: true }
          }
        });
      }
    },
    {
      show: props.showDeleteBtn,
      label: "Delete",
      includeInExtrasMenu: true,
      icon: CarbonIcons.Delete,
      onClick: (event: GridActionBarEvent) => {
        console.log(`Delete row ${props.rowId} onclick: `, event);
        setGrid({
          type: GridActions.onDeleteRecord,
          payload: { gridId: props.gridId, gridData: { id: props.rowId } }
        });
      }
    }
  ];

  const customActions = props.actions ?? [];
  // jon, 9/3/21: I am arranging these backwards for now, so instead of overriding the default actions, this makes the copy/delete appear
  //  last in the extras menu so other operations display on top. If we decide we do actually need to override the copy/delete, we will have
  //  to revisit this approach, but I don't think it will be necessary.
  const actions = [...customActions, ...defaultActions];
  const isExtrasMenuEmpty =
    actions.filter(
      (action) => action.includeInExtrasMenu === true && action.show === true
    ).length === 0;

  const onSelectMenuItem = (event: MenuSelectEvent) => {
    // Locate the action for the clicked menu item by its text
    const action = actions.filter((action) => action.label === event.item.text);
    if (action.length === 0) {
      console.log(
        `ACTION BAR ERROR: action not found for menu item with label '${event.item.text}`
      );
    } else if (action.length > 1) {
      console.log(
        `ACTION BAR ERROR: too many actions found for menu item with label '${event.item.text}. Menu item labels must be unique.`
      );
    } else {
      triggerRowClickEvent();
      if (action[0].onClick !== undefined)
        action[0].onClick({
          rowData: props.rowData,
          selectedDayGroupDays: props.selectedDayGroupDays,
          activeDayPartId: props.activeDayPartId,
          grid: grids.get(props.gridId)!
        });
    }
  };

  const extrasMenuBtn = () => {
    return (
      <div className="action-icon" key="extras_menu">
        {CarbonIcons.ActionMenuExtras}
      </div>
    );
  };

  const innerExtraMenuItemRender = (
    renderProps: any,
    action: GridActionBarItem
  ) => {
    return (
      <Box
        direction="row"
        gap="small"
        pad="xsmall"
        key={renderProps.itemId}
        margin={{ left: "-20px" }}
        align="start"
      >
        <Box align="start" width="40px">
          {action.icon}
        </Box>
        <Box align="start" fill>
          {action.label}
        </Box>
      </Box>
    );
  };

  const extraMenuItemRender = (renderProps: any, action: GridActionBarItem) => {
    if (action.linkUrl) {
      const url = replaceParametersWithData(action.linkUrl, props.rowData);
      // console.log(`Link URL: ${url}`);
      return <a href={url}>{innerExtraMenuItemRender(renderProps, action)}</a>;
    } else {
      return innerExtraMenuItemRender(renderProps, action);
    }
  };

  const extrasMenu = (
    <div className="action-bar-extras-menu">
      <Menu style={{ height: "100%" }} onSelect={onSelectMenuItem}>
        <MenuItem linkRender={extrasMenuBtn}>
          {actions.map(
            (action) =>
              action.show &&
              action.includeInExtrasMenu && (
                <MenuItem
                  key={action.label}
                  text={action.label}
                  render={(props: any) => extraMenuItemRender(props, action)}
                />
              )
          )}
        </MenuItem>
      </Menu>
    </div>
  );

  return props.gridInsertMode || props.gridEditMode ? (
    <></>
  ) : (
    <StyledDiv style={{ right: `${props.position}px` }} {...props}>
      <Grid columns={{ count: 1, size: "auto" }} gap="small">
        <Box direction="row-reverse" align="end" gap="small">
          {!isExtrasMenuEmpty && extrasMenu}
          {actions.map(
            (action, i) =>
              action.show &&
              !action.includeInExtrasMenu && (
                <div
                  className="action-icon"
                  key={i}
                  onClick={() => {
                    triggerRowClickEvent();
                    if (action.onClick)
                      action.onClick({
                        rowData: props.rowData,
                        selectedDayGroupDays: props.selectedDayGroupDays,
                        activeDayPartId: props.activeDayPartId,
                        grid: grids.get(props.gridId)!
                      });
                  }}
                >
                  {action.icon}
                  <p>{action.label}</p>
                </div>
              )
          )}
        </Box>
      </Grid>
    </StyledDiv>
  );
};

const StyledDiv = styled.div<GridRowActionBarProps>`
  position: absolute;
  z-index: 10;
  margin-top: -20px;
  padding-left: 40px;
  padding-right: 40px;
  background-color: ${(props) =>
    props.isRowSelected
      ? "var(--carbon-lightblue)"
      : "var(--carbon-verylightgray)"};

  div.action-icon {
    position: relative;
    display: inline-block;
    background-color: transparent;
    border-radius: 40px;
    padding: 5px 6px 6px 6px;

    &:hover {
      color: var(--carbon-white);
      background-color: var(--carbon-blue);
    }

    span[class^="material-icons"] {
      display: inline-flex;
      vertical-align: middle;
      width: 30px;
      padding-left: 3px;
    }

    p {
      display: none;
      position: absolute;
      top: 42px;
      left: -34px;
      width: 110px;
      word-break: normal;
      white-space: normal;
      color: var(--carbon-white);
      background-color: var(--carbon-blue);
      border-radius: 4px;
      padding: 0 8px 2px 8px;
      line-height: 22px;
      text-align: center;
    }

    &:hover p {
      display: block;
    }
  }

  li:hover {
    background-color: transparent !important;
  }
`;

export default GridRowActionBar;
