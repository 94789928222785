import { GridColumns } from "../../types/grid";
import { Placeholders } from "../../constants";

export const gridColumns: GridColumns[] = [
  {
    field: "TypeID",
    title: "TypeID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: -1,
    isPartOfUniqueKey: true
  },
  {
    field: "CompanyID",
    title: "CompanyID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    company: true,
    defaultValue: Placeholders.companyID,
    isPartOfUniqueKey: true,
    systemHidden: true
  },
  {
    field: "CompanyName",
    title: "Company",
    titlePlural: "Companies",
    width: "351px",
    defaultShow: true,
    filter: "text",
    editable: false,
    editor: "lookup",
    required: false,
    valueField: "CompanyID",
    maxLength: 50,
    canBeSaved: false,
    companyMaster: true,
    defaultValue: Placeholders.companyName
  },
  {
    field: "Screen",
    title: "Screen",
    width: "201px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "dropdown",
    required: true,
    canBeSaved: true,
    defaultValue: "ItemTypes",
    dropItems: [
      {
        value: "CampaignTypes",
        text: "Campaign"
      },
      {
        value: "ItemTypes",
        text: "Items"
      },
      {
        value: "MediaTypes",
        text: "Media"
      },
      {
        value: "NodeTypes",
        text: "Nodes"
      },
      {
        value: "PackageTypes",
        text: "Packages"
      },
      {
        value: "StencilTypes",
        text: "Stencils"
      },
      {
        value: "TemplateTypes",
        text: "Templates"
      },
      {
        value: "NodeDisplayTypes",
        text: "NodeDisplays"
      }
    ]
  },
  {
    field: "TypeName",
    title: "User Defined Type",
    width: "767px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    maxLength: 50,
    canBeSaved: true,
    isPartOfUniqueKey: true
  },
  {
    field: "TypeDesc",
    title: "Description",
    width: "260px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 256,
    canBeSaved: true
  },
  {
    field: "IsActive",
    title: "Active",
    width: "150px",
    defaultShow: true,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true,
    defaultValue: "Y"
  }
];
