import { useLayoutEffect, useState } from "react";

interface IWindowSizeResponse {
  windowHeight: number;
  windowWidth: number;
}

// jon, 2/22/22: Updated hook to include window width as well because some resize events are based on width changing.
export function useWindowSize(): IWindowSizeResponse {
  const [windowHeight, setWindowHeight] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      // will, 2/18/22: window.innerHeight is set to the device's height and not the viewport's
      // height on mobile. Updating to document height to capture the browser's viewport.
      setWindowHeight(document.documentElement.clientHeight);
      setWindowWidth(document.documentElement.clientWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return { windowHeight, windowWidth };
}
