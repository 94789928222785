// Base
import { FC, useState, useEffect } from "react";
import dayjs from "dayjs";
import styled from "styled-components";

// Grommet/Kendo Components
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import {
  RadioButton,
  RadioButtonChangeEvent
} from "@progress/kendo-react-inputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Notification } from "@progress/kendo-react-notification";
import { Box } from "grommet";

// Components Custom
import OctopusLoader from "../OctopusLoader";
import DropDownField from "../../Fields/DropDownField";
import { DateField } from "../../Fields/DateField";

// Types/Constants
import {
  CarbonIcons,
  GridIDs,
  StoreActions,
  GridActions,
  UserPermissions
} from "../../../constants";
import { NodeProcessUpdatesContract } from "../../../types";

// Hooks
import useNodeProcessUpdatesApi from "../../../hooks/nodes/useNodeProcessUpdates";
import { useStore } from "../../../contexts/store";
import { useGrid } from "../../../contexts/grid/useGrid";
import usePermissions from "../../../hooks/auth/usePermissions";

type ProcessingType = {
  label: string;
  details: JSX.Element;
};

interface IConfirmationDialogProps {
  activeGrid: string;
  gridRow: any;
  nodeGroup?: any;
  onCloseDialog: () => void;
}

const NodePublishDialog: FC<IConfirmationDialogProps> = ({
  activeGrid,
  gridRow,
  nodeGroup,
  onCloseDialog
}) => {
  const nodeName: string = gridRow.NodeName;
  const nodeCompanyId: number = gridRow.CompanyID;
  const nodeId: number = gridRow.NodeID;
  const {
    getNodeProcessUpdates,
    postProcessNow,
    postProcessLater,
    postCancelUpdates,
    postResendUpdates,
    getNodeGroup,
    isLoading
  } = useNodeProcessUpdatesApi();

  const { store, dispatch } = useStore();
  const { grids, setGrid } = useGrid();
  const { canView } = usePermissions();
  const [status, setStatus] = useState("");
  const [instructions, setInstructions] = useState("");
  const [processingTypes, setProcessingTypes] = useState<ProcessingType[]>([]);
  const [processTypeDropdown, setProcessTypeDropdown] = useState<string>();
  const [processTypeDetail, setProcessTypeDetail] = useState<JSX.Element>();
  const [processDate, setProcessDate] = useState<Date | null>(
    dayjs(`${dayjs().add(1, "day").format("YYYY-MM-DD")}T00:00:00`).toDate()
  );
  const [nodeGroupName, setNodeGroupName] = useState("");

  const [dialogDataRefetchTrigger, setDialogDataRefetchTrigger] =
    useState(true);

  // Publish Updates Table Data
  const [dataStatus, setDataStatus] = useState("");
  const [dataErrorMessage, setDataErrorMessage] = useState("");
  const [dataNodeProcessUpdateId, setDataNodeProcessUpdateId] = useState(-1);

  // API fields
  const [nodeSelect, setNodeSelect] = useState(0);
  const [nodeGroupId, setNodeGroupId] = useState(-1);

  useEffect(() => {
    const result = getNodeProcessUpdates(nodeId);
    result.then((res) => {
      if (res.type === "success") {
        const response: any = res.value;
        const data: any = response.data[0];
        console.log("API Response:", data);

        if (data) {
          setDataStatus(data.UpdateStatus);
          setDataErrorMessage(data.ErrorMessage);
          setDataNodeProcessUpdateId(data.NodeProcessUpdateID);
        } else {
          // will, 1/4/22: "Unprocessed" status does not return anything, so manual intervention is required
          // will, 3/14/22: Moved the setData functions out of the check for nodes grid because these still
          // need to be set in order for the dialog to update
          setDataStatus("Unprocessed");
          setDataErrorMessage("");
          setDataNodeProcessUpdateId(-1);

          // will, 2/15/22: only applies to nodes screen. causes error on reload when on subgrid screens
          if (activeGrid === GridIDs.Nodes) {
            const grid = grids.get(GridIDs.Nodes)!;
            const editedRecordID = gridRow[grid?.dataItemKey!];
            const newDataItem = { ...gridRow };
            newDataItem.UpdatesFilter = "Unprocessed";

            const records = grid.records.map((record) => {
              let newRecord = record;
              if (record[grid.dataItemKey!] === editedRecordID) {
                newRecord = newDataItem;
              }
              return newRecord;
            });

            setGrid({
              type: GridActions.onItemChange,
              payload: { gridId: GridIDs.Nodes, gridData: records }
            });
          }
        }
      }
    });

    setNodeSelect(0);

    if (nodeGroup) {
      setNodeGroupName(nodeGroup.nodeGroupName);
      setNodeGroupId(nodeGroup.nodeGroupId);
    }
  }, [dialogDataRefetchTrigger]);

  useEffect(() => {
    const ProcessNowAction: ProcessingType = {
      label: "Publish Now",
      details: (
        <label>
          The schedule content will be sent to the node(s) immediately.
        </label>
      )
    };
    const ProcessLaterAction: ProcessingType = {
      label: "Publish Later",
      details: (
        <label>
          The schedule content will be sent to the node(s) at the Publish Date
          and Time you specify above. This is the <b>LOCAL</b> time of the
          node(s) which uses each player machine&apos;s system time zone setting
          to determine the actual time to publish updates.
        </label>
      )
    };
    const CancelAction: ProcessingType = {
      label: "Cancel Updates",
      details: (
        <label>All pending updates for this node will be cancelled.</label>
      )
    };
    const ResendAction: ProcessingType = {
      label: "Resend Updates",
      details: (
        <label>The most recent updates for this node will be sent again.</label>
      )
    };
    const NoChangesInstructions =
      "No changes may be made at this time because the updates for this node are being published. Once the updates have been delivered and confirmed by the node, changes may be made. Click Cancel Updates to cancel all pending updates for this node.";

    switch (dataStatus) {
      case "Unprocessed":
      case "Confirmed":
        setProcessingTypes([ProcessNowAction, ProcessLaterAction]);
        setStatus("This node is ready to receive updates");
        setInstructions(
          "Select the node (or nodes) to publish and select Publish Now or Publish Later to send the current schedule content to the node(s)."
        );
        break;
      case "Error":
        setProcessingTypes([CancelAction, ResendAction]);
        setStatus("Error");
        setInstructions(
          "An error has occurred at this node while publishing updates.  Review the error message below and then click Cancel Updates to cancel the updates, or click Resend Updates to send the same updates again."
        );
        break;
      case "Pending":
        setProcessingTypes([
          CancelAction,
          ProcessNowAction,
          ProcessLaterAction
        ]);
        setStatus(
          "Pending - Updates are waiting to be published at the specified date and time"
        );
        setInstructions(
          "Select Cancel Updates to cancel all pending updates for this node, Publish Now to send the updates immediately, or Publish Later to update the date and time when the updates should be sent."
        );
        break;
      default:
        setProcessingTypes([CancelAction]);
        switch (dataStatus) {
          case "Processing":
            setStatus(
              "Processing - Updates are processing and are about to be sent to the node"
            );
            setInstructions(NoChangesInstructions);
            break;
          case "Ready to Send":
            setStatus("Ready to Send - Updates are waiting to be sent");
            setInstructions(NoChangesInstructions);
            break;
          case "Sent":
            setStatus(
              "Sent - Updates have been sent but have not yet arrived at the node"
            );
            setInstructions(NoChangesInstructions);
            break;
          case "Delivered":
            setStatus(
              "Delivered - Updates have arrived at the node and are being processed"
            );
            setInstructions(NoChangesInstructions);
        }
    }
  }, [dataStatus]);

  useEffect(() => {
    if (processingTypes.length > 0) {
      setProcessTypeDropdown(processingTypes[0].label);
      setProcessTypeDetail(processingTypes[0].details);
    }
  }, [processingTypes]);

  const handleProcessingTypeChange = (event: DropDownListChangeEvent) => {
    console.log(`Type Change:`, event.value);

    setProcessTypeDropdown(event.value);
    setProcessTypeDetail(
      processingTypes.find((type) => type.label === event.value)!.details
    );
  };

  const onClickRestoreButton = async () => {
    console.log("Node Select:", nodeSelect);

    // All actions require NodeProcessUpdateID
    // Default date to NOW
    const payload: NodeProcessUpdatesContract = {
      NodeProcessUpdateID: dataNodeProcessUpdateId,
      ProcessDate: `#${dayjs().format("MM/DD/YYYY")} 00:00:00#`,
      ProcessTime: "#1/1/1900 12:00:00 AM#"
    };

    // Process Now - Requires: IsNode, NodeGroup (if applicable)
    if (processTypeDropdown === "Publish Now") {
      if (nodeSelect === 0) {
        payload.IsNode = true;

        console.log("Publish Now (Single Node):", payload);
        const result = await postProcessNow(nodeId, nodeCompanyId, payload);
        applyNotification(result.type);
      } else {
        payload.IsNode = false;

        const result = getNodeGroup(nodeGroupId);
        result.then(async (res) => {
          if (res.type === "success") {
            const response: any = res.value;
            const data: any = response.data;
            const value: any[] = data.value;
            const nodeGroupList: number[] = value.map((node) =>
              node.NodeID.toString()
            );
            payload.NodeGroup = nodeGroupList.join();

            console.log("Publish Now (Node Group):", payload);
            const procResult = await postProcessNow(
              nodeId,
              nodeCompanyId,
              payload
            );
            applyNotification(procResult.type);
          }
        });
      }
    }

    // Process Later - Requires: IsNode, NodeGroup (if applicable), ProcessDate, ProcessTIme
    if (processTypeDropdown === "Publish Later") {
      console.log("Selected Date:", processDate);
      payload.ProcessDate = `#${dayjs(processDate).format(
        "MM/DD/YYYY"
      )} 00:00:00#`;
      payload.ProcessTime = `#1/1/1900 ${dayjs(processDate).format(
        "HH:mm:ss"
      )}#`;

      if (nodeSelect === 0) {
        payload.IsNode = true;

        /** chris foster - 02/09/2024:
         * If we are updating a "Pending" Process Later record then do nothing and include the NodeProcessUpdateId.
         * If the UpdateStatus (dataStatus) is not equal to "Pending" then we want to remove the NodeProcessUpdateID
         * so a new schedule history record will be created. */
        if (dataStatus !== "Pending") {
          payload.NodeProcessUpdateID = -1;
        }

        console.log("Publish Later (Single Node):", payload);
        const result = await postProcessLater(nodeId, nodeCompanyId, payload);
        applyNotification(result.type);
      } else {
        payload.IsNode = false;

        const result = getNodeGroup(nodeGroupId);
        result.then(async (res) => {
          if (res.type === "success") {
            const response: any = res.value;
            const data: any = response.data;
            const value: any[] = data.value;
            const nodeGroupList: number[] = value.map((node) =>
              node.NodeID.toString()
            );
            payload.NodeGroup = nodeGroupList.join();

            console.log("Publish Later (Node Group):", payload);
            const procResult = await postProcessLater(
              nodeId,
              nodeCompanyId,
              payload
            );
            applyNotification(procResult.type);
          }
        });
      }
    }

    // Cancel Updates - Requires: NO ADDITIONAL REQIREMENTS
    if (processTypeDropdown === "Cancel Updates") {
      console.log("Cancel Updates:", payload);
      const result = await postCancelUpdates(nodeId, nodeCompanyId, payload);
      applyNotification(result.type);
    }

    // Resend Updates - Requires: NO ADDITIONAL REQIREMENTS
    if (processTypeDropdown === "Resend Updates") {
      console.log("Resend Updates:", payload);
      const result = await postResendUpdates(nodeCompanyId, payload);
      applyNotification(result.type);
    }
  };

  // will, 3/1/22: Created separate function for notification and data refetch trigger so it can be called in all the
  // async functions above. Being called synchronously, the node GROUP processing was causing timing issues
  const applyNotification = (type: String) => {
    if (type === "success") {
      dispatch({
        type: StoreActions.addNotification,
        payload: {
          message: `Success: ${processTypeDropdown}`,
          messageType: "success",
          closable: false
        }
      });
    } else {
      dispatch({
        type: StoreActions.addNotification,
        payload: {
          message: `Error executing action: ${processTypeDropdown}`,
          messageType: "error",
          closable: true
        }
      });
    }

    setDialogDataRefetchTrigger(!dialogDataRefetchTrigger);
  };

  const onClickCancelButton = () => {
    // will, 12/21/21: update the Nodes grid when they close the dialog so they can see the updat without triggering a grid refresh and lose their selected row
    // will, 2/15/22: only applies to nodes screen. causes error on reload when on subgrid screens
    if (activeGrid === GridIDs.Nodes) {
      const grid = grids.get(GridIDs.Nodes)!;
      const editedRecordID = gridRow[grid?.dataItemKey!];
      const newDataItem = { ...gridRow };
      newDataItem.UpdatesFilter = dataStatus;

      const records = grid.records.map((record) => {
        let newRecord = record;
        if (record[grid.dataItemKey!] === editedRecordID) {
          newRecord = newDataItem;
        }
        return newRecord;
      });

      setGrid({
        type: GridActions.onItemChange,
        payload: { gridId: GridIDs.Nodes, gridData: records }
      });
    } else if (activeGrid === GridIDs.NodeDisplays) {
      // will, 3/14/22: Added refresh for Displays screen since this screen's data is effected by publishing
      setGrid({
        type: GridActions.toggleRefreshGrid,
        payload: { gridId: GridIDs.NodeDisplays, gridData: true }
      });
    }

    onCloseDialog();
  };

  return (
    <Dialog
      title={
        <h1>
          {CarbonIcons.Publish}
          <br />
          {"Publish Updates"}
        </h1>
      }
      closeIcon={false}
      width={"40%"}
    >
      <Box style={{ position: "relative" }}>
        {isLoading() && <OctopusLoader />}
        <Box gap="small" border="bottom" margin={{ bottom: "10px" }}>
          <Box>
            <p>{instructions}</p>
          </Box>
          <Box pad={{ bottom: "15px" }} border="bottom" gap="small">
            <Box direction="row" gap="xsmall" align="center">
              <Label
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  opacity: "0.75"
                }}
              >
                STATUS:
              </Label>
              <Label style={{ fontSize: "15px", fontStyle: "italic" }}>
                {status}
              </Label>
            </Box>
            {dataStatus === "Error" && (
              <Notification
                type={{ style: "error", icon: true }}
                closable={false}
              >
                <div style={{ maxHeight: "200px", overflowY: "scroll" }}>
                  {dataErrorMessage}
                </div>
              </Notification>
            )}
            {dataStatus !== "Confirmed" && (
              <Box direction="row" gap="xsmall" align="center">
                <Label
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    opacity: "0.75"
                  }}
                >
                  NODE:
                </Label>
                <Label
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    opacity: "0.75"
                  }}
                >
                  {nodeName}
                </Label>
              </Box>
            )}
          </Box>
          {(dataStatus === "Confirmed" || dataStatus === "Unprocessed") && (
            <Box pad={{ bottom: "15px" }} gap="xsmall" border="bottom">
              <Label
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  opacity: "0.75"
                }}
              >
                NODE SELECTION
              </Label>
              <Box direction="row">
                <RadioButton
                  name="nodeSelectRadioGroup"
                  id="rb1"
                  value={0}
                  defaultChecked={true}
                  onChange={(e: RadioButtonChangeEvent) => {
                    console.log(e.value);
                    setNodeSelect(e.value);
                  }}
                >
                  <label
                    htmlFor="rb1"
                    className={"k-radio-label"}
                    style={{ display: "inline-block" }}
                  >
                    <b>{nodeName}</b> node only
                  </label>
                </RadioButton>
              </Box>
              {/* will, 7/5/22: If the user does not have access to node groups, then only let them process the one node */}
              {!canView(UserPermissions.NodeGroupsPerms) ? (
                <Label style={{ color: "var(--carbon-red)" }}>
                  Publishing by Node Group is not available
                </Label>
              ) : store.activeCompany!.companyId === -1 ? (
                <Label style={{ color: "var(--carbon-red)" }}>
                  Publishing by Node Group for &apos;(All Companies)&apos; is
                  not supported
                </Label>
              ) : (
                <Box direction="row">
                  <RadioButton
                    name="nodeSelectRadioGroup"
                    id="rb2"
                    value={1}
                    onChange={(e: RadioButtonChangeEvent) => {
                      console.log(e.value);
                      setNodeSelect(e.value);
                    }}
                  >
                    <label
                      htmlFor="rb2"
                      className={"k-radio-label"}
                      style={{ display: "inline-block" }}
                    >
                      All nodes in{" "}
                      <b>
                        {nodeGroupName !== "" ? nodeGroupName : "(All Nodes)"}
                      </b>{" "}
                      node group
                    </label>
                  </RadioButton>
                </Box>
              )}
            </Box>
          )}
          <Label
            style={{ fontSize: "16px", fontWeight: "bold", opacity: "0.75" }}
          >
            PUBLISHING TYPE
          </Label>
          {processingTypes.length > 0 && (
            <Box direction="row" gap="medium">
              <DropDownField
                onChange={(event) => {
                  handleProcessingTypeChange(event);
                }}
                data={processingTypes.map((type) => type.label)}
                value={processTypeDropdown}
                defaultValue={processingTypes[0].label}
              />
              {processTypeDropdown === "Publish Later" && (
                <StyledFieldWrapper>
                  <DateField
                    id="ProcessDateTime"
                    label="Publish Date/Time"
                    labelWidth="auto"
                    fieldWidth="auto"
                    defaultValue={processDate}
                    datetime={true}
                    isPublishDialog={true}
                    onChange={(newValue: Date | null) =>
                      setProcessDate(newValue)
                    }
                  />
                </StyledFieldWrapper>
              )}
            </Box>
          )}
          <Box>
            <p>{processTypeDetail}</p>
          </Box>
        </Box>
        <Box margin={{ top: "10px" }}>
          <DialogActionsBar>
            <Button onClick={onClickCancelButton}>
              {CarbonIcons.Close}
              {"Close"}
            </Button>
            <Button
              autoFocus={true}
              primary={true}
              onClick={onClickRestoreButton}
            >
              {CarbonIcons.Check}
              {"Apply"}
            </Button>
          </DialogActionsBar>
        </Box>
      </Box>
    </Dialog>
  );
};

const StyledFieldWrapper = styled(FieldWrapper)`
  label {
    margin-top: 3px;
  }
`;

export default NodePublishDialog;
