import { Box } from "grommet";
import { FC, useEffect, useState } from "react";
import { CarbonIcons } from "../../constants";
import { Button } from "@progress/kendo-react-buttons";
import { useCrossTabState } from "../../hooks/utils/useCrossTabState";

// define the interface
declare global {
  interface Window {
    CarbonPreviewer?: {
      setOptions(options: {
        loading?: boolean;
        banner?: boolean;
        playPauseButton?: boolean;
        playerElementId?: string;
      }): void;
      loadXml(xmlUrl: string): void;
      play(): void;
      pause(): void;
      reset(): void;
    };
  }
}

const PreviewerStandalone: FC = (): JSX.Element => {
  const [isPaused, setIsPaused] = useState(false);
  const [previewerUrl] = useCrossTabState("previewerUrl", "");

  const updatePreviewer = () => {
    window.CarbonPreviewer?.setOptions({
      loading: false,
      banner: false,
      playPauseButton: false,
      playerElementId: "carbon-previewer"
    });
    if (previewerUrl !== "") window.CarbonPreviewer?.loadXml(previewerUrl);
  };

  useEffect(() => {
    if (!window.CarbonPreviewer && previewerUrl) {
      setTimeout(updatePreviewer, 1000);
    } else if (window.CarbonPreviewer && previewerUrl) {
      updatePreviewer();
    }
  }, [previewerUrl]);

  return (
    <div
      id="adminPlayerWrapper"
      style={{
        width: "100%",
        height: "100%",
        background: "var(--carbon-darkgray)"
      }}
    >
      <Box fill>
        <Box
          style={{
            right: 0,
            position: "absolute",
            zIndex: 999,
            width: 50,
            height: 120,
            margin: 4
          }}
          align={"end"}
          justify={"evenly"}
        >
          <Button
            className={"previewer-button-container"}
            onClick={() => {
              window.close();
            }}
          >
            <div className="previewer-button-icon-container">
              {CarbonIcons.Close}
            </div>
            <div className="previewer-button-text-container">
              <span>Close</span>
            </div>
          </Button>
          {isPaused ? (
            <Button
              className={"previewer-button-container"}
              onClick={() => {
                window.CarbonPreviewer?.play();
                setIsPaused(!isPaused);
              }}
            >
              <div className="previewer-button-icon-container">
                {CarbonIcons.Play}
              </div>
              <div className="previewer-button-text-container">
                <span>Play</span>
              </div>
            </Button>
          ) : (
            <Button
              className={"previewer-button-container"}
              onClick={() => {
                window.CarbonPreviewer?.pause();
                setIsPaused(!isPaused);
              }}
            >
              <div className="previewer-button-icon-container">
                {CarbonIcons.Pause}
              </div>
              <div className="previewer-button-text-container">
                <span>Pause</span>
              </div>
            </Button>
          )}
          <Button
            className={"previewer-button-container"}
            onClick={() => {
              // jon, 2/9/22: The reset button needs to reload the preview XML, not just call the player's built-in reset. We trigger that here by calling
              //   the existing updatePreviewer above, which reloads the XML from the URL.
              updatePreviewer();
            }}
          >
            <div className="previewer-button-icon-container">
              {CarbonIcons.Reset}
            </div>
            <div className="previewer-button-text-container">
              <span>Reset</span>
            </div>
          </Button>
        </Box>

        <div id="carbon-previewer" />
        <div id="loader">
          <div className="outer">
            <div className="middle">
              <div className="loadingSpinner spinner-trans previewer-spinner">
                <div className="spinner">
                  <div className="bounce1" />
                  <div className="bounce2" />
                  <div className="bounce3" />
                </div>
              </div>
              <div id="schedule_msg" className="schedule_msg">
                Loading
              </div>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default PreviewerStandalone;
