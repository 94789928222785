import { Label, Error, Hint } from "@progress/kendo-react-labels";
import { FieldWrapper, FieldRenderProps } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Box } from "grommet";
import styled from "styled-components";

interface AppFieldRenderProps extends FieldRenderProps {
  icon: React.Component<any>;
  passwordIcon: React.Component<any>;
  fieldType: string;
  required: boolean;
  validationMessage: string;
}

const FormInput = (fieldRenderProps: AppFieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    optional,
    icon,
    passwordIcon,
    width,
    fieldType,
    required,
    name,
    ...others
  } = fieldRenderProps;

  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";

  return (
    <FieldWrapper>
      <StyledLabel
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
        optional={optional}
      >
        {label}
      </StyledLabel>
      <StyledInputBox width={width} direction="row" align="center">
        <Box pad={{ horizontal: "small" }}>{icon}</Box>
        <StyledInput
          name={name}
          required={required}
          valid={valid}
          validationMessage={validationMessage}
          type={fieldType}
          id={id}
          disabled={disabled}
          ariaDescribedBy={`${hintId} ${errorId}`}
          {...others}
        />
        {passwordIcon && passwordIcon}
      </StyledInputBox>

      <Box>
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </Box>
    </FieldWrapper>
  );
};

const StyledLabel = styled(Label)`
  font-weight: bold;
`;

const StyledInputBox = styled(Box)`
  border: 1px solid #cccccc;
  border-radius: 20px;
  padding: 1px 15px 1px 0;
  box-shadow: inset 1px 1px 2px #cccccc;
`;

const StyledInput = styled(Input)`
  border: 0;
  box-shadow: none;
`;

export default FormInput;
