import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  // Field typeId from route is required for defalut value
  // {
  //   field: "TypeID",
  //   title: "TypeID",
  //   width: "150px",
  //   defaultShow: false,
  //   filter: "numeric",
  //   editable: false,
  //   required: false,
  //   canBeSaved: true,
  //   defaultValue: "[grdMain.TypeID]"
  // },
  {
    field: "TypeCompanyID",
    title: "TypeCompanyID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    defaultValue: -1
  },
  {
    field: "CompanyID",
    title: "CompanyID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    company: true,
    systemHidden: true
  },
  {
    field: "CompanyName",
    title: "Company",
    titlePlural: "Companies",
    width: "350px",
    maxLength: 50,
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    canBeSaved: false,
    companyMaster: true,
    valueField: "CompanyID"
  }
];
