// Base
import { FC, useState } from "react";
import styled from "styled-components";

// Grommet/Kendo Components
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Box } from "grommet";

// Components Custom
import OctopusLoader from "../OctopusLoader";

// Types/Constants
import { NodeHistoriesRestoreContract } from "../../../types";
import { CarbonIcons } from "../../../constants";

// Hooks
import useNodeHistoryRestoretApi from "../../../hooks/nodes/useNodeHistoryRestore";

interface IConfirmationDialogProps {
  nodeId: string;
  nodeProcessUpdateHistoryId: number;
  onAcceptCallback: () => void;
  onRejectCallback: () => void;
}

const NodeHistoryRestoreDialog: FC<IConfirmationDialogProps> = ({
  nodeId,
  nodeProcessUpdateHistoryId,
  onAcceptCallback,
  onRejectCallback
}) => {
  const [textAndPricesChecked, setTextAndPricesChecked] = useState(false);
  const [schedulesChecked, setSchedulesChecked] = useState(false);
  const { postNodeHistoriesRestore, isLoading } = useNodeHistoryRestoretApi();

  const onClickRestoreButton = async () => {
    const payload: NodeHistoriesRestoreContract = {
      NodeProcessUpdateHistoryId: nodeProcessUpdateHistoryId,
      RestoreItems: textAndPricesChecked ? "Y" : "N",
      RestoreSchedules: schedulesChecked ? "Y" : "N"
    };

    await postNodeHistoriesRestore(nodeId, payload);

    onAcceptCallback();
  };

  return (
    <Dialog
      title={
        <h1>
          {CarbonIcons.Restore}
          <br />
          {"Restore Node History"}
        </h1>
      }
      closeIcon={false}
    >
      <Box style={{ position: "relative" }}>
        {isLoading && <OctopusLoader />}
        <StyledBox
          border="bottom"
          pad={{ right: "xlarge", left: "small", bottom: "small" }}
        >
          <h1>Select items to restore:</h1>
          <Box
            gap="small"
            pad={{ left: "medium", top: "xsmall", bottom: "small" }}
            style={{ justifyContent: "center" }}
          >
            <Checkbox
              label="Schedules"
              value={schedulesChecked}
              onChange={() => setSchedulesChecked(!schedulesChecked)}
            />
            <Checkbox
              label="Text & Prices"
              value={textAndPricesChecked}
              onChange={() => setTextAndPricesChecked(!textAndPricesChecked)}
            />
          </Box>
        </StyledBox>
        <DialogActionsBar>
          <Button onClick={onRejectCallback}>
            {CarbonIcons.Close}
            {"Cancel"}
          </Button>
          <Button
            primary={true}
            onClick={onClickRestoreButton}
            disabled={!(textAndPricesChecked || schedulesChecked)}
          >
            {CarbonIcons.Check}
            {"Apply"}
          </Button>
        </DialogActionsBar>
      </Box>
    </Dialog>
  );
};

const StyledBox = styled(Box)`
  h1 {
    font-size: 20px;
    opacity: 0.5;
  }
`;

export default NodeHistoryRestoreDialog;
