import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  {
    field: "SeqNum",
    title: "Seq. No.",
    width: "82px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: true,
    canBeSaved: true,
    minValue: 0
  },
  {
    field: "ZoneName",
    title: "Zone",
    width: "219px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    maxLength: 50,
    canBeSaved: true
  },
  {
    field: "HSize",
    title: "Width",
    width: "113px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: true,
    canBeSaved: true,
    defaultValue: 0,
    minValue: 0
  },
  {
    field: "VSize",
    title: "Height",
    width: "117px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: true,
    canBeSaved: true,
    defaultValue: 0,
    minValue: 0
  },
  {
    field: "HOffset",
    title: "Hor. Offset",
    width: "113px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: true,
    canBeSaved: true,
    defaultValue: 0,
    minValue: 0
  },
  {
    field: "VOffset",
    title: "Vert. Offset",
    width: "114px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: true,
    canBeSaved: true,
    defaultValue: 0,
    minValue: 0
  },
  {
    field: "ZIndex",
    title: "Z Index",
    width: "82px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: true,
    canBeSaved: true,
    defaultValue: 0,
    minValue: 0,
    maxValue: 999
  },
  {
    field: "Alpha",
    title: "Alpha",
    width: "89px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: true,
    canBeSaved: true,
    defaultValue: 100,
    minValue: 0,
    maxValue: 100
  },
  {
    field: "ContentType",
    title: "Content Type",
    width: "121px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "dropdown",
    required: true,
    canBeSaved: true,
    defaultValue: "M",
    dropItems: [
      {
        value: "M",
        text: "Media"
      },
      {
        value: "P",
        text: "Package"
      }
    ]
  },
  {
    field: "ZoneContentFilter",
    title: "Content Filter",
    width: "169px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "combinedcolumn",
    required: false,
    canBeSaved: false
  },
  {
    field: "ZonePreviewContent",
    title: "Content Preview",
    width: "279px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "combinedcolumn",
    required: false,
    canBeSaved: false
  },
  {
    field: "PlaybackStyle",
    title: "Playback Style",
    width: "250px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "dropdown",
    required: true,
    canBeSaved: true,
    defaultValue: "ONE",
    dropItems: [
      {
        value: "ONE",
        text: "Play One"
      },
      {
        value: "ALL",
        text: "Play All"
      }
    ]
  },
  {
    field: "MediaBehavior",
    title: "Media Behavior",
    width: "250px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "dropdown",
    required: true,
    canBeSaved: true,
    defaultValue: "N--",
    dropItems: [
      {
        value: "N--",
        text: "(1) None"
      },
      {
        value: "SA-",
        text: "(1) Static, (2) Animated"
      },
      {
        value: "AS-",
        text: "(1) Animated, (2) Static"
      },
      {
        value: "SAS",
        text: "(1) Static, (2) Animated, (3) Static"
      }
    ]
  },
  {
    field: "StaticDisplayTime",
    title: "Static Display Time",
    width: "150px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: true,
    canBeSaved: true,
    defaultValue: 10,
    minValue: 0
  },
  {
    field: "HideZoneWhenInactive",
    title: "Hide When Inactive",
    width: "250px",
    defaultShow: true,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true,
    defaultValue: false
  },
  {
    field: "HideOtherZonesWhenActive",
    title: "Hide Other Zones When Active",
    width: "250px",
    defaultShow: true,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true,
    defaultValue: false
  },
  {
    field: "RandomizeContentOrder",
    title: "Randomize Order",
    width: "250px",
    defaultShow: true,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true,
    defaultValue: false
  },
  {
    field: "TemplateZoneID",
    title: "ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: -1
  },
  // {
  //   field: "TemplateID",
  //   title: "TemplateID",
  //   width: "150px",
  //   defaultShow: false,
  //   filter: "numeric",
  //   editable: false,
  //   required: false,
  //   canBeSaved: true
  // },
  {
    field: "ZoneDesc",
    title: "Description",
    width: "200px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 256,
    canBeSaved: true
  },
  {
    field: "FilterMediaTypeID",
    title: "FilterMediaTypeID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "FilterMediaTypeName",
    fieldNameInLookup: "MediaTypeName",
    title: "FilterMediaTypeName",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    valueField: "FilterMediaTypeID",
    valueFieldInLookup: "MediaTypeID",
    canBeSaved: false,
    combinedCol: "ZoneContentFilter",
    controlField: "ContentType",
    controlValue: "M"
  },
  {
    field: "FilterPackageTypeID",
    title: "FilterPackageTypeID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "FilterPackageTypeName",
    fieldNameInLookup: "PackageTypeName",
    title: "FilterPackageTypeName",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    valueField: "FilterPackageTypeID",
    valueFieldInLookup: "PackageTypeID",
    canBeSaved: false,
    combinedCol: "ZoneContentFilter",
    controlField: "ContentType",
    controlValue: "P"
  },
  {
    field: "PreviewContentMediaID",
    title: "PreviewContentMediaID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "PreviewContentMediaName",
    fieldNameInLookup: "MediaName",
    title: "PreviewContentMediaName",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    valueField: "PreviewContentMediaID",
    valueFieldInLookup: "MediaID",
    canBeSaved: false,
    combinedCol: "ZonePreviewContent",
    controlField: "ContentType",
    controlValue: "M",
    lookupFilterCol: "FilterMediaTypeID",
    lookupFilterDataName: "MediaTypeID"
  },
  {
    field: "PreviewContentPackageID",
    title: "PreviewContentPackageID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "PreviewContentPackageName",
    fieldNameInLookup: "PackageName",
    title: "PreviewContentPackageName",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    valueField: "PreviewContentPackageID",
    valueFieldInLookup: "PackageID",
    canBeSaved: false,
    combinedCol: "ZonePreviewContent",
    controlField: "ContentType",
    controlValue: "P",
    lookupFilterCol: "FilterPackageTypeID",
    lookupFilterDataName: "PackageTypeID"
  }
];
