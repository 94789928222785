import Axios, { AxiosInstance } from "axios";
import { Api, AppLocalStore } from "../constants";

const apiClient: AxiosInstance = Axios.create({
  baseURL: Api.baseUrl,
  headers: {
    "Content-Type": "application/json"
  }
});

apiClient.interceptors.request.use(
  (request) => {
    const authToken = localStorage.getItem(AppLocalStore.Token);

    if (authToken) {
      request.headers.Authorization = `Bearer ${authToken}`;
    }

    return request;
  },
  (error) => {
    // TODO Handle Errors.
    return error;
  }
);

export default apiClient;

// type ApiPostPut = {
//   method: string,
//   data?: { [key: string]: any };
// }

// export const get = (method: string): Promise<AxiosResponse> => {
//   return carbonApi.get(method);
// }

// export const post = ({ method, data = {} }: ApiPostPut): Promise<AxiosResponse> => {
//   return carbonApi.post(method, data);
// }

// export const put = ({ method, data = {} }: ApiPostPut): Promise<AxiosResponse> => {
//   return carbonApi.put(method, data);
// }
