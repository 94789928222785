import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  {
    field: "UserNodeID",
    title: "UserNodeID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: -1
  },
  {
    field: "UserID",
    title: "UserID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "AssignType",
    title: "AssignType",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: true
  },
  {
    field: "NodeID",
    title: "NodeID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "NodeName",
    title: "Node",
    titlePlural: "Nodes",
    width: "500px",
    maxLength: 50,
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: true,
    canBeSaved: false,
    valueField: "NodeID"
  }
];
