import { Box, Text } from "grommet";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { FormInput } from "../../Forms/Login";
import { Button } from "@progress/kendo-react-buttons";
import styled from "styled-components";
import { FC, useState } from "react";
import { ForgotPasswordContract, Result } from "../../../types";
import { ResultTypes, CarbonIcons } from "../../../constants";
import useForgotPassword from "../../../hooks/credentials/useForgotPassword";

interface IForgotPasswordProps {
  setRenderForgotPassword: any;
}

const ForgotPassword: FC<IForgotPasswordProps> = ({
  // eslint-disable-next-line react/prop-types
  setRenderForgotPassword
}) => {
  const { forgotPassword } = useForgotPassword();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (formValues: any) => {
    const forgotPasswordContract: ForgotPasswordContract = {
      companyCode: formValues.company,
      userName: formValues.username,
      email: formValues.email
    };

    const forgotPasswordResult: Result<boolean> = await forgotPassword(
      forgotPasswordContract
    );
    if (forgotPasswordResult.type === ResultTypes.Success) {
      console.log("good Password Reset");
    } else {
      console.log("bad Password Reset");
    }

    setIsSubmitted(true);
  };

  return (
    <>
      {isSubmitted ? (
        <>
          <Box width="medium" pad={{ bottom: "large" }}>
            <Text style={{ fontSize: 26 }}>Password Request Sent</Text>
          </Box>
          <div>
            <Box width="medium" pad={{ bottom: "medium" }}>
              <Text>
                You will receive a temporary password sent to your email.
                <br />
                <br />
                If you do not receive a temporary password, please try again or
                contact our Support Team.
              </Text>
            </Box>
          </div>
          <Box width="medium" align="center" pad={{ bottom: "250px" }}>
            <BackToLoginButton
              type={"button"}
              onClick={setRenderForgotPassword}
            >
              Back to Login
            </BackToLoginButton>
          </Box>
        </>
      ) : (
        <>
          <Box width="100%" pad={{ bottom: "large" }}>
            <Text style={{ fontSize: 26 }}>Reset Password</Text>
          </Box>
          <div>
            <Box width="100%" pad={{ bottom: "small" }}>
              <Text>
                To reset your password, enter either your Company Code and
                Username or enter your Email.
              </Text>
            </Box>
          </div>
          <Form
            onSubmit={handleSubmit}
            render={(formRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <Field
                  width="xsmall"
                  icon={<StyledIcon>{CarbonIcons.CompanyCode}</StyledIcon>}
                  id={"company"}
                  name={"company"}
                  label={"Company Code"}
                  maxLength={4}
                  style={{ textTransform: "uppercase" }}
                  required={!formRenderProps.valueGetter("email")}
                  component={FormInput}
                />
                <Field
                  width="100%"
                  icon={<StyledIcon>{CarbonIcons.User}</StyledIcon>}
                  id={"username"}
                  name={"username"}
                  label={"Username"}
                  maxLength={50}
                  required={!formRenderProps.valueGetter("email")}
                  component={FormInput}
                />
                <Box width="medium" align="center" pad={{ top: "small" }}>
                  <Text>- OR -</Text>
                </Box>
                <Box margin={{ top: "-28px" }}>
                  <Field
                    width="100%"
                    icon={<StyledIcon>{CarbonIcons.Mail}</StyledIcon>}
                    id={"email"}
                    name={"email"}
                    label={"Email"}
                    fieldType="email"
                    maxLength={50}
                    required={
                      !(
                        formRenderProps.valueGetter("company") &&
                        formRenderProps.valueGetter("username")
                      )
                    }
                    component={FormInput}
                  />
                </Box>

                <Box
                  direction="row"
                  justify="around"
                  pad={{ top: "medium" }}
                  width="100%"
                >
                  <BackToLoginButton
                    type={"button"}
                    onClick={setRenderForgotPassword}
                  >
                    Back to Login
                  </BackToLoginButton>
                  <ResetPasswordButton
                    type={"submit"}
                    disabled={
                      // If the (CompanyCode AND Username) fields OR the Email field is filled out, button will enable
                      !(
                        ((formRenderProps.valueGetter("company") &&
                          formRenderProps.valueGetter("username")) ||
                          formRenderProps.valueGetter("email")) &&
                        formRenderProps.allowSubmit
                      )
                    }
                  >
                    Reset Password
                  </ResetPasswordButton>
                </Box>
              </FormElement>
            )}
          />
        </>
      )}
    </>
  );
};

const ResetPasswordButton = styled(Button)`
  background-color: var(--carbon-orange);
  width: 40%;
  border-radius: 30px;
  color: var(--carbon-white);
  font-weight: bold;
`;

const BackToLoginButton = styled(ResetPasswordButton)`
  border: 2px solid;
  background-color: var(--carbon-white);
  border-color: var(--carbon-orange);
  color: var(--carbon-orange);
`;

const StyledIcon = styled(Box)`
  span[class^="material-icons"] {
    color: var(--carbon-orange);
  }
`;

export default ForgotPassword;
