import { Loader } from "@progress/kendo-react-indicators";
import { Box } from "grommet";

const OctopusLoader = (): JSX.Element => {
  return (
    <Box
      basis="1/2"
      pad="large"
      justify="center"
      align="center"
      style={{
        position: "absolute",
        height: "100%",
        width: "100%",
        zIndex: 99
      }}
    >
      <Loader size="large" type="converging-spinner" />
    </Box>
  );
};

export default OctopusLoader;
