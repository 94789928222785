import { Helmet } from "react-helmet-async";
import { useLocation, useParams } from "react-router-dom";
import { Box } from "grommet";
import { useState, useEffect } from "react";

// Components
import CarbonGridToolbar, {
  GridToolbarActionLabels,
  GridToolbarItem
} from "../../components/CarbonGrid/Toolbar/CarbonGridToolbar";
import CarbonTabStrip from "../../components/CarbonTabs/CarbonTabStrip";
import CarbonGrid from "../../components/CarbonGrid/CarbonGrid";
import Authorizer from "../../components/Util/Authorizer";
import NodeExportDialog from "../../components/Util/Dialogs/NodeItemsExportDialog";

// Types & Constants
import { GridType } from "../../types/grid";
import getTabs from "./NodesTabs";
import { gridColumns } from "./NodeItemsGridColumns";
import {
  EndpointResources,
  Placeholders,
  UserPermissions,
  GridIDs,
  CarbonIcons,
  GridActions
} from "../../constants";
import { GridActionBarItem } from "../../components/CarbonGrid/Toolbar/CarbonGridRowActionBar";

// Hooks
import { useGrid } from "../../contexts/grid/useGrid";
import { useStore } from "../../contexts/store";
import CarbonBreadcrumb from "../../components/Breadcrumb/CarbonBreadcrumb";

const NodeItems = (): JSX.Element => {
  const [showExportDialog, setShowExportDialog] = useState(false);
  const [nodeName, setNodeName] = useState("");
  const [parentCompanyId, setParentCompanyId] = useState("");
  const [nodeItemId, setNodeItemId] = useState<number | null>(null);

  const { pathname } = useLocation();
  const { nodeId } = useParams();
  const { store } = useStore();
  const gridName = GridIDs.NodeItems;
  const tabs = getTabs(EndpointResources.Nodes, nodeId!);
  const pagePermission = UserPermissions.CanManageNodePricing;
  const { grids, setGrid } = useGrid();

  const toggleDialog = () => {
    setShowExportDialog(!showExportDialog);
  };

  useEffect(() => {
    const nodeItemsGrid = grids.get(GridIDs.NodeItems);
    if (nodeItemsGrid) {
      if (nodeItemsGrid.state.selectedRowData) {
        setNodeItemId(nodeItemsGrid.state.selectedRowData?.NodeItemID);
      }
    }
  }, [grids.get(GridIDs.NodeItems)]);

  useEffect(() => {
    setGrid({
      type: GridActions.toggleRefreshGrid,
      payload: { gridId: gridName, gridData: true }
    });
  }, [pathname]);

  const nodesGrid = grids.get(GridIDs.Nodes);
  let nodesTitle = "Nodes";

  if (nodesGrid) {
    if (store.activeNodeGroup?.nodeGroupName) {
      nodesTitle = store.activeNodeGroup?.nodeGroupName;
    }
  }

  const currentTab = {
    index: tabs.findIndex((tab) => tab.resourceEndpoint === pathname),
    tab: tabs.find((tab) => tab.resourceEndpoint === pathname)
  };

  const actionLabels: GridToolbarActionLabels = {
    singular: "node item",
    plural: "node items",
    titleSingular: "Node Item",
    titlePlural: "Node Items"
  };

  const rowActions: GridActionBarItem[] = [
    {
      show: true,
      label: "Export Text & Price",
      includeInExtrasMenu: true,
      icon: CarbonIcons.Copy,
      onClick: toggleDialog
    }
  ];

  // jon, 1/25/22: Reset actions on page load
  useEffect(() => {
    setGrid({
      type: GridActions.setGridActions,
      payload: {
        gridId: gridName,
        gridData: { actions: rowActions }
      }
    });
  }, []);

  const gridSettings: GridType = {
    actions: rowActions,
    endpoints: {
      gridODataEndpoint: `/odata/${Placeholders.companyID}/${Placeholders.nodeTabNodeId}/gridnodeitems?$count=true&`,
      gridApiEndpoint: `/api/${Placeholders.nodeTabNodeId}/nodeitems`,
      parentGridApiEndpoint: `/api/${Placeholders.companyID}/${EndpointResources.Nodes}/${Placeholders.nodeTabNodeId}`
    },
    records: [],
    total: 0,
    data: [],
    lookups: {},
    dataState: {
      pageSize: 25,
      take: 50,
      skip: 0,
      sort: [{ field: "ItemName", dir: "asc" }]
    },
    dataItemKey: "ItemID",
    singularEntityName: actionLabels.singular,
    pluralEntityName: actionLabels.plural,
    columns: gridColumns,
    showCopyBtn: false,
    showDeleteBtn: false,
    parentGridId: GridIDs.Nodes,
    replaceRowAfterUpdate: false,
    state: {
      editMode: false,
      insertMode: false,
      selectedState: {},
      selectedRow: -1,
      showQuickFilter: false,
      lastSaveDate: null,
      showSavingIndicator: false,
      lockoutMode: false
    }
  };

  const addBtn: GridToolbarItem = { show: false, enable: false, tooltip: "" };

  const previewBtn: GridToolbarItem = {
    show: true,
    enable: true,
    tooltip: "Open the viewer to preview node schedule",
    toggledOn: false
  };

  return (
    <>
      <Helmet>
        <title>Carbon | Node Text & Prices</title>
      </Helmet>
      <Authorizer
        canView={pagePermission}
        auditLabel={`${actionLabels.titlePlural} screen`}
      >
        {showExportDialog && (
          <NodeExportDialog
            nodeName={nodeName}
            nodeId={nodeId}
            companyId={parentCompanyId}
            nodeItemId={nodeItemId !== null ? nodeItemId : -1}
            acceptText="Submit"
            onAcceptCallback={toggleDialog}
            onRejectCallback={toggleDialog}
          />
        )}
        <Box align="start" fill>
          <CarbonBreadcrumb
            backLinkTitle={nodesTitle}
            backLinkUrl="/app/nodes"
            pageTitle={currentTab.tab?.tabTitle ?? ""}
          />
          <CarbonGridToolbar
            gridId={gridName}
            screenIcon={CarbonIcons.Items}
            getScreenTitleFromParentRow={(rowData: { [key: string]: any }) => {
              setNodeName(rowData.NodeName);
              return rowData.NodeName;
            }}
            getScreenSubtitleFromParentRow={(rowData: {
              [key: string]: any;
            }) => {
              setParentCompanyId(rowData.CompanyID);
              return rowData.CompanyName;
            }}
            permissions={pagePermission}
            actionLabels={actionLabels}
            addBtn={addBtn}
            publishBtn={true}
            previewBtn={previewBtn}
          />
          <CarbonTabStrip carbonTabs={tabs} selectedTab={currentTab.index} />{" "}
          <Box fill className="carbon-grid-wrapper">
            <CarbonGrid gridId={gridName} gridSettings={gridSettings} />
          </Box>
        </Box>
      </Authorizer>
    </>
  );
};

export default NodeItems;
