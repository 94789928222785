import styled from "styled-components";

export const StyledBodyContent = styled.div`
  box-shadow: 2px 2px 8px var(--carbon-griddropshadow);
  background-color: #fff;
  border-radius: 6px;
  border-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  padding: 29px;
`;

export const StyledContentH2 = styled.h2`
  display: flex;
  width: 100%;
  justify-content: start;
`;

export const StyledContentH3 = styled.h3<{ margin?: string }>`
  font-size: 18px;
  color: var(--carbon-black);
  font-weight: 600;
  { ${(props) => (props.margin ? `margin: ${props.margin}` : "")} 
`;

export const StyledParagraph = styled.p``;
