import { EndpointResources } from "../../constants";

const getTabs = (parent: string, id: string) => {
  const tabs = [
    {
      resourceEndpoint: `/app/${parent}/${id}/${EndpointResources.UserManagedCompanies}`,
      tabTitle: "Managed Companies"
    },
    {
      resourceEndpoint: `/app/${parent}/${id}/${EndpointResources.UserNodeAssignment}`,
      tabTitle: "Node Assignments"
    }
  ];

  return tabs;
};

export default getTabs;
