import dayjs from "dayjs";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { Box, Grid } from "grommet";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { useGrid } from "../../../contexts/grid/useGrid";
import {
  GridActions,
  UserPermissions,
  CarbonIcons,
  GridIDs,
  StoreActions,
  Api
} from "../../../constants";
import { useEffect, useLayoutEffect, useState, FC } from "react";
import usePermissions from "../../../hooks/auth/usePermissions";
import Previewer from "../../Previewer/Previewer";
import NodeFilterDropDown from "../../../pages/Nodes/NodeFilterDropDown";
import { useStore } from "../../../contexts/store";
import EditIcon from "@material-ui/icons/Edit";
import { useCrossTabState } from "../../../hooks/utils/useCrossTabState";
import NodePublishDialog from "../../Util/Dialogs/NodePublishDialog";
import {
  DatePicker,
  DatePickerChangeEvent
} from "@progress/kendo-react-dateinputs";
import { useDayGroups } from "../../../hooks/useDayGroups";
import { Tooltip } from "@progress/kendo-react-tooltip";

export type GridToolbarItem = {
  show: boolean;
  enable: boolean;
  tooltip: string;
  labelOverride?: string;
  iconOverride?: any;
  toggledOn?: boolean;
  onClick?: () => void;
};

export type GridToolbarActionLabels = {
  singular: string;
  plural: string;
  titleSingular: string;
  titlePlural: string;
};

export interface GridToolbarProps {
  gridId: string;
  screenIcon: any;
  screenTitle?: string;
  screenSubtitle?: string;
  getScreenTitleFromParentRow?: (rowData: { [key: string]: any }) => string;
  getScreenSubtitleFromParentRow?: (rowData: { [key: string]: any }) => string;
  permissions: UserPermissions;
  actionLabels: GridToolbarActionLabels;
  publishBtn?: boolean;
  saveBtn?: GridToolbarItem;
  cancelBtn?: GridToolbarItem;
  previewBtn?: GridToolbarItem;
  exportBtn?: GridToolbarItem;
  addBtn?: GridToolbarItem;
  editModeBtn?: GridToolbarItem;
  quickFilterBtn?: GridToolbarItem;
  filterDropdown?: GridToolbarItem;
  isFileDownloading?: boolean;
}

// Tooltips do not show on disabled buttons so using wrappers below around each button
const CarbonGridToolbar: FC<GridToolbarProps> = (props) => {
  const { nodeId } = useParams();
  const { grids, setGrid } = useGrid();
  const { dispatch: storeDispatch, store } = useStore();
  const { canInsert, canUpdate } = usePermissions();
  const [isGridLockedOut, setIsGridLockedOut] = useState<boolean>(false);
  const { activeDayPart } = useDayGroups();
  const [publishDialogRowData, setPublishDialogRowData] =
    useState<{ [key: string]: any }>();
  const [showPublishDialog, setShowPublishDialog] = useState(false);
  const [showPreviewDateField, setShowPreviewDateField] = useState(false);
  // const [nodeProcessingLockedout, setNodeProcessingLockedout] = useState(true);
  const [publishBtn, setPublishBtn] = useState<GridToolbarItem>({
    show: false,
    enable: true,
    tooltip: ""
  });
  const [screenTitle, setScreenTitle] = useState<string | undefined>(
    props.screenTitle
  );
  const [screenSubtitle, setScreenSubtitle] = useState<string | undefined>(
    props.screenSubtitle
  );
  /* eslint-disable */
  // this will only work for crosstab state, it will not trigger stateful changes
  // on same browser window
  const [previewerUrl, setPreviewerUrl] = useCrossTabState("previewerUrl", "");
  /* eslint-enable */
  const [nodesPublishingLocked, setNodesPublishingLocked] = useState(true);

  // clean up and onload actions
  // useeffect to render the previewer date field visible when appropriate
  useEffect(() => {
    if (
      props.gridId === GridIDs.NodeTemplates ||
      props.gridId === GridIDs.NodeItems ||
      props.gridId === GridIDs.NodeHistory ||
      props.gridId === GridIDs.NodeDayParts ||
      props.gridId === GridIDs.NodePatchHistory ||
      props.gridId === GridIDs.NodeMovieCodes ||
      props.gridId === GridIDs.NodeDisplays ||
      props.gridId === GridIDs.NodeVideoCards
    ) {
      setShowPreviewDateField(true);
    }

    return () => {
      // clean up. close any previewer popup window and close embedded window
      storeDispatch({
        type: StoreActions.showPreviewerPopup,
        payload: { value: false }
      });

      setGrid({
        type: GridActions.togglePreviewer,
        payload: {
          gridId: props.gridId,
          gridData: { value: false }
        }
      });
    };
  }, []);

  // Toolbar Default States
  // NOTE: Publish Button is only usable for Nodes screen and subscreens
  useEffect(() => {
    if (props.publishBtn) {
      let setPublishButton: boolean = false;
      let publishRowData: { [key: string]: any } = {};

      if (props.gridId === GridIDs.Nodes) {
        if (grids.get(props.gridId)?.state.selectedRowData) {
          setPublishButton = true;
          publishRowData = grids.get(props.gridId)?.state.selectedRowData!;
          setPublishDialogRowData(publishRowData);
        }
      } else if (
        props.gridId === GridIDs.NodeTemplates ||
        props.gridId === GridIDs.NodeItems ||
        props.gridId === GridIDs.NodeHistory ||
        props.gridId === GridIDs.NodeDayParts ||
        props.gridId === GridIDs.NodePatchHistory ||
        props.gridId === GridIDs.NodeMovieCodes ||
        props.gridId === GridIDs.NodeDisplays ||
        props.gridId === GridIDs.NodeVideoCards
      ) {
        if (publishDialogRowData) {
          setPublishButton = true;
          publishRowData = publishDialogRowData;
        }
      }

      if (setPublishButton) {
        setPublishBtn({
          show: true,
          enable: true,
          tooltip: "Publish schedule",
          onClick: () => {
            if (publishRowData!.VigilixID) {
              setShowPublishDialog(true);
            } else {
              storeDispatch({
                type: StoreActions.addNotification,
                payload: {
                  message:
                    "A PC ID must be assigned to this node before updates can be processed",
                  messageType: "warning",
                  closable: true
                }
              });
            }
          }
        });
      }
    }
  }, [publishDialogRowData, grids.get(props.gridId)?.state.selectedRowData]);

  const cancelBtn: GridToolbarItem = props.cancelBtn
    ? props.cancelBtn
    : {
        show: false,
        enable: canInsert(props.permissions),
        tooltip: canInsert(props.permissions)
          ? `Cancel creation of new ${props.actionLabels.singular}`
          : `You do not have permission to create a new ${props.actionLabels.singular}.`
      };

  const saveBtn: GridToolbarItem = props.saveBtn
    ? props.saveBtn
    : {
        show: false,
        enable: canInsert(props.permissions),
        tooltip: canInsert(props.permissions)
          ? `Save the new ${props.actionLabels.singular}`
          : `You do not have permission to create a new ${props.actionLabels.singular}.`
      };

  const previewBtn: GridToolbarItem = props.previewBtn
    ? props.previewBtn
    : {
        show: false,
        enable: true,
        tooltip: `Open the viewer to preview ${props.actionLabels.singular}`,
        toggledOn: false
      };

  const exportBtn: GridToolbarItem = props.exportBtn
    ? props.exportBtn
    : { show: false, enable: true, tooltip: "" };

  const addBtn: GridToolbarItem = props.addBtn
    ? props.addBtn
    : {
        show: true,
        enable: canInsert(props.permissions),
        tooltip: canInsert(props.permissions)
          ? ` Create a new ${props.actionLabels.singular}`
          : `You do not have permission to create a new ${props.actionLabels.singular}.`,
        labelOverride: `Add ${props.actionLabels.titleSingular}`
      };

  const editModeBtn: GridToolbarItem = props.editModeBtn
    ? props.editModeBtn
    : {
        show: true,
        enable: canUpdate(props.permissions),
        tooltip: canUpdate(props.permissions)
          ? `Enable updates to existing ${props.actionLabels.plural}`
          : `You do not have permission to update existing ${props.actionLabels.plural}.`,
        toggledOn: false
      };

  const quickFilterBtn: GridToolbarItem = props.quickFilterBtn
    ? props.quickFilterBtn
    : {
        show: true,
        enable: true,
        tooltip: "Toggle search toolbar in grid for quick filtering",
        toggledOn: false
      };

  const [toggleEditModeBtnOn, setToggleEditModeBtnOn] = useState<boolean>(
    editModeBtn.toggledOn === true
  );
  const [toggleInsertModeBtnOn, setToggleInsertModeBtnOn] = useState<boolean>(
    addBtn.toggledOn === true
  );
  const [toggleQuickFilterBtnOn, setToggleQuickFilterBtnOn] = useState<boolean>(
    quickFilterBtn.toggledOn === true
  );
  const [showSaveBtn, setShowSaveButton] = useState<boolean>(saveBtn.show);
  const [showCancelBtn, setShowCancelButton] = useState<boolean>(
    cancelBtn.show
  );

  const isParentGrid = grids.get(props.gridId)?.detailRow !== undefined;
  const detailGridId =
    grids.get(props.gridId)?.detailRow?.subgridId !== undefined
      ? grids.get(props.gridId)?.detailRow?.subgridId![0]
      : "";

  // Handle last saved and saving indicator for subgrids
  const lastSavedGrid = grids.get(props.gridId)
    ? grids.get(props.gridId)!.state.lastSaveDate
    : null;
  const lastSavedDetailGrid =
    isParentGrid && grids.get(detailGridId!)
      ? grids.get(detailGridId!)!.state.lastSaveDate
      : null;
  let lastSaved = lastSavedGrid;
  if (
    lastSavedDetailGrid !== null &&
    lastSavedGrid !== null &&
    lastSavedDetailGrid >= lastSavedGrid
  ) {
    lastSaved = lastSavedDetailGrid;
  } else if (lastSavedDetailGrid !== null && lastSavedGrid === null) {
    lastSaved = lastSavedDetailGrid;
  }

  let showSavingIndicator: boolean = false;
  if (
    (grids.get(props.gridId) &&
      grids.get(props.gridId)!.state.showSavingIndicator) ||
    (isParentGrid &&
      grids.get(detailGridId!) &&
      grids.get(detailGridId!)!.state.showSavingIndicator)
  ) {
    showSavingIndicator = true;
  }

  // Effect runs when the Edit toggle button is clicked so we can toggle the state of the button.
  useEffect(() => {
    if (grids.get(props.gridId)) {
      let isDetailGridInEditMode: boolean = false;

      // If this grid has a detail grid and it is in edit mode, show Edit Mode. Basically, if either grid is in edit mode, show Edit Mode.
      const isDetailGridShowing =
        grids.get(props.gridId)?.state.selectedRowIsExpanded ?? false;

      if (isParentGrid && isDetailGridShowing) {
        isDetailGridInEditMode =
          grids.get(detailGridId!)?.state.editMode ?? false;
      }

      setToggleEditModeBtnOn(
        isDetailGridInEditMode || grids.get(props.gridId)!.state.editMode
      );
    }
  }, [
    grids.get(props.gridId)?.state.editMode,
    grids.get(detailGridId!)?.state.editMode
  ]);

  // Effect runs when detail grid is expanded
  useLayoutEffect(() => {
    if (grids.get(props.gridId)) {
      const isDetailGridShowing =
        grids.get(props.gridId)?.state.selectedRowIsExpanded ?? false;

      if (isParentGrid) {
        const isDetailGridInEditMode =
          grids.get(detailGridId!)?.state.editMode ?? false;
        const isParentGridInEditMode =
          grids.get(props.gridId)?.state.editMode ?? false;

        // If parent grid is in edit mode, and subgrid is not, put subgrid in edit mode
        if (
          isParentGridInEditMode &&
          !isDetailGridInEditMode &&
          isDetailGridShowing &&
          grids.get(detailGridId!)
        ) {
          setGrid({
            type: GridActions.toggleEditMode,
            payload: { gridId: detailGridId!, gridData: {} }
          });
        }

        // If parent grid is in not in edit mode, but subgrid is, put parent in edit mode if subgrid not showing.
        else if (
          isDetailGridInEditMode &&
          !isDetailGridShowing &&
          !isParentGridInEditMode
        ) {
          setGrid({
            type: GridActions.toggleEditMode,
            payload: { gridId: props.gridId, gridData: {} }
          });
        }
      }
    }
  }, [
    grids.get(props.gridId)?.state.selectedRowIsExpanded,
    grids.get(detailGridId ?? "")
  ]);

  // Effect runs when the Insert toggle button is clicked so we can toggle the state of the button.
  useEffect(() => {
    if (grids.get(props.gridId)) {
      setToggleInsertModeBtnOn(grids.get(props.gridId)!.state.insertMode);

      if (grids.get(props.gridId)!.state.insertMode === true) {
        setShowSaveButton(true);
        setShowCancelButton(true);
      } else {
        setShowSaveButton(saveBtn.show);
        setShowCancelButton(cancelBtn.show);
      }
    }
  }, [grids.get(props.gridId)?.state.insertMode]);

  // Effect runs when the Quick Filter toggle button is clicked so we can toggle the state of the button.
  useEffect(() => {
    if (grids.get(props.gridId)) {
      setToggleQuickFilterBtnOn(grids.get(props.gridId)!.state.showQuickFilter);
    }
  }, [grids.get(props.gridId)?.state.showQuickFilter]);

  useEffect(() => {
    if (
      grids.get(props.gridId)?.state.showPreviewer &&
      store.showPreviewerPopup
    ) {
      // close popup if user switches screens to a screen with an open internal previewer
      // setGrid({
      //   type: GridActions.togglePreviewer,
      //   payload: { gridId: props.gridId, gridData: {} }
      // });
      storeDispatch({
        type: StoreActions.showPreviewerPopup,
        payload: { value: true }
      });
    }
  }, [grids.get(props.gridId)?.state.showPreviewer]);

  // Effect runs when the Previewer toggle button is clicked so we can toggle the state of the button and show the previewer.
  //  Also runs when any of the rowdata values change so the previewer can be updated - like when user clicks a new row in the grid.
  useEffect(() => {
    if (grids.get(props.gridId)) {
      // Previewer is on. Build props for it from the selected grid row - if there is one
      let rowData = grids.get(props.gridId)!.state.selectedRowData;
      const parentSelectedRowData = grids.get(props.gridId)?.state
        ?.parentSelectedRowData;

      // Previewer for Node Tab screens rely on the Nodes grid data
      if (
        props.gridId === GridIDs.NodeTemplates ||
        props.gridId === GridIDs.NodeItems ||
        props.gridId === GridIDs.NodeHistory ||
        props.gridId === GridIDs.NodeDayParts ||
        props.gridId === GridIDs.NodePatchHistory ||
        props.gridId === GridIDs.NodeMovieCodes ||
        props.gridId === GridIDs.NodeDisplays ||
        props.gridId === GridIDs.NodeVideoCards
      )
        rowData = { NodeID: nodeId, ...rowData };

      if (!rowData) return;

      let previewType: string = "";
      let id: number = -1;
      let companyId: number = -1;
      let weekDay: string | undefined;
      let offset: number | undefined;
      let dayPartId: number | undefined;

      switch (props.gridId) {
        case GridIDs.Nodes:
          previewType = "node";
          id = rowData.NodeID;
          companyId = rowData.CompanyID;
          break;
        case GridIDs.NodeTemplates:
          previewType = "node";
          id = rowData.NodeID;
          companyId =
            grids.get(props.gridId)?.state.parentSelectedRowData?.CompanyID ??
            -1;
          weekDay = rowData.ScheduleWkDay;
          offset = rowData.OffsetMinutes;
          dayPartId = activeDayPart?.id;
          break;
        case GridIDs.NodeItems:
          previewType = "node";
          id = rowData.NodeID;
          companyId =
            grids.get(props.gridId)?.state.parentSelectedRowData?.CompanyID ??
            -1;
          break;
        case GridIDs.NodeHistory:
          previewType = "node";
          id = rowData.NodeID;
          companyId =
            grids.get(props.gridId)?.state.parentSelectedRowData?.CompanyID ??
            -1;
          break;
        case GridIDs.NodeDayParts:
          previewType = "node";
          id = rowData.NodeID;
          companyId =
            grids.get(props.gridId)?.state.parentSelectedRowData?.CompanyID ??
            -1;
          break;
        case GridIDs.NodePatchHistory:
          previewType = "node";
          id = rowData.NodeID;
          companyId =
            grids.get(props.gridId)?.state.parentSelectedRowData?.CompanyID ??
            -1;
          break;
        case GridIDs.NodeMovieCodes:
          previewType = "node";
          id = rowData.NodeID;
          companyId =
            grids.get(props.gridId)?.state.parentSelectedRowData?.CompanyID ??
            -1;
          break;
        case GridIDs.NodeDisplays:
          previewType = "node";
          id = rowData.NodeID;
          companyId =
            grids.get(props.gridId)?.state.parentSelectedRowData?.CompanyID ??
            -1;
          break;
        case GridIDs.NodeVideoCards:
          previewType = "node";
          id = rowData.NodeID;
          companyId =
            grids.get(props.gridId)?.state.parentSelectedRowData?.CompanyID ??
            -1;
          break;
        case GridIDs.Templates:
          previewType = "template";
          id = rowData.TemplateID;
          companyId = rowData.CompanyID;
          break;
        case GridIDs.TemplateZones:
          previewType = "template";
          id = parentSelectedRowData?.TemplateID;
          companyId = parentSelectedRowData?.CompanyID;
          break;
        case GridIDs.Stencils:
          previewType = "stencil";
          id = rowData.StencilID;
          companyId = rowData.CompanyID;
          break;
        case GridIDs.StencilZones:
          previewType = "stencil";
          id = parentSelectedRowData?.StencilID;
          companyId = parentSelectedRowData?.CompanyID;
          break;
        case GridIDs.Packages:
          previewType = "package";
          id = rowData.PackageID;
          companyId = rowData.CompanyID;
          break;
        case GridIDs.PackageZones:
          previewType = "package";
          id = parentSelectedRowData?.PackageID;
          companyId = parentSelectedRowData?.CompanyID;
          break;
        case GridIDs.MediaLibrary:
          previewType = "media";
          id = rowData.MediaID;
          companyId = rowData.CompanyID;
          break;
        default:
          console.log(`Previewer not supported for grid: ${props.gridId}`);
          return;
      }

      let newPreviewerUrl = `${Api.baseUrl}/api/${store.activeCompany?.companyId}/preview?type=${previewType}&companyid=${companyId}&id=${id}`;

      if (previewType === "node") {
        newPreviewerUrl += `&wd=${weekDay ?? ""}&dt=${
          getCarbonDateString(store.previewDate) ?? ""
        }&dpid=${dayPartId ?? ""}&offset=${offset ?? 0}&refresh=${0}`;
      }

      // set the previewer url for the popup window
      // console.log("rowdata", rowData);
      console.log("newPreviewerUrl", newPreviewerUrl);
      setPreviewerUrl(newPreviewerUrl);

      // set the previewer url for the embedded window
      storeDispatch({
        type: StoreActions.setEmbeddedPreviewerURL,
        payload: { value: newPreviewerUrl }
      });
    }
  }, [
    grids.get(props.gridId)?.state.selectedRowData,
    grids.get(props.gridId)?.state.showPreviewer,
    store.previewDate,
    activeDayPart
  ]);

  useEffect(() => {
    if (
      grids.get(props.gridId)?.state.selectedDayGroupDays &&
      grids.get(props.gridId)!.state.selectedDayGroupDays!.length > 0
    ) {
      // 20842: Change Node Preview Date Behavior - preview date is now set automatically based on the date of the next day in the day group that is >= today in
      //   order to ensure the preview content matches the selected daypart and day group on the Node Schedules screen.
      const newPreviewDate = getDateOfNextDayInDayGroup(
        grids.get(props.gridId)!.state.selectedDayGroupDays!
      );
      storeDispatch({
        type: StoreActions.setPreviewDate,
        payload: {
          value: newPreviewDate
        }
      });
    }
  }, [grids.get(props.gridId)?.state.selectedDayGroupDays]);

  useEffect(() => {
    configureNodesPublishingLockedStatus();
  }, [grids.get(props.gridId)?.state.selectedRowData]);

  // Effect runs when the grid's parent row data is set so the title can be set here. The grid itself now loads the missing data if needed.
  useEffect(() => {
    if (
      grids.get(props.gridId) &&
      grids.get(props.gridId)!.state.parentSelectedRowData
    ) {
      const rowData = grids.get(props.gridId)!.state.parentSelectedRowData!;
      setPublishDialogRowData(rowData);

      // Get title and subtitle from page events using parent row data. Title is not optional.
      if (!props.getScreenTitleFromParentRow) {
        throw new Error(
          "DEVELOPER: You must define getScreenTitleFromParentRow in page grid!"
        );
      }
      const title = props.getScreenTitleFromParentRow(rowData);
      setScreenTitle(title);

      // Subtitle is optional
      if (props.getScreenSubtitleFromParentRow) {
        const subtitle = props.getScreenSubtitleFromParentRow(rowData);
        setScreenSubtitle(subtitle);
      }
    }
  }, [grids.get(props.gridId)?.state.parentSelectedRowData]);

  // Layout Effect runs when grid gets locked out so we can change the disabled state of buttons
  useLayoutEffect(() => {
    if (grids.get(props.gridId)) {
      setIsGridLockedOut(grids.get(props.gridId)!.state.lockoutMode);
    }
  }, [grids.get(props.gridId)?.state.lockoutMode]);

  useLayoutEffect(() => {
    if (document) {
      const gridWrapper = document.querySelector(
        "div.carbon-grid-wrapper"
      ) as HTMLDivElement;
      if (gridWrapper) {
        const newHeight = gridWrapper.offsetHeight - 10;

        setGrid({
          type: GridActions.resizeGrid,
          payload: { gridId: props.gridId, gridData: newHeight }
        });
      }
    }
  }, [grids.get(props.gridId)?.state.showPreviewer]);

  const getDateOfNextDayInDayGroup = (days: string[]): Date => {
    // We are guaranteed to have at least one day in the day group

    // Build a zero-based array for weekdays, starting with Sunday, to match the integer value that comes back from the dayjs().day() function.
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];

    // Starting with today, get the day number and use that to look up the weekday in the array above. If that day is in daygroup, we are done.
    //   Otherwise, we increment to the next day and check again.  We keep going until we find a day in the day group and the resulting date is what we want.
    //   We will only check for days over the next week and stop if not found to avoid an infinite loop, although that should never actually happen.
    let numDaysChecked: number = 0;
    let checkDate = dayjs();
    let checkDay = "";

    while (numDaysChecked < 7) {
      checkDay = weekdays[checkDate.day()];
      if (days.indexOf(checkDay) >= 0) break;

      // Haven't found a day in the day group yet, so increment date and keep checking
      checkDate = checkDate.add(1, "day");
      numDaysChecked++;
    }

    return checkDate.toDate();
  };

  const onClickSaveBtn = async () => {
    // Toggle on the saving indicator. This is caught in an effect in the CarbonGrid to initiate the actual save when in insert mode.
    setGrid({
      type: GridActions.toggleSavingIndicator,
      payload: { gridId: props.gridId, gridData: { show: true } }
    });
  };

  const onClickRefreshBtn = () => {
    // If this grid has a subgrid open, refresh it instead of the parent grid since that causes selcted record to go back to first one and
    //   then subgrid shows under wrong parent.
    const isDetailGridShowing =
      grids.get(props.gridId)?.state.selectedRowIsExpanded ?? false;
    if (isParentGrid && isDetailGridShowing) {
      setGrid({
        type: GridActions.toggleRefreshGrid,
        payload: { gridId: detailGridId!, gridData: true }
      });
    } else {
      setGrid({
        type: GridActions.toggleRefreshGrid,
        payload: { gridId: props.gridId, gridData: true }
      });
    }
  };

  const onClickEditModeBtn = () => {
    setGrid({
      type: GridActions.toggleEditMode,
      payload: { gridId: props.gridId, gridData: {} }
    });
  };

  const onClickInsertModeBtn = (isOn: boolean) => {
    setGrid({
      type: GridActions.toggleInsertMode,
      payload: {
        gridId: props.gridId,
        gridData: { insertOn: isOn, data: null }
      }
    });
  };

  const onClickQuickFilterBtn = () => {
    setGrid({
      type: GridActions.toggleQuickFilter,
      payload: { gridId: props.gridId, gridData: {} }
    });
  };

  const onClickPreviewBtn = () => {
    setGrid({
      type: GridActions.togglePreviewer,
      payload: {
        gridId: props.gridId,
        gridData: { value: !grids.get(props.gridId)?.state.showPreviewer }
      }
    });
  };

  const configureNodesPublishingLockedStatus = () => {
    if (props.gridId === GridIDs.Nodes) {
      // if this is the nodes page, check selected row data
      const lockedNodesProcessing = grids.get(props.gridId)?.state
        .selectedRowData?.LockoutNodeProcessing;
      // console.log(
      //   "selected row ",
      //   grids.get(props.gridId)?.state.selectedRowData
      // );
      if (lockedNodesProcessing === "Y") {
        setNodesPublishingLocked(true);
      } else {
        setNodesPublishingLocked(false);
      }
    } else if (
      props.gridId === GridIDs.NodeTemplates ||
      props.gridId === GridIDs.NodeItems ||
      props.gridId === GridIDs.NodeHistory ||
      props.gridId === GridIDs.NodeDayParts ||
      props.gridId === GridIDs.NodePatchHistory ||
      props.gridId === GridIDs.NodeMovieCodes ||
      props.gridId === GridIDs.NodeDisplays ||
      props.gridId === GridIDs.NodeVideoCards
    ) {
      // else, this is a node sub screen, check the parent's selected data
      const lockedNodesProcessing = grids.get(props.gridId)?.state
        .parentSelectedRowData?.LockoutNodeProcessing;
      // console.log(
      //   "parent data ",
      //   grids.get(props.gridId)?.state.parentSelectedRowData
      // );
      if (lockedNodesProcessing === "Y") {
        setNodesPublishingLocked(true);
      } else {
        setNodesPublishingLocked(false);
      }
    }
  };

  // will, 3/14/22: Added edit mode check to publish button disable criteria so users can't publish mid edit
  const saveArea = (
    <Box direction="row-reverse" align="end" gap="small">
      <span title="Refresh grid data from the server and reset sort/filter.">
        <Button onClick={onClickRefreshBtn} disabled={isGridLockedOut}>
          {CarbonIcons.Refresh}
          Reload
        </Button>
      </span>
      {publishBtn.show && (
        <StyledTooltip openDelay={200} position="top" anchorElement="target">
          <span
            title={
              nodesPublishingLocked
                ? "Node cannot be processed while schedule changes are being made."
                : publishBtn.tooltip
            }
          >
            <Button
              disabled={
                nodesPublishingLocked ||
                !publishBtn.enable ||
                isGridLockedOut ||
                grids.get(props.gridId)?.state.editMode
              }
              onClick={publishBtn.onClick}
            >
              {CarbonIcons.Publish}
              {publishBtn.labelOverride ? publishBtn.labelOverride : "Publish"}
            </Button>
          </span>
        </StyledTooltip>
      )}
      {showSavingIndicator || props.isFileDownloading === true ? (
        <span className="saving-indicator">
          {props.isFileDownloading === true ? "Downloading file" : "Saving"}
          <Loader size="medium" type="pulsing" />
        </span>
      ) : (
        lastSaved && (
          <span className="last-saved-timestamp">
            Last saved{" "}
            {lastSaved.toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "2-digit",
              second: "2-digit"
            })}
          </span>
        )
      )}
    </Box>
  );

  const ScreenTitle: FC = () => {
    if (props.gridId === GridIDs.Nodes) {
      const activeNodesGroup = store.activeNodeGroup;
      if (activeNodesGroup && activeNodesGroup.nodeGroupId !== -1) {
        // if this is the nodes screen, and there is an active nodes group, return a JSX with the active group name and an edit icon
        return (
          <>
            {activeNodesGroup.nodeGroupName}{" "}
            {canUpdate(UserPermissions.NodeGroupsPerms) && (
              <Button
                className="carbon-toolbar-item-btn"
                style={{ color: "var(--carbon-blue)" }}
                onClick={handleEditNodeGroup}
                title="Edit node group"
              >
                <EditIcon style={{ width: 17, height: 17 }} />
              </Button>
            )}
          </>
        );
      }
    }
    return <>{screenTitle}</>;
  };

  const handleEditNodeGroup = () => {
    storeDispatch({
      type: StoreActions.onEditNodeGroup,
      payload: {
        undefined
      }
    });
  };

  return (
    <>
      {showPublishDialog && (
        <NodePublishDialog
          activeGrid={props.gridId}
          gridRow={publishDialogRowData}
          nodeGroup={store.activeNodeGroup}
          onCloseDialog={() => setShowPublishDialog(false)}
        />
      )}
      <StyledDiv>
        <Grid
          columns={{ count: 2, size: "auto" }}
          gap="small"
          style={{ marginBottom: "10px" }}
        >
          <Box align="start">
            <Box align="baseline" direction="row" gap="small">
              <h1>
                {props.screenIcon} {<ScreenTitle />}
              </h1>
              <h2>{screenSubtitle}</h2>
            </Box>
          </Box>
          {saveArea}
        </Grid>
        <Grid columns={{ count: 2, size: "auto" }} gap="small">
          <Box direction="row" align="start" gap="small">
            {previewBtn.show && (
              <span title={previewBtn.tooltip}>
                <Button
                  primary={grids.get(props.gridId)?.state.showPreviewer}
                  disabled={!previewBtn.enable || isGridLockedOut}
                  onClick={onClickPreviewBtn}
                >
                  {CarbonIcons.Preview}
                  {previewBtn.labelOverride
                    ? previewBtn.labelOverride
                    : "Preview"}
                </Button>
              </span>
            )}
            {showPreviewDateField && (
              <span style={{ width: 120 }}>
                <DatePicker
                  className="previewer-date-picker"
                  disabled={isGridLockedOut}
                  value={store.previewDate}
                  format="MM/dd/yyyy"
                  formatPlaceholder={{
                    year: "YYYY",
                    month: "MM",
                    day: "DD",
                    hour: "hh",
                    minute: "mm",
                    second: "ss"
                  }}
                  onChange={(event: DatePickerChangeEvent) => {
                    storeDispatch({
                      type: StoreActions.setPreviewDate,
                      payload: {
                        value: event.value || null
                      }
                    });
                  }}
                />
              </span>
            )}
            {exportBtn.show && !toggleInsertModeBtnOn && (
              <span title={exportBtn.tooltip}>
                <Button
                  disabled={!exportBtn.enable || isGridLockedOut}
                  onClick={() => {
                    if (exportBtn.onClick) {
                      exportBtn.onClick();
                    }
                  }}
                >
                  {exportBtn.iconOverride
                    ? exportBtn.iconOverride
                    : CarbonIcons.OpenFull}
                  {exportBtn.labelOverride ? exportBtn.labelOverride : "Export"}
                </Button>
              </span>
            )}
            {addBtn.show && !toggleInsertModeBtnOn && (
              <span title={addBtn.tooltip}>
                <Button
                  disabled={!addBtn.enable || isGridLockedOut}
                  onClick={() => {
                    if (addBtn.onClick) {
                      addBtn.onClick();
                    } else {
                      onClickInsertModeBtn(true);
                    }
                  }}
                >
                  {addBtn.iconOverride ? addBtn.iconOverride : CarbonIcons.Add}
                  {addBtn.labelOverride ? addBtn.labelOverride : "Add"}
                </Button>
              </span>
            )}
            {showCancelBtn && !showSavingIndicator && (
              <span title={cancelBtn.tooltip}>
                <Button
                  disabled={!cancelBtn.enable}
                  onClick={() => onClickInsertModeBtn(false)}
                >
                  {CarbonIcons.Close}
                  {cancelBtn.labelOverride ? cancelBtn.labelOverride : "Cancel"}
                </Button>
              </span>
            )}
            {showSaveBtn && !showSavingIndicator && (
              <span title={saveBtn.tooltip}>
                <Button
                  className="carbon-orange-btn"
                  disabled={!saveBtn.enable}
                  onClick={onClickSaveBtn}
                >
                  {CarbonIcons.Done}
                  {saveBtn.labelOverride ? saveBtn.labelOverride : "Save"}
                </Button>
              </span>
            )}
            {editModeBtn.show && !showSaveBtn && (
              <span title={editModeBtn.tooltip}>
                <Button
                  className="carbon-link-btn"
                  disabled={!editModeBtn.enable || isGridLockedOut}
                  onClick={() => {
                    if (editModeBtn.onClick) {
                      editModeBtn.onClick();
                    } else {
                      onClickEditModeBtn();
                    }
                  }}
                >
                  <span className="material-icons" style={{ fontSize: "16px" }}>
                    {toggleEditModeBtnOn === true ? "edit" : "lock"}
                  </span>
                  {toggleEditModeBtnOn === true ? "Edit Mode" : "Grid Locked"}
                </Button>
              </span>
            )}
          </Box>
          <Box direction="row-reverse" align="end" gap="small">
            {quickFilterBtn.show && (
              <span title={quickFilterBtn.tooltip}>
                <Button
                  primary={toggleQuickFilterBtnOn}
                  disabled={!quickFilterBtn.enable || isGridLockedOut}
                  onClick={onClickQuickFilterBtn}
                >
                  {CarbonIcons.Filter}
                  {quickFilterBtn.labelOverride
                    ? quickFilterBtn.labelOverride
                    : "Quick Filter"}
                </Button>
              </span>
            )}
            {props.gridId === GridIDs.Nodes && (
              <NodeFilterDropDown disabled={isGridLockedOut} />
            )}
          </Box>
          <Box direction="row" align="start" gap="small" />
        </Grid>
        {grids.get(props.gridId)?.state.showPreviewer &&
          !store.showPreviewerPopup && <Previewer gridId={props.gridId} />}
      </StyledDiv>
    </>
  );
};

const getCarbonDateString = (date: Date | null): string => {
  // YYYY_MM_DD <---- Carbon Previewer Date
  // jon, 2/10/22: Handle null date - change to empty string so backend can handle it
  if (date === null) return "";
  const dateString = date.toISOString().substr(0, 10).replaceAll("-", "_");
  return dateString;
};

const StyledDiv = styled.div`
  width: 100%;
  margin-top: 5px;
  margin-bottom: 25px;

  span.last-saved-timestamp {
    font-size: 12px;
    color: #333333;
    text-align: right;
  }

  span.saving-indicator {
    color: var(--carbon-orange);
    font-weight: bold;
  }

  h1 {
    span[class^="material-icons"] {
      display: inline-flex;
      vertical-align: middle;
      height: 30px;
      width: 30px;
    }
  }

  h2 {
    font-weight: normal;
    font-size: 15px;
  }
`;

const StyledTooltip = styled(Tooltip)`
  margin-top: 4px;

  & .k-tooltip {
    color: #ffffff;
    background: #333333;
    opacity: 0.9;
    width: 450px;
    margin-left: -114px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
  }

  & .k-callout {
    margin-left: 50px;
  }
`;

export default CarbonGridToolbar;
