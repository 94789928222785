import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  {
    field: "CompanyID",
    title: "CompanyID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    company: true,
    defaultValue: -1,
    isPartOfUniqueKey: true
  },
  {
    field: "CompanyName",
    title: "Company",
    width: "375px",
    maxLength: 50,
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    canBeSaved: true,
    isPartOfUniqueKey: true
  },
  {
    field: "CompanyCode",
    title: "Code",
    width: "185px",
    maxLength: 4,
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    canBeSaved: true,
    isPartOfUniqueKey: true
  },
  {
    field: "CompanyDesc",
    title: "Description",
    width: "350px",
    maxLength: 256,
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true
  },
  {
    field: "LockoutNodeProcessing",
    title: "Lockout Nodes",
    width: "310px",
    defaultShow: true,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true,
    defaultValue: false
  },
  {
    field: "LockoutNodesUsername",
    title: "Lockout Username",
    width: "310px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "LockoutNodesInitiatedTime",
    title: "Lockout Initiated",
    width: "310px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "Address",
    title: "Address",
    width: "310px",
    maxLength: 256,
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true
  },
  {
    field: "City",
    title: "City",
    width: "185px",
    maxLength: 50,
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true
  },
  {
    field: "State",
    title: "State",
    width: "185px",
    maxLength: 50,
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true
  },
  {
    field: "Zip",
    title: "Zip",
    width: "150px",
    maxLength: 10,
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true
  },
  {
    field: "PhoneNumber",
    title: "Phone Number",
    width: "200px",
    maxLength: 50,
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true
  },
  {
    field: "ContactName",
    title: "Contact Name",
    width: "185px",
    maxLength: 100,
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true
  },
  {
    field: "ContactEmail",
    title: "Contact Email",
    width: "375px",
    maxLength: 50,
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true
  },
  {
    field: "VigilixID",
    title: "PC ID",
    titlePlural: "PC IDs",
    width: "185px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    canBeSaved: true,
    valueField: "VigilixID"
  },
  {
    field: "EnableAdvVigilix",
    title: "PC Access",
    width: "310px",
    defaultShow: true,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true
  },
  {
    field: "IsActive",
    title: "Active",
    width: "310px",
    defaultShow: true,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true
  }
];
