import { Helmet } from "react-helmet-async";
import { Text, Box, ResponsiveContext, Image } from "grommet";
import { Button } from "@progress/kendo-react-buttons";
import { useNavigate } from "react-router";
import styled from "styled-components";
import DashboardFooter from "../components/Layouts/Dashboard/DashboardFooter";

const Fallback = (errorBoundaryProps: {
  error: any;
  componentStack: any;
  resetError: any;
}) => {
  const { error, componentStack, resetError } = errorBoundaryProps;
  const navigate = useNavigate();

  const handleClick = () => {
    resetError();
    navigate("/app/home");
  };

  return (
    <>
      <Helmet>
        <title>Carbon | Home</title>
      </Helmet>
      <ResponsiveContext.Consumer>
        {(screenSize) => (
          <StyledAppBar>
            <Box direction="row">
              {screenSize === "small" ||
              screenSize === "xsmall" ||
              screenSize === "medium" ? (
                <Box
                  focusIndicator={false}
                  onClick={handleClick}
                  width="52px"
                  pad={{ top: "xsmall", bottom: "xsmall" }}
                  margin={{ left: "small", right: "small" }}
                >
                  <Image src="/static/images/c-carbon-icon.svg" />
                </Box>
              ) : (
                <Box
                  focusIndicator={false}
                  onClick={handleClick}
                  width="204px"
                  pad={{ top: "small", bottom: "small" }}
                  margin={{ left: "large" }}
                >
                  <Image fill src="/static/images/carbon-logo.svg" />
                </Box>
              )}
            </Box>
          </StyledAppBar>
        )}
      </ResponsiveContext.Consumer>
      <Box
        overflow="auto"
        background="var(--carbon-verylightgray)"
        fill
        pad={{ top: "200px" }}
        justify="center"
        gap="xlarge"
        direction="row"
      >
        <Box align="center" gap="medium">
          <Text size="3xl">Oops, something went wrong!</Text>
          <Text>{error.toString()}</Text>
          <Text>{componentStack}</Text>
          <HomeButton type="button" onClick={handleClick}>
            Home
          </HomeButton>
        </Box>
        <Box height="medium" width="medium">
          <Image src="/sad_pup.png" />
        </Box>
      </Box>
      <DashboardFooter />
    </>
  );
};

const StyledAppBar = styled(Box)`
  background-color: var(--carbon-darkgray);
  position: sticky;
  top: 0;
  z-index: 100;
`;

const HomeButton = styled(Button)`
  background-color: var(--carbon-orange);
  width: 150px;
  border-radius: 30px;
  color: var(--carbon-white);
`;

export default Fallback;
