import React, { ReactElement } from "react";
import { GridColumns } from "../../../types/grid";
import { HeaderSelectAllCheckboxRender } from "./HeaderSelectAllCheckboxRender";

export interface HeaderCellRenderProps {
  gridId: string;
  th: ReactElement<HTMLTableHeaderCellElement>;
  colDefinition?: GridColumns;
}

export const HeaderCellRender = ({
  gridId,
  th,
  colDefinition
}: HeaderCellRenderProps) => {
  const editor: string = colDefinition?.editor ? colDefinition?.editor : "text";
  let additionalProps: any = {};

  // Checkbox cell type - center header
  if (editor === "boolean") {
    additionalProps = { style: { textAlign: "center" } };
  }

  // Select checkbox cell type - render select/unselect all checkbox
  if (editor === "selectcheckbox") {
    return (
      <HeaderSelectAllCheckboxRender
        gridId={gridId}
        th={th}
        colDefinition={colDefinition}
      />
    );
  }

  // Is column required? If so, show different styling
  const isRequired = colDefinition?.required || colDefinition?.companyMaster;
  let thChildren: JSX.Element = <>{th.props.children}</>;

  if (isRequired) {
    thChildren = (
      <div style={{ fontWeight: "bold" }}>
        <span
          style={{
            color: "var(--carbon-orange)",
            display: "inline-block",
            marginLeft: "-9px"
          }}
        >
          *&nbsp;
        </span>
        {th.props.children}
      </div>
    );
  }

  // Hide the title for this column?
  if (colDefinition && colDefinition.hideTitle === true) {
    thChildren = <></>;
  }

  return React.cloneElement(
    th,
    { ...th.props, ...additionalProps },
    thChildren
  );
};
