import { GridColumns } from "../../types/grid";
import { Placeholders } from "../../constants";

export const gridColumns: GridColumns[] = [
  {
    field: "ItemID",
    title: "Item ID",
    width: "250px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: -1,
    isPartOfUniqueKey: true
  },
  {
    field: "CompanyID",
    title: "CompanyID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    company: true,
    defaultValue: Placeholders.companyID,
    isPartOfUniqueKey: true,
    systemHidden: true
  },
  {
    field: "CompanyName",
    title: "Company",
    titlePlural: "Companies",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    editor: "lookup",
    required: false,
    valueField: "CompanyID",
    maxLength: 50,
    canBeSaved: false,
    companyMaster: true,
    defaultValue: Placeholders.companyName
  },
  {
    field: "ItemName",
    title: "Item",
    width: "280px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    maxLength: 256,
    canBeSaved: true,
    isPartOfUniqueKey: true
  },
  {
    field: "ItemDesc",
    title: "Description",
    width: "350px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 256,
    canBeSaved: true
  },
  {
    field: "ItemLabel",
    title: "Label",
    width: "425px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 256,
    canBeSaved: true
  },
  {
    field: "Price",
    title: "Default Price",
    width: "160px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 256,
    canBeSaved: true
  },
  {
    field: "Calories",
    title: "Calories",
    width: "245px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 256,
    canBeSaved: true
  },
  {
    field: "Dynamic1",
    title: "Text 1",
    width: "370px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 2000,
    canBeSaved: true
  },
  {
    field: "Dynamic2",
    title: "Text 2",
    width: "350px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 2000,
    canBeSaved: true
  },
  {
    field: "ItemTypeID",
    title: "ItemTypeID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "ItemTypeName",
    title: "Item Type",
    titlePlural: "Item Types",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    valueField: "ItemTypeID",
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "IsActive",
    title: "Active",
    width: "150px",
    defaultShow: false,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true,
    defaultValue: true
  }
];
