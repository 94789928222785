import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  {
    field: "FontID",
    title: "FontID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: -1
  },
  {
    field: "FontName",
    title: "Font",
    width: "435px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    maxLength: 256,
    canBeSaved: true
  },
  {
    field: "FontDesc",
    title: "Description",
    width: "350px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 256,
    canBeSaved: true
  },
  {
    field: "FileName",
    title: "File Name",
    width: "1395px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "IsActive",
    title: "Active",
    width: "250px",
    defaultShow: false,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true
  }
];
