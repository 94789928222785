import apiClient from "..";
import { authEndpoints } from "../../constants/endpoints";
import { ForgotPasswordContract } from "../../types";
import { AxiosResponse } from "axios";

const ForgotPasswordApi = (
  forgotPasswordContract: ForgotPasswordContract
): Promise<AxiosResponse> => {
  const data: Object = {
    CompanyCode: forgotPasswordContract.companyCode,
    UserName: forgotPasswordContract.userName,
    Email: forgotPasswordContract.email
  };

  const response = apiClient.put(
    authEndpoints.forgotPasswordEndpoint,
    JSON.stringify(data)
  );

  return response;
};

export default ForgotPasswordApi;
