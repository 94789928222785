// eslint-disable
import * as CarbonIcons from "./icons/CarbonIcons";
import config from "../config";
export { CarbonIcons };

export enum GridActions {
  delete = "RESET_GRID",
  resizeGrid = "RESIZE_GRID",
  restoreLayout = "RESTORE_LAYOUT",
  initialData = "INITIAL_DATA",
  createGrid = "CREATE_GRID",
  updateConfig = "UPDATE_GRID_CONFIG",
  updateData = "UPDATE_GRID_DATA",
  updateDataPage = "UPDATE_GRID_DATA_PAGE",
  updateLookupData = "UPDATE_LOOKUP_DATA",
  updateActiveCompany = "UPDATE_ACTIVE_COMPANY",
  updateColumns = "UPDATE_COLUMNS",
  updateColumnState = "UPDATE_COLUMN_STATE",
  updateSort = "UPDATE_SORT",
  editFieldName = "EDIT_FIELD_NAME",
  onItemChange = "ON_ITEM_CHANGE",
  onInsertItemChange = "ON_INSERT_ITEM_CHANGE",
  onRowClick = "ON_ROW_CLICK",
  onSelectionChange = "ON_SELECTION_CHANGE",
  onDataStateChange = "ON_DATA_STATE_CHANGE",
  onUpdateVisibleColumns = "ON_UPDATE_VISIBLE_COLUMNS",
  toggleQuickFilter = "TOGGLE_QUICK_FILTER",
  togglePreviewer = "TOGGLE_PREVIEWER",
  toggleEditMode = "TOGGLE_EDIT_MODE",
  toggleInsertMode = "TOGGLE_INSERT_MODE",
  toggleSavingIndicator = "TOGGLE_SAVING_INDICATOR",
  toggleCopyRecordDialog = "TOGGLE_COPY_RECORD_DIALOG",
  updateLastSaveDate = "UPDATE_LAST_SAVE_DATE",
  toggleGridLockoutMode = "TOGGLE_GRID_LOCKOUT_MODE",
  toggleRefreshGrid = "TOGGLE_REFRESH_GRID",
  setInitialGridSettings = "SET_INITIAL_GRID_SETTINGS",
  saveGridScrollPosition = "SAVE_GRID_SCROLL_POSITION",
  setCustomFilter = "SET_CUSTOM_FILTER",
  setColumnOrderLayout = "SET_COLUMN_ORDER_LAYOUT",
  setSortOrderLayout = "SET_SORT_ORDER_LAYOUT",
  setColumnResizeLayout = "SET_COLUMN_RESIZE_LAYOUT",
  setDefaultColumnsAndGridProps = "SET_DEFAULT_GRID_COLUMNS_AND_PROPS",
  setVisiblitySelectorColumns = "SET_VISIBILITY_SELECTOR_COLUMNS",
  setParentRowData = "SET_PARENT_ROW_DATA",
  onAfterRecordCopy = "ON_AFTER_RECORD_COPY",
  onDeleteRecord = "ON_DELETE_RECORD",
  onSetTextareaDialogParams = "ON_SET_TEXTAREA_DIALOG_PARAMS",
  onExpandRow = "ON_EXPAND_ROW",
  toggleSelectAllCheckbox = "TOGGLE_SELECT_ALL_CHECKBOX",
  buildSelectedPKList = "BUILD_SELECTED_PKLIST",
  onEditNodeFilter = "ON_EDIT_NODE_FILTER",
  onAddNewNodeFilter = "ON_ADD_NODE_FILTER",
  onCloseFilterDialog = "ON_CLOSE_FILTER_DIALOG",
  onSaveAdvancedFilter = "ON_SAVE_ADVANCED_FILTER",
  onNodeFilterDropdownChange = "ON_NODE_FILTER_DROPDOWN_CHANGE",
  setGridEndpoint = "SET_GRID_ENDPOINT",
  updateNodeScheduleIds = "UPDATE_NODESCHEDULE_IDS",
  onKeyNavigation = "ON_KEY_NAVIGATION",
  setGridRef = "SET_GRID_REF",
  setActiveDayGroupData = "SET_ACTIVE_DAYGROUP_DATA",
  setGridWidth = "SET_GRID_WIDTH",
  clearSelectedRow = "CLEAR_SELECTED_ROW",
  setGridActions = "SET_GRID_ACTIONS",
  updateSelectedRowData = "UPDATE_SELECTED_ROW_DATA",
  toggleGridResize = "TOGGLE_GRID_RESIZE"
}
export enum Placeholders {
  activeCompanies = "%ACTIVECOMPANIES%",
  companyID = "%COMPANYID%",
  companyName = "%COMPANYNAME%",
  filterID = "%FILTERID%",
  nodeGroupId = "%NODE_GROUP_ID%",
  globalChangeType = "%CHANGETYPE%",
  globalChangeSelectedId = "%SELECTEDID%",
  globalChangeSelectedSubId = "%SELECTEDSUBID%",
  globalChangeSelectedContentType = "%SELECTEDCONTENTTYPE%",
  NodeScheduleIDs = "%NODE_SCHEDULE_IDS%",
  nodeScheduleSelectedDayGroupDays = "%NODE_SCHEDULE_SELECTED_DAYGROUP_DAYS%",
  nodeScheduleSelectedDayPartID = "%NODE_SCHEDULE_SELECTED_DAYPART_ID%",
  selectedRecordID = "%SELECTED_RECORD_ID%",
  selectedNodeProcessUpdateHistoryID = "%SELECTED_NODE_PROCESS_UPDATE_HISTORY_ID%",
  HistoryNodeScheduleID = "%HISTORY_NODE_SCHEDULE_ID%",
  nodeTabNodeId = "%NODE_TAB_NODE_ID%",
  userTabUserId = "%USER_TAB_USER_ID%",
  roleTabRoleId = "%ROLE_TAB_ROLE_ID",
  patchTabPatchId = "%PATCH_TAB_PATCH_ID%",
  userDefinedTypesTabUdtId = "%UDT_TAB_UDT_ID%",
  templatesTabTemplateId = "%TEMPLATES_TAB_TEMPLATE_ID%",
  stencilsTabStencilId = "%STENCILS_TAB_STENCIL_ID%",
  mediaTabMediaId = "%MEDIA_TAB_MEDIA_ID%",
  packagesTabPackageId = "%PACKAGES_TAB_PACKAGE_ID",
  priceSchedulesTabPriceScheduleId = "%PRICE_SCHEDULES_TAB_PRICE_SCHEDULE_ID"
}

export enum StoreActions {
  addNotification = "SET_NOTIFICATION",
  removeNotification = "REMOVE_NOTIFICATION",
  removeAllNotifications = "REMOVE_ALL_NOTIFICATIONS",
  setMultiple = "SET_MULTIPLE",
  setUser = "SET_USER",
  setToken = "SET_TOKEN",
  setRefreshToken = "SET_REFRESH_TOKEN",
  setActiveCompany = "SET_ACTIVE_COMPANY",
  setStartupDetails = "SET_STARTUP_DETAILS",
  setUserGridLayout = "SET_USER_GRID_LAYOUT",
  setUserLoggedIn = "SET_USER_LOGGED_IN",
  onAddNewNodeGroup = "ON_ADD_NEW_NODE_GROUP",
  onEditNodeGroup = "ON_EDIT_NODE_GROUP",
  onCloseNodeGroupDialog = "ON_CLOSE_NODE_GROUP_DIALOG",
  onSelectNodeGroup = "ON_SELECT_NODE_GROUP",
  onSaveNodeGroup = "ON_SAVE_NODE_GROUP",
  onDeleteNodeGroup = "ON_NODE_GROUP_DELETE",
  showPreviewerPopup = "SHOW_PREVIEWER_POPUP",
  setEmbeddedPreviewerURL = "SET_EMBEDDED_PREVIEWER_URL",
  setPreviewerWindow = "SET_PREVIEWER_WINDOW",
  setPreviewDate = "SET_PREVIEW_DATE"
}

export enum PreviewerActions {
  setPreviewerProps = "SET_PREVIEWER_PROPS"
}

export enum UserPermissions {
  IsSuperUser = "IsSuperUser",
  HasVigilix = "HasVigilix",
  RolesPerms = "RolesPerms",
  UsersPerms = "UsersPerms",
  UserNodeAssignPerms = "UserNodeAssignPerms",
  UserCompanyAssignPerms = "UserCompanyAssignPerms",
  CompaniesPerms = "CompaniesPerms",
  PatchesPerms = "PatchesPerms",
  PriceSchedulesPerms = "PriceSchedulesPerms",
  POSSystemsPerms = "POSSystemsPerms",
  UserDefinedTypesPerms = "UserDefinedTypesPerms",
  TextStylesPerms = "TextStylesPerms",
  FontsPerms = "FontsPerms",
  MediaPerms = "MediaPerms",
  MediaMovieCodesPerms = "MediaMovieCodesPerms",
  ItemsPerms = "ItemsPerms",
  StencilsPerms = "StencilsPerms",
  PackagesPerms = "PackagesPerms",
  TemplatesPerms = "TemplatesPerms",
  NodeGroupsPerms = "NodeGroupsPerms",
  NodesPerms = "NodesPerms",
  CanEditInGroupMode = "CanEditInGroupMode",
  CanManageNodeTemplates = "CanManageNodeTemplates",
  CanManageNodeContent = "CanManageNodeContent",
  CanManageNodePricing = "CanManageNodePricing",
  CanManageNodeMovieCodes = "CanManageNodeMovieCodes",
  CanManageNodeDayParts = "CanManageNodeDayParts",
  CanManageNodeDisplays = "CanManageNodeDisplays",
  CanManageMultCompanies = "CanManageMultCompanies",
  CanCreatePubFilters = "CanCreatePubFilters",
  CanEditHelp = "CanEditHelp",
  CanManageNodeScheduleHistory = "CanManageNodeScheduleHistory",
  CanMakeGlobalChanges = "CanMakeGlobalChanges",
  AuditTrailPerms = "AuditTrailPerms",
  CampaignsPerms = "CampaignsPerms",
  CanViewMarketingManagementDashboard = "CanViewMarketingManagementDashboard"
}

export enum GridIDs {
  AuditTrail = "grdAuditTrail",
  Campaigns = "grdCampaigns",
  Companies = "grdCompanies",
  Fonts = "grdFonts",
  GlobalChanges = "grdGlobalChangesZones",
  Items = "grdItems",
  MediaLibrary = "grdMediaLibrary",
  MediaLibraryCodes = "grdMediaLibraryCodes",
  Nodes = "grdNodes",
  NodeDayParts = "grdNodeDayParts",
  NodeItems = "grdNodeItems",
  NodeItemHistory = "grdNodeItemHistories",
  NodeMovieCodes = "grdNodeMovieCodes",
  NodePatchHistory = "grdNodePatchHistories",
  NodeHistory = "grdNodeHistories",
  NodeDisplays = "grdNodeDisplays",
  NodeTemplates = "grdNodeTemplates",
  NodeTemplatesHistory = "grdNodeTemplatesHistories",
  NodeVideoCards = "grdNodeVideoCards",
  NodeZones = "grdNodeZones",
  NodeZonesHistory = "grdNodeZonesHistories",
  Patches = "grdPatches",
  Packages = "grdPackages",
  PackageZones = "grdPackageZones",
  PatchCompanies = "grdPatchCompanies",
  PriceSchedules = "grdPriceSchedules",
  PriceScheduleItems = "grdPriceScheduleItems",
  Roles = "grdRoles",
  RolePermissions = "grdRolePermissions",
  Stencils = "grdStencils",
  StencilZones = "grdStencilZones",
  Templates = "grdTemplates",
  TemplateZones = "grdTemplateZones",
  TextStyles = "grdTextStyles",
  Users = "grdUsers",
  UserManagedCompanies = "grdUserManagedCompanies",
  UserNodeAssignments = "grdUserNodeAssignments",
  UserDefinedTypes = "grdUserDefinedTypes",
  UserDefinedTypeCompanies = "grdUserDefinedTypeCompanies"
}

export enum EndpointResources {
  AuditTrail = "audit-trail",
  App = "app",
  Campaigns = "campaigns",
  CampaignsDashboard = "campaigns-dashboard",
  ChangePassword = "change-password",
  Companies = "companies",
  Fonts = "fonts",
  GlobalChanges = "global-changes",
  Home = "home",
  Items = "items",
  Login = "login",
  Media = "media",
  MediaMovieCodes = "movie-codes",
  Packages = "packages",
  PackageZones = "package-zones",
  Patches = "patches",
  PatchCompanies = "patch-companies",
  PriceSchedules = "price-schedules",
  PriceScheduleItems = "price-schedule-items",
  Roles = "roles",
  RolePermissions = "role-permissions",
  TextStyles = "text-styles",
  Users = "users",
  UserManagedCompanies = "managed-companies",
  UserNodeAssignment = "node-assignment",
  Nodes = "nodes",
  NodeSchedules = "node-schedules",
  NodeItems = "node-items",
  NodeHistory = "node-history",
  NodeDayparts = "node-dayparts",
  NodePatchHistory = "node-patch-history",
  NodeMovieCodes = "node-movie-codes",
  NodeDisplays = "node-displays",
  NodeVideoCards = "node-video-cards",
  UserDefinedTypes = "user-defined-types",
  UserDefinedTypeCompanies = "user-defined-type-companies",
  Stencils = "stencils",
  StencilZones = "stencil-zones",
  Templates = "templates",
  TemplateZones = "template-zones"
}

export enum AuditTrailTypes {
  LOGIN = 1,
  LOGOUT = 2,
  SCREEN = 3
}

export const Api = Object.freeze({
  baseUrl: config.apiBaseUrl
});

export enum AxiosStatus {
  OK = "OK"
}

export enum ResultTypes {
  Success = "success",
  Error = "error"
}

export enum FileUploadTypes {
  Media = "media",
  Fonts = "fonts",
  CampaignsDashboard = "campaignsDashboard"
}

export enum AppLocalStore {
  Token = "token",
  RefreshToken = "refresh_token",
  User = "user",
  CarbonRememberMe = "CarbonRememberMe",
  NavigationURI = "NavigationURI",
  ActiveCompany = "ActiveCompany",
  LoginScreenMessage = "LoginScreenMessage"
}

export const Keys = Object.freeze({
  backspace: 8,
  tab: 9,
  enter: 13,
  shift: 16,
  esc: 27,
  space: 32,
  pageUp: 33,
  pageDown: 34,
  end: 35,
  home: 36,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  delete: 46
});

export enum DayGroupMenu {
  DayPartSelect = "DayPartSelect",
  DuplicateDayGroup = "DuplicateDayGroup"
}
