import { Helmet } from "react-helmet-async";
import { Box } from "grommet";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import { useState } from "react";

// Components
import CarbonGridToolbar, {
  GridToolbarActionLabels,
  GridToolbarItem
} from "../../components/CarbonGrid/Toolbar/CarbonGridToolbar";
import CarbonGrid from "../../components/CarbonGrid/CarbonGrid";
import MediaFilesUploadDialog from "./MediaFilesUploadDialog";
import Authorizer from "../../components/Util/Authorizer";

// Contexts
import { useGrid } from "../../contexts/grid/useGrid";

// Types & Constants
import { GridType } from "../../types/grid";
import { gridColumns } from "./MediaLibraryGridColumns";

// User/Permissions
import usePermissions from "../../hooks/auth/usePermissions";
import {
  EndpointResources,
  GridActions,
  GridIDs,
  Placeholders,
  UserPermissions,
  CarbonIcons,
  FileUploadTypes
} from "../../constants";
import {
  GridActionBarEvent,
  GridActionBarItem
} from "../../components/CarbonGrid/Toolbar/CarbonGridRowActionBar";
import useFileDownload from "../../hooks/utils/useFileDownload";
import { onMediaRowChange } from "./MediaLibraryCustomEvents";

const Media = (): JSX.Element => {
  const { canView, canInsert, canDelete } = usePermissions();
  const navigate = useNavigate();
  const { setGrid } = useGrid();
  const { downloading, downloadFile } = useFileDownload(FileUploadTypes.Media);
  const [uploadZoneVisible, setUploadZoneVisible] = useState<boolean>(false);
  const gridName = GridIDs.MediaLibrary;
  const pagePermission = UserPermissions.MediaPerms;

  const actionLabels: GridToolbarActionLabels = {
    singular: "media",
    plural: "media",
    titleSingular: "Media",
    titlePlural: "Media"
  };

  const actions: GridActionBarItem[] = [
    {
      show: canView(UserPermissions.MediaMovieCodesPerms),
      label: "Movie Codes",
      includeInExtrasMenu: true,
      icon: CarbonIcons.MovieCodes,
      onClick: (event: GridActionBarEvent) => {
        navigate(
          `/app/media/${event.rowData.MediaID}/${EndpointResources.MediaMovieCodes}`
        );
      }
    },
    {
      show: true,
      label: "Download file",
      includeInExtrasMenu: false,
      icon: CarbonIcons.Download,
      onClick: async (event: GridActionBarEvent) => {
        await downloadFile(event.rowData.CompanyID, event.rowData.MediaID);
      }
    }
  ];

  const gridSettings: GridType = {
    actions,
    endpoints: {
      gridODataEndpoint: `/odata/${Placeholders.companyID}/gridmedia?$count=true&`,
      gridApiEndpoint: `/api/${Placeholders.companyID}/media`,
      lookupEndpoints: [
        {
          lookupField: "MediaTypeName",
          endpoint: `/api/${Placeholders.companyID}/lookupmediatypes`
        },
        {
          lookupField: "CampaignName",
          endpoint: `/api/${Placeholders.companyID}/lookupcampaigns`
        },
        {
          lookupField: "MediaName1",
          endpoint: `/api/${Placeholders.companyID}/lookupmedia/static`
        },
        {
          lookupField: "MediaName2",
          endpoint: `/api/${Placeholders.companyID}/lookupmedia/static`
        },
        {
          lookupField: "PackageName1",
          endpoint: `/api/${Placeholders.companyID}/lookuppackages/static`
        },
        {
          lookupField: "PackageName2",
          endpoint: `/api/${Placeholders.companyID}/lookuppackages/static`
        }
      ]
    },
    records: [],
    total: 0,
    data: [],
    lookups: {},
    dataState: {
      pageSize: 25,
      take: 50,
      skip: 0,
      sort: [
        { field: "CompanyName", dir: "asc" },
        { field: "MediaName", dir: "asc" }
      ],
      filter: {
        logic: "and",
        filters: [{ field: "IsActive", operator: "equals", value: true }]
      }
    },
    dataItemKey: "MediaID",
    singularEntityName: actionLabels.singular,
    pluralEntityName: actionLabels.plural,
    columns: gridColumns,
    showCopyBtn: false,
    showDeleteBtn: canDelete(pagePermission),
    onRowChange: onMediaRowChange,
    state: {
      editMode: false,
      insertMode: false,
      selectedState: {},
      selectedRow: -1,
      showQuickFilter: false,
      lastSaveDate: null,
      showSavingIndicator: false,
      lockoutMode: false
    }
  };

  const cancelBtn: GridToolbarItem = {
    show: false,
    enable: false,
    tooltip: ""
  };

  const previewBtn: GridToolbarItem = {
    show: true,
    enable: true,
    tooltip: "Open the viewer to preview the selected media",
    toggledOn: false
  };

  const saveBtn: GridToolbarItem = { show: false, enable: false, tooltip: "" };

  const addBtn: GridToolbarItem = {
    show: true,
    enable: canInsert(pagePermission),
    tooltip: canInsert(pagePermission)
      ? `Upload files to the ${actionLabels.titlePlural}`
      : `You do not have permission to upload files to the ${actionLabels.titlePlural}.`,
    labelOverride: `Add ${actionLabels.titleSingular}`,
    onClick: () => setUploadZoneVisible(true)
  };

  const handlePostUploadFiles = (uploadDate: Date | null) => {
    // If any files were uploaded, the date of the first file successfully uploaded will be passed to this function. Convert that date to a string
    //   in the correct format and set a filter on the grid to show only records with a DateUploaded since that date.
    // Date will be null if no files were uploaded so don't set a grid filter in that case.
    if (uploadDate !== null) {
      const filterDateStr = dayjs(uploadDate).format("YYYY-MM-DD HH:mm:ss");
      const dateFilter = {
        logic: "and",
        filters: [
          { field: "DateUploaded", operator: "gte", value: filterDateStr }
        ]
      };

      setGrid({
        type: GridActions.setCustomFilter,
        payload: { gridId: gridName, gridData: dateFilter }
      });
    }

    setUploadZoneVisible(false);
  };

  return (
    <>
      <Helmet>
        <title>Carbon | Media</title>
      </Helmet>
      <Authorizer
        canView={pagePermission}
        auditLabel={`${actionLabels.titlePlural} screen`}
      >
        <Box align="start" fill>
          <CarbonGridToolbar
            gridId={gridName}
            screenIcon={CarbonIcons.Media}
            screenTitle={actionLabels.titlePlural}
            permissions={pagePermission}
            actionLabels={actionLabels}
            cancelBtn={cancelBtn}
            saveBtn={saveBtn}
            addBtn={addBtn}
            previewBtn={previewBtn}
            isFileDownloading={downloading}
          />
          {uploadZoneVisible && (
            <MediaFilesUploadDialog onPostUploadFiles={handlePostUploadFiles} />
          )}
          <Box fill className="carbon-grid-wrapper">
            <CarbonGrid
              gridId={gridName}
              gridSettings={gridSettings}
              gridClassName={"grid-with-actionbar"}
            />
          </Box>
        </Box>
      </Authorizer>
    </>
  );
};

export default Media;
