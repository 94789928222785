import { GridColumns } from "../../types/grid";

// NOTE: MediaName, PackageName,  MediaName1,
// PackageName1, MediaName2, PackageName2 not present on DB

export const gridColumns: GridColumns[] = [
  {
    field: "HistoryScheduleZoneID",
    title: "ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false
  },
  // {
  //   field: "HistoryNodeScheduleID",
  //   title: "History Node Schedule ID",
  //   width: "150px",
  //   defaultShow: false,
  //   filter: "numeric",
  //   editable: false,
  //   required: false,
  //   canBeSaved: true
  // },
  {
    field: "TemplateZoneID",
    title: "Template Zone ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true
  },
  {
    field: "SeqNum",
    title: "Zone No.",
    width: "130px",
    defaultShow: true,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "ZoneName",
    title: "Zone",
    width: "200px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "OffsetMinutes",
    title: "Offset Time",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true,
    defaultValue: 0
  },
  {
    field: "ContentSeqNum",
    title: "Sequence",
    width: "120px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true,
    defaultValue: 1
  },
  {
    field: "ContentType",
    title: "Content Type",
    width: "150px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "MediaID",
    title: "Media ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true
  },
  {
    field: "PackageID",
    title: "Package ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true
  },
  {
    field: "ZoneContent",
    title: "Content",
    width: "250px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true
  },
  // {
  //   field: "MediaName",
  //   title: "Media",
  //   width: "250px",
  //   maxLength: 256,
  //   defaultShow: true,
  //   filter: "text",
  //   editable: true,
  //   editor: "text",
  //   required: false,
  //   canBeSaved: true
  // },
  // {
  //   field: "PackageName",
  //   title: "Package",
  //   width: "250px",
  //   maxLength: 50,
  //   defaultShow: true,
  //   filter: "text",
  //   editable: true,
  //   editor: "text",
  //   required: false,
  //   canBeSaved: true
  // },
  {
    field: "StartDate",
    title: "Start",
    width: "160px",
    defaultShow: true,
    filter: "date",
    editable: true,
    editor: "date",
    required: false,
    canBeSaved: true
  },
  {
    field: "EndDate",
    title: "End",
    width: "160px",
    defaultShow: true,
    filter: "date",
    editable: true,
    editor: "date",
    required: false,
    canBeSaved: true
  },
  {
    field: "Volume",
    title: "Volume",
    width: "150px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true
  },
  {
    field: "StartDate_Sort",
    title: "StartDate_Sort",
    width: "250px",
    defaultShow: false,
    filter: "date",
    editable: false,
    required: false,
    canBeSaved: true
  },
  {
    field: "EndDate_Sort",
    title: "EndDate_Sort",
    width: "250px",
    defaultShow: false,
    filter: "date",
    editable: false,
    required: false,
    canBeSaved: true
  },
  {
    field: "HasDeferredContent",
    title: "Deferred Zones?",
    width: "150px",
    defaultShow: false,
    filter: "boolean",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "ContentCategory",
    title: "Category",
    width: "250px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "AssocStaticContentType",
    title: "Assoc. Content Type",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "AssocStaticContent1",
    title: "Assoc. Static Content 1",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  // {
  //   field: "MediaName1",
  //   title: "Media",
  //   width: "250px",
  //   maxLength: 256,
  //   defaultShow: true,
  //   filter: "text",
  //   editable: true,
  //   editor: "text",
  //   required: false,
  //   canBeSaved: true
  // },
  // {
  //   field: "PackageName1",
  //   title: "Package",
  //   width: "250px",
  //   maxLength: 256,
  //   defaultShow: true,
  //   filter: "text",
  //   editable: true,
  //   editor: "text",
  //   required: false,
  //   canBeSaved: true
  // },
  // {
  //   field: "AssocStaticContent2",
  //   title: "Assoc. Static Content 2",
  //   width: "250px",
  //   defaultShow: false,
  //   filter: "text",
  //   editable: false,
  //   required: false,
  //   canBeSaved: false
  // },
  // {
  //   field: "MediaName2",
  //   title: "Media",
  //   width: "250px",
  //   maxLength: 256,
  //   defaultShow: true,
  //   filter: "text",
  //   editable: true,
  //   editor: "text",
  //   required: false,
  //   canBeSaved: true
  // },
  // {
  //   field: "PackageName2",
  //   title: "Package",
  //   width: "250px",
  //   maxLength: 256,
  //   defaultShow: true,
  //   filter: "text",
  //   editable: true,
  //   editor: "text",
  //   required: false,
  //   canBeSaved: true
  // },
  {
    field: "StaticDisplayTime",
    title: "Static Display Time",
    width: "150px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true
  },
  {
    field: "ZoneDesc",
    title: "Zone Description",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "FilterMediaTypeID",
    title: "Filter Media Type ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true
  },
  {
    field: "FilterPackageTypeID",
    title: "Filter Package Type ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true
  },
  {
    field: "FilterMediaTypeName",
    title: "Filter Media Type Name",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: true
  },
  {
    field: "HideZoneWhenInactive",
    title: "Hide When Inactive",
    width: "250px",
    defaultShow: false,
    filter: "boolean",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "HideOtherZonesWhenActive",
    title: "Hide Other Zones When Active",
    width: "250px",
    defaultShow: false,
    filter: "boolean",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "RandomizeContentOrder",
    title: "Randomize Order",
    width: "250px",
    defaultShow: false,
    filter: "boolean",
    editable: false,
    required: false,
    canBeSaved: false
  }
];
