import { Result, ResultError, NodeCopyDayPartContract } from "../../types";
import useApi from "../api/useApi";
import {
  CarbonGenericApiGet,
  CarbonGenericApiPost
} from "../../api/carbonGenericApis";

const useNodeCopyToDayPartApi = () => {
  const { loading: isLoadingDayPartData, request: getDayPartDataRequest } =
    useApi(CarbonGenericApiGet);

  const {
    loading: isLoadingNodeCopyDayPart,
    request: processNodeCopyDayPartRequest
  } = useApi(CarbonGenericApiPost);

  const getDayPartData = async (
    companyId: number,
    nodeId: number
  ): Promise<Result<string>> => {
    const url: string = `/api/${companyId}/lookupnodedayparts/${nodeId}`;

    let result: Result<string>;
    try {
      console.log("Day Part Lookup API: " + url);
      result = await getDayPartDataRequest(url);
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }

    return result;
  };

  const processNodeCopyDayPart = async (
    payload: NodeCopyDayPartContract
  ): Promise<Result<string>> => {
    const url = `api/${payload.NodeScheduleID}/nodezoneoperations/copydaypart`;

    let result: Result<string>;
    try {
      console.log("Node Copy Day Part API: " + url);
      result = await processNodeCopyDayPartRequest(url, payload);
      if (result.type !== "success") {
        console.log("Node Copy Day Part Bad Request:", result.error);
      }
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }

    return result;
  };

  return {
    getDayPartData,
    isLoadingDayPartData,
    processNodeCopyDayPart,
    isLoadingNodeCopyDayPart
  };
};

export default useNodeCopyToDayPartApi;
