/**
 * Environment Variables and App Configuration
 */
const config = {
  gaId: process.env.REACT_APP_GA_ID,
  environment: process.env.NODE_ENV ?? "development",
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
  helpLink: process.env.REACT_APP_HELP_LINK,
  appVersion: process.env.REACT_APP_VERSION,
  carbonVersion: process.env.REACT_APP_CARBON_VERSION,
  uatMode: process.env.REACT_APP_UAT_MODE,
  dayGroupsHelpLink: process.env.REACT_APP_DAYGROUPS_HELP_LINK,
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN,
    level: process.env.REACT_APP_SENTRY_TRACE_LEVEL ?? "1.0"
  }
};
export default config;
