import { GridActions, GridIDs, Placeholders } from "../../constants";
import { GridAction, GridStateType } from "../../contexts/grid/useGrid";
import { GridColumns } from "../../types/grid";
import {
  onAssocStaticContentTypeChange,
  onContentCategoryChange
} from "./PackagesCustomEvents";

// Jon, 1/19/22: Added event on stencil change to refresh Zones grid.
const onStencilChange = (
  value: any,
  previousValue: any,
  rowData: { [key: string]: any },
  columns: GridColumns[],
  grids: GridStateType,
  setGrid: React.Dispatch<GridAction>
): { changedData?: { [key: string]: any }; changedColumns?: GridColumns[] } => {
  if (value === previousValue) return {};

  // If stencil changes and the zones tab's grid has already loaded, delete the grid so it can be refreshed to show the correct data.
  console.log(
    `Stencil changed from ${previousValue} to ${value}. Reloading Zones grid.`
  );
  if (grids.get(GridIDs.PackageZones)) {
    setGrid({
      type: GridActions.delete,
      payload: {
        gridId: GridIDs.PackageZones,
        gridData: null
      }
    });
  }

  // Nothing to return
  return {};
};

/*
  jon, 9/21/21: Reviewed notes and saw that Associated Static Content 2 field is not used anywhere and to remove it.
*/

export const gridColumns: GridColumns[] = [
  {
    field: "PackageName",
    title: "Package",
    width: "368px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    maxLength: 50,
    canBeSaved: true,
    isPartOfUniqueKey: true
  },
  {
    field: "StencilName",
    title: "Stencil",
    titlePlural: "Stencils",
    width: "401px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: true,
    valueField: "StencilID",
    maxLength: 50,
    canBeSaved: false,
    onChange: onStencilChange
  },
  {
    field: "PackageTypeName",
    title: "Package Type",
    titlePlural: "Package Types",
    width: "319px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    valueField: "PackageTypeID",
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "ContentCategory",
    title: "Category",
    width: "150px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "dropdown",
    required: false,
    canBeSaved: true,
    defaultValue: "S",
    dropItems: [
      {
        value: "S",
        text: "Static"
      },
      {
        value: "A",
        text: "Animated"
      }
    ],
    onChange: onContentCategoryChange
  },
  {
    field: "HSize",
    title: "Width",
    width: "116px",
    defaultShow: true,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "VSize",
    title: "Height",
    width: "113px",
    defaultShow: true,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "CampaignName",
    title: "Campaign",
    titlePlural: "Campaigns",
    width: "200px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    valueField: "CampaignID",
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "AssocStaticContentType",
    title: "Assoc. Content Type",
    width: "200px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "dropdown",
    required: false,
    canBeSaved: true,
    defaultValue: "M",
    dropItems: [
      {
        value: "M",
        text: "Media"
      },
      {
        value: "P",
        text: "Package"
      }
    ],
    onChange: onAssocStaticContentTypeChange
  },
  {
    field: "AssocStaticContent1",
    title: "Assoc. Static Content",
    width: "350px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "combinedcolumn",
    required: false,
    canBeSaved: false
  },
  {
    field: "IsActive",
    title: "Active",
    width: "250px",
    defaultShow: true,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true,
    defaultValue: true
  },
  {
    field: "PackageID",
    title: "PackageID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: -1,
    isPartOfUniqueKey: true
  },
  {
    field: "CompanyID",
    title: "CompanyID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    company: true,
    defaultValue: Placeholders.companyID,
    isPartOfUniqueKey: true,
    systemHidden: true
  },
  {
    field: "CompanyName",
    title: "Company",
    titlePlural: "Companies",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    editor: "lookup",
    required: false,
    valueField: "CompanyID",
    maxLength: 50,
    canBeSaved: false,
    companyMaster: true,
    defaultValue: Placeholders.companyName
  },
  {
    field: "PackageDesc",
    title: "Description",
    width: "350px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 256,
    canBeSaved: true
  },
  {
    field: "StencilID",
    title: "StencilID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "PackageTypeID",
    title: "PackageTypeID",
    width: "180px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "CampaignID",
    title: "CampaignID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "AssocStaticContent1MediaID",
    title: "AssocStaticContent1MediaID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "AssocStaticContent1PackageID",
    title: "AssocStaticContent1PackageID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "MediaName1",
    fieldNameInLookup: "MediaName",
    title: "Media",
    titlePlural: "Media",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    valueField: "AssocStaticContent1MediaID",
    valueFieldInLookup: "MediaID",
    maxLength: 256,
    canBeSaved: false,
    combinedCol: "AssocStaticContent1",
    controlField: "AssocStaticContentType",
    controlValue: "M"
  },
  {
    field: "PackageName1",
    fieldNameInLookup: "PackageName",
    title: "Package",
    titlePlural: "Packages",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    valueField: "AssocStaticContent1PackageID",
    valueFieldInLookup: "PackageID",
    maxLength: 256,
    canBeSaved: false,
    combinedCol: "AssocStaticContent1",
    controlField: "AssocStaticContentType",
    controlValue: "P"
  }
];
