import React from "react";
import { CellRenderProps } from "./CellRender";
import styled from "styled-components";
import { CarbonIcons } from "../../../constants";

export const ThumbnailRender = ({ originalProps, td }: CellRenderProps) => {
  const image = (
    <>
      {originalProps.dataItem[originalProps.field!] === "" ? (
        <StyledPlaceholderDiv>
          {CarbonIcons.ImageNotSupported}
        </StyledPlaceholderDiv>
      ) : (
        <StyledThumbnailDiv>
          <img src={originalProps.dataItem[originalProps.field!]} />
        </StyledThumbnailDiv>
      )}
    </>
  );

  return React.cloneElement(td, td.props, image);
};

const StyledThumbnailDiv = styled.div`
  text-align: left;
  width: 54px;
  display: inline-flex;
  vertical-align: middle;

  img {
    max-width: 54px;
    height: 32px;
  }
`;

const StyledPlaceholderDiv = styled.div`
  text-align: center;
  width: 54px;
  display: inline-flex;
  vertical-align: middle;

  span {
    font-size: 32px;
    opacity: 0.5;
  }
`;
