import apiClient from "..";
import { authEndpoints } from "../../constants/endpoints";
import { LoginContract } from "../../types";
import qs from "qs";
import { AxiosResponse } from "axios";

export const userLogin = (
  loginContract: LoginContract
): Promise<AxiosResponse> => {
  // fields must meet endpoint params, so we must transform the LoginContract type
  const tokenData: Object = {
    UserName: loginContract.userName,
    CompanyCode: loginContract.companyCode,
    // this should be handled on serverside if its value will never change
    grant_type: "password",
    Password: loginContract.password,
    rememberme: loginContract.rememberMe
  };

  const response = apiClient.post(
    authEndpoints.tokenEndpoint,
    qs.stringify(tokenData)
  );
  return response;
};

export const userLogout = (): Promise<AxiosResponse> => {
  const response = apiClient.post(authEndpoints.logoutEndpoint);
  return response;
};

export const userDetailsGet = (): Promise<AxiosResponse> => {
  const response = apiClient.get(authEndpoints.userDetailsEndpoint);
  return response;
};
