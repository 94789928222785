import { GridColumns } from "../../types/grid";

export const onPermissionRowChange = (
  rowData: { [key: string]: any },
  columns: GridColumns[]
): { changedColumns?: GridColumns[] } => {
  // If PermType is F, Insert, Update, and Delete columns are read-only. Editable for P (Permission) type.
  const changedColumns = setGridColumnPropertiesByRowData(
    columns,
    rowData.PermType === "P"
  );
  return { changedColumns };
};

const setGridColumnPropertiesByRowData = (
  columns: GridColumns[],
  isPermissionType: boolean
): GridColumns[] => {
  const changedColumns: GridColumns[] = [
    {
      ...columns.find((c) => c.field === "CanInsert")!,
      editable: isPermissionType
    },
    {
      ...columns.find((c) => c.field === "CanUpdate")!,
      editable: isPermissionType
    },
    {
      ...columns.find((c) => c.field === "CanDelete")!,
      editable: isPermissionType
    }
  ];

  return changedColumns;
};
