// Base
import React from "react";
import styled from "styled-components";

// Grommet/Kendo Components
import { Box } from "grommet";
import { ProgressBar } from "@progress/kendo-react-progressbars";

// Custom Components
import { CellRenderProps } from "./CellRender";

export const ProgressBarRender = ({
  originalProps,
  td,
  colDefinition
}: CellRenderProps) => {
  const settings = colDefinition!.progressBarSettings;

  if (!settings) {
    throw new Error("Progress Bar settings not found");
  }

  const value = originalProps.dataItem[originalProps.field!];
  const relatedFieldValue = settings.relatedField
    ? originalProps.dataItem[settings.relatedField.column]
    : null;

  // Logic - only render the progress bar if:
  // value is defined and not null
  // related field value is equal to the value defined in the column definition (if applicable)
  // current value is less than or equal to the value cap (if applicable)
  return React.cloneElement(
    td,
    td.props,
    value === undefined || // will, 3/2/22: changed logic from !value to value === undefined so when value = 0 the bar still renders
      value === null ||
      (relatedFieldValue !== null &&
        relatedFieldValue !== settings.relatedField?.compareValue) ||
      (settings.renderValueCap && value >= settings.renderValueCap) ? null : (
      <StyledProgressBarArea direction="row" gap="xsmall">
        <ProgressBar
          value={value}
          label={() => {
            return <></>;
          }}
          progressStyle={{
            background: settings.color,
            borderRadius: 5
          }}
          style={{
            height: "10px",
            borderRadius: 5,
            background: "var(--carbon-lightergray)"
          }}
        />
        {settings.showPercentage && <span>{`${value}%`}</span>}
      </StyledProgressBarArea>
    )
  );
};

const StyledProgressBarArea = styled(Box)`
  display: inline-flex;
  vertical-align: middle;
  width: 100%;
  align-items: center;

  span {
    font-size: 16px;
    color: var(--carbon-mediumgray);
    font-weight: 600;
    margin-top: -1px;
  }
`;
