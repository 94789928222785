import {
  Result,
  ResultError,
  NodeSchedulesRefreshZonesContract,
  NodeSchedulesInsertZonesContract
} from "../../types";
import useApi from "../../hooks/api/useApi";
import {
  RefreshZonesFromTemplate,
  InsertScheduleZoneRecords
} from "../../api/Nodes/nodeSchedulesApi";

const useNodeSchedulesOps = () => {
  const {
    loading: isLoadingRefreshZones,
    request: refreshZonesFromTemplateRequest
  } = useApi(RefreshZonesFromTemplate);

  const {
    loading: isLoadingInsertZones,
    request: insertScheduleZoneRecordsRequest
  } = useApi(InsertScheduleZoneRecords);

  const refreshZonesFromTemplate = async (
    payload: NodeSchedulesRefreshZonesContract
  ): Promise<Result<any>> => {
    const url = `/api/${payload.nodeScheduleID}/nodezoneoperations/refreshzones?days=${payload.days}&daypartid=${payload.daypartid}`;

    let result: Result<any>;
    try {
      result = await refreshZonesFromTemplateRequest(url);
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }

    return result;
  };

  const insertScheduleZoneRecords = async (
    payload: NodeSchedulesInsertZonesContract
  ): Promise<Result<any>> => {
    const url = `/api/${payload.nodeScheduleID}/nodezoneoperations/insertzones/${payload.scheduleZoneID}?numZonesToAdd=${payload.numZonesToAdd}&days=${payload.days}&daypartid=${payload.daypartid}`;

    let result: Result<any>;
    try {
      result = await insertScheduleZoneRecordsRequest(url);
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }

    return result;
  };

  const isLoading = (): boolean => {
    return isLoadingRefreshZones || isLoadingInsertZones;
  };

  return {
    refreshZonesFromTemplate,
    insertScheduleZoneRecords,
    isLoading
  };
};

export default useNodeSchedulesOps;
