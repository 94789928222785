import React from "react";
import { CellRenderProps } from "./CellRender";
import styled from "styled-components";
import { Box } from "grommet";

export const IconRender = ({
  originalProps,
  td,
  colDefinition
}: CellRenderProps) => {
  const iconData = colDefinition!.icons!.find(
    (item) => item.value === originalProps.dataItem[originalProps.field!]
  );

  return React.cloneElement(
    td,
    td.props,
    <StyledIconArea
      direction="row"
      gap="xsmall"
      style={{ justifyContent: iconData?.label ? "" : "center" }}
    >
      <StyledIconDiv className={iconData?.rotate ? "spin" : ""}>
        {iconData?.icon}
      </StyledIconDiv>
      {iconData?.label && <StyledIconLabel>{iconData?.label}</StyledIconLabel>}
    </StyledIconArea>
  );
};

const StyledIconArea = styled(Box)`
  div.spin {
    animation: spin 1.5s infinite linear;
  }

  @keyframes spin {
    from {
      transform: scale(1) rotate(0deg);
    }
    to {
      transform: scale(1) rotate(360deg);
    }
  }
`;

const StyledIconDiv = styled.div`
  text-align: center;
  display: inline-flex;
  vertical-align: middle;
`;

const StyledIconLabel = styled.span`
  height: 24px;
  display: inline-flex;
  margin-top: -4px;
`;
