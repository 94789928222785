import { GridColumns } from "../../types/grid";
import { onSundayStartTimeChange } from "./NodeDayPartsCustomEvents";

export const gridColumns: GridColumns[] = [
  {
    field: "NodeDayPartID",
    title: "NodeDayPartID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: -1
  },
  // {
  //   field: "NodeID",
  //   title: "NodeID",
  //   width: "150px",
  //   defaultShow: false,
  //   filter: "numeric",
  //   editable: false,
  //   required: false,
  //   canBeSaved: true,
  //   defaultValue: -1
  // },
  {
    field: "NodeDayPartName",
    title: "Day Part Name",
    width: "435px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    maxLength: 50,
    canBeSaved: true
  },
  {
    field: "NodeDayPartDesc",
    title: "Description",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 256,
    canBeSaved: true
  },
  {
    field: "SundayStartTime",
    title: "Sun. Start Time",
    width: "200px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "time",
    required: true,
    canBeSaved: true,
    onChange: onSundayStartTimeChange
  },
  {
    field: "MondayStartTime",
    title: "Mon. Start Time",
    width: "200px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "time",
    required: true,
    canBeSaved: true
  },
  {
    field: "TuesdayStartTime",
    title: "Tue. Start Time",
    width: "200px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "time",
    required: true,
    canBeSaved: true
  },
  {
    field: "WednesdayStartTime",
    title: "Wed. Start Time",
    width: "200px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "time",
    required: true,
    canBeSaved: true
  },
  {
    field: "ThursdayStartTime",
    title: "Thu. Start Time",
    width: "200px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "time",
    required: true,
    canBeSaved: true
  },
  {
    field: "FridayStartTime",
    title: "Fri. Start Time",
    width: "200px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "time",
    required: true,
    canBeSaved: true
  },
  {
    field: "SaturdayStartTime",
    title: "Sat. Start Time",
    width: "200px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "time",
    required: true,
    canBeSaved: true
  }
];
