import apiClient from "..";
import { useParams } from "react-router-dom";
import { GridDeleteRecordContract, Result, ResultError } from "../../types";
import { AxiosResponse } from "axios";
import useApi from "../../hooks/api/useApi";
import { useStore } from "../../contexts/store";
import { useGrid } from "../../contexts/grid/useGrid";
import { GridIDs, Placeholders } from "../../constants";

const DeleteGridRecordApi = (gridId: string) => {
  const { store } = useStore();
  const { grids } = useGrid();
  const {
    nodeId,
    userId,
    roleId,
    patchId,
    typeId,
    templateId,
    stencilId,
    mediaId,
    packageId,
    priceScheduleId
  } = useParams();

  const gridDeleteRecordDelete = (
    deleteRecordContract: GridDeleteRecordContract
  ): Promise<AxiosResponse> => {
    if (!deleteRecordContract.endpoint) {
      throw new Error(
        `DEVELOPER: You must specify the gridApiEndpoint in your page if you want to support grid deletes for grid ${gridId}.`
      );
    }

    let cleanEndpoint = `${deleteRecordContract.endpoint.replace(
      Placeholders.companyID,
      `${store.activeCompany!.companyId}`
    )}`;

    // Tab Data - Tabbed screens use route data in API call
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.nodeTabNodeId,
      `${nodeId}`
    );
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.userTabUserId,
      `${userId}`
    );
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.roleTabRoleId,
      `${roleId}`
    );
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.patchTabPatchId,
      `${patchId}`
    );
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.userDefinedTypesTabUdtId,
      `${typeId}`
    );
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.templatesTabTemplateId,
      `${templateId}`
    );
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.stencilsTabStencilId,
      `${stencilId}`
    );
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.mediaTabMediaId,
      `${mediaId}`
    );
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.packagesTabPackageId,
      `${packageId}`
    );
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.priceSchedulesTabPriceScheduleId,
      `${priceScheduleId}`
    );

    // Overriden endpoints could contain a placeholder for selected id. If so, replace that. Otherwise, append it.
    if (cleanEndpoint.indexOf(Placeholders.selectedRecordID) >= 0) {
      cleanEndpoint = cleanEndpoint.replace(
        Placeholders.selectedRecordID,
        `${deleteRecordContract.id}`
      );
    } else {
      cleanEndpoint = cleanEndpoint + `/${deleteRecordContract.id}`;
    }

    // If grid has daygroup/daypart values, replace placeholders with selected day group days and day part from grid
    if (grids.get(GridIDs.NodeTemplates)?.state.selectedDayGroupDays) {
      cleanEndpoint = cleanEndpoint.replace(
        Placeholders.nodeScheduleSelectedDayGroupDays,
        `${grids.get(GridIDs.NodeTemplates)!.state.selectedDayGroupDays}`
      );
    }
    if (grids.get(GridIDs.NodeTemplates)?.state.activeDayPart?.id) {
      cleanEndpoint = cleanEndpoint.replace(
        Placeholders.nodeScheduleSelectedDayPartID,
        `${grids.get(GridIDs.NodeTemplates)!.state.activeDayPart!.id}`
      );
    }

    console.log(
      `DELETE GRID RECORD: ${cleanEndpoint}`,
      deleteRecordContract.id
    );
    const response = apiClient.delete(cleanEndpoint);

    return response;
  };

  const { loading: isDeleting, request: gridRequest } = useApi(
    gridDeleteRecordDelete
  );

  const deleteRecord = async (
    deleteRecordContract: GridDeleteRecordContract
  ): Promise<Result<string>> => {
    let result: Result<string>;
    try {
      result = await gridRequest(deleteRecordContract);
    } catch (error: any) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }
    return result;
  };

  return {
    deleteRecord,
    isDeleting
  };
};

export default DeleteGridRecordApi;
