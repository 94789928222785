import { Helmet } from "react-helmet-async";
import { useLocation, useParams } from "react-router-dom";
import { Box } from "grommet";
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab
} from "@progress/kendo-react-layout";
import { useState, useEffect, useLayoutEffect } from "react";

// Components
import CarbonGridToolbar, {
  GridToolbarActionLabels,
  GridToolbarItem
} from "../../components/CarbonGrid/Toolbar/CarbonGridToolbar";
import CarbonTabStrip from "../../components/CarbonTabs/CarbonTabStrip";
import CarbonGrid from "../../components/CarbonGrid/CarbonGrid";
import Authorizer from "../../components/Util/Authorizer";
import NodeHistoryRestoreDialog from "../../components/Util/Dialogs/NodeHistoryRestoreDialog";

// Types & Constants
import { GridType } from "../../types/grid";
import getTabs from "./NodesTabs";
import { gridColumns } from "./NodeScheduleHistoryGridColumns";
import { gridColumns as gridColumnsItems } from "./NodeItemsHistoryGridColumns";
import { gridColumns as gridColumnsTemplates } from "./NodeTemplatesHistoryGridColumns";
import { gridColumns as gridColumnsZones } from "./NodeZonesHistoryGridColumns";

// User/Permissions
import {
  EndpointResources,
  Placeholders,
  UserPermissions,
  GridIDs,
  CarbonIcons,
  GridActions
} from "../../constants";

// Contexts/Hooks
import { useGrid } from "../../contexts/grid/useGrid";
import { useWindowSize } from "../../hooks/windowSize/useWindowSize";
import usePermissions from "../../hooks/auth/usePermissions";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import { useStore } from "../../contexts/store";
import CarbonBreadcrumb from "../../components/Breadcrumb/CarbonBreadcrumb";
import { useDayGroups } from "../../hooks/useDayGroups";

const NodeScheduleHistory = (): JSX.Element => {
  const { pathname } = useLocation();
  const { nodeId } = useParams();
  const { store } = useStore();
  const gridName = GridIDs.NodeHistory;
  const tabs = getTabs(EndpointResources.Nodes, nodeId!);
  const pagePermission = UserPermissions.CanManageNodeScheduleHistory;
  const { grids, setGrid } = useGrid();
  const { windowHeight } = useWindowSize();
  const { isGranted } = usePermissions();
  const { loadInitialData } = useDayGroups();
  const nodesGrid = grids.get(GridIDs.Nodes);
  let nodesTitle = "Nodes";

  if (nodesGrid) {
    if (store.activeNodeGroup?.nodeGroupName) {
      nodesTitle = store.activeNodeGroup?.nodeGroupName;
    }
  }

  const [tabIndex, setTabIndex] = useState(0);
  const [showRestoreDialog, setShowRestoreDialog] = useState(false);
  const [
    selectedNodeProcessUpdateHistoryID,
    setSelectedNodeProcessUpdateHistoryID
  ] = useState<number | null>(null); // control field for Items and Templates grids

  const currentTab = {
    index: tabs.findIndex((tab) => tab.resourceEndpoint === pathname),
    tab: tabs.find((tab) => tab.resourceEndpoint === pathname)
  };

  const actionLabels: GridToolbarActionLabels = {
    singular: "node schedule history",
    plural: "node schedule histories",
    titleSingular: "Node Schedule History",
    titlePlural: "Node Schedule Histories"
  };

  // Update the selected row's ID for restore
  useLayoutEffect(() => {
    if (grids.get(GridIDs.NodeHistory)) {
      if (grids.get(GridIDs.NodeHistory)?.state.selectedRowData) {
        const historyRowData: any = grids.get(GridIDs.NodeHistory)?.state
          .selectedRowData;
        setSelectedNodeProcessUpdateHistoryID(
          historyRowData.NodeProcessUpdateHistoryID
        );
      } else {
        setSelectedNodeProcessUpdateHistoryID(-1);
        setGridEndpointParams(GridIDs.NodeTemplatesHistory, -1);
        setGridEndpointParams(GridIDs.NodeItemHistory, -1);
      }
    }
  }, [
    grids.get(GridIDs.NodeHistory)?.state.selectedRowData,
    grids.get(GridIDs.NodeHistory)?.state.pathname
  ]);

  // Once Node Templates History grid is set, update and fetch Zone history data
  useEffect(() => {
    if (grids.get(GridIDs.NodeTemplatesHistory)) {
      const historyRowData: any = grids.get(GridIDs.NodeTemplatesHistory)?.state
        .selectedRowData;
      if (historyRowData) {
        if (grids.get(GridIDs.NodeZonesHistory)) {
          setGrid({
            type: GridActions.toggleRefreshGrid,
            payload: { gridId: GridIDs.NodeZonesHistory, gridData: true }
          });
        }
      }
    }
  }, [grids.get(GridIDs.NodeTemplatesHistory)?.state.selectedRowData]);

  // Update the templates/items grids when main history grid selected row changes, but do this synchronously so we know grids already exist.
  useLayoutEffect(() => {
    // jon, 1/16/22: Make sure history grid actually exists first before doing anything with the selected record id.
    if (
      grids.get(GridIDs.NodeHistory) &&
      selectedNodeProcessUpdateHistoryID !== null
    ) {
      console.log(
        `Selected history record changed or grid pathname changed. history id=${selectedNodeProcessUpdateHistoryID}`,
        grids.get(GridIDs.NodeTemplatesHistory)?.state.pathname
      );

      if (selectedNodeProcessUpdateHistoryID === -1) {
        setGridEndpointParams(GridIDs.NodeTemplatesHistory, -1);
        setGridEndpointParams(GridIDs.NodeItemHistory, -1);
      } else {
        if (grids.get(GridIDs.NodeTemplatesHistory)) {
          setGridEndpointParams(
            GridIDs.NodeTemplatesHistory,
            selectedNodeProcessUpdateHistoryID
          );
        }

        if (grids.get(GridIDs.NodeItemHistory)) {
          setGridEndpointParams(
            GridIDs.NodeItemHistory,
            selectedNodeProcessUpdateHistoryID
          );
        }
      }
    }
  }, [selectedNodeProcessUpdateHistoryID]);

  // will, 2/3/22: Added NodeHistory grid effect dependency to trigger when pressing 'Reload' button
  useLayoutEffect(() => {
    if (document) {
      const gridWrapper = document.querySelector(
        "div.carbon-grid-wrapper"
      ) as HTMLDivElement;
      if (gridWrapper) {
        const mainGridHeight = gridWrapper.offsetHeight - 55;
        const tabGridHeight = gridWrapper.offsetHeight - 103;

        // sizing for main grid (due to requirement to move grid down to fit record count)
        setGrid({
          type: GridActions.resizeGrid,
          payload: { gridId: gridName, gridData: mainGridHeight }
        });

        // sizing for Items grid
        setGrid({
          type: GridActions.resizeGrid,
          payload: { gridId: GridIDs.NodeItemHistory, gridData: tabGridHeight }
        });

        // sizing for Templates grid
        if (grids.get(GridIDs.NodeTemplatesHistory)) {
          setGrid({
            type: GridActions.resizeGrid,
            payload: {
              gridId: GridIDs.NodeTemplatesHistory,
              gridData: tabGridHeight
            }
          });
        }
      }
    }
  }, [
    windowHeight,
    grids.get(GridIDs.NodeHistory),
    grids.get(GridIDs.NodeItemHistory),
    grids.get(GridIDs.NodeTemplatesHistory),
    grids.get(GridIDs.NodeZonesHistory)
  ]);

  const setGridEndpointParams = (
    gridId: string,
    newNodeProcessUpdateHistoryId: number
  ) => {
    const gridData = grids.get(gridId);

    if (gridData) {
      let newEndpoint: string = "";
      if (gridId === GridIDs.NodeTemplatesHistory)
        newEndpoint = `/odata/${Placeholders.selectedNodeProcessUpdateHistoryID}/gridnodetemplatehistories?$count=true&`;
      if (gridId === GridIDs.NodeItemHistory)
        newEndpoint = `/odata/${Placeholders.selectedNodeProcessUpdateHistoryID}/gridnodeitemhistories?$count=true&`;

      // jon, 1/4/22: Set endpoint to empty string when no schedule history record selected to avoid calling API.
      if (newNodeProcessUpdateHistoryId === -1) {
        newEndpoint = "";
      } else {
        newEndpoint = newEndpoint.replace(
          Placeholders.selectedNodeProcessUpdateHistoryID,
          `${newNodeProcessUpdateHistoryId}`
        );
      }

      const existingOdataEndpoint = gridData.endpoints?.gridODataEndpoint ?? "";

      if (newEndpoint !== existingOdataEndpoint) {
        setGrid({
          type: GridActions.setGridEndpoint,
          payload: { gridId: gridId, gridData: newEndpoint }
        });
      }
    }
  };

  const newGridColumns = [
    {
      field: "NodeID",
      title: "Node ID",
      width: "150px",
      defaultShow: false,
      filter: "numeric",
      editable: false,
      required: false,
      canBeSaved: true,
      defaultValue: nodeId
    },
    ...gridColumns
  ];

  const gridSettings: GridType = {
    endpoints: {
      gridODataEndpoint: `/odata/${Placeholders.companyID}/${Placeholders.nodeTabNodeId}/gridnodeschedulehistories?$count=true&`,
      parentGridApiEndpoint: `/api/${Placeholders.companyID}/${EndpointResources.Nodes}/${Placeholders.nodeTabNodeId}`
    },
    records: [],
    total: 0,
    data: [],
    lookups: {},
    dataState: {
      pageSize: 25,
      take: 50,
      skip: 0,
      sort: [{ field: "StatusChangedDate", dir: "desc" }]
    },
    dataItemKey: "NodeProcessUpdateHistoryID",
    singularEntityName: actionLabels.singular,
    pluralEntityName: "Records",
    columns: newGridColumns,
    showCopyBtn: false,
    showDeleteBtn: false,
    parentGridId: GridIDs.Nodes,
    state: {
      editMode: false,
      insertMode: false,
      selectedState: {},
      selectedRow: -1,
      showQuickFilter: false,
      lastSaveDate: null,
      showSavingIndicator: false,
      lockoutMode: false
    }
  };

  const gridSettingsItems: GridType = {
    // jon, 1/4/22: Set endpoint to empty initially to avoid unncessary API call and a race condition caused by two loads in a row.
    endpoints: {
      gridODataEndpoint: ``
    },
    records: [],
    total: 0,
    data: [],
    lookups: {},
    dataState: {
      pageSize: 25,
      take: 50,
      skip: 0,
      sort: [{ field: "ItemName", dir: "asc" }]
    },
    dataItemKey: "ItemID",
    singularEntityName: "Item",
    pluralEntityName: "Items",
    columns: gridColumnsItems,
    showCopyBtn: false,
    showDeleteBtn: false,
    state: {
      editMode: false,
      insertMode: false,
      selectedState: {},
      selectedRow: -1,
      showQuickFilter: false,
      lastSaveDate: null,
      showSavingIndicator: false,
      lockoutMode: false
    }
  };

  const detailGridName = GridIDs.NodeZonesHistory;

  const zonesSubgrid = (props: GridDetailRowProps): JSX.Element => {
    const dataItem = props.dataItem;

    const newGridColumnsZones = [
      {
        field: "HistoryNodeScheduleID",
        title: "History Node Schedule ID",
        width: "150px",
        defaultShow: false,
        filter: "numeric",
        editable: false,
        required: false,
        canBeSaved: true,
        defaultValue: dataItem.HistoryNodeScheduleID
      },
      ...gridColumnsZones
    ];

    const gridSettingsZones: GridType = {
      endpoints: {
        gridODataEndpoint: `/odata/${Placeholders.HistoryNodeScheduleID}/gridnodezonehistories?$count=true&`
      },
      records: [],
      total: 0,
      data: [],
      lookups: {},
      dataState: {
        pageSize: 25,
        take: 50,
        skip: 0,
        sort: [{ field: "SeqNum", dir: "asc" }]
      },
      dataItemKey: "HistoryScheduleZoneID",
      singularEntityName: "zone",
      pluralEntityName: "zones",
      columns: newGridColumnsZones,
      showCopyBtn: false,
      showDeleteBtn: false,
      isSubgrid: true,
      state: {
        editMode: false,
        insertMode: false,
        selectedState: {},
        selectedRow: -1,
        showQuickFilter: false,
        lastSaveDate: null,
        showSavingIndicator: false,
        lockoutMode: false
      }
    };

    return (
      <Box
        fill
        style={{
          overflowY: "hidden",
          overflowX: "scroll",
          padding: "0 0 10px 20px"
        }}
      >
        <CarbonGrid gridId={detailGridName} gridSettings={gridSettingsZones} />
      </Box>
    );
  };

  const gridSettingsTemplates: GridType = {
    // jon, 1/4/22: Set endpoint to empty initially to avoid unncessary API call and a race condition caused by two loads in a row.
    endpoints: {
      gridODataEndpoint: ``
    },
    records: [],
    total: 0,
    data: [],
    lookups: {},
    dataState: {
      pageSize: 25,
      take: 50,
      skip: 0,
      sort: [
        {
          field: "ScheduleWkDay",
          dir: "asc"
        },
        {
          field: "NodeDayPartName",
          dir: "asc"
        },
        {
          field: "DisplayOrder",
          dir: "asc"
        },
        {
          field: "TemplateName",
          dir: "asc"
        }
      ]
    },
    dataItemKey: "HistoryNodeScheduleID",
    singularEntityName: "template",
    pluralEntityName: "templates",
    columns: gridColumnsTemplates,
    showCopyBtn: false,
    showDeleteBtn: false,
    // jon, 1/16/22: Tell grid not to refresh when path changes because we want the refresh to be controlled completely by schedule history record selection
    autoRefreshOnPathChange: false,
    detailRow: { subgridId: [detailGridName], content: zonesSubgrid },
    state: {
      editMode: false,
      insertMode: false,
      selectedState: {},
      selectedRow: -1,
      showQuickFilter: false,
      lastSaveDate: null,
      showSavingIndicator: false,
      lockoutMode: false
    }
  };

  const cancelBtn: GridToolbarItem = {
    show: false,
    enable: false,
    tooltip: ""
  };

  const saveBtn: GridToolbarItem = {
    show: false,
    enable: false,
    tooltip: ""
  };

  const addBtn: GridToolbarItem = {
    show: true,
    enable: true,
    tooltip: isGranted(pagePermission)
      ? "Restore Template"
      : "You do not have permission to restore templates.",
    onClick: () => setShowRestoreDialog(!showRestoreDialog),
    labelOverride: "Restore",
    iconOverride: CarbonIcons.Restore
  };

  const editModeBtn: GridToolbarItem = {
    show: false,
    enable: false,
    tooltip: ""
  };

  const previewBtn: GridToolbarItem = {
    show: true,
    enable: true,
    tooltip: "Open the viewer to preview node schedule",
    toggledOn: false
  };

  const handleTabSelect = (e: TabStripSelectEventArguments) => {
    setTabIndex(e.selected);

    // jon, 1/4/22: Refresh grid when tab changes. This avoids old data sometimes showing on tab switch.
    if (e.selected === 0 && grids.get(GridIDs.NodeTemplatesHistory)) {
      setGrid({
        type: GridActions.toggleRefreshGrid,
        payload: { gridId: GridIDs.NodeTemplatesHistory, gridData: true }
      });
    } else if (e.selected === 1 && grids.get(GridIDs.NodeItemHistory)) {
      setGrid({
        type: GridActions.toggleRefreshGrid,
        payload: { gridId: GridIDs.NodeItemHistory, gridData: true }
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Carbon | Node History</title>
      </Helmet>
      <Authorizer
        canView={pagePermission}
        auditLabel={`${actionLabels.titleSingular} screen`}
      >
        {showRestoreDialog && (
          <NodeHistoryRestoreDialog
            nodeId={nodeId}
            nodeProcessUpdateHistoryId={
              selectedNodeProcessUpdateHistoryID ?? -1
            }
            onAcceptCallback={() => {
              setShowRestoreDialog(!showRestoreDialog);

              // jon, 1/19/22: If user has visited the schedule page, refresh that page by loading day group data again.
              if (grids.get(GridIDs.NodeTemplates)) {
                loadInitialData();
              }

              // jon, 2/10/22: If user has visited the Nodes Text & Prices page, refresh that page's grid by deleting it so it will be created again on next visit..
              if (grids.get(GridIDs.NodeItems)) {
                setGrid({
                  type: GridActions.delete,
                  payload: {
                    gridId: GridIDs.NodeItems,
                    gridData: null
                  }
                });
              }
            }}
            onRejectCallback={() => setShowRestoreDialog(!showRestoreDialog)}
          />
        )}
        <Box align="start" fill>
          <CarbonBreadcrumb
            backLinkTitle={nodesTitle}
            backLinkUrl="/app/nodes"
            pageTitle={currentTab.tab?.tabTitle ?? ""}
          />
          <CarbonGridToolbar
            gridId={gridName}
            screenIcon={CarbonIcons.Schedule}
            screenTitle={""}
            screenSubtitle={""}
            permissions={pagePermission}
            actionLabels={actionLabels}
            getScreenTitleFromParentRow={(rowData: { [key: string]: any }) => {
              return rowData.NodeName;
            }}
            getScreenSubtitleFromParentRow={(rowData: {
              [key: string]: any;
            }) => {
              return rowData.CompanyName;
            }}
            cancelBtn={cancelBtn}
            saveBtn={saveBtn}
            addBtn={addBtn}
            editModeBtn={editModeBtn}
            publishBtn={true}
            previewBtn={previewBtn}
          />
          <CarbonTabStrip carbonTabs={tabs} selectedTab={currentTab.index} />
          <Box
            fill
            className="carbon-grid-wrapper"
            margin={{ right: "medium" }}
            pad={{ top: "20px" }}
            background="var(--carbon-white)"
            elevation="medium"
            round="xsmall"
            direction="row"
          >
            <Box pad="10px">
              <CarbonGrid gridId={gridName} gridSettings={gridSettings} />
            </Box>
            <Box fill className="carbon-grid-wrapper" pad="10px">
              <TabStrip selected={tabIndex} onSelect={handleTabSelect}>
                <TabStripTab title={"Schedule"} />
                <TabStripTab title={"Text & Prices"} />
              </TabStrip>
              {tabIndex === 0 && (
                <CarbonGrid
                  gridId={GridIDs.NodeTemplatesHistory}
                  gridSettings={gridSettingsTemplates}
                />
              )}
              {tabIndex === 1 && (
                <CarbonGrid
                  gridId={GridIDs.NodeItemHistory}
                  gridSettings={gridSettingsItems}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Authorizer>
    </>
  );
};

export default NodeScheduleHistory;
