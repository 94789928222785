import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { FC } from "react";
import { Box } from "grommet";
import { CarbonIcons } from "../../../constants";
import { GlobalChangesGetAffectedCountResultContract } from "../../../types";

export interface IGlobalChangeApplyConfirmationDialogProps {
  applyResult: GlobalChangesGetAffectedCountResultContract;
  isAddTemplateZone: boolean;
  onAcceptCallback: () => void;
  onRejectCallback: () => void;
}

const GlobalChangeApplyConfirmationDialog: FC<IGlobalChangeApplyConfirmationDialogProps> =
  ({ applyResult, isAddTemplateZone, onAcceptCallback, onRejectCallback }) => {
    const CustomTitleBar = () => {
      return (
        <h1>
          {CarbonIcons.WarningDialog}
          <br />
          {"Please Confirm"}
        </h1>
      );
    };

    return (
      <Dialog title={<CustomTitleBar />} closeIcon={false}>
        <Box>
          {!isAddTemplateZone && (
            <p>
              {`This global change operation will affect ${applyResult.scheduleZoneCount?.toLocaleString()} Schedule Zone ${
                applyResult.scheduleZoneCount === 1 ? "record" : "records"
              } in the following Nodes. Do you want to continue?`}
            </p>
          )}
          {isAddTemplateZone && (
            <p>
              {`This global change operation will add the selected content to ${applyResult.scheduleZoneCount?.toLocaleString()} Template ${
                applyResult.scheduleZoneCount === 1 ? "Zone" : "Zones"
              } in the following Nodes. Do you want to continue?`}
            </p>
          )}
          <div
            style={{
              maxHeight: "400px",
              overflowY: "scroll",
              marginTop: "20px",
              marginBottom: "20px"
            }}
          >
            {applyResult.nodes?.map((node, i) => {
              return (
                <div
                  style={{
                    paddingBottom: "4px"
                  }}
                  key={i}
                >
                  {node.NodeName}
                </div>
              );
            })}
          </div>
        </Box>
        <DialogActionsBar>
          <Button onClick={onRejectCallback}>
            {CarbonIcons.Close}
            {"Cancel"}
          </Button>
          <Button autoFocus={true} primary={true} onClick={onAcceptCallback}>
            {CarbonIcons.Check}
            {"Yes, proceed"}
          </Button>
        </DialogActionsBar>
      </Dialog>
    );
  };

export default GlobalChangeApplyConfirmationDialog;
