import apiClient from "..";
import { useParams } from "react-router-dom";
import { GridUpdateRecordContract, Result, ResultError } from "../../types";
import { AxiosResponse } from "axios";
import useApi from "../../hooks/api/useApi";
import { useStore } from "../../contexts/store";
import { GridIDs, Placeholders } from "../../constants";
import { useGrid } from "../../contexts/grid/useGrid";

const UpdateGridRecordApi = (gridId: string) => {
  const { store } = useStore();
  const { grids } = useGrid();
  const {
    nodeId,
    userId,
    roleId,
    patchId,
    typeId,
    templateId,
    stencilId,
    mediaId,
    packageId,
    priceScheduleId
  } = useParams();

  const gridUpdateRecordPut = (
    updateRecordContract: GridUpdateRecordContract
  ): Promise<AxiosResponse> => {
    if (!updateRecordContract.endpoint) {
      throw new Error(
        `DEVELOPER: You must specify the gridApiEndpoint in your page if you want to support grid updates for grid ${gridId}.`
      );
    }

    let cleanEndpoint = updateRecordContract.endpoint;
    cleanEndpoint = `${cleanEndpoint.replace(
      Placeholders.companyID,
      `${store.activeCompany!.companyId}`
    )}`;

    // Tab Data - Tabbed screens use route data in API call
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.nodeTabNodeId,
      `${nodeId}`
    );
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.userTabUserId,
      `${userId}`
    );
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.roleTabRoleId,
      `${roleId}`
    );
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.patchTabPatchId,
      `${patchId}`
    );
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.userDefinedTypesTabUdtId,
      `${typeId}`
    );
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.templatesTabTemplateId,
      `${templateId}`
    );
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.stencilsTabStencilId,
      `${stencilId}`
    );
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.mediaTabMediaId,
      `${mediaId}`
    );
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.packagesTabPackageId,
      `${packageId}`
    );
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.priceSchedulesTabPriceScheduleId,
      `${priceScheduleId}`
    );

    // Overriden endpoints could contain a placeholder for selected id. If so, replace that. Otherwise, append it.
    if (cleanEndpoint.indexOf(Placeholders.selectedRecordID) >= 0) {
      cleanEndpoint = cleanEndpoint.replace(
        Placeholders.selectedRecordID,
        `${updateRecordContract.id}`
      );
    } else {
      cleanEndpoint = cleanEndpoint + `/${updateRecordContract.id}`;
    }

    // If grid has daygroup/daypart values, replace placeholders with selected day group days and day part from grid. Hardcoding gridid here
    //   since day groups and day part ids are always in NodeTemplates grid.
    if (grids.get(GridIDs.NodeTemplates)?.state.selectedDayGroupDays) {
      cleanEndpoint = cleanEndpoint.replace(
        Placeholders.nodeScheduleSelectedDayGroupDays,
        `${grids.get(GridIDs.NodeTemplates)!.state.selectedDayGroupDays}`
      );
    }
    if (grids.get(GridIDs.NodeTemplates)?.state.activeDayPart?.id) {
      cleanEndpoint = cleanEndpoint.replace(
        Placeholders.nodeScheduleSelectedDayPartID,
        `${grids.get(GridIDs.NodeTemplates)!.state.activeDayPart!.id}`
      );
    }

    console.log(
      `UPDATE GRID RECORD: ${cleanEndpoint}`,
      updateRecordContract.data
    );
    const response = apiClient.put(
      cleanEndpoint,
      JSON.stringify(updateRecordContract.data)
    );

    return response;
  };

  const { loading: isSaving, request: gridRequest } =
    useApi(gridUpdateRecordPut);

  const updateRecord = async (
    updateRecordContract: GridUpdateRecordContract
  ): Promise<Result<string>> => {
    let result: Result<string>;
    try {
      result = await gridRequest(updateRecordContract);
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }
    return result;
  };

  return {
    updateRecord,
    isSaving
  };
};

export default UpdateGridRecordApi;
