import { Placeholders } from "../../constants";
import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  {
    field: "MediaMoviePosterID",
    title: "MediaMoviePosterID",
    width: "250px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: -1
  },
  // {
  //   field: "MediaID",
  //   title: "MediaID",
  //   width: "150px",
  //   defaultShow: false,
  //   filter: "numeric",
  //   editable: false,
  //   required: false,
  //   canBeSaved: true,
  //   defaultValue: "[grdMediaFiles.MediaID]",
  //   systemHidden: true
  // },
  {
    field: "CompanyID",
    title: "CompanyID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: true,
    canBeSaved: true,
    company: true,
    systemHidden: true,
    defaultValue: Placeholders.companyID
  },
  {
    field: "CompanyName",
    title: "Company",
    titlePlural: "Companies",
    width: "310px",
    maxLength: 50,
    defaultShow: true,
    filter: "text",
    editable: false,
    editor: "lookup",
    required: false,
    canBeSaved: false,
    companyMaster: true,
    valueField: "CompanyID",
    defaultValue: Placeholders.companyName
  },
  {
    field: "MovieCode",
    title: "Movie Code",
    width: "375px",
    maxLength: 100,
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    canBeSaved: true
  },
  {
    field: "MovieTitle",
    title: "Movie Title",
    width: "",
    maxLength: 256,
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    canBeSaved: true
  }
];
