import { GridColumns } from "../../types/grid";

export const onStencilZoneRowChange = (
  rowData: { [key: string]: any },
  columns: GridColumns[]
): { changedColumns?: GridColumns[] } => {
  const changedColumns = setGridColumnPropertiesByContentTypeData(
    columns,
    rowData.ContentType
  );
  return { changedColumns };
};

export const onContentTypeChange = (
  value: any,
  previousValue: any,
  rowData: { [key: string]: any },
  columns: GridColumns[]
): { changedData?: { [key: string]: any }; changedColumns?: GridColumns[] } => {
  if (value === previousValue) return {};

  // Reset all content fields when content type changes
  // will, 3/23/22: added in case for switching from media so TextStyleID is not required
  let changedData;
  if (previousValue === "M" || (value !== "M" && previousValue !== "M")) {
    changedData = {
      TextStyleName: null,
      ZoneContentFilter: null,
      ZonePreviewContent: null,
      ItemDisplayType: "---"
    };
  } else {
    changedData = {
      TextStyleID: null,
      TextStyleName: null,
      ZoneContentFilter: null,
      ZonePreviewContent: null,
      ItemDisplayType: "---"
    };
  }

  const changedColumns = setGridColumnPropertiesByContentTypeData(
    columns,
    value
  );
  return { changedData, changedColumns };
};

export const setGridColumnPropertiesByContentTypeData = (
  columns: GridColumns[],
  contentType: string
): GridColumns[] => {
  // default at static text
  let changedColumns: GridColumns[] = [
    {
      ...columns.find((c) => c.field === "ItemDisplayType")!,
      editable: false
    },
    {
      ...columns.find((c) => c.field === "TextStyleName")!,
      editable: true
    },
    {
      ...columns.find((c) => c.field === "ZoneContentFilter")!,
      editable: false
    },
    {
      ...columns.find((c) => c.field === "ZonePreviewContent")!,
      editable: true
    }
  ];

  switch (contentType) {
    case "S":
      changedColumns = [
        {
          ...columns.find((c) => c.field === "ItemDisplayType")!,
          editable: false
        },
        {
          ...columns.find((c) => c.field === "TextStyleName")!,
          editable: true
        },
        {
          ...columns.find((c) => c.field === "ZoneContentFilter")!,
          editable: false
        },
        {
          ...columns.find((c) => c.field === "ZonePreviewContent")!,
          editable: true
        }
      ];
      break;
    case "M":
      changedColumns = [
        {
          ...columns.find((c) => c.field === "ItemDisplayType")!,
          editable: false
        },
        {
          ...columns.find((c) => c.field === "TextStyleName")!,
          editable: false
        },
        {
          ...columns.find((c) => c.field === "ZoneContentFilter")!,
          editable: true
        },
        {
          ...columns.find((c) => c.field === "ZonePreviewContent")!,
          editable: true
        }
      ];
      break;
    case "I":
      changedColumns = [
        {
          ...columns.find((c) => c.field === "ItemDisplayType")!,
          editable: true
        },
        {
          ...columns.find((c) => c.field === "TextStyleName")!,
          editable: true
        },
        {
          ...columns.find((c) => c.field === "ZoneContentFilter")!,
          editable: true
        },
        {
          ...columns.find((c) => c.field === "ZonePreviewContent")!,
          editable: true
        }
      ];
      break;
    default:
      break;
  }

  return changedColumns;
};
