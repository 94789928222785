import { UserPermissions } from "../../constants";
import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  {
    field: "ItemID",
    title: "ItemID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "PriceScheduleItemID",
    title: "PriceScheduleItemID",
    width: "200px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    defaultValue: -1,
    systemHidden: true
  },
  {
    field: "PriceScheduleID",
    title: "PriceScheduleID",
    width: "200px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "ItemName",
    title: "Item",
    width: "500px",
    maxLength: 50,
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "ItemDesc",
    title: "Description",
    width: "350px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "ItemPrice",
    title: "Default Price",
    width: "300px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "NewPrice",
    title: "Price",
    width: "375px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    canBeSaved: true,
    maxLength: 256,
    overrideColumn: "ItemPrice",
    overrideFilterColumn: "OverrideFilterPrice",
    overridePermission: UserPermissions.PriceSchedulesPerms
  }
];
