/*eslint-disable*/
import { Helmet } from "react-helmet-async";
import { useLocation, useParams } from "react-router-dom";
import { Box } from "grommet";

// Components
import CarbonGridToolbar, {
  GridToolbarActionLabels,
  GridToolbarItem
} from "../../components/CarbonGrid/Toolbar/CarbonGridToolbar";
import CarbonTabStrip from "../../components/CarbonTabs/CarbonTabStrip";
import CarbonGrid from "../../components/CarbonGrid/CarbonGrid";
import Authorizer from "../../components/Util/Authorizer";

// Types & Constants
import { GridType, GridColumns } from "../../types/grid";
import getTabs from "./NodesTabs";
import { gridColumns } from "./NodeDisplaysGridColumns";

// User/Permissions
import usePermissions from "../../hooks/auth/usePermissions";
import {
  EndpointResources,
  Placeholders,
  UserPermissions,
  GridIDs,
  CarbonIcons,
  GridActions
} from "../../constants";

// Events
import { useGrid } from "../../contexts/grid/useGrid";
import { useStore } from "../../contexts/store";
import CarbonBreadcrumb from "../../components/Breadcrumb/CarbonBreadcrumb";
import { useEffect } from "react";

const NodeDisplays = (): JSX.Element => {
  const { pathname } = useLocation();
  const { nodeId } = useParams();
  const { store } = useStore();
  const { canUpdate, isGranted } = usePermissions();
  const gridName = GridIDs.NodeDisplays;
  const tabs = getTabs(EndpointResources.Nodes, nodeId!);
  const pagePermission = UserPermissions.CanManageNodeDisplays;
  const { grids, setGrid } = useGrid();
  const nodesGrid = grids.get(GridIDs.Nodes);

  let nodesTitle = "Nodes";

  let newGridColumns: GridColumns[] = [
    {
      field: "NodeID",
      title: "NodeID",
      width: "150px",
      defaultShow: false,
      filter: "numeric",
      editable: false,
      required: false,
      canBeSaved: true,
      defaultValue: nodeId,
      systemHidden: true
    },
    ...gridColumns
  ];

  if (nodesGrid) {
    if (store.activeNodeGroup?.nodeGroupName) {
      nodesTitle = store.activeNodeGroup?.nodeGroupName;
    }
  }

  // will, 2/2/22: re-configured effect to match similar effect on StencilZones page to
  // improve consistancy and add in updated layout menu options
  useEffect(() => {
    if (
      grids.get(gridName)?.state.parentSelectedRowData &&
      grids.get(gridName)!.columns
    ) {
      const isMultiplayer =
        grids.get(gridName)!.state.parentSelectedRowData!.IsMultiPlayer;

      // allow super users to update this field
      newGridColumns = grids.get(gridName)!.columns!.map((column: any) => {
        const tempColumn = { ...column };
        if (tempColumn.field === "CarbonPlayerID") {
          if (isMultiplayer) {
            tempColumn.editable = isGranted(UserPermissions.IsSuperUser);
          } else {
            tempColumn.editable = false;
          }
        }

        if (tempColumn.field === "IsParent")
          tempColumn.editable = isMultiplayer ?? false;

        return tempColumn;
      });

      // update grid view and column selector based off multiplayer value
      if (isMultiplayer !== undefined) {
        const multiPlayerOnlyFields = [
          "MicroHealth",
          "UpdateStatus",
          "CarbonPlayerID",
          "SerialNumber",
          "MicroDesc",
          "MicroLastProcessed",
          "PatchVersion",
          "MicroProperties"
        ];

        // only update if not using a custom layout so the defaults are not restored every
        // time a layout is updated
        if (!store.user?.userSettings.layouts.get(gridName)) {
          // column settings for grid - inherit superuser setting update from CarbonPlayerID
          newGridColumns = newGridColumns.map((column: any) => {
            const tempColumn = { ...column };
            if (multiPlayerOnlyFields.includes(tempColumn.field)) {
              if (isMultiplayer) {
                tempColumn.defaultShow = true;
                tempColumn.show = true;
              } else {
                tempColumn.defaultShow = false;
                tempColumn.show = false;
              }
            }

            return tempColumn;
          });

          // column settings for visibility selector
          const newGridColumnVisibilitySelector = grids
            .get(gridName)!
            .state.visibilitySelectorColumns!.map((column: any) => {
              const tempColumn = { ...column };
              if (multiPlayerOnlyFields.includes(tempColumn.field)) {
                if (isMultiplayer) {
                  tempColumn.defaultShow = true;
                  tempColumn.show = true;
                } else {
                  tempColumn.defaultShow = false;
                  tempColumn.show = false;
                }
              }

              return tempColumn;
            });

          setGrid({
            type: GridActions.setVisiblitySelectorColumns,
            payload: {
              gridId: gridName,
              gridData: {
                columns: newGridColumnVisibilitySelector
              }
            }
          });
        } else {
          // will, 2/8/22: Added updates for saved layouts having different editability
          // column settings for grid - updating saved layout
          newGridColumns = newGridColumns.map((column: any) => {
            const tempColumn = { ...column };
            if (tempColumn.field === "MicroDesc") {
              if (isMultiplayer) {
                tempColumn.editable = true;
              } else {
                tempColumn.editable = false;
              }
            }

            if (tempColumn.field === "CarbonPlayerID") {
              if (isMultiplayer) {
                tempColumn.editable = isGranted(UserPermissions.IsSuperUser);
              } else {
                tempColumn.editable = false;
              }
            }

            if (tempColumn.field === "IsParent")
              tempColumn.editable = isMultiplayer ?? false;

            return tempColumn;
          });
        }
      }

      setGrid({
        type: GridActions.updateColumnState,
        payload: {
          gridId: gridName,
          gridData: { columns: newGridColumns }
        }
      });
    }
  }, [
    grids.get(gridName)?.state.parentSelectedRowData,
    store.user?.userSettings.layouts.get(gridName)
  ]);

  const currentTab = {
    index: tabs.findIndex((tab) => tab.resourceEndpoint === pathname),
    tab: tabs.find((tab) => tab.resourceEndpoint === pathname)
  };

  const actionLabels: GridToolbarActionLabels = {
    singular: "node display",
    plural: "node displays",
    titleSingular: "Node Display",
    titlePlural: "Node Displays"
  };

  const gridSettings: GridType = {
    endpoints: {
      gridODataEndpoint: `/odata/${Placeholders.companyID}/${Placeholders.nodeTabNodeId}/gridnodedisplays?$count=true&`,
      gridApiEndpoint: `/api/${Placeholders.nodeTabNodeId}/nodedisplays`,
      parentGridApiEndpoint: `/api/${Placeholders.companyID}/${EndpointResources.Nodes}/${Placeholders.nodeTabNodeId}`,
      lookupEndpoints: [
        {
          lookupField: "CarbonPlayerID",
          endpoint: `/api/${Placeholders.companyID}/lookupmicroplayers`
        },
        {
          lookupField: "NodeDisplayTypeName",
          endpoint: `/api/${Placeholders.companyID}/lookupnodedisplaytypes`
        }
      ]
    },
    records: [],
    total: 0,
    data: [],
    lookups: {},
    dataState: {
      pageSize: 25,
      take: 50,
      skip: 0,
      sort: [{ field: "DisplayOrder", dir: "asc" }]
    },
    dataItemKey: "NodeDisplayID",
    singularEntityName: actionLabels.singular,
    pluralEntityName: actionLabels.plural,
    columns: newGridColumns,
    showCopyBtn: false,
    showDeleteBtn: canUpdate(pagePermission),
    parentGridId: GridIDs.Nodes,
    isSuperUser: isGranted(UserPermissions.IsSuperUser),
    state: {
      editMode: false,
      insertMode: false,
      selectedState: {},
      selectedRow: -1,
      showQuickFilter: false,
      lastSaveDate: null,
      showSavingIndicator: false,
      lockoutMode: false
    }
  };

  const previewBtn: GridToolbarItem = {
    show: true,
    enable: true,
    tooltip: "Open the viewer to preview node schedule",
    toggledOn: false
  };

  return (
    <>
      <Helmet>
        <title>Carbon | Node Displays</title>
      </Helmet>
      <Authorizer
        canView={pagePermission}
        auditLabel={`${actionLabels.titlePlural} screen`}
      >
        <Box align="start" fill>
          <CarbonBreadcrumb
            backLinkTitle={nodesTitle}
            backLinkUrl="/app/nodes"
            pageTitle={currentTab.tab?.tabTitle ?? ""}
          />
          <CarbonGridToolbar
            gridId={gridName}
            screenIcon={CarbonIcons.Displays}
            screenTitle={""}
            screenSubtitle={""}
            permissions={pagePermission}
            actionLabels={actionLabels}
            getScreenTitleFromParentRow={(rowData: { [key: string]: any }) => {
              return rowData.NodeName;
            }}
            getScreenSubtitleFromParentRow={(rowData: {
              [key: string]: any;
            }) => {
              return rowData.CompanyName;
            }}
            publishBtn={true}
            previewBtn={previewBtn}
          />
          <CarbonTabStrip carbonTabs={tabs} selectedTab={currentTab.index} />
          <Box fill className="carbon-grid-wrapper">
            <CarbonGrid gridId={gridName} gridSettings={gridSettings} />
          </Box>
        </Box>
      </Authorizer>
    </>
  );
};

export default NodeDisplays;
