// Base
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useImmer } from "use-immer";
import styled from "styled-components";

// Kendo/Grommet Components
import { MenuItem, MenuSelectEvent, Menu } from "@progress/kendo-react-layout";
import { Box } from "grommet";

// Custom Components
import NodeGroupDialog from "../Util/Dialogs/NodeGroupDialog";

// Hooks
import usePermissions from "../../hooks/auth/usePermissions";
import { useGrid } from "../../contexts/grid/useGrid";
import { useStore } from "../../contexts/store";

// Constants
import {
  UserPermissions,
  CarbonIcons,
  GridIDs,
  StoreActions,
  GridActions,
  EndpointResources as EndPts
} from "../../constants";

// API
import apiClient from "../../api";
import useApi from "../../hooks/api/useApi";

// Interfaces
export interface INodeGroupItem {
  nodeGroupId: number;
  nodeGroupName: string;
}

interface CarbonMenuProps {
  screenSize: string;
}

const CarbonMenu: React.FC<CarbonMenuProps> = (props): JSX.Element => {
  const baseRoute = `/${EndPts.App}`;
  const activeMenuDefault = {
    Nodes: false,
    Content: false,
    Layouts: false,
    Tools: false,
    Campaigns: false,
    Admin: false
  };

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { store, dispatch } = useStore();
  const { canView, canInsert } = usePermissions();
  const { grids, setGrid } = useGrid();
  const nodesGrid = grids.get(GridIDs.Nodes);

  const [nodeGroups, setNodeGroups] = useImmer<INodeGroupItem[]>([]);
  const [activeMenu, setActiveMenu] = useImmer(activeMenuDefault);

  const { request: getNodeGroupsRequest } = useApi(
    async (userCompanyId: number) =>
      await apiClient.get(`/api/${userCompanyId}/nodegroups`)
  );
  // load node groups on component mount and company change
  useEffect(() => {
    const userCompanyId =
      store.activeCompany?.companyId === -1
        ? store.user?.companyID
        : store.activeCompany?.companyId;

    if (userCompanyId !== undefined) {
      loadNodeGroups(userCompanyId);
    }
  }, [store.user?.companyID]);

  const loadNodeGroups = async (userCompanyId: number) => {
    // will, 6/8/22: Added check for NodeGroupsPerms because some roles can view nodes but not node groups and automatically grabbing the groups causes login failures in this case
    if (canView(UserPermissions.NodeGroupsPerms)) {
      // jon, 3/11/22: Change all direct apiClient calls to use the useApi hook for proper refresh token handling.
      const getNodeGroupsResponse = await getNodeGroupsRequest(userCompanyId);
      if (getNodeGroupsResponse.type === "error") return;
      const result = getNodeGroupsResponse.value;

      const nodeGroupsArray = result.data as any[];
      const menuValues = nodeGroupsArray.map((group) => {
        const nodeGroupItem: INodeGroupItem = {
          nodeGroupId: group.NodeGroupID,
          nodeGroupName: group.GroupName
        };
        return nodeGroupItem;
      });
      // sort the array
      menuValues.sort((a, b) => {
        return a.nodeGroupName > b.nodeGroupName
          ? 1
          : b.nodeGroupName > a.nodeGroupName
          ? -1
          : 0;
      });
      setNodeGroups(menuValues);
    }
  };

  // load node groups whenever active company changes and when a new node group is created
  useEffect(() => {
    // if node groups menu data does not include the new activeNodeId, this means we have added a new Node Group
    if (store.user?.companyID !== undefined)
      if (
        !nodeGroups.find(
          (val) => val.nodeGroupId === nodesGrid?.state.activeFilterId
        )
      ) {
        const userCompanyId =
          store.activeCompany?.companyId === -1
            ? store.user?.companyID
            : store.activeCompany?.companyId;

        if (userCompanyId !== undefined) {
          loadNodeGroups(userCompanyId);
        }
      }
  }, [
    store.activeCompany?.companyId,
    store.activeNodeGroup?.nodeGroupId,
    store.activeNodeGroup?.nodeGroupName
  ]);

  // On navigation, highlight the menu item that the destination page belongs to
  useEffect(() => {
    setActiveMenu(activeMenuDefault);

    if (pathname.includes(`${baseRoute}/${EndPts.Nodes}`)) {
      setActiveMenu((menu) => {
        menu.Nodes = true;
      });
    } else if (
      pathname.includes(`${baseRoute}/${EndPts.Media}`) ||
      pathname.includes(`${baseRoute}/${EndPts.Packages}`) ||
      pathname.includes(`${baseRoute}/${EndPts.Items}`) ||
      pathname.includes(`${baseRoute}/${EndPts.PriceSchedules}`)
    ) {
      setActiveMenu((menu) => {
        menu.Content = true;
      });
    } else if (
      pathname.includes(`${baseRoute}/${EndPts.Templates}`) ||
      pathname.includes(`${baseRoute}/${EndPts.Stencils}`) ||
      pathname.includes(`${baseRoute}/${EndPts.TextStyles}`) ||
      pathname.includes(`${baseRoute}/${EndPts.Fonts}`)
    ) {
      setActiveMenu((menu) => {
        menu.Layouts = true;
      });
    } else if (pathname.includes(`${baseRoute}/${EndPts.GlobalChanges}`)) {
      setActiveMenu((menu) => {
        menu.Tools = true;
      });
    } else if (
      pathname.includes(`${baseRoute}/${EndPts.CampaignsDashboard}`) ||
      pathname.includes(`${baseRoute}/${EndPts.Campaigns}`)
    ) {
      setActiveMenu((menu) => {
        menu.Campaigns = true;
      });
    } else if (
      pathname.includes(`${baseRoute}/${EndPts.Companies}`) ||
      pathname.includes(`${baseRoute}/${EndPts.Users}`) ||
      pathname.includes(`${baseRoute}/${EndPts.Roles}`) ||
      pathname.includes(`${baseRoute}/${EndPts.Patches}`) ||
      pathname.includes(`${baseRoute}/${EndPts.AuditTrail}`) ||
      pathname.includes(`${baseRoute}/${EndPts.UserDefinedTypes}`)
    ) {
      setActiveMenu((menu) => {
        menu.Admin = true;
      });
    }
  }, [pathname]);

  const handleAddNewNodeGroup = () => {
    dispatch({
      type: StoreActions.onAddNewNodeGroup,
      payload: {}
    });
  };

  const canViewNodes = (): boolean => {
    if (
      canView(UserPermissions.NodeGroupsPerms) ||
      canView(UserPermissions.NodesPerms)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const canViewContent = (): boolean => {
    if (
      canView(UserPermissions.MediaPerms) ||
      canView(UserPermissions.PackagesPerms) ||
      canView(UserPermissions.ItemsPerms) ||
      canView(UserPermissions.PriceSchedulesPerms)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const canViewLayouts = (): boolean => {
    if (
      canView(UserPermissions.TemplatesPerms) ||
      canView(UserPermissions.StencilsPerms) ||
      canView(UserPermissions.TextStylesPerms) ||
      canView(UserPermissions.FontsPerms)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const canViewTools = (): boolean => {
    if (canView(UserPermissions.CanMakeGlobalChanges)) {
      return true;
    } else {
      return false;
    }
  };

  const canViewAdmin = (): boolean => {
    if (
      canView(UserPermissions.CompaniesPerms) ||
      canView(UserPermissions.UsersPerms) ||
      canView(UserPermissions.RolesPerms) ||
      canView(UserPermissions.PatchesPerms) ||
      canView(UserPermissions.AuditTrailPerms) ||
      canView(UserPermissions.CampaignsPerms) ||
      canView(UserPermissions.UserDefinedTypesPerms) ||
      canView(UserPermissions.POSSystemsPerms)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const canViewCampaigns = (): boolean => {
    if (
      canView(UserPermissions.CanViewMarketingManagementDashboard) ||
      canView(UserPermissions.CampaignsPerms)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const NodesMenuItem = () => {
    return (
      <StyledBox
        direction="row"
        align="center"
        justify="center"
        style={{
          background: activeMenu.Nodes === true ? "var(--carbon-orange)" : "",
          height:
            props.screenSize === "xsmall" || props.screenSize === "small"
              ? "48px"
              : "auto"
        }}
        pad={{ vertical: "xsmall", horizontal: "small" }}
        gap="xsmall"
      >
        {CarbonIcons.NodesMenu}
        {props.screenSize === "xsmall" || props.screenSize === "small"
          ? ""
          : "Nodes"}
        {CarbonIcons.ExpandMore}
      </StyledBox>
    );
  };

  const ContentMenuItem = () => {
    return (
      <StyledBox
        direction="row"
        align="center"
        justify="center"
        style={{
          background: activeMenu.Content === true ? "var(--carbon-orange)" : "",
          height:
            props.screenSize === "xsmall" || props.screenSize === "small"
              ? "48px"
              : "auto"
        }}
        pad={{ vertical: "xsmall", horizontal: "small" }}
        gap="small"
      >
        {CarbonIcons.ContentMenu}
        <Box direction="row" align="center" gap="xsmall">
          {props.screenSize === "xsmall" || props.screenSize === "small"
            ? ""
            : "Content"}
          {CarbonIcons.ExpandMore}
        </Box>
      </StyledBox>
    );
  };

  const LayoutsMenuItem = () => {
    return (
      <StyledBox
        direction="row"
        align="center"
        justify="center"
        style={{
          background: activeMenu.Layouts === true ? "var(--carbon-orange)" : "",
          height:
            props.screenSize === "xsmall" || props.screenSize === "small"
              ? "48px"
              : "auto"
        }}
        pad={{ vertical: "xsmall", horizontal: "small" }}
        gap="xsmall"
      >
        {CarbonIcons.LayoutsMenu}
        {props.screenSize === "xsmall" || props.screenSize === "small"
          ? ""
          : "Layouts"}
        {CarbonIcons.ExpandMore}
      </StyledBox>
    );
  };

  const ToolsMenuItem = () => {
    return (
      <StyledBox
        direction="row"
        align="center"
        justify="center"
        style={{
          background: activeMenu.Tools === true ? "var(--carbon-orange)" : "",
          height:
            props.screenSize === "xsmall" || props.screenSize === "small"
              ? "48px"
              : "auto"
        }}
        pad={{ vertical: "xsmall", horizontal: "small" }}
        gap="xsmall"
      >
        {CarbonIcons.ToolsMenu}
        {props.screenSize === "xsmall" || props.screenSize === "small"
          ? ""
          : "Tools"}
        {CarbonIcons.ExpandMore}
      </StyledBox>
    );
  };

  const CampaignsMenuItem = () => {
    return (
      <CustomCampaignWrapper
        direction="row"
        align="center"
        justify="center"
        style={{
          background:
            activeMenu.Campaigns === true ? "var(--carbon-orange)" : "",
          height:
            props.screenSize === "xsmall" || props.screenSize === "small"
              ? "48px"
              : "auto"
        }}
        pad={{ vertical: "xsmall", horizontal: "small" }}
        gap="xsmall"
      >
        {CarbonIcons.CampaignsMenu}
        {props.screenSize === "xsmall" || props.screenSize === "small"
          ? ""
          : "Campaigns"}
        <Box className="drop-style" style={{ alignItems: "center" }}>
          {CarbonIcons.ExpandMore}
        </Box>
      </CustomCampaignWrapper>
    );
  };

  const AdminMenuItem = () => {
    return (
      <StyledBox
        direction="row"
        align="center"
        justify="center"
        style={{
          background: activeMenu.Admin === true ? "var(--carbon-orange)" : "",
          height:
            props.screenSize === "xsmall" || props.screenSize === "small"
              ? "48px"
              : "auto"
        }}
        pad={{ vertical: "xsmall", horizontal: "small" }}
        gap="xsmall"
      >
        {CarbonIcons.AdminMenu}
        {props.screenSize === "xsmall" || props.screenSize === "small"
          ? ""
          : "Admin"}
        {CarbonIcons.ExpandMore}
      </StyledBox>
    );
  };

  const onSelect = (event: MenuSelectEvent) => {
    const eventData = event.item?.data;
    const route = eventData?.route;
    const newNodeGroupCreate = eventData?.addNewNodeGroup;
    const nodeGroupItem: INodeGroupItem = eventData?.nodeGroupItem;

    // user clicked on a specific node group
    // jon, 1/14/22: Only set new node group if it has changed. This keeps nodes grid from refreshing unnecessarily when navigating to it.
    if (
      nodeGroupItem &&
      store.activeNodeGroup?.nodeGroupId !== nodeGroupItem.nodeGroupId
    ) {
      dispatch({
        type: StoreActions.onSelectNodeGroup,
        payload: {
          nodeGroupItem: nodeGroupItem
        }
      });

      // will, 3/24/22: Added nodes grid refresh here because if the page is not currently rendered and you switch node groups the effect
      // for refresh on the nodes page does not catch the update and it does not refresh. Pathname argument allows for current refresh to
      // continue use without multiple refreshes.
      // jon, 4/22/22: This logic needs to run anytime the user is switching nodes group from anywhere except the main Nodes grid page. Otherwise,
      //   the node group was not taking effect when switching from a subpage on Nodes.
      if (nodesGrid && pathname !== `${baseRoute}/${EndPts.Nodes}`) {
        setGrid({
          type: GridActions.toggleRefreshGrid,
          payload: { gridId: GridIDs.Nodes, gridData: true }
        });
      }
    }

    if (route) {
      navigate(route, { replace: true });
    }

    // user clicked on create new node group
    if (newNodeGroupCreate) {
      handleAddNewNodeGroup();
    }
  };

  const renderCreateNewNodeGroup = () => {
    return (
      <Box
        className="create-node-group"
        pad={"none"}
        direction={"row"}
        justify="center"
        align="center"
        style={{ color: "var(--carbon-blue)" }}
      >
        {CarbonIcons.Add}
        Create Group
      </Box>
    );
  };

  return (
    <>
      {store.showNodeGroupDialog === true && <NodeGroupDialog />}
      <Menu
        className="carbon-main-menu"
        style={{ height: "100%" }}
        onSelect={onSelect}
      >
        {canViewNodes() && (
          <MenuItem linkRender={NodesMenuItem}>
            {canView(UserPermissions.NodesPerms) && (
              <MenuItem
                data={{
                  route: `${baseRoute}/${EndPts.Nodes}`,
                  nodeGroupItem: {
                    nodeGroupId: -1,
                    nodeGroupName: ""
                  } as INodeGroupItem
                }}
                text="All Nodes"
              />
            )}
            {canView(UserPermissions.NodeGroupsPerms) && (
              <MenuItem disabled={true} text={"NODE GROUPS"} />
            )}
            {nodeGroups.map((nodeGroup, index) => {
              return (
                <MenuItem
                  key={`nodegroup-${index}`}
                  data={{
                    nodeGroupItem: nodeGroup,
                    route: `${baseRoute}/${EndPts.Nodes}`
                  }}
                  text={nodeGroup.nodeGroupName}
                />
              );
            })}
            {canInsert(UserPermissions.NodeGroupsPerms) && (
              <MenuItem
                data={{
                  addNewNodeGroup: true,
                  route: `${baseRoute}/${EndPts.Nodes}`
                }}
                render={renderCreateNewNodeGroup}
              />
            )}
          </MenuItem>
        )}
        {canViewContent() && (
          <MenuItem linkRender={ContentMenuItem}>
            {canView(UserPermissions.MediaPerms) && (
              <MenuItem
                data={{ route: `${baseRoute}/${EndPts.Media}` }}
                text="Media"
              />
            )}
            {canView(UserPermissions.PackagesPerms) && (
              <MenuItem
                data={{ route: `${baseRoute}/${EndPts.Packages}` }}
                text="Packages"
              />
            )}
            {canView(UserPermissions.ItemsPerms) && (
              <MenuItem
                data={{ route: `${baseRoute}/${EndPts.Items}` }}
                text="Text & Prices"
              />
            )}
            {canView(UserPermissions.PriceSchedulesPerms) && (
              <MenuItem
                data={{ route: `${baseRoute}/${EndPts.PriceSchedules}` }}
                text="Price Schedules"
              />
            )}
          </MenuItem>
        )}
        {canViewLayouts() && (
          <MenuItem linkRender={LayoutsMenuItem}>
            {canView(UserPermissions.TemplatesPerms) && (
              <MenuItem
                data={{ route: `${baseRoute}/${EndPts.Templates}` }}
                text="Templates"
              />
            )}
            {canView(UserPermissions.StencilsPerms) && (
              <MenuItem
                data={{ route: `${baseRoute}/${EndPts.Stencils}` }}
                text="Stencils"
              />
            )}
            {canView(UserPermissions.TextStylesPerms) && (
              <MenuItem
                data={{ route: `${baseRoute}/${EndPts.TextStyles}` }}
                text="Text Styles"
              />
            )}
            {canView(UserPermissions.FontsPerms) && (
              <MenuItem
                data={{ route: `${baseRoute}/${EndPts.Fonts}` }}
                text="Fonts"
              />
            )}
          </MenuItem>
        )}
        {canViewTools() && (
          <MenuItem linkRender={ToolsMenuItem}>
            {canView(UserPermissions.CanMakeGlobalChanges) && (
              <MenuItem
                data={{ route: `${baseRoute}/${EndPts.GlobalChanges}` }}
                text="Global Change"
              />
            )}
          </MenuItem>
        )}
        {canViewCampaigns() && (
          <MenuItem linkRender={CampaignsMenuItem}>
            {canView(UserPermissions.CanViewMarketingManagementDashboard) && (
              <MenuItem
                data={{ route: `${baseRoute}/${EndPts.CampaignsDashboard}` }}
                text="Dashboard"
              />
            )}
            {canView(UserPermissions.CampaignsPerms) && (
              <MenuItem
                data={{ route: `${baseRoute}/${EndPts.Campaigns}` }}
                text="Campaigns"
              />
            )}
          </MenuItem>
        )}
        {canViewAdmin() && (
          <MenuItem linkRender={AdminMenuItem}>
            {canView(UserPermissions.CompaniesPerms) && (
              <MenuItem
                data={{ route: `${baseRoute}/${EndPts.Companies}` }}
                text="Companies"
              />
            )}
            {canView(UserPermissions.UsersPerms) && (
              <MenuItem
                data={{ route: `${baseRoute}/${EndPts.Users}` }}
                text="Users"
              />
            )}
            {canView(UserPermissions.RolesPerms) && (
              <MenuItem
                data={{ route: `${baseRoute}/${EndPts.Roles}` }}
                text="Roles"
              />
            )}
            {canView(UserPermissions.PatchesPerms) && (
              <MenuItem
                data={{ route: `${baseRoute}/${EndPts.Patches}` }}
                text="Patches"
              />
            )}
            {canView(UserPermissions.AuditTrailPerms) && (
              <MenuItem
                data={{ route: `${baseRoute}/${EndPts.AuditTrail}` }}
                text="Audit Trail"
              />
            )}
            {canView(UserPermissions.UserDefinedTypesPerms) && (
              <MenuItem
                data={{ route: `${baseRoute}/${EndPts.UserDefinedTypes}` }}
                text="User Defined Types"
              />
            )}
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

const CustomCampaignWrapper = styled(Box)`
  font-size: 18px;
  color: var(--carbon-white);

  span[class^="material-icons"] {
    color: var(--carbon-white);
    font-size: 26px;
  }

  .drop-style {
    span[class^="material-icons"] {
      color: var(--carbon-white);
      font-size: 18px;
    }
  }
`;

const StyledBox = styled(Box)`
  font-size: 18px;
  color: var(--carbon-white);

  span[class^="material-icons"] {
    color: var(--carbon-white);
    font-size: 18px;
  }
`;

export default CarbonMenu;
