import apiClient from "..";
import { useParams } from "react-router-dom";
import { GridGetODataContract, Result, ResultError } from "../../types";
import { AxiosResponse } from "axios";
import useApi from "../../hooks/api/useApi";
import { useStore } from "../../contexts/store";
import { Placeholders, GridIDs } from "../../constants";
import { useGrid } from "../../contexts/grid/useGrid";

const GetGridDataApi = (gridId: string) => {
  const { store } = useStore();
  const { grids } = useGrid();
  const {
    nodeId,
    userId,
    roleId,
    patchId,
    typeId,
    templateId,
    stencilId,
    mediaId,
    packageId,
    priceScheduleId
  } = useParams();

  const fetchDataGet = (
    getDataEndpoint: string,
    getDataContract: GridGetODataContract
  ): Promise<AxiosResponse> => {
    let cleanEndpoint = getDataEndpoint;
    cleanEndpoint = getDataEndpoint.replace(
      Placeholders.companyID,
      `${store.activeCompany!.companyId}`
    );
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.filterID,
      `${grids.get(gridId)?.state.activeFilterId || -1}`
    );
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.nodeGroupId,
      `${store.activeNodeGroup?.nodeGroupId || -1}`
    );
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.NodeScheduleIDs,
      `${grids.get(gridId)?.state.nodeScheduleIds || -1}`
    );
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.HistoryNodeScheduleID,
      `${
        grids.get(GridIDs.NodeTemplatesHistory)?.state.selectedRowData
          ? grids.get(GridIDs.NodeTemplatesHistory)?.state.selectedRowData!
              .HistoryNodeScheduleID || -1
          : -1
      }`
    );

    // Tab Data - Tabbed screens use route data in API call
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.nodeTabNodeId,
      `${nodeId}`
    );
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.userTabUserId,
      `${userId}`
    );
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.roleTabRoleId,
      `${roleId}`
    );
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.patchTabPatchId,
      `${patchId}`
    );
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.userDefinedTypesTabUdtId,
      `${typeId}`
    );
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.templatesTabTemplateId,
      `${templateId}`
    );
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.stencilsTabStencilId,
      `${stencilId}`
    );
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.mediaTabMediaId,
      `${mediaId}`
    );
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.packagesTabPackageId,
      `${packageId}`
    );
    cleanEndpoint = cleanEndpoint.replace(
      Placeholders.priceSchedulesTabPriceScheduleId,
      `${priceScheduleId}`
    );

    // will, 2/7/22: side scroll was causing skip value to set as NaN. Updating to return to default if this happens.
    let endpoint = `${cleanEndpoint}${getDataContract.odataString}`;
    if (endpoint.includes("$skip=NaN")) {
      endpoint = endpoint.replace("$skip=NaN", "$skip=0");
    }

    console.log(`GET GRID DATA: ${endpoint}`);
    return apiClient.get(endpoint);
  };

  const { loading: isLoading, request: gridRequest } = useApi(fetchDataGet);

  const getGridData = async (
    getDataEndpoint: string,
    getDataContract: GridGetODataContract
  ): Promise<Result<string>> => {
    let result: Result<string>;
    try {
      result = await gridRequest(getDataEndpoint, getDataContract);
    } catch (error) {
      return <ResultError>{ type: "error", error };
    }
    return result;
  };

  return {
    getGridData,
    isLoading
  };
};

export default GetGridDataApi;
