import { useEffect, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";
import { CarbonIcons } from "../../constants";
import DashboardPageHeader from "../../components/Layouts/Dashboard/DashboardPageHeader";
import {
  StyledParagraph,
  StyledContentH3,
  StyledBodyContent
} from "../../components/HTMLElements";

import {
  HelpVideos,
  HelpVideosPopular,
  Video,
  PopularVideo
} from "../../constants/helpVideos";

const HelpHome = () => {
  const [videos, setVideos] = useState<Video[]>([]);
  const [popularVideos, setPopularVideos] = useState<PopularVideo[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [activeVideo, setActiveVideo] = useState<{
    title: string;
    videoLink: string;
  } | null>(null);
  const [activeTag, setActiveTag] = useState<number>(-1);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (HelpVideos) {
      setVideos(HelpVideos);

      const tagSet: string[] = [
        ...new Set(HelpVideos.flatMap(({ tags }) => tags))
      ];
      setTags(tagSet);
    }

    if (HelpVideosPopular) {
      setPopularVideos(HelpVideosPopular);
    }
  }, []);

  const toggleVideo = (video: { title: string; videoLink: string } | null) => {
    if (video) {
      setActiveVideo(video);
      setVisible(!visible);
    } else {
      setVisible(!visible);
      setActiveVideo(null);
    }
  };

  const handleTagFilter = (id: number, selectedTag: string) => {
    if (activeTag === id) {
      setActiveTag(-1);
      setVideos(HelpVideos);
    } else {
      setActiveTag(id);
      const filtered = HelpVideos.filter((article) =>
        article.tags.some((tags) => tags.includes(selectedTag))
      );
      setVideos(filtered);
    }
  };

  return (
    <>
      <Helmet>
        <title>Carbon | Help & Knowledgebase</title>
      </Helmet>
      {visible && activeVideo && (
        <Dialog title={activeVideo.title} onClose={() => toggleVideo(null)}>
          <VideoContainer
            src={activeVideo.videoLink}
            title={activeVideo.title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Dialog>
      )}
      <DashboardPageHeader
        screenTitle={"Help & Knowledgebase"}
        screenIcon={CarbonIcons.HelpHome}
        screenSubtitle={"Last updated: 4/21/22"}
      />

      <HelpHeaderWrapper>
        <div>
          <StyledContentH3 margin={"0 0 12px 0"}>
            Popular videos
          </StyledContentH3>
          <PopularVideoLinks>
            {popularVideos &&
              popularVideos.map((video: PopularVideo, i: number) => {
                return (
                  <li key={i}>
                    <button onClick={() => toggleVideo(video)}>
                      {video.title}
                    </button>
                  </li>
                );
              })}
          </PopularVideoLinks>
        </div>
        <div>
          <StyledContentH3 margin={"0 0 12px 0"}>
            Filter by tags
          </StyledContentH3>
          <Tags>
            {tags &&
              tags.map((tag: string, i: number) => {
                return (
                  <span
                    key={i}
                    onClick={() => handleTagFilter(i, tag)}
                    className={activeTag === i ? "active" : ""}
                  >
                    {tag}
                  </span>
                );
              })}
          </Tags>
        </div>
      </HelpHeaderWrapper>

      <StyledBodyContent>
        <VideoList>
          {videos &&
            videos.map((video: Video, i: number) => {
              return (
                <li key={i}>
                  <img
                    src={"/static/images/video-thumbnail.jpg"}
                    onClick={() => toggleVideo(video)}
                    alt={video.title}
                  />
                  <div>
                    <StyledContentH3>{video.title}</StyledContentH3>
                    <StyledParagraph>
                      {video.description}
                      <br />
                    </StyledParagraph>
                    <VideoTags>
                      tags:&nbsp;
                      {video.tags.map((tag: string, idx: number) => {
                        return (
                          <button key={idx}>{(idx ? ", " : "") + tag}</button>
                        );
                      })}
                    </VideoTags>
                  </div>
                </li>
              );
            })}
        </VideoList>
      </StyledBodyContent>
    </>
  );
};

const HelpHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  > div {
    flex: 1;
  }
  @media (min-width: 1140px) {
    flex-direction: row;
  }
`;

const PopularVideoLinks = styled.ul`
  columns: 2;
  padding-bottom: 40px;
  justify-content: start;
  > li > button {
    font-size: 16px;
    text-decoration: underline;
    font-weight: 400;
    line-height: 16px;
    color: #444444;
    background-color: transparent;
    border-width: 0;
    font-family: inherit;
    font-style: inherit;
    padding: 0;
    cursor: pointer;
  }
  @media (min-width: 1140px) {
    padding-bottom: 0;
    > li:nth-child(odd) {
      padding-right: 0;
    }
  }
`;

const Tags = styled.div`
  display: inline-block;
  span {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    color: var(--carbon-black);
    background-color: var(--carbon-lightgray);
    border-radius: 12px;
    margin: 0 6px 6px 0;
    padding: 0 12px;
    cursor: pointer;
    transition: all 0.2s ease-in;
  }
  span.active,
  span:hover {
    color: var(--carbon-white);
    background-color: var(--carbon-orange);
  }
`;

const VideoList = styled.ul`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  @media (min-width: 1140px) {
    grid-template-columns: repeat(2, 1fr);
  }
  > li {
    :nth-child(odd) {
      padding-right: 0;
    }
    display: flex;

    > img {
      width: 315px;
      height: 180px;
      align-items: flex-start;
      border-radius: 8px;
      margin: 0 10px 12px 0;
      cursor: pointer;
      @media (min-width: 1140px) {
        width: 210px;
        height: 120px;
      }
    }
    @media (min-width: 1140px) {
      :nth-child(odd) {
        padding-right: 12px;
      }
    }
  }
`;

const VideoContainer = styled.iframe`
  border-radius: 8px;
  border: 0;
  width: 640px;
  height: 360px;
  @media (min-width: 1140px) {
    width: 854px;
    height: 480px;
  }
`;

const VideoTags = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #888888;
  > button {
    font-size: inherit;
    background-color: transparent;
    border-width: 0;
    font-family: inherit;
    font-style: inherit;
    padding: 0;
    cursor: pointer;
  }
`;

export default HelpHome;
