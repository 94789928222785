import apiClient from "..";
import { AxiosResponse } from "axios";
import {
  GlobalChangesGridFilterContract,
  GlobalChangesApplyChangesByMediaPackageContract,
  GlobalChangesApplyChangesByTemplateZoneContract,
  GlobalChangesApplyChangesByTypeContract,
  GlobalChangesProcessUpdatesContract,
  GlobalChangesRestoreScheduleContract
} from "../../types";

export const GetAffectedCount = (
  url: string,
  payload: GlobalChangesGridFilterContract
): Promise<AxiosResponse> => {
  const response = apiClient.post(url, JSON.stringify(payload));
  return response;
};

export const ApplyChangesByMediaPackage = (
  url: string,
  payload: GlobalChangesApplyChangesByMediaPackageContract
): Promise<AxiosResponse> => {
  const response = apiClient.post(url, JSON.stringify(payload));
  return response;
};

export const ApplyChangesByTemplateZone = (
  url: string,
  payload: GlobalChangesApplyChangesByTemplateZoneContract
): Promise<AxiosResponse> => {
  const response = apiClient.post(url, JSON.stringify(payload));
  return response;
};

export const ApplyChangesByType = (
  url: string,
  payload: GlobalChangesApplyChangesByTypeContract
): Promise<AxiosResponse> => {
  const response = apiClient.post(url, JSON.stringify(payload));
  return response;
};

export const PublishUpdatesToNodes = (
  url: string,
  payload: GlobalChangesProcessUpdatesContract
): Promise<AxiosResponse> => {
  const response = apiClient.post(url, JSON.stringify(payload));
  return response;
};

export const RestoreScheduleToNodes = (
  url: string,
  payload: GlobalChangesRestoreScheduleContract
): Promise<AxiosResponse> => {
  const response = apiClient.post(url, JSON.stringify(payload));
  return response;
};
