import { Api, FileUploadTypes } from "../../constants";
import { AxiosResponse } from "axios";
import { Result, ResultError, ResultSuccess } from "../../types";
import useApi from "../api/useApi";
import apiClient from "../../api";

interface GetTokenResponseType {
  data: string;
}

/**
 * The useFileDownload hook handles all our file download requirements
 * while exposing only what we need in our Views
 */
const useFileDownload = (type: FileUploadTypes) => {
  const getDownloadTokenApi = (
    companyID: number,
    id: number,
    method?: string,
    body?: any
  ): Promise<AxiosResponse> => {
    let controller: string;
    switch (type) {
      case FileUploadTypes.Media:
        controller = "media";
        break;
      case FileUploadTypes.Fonts:
        controller = "fonts";
        break;
      case FileUploadTypes.CampaignsDashboard:
        controller = "campaignsdashboard";
        break;
    }

    const linkUrl = `${Api.baseUrl}/api/${companyID}/${controller}/downloadlink/${id}`;

    let axiosPromise: Promise<AxiosResponse<any>>;
    if (method === "post") axiosPromise = apiClient.post(linkUrl, body);
    else axiosPromise = apiClient.get(linkUrl);

    return axiosPromise;
  };

  // set selected api
  const { loading: downloading, request: downloadTokenRequest } =
    useApi(getDownloadTokenApi);

  const downloadFile = async (
    companyID: number,
    id: number,
    method?: string,
    body?: any
  ) => {
    try {
      // Call API to get the download token
      const result: Result<GetTokenResponseType> = await downloadTokenRequest(
        companyID,
        id,
        method,
        body
      );

      if (result.type === "success") {
        const token = result.value.data;
        console.log("GOT DOWNLOAD TOKEN:", token);
        // Open a new window to trigger the browser to download the file
        const linkUrl: string = `${Api.baseUrl}/api/${companyID}/download/file/${token}`;
        window.open(linkUrl);

        const successResult: ResultSuccess<string> = {
          type: "success",
          value: ""
        };
        return successResult;
      }
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }
  };

  return {
    downloading,
    downloadFile
  };
};

export default useFileDownload;
