import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  {
    field: "HistoryNodeScheduleID",
    title: "HistoryNodeScheduleID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: -1
  },
  // {
  //   field: "NodeID",
  //   title: "NodeID",
  //   width: "150px",
  //   defaultShow: false,
  //   filter: "numeric",
  //   editable: false,
  //   required: false,
  //   canBeSaved: true,
  //   defaultValue: "[grdNodes.NodeID]"
  // },
  {
    field: "NodeProcessUpdateHistoryID",
    title: "NodeProcessUpdateHistoryID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "ScheduleWkDay",
    title: "Week Day",
    width: "160px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "ScheduleDate",
    title: "Date",
    width: "150px",
    defaultShow: false,
    filter: "date",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "SundayStartTime",
    title: "SundayStartTime",
    width: "150px",
    defaultShow: false,
    filter: "date",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "MondayStartTime",
    title: "MondayStartTime",
    width: "150px",
    defaultShow: false,
    filter: "date",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "TuesdayStartTime",
    title: "TuesdayStartTime",
    width: "150px",
    defaultShow: false,
    filter: "date",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "WednesdayStartTime",
    title: "WednesdayStartTime",
    width: "150px",
    defaultShow: false,
    filter: "date",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "ThursdayStartTime",
    title: "ThursdayStartTime",
    width: "150px",
    defaultShow: false,
    filter: "date",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "FridayStartTime",
    title: "FridayStartTime",
    width: "150px",
    defaultShow: false,
    filter: "date",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "SaturdayStartTime",
    title: "SaturdayStartTime",
    width: "150px",
    defaultShow: false,
    filter: "date",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "NodeDayPartID",
    title: "NodeDayPartID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "NodeDayPartName",
    title: "Day Part",
    width: "150px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 50,
    canBeSaved: true
  },
  {
    field: "OffsetMinutes",
    title: "Offset Time",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true,
    defaultValue: 0
  },
  {
    field: "ZIndex",
    title: "Z Index",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true,
    defaultValue: 0
  },
  {
    field: "HorizontalOffset",
    title: "Hor. Offset",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true,
    defaultValue: 0
  },
  {
    field: "TemplateID",
    title: "TemplateID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "TemplateName",
    title: "Template",
    width: "365px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 50,
    canBeSaved: true
  },
  {
    field: "NodeDisplayID",
    title: "NodeDisplayID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "DisplayOrder",
    title: "Display",
    width: "145px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true
  },
  {
    field: "StartDate",
    title: "Start",
    width: "175px",
    defaultShow: true,
    filter: "date",
    editable: true,
    editor: "datetime",
    required: false,
    canBeSaved: true
  },
  {
    field: "EndDate",
    title: "End",
    width: "170px",
    defaultShow: true,
    filter: "date",
    editable: true,
    editor: "datetime",
    required: false,
    canBeSaved: true
  },
  {
    field: "StartDate_Sort",
    title: "StartDate_Sort",
    width: "250px",
    defaultShow: false,
    filter: "date",
    editable: false,
    required: false,
    canBeSaved: true
  },
  {
    field: "EndDate_Sort",
    title: "EndDate_Sort",
    width: "250px",
    defaultShow: false,
    filter: "date",
    editable: false,
    required: false,
    canBeSaved: true
  }
];
