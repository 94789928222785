import { forwardRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import config from "../../../config";

const DashboardFooter = forwardRef((props, ref: any) => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterWrapper ref={ref}>
      <FooterText>{`Copyright © 2009-${currentYear} DEEL Media, Inc. All rights reserved. Version ${config.appVersion}`}</FooterText>
      <FooterRight>
        <FooterText orange={true}>Contact Support @ (770) 619-1225</FooterText>
        <FooterText orange={true}>|</FooterText>
        <FooterText orange={true}>
          <Link to="/app/legal/privacy-policy">Privacy Policy</Link>
        </FooterText>
        <FooterText orange={true}>|</FooterText>
        <FooterText orange={true}>
          <Link to="/app/legal/terms-of-use">Terms of Use</Link>
        </FooterText>
      </FooterRight>
    </FooterWrapper>
  );
});

const FooterWrapper = styled.div`
  background-color: var(--carbon-lightergray);
  width: 100%;
  z-index: 100;
  bottom: 0;
  font-size: 16px;
  height: 72px;
  padding: 12px 20px;
  @media (min-width: 1150px) {
    display: flex;
    height: 36px;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
  }
`;

const FooterRight = styled.div`
  display: flex;
`;

const FooterText = styled.p<{ orange?: boolean }>`
  color: ${(props) =>
    props.orange ? "var(--carbon-orange)" : "var(--carbon-mediumgray)"};
  margin: 0 8px 0 0;
`;

DashboardFooter.displayName = "DashboardFooter";

export default DashboardFooter;
