import { Helmet } from "react-helmet-async";
// import styled from "styled-components";
import { CarbonIcons } from "../../constants/";
import DashboardPageHeader from "../../components/Layouts/Dashboard/DashboardPageHeader";
import {
  StyledBodyContent,
  StyledParagraph,
  StyledContentH2
} from "../../components/HTMLElements";

const TermsOfUse = (): JSX.Element => {
  return (
    <>
      <Helmet>
        <title>Carbon | Terms of Use</title>
      </Helmet>
      <DashboardPageHeader
        screenTitle={"Terms of Use"}
        screenIcon={CarbonIcons.TermsOfUse}
        screenSubtitle={"Last updated: 6/03/22"}
      />
      <StyledBodyContent>
        <StyledContentH2>1.Introduction</StyledContentH2>
        <StyledParagraph>
          This website, Carbon, is operated by DEEL Media, Inc. The terms
          &quot;we&quot;, &quot;us&quot;, and &quot;our&quot; refer to DEEL
          Media, Inc. The use of Carbon is subject to the following terms and
          conditions of use, as amended from time to time (the
          &quot;Terms&quot;). The Terms are to be read together by you with any
          terms, conditions or disclaimers provided in the pages of our website.
          Please review the Terms carefully. The Terms apply to all users of our
          website, including without limitation, users who are browsers,
          customers, merchants, vendors and/or contributors of content. If you
          access and use this website, you accept and agree to be bound by and
          comply with the Terms and our Privacy Policy. If you do not agree to
          the Terms or our Privacy Policy, you are not authorized to access our
          website, use any of DEEL Media services.
        </StyledParagraph>
        <StyledContentH2>2.Use of Carbon</StyledContentH2>
        <StyledParagraph>
          You agree to use Carbon for legitimate purposes and not for any
          illegal or unauthorized purpose, including without limitation, in
          violation of any intellectual property or privacy law. By agreeing to
          the Terms, you represent and warrant that you are at least the age of
          majority in your state or province of residence and are legally
          capable of entering into a binding contract. You agree to not use our
          website to conduct any activity that would constitute a civil or
          criminal offence or violate any law. You agree not to attempt to
          interfere with our website’s network or security features or to gain
          unauthorized access to our systems. You agree to provide us with
          accurate personal information, such as your email address, mailing
          address and other contact details in order to contact you as needed.
          You agree to promptly update your account and information. You
          authorize us to collect and use this information to contact you in
          accordance with our Privacy Policy.
        </StyledParagraph>
        <StyledContentH2>3.Links to Third-Party Websites</StyledContentH2>
        <StyledParagraph>
          Links from Carbon to outside websites are meant for convenience only.
          We do not review, endorse, approve or control, and are not responsible
          for any sites linked from or to our website, the content of those
          sites, the third parties named therein, or their products and
          services. Linking to any other site is at your sole risk and we will
          not be responsible or liable for any damages in connection with
          linking. Links to downloadable software sites are for convenience only
          and we are not responsible or liable for any difficulties or
          consequences associated with downloading the software.
        </StyledParagraph>
        <StyledContentH2>
          4.Use Comments, Feedback, and Other Submissions
        </StyledContentH2>
        <StyledParagraph>
          You acknowledge that you are responsible for the information, images,
          videos, pricing data, schedule details and any other content
          (collectively, the &quot;Content&quot;) that you post, distribute or
          share on or through Carbon or services available in connection with
          our website. You further acknowledge that you have full responsibility
          for the Content, including but limited to, with respect to its
          legality, and its trademark, copyright and other intellectual property
          ownership. You agree that any Content submitted by you in response to
          a request by us for a specific submission may be edited, adapted,
          modified, recreated, published, or distributed by us. You further
          agree that we are under no obligation to maintain any Content in
          confidence, to pay compensation for any Content or to respond to any
          Content. You agree that you will not post, distribute or share any
          Content on Carbon that is protected by copyright, trademark, patent or
          any other proprietary right without the express consent of the owner
          of such proprietary right. You further agree that your Content will
          not be unlawful, abusive or obscene nor will it contain any malware or
          computer virus that could affect our website’s operations. You will be
          solely liable for any Content that you make and its accuracy. We have
          no responsibility and assume no liability for any Content posted by
          you or any third-party. We reserve the right to terminate your ability
          to post on our website and to remove and/or delete any Content that we
          deem objectionable. You consent to such removal and/or deletion and
          waive any claim against us for the removal and/or deletion of your
          Content.
        </StyledParagraph>
        <StyledContentH2>5.Your Personal Information</StyledContentH2>
        <StyledParagraph>
          Please see our Privacy Policy to learn about how we collect, use, and
          share your personal information.
        </StyledParagraph>
        <StyledContentH2>6.Errors and Omissions</StyledContentH2>
        <StyledParagraph>
          Please note that our website may contain typographical errors or
          inaccuracies and may not be complete or current. We reserve the right
          to correct any errors, inaccuracies or omissions and to change or
          update information at any time, without prior notice.
        </StyledParagraph>
        <StyledContentH2>
          7.Disclaimer and Limitation of Liability
        </StyledContentH2>
        <StyledParagraph>
          You assume all responsibility and risk with respect to your use of our
          website, which is provided “as is” without warranties, representations
          or conditions of any kind, either express or implied, with regard to
          information accessed from or via our website, including without
          limitation, all content and materials, and functions and services
          provided on our website, all of which are provided without warranty of
          any kind, including but not limited to warranties concerning the
          availability, accuracy, completeness or usefulness of content or
          information, uninterrupted access, and any warranties of title,
          non-infringement, merchantability or fitness for a particular purpose.
          We do not warrant that our website or its functioning or the content
          and material of the services made available thereby will be timely,
          secure, uninterrupted or error-free, that defects will be corrected,
          or that our websites or the servers that make our website available
          are free of viruses or other harmful components. The use of our
          website is at your sole risk and you assume full responsibility for
          any costs associated with your use of our website. We will not be
          liable for any damages of any kind related to the use of our website.
          In no event will we, or our affiliates, our or their respective
          content or service providers, or any of our or their respective
          directors, officers, agents, contractors, suppliers or employees be
          liable to you for any direct, indirect, special, incidental,
          consequential, exemplary or punitive damages, losses or causes of
          action, or lost revenue, lost profits, lost business or sales, or any
          other type of damage, whether based in contract or tort (including
          negligence), strict liability or otherwise, arising from your use of,
          or the inability to use, or the performance of, our website or the
          content or material or functionality through our website, even if we
          are advised of the possibility of such damages. Certain jurisdictions
          do not allow limitation of liability or the exclusion or limitation of
          certain damages. In such jurisdictions, some or all of the above
          disclaimers, exclusions, or limitations, may not apply to you and our
          liability will be limited to the maximum extent permitted by law.
        </StyledParagraph>
        <StyledContentH2>8.Indemnification</StyledContentH2>
        <StyledParagraph>
          You agree to defend and indemnify us, and hold us and our affiliates
          harmless, and our and their respective directors, officers, agents,
          contractors, and employees against any losses, liabilities, claims,
          expenses (including legal fees) in any way arising from, related to or
          in connection with your use of our website, your violation of the
          Terms, or the posting or transmission of any materials on or through
          the website by you, including but not limited to, any third party
          claim that any information or materials provided by you infringe upon
          any third party proprietary rights.
        </StyledParagraph>
        <StyledContentH2>9.Entire Agreement</StyledContentH2>
        <StyledParagraph>
          The Terms and any documents expressly referred to in them represent
          the entire agreement between you and us in relation to the subject
          matter of the Terms and supersede any prior agreement, understanding
          or arrangement between you and us, whether oral or in writing. Both
          you and we acknowledge that, in entering into these Terms, neither you
          nor we have relied on any representation, undertaking or promise given
          by the other or implied from anything said or written between you and
          us prior to such Terms, except as expressly stated in the Terms.
        </StyledParagraph>
        <StyledContentH2>10.Waiver</StyledContentH2>
        <StyledParagraph>
          Our failure to exercise or enforce any right or provision of the Terms
          will not constitute a waiver of such right or provision. A waiver by
          us of any default will not constitute a waiver of any subsequent
          default. No waiver by us is effective unless it is communicated to you
          in writing.
        </StyledParagraph>
        <StyledContentH2>11.Headings</StyledContentH2>
        <StyledParagraph>
          Any headings and titles herein are for convenience only.
        </StyledParagraph>
        <StyledContentH2>12.Severability</StyledContentH2>
        <StyledParagraph>
          If any of the provisions of the Terms are determined by any competent
          authority to be invalid, unlawful or unenforceable, such provision
          will to that extent be severed from the remaining Terms, which will
          continue to be valid and enforceable to the fullest extent permitted
          by law.
        </StyledParagraph>
        <StyledContentH2>13.Governing Law</StyledContentH2>
        <StyledParagraph>
          Any disputes arising out of or relating to the Terms, the Privacy
          Policy, use of our website, or our products or services offered on our
          website will be resolved in accordance with the laws of the State of
          Georgia without regard to its conflict of law rules. Any disputes,
          actions or proceedings relating to the Terms or your access to or use
          of our website must be brought before the courts of the State of
          Georgia in the City of Roswell, Georgia and you irrevocably consent to
          the exclusive jurisdiction and venue of such courts.
        </StyledParagraph>
        <StyledContentH2>14.Questions or Concerns</StyledContentH2>
        <StyledParagraph>
          Please send all questions, comments and feedback to us at
          support@deelmedia.com or (770) 619-1225.
        </StyledParagraph>
      </StyledBodyContent>
    </>
  );
};
export default TermsOfUse;
