/* eslint-disable */

import { Box, DropButton } from "grommet";
import React, { FC, useEffect } from "react";
import config from "../../config";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { useImmer } from "use-immer";
import { ListBox, ListBoxItemClickEvent } from "@progress/kendo-react-listbox";
import styled from "styled-components";
import HelpOutlineOutlined from "@material-ui/icons/HelpOutlineOutlined";
import apiClient from "../../api";
import ConfirmationDialog from "../../components/Util/Dialogs/ConfirmationDialog";
import { Menu, MenuItem, MenuSelectEvent } from "@progress/kendo-react-layout";
import {
  CarbonIcons,
  DayGroupMenu,
  GridActions,
  GridIDs,
  StoreActions,
  UserPermissions
} from "../../constants";
import { useStore } from "../../contexts/store";
import { useParams } from "react-router";
import { Loader } from "@progress/kendo-react-indicators";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { useGrid } from "../../contexts/grid/useGrid";
import useUpdateEffect from "../../hooks/utils/useUpdateEffect";
import {
  DropDownList,
  DropDownListChangeEvent,
  ListItemProps
} from "@progress/kendo-react-dropdowns";
import {
  DayPartStatusColorEnum,
  DayPartStatusTextEnum,
  IDayGroup,
  IDayPart,
  IDeleteDayGroupDraft,
  useDayGroups
} from "../../hooks/useDayGroups";
import usePermissions from "../../hooks/auth/usePermissions";

const NodeScheduleDayGroups: FC = () => {
  const {
    dayGroups,
    nodeDayParts,
    setSelectedDayGroup,
    selectedDayGroup,
    loading,
    handleDayClick,
    handleDayPartClick,
    handleDuplicateDayGroupDone,
    handleDeleteDayPart,
    handleDayPartSelectDone,
    showDeleteDayGroupDialog,
    deleteDayGroup,
    setShowDeleteDayGroupDialog,
    setShowSelectDayPartsDialog,
    setShowDuplicateDayGroupDialog,
    showDayPartModificationConfirmationDialog,
    showDuplicateDayGroupDialog,
    showSelectDayPartsDialog,
    setShowDayPartModificationConfirmationDialog,
    setDeleteDayGroupDraft,
    setDuplicateDayGroupDraft,
    dayPartsDraft,
    duplicateDayGroupDraft,
    deleteDayGroupDraft,
    generateNodeDayGroupDayPartId
  } = useDayGroups();

  const { isGranted } = usePermissions();

  // empty defualt deleteDayGroupDraft
  const emptyDeleteDayGroupDraft: IDeleteDayGroupDraft = {
    lastSelectedDay: undefined,
    newDayGroup: undefined,
    oldDayGroup: undefined
  };

  // only one day part can be active at a time, the active daypart will determine the content shown in the grid
  const handleDayPartClickEvent = (event: ListBoxItemClickEvent) => {
    console.log(event);
    let tempActiveDayPart = {} as IDayPart;
    let tempDayGroup = {} as IDayGroup;
    dayGroups.forEach((dayGroup) => {
      const foundDayPart = dayGroup.dayParts.find(
        (val) => val.dayGroupDayPartId === event.dataItem.dayGroupDayPartId
      );
      if (foundDayPart) {
        tempActiveDayPart = foundDayPart;
        tempDayGroup = dayGroup;
      }
    });

    handleDayPartClick(
      tempDayGroup,
      tempActiveDayPart,
      event.dataItem.dayGroupDayPartId
    );
  };

  const onMenuSelect = (event: MenuSelectEvent) => {
    const eventData = event.item?.data;
    console.log(eventData);
    const itemName = eventData?.itemName;
    const daygroup = eventData?.dayGroup;
    setSelectedDayGroup(daygroup);
    switch (itemName) {
      case DayGroupMenu.DayPartSelect:
        setShowSelectDayPartsDialog(true);
        break;
      case DayGroupMenu.DuplicateDayGroup:
        setShowDuplicateDayGroupDialog(true);
        break;

      default:
        break;
    }
  };

  const SelectDayPartsRender = () => {
    return (
      <Box
        pad="xsmall"
        direction={"row"}
        justify="center"
        align="center"
        className="daygroup-menu-item"
      >
        {CarbonIcons.Edit}
        <span style={{ marginLeft: 5 }}>Select Dayparts</span>
      </Box>
    );
  };

  const onChangeCheckbox = (event: CheckboxChangeEvent) => {
    // modify the draft (selectedDayGroup) then on click Done, make the db changes necessary
    setSelectedDayGroup((draft) => {
      const dayPart = nodeDayParts.find(
        (val) => val.NodeDayPartName === event.target.name
      );
      // if a new day part is added to the group (checked), then add the new day part to the draft
      if (event.value === true) {
        const newDayPart = {} as IDayPart;
        newDayPart.id = dayPart!.NodeDayPartID;
        newDayPart.dayGroupDayPartId = generateNodeDayGroupDayPartId(
          selectedDayGroup.sequence,
          dayPart!.NodeDayPartID
        );
        newDayPart.isActive = false;
        newDayPart.title = dayPart!.NodeDayPartName;
        newDayPart.nodeScheduleIDs = [-1];
        newDayPart.status = DayPartStatusTextEnum.Empty;
        draft.dayParts.push(newDayPart);
      } else {
        // if an existing day part is removed from the group (unchecked) then remove it from the draft so it can be delete later on submission
        draft.dayParts = draft.dayParts.filter(
          (val) => val.id !== dayPart?.NodeDayPartID
        );
      }
    });
  };

  const DuplicateDayGroupRender = () => {
    return (
      <Box
        pad="xsmall"
        direction={"row"}
        justify="center"
        align="center"
        className="daygroup-menu-item"
      >
        {CarbonIcons.Copy}
        <span style={{ marginLeft: 5 }}>Duplicate Day Group</span>
      </Box>
    );
  };

  const MenuButtonRender = () => {
    return (
      <Button
        style={{
          width: 30,
          height: 20,
          alignSelf: "center",
          marginLeft: 5
        }}
      >
        <span className="k-icon k-i-more-horizontal"/>
      </Button>
    );
  };

  const generateDayPartsModificationConfirmationString = (): string => {
    let confirmationString = "This action will ";
    if (dayPartsDraft.dayPartsToDelete.length > 0) {
      confirmationString += `erase all existing schedules for (${dayPartsDraft.dayPartsToDelete.map(
        (val) => ` ${val.title} `
      )})`;
    }
    confirmationString += ` on Day Group ${dayPartsDraft.dayGroup.sequence}`;

    return confirmationString;
  };

  const customListBoxItem = (props: any) => {
    let { dataItem, selected, ...others } = props;
    const dayPart = dataItem as IDayPart;
    return (
      <li {...others}>
        <Box fill direction="row" justify="between">
          <Box style={{ inlineSize: 150, overflow: "hidden" }}>
            {dayPart.title}
          </Box>
          <Box justify="center">
            <StyledStatusDiv>
              <span
                style={{
                  width: 60,
                  backgroundColor:
                    DayPartStatusColorEnum[
                      dayPart.status as keyof typeof DayPartStatusColorEnum
                    ]
                }}
              >
                {
                  DayPartStatusTextEnum[
                    dayPart.status as keyof typeof DayPartStatusTextEnum
                  ]
                }
              </span>
            </StyledStatusDiv>
          </Box>
        </Box>
      </li>
    );
  };

  const DayPartSelectDialog = () => {
    return (
      <Dialog
        closeIcon={false}
        contentStyle={{ padding: 10 }}
        className={"dayparts-select-dialog"}
        title={
          <Box
            style={{
              borderBottomColor: "var(--carbon-lightgrey)",
              fontSize: 15,
              padding: 5,
              paddingBottom: 0,
              fontWeight: 500,
              color: "var(--carbon-lightgrey)"
            }}
          >
            Select Dayparts
          </Box>
        }
      >
        <Box>
          {nodeDayParts.map((dayPart, index) => {
            return (
              <Box
                style={{ marginTop: 3, marginBottom: 3 }}
                key={`daypart-checkbox-${index}`}
              >
                <Checkbox
                  onChange={onChangeCheckbox}
                  label={dayPart.NodeDayPartName}
                  defaultValue={
                    selectedDayGroup.dayParts.find(
                      (val) => val.id === dayPart.NodeDayPartID
                    )
                      ? true
                      : false
                  }
                  name={dayPart.NodeDayPartName}
                />
              </Box>
            );
          })}
        </Box>
        <DialogActionsBar>
          <Button
            style={{ marginRight: 0 }}
            onClick={() => {
              setShowSelectDayPartsDialog(false);
            }}
          >
            {CarbonIcons.Close}
            {"Cancel"}
          </Button>
          <Button primary={true} onClick={handleDayPartSelectDone}>
            {CarbonIcons.Check}
            {"Done"}
          </Button>
        </DialogActionsBar>
      </Dialog>
    );
  };

  const handleDuplicateDayGroupItemChange = (
    event: DropDownListChangeEvent
  ) => {
    const sourceDayGroup: IDayGroup = event.value;
    setDuplicateDayGroupDraft({
      sourceDayGroup: sourceDayGroup,
      targetDayGroup: selectedDayGroup
    });
  };

  const DayGroupDuplicateDialog = () => {
    const renderSelectedDropDownValue = (
      element: React.ReactElement<HTMLSpanElement>,
      value: IDayGroup
    ) => {
      console.log("the day group", value);
      return (
        <Box justify="center" align="start" fill>
          {value.title}
        </Box>
      );
    };

    const itemRender = (
      li: React.ReactElement<HTMLLIElement>,
      itemProps: ListItemProps
    ) => {
      const dataItem: IDayGroup = itemProps.dataItem;
      const itemChildren = <Box>{dataItem.title}</Box>;
      return React.cloneElement(li, li.props, itemChildren);
    };

    return (
      <Dialog
        closeIcon={false}
        contentStyle={{ padding: 10 }}
        className={"dayparts-select-dialog"}
        title={
          <Box
            style={{
              borderBottomColor: "var(--carbon-lightgrey)",
              fontSize: 15,
              padding: 5,
              paddingBottom: 0,
              fontWeight: 500,
              color: "var(--carbon-lightgrey)"
            }}
          >
            Duplicate Day Group
          </Box>
        }
      >
        <Box style={{ width: 300 }}>
          <Box>Copy all schedules from</Box>
          <DropDownList
            data={dayGroups.filter(
              (val) => val.id !== selectedDayGroup.id && val.isReal === true
            )}
            defaultValue={
              dayGroups.filter(
                (val) => val.id !== selectedDayGroup.id && val.isReal === true
              )[0]
            }
            valueRender={renderSelectedDropDownValue}
            itemRender={itemRender}
            value={
              duplicateDayGroupDraft.sourceDayGroup ||
              dayGroups.filter(
                (val) => val.id !== selectedDayGroup.id && val.isReal === true
              )[0]
            }
            onChange={handleDuplicateDayGroupItemChange}
          />
          <Box style={{ marginTop: 5 }} direction="row">
            <Box>and paste to </Box>
            <Box style={{ fontWeight: "bold", marginLeft: 4 }}>
              {selectedDayGroup.title}
            </Box>
          </Box>
          <Box style={{ marginTop: 5 }} direction="row">
            <div style={{ fontWeight: "bold", marginRight: 4 }}>WARNING:</div>
            <Box>
              This will overwrite any existing templates in the destination day
              part!
            </Box>
          </Box>
        </Box>
        <DialogActionsBar>
          <Button
            style={{ marginRight: 0 }}
            onClick={() => {
              setShowDuplicateDayGroupDialog(false);
              setDuplicateDayGroupDraft({
                sourceDayGroup: undefined,
                targetDayGroup: undefined
              });
            }}
          >
            {CarbonIcons.Close}
            {"Cancel"}
          </Button>
          <Button primary={true} onClick={handleDuplicateDayGroupDone}>
            {CarbonIcons.Check}
            {"Done"}
          </Button>
        </DialogActionsBar>
      </Dialog>
    );
  };

  const isDuplicateMenuItemDisabled = (dayGroup: IDayGroup) => {
    let returnVal = false;

    // cannot duplicate a single daygroup
    if (dayGroups.length === 1) {
      returnVal = true;
      return true;
    }

    // cannot duplicate schedules from a fake day group
    if (dayGroups.length === 2) {
      // check if either one of these 2 day groups is fake
      const fakeDayGroup = dayGroups.find((val) => val.isReal === false);
      if (fakeDayGroup) {
        // one of these 2 day groups is fake,
        // if the selected day group is NOT the fake day group,
        // prevent this action and disable item
        if (fakeDayGroup.id !== dayGroup.id) {
          returnVal = true;
          return returnVal;
        }
      }
    }

    return returnVal;
  };

  /** Below is the top level daygroups render/return */
  return (
    <>
      {showSelectDayPartsDialog && <DayPartSelectDialog />}
      {showDuplicateDayGroupDialog && <DayGroupDuplicateDialog />}
      {loading ? (
        <Box
          width={"340px"}
          alignSelf="center"
          align={"center"}
          justify={"center"}
          style={{
            border: "1px solid var(--carbon-lightergray)",
            borderTopLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 6,
            height: "100%",
            minWidth: 310
          }}
        >
          <Loader size="large" type="converging-spinner"/>
        </Box>
      ) : (
        <div
          style={{
            border: "1px solid var(--carbon-lightergray)",
            borderTopLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 6,
            height: "100%",
            minWidth: 310
          }}
        >
          {showDayPartModificationConfirmationDialog && (
            <ConfirmationDialog
              title="Modify existing day parts?"
              bodyText={generateDayPartsModificationConfirmationString()}
              onAcceptCallback={handleDeleteDayPart}
              onRejectCallback={() => {
                setShowDayPartModificationConfirmationDialog(false);
              }}
            />
          )}
          {showDeleteDayGroupDialog && (
            <ConfirmationDialog
              title="Erase schedules ?"
              bodyText={`This action will erase all existing schedules for ${deleteDayGroupDraft.oldDayGroup?.title} ( ${deleteDayGroupDraft.lastSelectedDay?.titleKey} ) ?`}
              onAcceptCallback={deleteDayGroup}
              onRejectCallback={() => {
                setDeleteDayGroupDraft(emptyDeleteDayGroupDraft);
                setShowDeleteDayGroupDialog(false);
              }}
            />
          )}
          <DayGroupsHeaderBox direction="row" justify="between">
            <DayGroupTitleText>Day Groups</DayGroupTitleText>
            <Box align="center" direction="row">
              <HelpOutlineOutlined
                style={{ width: 15, height: 15, color: "var(--carbon-blue)" }}
              />
              <Box
                focusIndicator={false}
                style={{
                  color: "var(--carbon-blue)",
                  fontSize: 14,
                  fontWeight: 600
                }}
                onClick={() => {
                  window.open(config.dayGroupsHelpLink);
                }}
              >
                Help
              </Box>
            </Box>
          </DayGroupsHeaderBox>
          <div style={{ height: "calc( 100% - 33px )", overflow: "auto" }}>
            {dayGroups.map((dayGroup, index) => {
              return (
                <div key={`daygroups-${index}`}>
                  <DayGroupTitleText style={{ padding: 4 }}>
                    {dayGroup.title}
                  </DayGroupTitleText>
                  <Box direction="row" style={{ padding: 6 }}>
                    <StyledButtonGroup>
                      {dayGroup.days.map((day, index) => {
                        return (
                          <Button
                            key={`dayparts-menu-${index}`}
                            togglable={true}
                            onClick={() => handleDayClick(day, dayGroup)}
                            selected={day.selected}
                          >
                            {day.title}
                          </Button>
                        );
                      })}
                    </StyledButtonGroup>
                    {isGranted(UserPermissions.CanManageNodeTemplates) && (
                      <Menu onSelect={onMenuSelect} openOnClick={true}>
                        <MenuItem
                          cssClass={"day-group-menu-item-root"}
                          linkRender={MenuButtonRender}
                        >
                          <MenuItem
                            cssClass={"day-group-menu-item"}
                            linkRender={SelectDayPartsRender}
                            data={{
                              dayGroup: dayGroup,
                              itemName: DayGroupMenu.DayPartSelect
                            }}
                          />
                          <MenuItem
                            cssClass={"day-group-menu-item"}
                            linkRender={DuplicateDayGroupRender}
                            data={{
                              dayGroup: dayGroup,
                              itemName: DayGroupMenu.DuplicateDayGroup
                            }}
                            disabled={isDuplicateMenuItemDisabled(dayGroup)}
                          />
                        </MenuItem>
                      </Menu>
                    )}
                  </Box>
                  <div>
                    <ListBox
                      style={{ width: "100%" }}
                      data={dayGroup.dayParts}
                      textField="title"
                      valueField="dayGroupDayPartId"
                      selectedField={"isActive"}
                      item={customListBoxItem}
                      onItemClick={handleDayPartClickEvent}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

const StyledButtonGroup = styled(ButtonGroup)`
  box-shadow: none;
  height: 28px;
`;

const DayGroupsHeaderBox = styled(Box)`
  padding: 4px;
  border-bottom: 1px solid var(--carbon-lightergray);
`;

const DayGroupTitleText = styled(Box)`
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  color: var(--carbon-darkgray);
`;

const StyledStatusDiv = styled.div`
  text-align: center;
  display: inline-flex;
  vertical-align: middle;

  span {
    border-radius: 10px;
    padding: 1px 8px;
    font-size: 12px;
    color: var(--carbon-white);
    line-height: 16px;
    font-weight: 600;
  }
`;

export default NodeScheduleDayGroups;
