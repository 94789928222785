import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  {
    field: "RoleID",
    title: "Role ID",
    width: "100px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: -1,
    isPartOfUniqueKey: true
  },
  {
    field: "RoleName",
    title: "Role",
    width: "1400px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    maxLength: 50,
    canBeSaved: true,
    isPartOfUniqueKey: true
  },
  {
    field: "RoleDesc",
    title: "Description",
    width: "350px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 256,
    canBeSaved: true
  },
  {
    field: "IsSuperUser",
    title: "Super User",
    width: "150px",
    defaultShow: true,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true,
    defaultValue: false
  },
  {
    field: "IsActive",
    title: "Active",
    width: "285px",
    defaultShow: true,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true,
    defaultValue: true
  }
];
