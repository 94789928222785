/* eslint-disable */

import {
  BooleanFilter,
  DateFilter,
  FieldSettings,
  FilterOperator,
  NumericFilter,
  Operators,
  TextFilter
} from "@progress/kendo-react-data-tools";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { ComboBox, DropDownList } from "@progress/kendo-react-dropdowns";
import { useState } from "react";
import { GridColumns } from "../../types/grid";

// Generate a UUID
export const uuid = (a = ""): string =>
  a
    ? ((Number(a) ^ (Math.random() * 16)) >> (Number(a) / 4)).toString(16)
    : `${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`.replace(/[018]/g, uuid);

// const CustomFilter = (props: any) => {
//   const { filter, data = [], defaultItem } = props;
//   const onChange = (event: any) => {
//     props.onFilterChange.call(undefined, {
//       nextFilter: { ...props.filter, value: event.target.value }
//     });
//   };

//   return <ComboBox onChange={onChange} data={sizes} />;
// };

// function that returns the field settings for the kendo react advanced filter expressions components
export const getGridFieldSettings = (
  columns: GridColumns[]
): FieldSettings[] => {
  const fieldSettings: FieldSettings[] = columns!.flatMap((column) => {
    let tempColumnFilter;
    let tempColumnOperator: FilterOperator[];

    if (
      column.combinedCol ||
      column.editor === "thumbnail" ||
      column.systemHidden === true
    ) {
      return [];
    }

    // set the column filter and operator
    if (column?.dropItems?.length && column?.dropItems?.length > 0) {
      const CustomDropDownFilter = (props: any) => {
        const { filter, data, defaultItem } = props;
        const onChange = (event: any) => {
          props.onFilterChange.call(undefined, {
            nextFilter: { ...props.filter, value: event.target.value.value }
          });
        };

        return (
          <DropDownList
            tabIndex={0}
            onChange={onChange}
            value={column.dropItems?.find((val) => val.value === filter.value)}
            data={column.dropItems}
            textField={"text"}
            dataItemKey={"value"}
          />
        );
      };

      tempColumnFilter = CustomDropDownFilter;
      tempColumnOperator = Operators.boolean;
    } else if (column.editor === "status") {
      const CustomDropDownFilter = (props: any) => {
        const { filter, data, defaultItem } = props;
        const onChange = (event: any) => {
          props.onFilterChange.call(undefined, {
            nextFilter: { ...props.filter, value: event.target.value.value }
          });
        };

        return (
          <DropDownList
            tabIndex={0}
            onChange={onChange}
            value={column.statuses?.find((val) => val.value === filter.value)}
            data={column.statuses}
            textField={"text"}
            dataItemKey={"value"}
            filterable={false}
            validityStyles={false}
          />
        );
      };

      tempColumnFilter = CustomDropDownFilter;
      tempColumnOperator = Operators.boolean;
    } else {
      switch (column.filter) {
        case "text":
          tempColumnFilter = TextFilter;
          tempColumnOperator = Operators.text;
          break;
        case "boolean":
          tempColumnFilter = BooleanFilter;
          tempColumnOperator = Operators.boolean;
          break;
        case "numeric":
          tempColumnFilter = NumericFilter;
          tempColumnOperator = Operators.numeric;
          break;
        case "date":
          const CustomDatePickerFilter = (props: any) => {
            const { filter, data, defaultItem } = props;
            const onChange = (event: any) => {
              // this should be a date
              const dateVal: Date = event.target.value;
              props.onFilterChange.call(undefined, {
                nextFilter: {
                  ...props.filter,
                  value: dateVal.toISOString().split("T")[0]
                }
              });
            };

            return (
              <DatePicker
                defaultValue={new Date(filter.value)}
                onChange={onChange}
              />
            );
          };

          tempColumnFilter = CustomDatePickerFilter;
          tempColumnOperator = Operators.date;
          break;
        default:
          break;
      }
    }

    if (tempColumnFilter) {
      const tempFieldSettingsObj: FieldSettings = {
        name: column.field,
        label: column.title,
        filter: tempColumnFilter,
        operators: tempColumnOperator!
      };

      return tempFieldSettingsObj;
    }

    return [];
  });

  return fieldSettings;
};
