import { Helmet } from "react-helmet-async";
import { Box } from "grommet";

// Contexts
import { useGrid } from "../../contexts/grid/useGrid";

// Components
import CarbonGridToolbar, {
  GridToolbarActionLabels,
  GridToolbarItem
} from "../../components/CarbonGrid/Toolbar/CarbonGridToolbar";
import CarbonGrid from "../../components/CarbonGrid/CarbonGrid";
import FontFilesUploadDialogue from "./FontFilesUploadDialogue";
import Authorizer from "../../components/Util/Authorizer";

// Types & Constants
import { GridType } from "../../types/grid";
import { gridColumns } from "./FontsGridColumns";

import { useState } from "react";
import usePermissions from "../../hooks/auth/usePermissions";
import {
  GridActions,
  GridIDs,
  Placeholders,
  UserPermissions,
  CarbonIcons,
  FileUploadTypes
} from "../../constants";
import {
  GridActionBarEvent,
  GridActionBarItem
} from "../../components/CarbonGrid/Toolbar/CarbonGridRowActionBar";
import useFileDownload from "../../hooks/utils/useFileDownload";

const Fonts = (): JSX.Element => {
  // Using pathname for grid id for simple screens with a single grid
  const { canInsert, canDelete } = usePermissions();
  const gridName = GridIDs.Fonts;
  const { setGrid } = useGrid();
  const { downloading, downloadFile } = useFileDownload(FileUploadTypes.Fonts);
  const [uploadZoneVisible, setUploadZoneVisible] = useState<boolean>(false);

  const pagePermission = UserPermissions.FontsPerms;

  const actionLabels: GridToolbarActionLabels = {
    singular: "font",
    plural: "fonts",
    titleSingular: "Font",
    titlePlural: "Fonts"
  };

  const actions: GridActionBarItem[] = [
    {
      show: true,
      label: "Download file",
      includeInExtrasMenu: true,
      icon: CarbonIcons.Download,
      onClick: async (event: GridActionBarEvent) => {
        await downloadFile(-1, event.rowData.FontID);
      }
    }
  ];

  const gridSettings: GridType = {
    actions,
    endpoints: {
      gridODataEndpoint: `/odata/${Placeholders.companyID}/gridfonts?$count=true&`,
      gridApiEndpoint: `/api/${Placeholders.companyID}/fonts`
    },
    records: [],
    total: 0,
    data: [],
    lookups: {},
    dataState: {
      pageSize: 25,
      take: 50,
      skip: 0,
      sort: [{ field: "FontName", dir: "asc" }],
      filter: {
        logic: "and",
        filters: [{ field: "IsActive", operator: "equals", value: true }]
      }
    },
    dataItemKey: "FontID",
    singularEntityName: actionLabels.singular,
    pluralEntityName: actionLabels.plural,
    columns: gridColumns,
    showCopyBtn: false,
    showDeleteBtn: canDelete(pagePermission),
    state: {
      editMode: false,
      insertMode: false,
      selectedState: {},
      selectedRow: -1,
      showQuickFilter: false,
      lastSaveDate: null,
      showSavingIndicator: false,
      lockoutMode: false
    }
  };

  const addBtn: GridToolbarItem = {
    show: true,
    enable: canInsert(pagePermission),
    tooltip: canInsert(pagePermission)
      ? `Create a new ${actionLabels.singular}`
      : `You do not have permission to create a new ${actionLabels.singular}.`,
    labelOverride: `Add ${actionLabels.titlePlural}`,
    onClick: () => setUploadZoneVisible(true)
  };

  const handlePostUploadFiles = (fontId: number | null) => {
    // If any files were uploaded, the id of the first file successfully uploaded will be passed to this function. Use that id to filter the
    //  the grid to show all fonts with an id >= this one.
    // fontId will be null if no files were uploaded so don't set a grid filter in that case.
    if (fontId !== null) {
      const filter = {
        logic: "and",
        filters: [{ field: "FontID", operator: "gte", value: fontId }]
      };

      setGrid({
        type: GridActions.setCustomFilter,
        payload: { gridId: gridName, gridData: filter }
      });
    }

    setUploadZoneVisible(false);
  };

  return (
    <>
      <Helmet>
        <title>Carbon | Fonts</title>
      </Helmet>
      <Authorizer
        canView={pagePermission}
        auditLabel={`${actionLabels.titlePlural} screen`}
      >
        <Box align="start" fill>
          <CarbonGridToolbar
            gridId={gridName}
            screenIcon={CarbonIcons.Fonts}
            screenTitle={actionLabels.titlePlural}
            permissions={pagePermission}
            actionLabels={actionLabels}
            addBtn={addBtn}
            isFileDownloading={downloading}
          />
          {uploadZoneVisible && (
            <FontFilesUploadDialogue
              onPostUploadFiles={handlePostUploadFiles}
            />
          )}
          <Box fill className="carbon-grid-wrapper">
            <CarbonGrid gridId={gridName} gridSettings={gridSettings} />
          </Box>
        </Box>
      </Authorizer>
    </>
  );
};

export default Fonts;
