import { Placeholders } from "../../constants";
import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  {
    field: "CompanyName",
    title: "Company",
    titlePlural: "Companies",
    width: "250px",
    defaultShow: true,
    filter: "text",
    editable: false,
    editor: "lookup",
    required: false,
    valueField: "CompanyID",
    maxLength: 50,
    canBeSaved: false,
    companyMaster: true,
    defaultValue: Placeholders.companyName
  },
  {
    field: "UserName",
    title: "Username",
    width: "179px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    maxLength: 50,
    canBeSaved: true,
    isPartOfUniqueKey: true
  },
  {
    field: "FirstName",
    title: "First Name",
    width: "200px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    maxLength: 50,
    canBeSaved: true
  },
  {
    field: "LastName",
    title: "Last Name",
    width: "160px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    maxLength: 50,
    canBeSaved: true
  },
  {
    field: "RoleName",
    title: "Role",
    titlePlural: "Roles",
    width: "350px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: true,
    valueField: "RoleID",
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "IsLocked",
    title: "Locked",
    width: "150px",
    defaultShow: true,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true,
    defaultValue: false
  },
  {
    field: "LastLoginDate",
    title: "Last Login Date",
    width: "200px",
    defaultShow: true,
    filter: "date",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "IsSuperUser",
    title: "Super User",
    width: "150px",
    defaultShow: true,
    filter: "boolean",
    editable: false,
    editor: "boolean",
    required: false,
    canBeSaved: false
  },
  {
    field: "IsMultCompanyMgr",
    title: "Company Mgr.",
    width: "150px",
    defaultShow: true,
    filter: "boolean",
    editable: false,
    editor: "boolean",
    required: false,
    canBeSaved: false
  },
  {
    field: "Email",
    title: "Email",
    width: "350px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    maxLength: 50,
    canBeSaved: true
  },
  {
    field: "PhoneNumber",
    title: "Phone Number",
    width: "200px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    maxLength: 50,
    canBeSaved: true
  },
  {
    field: "LoggedIn",
    title: "Logged In",
    width: "150px",
    defaultShow: true,
    filter: "boolean",
    editable: false,
    editor: "boolean",
    required: false,
    canBeSaved: false
  },
  {
    field: "VigilixID",
    title: "PC ID",
    titlePlural: "PC IDs",
    width: "150px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "lookup",
    required: false,
    canBeSaved: true,
    valueField: "VigilixID"
  },
  {
    field: "IsActive",
    title: "Active",
    width: "150px",
    defaultShow: true,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true,
    defaultValue: true
  },
  {
    field: "UserID",
    title: "UserID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: -1,
    isPartOfUniqueKey: true
  },
  {
    field: "CompanyID",
    title: "CompanyID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    company: true,
    defaultValue: Placeholders.companyID,
    isPartOfUniqueKey: true,
    systemHidden: true
  },
  {
    field: "RoleID",
    title: "RoleID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  }
];
