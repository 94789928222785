import { FC, useState } from "react";

// Hooks
import useChangePassword from "../../../hooks/credentials/useChangePassword";

// Types and Constants
import { ChangePasswordContract, Result } from "../../../types";
import { CarbonIcons, ResultTypes, StoreActions } from "../../../constants";
import { FormInput } from "../../Forms/Login";
import { authEndpoints } from "../../../constants/endpoints";

// Components
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Box } from "grommet";

// Contexts
import { useStore } from "../../../contexts/store";

interface IChangePasswordDialogProps {
  userId?: number;
  confirmation: boolean;
  closeDialog: () => void;
}

const ChangePasswordDialog: FC<IChangePasswordDialogProps> = ({
  userId,
  confirmation,
  closeDialog
}) => {
  const { changePassword } = useChangePassword();
  const { dispatch } = useStore();

  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const handleSubmit = async (formValues: any) => {
    const changePasswordContract: ChangePasswordContract = {
      userId: userId,
      currentPassword: formValues.currentPassword,
      newPassword: formValues.newPassword
    };

    if (
      (changePasswordContract.currentPassword || !confirmation) &&
      formValues.confirmNewPassword
    ) {
      setShowErrorMessage(false);

      let endpoint: string;
      if (confirmation) endpoint = authEndpoints.changePasswordEndpoint;
      else endpoint = authEndpoints.changeUserPasswordEndpoint;

      if (
        changePasswordContract.newPassword === formValues.confirmNewPassword
      ) {
        const changePasswordResult: Result<string> = await changePassword(
          changePasswordContract,
          endpoint
        );
        if (changePasswordResult.type === ResultTypes.Success) {
          console.log("good Password Change");
          dispatch({
            type: StoreActions.addNotification,
            payload: {
              message: "Password has been updated",
              messageType: "success",
              closable: false
            }
          });
          closeDialog();
        } else {
          console.log("bad Password Change");

          const result = JSON.parse(
            JSON.stringify(changePasswordResult.error)
          ).message;
          if (result) {
            setErrorMessage(result);
            setShowErrorMessage(true);
          }
        }
      } else {
        setErrorMessage("New Password and Confirmation do not match");
        setShowErrorMessage(true);
      }
    }
  };

  const CustomTitleBar = () => {
    return (
      <h1>
        {CarbonIcons.ChangePassword}
        <br />
        {"Set New Password"}
      </h1>
    );
  };

  return (
    <Dialog title={<CustomTitleBar />} closeIcon={false}>
      <Form
        onSubmit={handleSubmit}
        render={(formRenderProps) => (
          <FormElement
            style={{
              width: "100%"
            }}
          >
            {showErrorMessage && (
              <div className={"k-messagebox k-messagebox-error"}>
                {errorMessage}
              </div>
            )}
            {confirmation && (
              <Field
                width="medium"
                id={"currentPassword"}
                name={"currentPassword"}
                label={"Current Password"}
                maxLength={50}
                fieldType="password"
                component={FormInput}
                autoFocus
              />
            )}
            <Field
              width="medium"
              id={"newPassword"}
              name={"newPassword"}
              label={"New Password"}
              maxLength={50}
              fieldType="password"
              component={FormInput}
              autoFocus={!confirmation}
            />
            <Field
              width="medium"
              id={"confirmNewPassword"}
              name={"confirmNewPassword"}
              label={"Confirm New Password"}
              maxLength={50}
              fieldType="password"
              component={FormInput}
            />
            <Box pad={{ top: "medium" }} />
            <DialogActionsBar>
              <Box direction="row-reverse">
                <Button
                  primary={true}
                  onClick={handleSubmit}
                  type="submit"
                  disabled={
                    //  If the CompanyCode and Username fields OR the Email field is filled out, button will enable
                    !(
                      (formRenderProps.valueGetter("currentPassword") ||
                        !confirmation) &&
                      formRenderProps.valueGetter("newPassword") &&
                      formRenderProps.valueGetter("confirmNewPassword") &&
                      formRenderProps.allowSubmit
                    )
                  }
                >
                  {CarbonIcons.Check} Submit
                </Button>
                <Button onClick={closeDialog}>
                  {CarbonIcons.Close} Cancel
                </Button>
              </Box>
            </DialogActionsBar>
          </FormElement>
        )}
      />
    </Dialog>
  );
};

export default ChangePasswordDialog;
