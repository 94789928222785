import { ForgotPasswordContract, Result } from "../../types";
import useApi from "../../hooks/api/useApi";
import { ResultTypes } from "../../constants";
import ForgotPasswordApi from "../../api/credentials/forgotPasswordApi";

const useForgotPassword = () => {
  const { loading: forgotPasswordLoading, request: forgotPasswordRequest } =
    useApi(ForgotPasswordApi);

  const forgotPassword = async (
    forgotPasswordContract: ForgotPasswordContract
  ): Promise<Result<boolean>> => {
    let result: Result<boolean>;
    try {
      const forgotPasswordResult = (result = await forgotPasswordRequest(
        forgotPasswordContract
      ));
      if (forgotPasswordResult.type === ResultTypes.Success) {
        console.log(forgotPasswordResult.value.data);
      }
    } catch (error) {
      return { type: "error", error: error };
    }

    return result;
  };

  const isLoading = (): boolean => {
    return forgotPasswordLoading;
  };

  return {
    forgotPassword,
    isLoading
  };
};

export default useForgotPassword;
