import {
  GridColumnMenuItemContent,
  GridColumnMenuItemGroup
} from "@progress/kendo-react-grid";
import { Tooltip } from "@progress/kendo-react-tooltip";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "@progress/kendo-react-buttons";
import { Box } from "grommet";
import {
  Api,
  CarbonIcons,
  GridActions,
  GridIDs,
  StoreActions
} from "../../../constants";
import { useGrid } from "../../../contexts/grid/useGrid";
import { useStore } from "../../../contexts/store";
import apiClient from "../../../api";
import {
  ColumnOrderLayout,
  ColumnResizeLayout,
  ColumnVisibilityLayout,
  GridLayout
} from "../../../types";
import { CarbonGridStateType } from "../CarbonGrid";
import useApi from "../../../hooks/api/useApi";

export interface GridSelectorColumn {
  field: string;
  defaultShow: boolean;
  required: boolean;
  show: boolean;
  title: string;
  isCombinedCol: boolean;
  systemHidden: boolean;
}

interface IGridColumnSelector {
  carbonGridState: CarbonGridStateType;
  setCarbonGridState: any;
  gridId: string;
}

const GridColumnSelector: FC<IGridColumnSelector> = ({
  carbonGridState,
  setCarbonGridState,
  gridId
}) => {
  const { grids, setGrid } = useGrid();
  const { store, dispatch } = useStore();
  const [visibilitySelectorColumns, setvisibilitySelectorColumns] = useState(
    grids.get(gridId)?.state.visibilitySelectorColumns ||
      ([] as GridSelectorColumn[])
  );

  // sometimes the grid doesnt have the columns set in its state
  if (visibilitySelectorColumns.length === 0) {
    const defaultSelectorColumns: GridSelectorColumn[] = grids
      .get(gridId)!
      .defaultColumns!.map((val) => {
        const tempGridSelectorColumn: GridSelectorColumn = {
          field: val.field,
          defaultShow: val.defaultShow,
          required: val.required,
          show: val.defaultShow,
          title: val.title,
          isCombinedCol: val.combinedCol !== undefined,
          systemHidden: val.systemHidden === true
        };

        return tempGridSelectorColumn;
      });
    setvisibilitySelectorColumns(defaultSelectorColumns);
  }

  useEffect(() => {
    const columnSelectors = grids?.get(gridId)?.state.visibilitySelectorColumns;
    // console.log("the cols", columnSelectors);
    if (columnSelectors !== undefined) {
      setvisibilitySelectorColumns(columnSelectors);
    }
  }, [grids.get(gridId)?.state.visibilitySelectorColumns]);

  const onToggleColumn = (id: number) => {
    const newCols = visibilitySelectorColumns!.map((column, idx) => {
      return idx === id ? { ...column, show: !column.show } : column;
    });

    // console.log("visibility selector cols", visibilitySelectorColumns);
    setGrid({
      type: GridActions.setVisiblitySelectorColumns,
      payload: {
        gridId,
        gridData: {
          columns: newCols
        }
      }
    });
  };

  const onReset = (event: React.FormEvent<HTMLFormElement>) => {
    // Reset all columns back to their default show setting
    event.preventDefault();
    const allColumns = visibilitySelectorColumns!.map((col) => {
      return {
        ...col,
        show: col.defaultShow
      };
    });

    setGrid({
      type: GridActions.setVisiblitySelectorColumns,
      payload: {
        gridId,
        gridData: {
          columns: allColumns
        }
      }
    });
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }
    setGrid({
      type: GridActions.onUpdateVisibleColumns,
      payload: {
        gridId,
        gridData: {
          columns: visibilitySelectorColumns
        }
      }
    });
    setCarbonGridState({ ...carbonGridState, showColumnSelector: false });
  };

  const { request: deleteLayoutRequest } = useApi(
    async (gridUserLayoutId: number) =>
      await apiClient.delete(
        `${Api.baseUrl}/api/-1/gridlayouts/${gridUserLayoutId}`
      )
  );

  const onRestoreLayout = async () => {
    const currentActiveGrid = grids.get(gridId);

    setCarbonGridState({ ...carbonGridState, showColumnSelector: false });

    // action below restores the layout within the grid state
    setGrid({
      type: GridActions.restoreLayout,
      payload: { gridId: gridId, gridData: {} }
    });

    const gridUserLayoutId =
      store.user?.userSettings.layouts.get(gridId)?.gridUserLayoutId;
    if (gridUserLayoutId) {
      // jon, 3/11/22: Change all direct apiClient calls to use the useApi hook for proper refresh token handling.
      const deleteLayoutResponse = await deleteLayoutRequest(gridUserLayoutId);
      if (deleteLayoutResponse.type === "error") return;
      const result = deleteLayoutResponse.value;

      if (result.status === 200) {
        store.user?.userSettings.layouts.set(gridId, null);
        dispatch({
          type: StoreActions.addNotification,
          payload: {
            message: `Restored ${currentActiveGrid?.pluralEntityName} screen layout to default.`,
            messageType: "success",
            closable: false
          }
        });
      } else {
        dispatch({
          type: StoreActions.addNotification,
          payload: {
            message: `Failed to restore ${currentActiveGrid?.pluralEntityName} screen layout.`,
            messageType: "error",
            closable: false
          }
        });
      }
    }

    store.user?.userSettings.layouts.delete(gridId);
  };

  const { request: saveLayoutRequest } = useApi(
    async (dataObj: any) =>
      await apiClient.post(`${Api.baseUrl}/api/-1/gridlayouts`, dataObj)
  );

  const onSaveLayout = async () => {
    const currentActiveGrid = grids.get(gridId);
    const existingLayout = store.user?.userSettings.layouts.get(gridId);
    // construct data object
    let columnOrderLayout =
      currentActiveGrid?.state.columnOrderLayout ||
      existingLayout?.columnOrderLayout;

    let columnResizeLayout: ColumnResizeLayout[] | undefined = [];

    if (
      existingLayout?.columnResizeLayout &&
      currentActiveGrid?.state.columnResizeLayout
    ) {
      // merge items and overwrite values with current layout if there exists one
      columnResizeLayout = [...currentActiveGrid?.state.columnResizeLayout];
      existingLayout?.columnResizeLayout.forEach((val) => {
        if (!columnResizeLayout?.find((col) => col.field === val.field)) {
          columnResizeLayout?.push(val);
        }
      });
    } else if (existingLayout?.columnResizeLayout) {
      columnResizeLayout = existingLayout?.columnResizeLayout;
    } else {
      columnResizeLayout = currentActiveGrid?.state.columnResizeLayout;
    }

    let gridSortLayout =
      currentActiveGrid?.state.gridSortLayout || existingLayout?.gridSort;

    const columnVisibilityLayout = visibilitySelectorColumns!.map((item) => {
      const tempColumn: ColumnVisibilityLayout = {
        field: item.field,
        show: item.show!
      };
      return tempColumn;
    });

    // check visibility last and cleanse all custom layouts from columns that are not visible
    // user can sort, resize, and reorder column then finally render it invisbile, thus eliminating all applied effects on the column
    for (const col of columnVisibilityLayout!) {
      if (col.show === false) {
        columnOrderLayout = columnOrderLayout?.filter(
          (val) => val.field !== col.field
        );
        columnResizeLayout = columnResizeLayout?.filter(
          (val) => val.field !== col.field
        );
        gridSortLayout = gridSortLayout?.filter(
          (val) => val.field !== col.field
        );
      }
    }

    if (columnOrderLayout?.length === 0) {
      columnOrderLayout = undefined;
    }

    if (columnResizeLayout?.length === 0) {
      columnResizeLayout = undefined;
    }

    if (gridSortLayout?.length === 0) {
      gridSortLayout = undefined;
    }

    // reorder column indicies
    columnOrderLayout?.map((val, index) => {
      const columnOrderLayout: ColumnOrderLayout = {
        field: val.field,
        orderIndex: index
      };

      return columnOrderLayout;
    });

    const layout: GridLayout = {
      columnOrderLayout: columnOrderLayout?.map((val) => {
        const columnOrderLayout: ColumnOrderLayout = {
          field: val.field,
          orderIndex: val.orderIndex
        };
        return columnOrderLayout;
      }),
      columnResizeLayout: columnResizeLayout,
      gridSort: gridSortLayout,
      columnVisibilityLayout
    };

    const dataObj = {
      UserID: store.user?.userID,
      GridID: gridId,
      Layout: layout
    };

    // jon, 3/11/22: Change all direct apiClient calls to use the useApi hook for proper refresh token handling.
    const saveLayoutResponse = await saveLayoutRequest(dataObj);
    if (saveLayoutResponse.type === "error") return;
    const result = saveLayoutResponse.value;

    if (result.status === 200) {
      // console.log(result.data);
      dispatch({
        type: StoreActions.addNotification,
        payload: {
          message: `Layout for ${currentActiveGrid?.pluralEntityName} screen has been saved.`,
          messageType: "success",
          closable: false
        }
      });
      // overwrite existing layout if there is one
      dataObj.Layout.gridUserLayoutId = result.data.GridUserLayoutID;
      store.user?.userSettings.layouts.set(gridId, dataObj.Layout);
      // update the checked/unchecked column selector values in the layout menu
      setGrid({
        type: GridActions.onUpdateVisibleColumns,
        payload: {
          gridId,
          gridData: {
            columns: visibilitySelectorColumns
          }
        }
      });
    } else {
      dispatch({
        type: StoreActions.addNotification,
        payload: {
          message: `Failed to save ${currentActiveGrid?.pluralEntityName} screen layout.`,
          messageType: "error",
          closable: false
        }
      });
    }

    setCarbonGridState({ ...carbonGridState, showColumnSelector: false });
  };

  const handleCancel = () => {
    setCarbonGridState({ ...carbonGridState, showColumnSelector: false });
  };

  const gridLayoutExists = () => {
    const gridLayoutExists = store.user?.userSettings.layouts.get(gridId);
    return gridLayoutExists;
  };

  // const gridLayoutHasBeenModified = () => {
  //   const currentActiveGrid = grids.get(gridId);

  //   if (
  //     currentActiveGrid?.state.columnOrderLayout ||
  //     currentActiveGrid?.state.columnResizeLayout ||
  //     currentActiveGrid?.state.gridSortLayout
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  return (
    <>
      {carbonGridState.showColumnSelector && (
        <StyledDiv>
          <Tooltip anchorElement="pointer" position="top">
            <div className={"save-restore-layout-section"}>
              <h1>Layout Changes</h1>
              <Box direction="row" alignContent="center" gap="small">
                <Button
                  disabled={!gridLayoutExists()}
                  onClick={onRestoreLayout}
                  title="Restore the default layout for this screen"
                >
                  Restore Layout
                </Button>
                <Button
                  primary={true}
                  onClick={onSaveLayout}
                  // title="Save current column order, size, and visibility as the default for this screen."
                >
                  {CarbonIcons.Done}
                  Save Layout
                </Button>
              </Box>
            </div>
            <GridColumnMenuItemGroup>
              <h2>Hide/Show Columns</h2>
              <GridColumnMenuItemContent show={true}>
                <div className={"k-column-list-wrapper"}>
                  <form onSubmit={onSubmit} onReset={onReset}>
                    <div className={"k-column-list"}>
                      {visibilitySelectorColumns?.map(
                        (column, idx) =>
                          !column.isCombinedCol &&
                          !(column.systemHidden === true) &&
                          !(
                            // will, 2/3/22: needed to remove empty select for the global changes zone select.
                            // Did not use 'systemHidden' becaues this also hides the column in the viewable grid.
                            (
                              gridId === GridIDs.GlobalChanges &&
                              column.field === "IsSelected"
                            )
                          ) && (
                            <div key={idx} className={"k-column-list-item"}>
                              <span title={column.required ? "Required" : ""}>
                                <input
                                  id={`column-visiblity-show-${idx}`}
                                  className="k-checkbox"
                                  type="checkbox"
                                  readOnly={true}
                                  disabled={column.required}
                                  checked={column.show}
                                  onClick={() => {
                                    onToggleColumn(idx);
                                  }}
                                />
                                <label
                                  htmlFor={`column-visiblity-show-${idx}`}
                                  className="k-checkbox-label"
                                  style={{ userSelect: "none" }}
                                >
                                  {column.title}
                                </label>
                              </span>
                            </div>
                          )
                      )}
                    </div>
                    <div
                      className={"k-columnmenu-actions"}
                      style={{ height: "45px" }}
                    >
                      <button
                        onClick={handleCancel}
                        type={"reset"}
                        className={"k-button"}
                      >
                        Cancel
                      </button>
                      <button className={"k-button k-primary"} autoFocus>
                        Done
                      </button>
                    </div>
                  </form>
                </div>
              </GridColumnMenuItemContent>
            </GridColumnMenuItemGroup>
          </Tooltip>
        </StyledDiv>
      )}
    </>
  );
};

const StyledDiv = styled.div`
  position: absolute;
  z-index: 3;
  top: 28px;
  right: 10px;
  width: 340px;
  height: 610px;
  background-color: var(--carbon-white);
  border: 1px solid var(--carbon-mediumgray);
  box-shadow: 2px 2px 8px var(--carbon-griddropshadow);

  div.save-restore-layout-section {
    padding: 10px;
    margin-bottom: 20px;

    h1 {
      margin-bottom: 10px;
    }
  }

  div.k-column-list-wrapper div.k-column-list {
    margin-top: 4px;
    height: 400px;
    width: 95%;
    max-height: 400px;

    div.k-column-list-item {
      margin-top: 4px;
    }
  }

  h2 {
    padding: 6px 10px;
    margin: 0 0 0 5px;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid var(--carbon-lightgray);
  }
`;

export default GridColumnSelector;
