import {
  DropDownList,
  DropDownListChangeEvent
} from "@progress/kendo-react-dropdowns";
import React, { useState, MouseEvent, useEffect, useLayoutEffect } from "react";
import { GridActions } from "../../../constants";
import { useGrid } from "../../../contexts/grid/useGrid";
import { CellRenderProps } from "./CellRender";
import { unlockGridIfLockedOut } from "../Utility/GridUtility";

export const DropDownRender = ({
  originalProps,
  td,
  gridId,
  colDefinition,
  exitEdit
}: CellRenderProps) => {
  const { grids, setGrid } = useGrid();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [previousDataItem, setPreviousDataItem] = useState<{
    [key: string]: any;
  }>({});
  const [selectedValue, setSelectedValue] = useState<any>(
    colDefinition?.defaultValue ? colDefinition.defaultValue : null
  );
  let inputRef: HTMLInputElement | null;

  // Effect runs on first load and when field value changes to set the selected value/text
  useEffect(() => {
    if (isThisRowBeingEdited) {
      if (
        originalProps.dataItem[originalProps.field!] &&
        originalProps.dataItem[originalProps.field!] !== selectedValue
      ) {
        setSelectedValue(originalProps.dataItem[originalProps.field!]);
      }
    }
  }, [originalProps.dataItem[originalProps.field!]]);

  // Layout Effect runs when the grid's current edit field changes so we can see if we should set the focus if this is that field in insert mode
  useLayoutEffect(() => {
    if (
      grids.get(gridId)!.state.editFieldName === originalProps.field! &&
      grids.get(gridId)!.state.insertMode === true &&
      inputRef !== null
    ) {
      inputRef.focus();
    }
  }, [grids.get(gridId)!.state.editFieldName]);

  const enterEdit = (
    type: string,
    dataItem: { [key: string]: any },
    field: string | undefined
  ): void => {
    // console.log(
    //   `DROPDOWN CELL ENTER EDIT ${colDefinition?.field} - ${type}: `,
    //   dataItem,
    //   field
    // );
    setPreviousDataItem(dataItem);
    setGrid({
      type: GridActions.editFieldName,
      payload: { gridId, gridData: field }
    });
  };

  const isThisRowBeingEdited =
    grids.get(gridId)!.state.editMode &&
    !(grids.get(gridId)!.state.selectedRowIsExpanded ?? false) &&
    originalProps.dataItem[grids.get(gridId)!.dataItemKey] ===
      grids.get(gridId)!.state.selectedRow;

  const additionalProps = {
    ref: (tdElem: HTMLTableCellElement) => {
      inputRef = tdElem && tdElem.querySelector(".k-dropdown");
      inputRef?.addEventListener("keydown", (e) => {
        if ((e.key === "ArrowDown" || e.key === "ArrowUp") && e.ctrlKey) {
          e.stopImmediatePropagation();
          const target: Element = e.target as Element;
          const columnIndex = target
            .closest("[data-grid-col-index]")
            ?.getAttribute("data-grid-col-index");
          // trigger blur to initiate save
          (e.target as HTMLElement).blur();
          // jon, 3/23/22: I added a timeout here to allow for the save and then the navigation event since sometimes the nav would stop working afterwards.
          window.setTimeout(() => {
            setGrid({
              type: GridActions.onKeyNavigation,
              payload: {
                gridId,
                gridData: { key: e.key, colIndex: columnIndex }
              }
            });
          }, 1);
        }
      });
    },
    onFocus: () => {
      enterEdit("ON FOCUS", originalProps.dataItem, originalProps.field!);
    },
    onKeyDown: (e: KeyboardEvent) => {
      // If user hits ESC, undo any changes made to this cell and do not save
      if (e.key === "Escape") {
        unlockGridIfLockedOut(gridId, grids, setGrid);
        handleItemChange(previousDataItem[originalProps.field!], true);
      }
    },
    onMouseEnter: (e: MouseEvent<HTMLTableCellElement>) => {
      // Show tooltip only when cell is too small to show all contents
      const target = e.target as HTMLTableCellElement;
      setShowTooltip(target.offsetWidth < target.scrollWidth);
    },
    title: showTooltip ? originalProps.dataItem[originalProps.field!] : ""
  };

  const handleItemChange = (
    newValue: number | null,
    resetPreviousDataItem: boolean
  ) => {
    unlockGridIfLockedOut(gridId, grids, setGrid);
    // This set the selected item for this combobox only
    setSelectedValue(newValue);

    // This sets the new value in the grid data
    const newDataItem = { ...originalProps.dataItem };
    newDataItem[originalProps.field!] = newValue;

    // This reset is used when user clicks ESC
    if (resetPreviousDataItem) {
      setPreviousDataItem(newDataItem);
    }

    const grid = grids.get(gridId)!;

    if (grid.state.insertMode) {
      setGrid({
        type: GridActions.onInsertItemChange,
        payload: { gridId, gridData: newDataItem }
      });
    } else {
      const editedRecordID = originalProps.dataItem[grid.dataItemKey!];
      const records = grid.records.map((record) => {
        let newRecord = record;
        if (record[grid.dataItemKey!] === editedRecordID) {
          newRecord = newDataItem;
        }
        return newRecord;
      });

      setGrid({
        type: GridActions.onItemChange,
        payload: { gridId, gridData: records }
      });
    }

    // Dropdowns save as soon as user selects the value since they cannot type into the field.
    exitEdit("ON SELECT", originalProps.field!, previousDataItem, newDataItem, [
      originalProps.field!
    ]);

    // Update previous data item on save
    setPreviousDataItem(newDataItem);
  };

  const onChange = async (event: DropDownListChangeEvent) => {
    let newValue: number | null = null;

    if (event.target.value !== null) {
      newValue = event.target.value.value;
    }

    // Update selected item and grid values but keep previous value for now so it can be reset with ESC (last parameter).
    handleItemChange(newValue, false);
  };

  // We don't show the dropdown if the field is not editable
  const isEditable =
    colDefinition?.editable === true ||
    (colDefinition?.editableInInsertOnly === true &&
      grids.get(gridId)!.state.insertMode === true);

  const showDropdown: boolean = isThisRowBeingEdited && isEditable === true;

  const dataValue: { [key: string]: any } = {};
  dataValue.value = selectedValue;
  dataValue.text = colDefinition!.dropItems!.find(
    (item) => item.value === selectedValue
  )?.text;

  if (!showDropdown)
    return React.cloneElement(
      td,
      { ...td.props, ...additionalProps },
      <>
        {
          colDefinition!.dropItems!.find(
            (item) =>
              item.value === originalProps.dataItem[originalProps.field!]
          )?.text
        }
      </>
    );

  const elem = (
    <DropDownList
      tabIndex={0}
      data={colDefinition!.dropItems!}
      textField={"text"}
      dataItemKey={"value"}
      filterable={false}
      value={dataValue}
      validityStyles={false}
      onChange={onChange}
    />
  );

  return React.cloneElement(td, { ...td.props, ...additionalProps }, elem);
};
