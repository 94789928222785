import { Helmet } from "react-helmet-async";
import { Box } from "grommet";
import styled from "styled-components";

// Components
import CarbonGridToolbar, {
  GridToolbarActionLabels
} from "../../components/CarbonGrid/Toolbar/CarbonGridToolbar";
import CarbonGrid from "../../components/CarbonGrid/CarbonGrid";
import Authorizer from "../../components/Util/Authorizer";

// Types & Constants
import { GridType } from "../../types/grid";
import { gridColumns } from "./CampaignsGridColumns";

// User/Permissions
import usePermissions from "../../hooks/auth/usePermissions";
import {
  Placeholders,
  UserPermissions,
  GridIDs,
  CarbonIcons
} from "../../constants";

const Campaigns = (): JSX.Element => {
  // Using pathname for grid id for simple screens with a single grid
  const { canInsert, canDelete } = usePermissions();
  const gridName = GridIDs.Campaigns;

  const pagePermission = UserPermissions.CampaignsPerms;

  const icon = <StyledIcon>{CarbonIcons.Campaigns}</StyledIcon>;

  const actionLabels: GridToolbarActionLabels = {
    singular: "campaign",
    plural: "campaigns",
    titleSingular: "Campaign",
    titlePlural: "Campaigns"
  };

  const gridSettings: GridType = {
    endpoints: {
      gridODataEndpoint: `/odata/${Placeholders.companyID}/gridcampaigns?$count=true&`,
      gridApiEndpoint: `/api/${Placeholders.companyID}/campaigns`,
      lookupEndpoints: [
        {
          lookupField: "CampaignTypeName",
          endpoint: `/api/${Placeholders.companyID}/campaigntypes`
        },
        {
          lookupField: "CompanyName",
          endpoint: `/api/${Placeholders.companyID}/lookupcompanies`
        }
      ]
    },
    records: [],
    total: 0,
    data: [],
    lookups: {},
    dataState: {
      pageSize: 25,
      take: 50,
      skip: 0,
      sort: [
        { field: "CompanyName", dir: "asc" },
        { field: "CampaignName", dir: "asc" }
      ],
      filter: {
        logic: "and",
        filters: [{ field: "IsActive", operator: "equals", value: true }]
      }
    },
    dataItemKey: "CampaignID",
    singularEntityName: actionLabels.singular,
    pluralEntityName: actionLabels.plural,
    columns: gridColumns,
    showCopyBtn: canInsert(pagePermission),
    showDeleteBtn: canDelete(pagePermission),
    state: {
      editMode: false,
      insertMode: false,
      selectedState: {},
      selectedRow: -1,
      showQuickFilter: false,
      lastSaveDate: null,
      showSavingIndicator: false,
      lockoutMode: false
    }
  };

  return (
    <>
      <Helmet>
        <title>Carbon | Campaigns</title>
      </Helmet>
      <Authorizer
        canView={pagePermission}
        auditLabel={`${actionLabels.titlePlural} screen`}
      >
        <Box align="start" fill>
          <CarbonGridToolbar
            gridId={gridName}
            screenIcon={icon}
            screenTitle={actionLabels.titlePlural!}
            permissions={pagePermission}
            actionLabels={actionLabels}
          />
          <Box fill className="carbon-grid-wrapper">
            <CarbonGrid gridId={gridName} gridSettings={gridSettings} />
          </Box>
        </Box>
      </Authorizer>
    </>
  );
};

const StyledIcon = styled.span`
  span[class^="material-icons"] {
    font-size: xx-large;
  }
`;

export default Campaigns;
