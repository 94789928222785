import React, {
  ReactElement,
  useState,
  MouseEvent,
  useLayoutEffect
} from "react";
import { GridRowProps } from "@progress/kendo-react-grid";
import GridRowActionBar from "../Toolbar/CarbonGridRowActionBar";

// Contexts
import { useGrid } from "../../../contexts/grid/useGrid";
import { GridActions } from "../../../constants";

interface RowRenderProps {
  originalProps: GridRowProps;
  tr: ReactElement<HTMLTableRowElement>;
  gridId: string;
  hoverRowId: number;
  onHoverRow: (rowId: number) => void;
  exitEdit: (
    type: string | undefined,
    previousDataItem: { [p: string]: any },
    dataItem: { [p: string]: any },
    field: object
  ) => any;
}

let preventExitTimeout: any;
let blurTimeout: any;
let preventExit: boolean | undefined = true;
export const RowRender = ({
  originalProps,
  tr,
  gridId,
  hoverRowId,
  onHoverRow,
  exitEdit
}: RowRenderProps) => {
  const { grids, setGrid } = useGrid();
  // State for if we should show the action bar for this row
  const [showActionBar, setShowActionBar] = useState<boolean>(false);
  // State for figuring out right side of grid for action bar so we don't draw it off screen when grid scrolls horizontally
  const [actionBarPosition, setActionBarPosition] = useState<number>(0);

  const grid = grids.get(gridId);
  const rowId = grid ? originalProps.dataItem[grid.dataItemKey!] : -1;
  const gridEditMode = grid ? grid.state.editMode! : false;
  const gridInsertMode = grid ? grid.state.insertMode! : false;
  const gridDeletingRecord = grid && grid.state.recordIdToDelete;
  const actions = grid ? grid.actions : undefined;
  const showCopyBtn = grid ? grid.showCopyBtn! : false;
  const showDeleteBtn = grid ? grid.showDeleteBtn! : false;
  const isDetailGridShowing = grid ? grid.state.selectedRowIsExpanded : false;
  const parentGridId = grid ? grid.parentGridId : undefined;
  const selectedDayGroupDays =
    parentGridId && grids.get(parentGridId)
      ? `${grids.get(parentGridId!)?.state.selectedDayGroupDays}`
      : undefined;
  const activeDayPartId =
    parentGridId && grids.get(parentGridId)
      ? grids.get(parentGridId!)?.state.activeDayPart?.id
      : undefined;

  let trRef: HTMLTableRowElement | null;

  // Layout Effect runs when grid is put into insert mode in order to set focus.
  useLayoutEffect(() => {
    if (
      grids.get(gridId) &&
      grids.get(gridId)?.state.insertMode === true &&
      originalProps.isInEdit &&
      trRef !== null
    ) {
      trRef.querySelector("input")?.focus();
    }
  }, [grids.get(gridId)?.state.insertMode]);

  // Layout Effect runs when grid has a key navigation event that changes the row in order to set focus.
  useLayoutEffect(() => {
    if (
      grids.get(gridId) &&
      grids.get(gridId)?.state.editMode === true &&
      originalProps.isInEdit &&
      trRef !== null
    ) {
      const focusedColumnIndex = grids.get(gridId)?.state.focusedColumnIndex;
      const elem = trRef.querySelector(
        `td[data-grid-col-index="${focusedColumnIndex}"]`
      );
      // console.log("the td on switch", elem);
      if (elem) {
        // jon, 2/17/22: Wrapping the focusing/selecting here in a setTimeout because this is happening too early in some cases (like numeric textboxes)
        //   and causing the field to show as blank.  Even 1ms allows the page to render and put the value in so it gets selected properly.
        window.setTimeout(() => {
          // determine what the element is
          // check if it's a dropdown
          if (elem.querySelector(".k-dropdown-wrap")) {
            (elem.querySelector(".k-dropdown-wrap") as HTMLElement).focus();
          }

          if (elem.querySelector("input")) {
            // simple text field input
            elem.querySelector("input")?.select();
          }
        }, 1);
      }
    }
  }, [grids.get(gridId)?.state.selectedRow]);

  // If we are in edit/insert mode, dim all other rows except the one being edited/inserted. Except when detail row is showing.
  let trClass: string = tr.props.className;
  if (gridEditMode && !originalProps.isInEdit && !isDetailGridShowing) {
    trClass = `${trClass} grid-insert-row-overlay`;
  } else if (gridInsertMode && originalProps.isInEdit) {
    // If we are in insert mode and this is the insert row, highlight differently to make it stand out
    trClass = `${trClass} carbon-insert-row`;
  } else if (gridDeletingRecord) {
    // If we are deleting a record and this is the row being deleted, highlight differently to make it stand out
    trClass = `${trClass} carbon-delete-row`;
  }

  const trProps = {
    ...tr.props,
    className: trClass,
    onKeyDown: (event: any) => {
      // determine if it's a valid input that we can navigate away from
      if (
        (event.key === "ArrowUp" || event.key === "ArrowDown") &&
        event.ctrlKey
      ) {
        event.preventDefault();
        event.stopPropagation();
        const target: Element = event.target;
        const columnIndex = target
          .closest("[data-grid-col-index]")
          ?.getAttribute("data-grid-col-index");
        // trigger blur to initiate save
        event.target.blur();
        // jon, 3/23/22: I added a timeout here to allow for the save and then the navigation event since sometimes the nav would stop working afterwards.
        //   Note: this event in RowRender is for the textbox.
        window.setTimeout(() => {
          setGrid({
            type: GridActions.onKeyNavigation,
            payload: {
              gridId,
              gridData: { key: event.key, colIndex: columnIndex }
            }
          });
        }, 1);
      }
    },
    onMouseDown: () => {
      clearTimeout(preventExitTimeout);
      preventExitTimeout = setTimeout(() => {
        preventExit = undefined;
      });
    },
    onBlur: () => {
      clearTimeout(blurTimeout);
      if (!preventExit) {
        blurTimeout = setTimeout(() => {
          exitEdit("ROW", [], originalProps, tr.props!);
        });
      }
    },
    onFocus: () => {
      clearTimeout(blurTimeout);
    },
    onMouseEnter: (e: MouseEvent<HTMLTableRowElement>) => {
      // Determine width of grid not counting overflow so we can correctly position the grid action bar. To do this, we find the tr's parent presentation table element
      //  and get its width for the scroll width and the grid itself for the screen width.
      const tr = e.target as HTMLTableRowElement;
      const gridElem = tr.closest("div.k-grid") as HTMLDivElement;
      const scrollElem = tr.closest("div.k-grid-content") as HTMLDivElement;
      const rightIndentAdjustment: number = 17;
      const clientWidth: number = gridElem ? gridElem.clientWidth : 0;

      // If this is a subgrid, there will be a parent k-grid-content that we need to use above the scollElem to determine the viewable width
      // if (
      //   scrollElem.parentElement !== null &&
      //   scrollElem.parentElement.closest("div.k-grid-content") !== null
      // ) {
      //   scrollElem = scrollElem.parentElement.closest(
      //     "div.k-grid-content"
      //   ) as HTMLDivElement;

      //   // If full subgrid is in view because parent scroll width > subgrid width, use parent client width so calculation below is correct.
      //   if (scrollElem.clientWidth >= scrollElem.scrollWidth) {
      //     clientWidth = scrollElem.clientWidth;
      //   }

      //   if (scrollElem.clientWidth + scrollElem.scrollLeft > clientWidth) {
      //     // Right side of subgrid is in view so no need to adjust below
      //     rightIndentAdjustment = 17;
      //   } else {
      //     // Adjust according to how far we have scrolled relative to the width of the subgrid
      //     rightIndentAdjustment =
      //       clientWidth - (scrollElem.clientWidth + scrollElem.scrollLeft);
      //   }
      // }

      // Determine here how much user has scrolled horizontally so we can properly position the action bar relative to the right side of the screen, but not necessarily
      //   on the right side of the grid.
      let pos = scrollElem
        ? scrollElem.scrollWidth - clientWidth - scrollElem.scrollLeft
        : 0;
      if (pos < 0) pos = 0;
      pos += rightIndentAdjustment;
      // console.log(`Pos: ${pos}`);

      setActionBarPosition(pos);
      setShowActionBar(true);
      onHoverRow(rowId);
    },
    onMouseLeave: () => {
      setShowActionBar(false);
    }
  };

  const row =
    showActionBar && hoverRowId === rowId && !gridDeletingRecord ? (
      <>
        {tr.props.children}
        <td>
          <GridRowActionBar
            gridId={gridId}
            rowId={rowId}
            rowData={originalProps.dataItem}
            selectedDayGroupDays={selectedDayGroupDays}
            activeDayPartId={activeDayPartId}
            position={actionBarPosition}
            showCopyBtn={showCopyBtn}
            showDeleteBtn={showDeleteBtn}
            actions={actions!}
            gridInsertMode={gridInsertMode}
            gridEditMode={gridEditMode}
            isRowSelected={originalProps.isSelected}
          />
        </td>
      </>
    ) : (
      <>
        {tr.props.children}
        <td />
      </>
    );

  const additionalProps = {
    ref: (trElem: HTMLTableRowElement) => {
      trRef = trElem;
    }
  };

  return React.cloneElement(tr, { ...trProps, ...additionalProps }, row);
};
