import { Box } from "grommet";
import { useLayoutEffect, useRef, useState } from "react";
import { Outlet } from "react-router";
import { useWindowSize } from "../../../hooks/windowSize/useWindowSize";
import DashboardFooter from "./DashboardFooter";
import DashboardNavbar from "./DashboardNavbar";

const DashboardLayout = () => {
  const { windowHeight, windowWidth } = useWindowSize();
  const headerRef = useRef<HTMLDivElement>();
  const footerRef = useRef<HTMLDivElement>();
  const outerDivRef = useRef<HTMLDivElement>(null);
  const [innerDivHeight, setInnerDivHeight] = useState(0);

  const adjustScreenSize = () => {
    const outerDivHeight: number = outerDivRef.current?.clientHeight ?? 0;
    const headerDivHeight: number = headerRef.current?.clientHeight ?? 0;
    const footerDivHeight: number = footerRef.current?.clientHeight ?? 0;

    const newInnerDivHeight =
      outerDivHeight - (headerDivHeight + footerDivHeight);
    // console.log("New Layout Content Height: ", newInnerDivHeight);
    setInnerDivHeight(newInnerDivHeight);
  };

  useLayoutEffect(() => {
    // console.log(
    //   `Window resize event! Height: ${windowHeight}, Header Height: ${headerRef.current?.clientHeight}, Footer Height: ${footerRef.current?.clientHeight}`
    // );
    // jon, 2/22/22: Changed this effect to a layout effect to make sure it process last, but still needed a setTimeout here to be sure everything has already
    //   resized on first load before trying to set the content height based on the header and footer height.
    window.setTimeout(() => {
      adjustScreenSize();
    }, 100);
  }, [
    windowHeight,
    windowWidth,
    headerRef.current?.clientHeight,
    footerRef.current?.clientHeight
  ]);

  return (
    <div ref={outerDivRef} style={{ height: "100%", width: "100%" }}>
      <DashboardNavbar ref={headerRef} />
      <Box
        overflow="auto"
        background="var(--carbon-verylightgray)"
        pad="medium"
        height={`${innerDivHeight}px`}
        style={{ minHeight: innerDivHeight, maxHeight: innerDivHeight }}
      >
        <Outlet />
      </Box>
      <DashboardFooter ref={footerRef} />
    </div>
  );
};

export default DashboardLayout;
