import { Outlet } from "react-router";

const MainLayout = () => {
  /**
   * maybe add a footer or mainlayout navbar later?
   */

  return (
    <>
      <Outlet />
    </>
  );
};

export default MainLayout;
