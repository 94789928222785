import React, { useState, useLayoutEffect, useEffect, useRef } from "react";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import styled from "styled-components";
import { CarbonIcons, GridActions, UserPermissions } from "../../../constants";
import { useGrid } from "../../../contexts/grid/useGrid";
import { unlockGridIfLockedOut } from "../Utility/GridUtility";
import { CellRenderProps, UnMountSaveData } from "./CellRender";
import usePermissions from "../../../hooks/auth/usePermissions";

export const OverrideTextboxRender = ({
  originalProps,
  td,
  gridId,
  colDefinition,
  exitEdit
}: CellRenderProps) => {
  const { grids, setGrid } = useGrid();
  const { isGranted, canUpdate } = usePermissions();
  const [previousDataItem, setPreviousDataItem] = useState<{
    [key: string]: any;
  }>({});
  const [saving, setSaving] = useState<boolean>(false);

  const mounted = useRef(false);
  const saveData = useRef<UnMountSaveData>();

  let inputRef: HTMLInputElement | null = null;
  // const editFieldName = grids.get(gridId)!.state.editFieldName!;
  const dataItem = originalProps.dataItem;
  const field = originalProps.field!;
  const dataValue = dataItem[field!];
  const isThisRowBeingEdited =
    grids.get(gridId)!.state.editMode &&
    !(grids.get(gridId)!.state.selectedRowIsExpanded ?? false) &&
    originalProps.dataItem[grids.get(gridId)!.dataItemKey] ===
      grids.get(gridId)!.state.selectedRow;

  // jon, 4/3/22: Save data needed for possible save on unmount since this must use a ref because useEffect below only knows values as they were on mount.
  useEffect(() => {
    saveData.current = {
      previousDataItem: previousDataItem,
      dataItem: dataItem,
      isThisRowBeingEdited: isThisRowBeingEdited,
      saving: saving
    };
  }, [previousDataItem, dataItem, isThisRowBeingEdited, saving]);

  // jon, 4/3/22: Added effect to monitor if component is mounted. Save can now be initiated by scrolling off the grid, so need to check for mounted and
  //   initiate a save if value has changed.
  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;

      if (
        saveData.current &&
        saveData.current.isThisRowBeingEdited &&
        !saveData.current.saving &&
        saveData.current.previousDataItem[originalProps.field!] !== undefined &&
        saveData.current.dataItem[originalProps.field!] !==
          saveData.current.previousDataItem[originalProps.field!]
      ) {
        console.log(
          `Saving changes to override text field due to control unmounting...`
        );
        setSaving(true);
        doBlur(saveData.current.previousDataItem, saveData.current.dataItem);
      }
    };
  }, []);

  // Layout Effect runs when the grid's current edit field changes, so we can see if we should set the focus if this is that field in insert mode
  useLayoutEffect(() => {
    if (
      grids.get(gridId)!.state.editFieldName === originalProps.field! &&
      grids.get(gridId)!.state.insertMode &&
      inputRef !== null
    ) {
      inputRef.focus();
    }
  }, [grids.get(gridId)!.state.editFieldName]);

  const setMaxLength = () => {
    // Set max length on input if one is defined the column definition
    if (inputRef !== null && colDefinition?.maxLength) {
      inputRef.maxLength = colDefinition!.maxLength;
    }
  };

  const doBlur = async (
    prevDataItem: { [key: string]: any },
    curDataItem: { [key: string]: any }
  ) => {
    const result = await exitEdit("ON BLUR", field, prevDataItem, curDataItem, [
      field
    ]);
    if (result === null) setPreviousDataItem(curDataItem);
    if (mounted.current) setSaving(false);
  };

  const enterEdit = (
    type: string,
    dataItem: { [key: string]: any },
    field: string | undefined
  ): void => {
    // console.log(
    //   `OVERRIDE TEXTBOX CELL ENTER EDIT - ${type}: `,
    //   dataItem,
    //   field
    // );
    setPreviousDataItem(dataItem);
    setMaxLength();
    setGrid({
      type: GridActions.editFieldName,
      payload: { gridId, gridData: field }
    });
  };

  const additionalProps = {
    ref: (tdElem: HTMLTableCellElement) => {
      inputRef = tdElem && tdElem.querySelector("input");
    },
    onBlur: async () => {
      // jon, 4/11/22: Set saving flag so unmount does not also fire update if input is blurred and then unmounted immediately. Can cause Unique Key violation when none exists.
      setSaving(true);
      await doBlur(previousDataItem, dataItem);
    },
    onFocus: () => {
      enterEdit("ON FOCUS", { ...dataItem }, field);
    },
    onKeyDown: (e: KeyboardEvent) => {
      // If user hits ESC, undo any changes made to this cell and do not save
      if (e.key === "Escape") {
        unlockGridIfLockedOut(gridId, grids, setGrid);

        const newDataItem = { ...dataItem };
        newDataItem[field] = previousDataItem[field];
        setPreviousDataItem(newDataItem);

        const grid = grids.get(gridId)!;

        if (grid.state.insertMode) {
          setGrid({
            type: GridActions.onInsertItemChange,
            payload: { gridId, gridData: newDataItem }
          });
        } else {
          const editedRecordID = dataItem[grid.dataItemKey!];
          const records = grid.records.map((record) => {
            let newRecord = record;
            if (record[grid.dataItemKey!] === editedRecordID) {
              newRecord = newDataItem;
            }
            return newRecord;
          });

          setGrid({
            type: GridActions.onItemChange,
            payload: { gridId, gridData: records }
          });
        }
      }
    }
  };

  const onChange = async (event: InputChangeEvent) => {
    const newDataItem = { ...originalProps.dataItem };
    newDataItem[originalProps.field!] = event.target.value;

    handleChangeEvent(newDataItem);
  };

  const onClickUndoButton = async () => {
    // Reset value to null to set back to original value
    const newDataItem = { ...originalProps.dataItem };

    // jon, 1/20/22: Use null instead of blank so user can blank out the field without it getting reset to the original value.
    newDataItem[originalProps.field!] = null;

    handleChangeEvent(newDataItem);
    const result = await exitEdit(
      "ON UNDO",
      field,
      previousDataItem,
      newDataItem,
      [field]
    );
    if (result === null) setPreviousDataItem(newDataItem);
  };

  const handleChangeEvent = (newDataItem: { [key: string]: any }) => {
    const grid = grids.get(gridId)!;
    const editedRecordID = originalProps.dataItem[grid.dataItemKey!];
    const records = grid.records.map((record) => {
      let newRecord = record;
      if (record[grid.dataItemKey!] === editedRecordID) {
        newRecord = newDataItem;
      }
      return newRecord;
    });

    setGrid({
      type: GridActions.onItemChange,
      payload: { gridId, gridData: records }
    });
  };

  // Is this column overriding another column's value? This is used on PriceScheduleItems and NodeItems screens.
  //  The grid only shows the one price col (and other cols) from the "overrideColumn" unless it is overridden by this col. If so, we render it in bold
  //  and with a "reset" button.
  let isOverrideCol: boolean = false;

  // On NodeItems, the user can override the PriceScheduleItems price or the Items price directly. In those cases, the overrideColumn will contain two columns
  //  separated by a comma.  We check the first column for a value. If none, we use the value of the second column. A max of 2 columns is supported.
  const overrideColName = colDefinition!.overrideColumn!;
  let overridingValue: string;

  const pos = overrideColName.indexOf(",");
  if (pos >= 0) {
    const colName1 = overrideColName.substring(0, pos);
    const colName2 = overrideColName.substring(pos + 1);

    overridingValue =
      dataItem[colName1] &&
      dataItem[colName1] !== null &&
      dataItem[colName1] !== ""
        ? dataItem[colName1]
        : dataItem[colName2];
  } else {
    overridingValue = dataItem[colDefinition!.overrideColumn!];
  }

  // jon, 1/20/22: Use null instead of blank so user can blank out the field without it getting reset to the original value.
  if (dataValue !== null) {
    // Value is being overridden since we have a non-blank value in the field.
    isOverrideCol = true;
  }

  const { className, ...tdprops } = td.props;
  const newClassName = isOverrideCol ? className + " override-cell" : className;

  const finalProps = {
    ...tdprops,
    className: newClassName
  };

  let userHasEditPermission: boolean = false;
  if (colDefinition?.overridePermission) {
    const permission = colDefinition!.overridePermission!;
    // jon, 1/27/22: Most of these override permissions are simple flag-type we can check for isGranted, but Price Schedules is a screen-type permission that we need to check for update.
    userHasEditPermission =
      permission === UserPermissions.PriceSchedulesPerms
        ? canUpdate(permission)
        : isGranted(permission);
  }

  // jon, 1/27/22: User can only revert a price if they have the proper permission
  let elem = (
    <>
      {isOverrideCol ? (
        <StyledTextOverride>
          {dataValue}
          {userHasEditPermission && (
            <span
              title={`Reset value to: ${
                overridingValue && overridingValue !== ""
                  ? overridingValue
                  : "(blank)"
              }`}
              onClick={onClickUndoButton}
            >
              {CarbonIcons.Undo}
            </span>
          )}
        </StyledTextOverride>
      ) : (
        <span>{overridingValue}</span>
      )}
    </>
  );

  if (isThisRowBeingEdited) {
    const renderValue = dataValue === null ? "" : dataValue;
    elem = (
      <Input
        tabIndex={0}
        value={isOverrideCol ? renderValue : overridingValue}
        onChange={onChange}
      />
    );
  }

  return React.cloneElement(td, { ...finalProps, ...additionalProps }, elem);
};

const StyledTextOverride = styled.div`
  white-space: pre-wrap;
`;
