import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import React, { useEffect, useState } from "react";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Label } from "@progress/kendo-react-labels";
import styled from "styled-components";

export interface ITextFieldProps {
  id: string;
  label: string;
  labelWidth?: string;
  defaultValue: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  onChange: (value: string) => void;
}

export const TextField = (props: ITextFieldProps) => {
  const [selectedValue, setSelectedValue] = useState<string>(
    props.defaultValue
  );
  const labelWidth = props.labelWidth ?? "100px";
  const disabled = props.disabled ?? false;
  const required = props.required ?? false;
  const placeholder = props.placeholder ?? "";

  // Effect allows default value to be changed by calling page so the value here can be updated.
  useEffect(() => {
    if (selectedValue !== props.defaultValue) {
      setSelectedValue(props.defaultValue);
    }
  }, [props.defaultValue]);

  const onChange = async (event: InputChangeEvent) => {
    let newValue: string = "";
    if (event.value) newValue = event.value;

    // Update selected value
    setSelectedValue(newValue);
    props.onChange(newValue);
  };

  const label = required ? (
    <span style={{ fontWeight: "bold" }}>
      <span
        style={{
          color: "var(--carbon-orange)",
          display: "inline-block",
          marginLeft: "-12px"
        }}
      >
        *&nbsp;
      </span>
      <Label
        style={{ width: labelWidth, fontWeight: "bold" }}
        editorId={props.id}
      >
        {props.label}
      </Label>
    </span>
  ) : (
    <Label
      style={{ width: labelWidth, fontWeight: "bold" }}
      editorId={props.id}
    >
      {props.label}
    </Label>
  );

  const elem = (
    <StyledFieldWrapper>
      {label}
      <Input
        disabled={disabled}
        tabIndex={0}
        value={selectedValue}
        onChange={onChange}
        placeholder={placeholder}
      />
    </StyledFieldWrapper>
  );

  return React.cloneElement(elem);
};

const StyledFieldWrapper = styled(FieldWrapper)`
  margin-bottom: 8px;

  label {
    color: var(--carbon-darkgray);
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-right: 10px;
    display: inline-block;
  }

  input.k-textbox {
    display: inline-block;
    margin-top: -4px;
    font-size: 16px;
    padding-left: 4px;
    background: var(--carbon-white);
    border: 1px solid var(--carbon-lightgray) !important;
    box-sizing: border-box;
    border-radius: 6px;
    height: 28px;
  }
`;
