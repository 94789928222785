import apiClient from "..";
import { AxiosResponse } from "axios";

const GetGridRecordApi = (
  getRecordEndpoint: string
): Promise<AxiosResponse> => {
  const response = apiClient.get(getRecordEndpoint);
  return response;
};

export default GetGridRecordApi;
