import { Result, ResultError, AuditTrailRecordContract } from "../../types";
import useApi from "../../hooks/api/useApi";
import PostAuditTrailRecord from "../../api/AuditTrail/AuditTrailAPI";
import { AuditTrailTypes } from "../../constants";

const useAuditTrailRecordInsert = () => {
  const { loading: auditTrailInsertLoading, request: auditTrailInsertRequest } =
    useApi(PostAuditTrailRecord);

  const postAuditTrailRecord = async (
    auditTrailType: AuditTrailTypes,
    accessName: string
  ): Promise<Result<string>> => {
    let result: Result<string>;
    try {
      const recordContract: AuditTrailRecordContract = {
        auditTrailType: auditTrailType,
        accessName: accessName
      };

      result = await auditTrailInsertRequest(recordContract);
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }
    return result;
  };

  const isLoading = (): boolean => {
    return auditTrailInsertLoading;
  };

  return {
    postAuditTrailRecord,
    isLoading
  };
};

export default useAuditTrailRecordInsert;
