import { Helmet } from "react-helmet-async";
import { Box } from "grommet";

// Components
import CarbonGridToolbar, {
  GridToolbarActionLabels
} from "../../components/CarbonGrid/Toolbar/CarbonGridToolbar";
import CarbonGrid from "../../components/CarbonGrid/CarbonGrid";
import Authorizer from "../../components/Util/Authorizer";

// Types & Constants
import { GridType } from "../../types/grid";
import { gridColumns } from "./ItemsGridColumns";

// User/Permissions
import usePermissions from "../../hooks/auth/usePermissions";
import {
  Placeholders,
  UserPermissions,
  GridIDs,
  CarbonIcons
} from "../../constants";

const Items = (): JSX.Element => {
  // Using pathname for grid id for simple screens with a single grid
  const { canInsert, canDelete } = usePermissions();
  const gridName = GridIDs.Items;

  const pagePermission = UserPermissions.ItemsPerms;

  const actionLabels: GridToolbarActionLabels = {
    singular: "item",
    plural: "items",
    titleSingular: "Item",
    titlePlural: "Items"
  };

  const gridSettings: GridType = {
    endpoints: {
      gridODataEndpoint: `/odata/${Placeholders.companyID}/griditems?$count=true&`,
      gridApiEndpoint: `/api/${Placeholders.companyID}/items`,
      lookupEndpoints: [
        {
          lookupField: "ItemTypeName",
          endpoint: `/api/${Placeholders.companyID}/lookupitemtypes`
        }
      ]
    },
    records: [],
    total: 0,
    data: [],
    lookups: {},
    dataState: {
      pageSize: 25,
      take: 50,
      skip: 0,
      sort: [
        { field: "CompanyName", dir: "asc" },
        { field: "ItemName", dir: "asc" }
      ],
      filter: {
        logic: "and",
        filters: [{ field: "IsActive", operator: "equals", value: true }]
      }
    },
    dataItemKey: "ItemID",
    singularEntityName: actionLabels.singular,
    pluralEntityName: actionLabels.plural,
    columns: gridColumns,
    showCopyBtn: canInsert(pagePermission),
    showDeleteBtn: canDelete(pagePermission),
    state: {
      editMode: false,
      insertMode: false,
      selectedState: {},
      selectedRow: -1,
      showQuickFilter: false,
      lastSaveDate: null,
      showSavingIndicator: false,
      lockoutMode: false
    }
  };

  return (
    <>
      <Helmet>
        <title>Carbon | Text &amp; Prices</title>
      </Helmet>
      <Authorizer canView={pagePermission} auditLabel={"Text & Prices screen"}>
        <Box align="start" fill>
          <CarbonGridToolbar
            gridId={gridName}
            screenIcon={CarbonIcons.Items}
            screenTitle={"Text & Prices"}
            permissions={pagePermission}
            actionLabels={actionLabels}
          />
          <Box fill className="carbon-grid-wrapper">
            <CarbonGrid gridId={gridName} gridSettings={gridSettings} />
          </Box>
        </Box>
      </Authorizer>
    </>
  );
};

export default Items;
