import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  {
    field: "IsSelected",
    title: " ",
    width: "60px",
    defaultShow: true,
    filterable: false,
    sortable: false,
    resizable: false,
    editable: false,
    editor: "selectcheckbox",
    required: false,
    canBeSaved: false
  },
  {
    field: "NodeName",
    title: "Node",
    titlePlural: "Nodes",
    width: "345px",
    defaultShow: true,
    filter: "text",
    editable: false,
    editor: "text",
    required: false,
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "TemplateName",
    title: "Template",
    titlePlural: "Templates",
    width: "245px",
    defaultShow: true,
    filter: "text",
    editable: false,
    editor: "text",
    required: false,
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "ZoneName",
    title: "Zone",
    width: "125px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "ContentSeqNum",
    title: "Content Seq.",
    width: "135px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: true,
    canBeSaved: true,
    defaultValue: 1,
    minValue: 0
  },
  {
    field: "ZoneContent",
    title: "Content",
    width: "310px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "combinedcolumn",
    required: false,
    canBeSaved: false
  },
  {
    field: "ContentStatus",
    title: "Status",
    width: "110px",
    defaultShow: true,
    customFilter: "status",
    editable: false,
    editor: "status",
    required: false,
    canBeSaved: false,
    defaultValue: "reddot.gif",
    statuses: [
      {
        value: "greendot.gif",
        color: "var(--carbon-green)",
        text: "Current"
      },
      {
        value: "bluedot.gif",
        color: "var(--carbon-blue)",
        text: "Future"
      },
      {
        value: "reddot.gif",
        color: "var(--carbon-red)",
        text: "Expired"
      }
    ]
  },
  {
    field: "StartDate",
    title: "Cont. Start",
    width: "160px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "date",
    required: false,
    canBeSaved: true
  },
  {
    field: "EndDate",
    title: "Cont. End",
    width: "155px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "date",
    required: false,
    canBeSaved: true
  },
  {
    field: "NodeDayPartName",
    title: "Tmpl. Day Part",
    titlePlural: "Tmpl. Day Parts",
    width: "120px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "ScheduleWkDay",
    title: "Tmpl. Weekday",
    width: "185px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "StaticDisplayTime",
    title: "Static Display Time",
    width: "205px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: false,
    canBeSaved: true,
    defaultValue: 10,
    minValue: 0
  },
  {
    field: "TemplateStartDate",
    title: "Tmpl. Start",
    width: "165px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "TemplateEndDate",
    title: "Tmpl. End",
    width: "165px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "TemplateStatus",
    title: "Tmpl. Status",
    width: "185px",
    defaultShow: true,
    customFilter: "status",
    filterable: true,
    editable: false,
    editor: "status",
    required: false,
    canBeSaved: false,
    defaultValue: "reddot.gif",
    statuses: [
      {
        value: "greendot.gif",
        color: "var(--carbon-green)",
        text: "Current"
      },
      {
        value: "bluedot.gif",
        color: "var(--carbon-blue)",
        text: "Future"
      },
      {
        value: "reddot.gif",
        color: "var(--carbon-red)",
        text: "Expired"
      }
    ]
  },
  {
    field: "MediaTypeName",
    title: "Media Type",
    titlePlural: "Media Types",
    width: "185px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "PackageTypeName",
    title: "Package Type",
    titlePlural: "Package Types",
    width: "185px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "MediaZoneFilterName",
    title: "Media Zone Filter",
    width: "185px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "PackageZoneFilterName",
    title: "Package Zone Filter",
    width: "185px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "AssocStaticContent1",
    title: "Assoc. Static Content 1",
    width: "310px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "ContentCategory",
    title: "Category",
    width: "185px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: "S",
    dropItems: [
      {
        value: "S",
        text: "Static"
      },
      {
        value: "A",
        text: "Animated"
      }
    ]
  },
  {
    field: "Volume",
    title: "Volume",
    width: "165px",
    defaultShow: true,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: 0
  },
  {
    field: "ZoneDesc",
    title: "Zone Description",
    width: "310px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "TemplateOffsetMinutes",
    title: "Tmpl. Offset Time",
    width: "190px",
    defaultShow: true,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: 0
  },
  {
    field: "TemplateZIndex",
    title: "Tmpl. Z Index",
    width: "190px",
    defaultShow: true,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: 0
  },
  {
    field: "HorizontalOffset",
    title: "Tmpl. Hor. Offset",
    width: "190px",
    defaultShow: true,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: 0
  },
  {
    field: "DisplayOrder",
    title: "Tmpl. Display",
    titlePlural: "Tmpl. Displays",
    width: "190px",
    defaultShow: true,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "NodeDesc",
    title: "Node Description",
    width: "435px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    maxLength: 256,
    canBeSaved: false
  },
  {
    field: "LastProcessed",
    title: "Node Last Processed",
    width: "310px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "NodeTypeName",
    title: "Node Type",
    titlePlural: "Node Types",
    width: "185px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "PriceScheduleName",
    title: "Price Schedule",
    titlePlural: "Price Schedules",
    width: "185px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "PatchVersion",
    title: "Patch Version",
    width: "185px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "Address",
    title: "Node Address",
    width: "310px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    maxLength: 256,
    canBeSaved: false
  },
  {
    field: "City",
    title: "Node City",
    width: "185px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "State",
    title: "Node State",
    width: "185px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "Zip",
    title: "Node Zip",
    width: "185px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    maxLength: 10,
    canBeSaved: false
  },
  {
    field: "VigilixID",
    title: "Node PC ID",
    width: "185px",
    defaultShow: true,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "Custom1",
    title: "Node Custom 1",
    width: "185px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "Custom2",
    title: "Node Custom 2",
    width: "185px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "Custom3",
    title: "Node Custom 3",
    width: "185px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "Custom4",
    title: "Node Custom 4",
    width: "185px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "Custom5",
    title: "Node Custom 5",
    width: "185px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "DownloadPosters",
    title: "Download Posters",
    width: "200px",
    defaultShow: true,
    filter: "boolean",
    editable: false,
    editor: "boolean",
    required: false,
    canBeSaved: false,
    defaultValue: "N"
  },
  {
    field: "IsActive",
    title: "Node Active",
    width: "185px",
    defaultShow: true,
    filter: "boolean",
    editable: false,
    editor: "boolean",
    required: false,
    canBeSaved: false,
    defaultValue: "Y"
  },
  {
    field: "ScheduleZoneID",
    title: "ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "NodeScheduleID",
    title: "NodeScheduleID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: false,
    defaultValue: "",
    systemHidden: true
  },
  {
    field: "TemplateID",
    title: "TemplateID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    systemHidden: true
  },
  {
    field: "NodeID",
    title: "NodeID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: false,
    defaultValue: "",
    systemHidden: true
  },
  {
    field: "CompanyID",
    title: "CompanyID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    company: true,
    systemHidden: true
  },
  {
    field: "CompanyName",
    title: "Company",
    titlePlural: "Companies",
    width: "180px",
    defaultShow: false,
    filter: "text",
    editable: false,
    editor: "text",
    required: false,
    maxLength: 50,
    canBeSaved: false,
    companyMaster: true
  },
  {
    field: "TemplateZoneID",
    title: "TemplateZoneID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    systemHidden: true
  },
  {
    field: "SeqNum",
    title: "Seq. No.",
    width: "135px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "OffsetMinutes",
    title: "Offset Time",
    width: "120px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: 0
  },
  {
    field: "ContentType",
    title: "Content Type",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    editor: "dropdown",
    required: false,
    canBeSaved: false,
    defaultValue: "M",
    dropItems: [
      {
        value: "M",
        text: "Media"
      },
      {
        value: "P",
        text: "Package"
      }
    ]
  },
  {
    field: "MediaID",
    title: "Media ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "PackageID",
    title: "Package ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "MediaName",
    title: "Media",
    titlePlural: "Media",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    valueField: "MediaID",
    maxLength: 256,
    canBeSaved: false,
    combinedCol: "ZoneContent",
    controlField: "ContentType",
    controlValue: "M",
    lookupFilterCol: "FilterMediaTypeID",
    lookupFilterDataName: "MediaTypeID"
  },
  {
    field: "PackageName",
    title: "Package",
    titlePlural: "Packages",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    valueField: "PackageID",
    maxLength: 50,
    canBeSaved: false,
    combinedCol: "ZoneContent",
    controlField: "ContentType",
    controlValue: "P",
    lookupFilterCol: "FilterPackageTypeID",
    lookupFilterDataName: "PackageTypeID"
  },
  {
    field: "NodeDayPartID",
    title: "NodeDayPartID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    systemHidden: true
  },
  {
    field: "MediaTypeID",
    title: "MediaTypeID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    systemHidden: true
  },
  {
    field: "PackageTypeID",
    title: "PackageTypeID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    systemHidden: true
  },
  {
    field: "AssocStaticContentType",
    title: "Assoc. Content Type",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: "M",
    dropItems: [
      {
        value: "M",
        text: "Media"
      },
      {
        value: "P",
        text: "Package"
      }
    ]
  },
  {
    field: "FilterMediaTypeID",
    title: "FilterMediaTypeID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    systemHidden: true
  },
  {
    field: "FilterPackageTypeID",
    title: "FilterPackageTypeID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    systemHidden: true
  },
  {
    field: "FilterMediaTypeName",
    title: "FilterMediaTypeName",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "NodeDisplayID",
    title: "NodeDisplayID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    systemHidden: true
  },
  {
    field: "NodeTypeID",
    title: "NodeTypeID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    systemHidden: true
  },
  {
    field: "PriceScheduleID",
    title: "PriceScheduleID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    systemHidden: true
  },
  {
    field: "PatchID",
    title: "PatchID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    systemHidden: true
  }
];
