import styled from "styled-components";
import { Box, Grid } from "grommet";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { useGrid } from "../../contexts/grid/useGrid";
import {
  GridActions,
  CarbonIcons,
  Placeholders,
  StoreActions
} from "../../constants";
import React, { useEffect, useLayoutEffect, useState, FC } from "react";
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab
} from "@progress/kendo-react-layout";
import GlobalChangesMediaTab from "./GlobalChangesMediaTab";
import GlobalChangesPackageTab from "./GlobalChangesPackageTab";
import GlobalChangesTemplateTab from "./GlobalChangesTemplateTab";
import GlobalChangesTypeTab from "./GlobalChangesTypeTab";
import { useWindowSize } from "../../hooks/windowSize/useWindowSize";
import { useStore } from "../../contexts/store";
import OctopusLoader from "../../components/Util/OctopusLoader";
import GetLookupDataApi from "../../api/grid/getLookupDataApi";
import { LookupNodeType, Result } from "../../types";
import GlobalChangesFilterDialog from "./GlobalChangesFilterDialog";
import GlobalChangesFilterDropDown from "./GlobalChangesFilterDropdown";
import GlobalChangePublishDialog from "../../components/Util/Dialogs/GlobalChangePublishDialog";
import GlobalChangeRestoreDialog from "../../components/Util/Dialogs/GlobalChangeRestoreDialog";

export enum GlobalChangeType {
  BY_MEDIA = "Media",
  BY_PACKAGE = "Package",
  BY_TEMPLATEZONE = "Template Zone",
  BY_TYPE = "Type"
}

export enum GlobalOperationType {
  NONE = "None",
  REPLACE_CONTENT = "Replace content",
  ADD_CONTENT = "Add content",
  REMOVE_CONTENT = "Remove content"
}

export type GridToolbarActionLabels = {
  singular: string;
  plural: string;
  titleSingular: string;
  titlePlural: string;
};

export interface GlobalChangeAutoSearchParameters {
  type: GlobalChangeType | null;
  id: number | null;
  name: string | null;
}

export interface GlobalChangesToolbarProps {
  gridId: string;
  gridODataEndpoint: string;
  autoSearchParams: GlobalChangeAutoSearchParameters;
  autoSearchComplete: boolean;
  setAutoSearchComplete: React.Dispatch<React.SetStateAction<boolean>>;
}

export type GlobalChangesTabProps = {
  gridId: string;
  autoSearchParams: GlobalChangeAutoSearchParameters;
  selectedCompanyId: number;
  lookup1Data: any[] | null;
  lookup2Data?: any[] | null;
  lookup3Data?: any[] | null;
  lookup4Data?: any[] | null;
  setShowChangeArea: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedId: React.Dispatch<React.SetStateAction<number>>;
  setSelectedSubId: React.Dispatch<React.SetStateAction<number>>;
  setContentType: React.Dispatch<React.SetStateAction<string>>;
  autoSearchComplete: boolean;
  setAutoSearchComplete: React.Dispatch<React.SetStateAction<boolean>>;
  lastResultNodes: LookupNodeType[] | null;
  setLastResultNodes: React.Dispatch<
    React.SetStateAction<LookupNodeType[] | null>
  >;
  setShowPublishConfirmationDialog: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setShowRestoreConfirmationDialog: React.Dispatch<
    React.SetStateAction<boolean>
  >;
};

export interface GlobalChangesLookupItem {
  id: number | null;
  name: string;
}

// Tooltips do not show on disabled buttons so using wrappers below around each button
const GlobalChangesToolbar: FC<GlobalChangesToolbarProps> = (props) => {
  const { grids, setGrid } = useGrid();
  const { windowHeight } = useWindowSize();
  const { store, dispatch } = useStore();
  const { getGridLookupData } = GetLookupDataApi();

  const [isGridLockedOut, setIsGridLockedOut] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState(
    props.autoSearchParams.type === GlobalChangeType.BY_MEDIA
      ? 0
      : props.autoSearchParams.type === GlobalChangeType.BY_PACKAGE
      ? 1
      : props.autoSearchParams.type === GlobalChangeType.BY_TEMPLATEZONE
      ? 2
      : props.autoSearchParams.type === GlobalChangeType.BY_TYPE
      ? 3
      : 0
  );
  const [showChangeArea, setShowChangeArea] = useState<boolean>(false);
  const [selectedChangeType, setSelectedChangeType] =
    useState<GlobalChangeType>(
      props.autoSearchParams.type ?? GlobalChangeType.BY_MEDIA
    );
  const [selectedId, setSelectedId] = useState<number>(-1);
  const [selectedSubId, setSelectedSubId] = useState<number>(-1);
  const [contentType, setContentType] = useState<string>("");

  const [toggleEditModeBtnOn, setToggleEditModeBtnOn] =
    useState<boolean>(false);
  const [toggleQuickFilterBtnOn, setToggleQuickFilterBtnOn] =
    useState<boolean>(false);
  const [showCompanyWarning, setShowCompanyWarning] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [mediaLookupData, setMediaLookupData] = useState<any[] | null>(null);
  const [packageLookupData, setPackageLookupData] = useState<any[] | null>(
    null
  );
  const [templateLookupData, setTemplateLookupData] = useState<any[] | null>(
    null
  );
  const [templateZoneLookupData, setTemplateZoneLookupData] = useState<
    any[] | null
  >(null);
  const [mediaTypeLookupData, setMediaTypeLookupData] = useState<any[] | null>(
    null
  );
  const [packageTypeLookupData, setPackageTypeLookupData] = useState<
    any[] | null
  >(null);
  const [showFilterDialog, setShowFilterDialog] = useState<boolean>(false);
  const [lastResultNodes, setLastResultNodes] = useState<
    LookupNodeType[] | null
  >(null);

  const lastSaved = grids.get(props.gridId)
    ? grids.get(props.gridId)!.state.lastSaveDate
    : null;
  const showSavingIndicator = grids.get(props.gridId)
    ? grids.get(props.gridId)!.state.showSavingIndicator
    : false;
  const [showPublishConfirmationDialog, setShowPublishConfirmationDialog] =
    useState<boolean>(false);
  const [showRestoreConfirmationDialog, setShowRestoreConfirmationDialog] =
    useState<boolean>(false);

  const getLookupData = async (type: GlobalChangeType) => {
    switch (type) {
      case GlobalChangeType.BY_MEDIA:
        await getMediaLookupData();
        break;
      case GlobalChangeType.BY_PACKAGE:
        await getPackageLookupData();
        break;
      case GlobalChangeType.BY_TEMPLATEZONE:
        await getMediaLookupData();
        await getPackageLookupData();
        await getTemplateLookupData();
        break;
      case GlobalChangeType.BY_TYPE:
        await getMediaLookupData();
        await getPackageLookupData();
        await getMediaTypeLookupData();
        await getPackageTypeLookupData();
        break;
      default:
        break;
    }
  };

  const getMediaLookupData = async () => {
    const rawResponse: Result<string> = await getGridLookupData({
      lookupField: "Media",
      endpoint: `/api/${Placeholders.companyID}/lookupmedia/globalchange/-1`
    });
    if (rawResponse.type === "success") {
      const dataResponse: any = rawResponse.value;
      setMediaLookupData(dataResponse.data);
    }
  };

  const getPackageLookupData = async () => {
    const rawResponse: Result<string> = await getGridLookupData({
      lookupField: "Packages",
      endpoint: `/api/${Placeholders.companyID}/lookuppackages/globalchange/-1`
    });
    if (rawResponse.type === "success") {
      const dataResponse: any = rawResponse.value;
      setPackageLookupData(dataResponse.data);
    }
  };

  const getTemplateLookupData = async () => {
    const rawResponse: Result<string> = await getGridLookupData({
      lookupField: "Templates",
      endpoint: `/api/${Placeholders.companyID}/lookuptemplates/globalchange`
    });
    if (rawResponse.type === "success") {
      const dataResponse: any = rawResponse.value;
      setTemplateLookupData(dataResponse.data);
    }
  };

  const getTemplateZoneLookupData = async (templateID: number) => {
    const rawResponse: Result<string> = await getGridLookupData({
      lookupField: "TemplateZones",
      endpoint: `/api/${Placeholders.companyID}/lookuptemplatezones/globalchange/${templateID}`
    });
    if (rawResponse.type === "success") {
      const dataResponse: any = rawResponse.value;
      setTemplateZoneLookupData(dataResponse.data);
    }
  };

  const getMediaTypeLookupData = async () => {
    const rawResponse: Result<string> = await getGridLookupData({
      lookupField: "MediaTypes",
      endpoint: `/api/${Placeholders.companyID}/lookupmediatypes/globalchange`
    });
    if (rawResponse.type === "success") {
      const dataResponse: any = rawResponse.value;
      setMediaTypeLookupData(dataResponse.data);
    }
  };

  const getPackageTypeLookupData = async () => {
    const rawResponse: Result<string> = await getGridLookupData({
      lookupField: "PackageTypes",
      endpoint: `/api/${Placeholders.companyID}/lookuppackagetypes/globalchange`
    });
    if (rawResponse.type === "success") {
      const dataResponse: any = rawResponse.value;
      setPackageTypeLookupData(dataResponse.data);
    }
  };

  const reloadTemplateZoneLookupData = async (templateID: number) => {
    await getTemplateZoneLookupData(templateID);

    // Reset grid endpoint
    setGridEndpointParams(selectedChangeType, selectedId, -1, contentType);
  };

  // Effect runs when advanced filter dialog needs to show
  useEffect(() => {
    setShowFilterDialog(
      grids.get(props.gridId)?.state.showAdvancedFilterDialog || false
    );
  }, [grids.get(props.gridId)?.state.showAdvancedFilterDialog]);

  // Effect runs when the active company changes in the global company dropdown to either show the company warning or not - global changes allowed only with single company selected.
  useEffect(() => {
    // Show change area automatically if we have auto search parameters passed in via query string
    if (
      (showChangeArea && showCompanyWarning) ||
      props.autoSearchParams.type !== null
    ) {
      setShowChangeArea(true);
    } else {
      setShowChangeArea(false);
    }

    if (store.activeCompany !== null && store.activeCompany.companyId === -1) {
      setShowCompanyWarning(true);
    } else {
      setShowCompanyWarning(false);

      // Reload lookups for the new company
      setMediaLookupData(null);
      setPackageLookupData(null);
      setTemplateLookupData(null);
      setTemplateZoneLookupData(null);
      setMediaTypeLookupData(null);
      setPackageTypeLookupData(null);

      // Reset all selected checkboxes in grid
      if (grids.get(props.gridId)) {
        setGrid({
          type: GridActions.toggleSelectAllCheckbox,
          payload: {
            gridId: props.gridId,
            gridData: {
              field: "IsSelected",
              value: false
            }
          }
        });
      }

      // Reset apply/published states
      setLastResultNodes(null);
    }
  }, [store.activeCompany?.companyId]);

  // Effect runs on first load and when lookup data is cleared so we know to reload it. Also removes loader when loading is done.
  //  Effect also runs when new change area is shown/hidden or company is changed.
  useEffect(() => {
    if (!showChangeArea || store.activeCompany?.companyId === -1) {
      setLoading(false);
      return;
    }
    if (
      selectedChangeType === GlobalChangeType.BY_MEDIA &&
      mediaLookupData === null
    ) {
      getLookupData(GlobalChangeType.BY_MEDIA);
    } else if (
      selectedChangeType === GlobalChangeType.BY_PACKAGE &&
      packageLookupData === null
    ) {
      getLookupData(GlobalChangeType.BY_PACKAGE);
    } else if (
      selectedChangeType === GlobalChangeType.BY_TEMPLATEZONE &&
      (templateLookupData === null ||
        mediaLookupData === null ||
        packageLookupData === null)
    ) {
      getLookupData(GlobalChangeType.BY_TEMPLATEZONE);
    } else if (
      selectedChangeType === GlobalChangeType.BY_TYPE &&
      (mediaTypeLookupData === null || packageTypeLookupData === null)
    ) {
      getLookupData(GlobalChangeType.BY_TYPE);
    } else {
      // Already loaded
      setLoading(false);
    }
  }, [
    showChangeArea,
    selectedChangeType,
    store.activeCompany?.companyId,
    mediaLookupData,
    packageLookupData,
    templateLookupData,
    templateZoneLookupData,
    mediaTypeLookupData,
    packageTypeLookupData
  ]);

  // Effect runs when the Edit toggle button is clicked so we can toggle the state of the button.
  useEffect(() => {
    if (grids.get(props.gridId)) {
      setToggleEditModeBtnOn(grids.get(props.gridId)!.state.editMode);
    }
  }, [grids.get(props.gridId)?.state.editMode]);

  // Effect runs when the Quick Filter toggle button is clicked so we can toggle the state of the button.
  useEffect(() => {
    if (grids.get(props.gridId)) {
      setToggleQuickFilterBtnOn(grids.get(props.gridId)!.state.showQuickFilter);
    }
  }, [grids.get(props.gridId)?.state.showQuickFilter]);

  // Effect runs when selected id/sub id, or content type changes so grid endpoint can be reset.
  useEffect(() => {
    if (grids.get(props.gridId)) {
      setGridEndpointParams(
        selectedChangeType,
        selectedId,
        selectedSubId,
        contentType
      );
    }
  }, [selectedSubId, contentType, showChangeArea]);

  // Effect runs when selected id changes so we can load the template zone lookup if making Template Zone global changes - also set new grid endpoint.
  useEffect(() => {
    if (grids.get(props.gridId)) {
      if (selectedChangeType === GlobalChangeType.BY_TEMPLATEZONE) {
        setSelectedSubId(-1);
        reloadTemplateZoneLookupData(selectedId);
      } else {
        setGridEndpointParams(
          selectedChangeType,
          selectedId,
          selectedSubId,
          contentType
        );
      }
    }
  }, [selectedId]);

  // Effect runs when selected change type/tab changes so selected ids and stuff can be reset - and grid endpoint.
  useEffect(() => {
    setSelectedId(-1);
    setSelectedSubId(-1);
    setContentType(selectedChangeType === GlobalChangeType.BY_TYPE ? "M" : "");
    // Reset apply/published states
    setLastResultNodes(null);

    if (grids.get(props.gridId)) {
      setGridEndpointParams(
        selectedChangeType,
        -1,
        -1,
        selectedChangeType === GlobalChangeType.BY_TYPE ? "M" : ""
      );
    }
  }, [selectedChangeType]);

  // Layout Effect runs when grid gets locked out so we can change the disabled state of buttons
  useLayoutEffect(() => {
    if (grids.get(props.gridId)) {
      setIsGridLockedOut(grids.get(props.gridId)!.state.lockoutMode);
    }
  }, [grids.get(props.gridId)?.state.lockoutMode]);

  // Layout effect runs when window is resized or change tab control is shown or hidden so the grid can be resized.
  useLayoutEffect(() => {
    if (document) {
      const gridWrapper = document.querySelector(
        "div.carbon-grid-wrapper"
      ) as HTMLDivElement;
      if (gridWrapper) {
        const newHeight = gridWrapper.offsetHeight;

        // sizing for grid
        setGrid({
          type: GridActions.resizeGrid,
          payload: { gridId: props.gridId, gridData: newHeight }
        });
      }
    }
  }, [windowHeight, showChangeArea]);

  const setGridEndpointParams = (
    newChangeType: GlobalChangeType,
    newId: number,
    newSubId: number,
    newContentType: string
  ) => {
    let newEndpoint = props.gridODataEndpoint;
    newEndpoint = newEndpoint.replaceAll(
      Placeholders.globalChangeType,
      showChangeArea ? encodeURIComponent(newChangeType) : ""
    );
    newEndpoint = newEndpoint.replaceAll(
      Placeholders.globalChangeSelectedId,
      showChangeArea ? `${newId}` : "-1"
    );
    newEndpoint = newEndpoint.replaceAll(
      Placeholders.globalChangeSelectedSubId,
      showChangeArea ? `${newSubId}` : "-1"
    );
    newEndpoint = newEndpoint.replaceAll(
      Placeholders.globalChangeSelectedContentType,
      showChangeArea ? `${newId === -1 ? "" : newContentType}` : ""
    );

    // Reset search params if user is closing New Change area
    if (!showChangeArea) {
      setTabIndex(0);
      setSelectedChangeType(GlobalChangeType.BY_MEDIA);
      setSelectedId(-1);
      setSelectedSubId(-1);
      setContentType("");
      // Reset apply/published states
      setLastResultNodes(null);
    }

    setGrid({
      type: GridActions.setGridEndpoint,
      payload: { gridId: props.gridId, gridData: newEndpoint }
    });
  };

  const onClickRefreshBtn = () => {
    setGrid({
      type: GridActions.toggleRefreshGrid,
      payload: { gridId: props.gridId, gridData: true }
    });
  };

  const onClickEditModeBtn = () => {
    setGrid({
      type: GridActions.toggleEditMode,
      payload: { gridId: props.gridId, gridData: {} }
    });
  };

  const onClickQuickFilterBtn = () => {
    setGrid({
      type: GridActions.toggleQuickFilter,
      payload: { gridId: props.gridId, gridData: {} }
    });
  };

  const saveArea = (
    <Box direction="row-reverse" align="end" gap="small">
      <span title="Refresh grid data from the server and reset sort/filter.">
        <Button onClick={onClickRefreshBtn} disabled={isGridLockedOut}>
          {CarbonIcons.Refresh}
          Reload
        </Button>
      </span>
      {showSavingIndicator ? (
        <span className="saving-indicator">
          Saving
          <Loader size="medium" type="pulsing" />
        </span>
      ) : (
        lastSaved && (
          <span className="last-saved-timestamp">
            Last saved{" "}
            {lastSaved.toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "2-digit",
              second: "2-digit"
            })}
          </span>
        )
      )}
    </Box>
  );

  const onGlobalChangeTypeChanged = (selectedType: GlobalChangeType) => {
    setSelectedChangeType(selectedType);
  };

  const handleTabSelect = (e: TabStripSelectEventArguments) => {
    setTabIndex(e.selected);

    switch (e.selected) {
      case 0:
        onGlobalChangeTypeChanged(GlobalChangeType.BY_MEDIA);
        break;
      case 1:
        onGlobalChangeTypeChanged(GlobalChangeType.BY_PACKAGE);
        break;
      case 2:
        onGlobalChangeTypeChanged(GlobalChangeType.BY_TEMPLATEZONE);
        break;
      case 3:
        onGlobalChangeTypeChanged(GlobalChangeType.BY_TYPE);
        break;
      default:
        console.log(
          `Where did this mystery tab come from? Tab index: ${e.selected}`
        );
        break;
    }
  };

  const handlePublishDialogYes = async () => {
    setShowPublishConfirmationDialog(false);
    setLastResultNodes(null);

    // Show success message
    dispatch({
      type: StoreActions.addNotification,
      payload: {
        message: "Nodes published successfully",
        messageType: "success",
        closable: false
      }
    });
  };

  const handlePublishDialogNo = () => {
    setShowPublishConfirmationDialog(false);
  };

  const handleRestoreDialogYes = async () => {
    setShowRestoreConfirmationDialog(false);
    setLastResultNodes(null);

    // Refresh grid
    setGrid({
      type: GridActions.toggleRefreshGrid,
      payload: { gridId: props.gridId, gridData: true }
    });

    // Show success message
    dispatch({
      type: StoreActions.addNotification,
      payload: {
        message: "Nodes restored successfully",
        messageType: "success",
        closable: false
      }
    });
  };

  const handleRestoreDialogNo = () => {
    setShowRestoreConfirmationDialog(false);
  };

  return (
    <StyledDiv>
      {showFilterDialog && (
        <GlobalChangesFilterDialog setShowDialog={setShowFilterDialog} />
      )}
      {showPublishConfirmationDialog && lastResultNodes && (
        <GlobalChangePublishDialog
          nodes={lastResultNodes!}
          onAcceptCallback={handlePublishDialogYes}
          onRejectCallback={handlePublishDialogNo}
        />
      )}
      {showRestoreConfirmationDialog && lastResultNodes && (
        <GlobalChangeRestoreDialog
          nodes={lastResultNodes!}
          onAcceptCallback={handleRestoreDialogYes}
          onRejectCallback={handleRestoreDialogNo}
        />
      )}
      <Grid
        columns={{ count: 2, size: "auto" }}
        gap="small"
        style={{ marginBottom: "10px" }}
      >
        <Box align="start">
          <Box align="baseline" direction="row" gap="small">
            <h1>{CarbonIcons.GlobalChange} Global Change</h1>
          </Box>
        </Box>
        {saveArea}
      </Grid>
      <Grid columns={{ count: 2, size: "auto" }} gap="small">
        <Box direction="row" align="start" gap="small">
          {showChangeArea ? (
            <span title="Cancel global change">
              <Button
                disabled={isGridLockedOut}
                onClick={() => {
                  setTabIndex(0);
                  setShowChangeArea(false);
                }}
              >
                {CarbonIcons.Close}
                Cancel
              </Button>
            </span>
          ) : (
            <span title="Start a new global change">
              <Button
                disabled={isGridLockedOut}
                onClick={() => {
                  setShowChangeArea(true);
                }}
              >
                {CarbonIcons.Add}
                New Change
              </Button>
            </span>
          )}
          <span title="Enable updates to existing zones">
            <Button
              className={"carbon-link-btn"}
              disabled={isGridLockedOut}
              onClick={() => {
                onClickEditModeBtn();
              }}
            >
              <span className="material-icons" style={{ fontSize: "16px" }}>
                {toggleEditModeBtnOn === true ? "edit" : "lock"}
              </span>
              {toggleEditModeBtnOn === true ? "Edit Mode" : "Grid Locked"}
            </Button>
          </span>
        </Box>
        <Box direction="row-reverse" align="end" gap="small">
          <span title="Toggle search toolbar in grid for quick filtering">
            <Button
              primary={toggleQuickFilterBtnOn}
              disabled={isGridLockedOut}
              onClick={onClickQuickFilterBtn}
            >
              {CarbonIcons.Filter} Quick Filter
            </Button>
          </span>
          <GlobalChangesFilterDropDown disabled={isGridLockedOut} />
        </Box>
      </Grid>
      {showChangeArea &&
        (showCompanyWarning ? (
          <WarningLabel>
            <Box direction="row" gap="xsmall">
              {CarbonIcons.Warning}
              Global changes are not allowed across companies. Make a selection
              from the Company dropdown if you want to make global changes.
              {CarbonIcons.Warning}
            </Box>
          </WarningLabel>
        ) : (
          <div style={{ position: "relative" }}>
            {loading && <OctopusLoader />}
            <Grid
              columns={{ count: 1, size: "auto" }}
              gap="small"
              style={{ marginTop: "10px" }}
            >
              <Box>
                <TabStrip selected={tabIndex} onSelect={handleTabSelect}>
                  <TabStripTab title={"Media"} />
                  <TabStripTab title={"Packages"} />
                  <TabStripTab title={"Templates"} />
                  <TabStripTab title={"Type"} />
                </TabStrip>
                {selectedChangeType === GlobalChangeType.BY_MEDIA && (
                  <GlobalChangesMediaTab
                    gridId={props.gridId}
                    autoSearchParams={props.autoSearchParams}
                    selectedCompanyId={store.activeCompany?.companyId ?? -1}
                    lookup1Data={mediaLookupData}
                    setShowChangeArea={setShowChangeArea}
                    setLoading={setLoading}
                    setSelectedId={setSelectedId}
                    setSelectedSubId={setSelectedSubId}
                    setContentType={setContentType}
                    autoSearchComplete={props.autoSearchComplete}
                    setAutoSearchComplete={props.setAutoSearchComplete}
                    lastResultNodes={lastResultNodes}
                    setLastResultNodes={setLastResultNodes}
                    setShowPublishConfirmationDialog={
                      setShowPublishConfirmationDialog
                    }
                    setShowRestoreConfirmationDialog={
                      setShowRestoreConfirmationDialog
                    }
                  />
                )}
                {selectedChangeType === GlobalChangeType.BY_PACKAGE && (
                  <GlobalChangesPackageTab
                    gridId={props.gridId}
                    autoSearchParams={props.autoSearchParams}
                    selectedCompanyId={store.activeCompany?.companyId ?? -1}
                    lookup1Data={packageLookupData}
                    setShowChangeArea={setShowChangeArea}
                    setLoading={setLoading}
                    setSelectedId={setSelectedId}
                    setSelectedSubId={setSelectedSubId}
                    setContentType={setContentType}
                    autoSearchComplete={props.autoSearchComplete}
                    setAutoSearchComplete={props.setAutoSearchComplete}
                    lastResultNodes={lastResultNodes}
                    setLastResultNodes={setLastResultNodes}
                    setShowPublishConfirmationDialog={
                      setShowPublishConfirmationDialog
                    }
                    setShowRestoreConfirmationDialog={
                      setShowRestoreConfirmationDialog
                    }
                  />
                )}
                {selectedChangeType === GlobalChangeType.BY_TEMPLATEZONE && (
                  <GlobalChangesTemplateTab
                    gridId={props.gridId}
                    autoSearchParams={props.autoSearchParams}
                    selectedCompanyId={store.activeCompany?.companyId ?? -1}
                    lookup1Data={templateLookupData}
                    lookup2Data={templateZoneLookupData}
                    lookup3Data={mediaLookupData}
                    lookup4Data={packageLookupData}
                    setShowChangeArea={setShowChangeArea}
                    setLoading={setLoading}
                    setSelectedId={setSelectedId}
                    setSelectedSubId={setSelectedSubId}
                    setContentType={setContentType}
                    autoSearchComplete={props.autoSearchComplete}
                    setAutoSearchComplete={props.setAutoSearchComplete}
                    lastResultNodes={lastResultNodes}
                    setLastResultNodes={setLastResultNodes}
                    setShowPublishConfirmationDialog={
                      setShowPublishConfirmationDialog
                    }
                    setShowRestoreConfirmationDialog={
                      setShowRestoreConfirmationDialog
                    }
                  />
                )}
                {selectedChangeType === GlobalChangeType.BY_TYPE && (
                  <GlobalChangesTypeTab
                    gridId={props.gridId}
                    autoSearchParams={props.autoSearchParams}
                    selectedCompanyId={store.activeCompany?.companyId ?? -1}
                    lookup1Data={mediaTypeLookupData}
                    lookup2Data={packageTypeLookupData}
                    lookup3Data={mediaLookupData}
                    lookup4Data={packageLookupData}
                    setShowChangeArea={setShowChangeArea}
                    setLoading={setLoading}
                    setSelectedId={setSelectedId}
                    setSelectedSubId={setSelectedSubId}
                    setContentType={setContentType}
                    autoSearchComplete={props.autoSearchComplete}
                    setAutoSearchComplete={props.setAutoSearchComplete}
                    lastResultNodes={lastResultNodes}
                    setLastResultNodes={setLastResultNodes}
                    setShowPublishConfirmationDialog={
                      setShowPublishConfirmationDialog
                    }
                    setShowRestoreConfirmationDialog={
                      setShowRestoreConfirmationDialog
                    }
                  />
                )}
              </Box>
            </Grid>
          </div>
        ))}
    </StyledDiv>
  );
};

const WarningLabel = styled(Box)`
  align-items: center;
  background-color: var(--carbon-yellow);
  margin: 5px 0px;
  padding: 3px 0px;
`;

const StyledDiv = styled.div`
  width: 100%;
  margin-bottom: 25px;

  span.last-saved-timestamp {
    font-size: 12px;
    color: #333333;
    text-align=right;
  }

  span.saving-indicator {
    color: var(--carbon-orange);
    font-weight: bold;
  }

  h1 {
    span[class^="material-icons"] {
      display: inline-flex;
      vertical-align: middle;      
      height: 30px;
      width: 30px;
    }
  }

  h2 {
    font-weight: normal;
    font-size: 15px;
  }

  div.k-form-field {
    display: inline-block;
  }

  div.global-change-tab {
    // will, 2/15/22: added media queries for buttons stacking on small screens making fields unreachable
    @media (max-width: 1024px) {
      display: flex; 
      flex-direction: column;

      div.box-with-right-border {
        border-bottom: 2px solid var(--carbon-lightergray);
      }
    }

    @media (min-width: 1025px) {
      div.box-with-right-border {
        border-right: 2px solid var(--carbon-lightergray);
      }
    }
    
    width: 100%;
    min-height: 162px;
    background-color: var(--carbon-white);
    border: 1px solid var(--carbon-lightergray);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    h2 {
      font-size: 14px;
      font-weight: 600;
      color: #333333;
      margin-bottom: 14px;
    }

    div.k-form-field {
      width: 100%;
    }
  }
`;

export default GlobalChangesToolbar;
