import { CarbonIcons, Placeholders } from "../../constants";
import { GridColumns } from "../../types/grid";
import {
  onAssocStaticContentTypeChange,
  onContentCategoryChange
} from "./MediaLibraryCustomEvents";

export const gridColumns: GridColumns[] = [
  {
    field: "MediaID",
    title: "Media ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: -1,
    isPartOfUniqueKey: true
  },
  {
    field: "ThumbnailImage",
    title: "Thumbnail",
    hideTitle: true,
    width: "105px",
    defaultShow: true,
    filterable: false,
    sortable: false,
    resizable: false,
    editable: false,
    editor: "thumbnail",
    required: false,
    canBeSaved: false
  },
  {
    field: "Ready",
    title: "Status",
    width: "110px",
    defaultShow: true,
    customFilter: "status",
    sortable: false,
    resizable: true,
    editable: false,
    editor: "icon",
    required: false,
    canBeSaved: false,
    defaultValue: "greendot.gif",
    icons: [
      {
        value: "greendot.gif",
        icon: CarbonIcons.MediaConverted
      },
      {
        value: "reddot.gif",
        icon: CarbonIcons.InProgress
      }
    ],
    statuses: [
      {
        value: "greendot.gif",
        color: "",
        text: "Complete"
      },
      {
        value: "reddot.gif",
        color: "",
        text: "Converting"
      }
    ]
  },
  {
    field: "CompanyID",
    title: "CompanyID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    company: true,
    defaultValue: Placeholders.companyID,
    isPartOfUniqueKey: true,
    systemHidden: true
  },
  {
    field: "CompanyName",
    title: "Company",
    titlePlural: "Companies",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    editor: "lookup",
    required: false,
    valueField: "CompanyID",
    maxLength: 50,
    canBeSaved: false,
    companyMaster: true,
    defaultValue: Placeholders.companyName
  },
  {
    field: "MediaName",
    title: "Media",
    width: "600px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    maxLength: 256,
    canBeSaved: true,
    isPartOfUniqueKey: true
  },
  {
    field: "MediaTypeID",
    title: "MediaTypeID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "MediaTypeName",
    title: "Media Type",
    titlePlural: "Media Types",
    width: "260px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    valueField: "MediaTypeID",
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "MediaDesc",
    title: "Description",
    width: "350px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 256,
    canBeSaved: true
  },
  {
    field: "DateUploaded",
    title: "Date Uploaded",
    width: "225px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "FileSize",
    title: "Size (MB)",
    width: "135px",
    defaultShow: true,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "MediaDurationMilliseconds",
    title: "Duration (S)",
    width: "150px",
    defaultShow: true,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "HSize",
    title: "Width",
    width: "120px",
    defaultShow: true,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    minValue: 0
  },
  {
    field: "VSize",
    title: "Height",
    width: "115px",
    defaultShow: true,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    minValue: 0
  },
  {
    field: "FileExt",
    title: "File Ext.",
    width: "150px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "FileName",
    title: "File Name",
    width: "350px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "ContentCategory",
    title: "Category",
    width: "150px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "dropdown",
    required: false,
    canBeSaved: true,
    defaultValue: "S",
    dropItems: [
      {
        value: "S",
        text: "Static"
      },
      {
        value: "A",
        text: "Animated"
      }
    ],
    onChange: onContentCategoryChange
  },
  {
    field: "CampaignID",
    title: "CampaignID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "CampaignName",
    title: "Campaign",
    titlePlural: "Campaigns",
    width: "200px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    valueField: "CampaignID",
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "AssocStaticContentType",
    title: "Assoc. Content Type",
    width: "200px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "dropdown",
    required: false,
    canBeSaved: true,
    defaultValue: "M",
    dropItems: [
      {
        value: "M",
        text: "Media"
      },
      {
        value: "P",
        text: "Package"
      }
    ],
    onChange: onAssocStaticContentTypeChange
  },
  {
    field: "AssocStaticContent1MediaID",
    title: "Assoc. Static Media ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "AssocStaticContent1PackageID",
    title: "Assoc. Static Package ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "AssocStaticContent1",
    title: "Assoc. Static Content",
    width: "350px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "combinedcolumn",
    required: false,
    canBeSaved: false
  },
  {
    field: "MediaName1",
    fieldNameInLookup: "MediaName",
    title: "Media",
    titlePlural: "Media",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    valueField: "AssocStaticContent1MediaID",
    valueFieldInLookup: "MediaID",
    maxLength: 256,
    canBeSaved: false,
    combinedCol: "AssocStaticContent1",
    controlField: "AssocStaticContentType",
    controlValue: "M"
  },
  {
    field: "PackageName1",
    fieldNameInLookup: "PackageName",
    title: "Package",
    titlePlural: "Packages",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    valueField: "AssocStaticContent1PackageID",
    valueFieldInLookup: "PackageID",
    maxLength: 256,
    canBeSaved: false,
    combinedCol: "AssocStaticContent1",
    controlField: "AssocStaticContentType",
    controlValue: "P"
  },
  {
    field: "IsMoviePoster",
    title: "Movie Poster",
    width: "150px",
    defaultShow: false,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true,
    defaultValue: false
  },
  {
    field: "IsShared",
    title: "Shared?",
    width: "150px",
    defaultShow: false,
    filter: "boolean",
    editable: false,
    editor: "boolean",
    required: false,
    canBeSaved: false,
    defaultValue: false
  },
  {
    field: "MediaProperties",
    title: "Properties",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    editor: "note",
    required: false,
    canBeSaved: false
  },
  {
    field: "IsActive",
    title: "Active",
    width: "150px",
    defaultShow: false,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true,
    defaultValue: true
  }
];
