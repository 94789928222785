// Base
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import config from "../config";

// Grommet/Kendo Components
import { Box, Image } from "grommet";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";

// Custom Components
import { FormInput } from "../components/Forms/Login";
import ForgotPassword from "../components/Util/ForgotPassword";
import MaintenanceMessage from "../components/Util/MaintenanceMessage";

// Constants/Contexts
import { LoginContract } from "../types";
import { AppLocalStore, CarbonIcons } from "../constants";
import { useStore } from "../contexts/store";

// Hooks
import useLogin from "../hooks/auth/useLogin";
import useWebAppStartup from "../hooks/webAppStartup/useWebAppStartup";

const Login = () => {
  const { store } = useStore();
  const {
    getStartupDetails,
    isLoading: startupDetailsLoading,
    startupResult
  } = useWebAppStartup();
  const { userLogin, isLoading: userLoginLoading, loginResult } = useLogin();
  const [renderForgotPassword, setRenderForgotPassword] = useState(false);
  const [preventLogin, setPreventLogin] = useState(false);
  const [loginErrorMsg, setLoginErrorMsg] = useState(
    "Unknown Error: Please contact your administrator"
  );
  const [loginMessage, setLoginMessage] = useState("");
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [startUpLoading, setStartUpLoading] = useState<boolean>(true);

  const currentYear = new Date().getFullYear();

  // TODO: useApi does not return "true" as default initialState for "loading":
  //  As a result the login form flickers while loading. The following useEffect prevents this
  //  flickering by waiting for useApi to complete loading.
  //  The flickering is caused by useApi returning "false" for the initial loading state when
  //  calling "startupDetailsLoading" then immediately sets the state for "loading" to "true"
  //  then back to "false" when this API call has completed.
  //  Changing the initialState of useApi "loading" state to "true" causes negative cascading
  //  effects throughout the rest of the system and will require a bit more investigation and
  //  testing before that change should be made. Once this is resolved, the following useEffect can
  //  be removed.
  useEffect(() => {
    const loadTimer = setTimeout(() => {
      !startupDetailsLoading() && setStartUpLoading(false);
    }, 1000);
    return () => clearTimeout(loadTimer);
  }, []);

  useEffect(() => {
    // jon, 3/8/22: The login message is for Session Expired and is set in useApi when a 401/403 error is returned and user is redirected here.
    if (localStorage.getItem(AppLocalStore.LoginScreenMessage) !== null) {
      setLoginMessage(
        localStorage.getItem(AppLocalStore.LoginScreenMessage) ?? ""
      );
    }

    // get startup details
    getStartupDetails();
    // clean up function
    return () => {
      setRenderForgotPassword(false);
      localStorage.removeItem(AppLocalStore.LoginScreenMessage);
    };
  }, []);

  useEffect(() => {
    // verify if login is allowed
    if (store.startupDetails) {
      setPreventLogin(store.startupDetails.preventLogin);
    }
  }, [store.startupDetails]);

  useEffect(() => {
    // const error = JSON.parse(JSON.stringify(loginResult)).error.message;
    const error: any = loginResult;

    if (error.error) {
      if (error.error.message) {
        if (error.error.message.error_description) {
          setLoginErrorMsg(error.error.message.error_description);
        } else {
          setLoginErrorMsg(error.error.message);
        }
      }
    }
  }, [loginResult]);

  const togglePasswordVisible = () => {
    setPasswordVisible(!passwordVisible);
    const x = document.getElementById("password") as HTMLInputElement;
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  // do not stringify for api login post (api endpoint is expecting form data to parse, NOT raw)
  // cant find typings for Kendo react form values. Formik is a forms library that is better associated with typescript -Ali
  const handleSubmit = (loginFormValues: any) => {
    // jon, 3/9/22: Remove Remember Me checkbox per Deel
    // if (loginFormValues?.remembermeBox === true) {
    //   localStorage.setItem(AppLocalStore.CarbonRememberMe, "true");
    // }
    const loginContract: LoginContract = {
      userName: loginFormValues.username,
      companyCode: loginFormValues.company,
      password: loginFormValues.password,
      rememberMe: false // loginFormValues.remembermeBox
    };

    userLogin(loginContract);
  };

  return (
    <>
      <Helmet>
        <title>Carbon | Login</title>
      </Helmet>

      {startUpLoading ? (
        <LoadingWrapper>
          <Loader size="large" type="converging-spinner" />
          <LoadingLogo src="/static/images/carbon-logo.svg" />
        </LoadingWrapper>
      ) : (
        <LoginWindow>
          <MaintenanceMessage
            message={
              store.startupDetails && store.startupDetails.maintenanceMessage
            }
          />
          <LoginWrapper>
            <FormWrapper>
              {!preventLogin && startupResult.type === "success" && (
                <>
                  {renderForgotPassword ? (
                    <ForgotPassword
                      setRenderForgotPassword={() =>
                        setRenderForgotPassword(false)
                      }
                    />
                  ) : (
                    <Form
                      onSubmit={handleSubmit}
                      render={(formRenderProps) => (
                        <FormElement>
                          <LogoImg
                            fill="horizontal"
                            fit="contain"
                            src="/static/images/carbon-logo.svg"
                          />
                          <Box
                            margin={{ top: "xsmall" }}
                            // pad={{ top: "large" }}
                            style={{
                              fontSize: 26,
                              marginTop: "2rem"
                            }}
                          >
                            Login
                          </Box>
                          {loginMessage && (
                            <LoginMessage>
                              <AnnouncementIcon
                                style={{
                                  width: 18,
                                  height: 18,
                                  color: "var(--carbon-orange)",
                                  marginRight: "4px",
                                  marginTop: "3px"
                                }}
                              />
                              {loginMessage}
                            </LoginMessage>
                          )}

                          {loginResult.type === "error" && (
                            <div className={"k-messagebox k-messagebox-error"}>
                              {loginErrorMsg}
                            </div>
                          )}

                          <Field
                            width="xsmall"
                            icon={
                              <StyledIcon>{CarbonIcons.CompanyCode}</StyledIcon>
                            }
                            id={"company"}
                            name={"company"}
                            label={"Company Code"}
                            maxLength={4}
                            required={true}
                            autoFocus
                            style={{ textTransform: "uppercase" }}
                            component={FormInput}
                          />
                          <Field
                            icon={<StyledIcon>{CarbonIcons.Mail}</StyledIcon>}
                            id={"username"}
                            name={"username"}
                            label={"Username"}
                            maxLength={50}
                            required={true}
                            component={FormInput}
                          />
                          <Field
                            sx={{
                              display: "flex",
                              alignItems: "center"
                            }}
                            width="100%"
                            icon={<StyledIcon>{CarbonIcons.Lock}</StyledIcon>}
                            passwordIcon={
                              <StyledIcon onClick={togglePasswordVisible}>
                                {passwordVisible
                                  ? CarbonIcons.VisibilityOff
                                  : CarbonIcons.Visibility}
                              </StyledIcon>
                            }
                            id={"password"}
                            name={"password"}
                            label={"Password"}
                            maxLength={50}
                            fieldType="password"
                            required={true}
                            component={FormInput}
                          />
                          <Box direction="row" justify="end">
                            {/* jon, 3/9/22: Remove Remember Me checkbox per Deel -
                              <Field
                                id={"remembermeBox"}
                                name={"remembermeBox"}
                                label={"Remember Me"}
                                style={{ margin: 0 }}
                                required={false}
                                component={FormCheckbox}
                              />
                            */}
                            <StyledForgotPassword
                              focusIndicator={false}
                              onClick={() => {
                                setRenderForgotPassword(true);
                              }}
                            >
                              Forgot Password?
                            </StyledForgotPassword>
                          </Box>
                          <Box align="center" pad={{ top: "medium" }}>
                            {userLoginLoading() ? (
                              <Loader size="small" type="converging-spinner" />
                            ) : (
                              <LoginButton
                                type={"submit"}
                                disabled={!formRenderProps.allowSubmit}
                              >
                                Login
                              </LoginButton>
                            )}
                          </Box>
                          <Box align="center">
                            <Box
                              pad={{ bottom: "small", top: "medium" }}
                              direction="row"
                            >
                              <Box>Want to see Carbon in action?</Box>
                              <Box
                                pad={{ left: "xsmall" }}
                                focusIndicator={false}
                                style={{
                                  color: "var(--carbon-orange)",
                                  fontWeight: "bold"
                                }}
                              >
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href="https://www.deelmedia.com/contact-us"
                                >
                                  Contact Us
                                </a>
                              </Box>
                            </Box>
                            <Box>{`Version: UI ${config.appVersion} | Core ${config.carbonVersion}`}</Box>
                            <StyledCopyright>
                              {`Copyright © 2009-${currentYear} DEEL Media, Inc. All rights reserved.`}
                            </StyledCopyright>
                            <StyledLegalFooter>
                              Use of DEEL Media’s Carbon™ software signals that
                              you agree to the terms and conditions detailed in
                              the DEEL Media Carbon End User License Agreement
                              which can be found at{" "}
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://www.deelmedia.com/eula"
                              >
                                https://www.deelmedia.com/eula
                              </a>
                            </StyledLegalFooter>
                          </Box>
                        </FormElement>
                      )}
                      // form closing below
                    />
                  )}
                </>
              )}
            </FormWrapper>
            <LoginSplashImage>
              <Image src="/static/images/carbon-login-v2.png" />
            </LoginSplashImage>
          </LoginWrapper>
        </LoginWindow>
      )}
    </>
  );
};

const LoadingWrapper = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoadingLogo = styled(Image)`
  max-width: 100%;
  margin-top: 24px;
`;

const LoginWindow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

const LoginWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  @media (min-width: 1024px) {
    max-width: 1009px;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    margin: 24px 50px;
    box-shadow: rgb(0 0 0 / 20%) 0 4px 8px;
  }
`;

const FormWrapper = styled(Box)`
  display: flex;
  padding: 24px;
  max-width: 400px;
  width: 100%;
  @media (min-width: 1024px) {
    max-width: none;
    flex-basis: 50%;
    padding: 48px 80px 24px 80px;
  }
`;

const LoginSplashImage = styled(Box)`
  display: none;
  @media (min-width: 1024px) {
    display: flex;
    flex-basis: 50%;
    max-width: 506px;
  }
`;

const LogoImg = styled(Image)`
  max-width: 70%;
  text-align: center;
`;

const LoginButton = styled(Button)`
  background-color: var(--carbon-orange);
  width: 100%;
  border-radius: 30px;
  color: var(--carbon-white);
`;

const StyledIcon = styled(Box)`
  box-shadow: none;
  span[class^="material-icons"] {
    color: var(--carbon-orange);
    font-size: x-large;
  }
`;

const StyledForgotPassword = styled(Box)`
  color: var(--carbon-orange);
  font-weight: 700;
  :hover {
    color: var(--carbon-darkOrange);
  }
`;

const StyledCopyright = styled(Box)`
  text-align: center;
`;

const StyledLegalFooter = styled(Box)`
  display: block;
  color: var(--carbon-darkgray);
  text-align: center;
  font-size: 12px;
  margin-top: 22px;
  line-height: normal;
  a {
    color: var(--carbon-darkgray);
    :hover {
      color: var(--carbon-orange);
    }
  }
`;

const LoginMessage = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 16px;
  color: var(--carbon-orange);
  font-weight: bold;
  background: rgba(239, 94, 37, 0.01);
  border: 1px solid rgba(239, 94, 37, 0.5);
  padding: 4px 12px;
  justify-content: center;
`;

export default Login;
