import React, { useEffect, useState } from "react";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Label } from "@progress/kendo-react-labels";
import styled from "styled-components";
// import dayjs from "dayjs";
import {
  DatePicker,
  DatePickerChangeEvent,
  DateTimePicker,
  DateTimePickerChangeEvent
} from "@progress/kendo-react-dateinputs";

import { Popup, PopupProps } from "@progress/kendo-react-popup";

export interface IDateFieldProps {
  id: string;
  label: string;
  labelWidth?: string;
  fieldWidth?: string;
  defaultValue: Date | null;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  datetime?: boolean;
  isPublishDialog?: boolean;
  onChange: (value: Date | null) => void;
}

export const DateField = (props: IDateFieldProps) => {
  const dateVal = props.defaultValue;
  // props.defaultValue && props.defaultValue !== null
  //   ? dayjs(props.defaultValue).toDate()
  //   : null;

  const [selectedValue, setSelectedValue] = useState<Date | null>(dateVal);
  const labelWidth = props.labelWidth ?? "100px";
  const fieldWidth = props.fieldWidth ?? "110px";
  const disabled = props.disabled ?? false;
  const required = props.required ?? false;
  const placeholder = props.placeholder ?? "MM/DD/YY";

  // Effect allows default value to be changed by calling page so the value here can be updated.
  useEffect(() => {
    if (selectedValue !== props.defaultValue) {
      setSelectedValue(props.defaultValue);
    }
  }, [props.defaultValue]);

  const onChange = async (newValue: Date | null) => {
    // Update selected value
    setSelectedValue(newValue);
    props.onChange(newValue);
  };

  const customFormatPlaceholder = {
    year: "YY",
    month: "MM",
    day: "DD",
    hour: "hh",
    minute: "mm",
    second: "ss"
  };

  const label = required ? (
    <span style={{ fontWeight: "bold" }}>
      <span
        style={{
          color: "var(--carbon-orange)",
          display: "inline-block",
          marginLeft: "-12px"
        }}
      >
        *&nbsp;
      </span>
      <Label
        style={{ width: labelWidth, fontWeight: "bold" }}
        editorId={props.id}
      >
        {props.label}
      </Label>
    </span>
  ) : (
    <Label
      style={{ width: labelWidth, fontWeight: "bold" }}
      editorId={props.id}
    >
      {props.label}
    </Label>
  );

  // will, 3/15/22: Added in custom popup for positioning publish dialog component. 'Cancel' and 'Set' buttons unreachable if too short.
  const customPopup = (popupProps: PopupProps) => {
    return (
      <Popup
        {...popupProps}
        anchorAlign={{
          horizontal: props.isPublishDialog ? "right" : "left",
          vertical: props.isPublishDialog ? "center" : "bottom"
        }}
        popupAlign={{
          horizontal: "left",
          vertical: props.isPublishDialog ? "center" : "top"
        }}
      />
    );
  };

  const elem = (
    <StyledFieldWrapper>
      {label}
      {props.datetime ? (
        <DateTimePicker
          disabled={disabled}
          onChange={(event: DateTimePickerChangeEvent) => {
            onChange(event.value);
          }}
          value={selectedValue}
          formatPlaceholder={customFormatPlaceholder}
          placeholder={placeholder}
          width={fieldWidth}
          popup={customPopup}
        />
      ) : (
        <DatePicker
          disabled={disabled}
          onChange={(event: DatePickerChangeEvent) => {
            onChange(event.value);
          }}
          value={selectedValue}
          formatPlaceholder={customFormatPlaceholder}
          placeholder={placeholder}
          width={fieldWidth}
        />
      )}
    </StyledFieldWrapper>
  );

  return React.cloneElement(elem);
};

const StyledFieldWrapper = styled(FieldWrapper)`
  margin-bottom: 8px;

  label {
    color: var(--carbon-darkgray);
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-right: 10px;
    display: inline-block;
  }

  div.k-datetimepicker {
    background-color: var(--carbon-white);
    margin-top: -3px;
    min-width: 160px;
    background: var(--carbon-white);
    border: 1px solid var(--carbon-lightgray);
    box-sizing: border-box;
    border-radius: 6px;
    height: 28px;

    > div.k-picker-wrap,
    span.k-picker-wrap {
      border-width: 0;
      padding-left: 0;

      input {
        font-size: 16px;
        margin-top: -4px;
        margin-left: 2px;
      }
    }
  }

  span.k-datepicker {
    background-color: var(--carbon-white);
    margin-top: -3px;
    min-width: 110px;
    background: var(--carbon-white);
    border: 1px solid var(--carbon-lightgray);
    box-sizing: border-box;
    border-radius: 6px;
    height: 28px;

    > div.k-picker-wrap,
    span.k-picker-wrap {
      border-width: 0;
      padding-left: 0;

      input {
        font-size: 16px;
        margin-top: -4px;
        margin-left: 2px;
      }
    }
  }
`;
