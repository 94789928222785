import { Helmet } from "react-helmet-async";
import { useLocation, useParams } from "react-router-dom";
import { Box } from "grommet";
import { useState } from "react";

// Components
import CarbonGridToolbar, {
  GridToolbarActionLabels,
  GridToolbarItem
} from "../../components/CarbonGrid/Toolbar/CarbonGridToolbar";
import CarbonTabStrip from "../../components/CarbonTabs/CarbonTabStrip";
import CarbonGrid from "../../components/CarbonGrid/CarbonGrid";
import Authorizer from "../../components/Util/Authorizer";
import ConfirmationDialog from "../../components/Util/Dialogs/ConfirmationDialog";

// Types & Constants
import { GridType } from "../../types/grid";
import getTabs from "./NodesTabs";
import { gridColumns } from "./NodePatchHistoryGridColumns";
import { Result } from "../../types";

// User/Permissions
import usePermissions from "../../hooks/auth/usePermissions";
import {
  EndpointResources,
  Placeholders,
  UserPermissions,
  GridIDs,
  CarbonIcons,
  StoreActions,
  ResultTypes
} from "../../constants";

// Hooks
import useNodePatchResend from "../../hooks/nodes/useNodePatchResend";
import { useStore } from "../../contexts/store";
import { useGrid } from "../../contexts/grid/useGrid";
import CarbonBreadcrumb from "../../components/Breadcrumb/CarbonBreadcrumb";

const NodePatchHistory = (): JSX.Element => {
  const { pathname } = useLocation();
  const { nodeId } = useParams();
  const { canView } = usePermissions();
  const { store, dispatch } = useStore();
  const gridName = GridIDs.NodePatchHistory;
  const tabs = getTabs(EndpointResources.Nodes, nodeId!);
  const pagePermission = UserPermissions.NodesPerms;
  const { postNodePatchResend } = useNodePatchResend();
  const { grids } = useGrid();
  const nodesGrid = grids.get(GridIDs.Nodes);
  let nodesTitle = "Nodes";

  if (nodesGrid) {
    if (store.activeNodeGroup?.nodeGroupName) {
      nodesTitle = store.activeNodeGroup?.nodeGroupName;
    }
  }

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const newGridColumns = [
    {
      field: "NodeID",
      title: "Node ID",
      width: "150px",
      defaultShow: false,
      filter: "numeric",
      editable: false,
      required: false,
      canBeSaved: true,
      defaultValue: nodeId,
      systemHidden: true
    },
    ...gridColumns
  ];

  const currentTab = {
    index: tabs.findIndex((tab) => tab.resourceEndpoint === pathname),
    tab: tabs.find((tab) => tab.resourceEndpoint === pathname)
  };

  const actionLabels: GridToolbarActionLabels = {
    singular: "node patch history",
    plural: "node patch histories",
    titleSingular: "Node Patch History",
    titlePlural: "Node Patch Histories"
  };

  const gridSettings: GridType = {
    endpoints: {
      gridODataEndpoint: `/odata/${Placeholders.companyID}/${Placeholders.nodeTabNodeId}/gridnodepatchhistories?$count=true&`,
      parentGridApiEndpoint: `/api/${Placeholders.companyID}/${EndpointResources.Nodes}/${Placeholders.nodeTabNodeId}`
    },
    records: [],
    total: 0,
    data: [],
    lookups: {},
    dataState: {
      pageSize: 25,
      take: 50,
      skip: 0,
      sort: [{ field: "DateInstalled", dir: "desc" }]
    },
    dataItemKey: "NodePatchHistoryID",
    singularEntityName: actionLabels.singular,
    pluralEntityName: actionLabels.plural,
    columns: newGridColumns,
    showCopyBtn: false,
    showDeleteBtn: false,
    parentGridId: GridIDs.Nodes,
    state: {
      editMode: false,
      insertMode: false,
      selectedState: {},
      selectedRow: -1,
      showQuickFilter: false,
      lastSaveDate: null,
      showSavingIndicator: false,
      lockoutMode: false
    }
  };

  const cancelBtn: GridToolbarItem = {
    show: false,
    enable: false,
    tooltip: ""
  };

  const saveBtn: GridToolbarItem = {
    show: false,
    enable: false,
    tooltip: ""
  };

  const editModeBtn: GridToolbarItem = {
    show: false,
    enable: false,
    tooltip: ""
  };

  const previewBtn: GridToolbarItem = {
    show: true,
    enable: true,
    tooltip: "Open the viewer to preview node schedule",
    toggledOn: false
  };

  // Add button will be repurposed to "Resend Latest Patch"
  const bodyTextMessage =
    "This will resend the latest player patch to the node. " +
    "This should only be done if the previous patch attempt failed. " +
    "Are you sure you want to continue?";

  const confirmMessage =
    "The patch has been scheduled for resending. " +
    "Once installed, a new patch history record will be added to the " +
    "grid below (after refresh).";

  const handleNodePatchResendAccept = async () => {
    console.log(`NODE PATCH RESEND - Node: ${nodeId}`);
    const result: Result<string> = await postNodePatchResend({
      api: `/api/-1/nodepatchhistories`,
      nodeId: nodeId!
    });

    setShowConfirmationDialog(false);

    if (result.type === ResultTypes.Success) {
      dispatch({
        type: StoreActions.addNotification,
        payload: {
          message: confirmMessage,
          messageType: "success",
          closable: false
        }
      });
    }
  };

  const handleNodePatchResendReject = () => {
    setShowConfirmationDialog(false);
  };

  const addBtn: GridToolbarItem = {
    show: true,
    enable: canView(pagePermission),
    tooltip: canView(pagePermission)
      ? "Resend latest patch."
      : "You do not have permission to resend latest patch.",
    labelOverride: "Resend Latest Patch",
    iconOverride: CarbonIcons.Resend,
    onClick: () => {
      setShowConfirmationDialog(true);
    }
  };

  return (
    <>
      <Helmet>
        <title>Carbon | Node Patch History</title>
      </Helmet>
      <Authorizer
        canView={pagePermission}
        auditLabel={`${actionLabels.titleSingular} screen`}
      >
        {showConfirmationDialog && (
          <ConfirmationDialog
            bodyText={bodyTextMessage}
            onAcceptCallback={handleNodePatchResendAccept}
            onRejectCallback={handleNodePatchResendReject}
          />
        )}
        <Box align="start" fill>
          <CarbonBreadcrumb
            backLinkTitle={nodesTitle}
            backLinkUrl="/app/nodes"
            pageTitle={currentTab.tab?.tabTitle ?? ""}
          />
          <CarbonGridToolbar
            gridId={gridName}
            screenIcon={CarbonIcons.Patches}
            screenTitle={""}
            screenSubtitle={""}
            permissions={pagePermission}
            actionLabels={actionLabels}
            getScreenTitleFromParentRow={(rowData: { [key: string]: any }) => {
              return rowData.NodeName;
            }}
            getScreenSubtitleFromParentRow={(rowData: {
              [key: string]: any;
            }) => {
              return rowData.CompanyName;
            }}
            cancelBtn={cancelBtn}
            saveBtn={saveBtn}
            addBtn={addBtn}
            editModeBtn={editModeBtn}
            publishBtn={true}
            previewBtn={previewBtn}
          />
          <CarbonTabStrip carbonTabs={tabs} selectedTab={currentTab.index} />
          <Box fill className="carbon-grid-wrapper">
            <CarbonGrid gridId={gridName} gridSettings={gridSettings} />
          </Box>
        </Box>
      </Authorizer>
    </>
  );
};

export default NodePatchHistory;
