import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  {
    field: "RolePermissionID",
    title: "Role Permission ID",
    width: "160px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: -1
  },
  {
    field: "RoleID",
    title: "Role ID",
    width: "100px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "PermOrder",
    title: "Permission Order",
    width: "180px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: true
  },
  {
    field: "PermType",
    title: "Permission Type",
    width: "150px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: true
  },
  {
    field: "PermissionName",
    title: "Permission Name",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: true
  },
  {
    field: "PermissionDesc",
    title: "Permission",
    width: "500px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "IsGranted",
    title: "View/Grant",
    width: "150px",
    defaultShow: true,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true
  },
  {
    field: "CanInsert",
    title: "Insert",
    width: "125px",
    defaultShow: true,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true
  },
  {
    field: "CanUpdate",
    title: "Update",
    width: "125px",
    defaultShow: true,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true
  },
  {
    field: "CanDelete",
    title: "Delete",
    width: "125px",
    defaultShow: true,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true
  }
];
