import { Box, Grid } from "grommet";
import { FC, useEffect, useState } from "react";
import { LookupField } from "../../components/Fields/LookupField";
import {
  GlobalChangesLookupItem,
  GlobalChangesTabProps,
  GlobalChangeType,
  GlobalOperationType
} from "./GlobalChangesToolbar";
import {
  RadioGroup,
  RadioGroupChangeEvent
} from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { DateField } from "../../components/Fields/DateField";
import { CarbonIcons, GridActions, StoreActions } from "../../constants";
import { useGrid } from "../../contexts/grid/useGrid";
import { useStore } from "../../contexts/store";
import useGlobalChangesApi from "../../hooks/globalChanges/useGlobalChangesApi";
import {
  GlobalChangesApplyChangesByTypeContract,
  GlobalChangesGetAffectedCountResultContract,
  GlobalChangesGridFilterContract
} from "../../types";
import GlobalChangeApplyConfirmationDialog from "../../components/Util/Dialogs/GlobalChangeApplyConfirmationDialog";
import DropDownField from "../../components/Fields/DropDownField";

const GlobalChangesTypeTab: FC<GlobalChangesTabProps> = (props) => {
  const LABEL_WIDTH: string = "100px";
  const LOOKUP_FIELD_WIDTH: string = "70%";
  const BUTTON_WIDTH: string = "152px";

  const { grids, setGrid } = useGrid();
  const { dispatch } = useStore();
  const { getGlobalChangesAffectedCount, applyChangesByType } =
    useGlobalChangesApi();
  const [criteriaBox1, setCriteriaBox1] = useState<GlobalChangesLookupItem>({
    id: null,
    name: ""
  });
  const [criteriaBox2, setCriteriaBox2] = useState<GlobalChangesLookupItem>({
    id: null,
    name: ""
  });
  const [contentType, setContentType] = useState<string>("M");
  const [contentCategory, setContentCategory] = useState<string>("");

  const [selectedRecords, setSelectedRecords] = useState<number>(0);
  const [operation, setOperation] = useState<GlobalOperationType>(
    GlobalOperationType.REPLACE_CONTENT
  );
  const [detailsNewValue, setDetailsNewValue] = useState<number | null>(null);
  const [detailsStartDate, setDetailsStartDate] = useState<Date | null>(null);
  const [detailsEndDate, setDetailsEndDate] = useState<Date | null>(null);
  const [detailsMediaLookupData, setDetailsMediaLookupData] = useState<any[]>(
    []
  );
  const [detailsPackagesLookupData, setDetailsPackagesLookupData] = useState<
    any[]
  >([]);

  const [lastApplyResult, setLastApplyResult] =
    useState<GlobalChangesGetAffectedCountResultContract | null>(null);
  const [
    showApplyChangesConfirmationDialog,
    setShowApplyChangesConfirmationDialog
  ] = useState<boolean>(false);

  useEffect(() => {
    if (grids.get(props.gridId)) {
      const selectAll: boolean =
        grids.get(props.gridId)!.state.selectAllChecked ?? false;
      const pklist: number[] =
        grids.get(props.gridId)!.state.selectedPKList ?? [];

      let selectedCount = pklist.length;
      if (selectAll) {
        // When select all is checked, the total count is all records minus the ones the user has unselected. In this case, the pklist contains unselected records.
        selectedCount = grids.get(props.gridId)!.records.length - pklist.length;
      }
      setSelectedRecords(selectedCount);
    }
  }, [grids.get(props.gridId)?.state.selectedPKList]);

  const mediaLookupData: any[] =
    !props.lookup3Data || props.lookup3Data === null ? [] : props.lookup3Data;
  const packageLookupData: any[] =
    !props.lookup4Data || props.lookup4Data === null ? [] : props.lookup4Data;

  const handleCriteria1LookupChange = (value: number | null, text: string) => {
    console.log(`Media Type Lookup Selection - value: ${value}, text: ${text}`);
    setCriteriaBox1({ id: value, name: text });
    props.setSelectedId(value === null ? -1 : value);
    setCriteriaBox2({ id: null, name: "" });

    if (value === null) {
      clearAllDetails();
      return;
    }

    setDetailsMediaLookupData(
      mediaLookupData.filter((row) => row.MediaTypeID === value)
    );
  };

  const handleCriteria2LookupChange = (value: number | null, text: string) => {
    console.log(
      `Package Type Lookup Selection - value: ${value}, text: ${text}`
    );
    setCriteriaBox2({ id: value, name: text });
    props.setSelectedId(value === null ? -1 : value);
    setCriteriaBox1({ id: null, name: "" });

    if (value === null) {
      clearAllDetails();
      return;
    }

    setDetailsPackagesLookupData(
      packageLookupData.filter((row) => row.PackageTypeID === value)
    );
  };

  const clearAllDetails = () => {
    setOperation(GlobalOperationType.REPLACE_CONTENT);
    setDetailsNewValue(null);
    setDetailsStartDate(null);
    setDetailsEndDate(null);
    setDetailsMediaLookupData([]);
    setDetailsPackagesLookupData([]);
  };

  const onOperationChanged = (selectedOperation: GlobalOperationType) => {
    setOperation(selectedOperation);
    setDetailsNewValue(null);
    setDetailsStartDate(null);
    setDetailsEndDate(null);
  };

  const handleDetailsNewValueLookupChange = (
    value: number | null,
    text: string
  ) => {
    console.log(`Details new value: ${value}, text: ${text}`);
    setDetailsNewValue(value);

    // Get content category from the lookup data for either media or package, by content type
    let category: string | null = "";
    if (contentType === "M") {
      category = mediaLookupData.find(
        (row) => row.MediaID === value
      )?.ContentCategory;
    } else {
      // Package
      category = packageLookupData.find(
        (row) => row.PackageID === value
      )?.ContentCategory;
    }
    setContentCategory(category ?? "");
  };
  const handleDetailsStartDateChange = (value: Date | null) => {
    setDetailsStartDate(value);
  };

  const handleDetailsEndDateChange = (value: Date | null) => {
    setDetailsEndDate(value);
  };

  const onApplyChangesClick = async () => {
    // Confirm start date <= end date if this is an Add operation. Only need to check if both dates have a value.
    if (
      operation === GlobalOperationType.ADD_CONTENT &&
      detailsStartDate !== null &&
      detailsEndDate !== null &&
      detailsEndDate < detailsStartDate
    ) {
      dispatch({
        type: StoreActions.addNotification,
        payload: {
          message: "Start Date must be less than or equal to End Date.",
          messageType: "warning",
          closable: true
        }
      });
      return;
    }

    props.setLoading(true);
    const grid = grids.get(props.gridId)!;
    const payload: GlobalChangesGridFilterContract = {
      userFilterId: grid.state.activeFilterId ?? null,
      gridFilter: JSON.stringify(grid.dataState.filter),
      operation: operation.toString(),
      pkList: grid.state.selectedPKList?.toString() ?? "",
      selectAll: grid.state.selectAllChecked ?? false,
      changeType: GlobalChangeType.BY_TYPE,
      selectedId:
        contentType === "M" ? criteriaBox1.id ?? -1 : criteriaBox2.id ?? -1,
      selectedSubId: -1,
      selectedContentType: contentType
    };

    const result = await getGlobalChangesAffectedCount(payload);
    props.setLoading(false);
    if (result.type === "success") {
      const responseResult = result.value;
      setLastApplyResult(responseResult.data);
      setShowApplyChangesConfirmationDialog(true);
    }
  };

  const handleApplyChangesConfirmationYes = async () => {
    setShowApplyChangesConfirmationDialog(false);

    props.setLoading(true);
    const grid = grids.get(props.gridId)!;
    // For global changes by type and ADD operation only, a list of affected node schedule ids are passed back from the get affected count call above.
    //  This list is used in addition to the selected schedule zone records (the pkList) in the payload below as nodeScheduleIDs.
    const payload: GlobalChangesApplyChangesByTypeContract = {
      filter: {
        userFilterId: grid.state.activeFilterId ?? null,
        gridFilter: JSON.stringify(grid.dataState.filter),
        operation: operation.toString(),
        pkList: grid.state.selectedPKList?.toString() ?? "",
        selectAll: grid.state.selectAllChecked ?? false,
        changeType: GlobalChangeType.BY_TYPE,
        selectedId:
          contentType === "M" ? criteriaBox1.id ?? -1 : criteriaBox2.id ?? -1,
        selectedSubId: -1,
        selectedContentType: contentType
      },
      nodeScheduleIDs: lastApplyResult?.nodeScheduleIDs?.toString() ?? "",
      startDate: detailsStartDate,
      endDate: detailsEndDate,
      oldID: criteriaBox2.id ?? -1,
      newID: detailsNewValue,
      contentType: contentType,
      contentCategory: contentCategory
    };

    const result = await applyChangesByType(payload);
    props.setLoading(false);

    if (result.type === "success") {
      // Refresh grid and all controls
      setDetailsNewValue(null);
      setDetailsStartDate(null);
      setDetailsEndDate(null);
      props.setLastResultNodes(lastApplyResult!.nodes);

      setGrid({
        type: GridActions.toggleRefreshGrid,
        payload: {
          gridId: props.gridId,
          gridData: true
        }
      });

      // Show success message
      dispatch({
        type: StoreActions.addNotification,
        payload: {
          message: "Changes applied successfully",
          messageType: "success",
          closable: false
        }
      });
    } else {
      // jon, 2/8/22: 409 (conflict) errors need to be handled here since they are normally ignored and handled by the grid
      if (
        result.error.message ===
        "A record using this unique value already exists."
      ) {
        dispatch({
          type: StoreActions.addNotification,
          payload: {
            message:
              "This operation was cancelled because it would result in duplicate key data.",
            messageType: "error",
            closable: true
          }
        });
      }
    }
  };

  const handleApplyChangesConfirmationNo = () => {
    setShowApplyChangesConfirmationDialog(false);
  };

  return (
    <Grid
      className="global-change-tab"
      columns={["1/3", "220px", "auto", "188px"]}
      gap="small"
      style={{ marginBottom: "10px" }}
    >
      {showApplyChangesConfirmationDialog && (
        <GlobalChangeApplyConfirmationDialog
          applyResult={lastApplyResult!}
          isAddTemplateZone={operation === GlobalOperationType.ADD_CONTENT}
          onAcceptCallback={handleApplyChangesConfirmationYes}
          onRejectCallback={handleApplyChangesConfirmationNo}
        />
      )}
      <Box
        align="start"
        pad="small"
        className="box-with-right-border"
        style={{ paddingLeft: "20px" }}
      >
        <h2>1. Criteria</h2>
        <DropDownField
          label="Type"
          labelWidth="90px"
          required={true}
          onChange={(event) => {
            const newContentType = event.value === "Media" ? "M" : "P";
            clearAllDetails();
            setContentType(newContentType);
            props.setSelectedId(-1);
            props.setContentType(newContentType);
          }}
          data={["Media", "Package"]}
          value={contentType === "M" ? "Media" : "Package"}
          defaultValue={"Media"}
        />
        {contentType === "M" && (
          <LookupField
            labelWidth="90px"
            required={true}
            fieldWidth={LOOKUP_FIELD_WIDTH}
            key="criteria1Lookup"
            id="criteria1Lookup"
            label="Media Type"
            lookupData={props.lookup1Data ?? []}
            valueFieldInLookup="MediaTypeID"
            textFieldInLookup="MediaTypeName"
            defaultValue={criteriaBox1.id}
            defaultText={criteriaBox1.name}
            onChange={handleCriteria1LookupChange}
          />
        )}
        {contentType === "P" && (
          <LookupField
            labelWidth="90px"
            required={true}
            fieldWidth={LOOKUP_FIELD_WIDTH}
            key="criteria2Lookup"
            id="criteria2Lookup"
            label="Package Type"
            lookupData={props.lookup2Data ?? []}
            valueFieldInLookup="PackageTypeID"
            textFieldInLookup="PackageTypeName"
            defaultValue={criteriaBox2.id}
            defaultText={criteriaBox2.name}
            onChange={handleCriteria2LookupChange}
          />
        )}
        <Box>
          <p style={{ color: "var(--carbon-darkgray)", fontSize: "14px" }}>
            {selectedRecords} selected{" "}
            {selectedRecords === 1 ? "record" : "records"}
          </p>
        </Box>
      </Box>
      <Box align="start" pad="small" className="box-with-right-border">
        <h2>2. Operation</h2>
        <RadioGroup
          disabled={criteriaBox1 === null}
          data={[
            {
              label: GlobalOperationType.REPLACE_CONTENT,
              value: GlobalOperationType.REPLACE_CONTENT
            },
            {
              label: GlobalOperationType.REMOVE_CONTENT,
              value: GlobalOperationType.REMOVE_CONTENT
            },
            {
              label: GlobalOperationType.ADD_CONTENT,
              value: GlobalOperationType.ADD_CONTENT
            }
          ]}
          value={operation}
          onChange={(event: RadioGroupChangeEvent) =>
            onOperationChanged(event.value)
          }
        />
      </Box>
      <Box align="start" pad="small" className="box-with-right-border">
        <h2>3. Details</h2>
        {operation === GlobalOperationType.REPLACE_CONTENT && (
          <>
            {contentType === "M" && (
              <LookupField
                disabled={criteriaBox1 === null && criteriaBox2 === null}
                required={true}
                labelWidth={LABEL_WIDTH}
                fieldWidth={LOOKUP_FIELD_WIDTH}
                key="detailsNewValueLookup"
                id="detailsNewValueLookup"
                label="New Media"
                lookupData={detailsMediaLookupData}
                valueFieldInLookup="MediaID"
                textFieldInLookup="MediaName"
                defaultValue={detailsNewValue}
                defaultText=""
                onChange={handleDetailsNewValueLookupChange}
              />
            )}
            {contentType === "P" && (
              <LookupField
                disabled={criteriaBox1 === null && criteriaBox2 === null}
                required={true}
                labelWidth={LABEL_WIDTH}
                fieldWidth={LOOKUP_FIELD_WIDTH}
                key="detailsNewValueLookup"
                id="detailsNewValueLookup"
                label="New Package"
                lookupData={detailsPackagesLookupData}
                valueFieldInLookup="PackageID"
                textFieldInLookup="PackageName"
                defaultValue={detailsNewValue}
                defaultText=""
                onChange={handleDetailsNewValueLookupChange}
              />
            )}
            <DateField
              disabled={criteriaBox1 === null}
              labelWidth={LABEL_WIDTH}
              key="effectiveDate"
              id="effectiveDate"
              label="Effective Date"
              defaultValue={detailsStartDate}
              onChange={handleDetailsStartDateChange}
            />
          </>
        )}
        {operation === GlobalOperationType.REMOVE_CONTENT && (
          <>
            <DateField
              disabled={criteriaBox1 === null}
              labelWidth={LABEL_WIDTH}
              key="effectiveDate"
              id="effectiveDate"
              label="Effective Date"
              defaultValue={detailsStartDate}
              onChange={handleDetailsStartDateChange}
            />
          </>
        )}
        {operation === GlobalOperationType.ADD_CONTENT && (
          <>
            {contentType === "M" && (
              <LookupField
                disabled={criteriaBox1 === null && criteriaBox2 === null}
                required={true}
                labelWidth={LABEL_WIDTH}
                fieldWidth={LOOKUP_FIELD_WIDTH}
                key="detailsNewValueLookup"
                id="detailsNewValueLookup"
                label="New Media"
                lookupData={detailsMediaLookupData}
                valueFieldInLookup="MediaID"
                textFieldInLookup="MediaName"
                defaultValue={detailsNewValue}
                defaultText=""
                onChange={handleDetailsNewValueLookupChange}
              />
            )}
            {contentType === "P" && (
              <LookupField
                disabled={criteriaBox1 === null && criteriaBox2 === null}
                required={true}
                labelWidth={LABEL_WIDTH}
                fieldWidth={LOOKUP_FIELD_WIDTH}
                key="detailsNewValueLookup"
                id="detailsNewValueLookup"
                label="New Package"
                lookupData={detailsPackagesLookupData}
                valueFieldInLookup="PackageID"
                textFieldInLookup="PackageName"
                defaultValue={detailsNewValue}
                defaultText=""
                onChange={handleDetailsNewValueLookupChange}
              />
            )}
            <DateField
              disabled={criteriaBox1 === null}
              labelWidth={LABEL_WIDTH}
              key="startDate"
              id="startDate"
              label="Start Date"
              defaultValue={detailsStartDate}
              onChange={handleDetailsStartDateChange}
            />
            <DateField
              disabled={criteriaBox1 === null}
              labelWidth={LABEL_WIDTH}
              key="endDate"
              id="endDate"
              label="End Date"
              defaultValue={detailsEndDate}
              onChange={handleDetailsEndDateChange}
            />
          </>
        )}
      </Box>
      <Box align="start" pad="small">
        <div title="Apply changes to Nodes" style={{ marginBottom: "10px" }}>
          <Button
            primary={true}
            disabled={
              criteriaBox1 === null ||
              criteriaBox2 === null ||
              (operation !== GlobalOperationType.REMOVE_CONTENT &&
                detailsNewValue === null) ||
              selectedRecords === 0
            }
            style={{ width: BUTTON_WIDTH }}
            onClick={onApplyChangesClick}
          >
            {CarbonIcons.Done} Apply Changes
          </Button>
        </div>
        <div
          title="Restore history to Nodes affected by last operation"
          style={{ marginBottom: "10px" }}
        >
          <Button
            disabled={props.lastResultNodes === null}
            style={{ width: BUTTON_WIDTH }}
            onClick={() => props.setShowRestoreConfirmationDialog(true)}
          >
            {CarbonIcons.Restore} Revert Changes
          </Button>
        </div>
        <div title="Publish changes to Nodes affected by last operation">
          <Button
            disabled={props.lastResultNodes === null}
            style={{ width: BUTTON_WIDTH }}
            onClick={() => props.setShowPublishConfirmationDialog(true)}
          >
            {CarbonIcons.Publish} Publish Changes
          </Button>
        </div>
      </Box>
    </Grid>
  );
};

export default GlobalChangesTypeTab;
