// base
import { FC, useState, useEffect } from "react";
import styled from "styled-components";

// Kendo/Grommet
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import {
  RadioButton,
  RadioButtonChangeEvent,
  Checkbox
} from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import {
  Grid,
  GridColumn as Column,
  GridSortChangeEvent
} from "@progress/kendo-react-grid";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Notification,
  NotificationGroup
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Box } from "grommet";

// Custom components
import { LookupField } from "../../Fields/LookupField";
import DropDownField from "../../Fields/DropDownField";
import OctopusLoader from "../OctopusLoader";

// Hooks
import useNodeItemsExportApi from "../../../hooks/nodes/useNodeItemsExport";
import { useStore } from "../../../contexts/store";

// Constants
import { CarbonIcons } from "../../../constants";
import { NodeItemsExportContract } from "../../../types";

export interface ISectionDataProps {
  label: string;
  value: number;
}

interface INodeExportDialogProps {
  nodeName: string;
  nodeId: string;
  companyId: string;
  nodeItemId: number;
  acceptText?: string;
  rejectText?: string;
  onAcceptCallback: () => void;
  onRejectCallback: () => void;
}

const NodeExportDialog: FC<INodeExportDialogProps> = ({
  nodeName,
  nodeId,
  companyId,
  nodeItemId,
  acceptText,
  rejectText,
  onAcceptCallback,
  onRejectCallback
}) => {
  const nodeDropdownMenu = ["Node", "Node Group"];
  const initialSort: Array<SortDescriptor> = [
    { field: "NodeName", dir: "asc" }
  ];
  const { store } = useStore();
  const [sort, setSort] = useState(initialSort);
  const [nodeDropdown, setNodeDropdown] = useState(nodeDropdownMenu[0]);
  const [nodesData, setNodesData] = useState<any[]>([]);
  const [nodeGroupsData, setNodeGroupsData] = useState<any[]>([]);
  const [nodesGridData, setNodesGridData] = useState<any[]>([]);
  const [errorValue, setErrorValue] = useState("");

  // API requirements
  const {
    getNodeItemsExportData,
    isLoadingNodeListData,
    getNodeGroupData,
    isLoadingNodeGroupData,
    processNodeItemsExport,
    isLoadingNodeItemExport
  } = useNodeItemsExportApi();
  const [overwriteChecked, setOverwriteChecked] = useState(false);
  const [exportTypeSelect, setExportTypeSelect] = useState(-1);

  useEffect(() => {
    const nodesApiResult = getNodeItemsExportData(companyId, nodeId);
    nodesApiResult?.then((res) => {
      if (res.type === "success") {
        const response: any = res.value;
        const data: any[] = response.data;
        setNodesData(data.filter((item) => item.CompanyID === companyId));
      }
    });

    let nodeGroupCompany: number = -1;
    if (store.activeCompany) {
      if (store.activeCompany.companyId !== -1) {
        nodeGroupCompany = store.activeCompany.companyId;
      }
    }

    const nodeGroupApiResult = getNodeItemsExportData(
      nodeGroupCompany.toString()
    );
    nodeGroupApiResult?.then((res) => {
      if (res.type === "success") {
        const response: any = res.value;
        const data: any[] = response.data;
        console.log("\n\nNode Group Data:", data);
        setNodeGroupsData(data);
      }
    });
  }, []);

  // EVENT HANDLERS
  const handleNodeExportTypeChange = (event: DropDownListChangeEvent) => {
    console.log(`Type Change:`, event.value);

    setNodeDropdown(event.value);
    setNodesGridData([]);
  };

  const handleNodeExportDestChange = (value: number | null, text: string) => {
    console.log(`Type Change (${text}):`, value);

    if (value) {
      if (nodeDropdown === nodeDropdownMenu[0]) {
        setNodesGridData([nodesData.find((item) => item.NodeID === value)]);
      } else {
        const result = getNodeGroupData(companyId, value, nodeId);
        result.then((res) => {
          if (res.type === "success") {
            const response: any = res.value;
            const data: any = response.data;

            setNodesGridData(data.value);
          }
        });
      }
    } else {
      setNodesGridData([]);
    }
  };

  const onSubmitButtonClick = async () => {
    const toNodeList: number[] = nodesGridData.map((node) => node.NodeID);
    const toNodeStringList: string[] = toNodeList.map((node) =>
      node.toString()
    );

    const payload: NodeItemsExportContract = {
      ToNodeList: toNodeStringList.join(),
      Overwrite: overwriteChecked,
      SelectionType: exportTypeSelect,
      NodeItemID: nodeItemId
    };
    console.log("Company ID:", companyId);
    console.log("Node ID:", nodeId);
    console.log("API Contract:", payload);

    const result = await processNodeItemsExport(nodeId, companyId, payload);
    if (result.type === "success") {
      const resultValue: any = result.value;
      const message: string = resultValue.data;
      if (message.includes("ERROR")) {
        setErrorValue(message);
      } else {
        onAcceptCallback();
      }
    }
  };

  const sortChange = (event: GridSortChangeEvent) => {
    setNodesGridData(getProducts(event.sort));
    setSort(event.sort);
  };

  const getProducts = (sort: SortDescriptor[]) => {
    return orderBy(nodesGridData, sort);
  };

  return (
    <Dialog
      title={
        <h1 style={{ marginTop: "30px" }}>
          {CarbonIcons.Copy}
          <br />
          {"Export Text & Price"}
        </h1>
      }
      closeIcon={false}
      width={"45%"}
      minWidth={"475px"}
    >
      <Box style={{ position: "relative" }}>
        <Box>
          {errorValue.length > 0 && (
            <NotificationGroup
              style={{
                alignItems: "flex-start",
                flexWrap: "wrap-reverse",
                position: "relative",
                width: "100%",
                paddingRight: 0,
                paddingBottom: 30
              }}
            >
              <Fade className={"file-upload-warning"}>
                <Notification
                  type={{ style: "warning", icon: true }}
                  closable={true}
                  style={{ width: "100%" }}
                  onClose={() => {
                    setErrorValue("");
                  }}
                >
                  <span>{errorValue}</span>
                </Notification>
              </Fade>
            </NotificationGroup>
          )}
        </Box>
        {(isLoadingNodeListData ||
          isLoadingNodeItemExport ||
          isLoadingNodeGroupData) && <OctopusLoader />}
        <Box
          gap="small"
          border="bottom"
          pad={{ bottom: "15px" }}
          margin={{ bottom: "10px" }}
        >
          <Box pad={{ bottom: "15px" }} gap="xsmall" border="bottom">
            <Label
              style={{ fontSize: "16px", fontWeight: "bold", opacity: "0.75" }}
            >
              1. TEXT & PRICE
            </Label>
            <Box direction="row">
              <RadioButton
                name="typeSelectRadioGroup"
                label="Selected text & price record"
                value={0}
                onChange={(e: RadioButtonChangeEvent) => {
                  console.log(e.value);
                  setExportTypeSelect(e.value);
                }}
              />
            </Box>
            <Box direction="row">
              <RadioButton
                name="typeSelectRadioGroup"
                id="rb2"
                value={1}
                onChange={(e: RadioButtonChangeEvent) => {
                  console.log(e.value);
                  setExportTypeSelect(e.value);
                }}
              >
                <label
                  htmlFor="rb2"
                  className={"k-radio-label"}
                  style={{ display: "inline-block" }}
                >
                  All text & prices for node: <b>{nodeName}</b>
                </label>
              </RadioButton>
            </Box>
          </Box>
          <Label
            style={{ fontSize: "16px", fontWeight: "bold", opacity: "0.75" }}
          >
            2. DESTINATION
          </Label>
          <Box direction="row" gap="medium">
            <StyledDropdownFieldWrapper>
              <DropDownField
                onChange={(event) => {
                  handleNodeExportTypeChange(event);
                }}
                data={nodeDropdownMenu}
                value={nodeDropdown}
                defaultValue={nodeDropdownMenu[0]}
                fieldWidth="100%"
              />
            </StyledDropdownFieldWrapper>
            <StyledLookupFieldWrapper>
              {nodeDropdown === nodeDropdownMenu[0] && (
                <LookupField
                  labelWidth="0px"
                  key="NodeItemExportDestSelect"
                  id="NodeItemExportDestSelect"
                  label=""
                  lookupData={nodesData}
                  valueFieldInLookup={"NodeID"}
                  textFieldInLookup={"NodeName"}
                  defaultValue={-1}
                  defaultText=""
                  onChange={handleNodeExportDestChange}
                  fieldWidth="100%"
                />
              )}
              {nodeDropdown === nodeDropdownMenu[1] && (
                <LookupField
                  labelWidth="0px"
                  key="NodeGroupExportDestSelect"
                  id="NodeGroupExportDestSelect"
                  label=""
                  lookupData={nodeGroupsData}
                  valueFieldInLookup={"NodeGroupID"}
                  textFieldInLookup={"GroupName"}
                  defaultValue={-1}
                  defaultText=""
                  onChange={handleNodeExportDestChange}
                  fieldWidth="100%"
                />
              )}
            </StyledLookupFieldWrapper>
          </Box>
          <Box>
            <StyledGrid
              data={nodesGridData}
              scrollable="scrollable"
              resizable={true}
              style={{ height: "300px", maxHeight: "300px" }}
              sortable={{
                allowUnsort: true,
                mode: "multiple"
              }}
              sort={sort}
              onSortChange={sortChange}
            >
              <Column field="NodeName" title="Node" />
              <Column field="NodeDesc" title="Description" sortable={false} />
            </StyledGrid>
          </Box>
          <Checkbox
            label="Overwrite existing text & prices"
            value={overwriteChecked}
            onChange={() => setOverwriteChecked(!overwriteChecked)}
          />
        </Box>
        <Box margin={{ top: "10px" }}>
          <DialogActionsBar>
            <Button onClick={onRejectCallback}>
              {CarbonIcons.Close}
              {rejectText || "Cancel"}
            </Button>
            <Button
              primary={true}
              onClick={onSubmitButtonClick}
              disabled={exportTypeSelect === -1 || nodesGridData.length === 0}
            >
              {CarbonIcons.Check}
              {acceptText || "Yes, proceed"}
            </Button>
          </DialogActionsBar>
        </Box>
      </Box>
    </Dialog>
  );
};

const StyledDropdownFieldWrapper = styled(FieldWrapper)`
  margin-top: -20px;
  width: 25%;
`;

const StyledLookupFieldWrapper = styled(FieldWrapper)`
  margin-top: -20px;
  width: 75%;
`;

const StyledGrid = styled(Grid)`
  box-shadow: 2px 2px 8px var(--carbon-griddropshadow);
  border-radius: 6px;

  /* Remove right border in header since it interferes with column selector button */
  div.k-grid-header-wrap {
    border-width: 0;
  }

  /* Turn off default hover state on header row*/
  table tr:hover,
  table tr.k-state-hover,
  table td.k-state-focused,
  table th.k-state-focused,
  table th:focus,
  table .k-master-row > td:focus,
  table .k-grouping-row > td:focus,
  table .k-detail-row > td:focus,
  table .k-group-footer > td:focus {
    background-color: transparent;
  }

  table {
    table-layout: fixed;

    colgroup {
      col.k-sorted {
        background-color: var(--carbon-white);
      }
    }

    thead {
      tr {
        height: 26px !important;

        th {
          color: var(--carbon-darkgray);
          font-size: 14px;
          font-weight: 600;
          padding: 2px 24px;

          &.k-header {
            &:hover {
              color: var(--carbon-white);
              background-color: var(--carbon-blue);

              .k-link {
                color: var(--carbon-white);
              }

              input.k-checkbox {
                color: var(--carbon-blue);
                background-color: var(--carbon-white);
                border-color: var(--carbon-white);
              }
            }
          }

          &.k-sorted {
            background-color: var(--carbon-white);

            .k-i-sort-asc-sm,
            .k-i-sort-desc-sm,
            .k-sort-order {
              color: var(--carbon-blue);
            }

            &:hover .k-sort-order {
              color: var(--carbon-white);
            }
          }

          &:first-child {
            border-left: 5px solid transparent;
          }
        }

        th[role="columnheader"] {
          border-bottom: 1px solid var(--carbon-darkgray);
        }
      }

      tr.k-filter-row th {
        background-color: var(--carbon-lightergray);
        padding: 0 8px 0 24px;

        input.k-textbox,
        .k-input,
        .k-select,
        .k-button {
          padding: 0;
          font-size: 14px;
          font-weight: normal;
          height: 24px !important;
        }

        &.k-dropdown-wrap {
          margin-bottom: 5px;
        }

        div.k-filtercell button,
        div.k-filtercell span.k-select {
          width: 22px;
        }
      }
    }

    tbody {
      tr {
        height: 51px;
        cursor: pointer;
        position: relative;

        &.grid-insert-row-overlay {
          opacity: 0.5;
          background-color: var(--carbon-verylightgray) !important;
        }

        &:active,
        &:hover {
          background-color: var(--carbon-verylightgray);

          > td {
            &:first-child {
              border-left: 5px solid var(--carbon-mediumgray);
            }
          }
        }

        td {
          color: var(--carbon-black);
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 16px;
          font-weight: 400;
          padding: 8px 24px;

          &:first-child {
            border-left: 5px solid transparent;
          }

          &.override-cell {
            font-weight: bold;

            span[class^="material-icons"] {
              font-size: 16px;
              color: var(--carbon-blue);
              display: inline-flex;
              vertical-align: middle;
              margin-left: 5px;
              margin-top: -2px;
              padding: 2px;

              &:hover {
                color: var(--carbon-black);
              }
            }
          }
        }
      }
    }
  }
`;

export default NodeExportDialog;
