import {
  DropDownList,
  DropDownListProps
} from "@progress/kendo-react-dropdowns";
import { Box } from "grommet";
import { FC, ReactElement } from "react";
import { Label } from "@progress/kendo-react-labels";
import styled from "styled-components";
import { FieldWrapper } from "@progress/kendo-react-form";

export interface IDropDownFieldProps extends DropDownListProps {
  labelWidth?: string;
  label?: string;
  fieldWidth?: string;
  disabled?: boolean;
  required?: boolean;
}

const DropDownField: FC<IDropDownFieldProps> = ({ labelWidth, ...props }) => {
  const valueRender = (element: ReactElement<HTMLSpanElement>, value: any) => {
    return (
      <Box pad={{ left: "small" }} justify="center" align="start" fill>
        {value}
      </Box>
    );
  };
  const dropdownListWidth = props.fieldWidth ?? "200px";
  const disabled = props.disabled ?? false;
  const required = props.required ?? false;

  const label = required ? (
    <span style={{ fontWeight: "bold" }}>
      <span
        style={{
          color: "var(--carbon-orange)",
          display: "inline-block",
          marginLeft: "-12px"
        }}
      >
        *&nbsp;
      </span>
      <Label
        style={{ width: labelWidth, fontWeight: "bold" }}
        editorId={props.id}
      >
        {props.label}
      </Label>
    </span>
  ) : (
    <Label
      style={{ width: labelWidth, fontWeight: "bold" }}
      editorId={props.id}
    >
      {props.label}
    </Label>
  );

  return (
    <StyledFieldWrapper>
      {label}
      <DropDownList
        disabled={disabled}
        style={{
          width: dropdownListWidth,
          backgroundColor: "var(--carbon-white)",
          borderRadius: 5
        }}
        valueRender={valueRender}
        data={props.data}
        value={props.value}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
      />
    </StyledFieldWrapper>
  );
};

export default DropDownField;

const StyledFieldWrapper = styled(FieldWrapper)`
  margin-bottom: 8px;

  label {
    color: var(--carbon-darkgray);
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-right: 10px;
    display: inline-block;
  }

  span.k-dropdown {
    display: inline-block;
    font-size: 16px;
    background: var(--carbon-white);
    border: 1px solid var(--carbon-lightgray);
    box-sizing: border-box;
    border-radius: 6px;
    height: 28px;

    &:focus {
      border-bottom-color: var(--carbon-orange);
    }

    span.k-dropdown-wrap {
      border-bottom-width: 0;
      margin-top: -4px;
    }

    span.k-dropdown-wrap > div {
      margin-top: 6px;
      margin-left: -8px;
    }
  }
`;
