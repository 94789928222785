import { GridActions } from "../../../constants";
import { GridAction, GridStateType } from "../../../contexts/grid/useGrid";

export const unlockGridIfLockedOut = (
  gridId: string,
  grids: GridStateType,
  setGrid: React.Dispatch<GridAction>
) => {
  // Unlock grid (if it is locked out)
  if (grids.get(gridId)!.state.lockoutMode) {
    setGrid({
      type: GridActions.toggleGridLockoutMode,
      payload: { gridId, gridData: false }
    });
  }
};

export const replaceParametersWithData = (
  text: string,
  dataArray: { [key: string]: any }
): string => {
  let s = text;

  // Look for instances of bracketed parameter names and replace those values with actual values from the array
  //   This is used to build URLs, for example, when the values cannot be known until runtime:  "/api/[CompanyID]/download/media/[MediaID]"
  let pos = s.indexOf("[");
  while (pos >= 0) {
    const pos2 = s.indexOf("]", pos);
    const paramName = s.substring(pos + 1, pos2);

    // Replace all instances with data from the array, but if the param is not found in the array, replace brackets anyway so we avoid infinite loop.
    s = s.replaceAll(`[${paramName}]`, dataArray[paramName] ?? "");

    pos = s.indexOf("[");
  }

  return s;
};
