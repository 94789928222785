import { Helmet } from "react-helmet-async";
import { useLocation, useParams } from "react-router-dom";
import { Box } from "grommet";
import { useState, useRef, useEffect } from "react";

// Components
import CarbonGridToolbar, {
  GridToolbarActionLabels,
  GridToolbarItem
} from "../../components/CarbonGrid/Toolbar/CarbonGridToolbar";
import CarbonTabStrip from "../../components/CarbonTabs/CarbonTabStrip";
import CarbonGrid from "../../components/CarbonGrid/CarbonGrid";
import Authorizer from "../../components/Util/Authorizer";
import NodeExportDialog from "../../components/Util/Dialogs/NodeTemplatesExportDialog";
import ConfirmationDialog from "../../components/Util/Dialogs/ConfirmationDialog";
import OctopusLoader from "../../components/Util/OctopusLoader";
import {
  GridActionBarEvent,
  GridActionBarItem
} from "../../components/CarbonGrid/Toolbar/CarbonGridRowActionBar";
import CarbonBreadcrumb from "../../components/Breadcrumb/CarbonBreadcrumb";

// Types & Constants
import { GridType } from "../../types/grid";
import getTabs from "./NodesTabs";
import { gridColumns as gridColumnsNodeTemplates } from "./NodeTemplatesGridColumns";
import { gridColumns as gridColumnsNodeZones } from "./NodeZonesGridColumns";

// Contexts
import { useGrid } from "../../contexts/grid/useGrid";
import { useStore } from "../../contexts/store";

// Hooks
import usePermissions from "../../hooks/auth/usePermissions";
import useNodeSchedulesOps from "../../hooks/nodes/useNodeSchedulesOps";
import { Button } from "@progress/kendo-react-buttons";

// User/Permissions
import {
  EndpointResources,
  Placeholders,
  UserPermissions,
  GridIDs,
  CarbonIcons,
  GridActions,
  StoreActions
} from "../../constants";

import { GridDetailRowProps } from "@progress/kendo-react-grid";

import {
  NodeSchedulesInsertZonesContract,
  NodeSchedulesRefreshZonesContract
} from "../../types";
import NodeSchedulesAddZonesDialog from "../../components/Util/Dialogs/NodeSchedulesAddZonesDialog";
import NodeCopyToDayPartDialog from "../../components/Util/Dialogs/NodeCopyToDayPartDialog";
import { IDayPart, useDayGroups } from "../../hooks/useDayGroups";
import NodeScheduleDayGroups from "./NodeScheduleDayGroups";

type ScheduleZoneOpType = {
  nodeScheduleID: number;
  scheduleZoneID: number;
  selectedDayGroupDays: string;
  activeDayPartId: number;
};

const NodeSchedules = (): JSX.Element => {
  const [showExportTemplatesDialog, setShowExportTemplatesDialog] =
    useState(false);
  const [nodeName, setNodeName] = useState("");
  const [parentCompanyId, setParentCompanyId] = useState("");
  const [nodeScheduleId, setNodeScheduleId] = useState(-1);
  const [selectedDayGroupDays, setSelectedDayGroupDays] = useState<string>("");
  const [selectedDayPart, setSelectedDayPart] = useState<IDayPart | undefined>(
    undefined
  );
  const [selectedDayGroupName, setSelectedDayGroupName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [zoneOpParams, setZoneOpParams] = useState<
    ScheduleZoneOpType | undefined
  >(undefined);
  const [showConfirmRefreshZonesDialog, setShowConfirmRefreshZonesDialog] =
    useState<boolean>(false);
  const [showSelectNumberOfZonesDialog, setShowSelectNumberOfZonesDialog] =
    useState<boolean>(false);
  const [showCopyDayPartDialog, setShowCopyDayPartDialog] =
    useState<boolean>(false);

  const [showEmptyGridRender, setShowEmptyGridRender] = useState<boolean>(true);

  const { pathname } = useLocation();
  const { nodeId } = useParams();
  const { grids, setGrid } = useGrid();
  const { store, dispatch } = useStore();
  const { refreshZonesFromTemplate, insertScheduleZoneRecords } =
    useNodeSchedulesOps();

  const gridNameNodeTemplates = GridIDs.NodeTemplates;
  const gridNameNodeZones = GridIDs.NodeZones;
  const tabs = getTabs(EndpointResources.Nodes, nodeId!);
  const { isGranted } = usePermissions();
  const pagePermissionNodeTemplates = UserPermissions.CanManageNodeTemplates;
  const pagePermissionNodeContent = UserPermissions.CanManageNodeContent;
  const nodesGrid = grids.get(GridIDs.Nodes);
  let nodesTitle = "Nodes";
  const emptyGridRenderRef = useRef(null);
  const detailGridName = gridNameNodeZones;

  // will, 3/25/22: Added effect for monitoring quick filter status so that if the quick filter is in use it does not trigger the EmptyGridRender logic and hide the filter
  useEffect(() => {
    setShowEmptyGridRender(
      !grids.get(GridIDs.NodeTemplates)?.state.showQuickFilter
    );
  }, [grids.get(GridIDs.NodeTemplates)?.state.showQuickFilter]);

  const { refreshDayPartStatuses, loadInitialData } = useDayGroups();

  const onAfterInsertTemplate = (rowData: { [key: string]: any }) => {
    console.log(`After insert template: `, rowData);
    refreshDayPartStatuses(rowData.NodeID);
  };

  const onAfterUpdateTemplate = (rowData: { [key: string]: any }) => {
    console.log(`After update template: `, rowData);
    refreshDayPartStatuses(rowData.NodeID);
  };

  const onAfterDeleteTemplate = (deletedRecordId: number) => {
    console.log(`After delete template: `, deletedRecordId);
    const nodeID =
      grids.get(GridIDs.NodeTemplates)?.state.parentSelectedRowData?.NodeID ??
      -1;
    refreshDayPartStatuses(nodeID);
  };

  const onAfterInsertZone = (rowData: { [key: string]: any }) => {
    console.log(`After insert zone: `, rowData);
    refreshDayPartStatuses(rowData.NodeID);
  };

  const onAfterUpdateZone = (rowData: { [key: string]: any }) => {
    console.log(`After update zone: `, rowData);
    refreshDayPartStatuses(rowData.NodeID);
  };

  const onAfterDeleteZone = (deletedRecordId: number) => {
    console.log(`After delete zone: `, deletedRecordId);
    // For some reason, grids do not seem to exist here, but only here. Use route param instead.
    refreshDayPartStatuses(parseInt(nodeId, 10));
  };

  if (nodesGrid) {
    if (store.activeNodeGroup?.nodeGroupName) {
      nodesTitle = store.activeNodeGroup?.nodeGroupName;
    }
  }

  // PERMISSIONS: this page is accessible to users with Manage Templates and/or Manage Content
  // permissions. All permissions will be set to Manage Templates permissions and if that is
  // false, then will be switched to Manage Content permission.
  // jon, 1/26/22: Let users with View Nodes permission see this page
  const viewPagePermission = UserPermissions.NodesPerms;

  let insertPagePermission = pagePermissionNodeTemplates;
  if (!isGranted(pagePermissionNodeTemplates))
    insertPagePermission = pagePermissionNodeContent;

  let updatePagePermission = pagePermissionNodeTemplates;
  if (!isGranted(pagePermissionNodeTemplates))
    updatePagePermission = pagePermissionNodeContent;

  const newGridColumnsNodeTemplates = [
    {
      field: "NodeID",
      title: "NodeID",
      width: "150px",
      defaultShow: false,
      filter: "numeric",
      editable: false,
      required: false,
      canBeSaved: true,
      defaultValue: nodeId,
      systemHidden: true
    },
    ...gridColumnsNodeTemplates
  ];

  const currentTab = {
    index: tabs.findIndex((tab) => tab.resourceEndpoint === pathname),
    tab: tabs.find((tab) => tab.resourceEndpoint === pathname)
  };

  const actionLabels: GridToolbarActionLabels = {
    singular: "template",
    plural: "templates",
    titleSingular: "Template",
    titlePlural: "Templates"
  };

  const RefreshZonesConfirmationDialog = (): JSX.Element => {
    return (
      <ConfirmationDialog
        bodyText={`This process will add any new zones that may have been added to the Template. Do you want to continue?`}
        onAcceptCallback={() => {
          setShowConfirmRefreshZonesDialog(false);
          refreshZonesFromTemplateOperation();
        }}
        onRejectCallback={() => {
          setShowConfirmRefreshZonesDialog(false);
        }}
      />
    );
  };

  const refreshZonesFromTemplateOperation = async () => {
    const nodeScheduleID = grids.get(gridNameNodeTemplates)!.state
      .selectedRowData!.NodeScheduleID;
    const days = `${
      grids.get(gridNameNodeTemplates)!.state.selectedDayGroupDays
    }`;
    const daypartid = grids.get(gridNameNodeTemplates)!.state.activeDayPart!.id;

    const payload: NodeSchedulesRefreshZonesContract = {
      nodeScheduleID,
      days,
      daypartid
    };

    console.log(
      `Refreshing zones from template for NodeScheduleID ${nodeScheduleID}`,
      payload
    );
    setLoading(true);
    const result = await refreshZonesFromTemplate(payload);
    setLoading(false);

    if (result.type === "success") {
      setGrid({
        type: GridActions.toggleRefreshGrid,
        payload: {
          gridId: gridNameNodeTemplates,
          gridData: true
        }
      });

      // Show success message
      dispatch({
        type: StoreActions.addNotification,
        payload: {
          message: "Zones refreshed successfully",
          messageType: "success",
          closable: false
        }
      });
    }
  };

  const doRefreshZonesFromTemplate = async (confirm: boolean) => {
    if (confirm) {
      setShowConfirmRefreshZonesDialog(true);
      return;
    }

    await refreshZonesFromTemplateOperation();
  };

  const SelectNumberOfZonesDialog = (): JSX.Element => {
    return (
      <NodeSchedulesAddZonesDialog
        onAcceptCallback={(numZonesToAdd: number) => {
          setShowSelectNumberOfZonesDialog(false);
          if (zoneOpParams) {
            doInsertScheduleZoneRecords(
              zoneOpParams!.nodeScheduleID,
              zoneOpParams!.scheduleZoneID,
              zoneOpParams!.selectedDayGroupDays,
              zoneOpParams!.activeDayPartId,
              numZonesToAdd
            );
            setZoneOpParams(undefined);
          }
        }}
        onRejectCallback={() => {
          setShowSelectNumberOfZonesDialog(false);
        }}
      />
    );
  };

  const doInsertScheduleZoneRecords = async (
    nodeScheduleID: number,
    scheduleZoneID: number,
    days: string,
    daypartid: number,
    numZonesToAdd: number
  ) => {
    setLoading(true);

    const payload: NodeSchedulesInsertZonesContract = {
      nodeScheduleID,
      scheduleZoneID,
      numZonesToAdd,
      days,
      daypartid
    };

    console.log(
      `Inserting zone records for NodeScheduleID ${nodeScheduleID} and ScheduleZoneID ${scheduleZoneID}`,
      payload
    );
    const result = await insertScheduleZoneRecords(payload);
    setLoading(false);

    if (result.type === "success") {
      setGrid({
        type: GridActions.toggleRefreshGrid,
        payload: {
          gridId: detailGridName,
          gridData: true
        }
      });

      // Show success message
      dispatch({
        type: StoreActions.addNotification,
        payload: {
          message: `${numZonesToAdd} zone ${
            numZonesToAdd === 1 ? "record" : "records"
          } added successfully`,
          messageType: "success",
          closable: false
        }
      });
    }
  };

  // will, 2/11/22: added effect for dialog instead of in multiple zone button event to improve timing
  useEffect(() => {
    if (zoneOpParams !== undefined) {
      console.log(
        "*ZONES ISSUE* - zone op params are changed. Showing dialog...",
        zoneOpParams
      );
      setShowSelectNumberOfZonesDialog(true);
    } else {
      console.log("*ZONES ISSUE* - zone op params are changed but undefined");
    }
  }, [zoneOpParams]);

  const zoneActions: GridActionBarItem[] = [
    {
      show: isGranted(pagePermissionNodeContent),
      label: "Add multiple to zone",
      includeInExtrasMenu: false,
      icon: CarbonIcons.AddZoneMultiple,
      onClick: async (event: GridActionBarEvent) => {
        console.log(
          "*ZONES ISSUE* - Add multiple to zone clicked",
          event.rowData
        );
        setZoneOpParams({
          nodeScheduleID: event.rowData.NodeScheduleID,
          scheduleZoneID: event.rowData.ScheduleZoneID,
          selectedDayGroupDays: event.selectedDayGroupDays!,
          activeDayPartId: event.activeDayPartId!
        });
      }
    },
    {
      show: isGranted(pagePermissionNodeContent),
      label: "Add content to zone",
      includeInExtrasMenu: false,
      icon: CarbonIcons.AddZoneSingle,
      onClick: async (event: GridActionBarEvent) => {
        await doInsertScheduleZoneRecords(
          event.rowData.NodeScheduleID,
          event.rowData.ScheduleZoneID,
          event.selectedDayGroupDays!,
          event.activeDayPartId!,
          1
        );
      }
    }
  ];

  const zonesSubgrid = (props: GridDetailRowProps): JSX.Element => {
    const dataItem = props.dataItem;

    const newGridColumnsNodeZones = [
      {
        field: "NodeScheduleID",
        title: "NodeScheduleID",
        width: "150px",
        defaultShow: false,
        filter: "numeric",
        editable: false,
        required: false,
        canBeSaved: true,
        defaultValue: dataItem.NodeScheduleID
      },
      ...gridColumnsNodeZones
    ];

    const gridSettingsNodeZones: GridType = {
      actions: zoneActions,
      endpoints: {
        gridODataEndpoint: `/odata/${Placeholders.companyID}/${dataItem.NodeScheduleID}/gridnodezones?$count=true&`,
        gridApiUpdateEndpointOverride: `/api/${dataItem.NodeScheduleID}/nodezones/${Placeholders.selectedRecordID}?days=${Placeholders.nodeScheduleSelectedDayGroupDays}&daypartid=${Placeholders.nodeScheduleSelectedDayPartID}`,
        gridApiDeleteEndpointOverride: `/api/${dataItem.NodeScheduleID}/nodezones/${Placeholders.selectedRecordID}?days=${Placeholders.nodeScheduleSelectedDayGroupDays}&daypartid=${Placeholders.nodeScheduleSelectedDayPartID}`,
        lookupEndpoints: [
          {
            lookupField: "MediaName",
            endpoint: `/api/${Placeholders.companyID}/lookupmedia/all`
          },
          {
            lookupField: "PackageName",
            endpoint: `/api/${Placeholders.companyID}/lookuppackages`
          }
        ]
      },
      records: [],
      total: 0,
      data: [],
      lookups: {},
      dataState: {
        pageSize: 10000,
        take: 10000,
        skip: 0,
        sort: [
          { field: "SeqNum", dir: "asc" },
          { field: "ZoneName", dir: "asc" },
          { field: "ContentSeqNum", dir: "asc" },
          { field: "StartDate", dir: "asc" },
          { field: "EndDate", dir: "asc" }
        ]
      },
      dataItemKey: "ScheduleZoneID",
      singularEntityName: actionLabels.singular,
      pluralEntityName: actionLabels.plural,
      columns: newGridColumnsNodeZones,
      showCopyBtn: false,
      showDeleteBtn: isGranted(pagePermissionNodeContent),
      isSubgrid: true,
      parentGridId: gridNameNodeTemplates,
      onAfterInsert: onAfterInsertZone,
      onAfterUpdate: onAfterUpdateZone,
      onAfterDelete: onAfterDeleteZone,
      state: {
        editMode: false,
        insertMode: false,
        selectedState: {},
        selectedRow: -1,
        showQuickFilter: false,
        lastSaveDate: null,
        showSavingIndicator: false,
        lockoutMode: false
      }
    };

    return (
      <Box fill>
        <CarbonGrid
          gridId={detailGridName}
          gridSettings={gridSettingsNodeZones}
          gridClassName={"grid-with-actionbar"}
          onEmptyGridRender={emptyZonesGridRender}
          onAfterUpdate={onAfterUpdateZone}
          onAfterDelete={onAfterDeleteZone}
          onAfterInsert={onAfterInsertZone}
        />
      </Box>
    );
  };

  const DuplicateDayPartDialog = (): JSX.Element => {
    return (
      <NodeCopyToDayPartDialog
        nodeId={parseInt(nodeId, 10)}
        companyId={parseInt(parentCompanyId, 10)}
        nodeScheduleId={nodeScheduleId}
        selectedDayPartName={selectedDayPart!.title}
        selectedDayPartId={selectedDayPart!.id}
        selectedDays={selectedDayGroupDays}
        onAcceptCallback={(message: string) => {
          setShowCopyDayPartDialog(false);

          loadInitialData();
          // Show success message
          dispatch({
            type: StoreActions.addNotification,
            payload: {
              message: message,
              messageType: "success",
              closable: false
            }
          });
        }}
        onRejectCallback={() => {
          setShowCopyDayPartDialog(false);
        }}
      />
    );
  };

  const ExportTemplatesDialog = (): JSX.Element => {
    return (
      <NodeExportDialog
        nodeName={nodeName}
        nodeId={nodeId}
        companyId={parentCompanyId}
        nodeScheduleId={nodeScheduleId}
        sourceDays={selectedDayGroupDays}
        selectedDayGroupName={selectedDayGroupName}
        acceptText="Submit"
        onAcceptCallback={(message: string) => {
          setShowExportTemplatesDialog(false);

          // Show success message
          dispatch({
            type: StoreActions.addNotification,
            payload: {
              message: message,
              messageType: "success",
              closable: false
            }
          });
        }}
        onRejectCallback={() => {
          setShowExportTemplatesDialog(false);
        }}
      />
    );
  };

  const templateActions: GridActionBarItem[] = [
    {
      show:
        isGranted(pagePermissionNodeTemplates) &&
        isGranted(UserPermissions.CanManageNodeContent), // will, 7/8/22: matching permissions required for API endpoint
      label: "Duplicate Day Part",
      includeInExtrasMenu: true,
      icon: CarbonIcons.Dayparts,
      onClick: (event: GridActionBarEvent) => {
        const nodeTemplatesGrid = event.grid;
        if (
          !nodeTemplatesGrid ||
          !event.rowData ||
          !nodeTemplatesGrid.state.selectedDayGroupDays ||
          !nodeTemplatesGrid.state.activeDayPart
        ) {
          console.log(
            "Row data unexpectedly undefined in Duplicate Day part menu click!"
          );
          return;
        }

        // will, 2/8/22: Disabled grid resizing manually for soft keyboard causing fields in dialog to re-render
        // because of window resize (mobile browser)
        setGrid({
          type: GridActions.toggleGridResize,
          payload: {
            gridId: GridIDs.NodeTemplates,
            gridData: { data: true }
          }
        });

        setNodeScheduleId(event.rowData.NodeScheduleID);
        setSelectedDayGroupDays(
          `${nodeTemplatesGrid.state.selectedDayGroupDays}`
        );
        setSelectedDayPart(nodeTemplatesGrid.state.activeDayPart);
        setShowCopyDayPartDialog(true);
      }
    },
    {
      show: isGranted(updatePagePermission),
      label: "Refresh zones from template",
      includeInExtrasMenu: true,
      icon: CarbonIcons.Refresh,
      onClick: async () => {
        await doRefreshZonesFromTemplate(true);
      }
    },
    {
      show:
        isGranted(pagePermissionNodeTemplates) &&
        isGranted(UserPermissions.CanManageNodeContent), // will, 7/8/22: matching permissions required for API endpoint
      label: "Export Templates",
      includeInExtrasMenu: true,
      icon: CarbonIcons.Copy,
      onClick: (event: GridActionBarEvent) => {
        const nodeTemplatesGrid = event.grid;
        if (
          !nodeTemplatesGrid ||
          !event.rowData ||
          !nodeTemplatesGrid.state.selectedDayGroupDays
        ) {
          console.log(
            "Row data unexpectedly undefined in Export Templates menu click!"
          );
          return;
        }

        // will, 2/8/22: Disabled grid resizing manually for soft keyboard causing fields in dialog to re-render
        // because of window resize (mobile browser)
        setGrid({
          type: GridActions.toggleGridResize,
          payload: {
            gridId: GridIDs.NodeTemplates,
            gridData: { data: true }
          }
        });

        setNodeScheduleId(event.rowData.NodeScheduleID);
        setSelectedDayGroupDays(
          `${nodeTemplatesGrid.state.selectedDayGroupDays}`
        );
        setSelectedDayGroupName(
          nodeTemplatesGrid.state.selectedDayGroupName ??
            "the selected day group"
        );

        setShowExportTemplatesDialog(true);
      }
    }
  ];

  const gridSettingsTemplates: GridType = {
    actions: templateActions,
    endpoints: {
      gridODataEndpoint: `/odata/${Placeholders.companyID}/${Placeholders.nodeTabNodeId}/gridnodetemplates?nodescheduleids=${Placeholders.NodeScheduleIDs}&$count=true&`,
      gridApiInsertEndpointOverride: `/api/${Placeholders.nodeTabNodeId}/nodetemplates?days=${Placeholders.nodeScheduleSelectedDayGroupDays}&daypartid=${Placeholders.nodeScheduleSelectedDayPartID}`,
      gridApiUpdateEndpointOverride: `/api/${Placeholders.nodeTabNodeId}/nodetemplates/${Placeholders.selectedRecordID}?days=${Placeholders.nodeScheduleSelectedDayGroupDays}&daypartid=${Placeholders.nodeScheduleSelectedDayPartID}`,
      gridApiDeleteEndpointOverride: `/api/${Placeholders.nodeTabNodeId}/nodetemplates/${Placeholders.selectedRecordID}?days=${Placeholders.nodeScheduleSelectedDayGroupDays}&daypartid=${Placeholders.nodeScheduleSelectedDayPartID}`,
      parentGridApiEndpoint: `/api/${Placeholders.companyID}/${EndpointResources.Nodes}/${Placeholders.nodeTabNodeId}`,
      lookupEndpoints: [
        {
          lookupField: "NodeDayPartName",
          endpoint: `/api/${Placeholders.companyID}/lookupnodedayparts/${Placeholders.nodeTabNodeId}`
        },
        {
          lookupField: "TemplateName",
          endpoint: `/api/${Placeholders.companyID}/lookuptemplates`
        },
        {
          lookupField: "DisplayOrder",
          endpoint: `/api/${Placeholders.companyID}/lookupnodedisplays/${Placeholders.nodeTabNodeId}`
        }
      ]
    },
    records: [],
    total: 0,
    data: [],
    lookups: {},
    dataState: {
      pageSize: 25,
      take: 10000,
      skip: 0,
      sort: [
        { field: "TemplateName", dir: "asc" },
        { field: "StartDate", dir: "asc" },
        { field: "EndDate", dir: "asc" }
      ]
    },
    dataItemKey: "NodeScheduleID",
    singularEntityName: "template",
    pluralEntityName: "templates",
    columns: newGridColumnsNodeTemplates,
    showCopyBtn: false,
    showDeleteBtn: true,
    parentGridId: GridIDs.Nodes,
    // jon, 1/16/22: Tell grid not to refresh when path changes because we want the refresh to be controlled completely by day group/part record selection
    autoRefreshOnPathChange: false,
    detailRow: { subgridId: [detailGridName], content: zonesSubgrid },
    onAfterInsert: onAfterInsertTemplate,
    onAfterUpdate: onAfterUpdateTemplate,
    onAfterDelete: onAfterDeleteTemplate,
    state: {
      editMode: false,
      insertMode: false,
      selectedState: {},
      selectedRow: -1,
      showQuickFilter: false,
      lastSaveDate: null,
      showSavingIndicator: false,
      lockoutMode: false,
      nodeScheduleIds: []
    }
  };

  const cancelBtn: GridToolbarItem = {
    show: false,
    enable: isGranted(insertPagePermission),
    tooltip: isGranted(insertPagePermission)
      ? `Cancel creation of new ${actionLabels.singular}`
      : `You do not have permission to create a new ${actionLabels.singular}.`
  };

  const saveBtn: GridToolbarItem = {
    show: false,
    enable: isGranted(insertPagePermission),
    tooltip: isGranted(insertPagePermission)
      ? `Save the new ${actionLabels.singular}`
      : `You do not have permission to create a new ${actionLabels.singular}.`
  };

  const addBtn: GridToolbarItem = {
    show: true,
    enable: isGranted(insertPagePermission),
    tooltip: isGranted(insertPagePermission)
      ? ` Create a new ${actionLabels.singular}`
      : `You do not have permission to create a new ${actionLabels.singular}.`,
    labelOverride: `Add ${actionLabels.titleSingular}`
  };

  const previewBtn: GridToolbarItem = {
    show: true,
    enable: true,
    tooltip: "Open the viewer to preview node schedule",
    toggledOn: false
  };

  const editModeBtn: GridToolbarItem = {
    show: true,
    enable: isGranted(updatePagePermission),
    tooltip: isGranted(updatePagePermission)
      ? `Enable updates to existing ${actionLabels.plural}`
      : `You do not have permission to update existing ${actionLabels.plural}.`,
    onClick: () => {
      if (!grids.get(gridNameNodeTemplates)) return;

      // If the detail grid is showing, put that grid into edit mode. Otherwise, do parent grid.
      const isDetailGridShowing =
        grids.get(gridNameNodeTemplates)?.state.selectedRowIsExpanded ?? false;
      const isParentGridInEditMode =
        grids.get(gridNameNodeTemplates)?.state.editMode ?? false;
      const isDetailGridInEditMode =
        grids.get(detailGridName) &&
        (grids.get(detailGridName)?.state.editMode ?? false);

      if (isDetailGridShowing) {
        // Also toggle parent grid off if user clicks Edit toggle when editing detail grid
        if (isDetailGridInEditMode && isParentGridInEditMode) {
          setGrid({
            type: GridActions.toggleEditMode,
            payload: { gridId: gridNameNodeTemplates, gridData: {} }
          });
        }

        setGrid({
          type: GridActions.toggleEditMode,
          payload: { gridId: detailGridName, gridData: {} }
        });
      } else {
        setGrid({
          type: GridActions.toggleEditMode,
          payload: { gridId: gridNameNodeTemplates, gridData: {} }
        });
      }
    }
  };

  const emptyGridRender = () => {
    return (
      <Box
        fill
        ref={emptyGridRenderRef}
        style={{
          border: "1px solid var(--carbon-lightergray)",
          padding: 0
        }}
      >
        <Box
          style={{
            height: 33,
            borderBottom: "1px solid var(--carbon-lightergray)"
          }}
        />
        <Box>
          <Box
            align="center"
            style={{
              fontSize: 16,
              color: "var(--carbon-lightgray)",
              marginTop: 60
            }}
          >
            <Box>No templates in this Daypart.</Box>
            <Box style={{ marginTop: 20 }}>
              <Button
                className="carbon-link-btn"
                onClick={() => {
                  setGrid({
                    type: GridActions.toggleInsertMode,
                    payload: {
                      gridId: gridNameNodeTemplates,
                      gridData: { insertOn: true, data: null }
                    }
                  });
                }}
              >
                <span className="material-icons" style={{ fontSize: "16px" }}>
                  add
                </span>
                Add Template
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const emptyZonesGridRender = () => {
    return (
      <Box
        align="center"
        style={{
          marginTop: 20,
          marginBottom: 20,
          fontSize: 16,
          color: "var(--carbon-mediumgray)"
        }}
      >
        <Box>
          This template contains no zones. You must first add zones to the
          template before editing their content here.
        </Box>
      </Box>
    );
  };

  /* the way we pass grid actions now requires the actions 
  to be updated whenever the grid is chached (because the grid will cache these references). This useEffect will update these actions everytime 
  this component remounts
    */
  useEffect(() => {
    setGrid({
      type: GridActions.setGridActions,
      payload: {
        gridId: gridNameNodeTemplates,
        gridData: { actions: templateActions }
      }
    });
  }, []);

  // jon, 2/18/22: Look for changes to selected template row to fire this useeffect as well because selectedRowIsExpanded alone does not work when
  //   a row is already expanded and then another one is immediately expanded without closing the previous one first. selectedRowIsExpanded remains true.
  //   This hopefully fixes 20600: Add multiple zones button not responding
  useEffect(() => {
    if (
      grids.get(gridNameNodeTemplates)?.state.selectedRowIsExpanded === true
    ) {
      console.log(
        "*ZONES ISSUE* - Resetting zone actions on selected template row expansion",
        grids.get(gridNameNodeTemplates)?.state.selectedRowData?.TemplateName
      );
      setGrid({
        type: GridActions.setGridActions,
        payload: {
          gridId: gridNameNodeZones,
          gridData: { actions: zoneActions }
        }
      });
    } else {
      console.log(
        "*ZONES ISSUE* - Template grid's selectedRowIsExpanded property changed but not to TRUE!",
        grids.get(gridNameNodeTemplates)?.state.selectedRowData?.TemplateName
      );
    }
  }, [
    grids.get(gridNameNodeTemplates)?.state.selectedRowIsExpanded,
    grids.get(gridNameNodeTemplates)?.state.selectedRow
  ]);

  return (
    <>
      <Helmet>
        <title>Carbon | Node Schedules</title>
      </Helmet>
      <Authorizer
        canView={viewPagePermission}
        auditLabel={`${actionLabels.titlePlural} screen`}
      >
        {showExportTemplatesDialog && <ExportTemplatesDialog />}
        {showConfirmRefreshZonesDialog && <RefreshZonesConfirmationDialog />}
        {showSelectNumberOfZonesDialog && <SelectNumberOfZonesDialog />}
        {showCopyDayPartDialog && <DuplicateDayPartDialog />}
        <Box align="start" fill>
          <CarbonBreadcrumb
            backLinkTitle={nodesTitle}
            backLinkUrl="/app/nodes"
            pageTitle={currentTab.tab?.tabTitle ?? ""}
          />
          <CarbonGridToolbar
            gridId={gridNameNodeTemplates}
            screenIcon={CarbonIcons.Schedule}
            screenTitle={""}
            screenSubtitle={""}
            permissions={pagePermissionNodeTemplates}
            actionLabels={actionLabels}
            getScreenTitleFromParentRow={(rowData: { [key: string]: any }) => {
              setNodeName(rowData.NodeName);
              return rowData.NodeName;
            }}
            getScreenSubtitleFromParentRow={(rowData: {
              [key: string]: any;
            }) => {
              setParentCompanyId(rowData.CompanyID);
              return rowData.CompanyName;
            }}
            cancelBtn={cancelBtn}
            saveBtn={saveBtn}
            addBtn={addBtn}
            editModeBtn={editModeBtn}
            publishBtn={true}
            previewBtn={previewBtn}
          />
          <CarbonTabStrip carbonTabs={tabs} selectedTab={currentTab.index} />
          <Box
            fill
            background="var(--carbon-white)"
            round="xsmall"
            direction="row"
            basis="full"
          >
            <NodeScheduleDayGroups />
            <Box fill pad={{ bottom: emptyGridRenderRef ? "0px" : "15px" }}>
              <Box fill className="carbon-grid-wrapper">
                {loading && <OctopusLoader />}
                <CarbonGrid
                  gridId={gridNameNodeTemplates}
                  gridSettings={gridSettingsTemplates}
                  gridClassName={"node-schedule-grid"}
                  onEmptyGridRender={
                    showEmptyGridRender ? emptyGridRender : undefined
                  }
                  onAfterUpdate={onAfterUpdateTemplate}
                  onAfterDelete={onAfterDeleteTemplate}
                  onAfterInsert={onAfterInsertTemplate}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Authorizer>
    </>
  );
};

export default NodeSchedules;
