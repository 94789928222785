import apiClient from ".";
import { AxiosResponse } from "axios";

export const CarbonGenericApiGet = (
  endpoint: string
): Promise<AxiosResponse> => {
  const response = apiClient.get(endpoint);
  return response;
};

export const CarbonGenericApiPost = (
  endpoint: string,
  payload: any
): Promise<AxiosResponse> => {
  const response = apiClient.post(endpoint, JSON.stringify(payload));
  return response;
};

export const CarbonGenericApiPut = (
  endpoint: string,
  payload: any
): Promise<AxiosResponse> => {
  const response = apiClient.put(endpoint, JSON.stringify(payload));
  return response;
};
