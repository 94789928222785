import {
  DateTimePicker,
  DateTimePickerChangeEvent,
  TimePicker,
  TimePickerChangeEvent,
  DatePicker,
  DatePickerChangeEvent
} from "@progress/kendo-react-dateinputs";
import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { CarbonIcons, GridActions, StoreActions } from "../../../constants";
import { useGrid } from "../../../contexts/grid/useGrid";
import { CellRenderProps } from "./CellRender";
import { unlockGridIfLockedOut } from "../Utility/GridUtility";
import dayjs from "dayjs";
import { GridValidationMessagePosType } from "../../../types/grid";
import { useStore } from "../../../contexts/store";

type UnMountSaveData = {
  previousDataItem: { [key: string]: any };
  dataItem: { [key: string]: any };
  value: Date | null;
  isThisRowBeingEdited: boolean;
  saving: boolean;
};

export const DateTimeRender = ({
  originalProps,
  td,
  gridId,
  colDefinition,
  exitEdit
}: CellRenderProps) => {
  const { dispatch } = useStore();
  const { grids, setGrid } = useGrid();
  const [previousDataItem, setPreviousDataItem] = useState<{
    [key: string]: any;
  }>({});
  const [value, setValue] = React.useState<Date | null>(null);
  const [validationMsg, setValidationMsg] = useState<string | null>(null);
  const [validationPos, setValidationPos] =
    useState<GridValidationMessagePosType | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const [showPickerDropdown, setShowPickerDropdown] = useState(true);
  const [arrowNavigationParams, setArrowNavigationParams] = useState<{
    key: string;
    columnIndex: string | null | undefined;
  } | null>(null);
  const mounted = useRef(false);
  const saveData = useRef<UnMountSaveData>();

  let inputRef: HTMLSpanElement | null = null;
  const editorType: string = colDefinition?.editor
    ? colDefinition?.editor
    : "text";

  const isThisRowBeingEdited =
    grids.get(gridId)!.state.editMode &&
    !(grids.get(gridId)!.state.selectedRowIsExpanded ?? false) &&
    originalProps.dataItem[grids.get(gridId)!.dataItemKey] ===
      grids.get(gridId)!.state.selectedRow;

  // jon, 4/3/22: Save data needed for possible save on unmount since this must use a ref because useEffect below only knows values as they were on mount.
  useEffect(() => {
    saveData.current = {
      previousDataItem: previousDataItem,
      dataItem: originalProps.dataItem,
      value: value,
      isThisRowBeingEdited: isThisRowBeingEdited,
      saving: saving
    };
  }, [
    previousDataItem,
    originalProps.dataItem,
    value,
    isThisRowBeingEdited,
    saving
  ]);

  // jon, 4/3/22: Added effect to monitor if component is mounted. Save can now be initiated by scrolling off the grid, so need to check for mounted and
  //   initiate a save if value has changed.
  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;

      if (
        saveData.current &&
        saveData.current.isThisRowBeingEdited &&
        !saveData.current.saving &&
        saveData.current.previousDataItem[originalProps.field!] !== undefined &&
        saveData.current.value !==
          saveData.current.previousDataItem[originalProps.field!]
      ) {
        console.log(
          `Saving changes to date field due to control unmounting...`
        );
        setSaving(true);
        doBlur(
          null,
          null,
          saveData.current.previousDataItem,
          saveData.current.dataItem
        );
      }
    };
  }, []);

  // will, 2/16/22: Added effect to monitor if component is mounted. Mobile causes unmounting issues.
  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (inputRef) {
      const onInputKeyDown = function (e: KeyboardEvent) {
        if ((e.key === "ArrowUp" || e.key === "ArrowDown") && e.ctrlKey) {
          // console.log("Overriding ctrl-arrow key event in DateTimeRenderer");
          e.preventDefault();
          e.stopPropagation();

          const target: HTMLInputElement = e.target as HTMLInputElement;
          const columnIndex = target
            .closest("[data-grid-col-index]")
            ?.getAttribute("data-grid-col-index");

          // trigger blur to initiate save
          setArrowNavigationParams({ key: e.key, columnIndex });
        }
      };
      const inputField = inputRef.querySelector("input");
      if (inputField) {
        inputField.addEventListener("keydown", onInputKeyDown);
      }

      // Specify how to clean up after this effect:
      return function cleanup() {
        if (inputField) {
          inputField.removeEventListener("keydown", onInputKeyDown);
        }
      };
    }
  }, [inputRef]);

  useEffect(() => {
    if (arrowNavigationParams !== null) {
      // jon, 4/11/22: Set saving flag so unmount does not also fire update if input is blurred and then unmounted immediately. Can cause Unique Key violation when none exists.
      setSaving(true);
      doBlur(
        arrowNavigationParams.key,
        arrowNavigationParams.columnIndex,
        previousDataItem,
        originalProps.dataItem
      );
    }
  }, [arrowNavigationParams]);

  // Effect runs after validation msg changes. This gives us an opportunity to set the validation message position correctly relative to input.
  useEffect(() => {
    if (validationMsg !== null && inputRef !== null) {
      setValidationPos({
        posTop: inputRef.offsetTop + 393,
        posLeft: inputRef.offsetLeft + 2
      });
    }
  }, [validationMsg]);

  // Effect runs on first load and when field value changes to set the value
  useEffect(() => {
    if (isThisRowBeingEdited) {
      if (
        originalProps.dataItem[originalProps.field!] &&
        originalProps.dataItem[originalProps.field!] !== value
      ) {
        const dateVal =
          originalProps.dataItem[originalProps.field!] &&
          originalProps.dataItem[originalProps.field!] !== null
            ? dayjs(originalProps.dataItem[originalProps.field!]).toDate()
            : null;
        setValue(dateVal);
      }
    }
  }, [originalProps.dataItem[originalProps.field!]]);

  // Layout Effect runs when the grid's current edit field changes so we can see if we should set the focus if this is that field in insert mode
  useLayoutEffect(() => {
    if (
      grids.get(gridId)!.state.editFieldName === originalProps.field! &&
      grids.get(gridId)!.state.insertMode === true &&
      inputRef !== null
    ) {
      inputRef.querySelector("input")!.focus();
    }
  }, [grids.get(gridId)!.state.editFieldName]);

  const enterEdit = (
    type: string,
    dataItem: { [key: string]: any },
    field: string | undefined
  ): void => {
    // console.log(
    //   `DATETIME CELL ENTER EDIT ${colDefinition?.field} - ${type}: `,
    //   dataItem,
    //   field
    // );
    setPreviousDataItem(dataItem);
    setGrid({
      type: GridActions.editFieldName,
      payload: { gridId, gridData: field }
    });
  };

  let dateTimeFormatStr = "YYYY-MM-DD HH:mm:ss";
  if (editorType === "date") dateTimeFormatStr = "YYYY-MM-DD";
  else if (editorType === "time") dateTimeFormatStr = "HH:mm:ss";

  // jon, 1/17/22: For future me:  YES, two of these are DIVs and one is a SPAN
  let inputRefSelector = "div.k-datetimepicker";
  if (editorType === "date") inputRefSelector = "span.k-datepicker";
  else if (editorType === "time") inputRefSelector = "div.k-timepicker";

  const additionalProps = {
    ref: (tdElem: HTMLTableCellElement) => {
      inputRef = tdElem && tdElem.querySelector(inputRefSelector);
    },
    onFocus: () => {
      // Do not fire focus event if validation message showing since this resets the "previous" value to the new, incorrect one.
      if (validationMsg === null) {
        enterEdit("ON FOCUS", originalProps.dataItem, originalProps.field!);
      }
    },
    onBlur: async () => {
      // jon, 4/11/22: Set saving flag so unmount does not also fire update if input is blurred and then unmounted immediately. Can cause Unique Key violation when none exists.
      setSaving(true);
      await doBlur(null, null, previousDataItem, originalProps.dataItem);
    },
    onKeyDown: (e: KeyboardEvent) => {
      // If user hits ESC, undo any changes made to this cell and do not save
      if (e.key === "Escape") {
        setValidationPos(null);
        setValidationMsg(null);

        // unlockGridIfLockedOut(gridId, grids, setGrid);
        handleItemChange(
          dayjs(
            dayjs(previousDataItem[originalProps.field!]).format(
              dateTimeFormatStr
            )
          ).toDate(),
          true
        );
      }
    }
  };

  const doBlur = async (
    key: string | null,
    columnIndex: string | null | undefined,
    prevDataItem: { [key: string]: any },
    curDataItem: { [key: string]: any }
  ) => {
    if (validationMsg !== null) {
      // If this cell is/was invalid, unlock grid. If cell is still invalid, the exitEdit will re-lock the grid after save attempt.
      unlockGridIfLockedOut(gridId, grids, setGrid);
    }

    // jon, 1/17/22: If picker dropdown is still showing, do not try to update
    if (validationMsg !== null && showPickerDropdown === true) return;

    setValidationPos(null);
    setValidationMsg(null);
    const result = await exitEdit(
      "ON BLUR",
      originalProps.field!,
      prevDataItem,
      curDataItem,
      [originalProps.field!]
    );

    if (result !== null) {
      // will, 2/16/22: Added mount check for mobile events causing component to unmount before validation
      if (mounted.current) {
        // Validation on this field failed so set the message which fires the effects above to re-focus the input
        setValidationMsg(result);
        setShowPickerDropdown(true);
        enterEdit(
          "ON BLUR VALIDATION FAILED",
          previousDataItem,
          originalProps.field!
        );
      } else {
        handleUnmountedUpdate(result);
      }
    } else {
      setPreviousDataItem(curDataItem);

      // If this blur call came from the ctrl-arrow navigation, continue with that since the save was successful
      if (key !== null) {
        // jon, 3/23/22: I added a timeout here to allow for the save and then the navigation event since sometimes the nav would stop working afterwards.
        window.setTimeout(() => {
          setGrid({
            type: GridActions.onKeyNavigation,
            payload: {
              gridId,
              gridData: { key: key, colIndex: columnIndex }
            }
          });
        }, 1);
      }
    }

    if (mounted.current) setSaving(false);
  };

  // will, 2/16/22: Reformatted handleItemChange() without any setState functions to be usable when component is unmounted
  // and to set failure message
  const handleUnmountedUpdate = (failureMessage: string) => {
    // This sets the new value in the grid data
    const newDataItem = { ...saveData.current?.dataItem };
    newDataItem[originalProps.field!] =
      saveData.current?.previousDataItem[originalProps.field!];

    const grid = grids.get(gridId)!;

    if (grid.state.insertMode) {
      setGrid({
        type: GridActions.onInsertItemChange,
        payload: { gridId, gridData: newDataItem }
      });
    } else {
      const editedRecordID = saveData.current?.dataItem[grid.dataItemKey!];
      const records = grid.records.map((record) => {
        let newRecord = record;
        if (record[grid.dataItemKey!] === editedRecordID) {
          newRecord = newDataItem;
        }
        return newRecord;
      });

      setGrid({
        type: GridActions.onItemChange,
        payload: { gridId, gridData: records }
      });
    }

    setGrid({
      type: GridActions.toggleGridLockoutMode,
      payload: { gridId, gridData: false }
    });

    dispatch({
      type: StoreActions.addNotification,
      payload: {
        message: "Save failed with the following message:\n" + failureMessage,
        messageType: "warning",
        closable: true
      }
    });
  };

  const handleItemChange = (
    newValue: Date | null,
    resetPreviousDataItem: boolean
  ) => {
    unlockGridIfLockedOut(gridId, grids, setGrid);
    setValue(newValue);

    // This sets the new value in the grid data
    const newDataItem = { ...originalProps.dataItem };
    newDataItem[originalProps.field!] = newValue;

    // This reset is used when user clicks ESC
    if (resetPreviousDataItem) {
      setPreviousDataItem(newDataItem);
    }

    const grid = grids.get(gridId)!;

    if (grid.state.insertMode) {
      setGrid({
        type: GridActions.onInsertItemChange,
        payload: { gridId, gridData: newDataItem }
      });
    } else {
      const editedRecordID = originalProps.dataItem[grid.dataItemKey!];
      const records = grid.records.map((record) => {
        let newRecord = record;
        if (record[grid.dataItemKey!] === editedRecordID) {
          newRecord = newDataItem;
        }
        return newRecord;
      });

      setGrid({
        type: GridActions.onItemChange,
        payload: { gridId, gridData: records }
      });
    }
  };

  const onChange = async (newValue: Date | null) => {
    handleItemChange(newValue, false);
  };

  // We don't show the datetime control if the field is not editable
  const isEditable =
    colDefinition?.editable === true ||
    (colDefinition?.editableInInsertOnly === true &&
      grids.get(gridId)!.state.insertMode === true);

  const showEditControl: boolean = isThisRowBeingEdited && isEditable === true;

  if (!showEditControl)
    return React.cloneElement(
      td,
      { ...td.props, ...additionalProps },
      <>
        {originalProps.dataItem[originalProps.field!]
          ? dayjs(originalProps.dataItem[originalProps.field!]).format(
              dateTimeFormatStr
            )
          : ""}
      </>
    );

  let elem: JSX.Element | null = null;
  let elemError: JSX.Element | null = null;

  const customFormatPlaceholder = {
    year: "YYYY",
    month: "MM",
    day: "DD",
    hour: "hh",
    minute: "mm",
    second: "ss"
  };

  if (editorType === "datetime") {
    elem = (
      <DateTimePicker
        onChange={(event: DateTimePickerChangeEvent) => {
          onChange(event.value);
        }}
        value={value}
        formatPlaceholder={customFormatPlaceholder}
      />
    );
    elemError = (
      <DateTimePicker
        onChange={(event: DateTimePickerChangeEvent) => {
          setShowPickerDropdown(false);
          onChange(event.value);
        }}
        value={value}
        formatPlaceholder={customFormatPlaceholder}
        show={showPickerDropdown}
        popupSettings={{ popupClass: "grid-validation-error" }}
      />
    );
  } else if (editorType === "date") {
    elem = (
      <DatePicker
        onChange={(event: DatePickerChangeEvent) => {
          onChange(event.value);
        }}
        value={value}
        formatPlaceholder={customFormatPlaceholder}
      />
    );
    elemError = (
      <DatePicker
        onChange={(event: DatePickerChangeEvent) => {
          setShowPickerDropdown(false);
          onChange(event.value);
        }}
        value={value}
        formatPlaceholder={customFormatPlaceholder}
        show={showPickerDropdown}
        popupSettings={{ popupClass: "grid-validation-error" }}
      />
    );
  } else if (editorType === "time") {
    elem = (
      <TimePicker
        onChange={(event: TimePickerChangeEvent) => {
          onChange(event.value);
        }}
        value={value}
        formatPlaceholder={customFormatPlaceholder}
      />
    );
    elemError = (
      <TimePicker
        onChange={(event: TimePickerChangeEvent) => {
          setShowPickerDropdown(false);
          onChange(event.value);
        }}
        value={value}
        formatPlaceholder={customFormatPlaceholder}
        show={showPickerDropdown}
        popupSettings={{ popupClass: "grid-validation-error" }}
      />
    );
  }

  const validationWrapper =
    validationPos !== null && validationMsg !== null ? (
      <>
        <div
          className="validation-msg"
          style={{
            top: `${validationPos!.posTop}px`,
            left: `${validationPos!.posLeft}px`
          }}
        >
          {CarbonIcons.Warning}
          {validationMsg}
        </div>
        {elemError}
      </>
    ) : (
      <>{elem}</>
    );

  const { className, ...tdprops } = td.props;

  const finalProps = {
    ...tdprops,
    className:
      validationMsg !== null ? className + " validation-error-cell" : className
  };

  return React.cloneElement(
    td,
    { ...finalProps, ...additionalProps },
    validationWrapper
  );
};
