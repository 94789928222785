import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  {
    field: "ItemID",
    title: "ItemID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "HistoryNodeItemID",
    title: "History Node Item ID",
    width: "200px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "NodeProcessUpdateHistoryID",
    title: "Node Process Update History ID",
    width: "250px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "ItemName",
    title: "Item",
    width: "435px",
    maxLength: 50,
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "NewPrice",
    title: "Price",
    width: "310px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true
  },
  {
    field: "ItemDesc",
    title: "Label",
    width: "310px",
    maxLength: 256,
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true
  },
  {
    field: "NewCalories",
    title: "New Calories",
    width: "310px",
    maxLength: 256,
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true
  },
  {
    field: "NewDynamic1",
    title: "New Text 1",
    width: "310px",
    maxLength: 2000,
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true
  },
  {
    field: "NewDynamic2",
    title: "New Text 2",
    width: "310px",
    maxLength: 2000,
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true
  }
];
