import { Helmet } from "react-helmet-async";
import { useLocation, useParams } from "react-router-dom";
import { Box } from "grommet";

// Components
import CarbonGridToolbar, {
  GridToolbarActionLabels,
  GridToolbarItem
} from "../../components/CarbonGrid/Toolbar/CarbonGridToolbar";
import CarbonTabStrip from "../../components/CarbonTabs/CarbonTabStrip";
import CarbonGrid from "../../components/CarbonGrid/CarbonGrid";
import Authorizer from "../../components/Util/Authorizer";

// Types & Constants
import { GridType } from "../../types/grid";
import getTabs from "./TemplatesTabs";
import { gridColumns } from "./TemplateZonesGridColumns";

// User/Permissions
import usePermissions from "../../hooks/auth/usePermissions";
import {
  EndpointResources,
  Placeholders,
  UserPermissions,
  GridIDs,
  CarbonIcons,
  GridActions
} from "../../constants";
import {
  GridActionBarEvent,
  GridActionBarItem
} from "../../components/CarbonGrid/Toolbar/CarbonGridRowActionBar";
import { useGrid } from "../../contexts/grid/useGrid";
import CarbonBreadcrumb from "../../components/Breadcrumb/CarbonBreadcrumb";
import { useEffect } from "react";

const TemplateZones = (): JSX.Element => {
  const { pathname } = useLocation();
  const { templateId } = useParams();
  const { setGrid, grids } = useGrid();
  const { canInsert, canDelete } = usePermissions();
  const gridName = GridIDs.TemplateZones;
  const tabs = getTabs(EndpointResources.Templates, templateId); // parent endpoint, parameter from route defined above /*** TAB PAGE ONLY ***/
  const pagePermission = UserPermissions.TemplatesPerms;

  let newGridColumns = [
    {
      field: "TemplateID",
      title: "TemplateID",
      width: "150px",
      defaultShow: false,
      filter: "numeric",
      editable: false,
      required: false,
      canBeSaved: true,
      defaultValue: templateId,
      systemHidden: true
    },
    ...gridColumns
  ];

  // jon, 1/17/22: Set default values for HSize and VSize columns to be the size of the template itself
  // useeffect that alters the grid columns based on selected parent row values
  useEffect(() => {
    if (
      grids.get(gridName)?.state.parentSelectedRowData &&
      grids.get(gridName)!.state.parentSelectedRowData!.HSize &&
      grids.get(gridName)!.state.parentSelectedRowData!.VSize
    ) {
      // will, 2/10/22: updated to map from current columns so adjustments are preserved
      // Current column values
      newGridColumns = grids.get(gridName)!.columns!.map((column: any) => {
        const tempColumn = { ...column };
        if (tempColumn.field === "HSize") {
          tempColumn.defaultValue =
            grids.get(gridName)!.state.parentSelectedRowData!.HSize;
        } else if (tempColumn.field === "VSize") {
          tempColumn.defaultValue =
            grids.get(gridName)!.state.parentSelectedRowData!.VSize;
        }
        return tempColumn;
      });

      setGrid({
        type: GridActions.updateColumnState,
        payload: {
          gridId: gridName,
          gridData: { columns: newGridColumns }
        }
      });
    }
  }, [grids.get(gridName)?.state.parentSelectedRowData]);

  const currentTab = {
    index: tabs.findIndex((tab) => tab.resourceEndpoint === pathname),
    tab: tabs.find((tab) => tab.resourceEndpoint === pathname)
  };

  const actionLabels: GridToolbarActionLabels = {
    singular: "templatezone",
    plural: "templatezones",
    titleSingular: "TemplateZone",
    titlePlural: "TemplateZones"
  };

  const handleCopyZoneDown = (event: GridActionBarEvent) => {
    const currentData = event.grid.data;

    // get next sequence number
    const maxSeqNumObj = currentData.reduce((prev: any, current: any) => {
      return prev.SeqNum > current.SeqNum ? prev : current;
    }); // returns object with max seq number in available data

    const customValues = {
      SeqNum: maxSeqNumObj.SeqNum + 1,
      VOffset: event.rowData.VSize + event.rowData.VOffset,
      ZoneName: `${event.rowData.ZoneName} - copy`,
      ZoneDesc: `${event.rowData.ZoneDesc || ""} - copy`,
      TemplateZoneID: -1
    };

    // merge two objects. Key values from customValues will be copied over key values of event.rowData forming a new object (first param of .assign())
    const customRowValues: { [key: string]: any } = Object.assign(
      {},
      event.rowData,
      customValues
    );

    setGrid({
      type: GridActions.toggleInsertMode,
      payload: {
        gridId: GridIDs.TemplateZones,
        gridData: {
          insertOn: true,
          data: null,
          customDefaultValues: customRowValues
        }
      }
    });
  };

  const handleCopyZoneRight = (event: GridActionBarEvent) => {
    const currentData = event.grid.data;

    // get next sequence number
    const maxSeqNumObj = currentData.reduce((prev: any, current: any) => {
      return prev.SeqNum > current.SeqNum ? prev : current;
    }); // returns object with max seq number in available data

    const customValues = {
      SeqNum: maxSeqNumObj.SeqNum + 1,
      HOffset: event.rowData.HSize + event.rowData.HOffset,
      ZoneName: `${event.rowData.ZoneName} - copy`,
      ZoneDesc: `${event.rowData.ZoneDesc || ""} - copy`,
      TemplateZoneID: -1
    };

    // merge two objects. Key values from customValues will be copied over key values of event.rowData forming a new object (first param of .assign())
    const customRowValues: { [key: string]: any } = Object.assign(
      {},
      event.rowData,
      customValues
    );

    setGrid({
      type: GridActions.toggleInsertMode,
      payload: {
        gridId: GridIDs.TemplateZones,
        gridData: {
          insertOn: true,
          data: null,
          customDefaultValues: customRowValues
        }
      }
    });
  };

  const handleAddNewTemplateZone = () => {
    const currentData = grids.get(GridIDs.TemplateZones)?.data;
    let maxSeqNumObj = {} as { [key: string]: any } | undefined;

    if (currentData?.length === 0) {
      maxSeqNumObj = { SeqNum: 0 };
    } else {
      // get next sequence number
      maxSeqNumObj = currentData?.reduce((prev: any, current: any) => {
        return prev.SeqNum > current.SeqNum ? prev : current;
      }); // returns object with max seq number in available data
    }

    const customValues = {
      SeqNum: maxSeqNumObj?.SeqNum + 1 || -1
    };

    // new template zone record should have the next seqnum set by default
    const customRowValues: { [key: string]: any } = customValues;

    setGrid({
      type: GridActions.toggleInsertMode,
      payload: {
        gridId: GridIDs.TemplateZones,
        gridData: {
          insertOn: true,
          data: null,
          customDefaultValues: customRowValues
        }
      }
    });
  };

  const addBtn: GridToolbarItem = {
    show: true,
    enable: canInsert(pagePermission),
    tooltip: canInsert(pagePermission)
      ? `Add new ${actionLabels.titlePlural}`
      : `You do not have permission to add new ${actionLabels.titlePlural}.`,
    labelOverride: `Add ${actionLabels.titleSingular}`,
    onClick: () => handleAddNewTemplateZone()
  };

  const previewBtn: GridToolbarItem = {
    show: true,
    enable: true,
    tooltip: "Open the viewer to preview selected template",
    toggledOn: false
  };

  const actions: GridActionBarItem[] = [
    {
      show: true,
      label: "Copy Zone Down",
      includeInExtrasMenu: true,
      icon: CarbonIcons.CopyDown,
      onClick: handleCopyZoneDown
    },
    {
      show: true,
      label: "Copy Zone Right",
      includeInExtrasMenu: true,
      icon: CarbonIcons.CopyRight,
      onClick: handleCopyZoneRight
    }
  ];

  const gridSettings: GridType = {
    actions,
    endpoints: {
      gridODataEndpoint: `/odata/${Placeholders.companyID}/${Placeholders.templatesTabTemplateId}/gridtemplatezones?$count=true&`,
      gridApiEndpoint: `/api/${Placeholders.templatesTabTemplateId}/templatezones`,
      parentGridApiEndpoint: `/api/${Placeholders.companyID}/${EndpointResources.Templates}/${Placeholders.templatesTabTemplateId}`,
      lookupEndpoints: [
        {
          lookupField: "FilterMediaTypeName",
          endpoint: `/api/${Placeholders.companyID}/lookupmediatypes`
        },
        {
          lookupField: "FilterPackageTypeName",
          endpoint: `/api/${Placeholders.companyID}/lookuppackagetypes`
        },
        {
          lookupField: "PreviewContentMediaName",
          endpoint: `/api/${Placeholders.companyID}/lookupmedia/all`
        },
        {
          lookupField: "PreviewContentPackageName",
          endpoint: `/api/${Placeholders.companyID}/lookuppackages`
        }
      ]
    },
    records: [],
    total: 0,
    data: [],
    lookups: {},
    dataState: {
      pageSize: 25,
      take: 50,
      skip: 0,
      sort: [{ field: "SeqNum", dir: "asc" }]
    },
    dataItemKey: "TemplateZoneID",
    singularEntityName: actionLabels.singular,
    pluralEntityName: actionLabels.plural,
    columns: newGridColumns,
    showCopyBtn: false,
    showDeleteBtn: canDelete(pagePermission), // will, 7/5/22: changed form canUpdate to canDelete to align with permission properties
    parentGridId: GridIDs.Templates,
    state: {
      editMode: false,
      insertMode: false,
      selectedState: {},
      selectedRow: -1,
      showQuickFilter: false,
      lastSaveDate: null,
      showSavingIndicator: false,
      lockoutMode: false
    }
  };

  return (
    <>
      <Helmet>
        <title>Carbon | Template Zones</title>
      </Helmet>
      <Authorizer
        canView={pagePermission}
        auditLabel={`${actionLabels.plural} screen`}
      >
        <Box align="start" fill>
          <CarbonBreadcrumb
            backLinkTitle="Templates"
            backLinkUrl="/app/templates"
            pageTitle={currentTab.tab?.tabTitle ?? ""}
          />
          <CarbonGridToolbar
            addBtn={addBtn}
            gridId={gridName}
            screenIcon={CarbonIcons.TemplateZones}
            getScreenTitleFromParentRow={(rowData: { [key: string]: any }) => {
              return rowData.TemplateName;
            }}
            getScreenSubtitleFromParentRow={(rowData: {
              [key: string]: any;
            }) => {
              return rowData.TemplateDesc;
            }}
            permissions={pagePermission}
            actionLabels={actionLabels}
            previewBtn={previewBtn}
          />
          <CarbonTabStrip carbonTabs={tabs} selectedTab={currentTab.index} />{" "}
          <Box fill className="carbon-grid-wrapper">
            <CarbonGrid gridId={gridName} gridSettings={gridSettings} />
          </Box>
        </Box>
      </Authorizer>
    </>
  );
};

export default TemplateZones;
