import { Placeholders } from "../../constants";
import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  {
    field: "TextStyleName",
    title: "Text Style",
    width: "221px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    maxLength: 50,
    canBeSaved: true,
    isPartOfUniqueKey: true
  },
  {
    field: "SwfFontName",
    title: "Font",
    titlePlural: "Fonts",
    width: "226px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    valueField: "FontID",
    maxLength: 256,
    canBeSaved: false
  },
  {
    field: "TextStyleSize",
    title: "Font Size",
    width: "156px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: true,
    canBeSaved: true
  },
  {
    field: "TextColor",
    title: "Color",
    width: "173px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    maxLength: 6,
    canBeSaved: true
  },
  {
    field: "Justification",
    title: "Alignment",
    width: "115px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "dropdown",
    required: true,
    canBeSaved: true,
    defaultValue: "Left",
    dropItems: [
      {
        value: "Left",
        text: "Left"
      },
      {
        value: "Center",
        text: "Center"
      },
      {
        value: "Right",
        text: "Right"
      }
    ]
  },
  {
    field: "IsBold",
    title: "Bold",
    width: "80px",
    defaultShow: true,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true
  },
  {
    field: "AdvancedFormat",
    title: "Advanced Format",
    width: "517px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 2000,
    canBeSaved: true
  },
  {
    field: "IsItalic",
    title: "Italic",
    width: "120px",
    defaultShow: true,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true
  },
  {
    field: "FontNameInSWF",
    title: "Font Name in File",
    width: "200px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    maxLength: 50,
    canBeSaved: true
  },
  {
    field: "HtmlFontName",
    title: "HTML Font",
    titlePlural: "HTML Fonts",
    width: "200px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    valueField: "HtmlFontID",
    maxLength: 256,
    canBeSaved: false
  },
  {
    field: "BackgroundColor",
    title: "Background Color",
    width: "200px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 6,
    canBeSaved: true
  },
  {
    field: "IsUnderline",
    title: "Underline",
    width: "150px",
    defaultShow: true,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true
  },
  {
    field: "PositionLeft",
    title: "Left",
    width: "120px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: true,
    canBeSaved: true
  },
  {
    field: "PositionTop",
    title: "Top",
    width: "120px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: true,
    canBeSaved: true
  },
  {
    field: "TextStyleID",
    title: "TextStyleID",
    width: "120px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: -1,
    isPartOfUniqueKey: true
  },
  {
    field: "CompanyID",
    title: "CompanyID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    company: true,
    defaultValue: Placeholders.companyID,
    isPartOfUniqueKey: true,
    systemHidden: true
  },
  {
    field: "CompanyName",
    title: "Company",
    titlePlural: "Companies",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    editor: "lookup",
    required: false,
    valueField: "CompanyID",
    maxLength: 50,
    canBeSaved: false,
    companyMaster: true,
    defaultValue: Placeholders.companyName
  },
  {
    field: "TextStyleDesc",
    title: "Description",
    width: "350px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 256,
    canBeSaved: true
  },
  {
    field: "FontID",
    title: "FontID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "HtmlFontID",
    title: "HtmlFontID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "IsActive",
    title: "Active",
    width: "150px",
    defaultShow: false,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true,
    defaultValue: true
  }
];
