/* eslint-disable */
import {
  DropDownList,
  DropDownListChangeEvent,
  DropDownListProps
} from "@progress/kendo-react-dropdowns";
import { Box } from "grommet";
import { FC, useEffect, useState } from "react";
import {
  CarbonIcons,
  GridActions,
  GridIDs,
  StoreActions
} from "../../constants";
import { useGrid } from "../../contexts/grid/useGrid";
import apiClient from "../../api";

import {
  Toolbar,
  ToolbarItem,
  ToolbarSeparator,
  Button
} from "@progress/kendo-react-buttons";
import ConfirmationDialog from "../../components/Util/Dialogs/ConfirmationDialog";
import { useStore } from "../../contexts/store";
import useUpdateEffect from "../../hooks/utils/useUpdateEffect";
import useApi from "../../hooks/api/useApi";

interface IGlobalChangesFilterDropDown extends DropDownListProps {}

export interface IFilterItem {
  filterId: number;
  filterTitle: string;
}

const noFilterItem: IFilterItem = {
  filterId: -1,
  filterTitle: "- No Filter Selected -"
};

const GlobalChangesFilterDropDown: FC<IGlobalChangesFilterDropDown> = ({
  disabled,
  ...props
}) => {
  const { grids, setGrid } = useGrid();
  const { dispatch } = useStore();
  const grid = grids.get(GridIDs.GlobalChanges);

  const [showDeleteFilterDialog, setShowDeleteFilterDialog] =
    useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<IFilterItem>(noFilterItem);
  const [dropdownData, setDropdownData] = useState<IFilterItem[]>([
    noFilterItem
  ]);
  const [dropdownListIsOpen, setDropdownListIsOpen] = useState(false);

  useEffect(() => {
    loadFilters();
  }, []);

  const { request: loadFiltersRequest } = useApi(
    async () => await apiClient.get(`/api/${GridIDs.GlobalChanges}/userfilters`)
  );

  const loadFilters = async () => {
    // jon, 3/15/22: Change all direct apiClient calls to use the useApi hook for proper refresh token handling.
    const loadFiltersResponse = await loadFiltersRequest();
    if (loadFiltersResponse.type === "error") return;
    const response = loadFiltersResponse.value;

    const filterArray = response.data as any[];
    const dropdownValues = filterArray.map((filter) => {
      const filterItem: IFilterItem = {
        filterId: filter.FilterID,
        filterTitle: filter.FilterName
      };
      return filterItem;
    });
    const newDropdownValues = [...dropdownValues];
    // sort the array
    newDropdownValues.sort((a, b) => {
      return b.filterId - a.filterId;
    });
    const sortedValues = [noFilterItem, ...newDropdownValues];
    setDropdownData(sortedValues);
    setCurrentValue(noFilterItem);
  };

  useUpdateEffect(() => {
    // if dropdown data does not include the new activeFilterId, this means we have added a new filter
    if (
      !dropdownData.find((val) => val.filterId === grid?.state.activeFilterId)
    ) {
      loadFiltersOnUpdate();
    } else {
      // just finished editing
      setCurrentValue(
        dropdownData.find(
          (val) => val.filterId === grid?.state.activeFilterId
        ) || noFilterItem
      );
    }
  }, [grid?.state.activeFilterId]);

  const loadFiltersOnUpdate = async () => {
    // jon, 3/15/22: Change all direct apiClient calls to use the useApi hook for proper refresh token handling.
    const loadFiltersResponse = await loadFiltersRequest();
    if (loadFiltersResponse.type === "error") return;
    const response = loadFiltersResponse.value;

    const filterArray = response.data as any[];
    const dropdownValues = filterArray.map((filter) => {
      const filterItem: IFilterItem = {
        filterId: filter.FilterID,
        filterTitle: filter.FilterName
      };
      return filterItem;
    });
    const newDropdownValues = [...dropdownValues];
    // sort the array
    newDropdownValues.sort((a, b) => {
      return b.filterId - a.filterId;
    });
    const sortedValues = [noFilterItem, ...newDropdownValues];
    setDropdownData(sortedValues);
    setCurrentValue(sortedValues[1]);
    setGrid({
      type: GridActions.toggleRefreshGrid,
      payload: { gridId: GridIDs.GlobalChanges, gridData: true }
    });
  };

  const valueRender = (
    element: React.ReactElement<HTMLSpanElement>,
    value: IFilterItem
  ) => {
    if (!value) {
      return element;
    }

    const customRender = (
      <Box pad={{ left: "small" }} justify="center" align="start" fill>
        {value.filterTitle}
      </Box>
    );

    return customRender;
  };

  const onValueChange = async (event: DropDownListChangeEvent) => {
    setCurrentValue(event.value);
    setGrid({
      type: GridActions.onNodeFilterDropdownChange,
      payload: {
        gridId: GridIDs.GlobalChanges,
        gridData: {
          nodeFilterId: event.value.filterId
        }
      }
    });
    setGrid({
      type: GridActions.toggleRefreshGrid,
      payload: { gridId: GridIDs.GlobalChanges, gridData: true }
    });
  };

  const handleAddNewFilter = () => {
    setDropdownListIsOpen(false);
    setGrid({
      type: GridActions.onAddNewNodeFilter,
      payload: {
        gridId: GridIDs.GlobalChanges,
        gridData: {
          undefined
        }
      }
    });
  };

  const handleEditSelectedFilter = () => {
    setDropdownListIsOpen(false);
    setGrid({
      type: GridActions.onEditNodeFilter,
      payload: {
        gridId: GridIDs.GlobalChanges,
        gridData: {
          nodeFilterId: currentValue?.filterId
        }
      }
    });
  };

  const { request: deleteFilterRequest } = useApi(
    async (filterId: number) =>
      await apiClient.delete(`/api/-1/userfilters/${filterId}`)
  );

  const handleDeleteSelectedFilter = async () => {
    try {
      // jon, 3/15/22: Change all direct apiClient calls to use the useApi hook for proper refresh token handling.
      const deleteFilterResponse = await deleteFilterRequest(
        currentValue?.filterId ?? -1
      );
      if (deleteFilterResponse.type === "error") return;
      const response = deleteFilterResponse.value;

      if (response.status === 200) {
        setShowDeleteFilterDialog(false);
        setDropdownData(
          dropdownData.filter((val) => val.filterId !== currentValue?.filterId)
        );
        setCurrentValue(noFilterItem);
        setGrid({
          type: GridActions.onNodeFilterDropdownChange,
          payload: {
            gridId: GridIDs.GlobalChanges,
            gridData: {
              nodeFilterId: -1
            }
          }
        });
        setGrid({
          type: GridActions.toggleRefreshGrid,
          payload: { gridId: GridIDs.GlobalChanges, gridData: true }
        });
        dispatch({
          type: StoreActions.addNotification,
          payload: {
            message: `Deleted '${currentValue?.filterTitle}' successfully.`,
            messageType: "success",
            closable: false
          }
        });
      }
    } catch (error: any) {
      console.log(error.response.data);
    } finally {
      setShowDeleteFilterDialog(false);
    }
  };

  const handleDropDownListOpen = () => {
    setDropdownListIsOpen(true);
  };

  const handleDropDownListClose = () => {
    setDropdownListIsOpen(false);
  };

  return (
    <>
      {showDeleteFilterDialog && (
        <ConfirmationDialog
          bodyText={`Are you sure you want to delete this filter (${currentValue?.filterTitle}) ?`}
          onAcceptCallback={handleDeleteSelectedFilter}
          onRejectCallback={() => {
            setShowDeleteFilterDialog(false);
          }}
        />
      )}
      <Toolbar
        style={{
          padding: 0,
          height: 34
        }}
      >
        <ToolbarItem>
          <DropDownList
            onOpen={handleDropDownListOpen}
            onClose={handleDropDownListClose}
            opened={dropdownListIsOpen}
            data={dropdownData}
            value={currentValue}
            textField={"filterTitle"}
            dataItemKey={"filterId"}
            valueRender={valueRender}
            onChange={onValueChange}
            style={{
              backgroundColor: "var(--carbon-white)",
              borderRadius: 5,
              borderColor: "GrayText",
              borderWidth: "5px",
              width: "210px"
            }}
            disabled={disabled}
          />
        </ToolbarItem>
        <ToolbarSeparator />
        <ToolbarItem>
          <Button
            className="carbon-toolbar-item-btn"
            onClick={handleAddNewFilter}
            title="Add new filter"
            disabled={disabled}
          >
            {CarbonIcons.Add}
          </Button>
        </ToolbarItem>
        <ToolbarItem>
          <Button
            className="carbon-toolbar-item-btn"
            disabled={currentValue?.filterId === -1 || disabled}
            onClick={handleEditSelectedFilter}
            title="Edit selected filter"
          >
            {CarbonIcons.Edit}
          </Button>
        </ToolbarItem>
        <ToolbarItem>
          <Button
            className="carbon-toolbar-item-btn"
            style={{ marginRight: 10 }}
            onClick={() => {
              setShowDeleteFilterDialog(true);
            }}
            title="Delete selected filter"
            disabled={currentValue?.filterId === -1 || disabled}
          >
            {CarbonIcons.Delete}
          </Button>
        </ToolbarItem>
      </Toolbar>
    </>
  );
};

export default GlobalChangesFilterDropDown;
