import { GridColumns } from "../../types/grid";
import { onContentTypeChange } from "./StencilZonesCustomEvents";

export const gridColumns: GridColumns[] = [
  {
    field: "ZoneName",
    title: "Zone",
    width: "250px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    maxLength: 50,
    canBeSaved: true
  },
  {
    field: "ZoneDesc",
    title: "Description",
    width: "250px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 256,
    canBeSaved: true
  },
  {
    field: "HSize",
    title: "Width",
    width: "102px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: true,
    canBeSaved: true,
    defaultValue: 60,
    minValue: 0
  },
  {
    field: "VSize",
    title: "Height",
    width: "102px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: true,
    canBeSaved: true,
    defaultValue: 60,
    minValue: 0
  },
  {
    field: "HOffset",
    title: "Hor. Offset",
    width: "101px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: true,
    canBeSaved: true,
    defaultValue: 0,
    minValue: 0
  },
  {
    field: "VOffset",
    title: "Vert. Offset",
    width: "108px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: true,
    canBeSaved: true,
    defaultValue: 0,
    minValue: 0
  },
  {
    field: "ZIndex",
    title: "Z Index",
    width: "88px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: true,
    canBeSaved: true,
    defaultValue: 0,
    minValue: 0,
    maxValue: 999
  },
  {
    field: "Alpha",
    title: "Alpha",
    width: "78px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: true,
    canBeSaved: true,
    defaultValue: 100,
    minValue: 0,
    maxValue: 100
  },
  {
    field: "ContentType",
    title: "Content Type",
    width: "149px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "dropdown",
    required: false,
    canBeSaved: true,
    defaultValue: "T",
    dropItems: [
      {
        value: "T",
        text: "Static Text"
      },
      {
        value: "M",
        text: "Media"
      },
      {
        value: "I",
        text: "Item"
      }
    ],
    onChange: onContentTypeChange
  },
  {
    field: "ZoneContentFilter",
    title: "Content Filter",
    width: "276px",
    defaultShow: true,
    filter: "text",
    editable: false,
    editor: "combinedcolumn",
    required: false,
    canBeSaved: false
  },
  {
    field: "ItemDisplayType",
    title: "Item Display Type",
    width: "192px",
    defaultShow: true,
    filter: "text",
    editable: false,
    editor: "dropdown",
    required: false,
    canBeSaved: true,
    defaultValue: "---",
    dropItems: [
      {
        value: "---",
        text: "(None)"
      },
      {
        value: "L--",
        text: "Label"
      },
      {
        value: "P--",
        text: "Price"
      },
      {
        value: "C--",
        text: "Calories"
      },
      {
        value: "D1-",
        text: "Text 1"
      },
      {
        value: "D2-",
        text: "Text 2"
      }
    ],
    controlField: "ContentType",
    controlValue: "I"
  },
  {
    field: "TextStyleName",
    title: "Style",
    titlePlural: "Styles",
    width: "241px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: true,
    valueField: "TextStyleID",
    valueFieldInLookup: "TextStyleID",
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "ZonePreviewContent",
    title: "Content Preview",
    width: "250px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "combinedcolumn",
    required: false,
    canBeSaved: false
  },
  {
    field: "StencilZoneID",
    title: "ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: -1
  },
  // {
  //   field: "StencilID",
  //   title: "StencilID",
  //   width: "150px",
  //   defaultShow: false,
  //   filter: "numeric",
  //   editable: false,
  //   required: false,
  //   canBeSaved: true
  // },
  {
    field: "TextStyleID",
    title: "TextStyleID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "FilterMediaTypeID",
    title: "FilterMediaTypeID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "FilterMediaTypeName",
    fieldNameInLookup: "MediaTypeName",
    title: "FilterMediaTypeName",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    valueField: "FilterMediaTypeID",
    valueFieldInLookup: "MediaTypeID",
    canBeSaved: false,
    combinedCol: "ZoneContentFilter",
    controlField: "ContentType",
    controlValue: "M"
  },
  {
    field: "FilterItemTypeID",
    title: "FilterItemTypeID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "FilterItemTypeName",
    fieldNameInLookup: "ItemTypeName",
    title: "FilterItemTypeName",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    valueField: "FilterItemTypeID",
    valueFieldInLookup: "ItemTypeID",
    canBeSaved: false,
    combinedCol: "ZoneContentFilter",
    controlField: "ContentType",
    controlValue: "I"
  },
  {
    field: "PreviewContentMediaID",
    title: "PreviewContentMediaID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "PreviewContentMediaName",
    fieldNameInLookup: "MediaName",
    title: "PreviewContentMediaName",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    valueField: "PreviewContentMediaID",
    valueFieldInLookup: "MediaID",
    canBeSaved: false,
    combinedCol: "ZonePreviewContent",
    controlField: "ContentType",
    controlValue: "M",
    lookupFilterCol: "FilterMediaTypeID",
    lookupFilterDataName: "MediaTypeID"
  },
  {
    field: "PreviewContentItemID",
    title: "PreviewContentItemID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "PreviewContentItemName",
    fieldNameInLookup: "ItemName",
    title: "PreviewContentItemName",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    valueField: "PreviewContentItemID",
    valueFieldInLookup: "ItemID",
    canBeSaved: false,
    combinedCol: "ZonePreviewContent",
    controlField: "ContentType",
    controlValue: "I",
    lookupFilterCol: "FilterItemTypeID",
    lookupFilterDataName: "ItemTypeID"
  },
  {
    field: "PreviewText",
    title: "Preview Text",
    titlePlural: "Preview Texts",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 256,
    canBeSaved: true,
    combinedCol: "ZonePreviewContent",
    controlField: "ContentType",
    controlValue: "T"
  },
  {
    field: "DummyCol",
    title: "Dummy Col",
    titlePlural: "DummyCol",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    editor: "text",
    required: false,
    maxLength: 256,
    canBeSaved: false,
    combinedCol: "ZoneContentFilter",
    controlField: "ContentType",
    controlValue: "T"
  }
];
