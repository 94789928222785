import { Result, ResultError, PackagesRefreshZonesContract } from "../../types";
import useApi from "../../hooks/api/useApi";
import { RefreshZonesFromPackage } from "../../api/Packages/packagesApi";

const usePackageOps = () => {
  const {
    loading: isLoadingRefreshZones,
    request: refreshZonesFromPackageRequest
  } = useApi(RefreshZonesFromPackage);

  const refreshZonesFromPackage = async (
    payload: PackagesRefreshZonesContract
  ): Promise<Result<any>> => {
    const url = `/api/${payload.packageId}/packagezoneoperations/refreshzones?stencilID=${payload.stencilId}`;

    let result: Result<any>;
    try {
      result = await refreshZonesFromPackageRequest(url);
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }

    return result;
  };

  const isLoading = (): boolean => {
    return isLoadingRefreshZones;
  };

  return {
    refreshZonesFromPackage,
    isLoading
  };
};

export default usePackageOps;
