import { Box, Grid } from "grommet";
import { FC, useEffect, useState } from "react";
import { LookupField } from "../../components/Fields/LookupField";
import {
  GlobalChangesLookupItem,
  GlobalChangesTabProps,
  GlobalChangeType,
  GlobalOperationType
} from "./GlobalChangesToolbar";
import {
  RadioGroup,
  RadioGroupChangeEvent
} from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { DateField } from "../../components/Fields/DateField";
import { CarbonIcons, GridActions, StoreActions } from "../../constants";
import { useGrid } from "../../contexts/grid/useGrid";
import { useStore } from "../../contexts/store";
import useGlobalChangesApi from "../../hooks/globalChanges/useGlobalChangesApi";
import {
  GlobalChangesApplyChangesByMediaPackageContract,
  GlobalChangesGetAffectedCountResultContract,
  GlobalChangesGridFilterContract
} from "../../types";
import GlobalChangeApplyConfirmationDialog from "../../components/Util/Dialogs/GlobalChangeApplyConfirmationDialog";

const GlobalChangesMediaTab: FC<GlobalChangesTabProps> = (props) => {
  const LABEL_WIDTH: string = "100px";
  const LOOKUP_FIELD_WIDTH: string = "70%";
  const BUTTON_WIDTH: string = "152px";

  const { grids, setGrid } = useGrid();
  const { dispatch } = useStore();
  const { getGlobalChangesAffectedCount, applyChangesByMedia } =
    useGlobalChangesApi();
  const [criteriaBox1, setCriteriaBox1] = useState<GlobalChangesLookupItem>({
    id: null,
    name: ""
  });
  const [selectedRecords, setSelectedRecords] = useState<number>(0);
  const [operation, setOperation] = useState<GlobalOperationType>(
    GlobalOperationType.REPLACE_CONTENT
  );
  const [detailsNewValue, setDetailsNewValue] = useState<number | null>(null);
  const [detailsStartDate, setDetailsStartDate] = useState<Date | null>(null);
  const [detailsEndDate, setDetailsEndDate] = useState<Date | null>(null);
  const [detailsLookupData, setDetailsLookupData] = useState<any[]>([]);

  const [lastApplyResult, setLastApplyResult] =
    useState<GlobalChangesGetAffectedCountResultContract | null>(null);
  const [
    showApplyChangesConfirmationDialog,
    setShowApplyChangesConfirmationDialog
  ] = useState<boolean>(false);

  useEffect(() => {
    if (grids.get(props.gridId)) {
      const selectAll: boolean =
        grids.get(props.gridId)!.state.selectAllChecked ?? false;
      const pklist: number[] =
        grids.get(props.gridId)!.state.selectedPKList ?? [];

      let selectedCount = pklist.length;
      if (selectAll) {
        // When select all is checked, the total count is all records minus the ones the user has unselected. In this case, the pklist contains unselected records.
        selectedCount = grids.get(props.gridId)!.records.length - pklist.length;
      }
      setSelectedRecords(selectedCount);
    }
  }, [grids.get(props.gridId)?.state.selectedPKList]);

  const mediaLookupData: any[] =
    props.lookup1Data === null ? [] : props.lookup1Data;

  // Effect runs with criteria changes so the grid can be filtered on the auto search parameter. Must wait until after lookups load.
  useEffect(() => {
    if (
      props.lookup1Data !== null &&
      !props.autoSearchComplete &&
      props.autoSearchParams.id !== null
    ) {
      console.log("Criteria changed for auto search", criteriaBox1);

      props.setLoading(true);
      const selectedItem = {
        id: props.autoSearchParams.id,
        name:
          props.autoSearchParams.name === null
            ? ""
            : props.autoSearchParams.name
      };

      setCriteriaBox1(selectedItem);

      window.setTimeout(() => {
        handleCriteriaLookupChange(selectedItem.id, selectedItem.name);
        props.setAutoSearchComplete(true);
        props.setLoading(false);
      }, 1000);
    }
  }, [props.lookup1Data]);

  const handleCriteriaLookupChange = (value: number | null, text: string) => {
    console.log(`By Media Lookup Selection - value: ${value}, text: ${text}`);
    setCriteriaBox1({ id: value, name: text });
    props.setSelectedId(value === null ? -1 : value);
    if (value === null) {
      setOperation(GlobalOperationType.REPLACE_CONTENT);
      setDetailsNewValue(null);
      setDetailsStartDate(null);
      setDetailsEndDate(null);
      setDetailsLookupData([]);
    } else {
      // If selected value has a MediaTypeID, filter the detail lookup by that media type
      const typeID: number | null = mediaLookupData.find(
        (row) => row.MediaID === value
      )?.MediaTypeID;
      console.log(`Selected media type id: ${typeID}`);

      if (typeID === null) {
        setDetailsLookupData(mediaLookupData);
      } else {
        setDetailsLookupData(
          mediaLookupData.filter((row) => row.MediaTypeID === typeID)
        );
      }
    }
  };
  const onOperationChanged = (selectedOperation: GlobalOperationType) => {
    setOperation(selectedOperation);
    setDetailsNewValue(null);
    setDetailsStartDate(null);
    setDetailsEndDate(null);
  };
  const handleDetailsNewValueLookupChange = (
    value: number | null,
    text: string
  ) => {
    console.log(`Details new value: ${value}, text: ${text}`);
    setDetailsNewValue(value);
  };
  const handleDetailsStartDateChange = (value: Date | null) => {
    setDetailsStartDate(value);
  };

  const handleDetailsEndDateChange = (value: Date | null) => {
    setDetailsEndDate(value);
  };

  const onApplyChangesClick = async () => {
    // Confirm start date <= end date if this is an Add operation. Only need to check if both dates have a value.
    if (
      operation === GlobalOperationType.ADD_CONTENT &&
      detailsStartDate !== null &&
      detailsEndDate !== null &&
      detailsEndDate < detailsStartDate
    ) {
      dispatch({
        type: StoreActions.addNotification,
        payload: {
          message: "Start Date must be less than or equal to End Date.",
          messageType: "warning",
          closable: true
        }
      });
      return;
    }

    props.setLoading(true);
    const grid = grids.get(props.gridId)!;
    const payload: GlobalChangesGridFilterContract = {
      userFilterId: grid.state.activeFilterId ?? null,
      gridFilter: JSON.stringify(grid.dataState.filter),
      operation: operation.toString(),
      pkList: grid.state.selectedPKList?.toString() ?? "",
      selectAll: grid.state.selectAllChecked ?? false,
      changeType: GlobalChangeType.BY_MEDIA,
      selectedId: criteriaBox1.id ?? -1,
      selectedSubId: -1,
      selectedContentType: ""
    };

    const result = await getGlobalChangesAffectedCount(payload);
    props.setLoading(false);
    if (result.type === "success") {
      const responseResult = result.value;
      setLastApplyResult(responseResult.data);
      setShowApplyChangesConfirmationDialog(true);
    }
  };

  const handleApplyChangesConfirmationYes = async () => {
    setShowApplyChangesConfirmationDialog(false);

    props.setLoading(true);
    const grid = grids.get(props.gridId)!;
    const payload: GlobalChangesApplyChangesByMediaPackageContract = {
      filter: {
        userFilterId: grid.state.activeFilterId ?? null,
        gridFilter: JSON.stringify(grid.dataState.filter),
        operation: operation.toString(),
        pkList: grid.state.selectedPKList?.toString() ?? "",
        selectAll: grid.state.selectAllChecked ?? false,
        changeType: GlobalChangeType.BY_MEDIA,
        selectedId: criteriaBox1.id ?? -1,
        selectedSubId: -1,
        selectedContentType: ""
      },
      startDate: detailsStartDate,
      endDate: detailsEndDate,
      oldID: criteriaBox1.id ?? -1,
      newID: detailsNewValue
    };

    const result = await applyChangesByMedia(payload);
    props.setLoading(false);

    if (result.type === "success") {
      // Refresh grid and all controls
      setDetailsNewValue(null);
      setDetailsStartDate(null);
      setDetailsEndDate(null);
      props.setLastResultNodes(lastApplyResult!.nodes);

      setGrid({
        type: GridActions.toggleRefreshGrid,
        payload: {
          gridId: props.gridId,
          gridData: true
        }
      });

      // Show success message
      dispatch({
        type: StoreActions.addNotification,
        payload: {
          message: "Changes applied successfully",
          messageType: "success",
          closable: false
        }
      });
    } else {
      // jon, 2/8/22: 409 (conflict) errors need to be handled here since they are normally ignored and handled by the grid
      if (
        result.error.message ===
        "A record using this unique value already exists."
      ) {
        dispatch({
          type: StoreActions.addNotification,
          payload: {
            message:
              "This operation was cancelled because it would result in duplicate key data.",
            messageType: "error",
            closable: true
          }
        });
      }
    }
  };

  const handleApplyChangesConfirmationNo = () => {
    setShowApplyChangesConfirmationDialog(false);
  };

  return (
    <Grid
      className="global-change-tab"
      columns={["1/3", "220px", "auto", "188px"]}
      gap="small"
      style={{ marginBottom: "10px" }}
    >
      {showApplyChangesConfirmationDialog && (
        <GlobalChangeApplyConfirmationDialog
          applyResult={lastApplyResult!}
          isAddTemplateZone={false}
          onAcceptCallback={handleApplyChangesConfirmationYes}
          onRejectCallback={handleApplyChangesConfirmationNo}
        />
      )}
      <Box
        align="start"
        pad="small"
        className="box-with-right-border"
        style={{ paddingLeft: "20px" }}
      >
        <h2>1. Criteria</h2>
        <LookupField
          labelWidth="60px"
          required={true}
          fieldWidth={LOOKUP_FIELD_WIDTH}
          key="criteriaLookup"
          id="criteriaLookup"
          label="Content"
          lookupData={mediaLookupData}
          valueFieldInLookup="MediaID"
          textFieldInLookup="MediaName"
          defaultValue={criteriaBox1.id}
          defaultText={criteriaBox1.name}
          onChange={handleCriteriaLookupChange}
        />
        <Box>
          <p style={{ color: "var(--carbon-darkgray)", fontSize: "14px" }}>
            {selectedRecords} selected{" "}
            {selectedRecords === 1 ? "record" : "records"}
          </p>
        </Box>
      </Box>
      <Box align="start" pad="small" className="box-with-right-border">
        <h2>2. Operation</h2>
        <RadioGroup
          disabled={criteriaBox1 === null}
          data={[
            {
              label: GlobalOperationType.REPLACE_CONTENT,
              value: GlobalOperationType.REPLACE_CONTENT
            },
            {
              label: GlobalOperationType.REMOVE_CONTENT,
              value: GlobalOperationType.REMOVE_CONTENT
            },
            {
              label: GlobalOperationType.ADD_CONTENT,
              value: GlobalOperationType.ADD_CONTENT
            }
          ]}
          value={operation}
          onChange={(event: RadioGroupChangeEvent) =>
            onOperationChanged(event.value)
          }
        />
      </Box>
      <Box align="start" pad="small" className="box-with-right-border">
        <h2>3. Details</h2>
        {operation === GlobalOperationType.REPLACE_CONTENT && (
          <>
            <LookupField
              disabled={criteriaBox1 === null}
              required={true}
              labelWidth={LABEL_WIDTH}
              fieldWidth={LOOKUP_FIELD_WIDTH}
              key="detailsNewValueLookup"
              id="detailsNewValueLookup"
              label="New Content"
              lookupData={detailsLookupData}
              valueFieldInLookup="MediaID"
              textFieldInLookup="MediaName"
              defaultValue={detailsNewValue}
              defaultText=""
              onChange={handleDetailsNewValueLookupChange}
            />
            <DateField
              disabled={criteriaBox1 === null}
              labelWidth={LABEL_WIDTH}
              key="effectiveDate"
              id="effectiveDate"
              label="Effective Date"
              defaultValue={detailsStartDate}
              onChange={handleDetailsStartDateChange}
            />
          </>
        )}
        {operation === GlobalOperationType.REMOVE_CONTENT && (
          <>
            <DateField
              disabled={criteriaBox1 === null}
              labelWidth={LABEL_WIDTH}
              key="effectiveDate"
              id="effectiveDate"
              label="Effective Date"
              defaultValue={detailsStartDate}
              onChange={handleDetailsStartDateChange}
            />
          </>
        )}
        {operation === GlobalOperationType.ADD_CONTENT && (
          <>
            <LookupField
              disabled={criteriaBox1 === null}
              required={true}
              labelWidth={LABEL_WIDTH}
              fieldWidth={LOOKUP_FIELD_WIDTH}
              key="detailsNewValueLookup"
              id="detailsNewValueLookup"
              label="New Content"
              lookupData={detailsLookupData}
              valueFieldInLookup="MediaID"
              textFieldInLookup="MediaName"
              defaultValue={detailsNewValue}
              defaultText=""
              onChange={handleDetailsNewValueLookupChange}
            />
            <DateField
              disabled={criteriaBox1 === null}
              labelWidth={LABEL_WIDTH}
              key="startDate"
              id="startDate"
              label="Start Date"
              defaultValue={detailsStartDate}
              onChange={handleDetailsStartDateChange}
            />
            <DateField
              disabled={criteriaBox1 === null}
              labelWidth={LABEL_WIDTH}
              key="endDate"
              id="endDate"
              label="End Date"
              defaultValue={detailsEndDate}
              onChange={handleDetailsEndDateChange}
            />
          </>
        )}
      </Box>
      <Box align="start" pad="small">
        <div title="Apply changes to Nodes" style={{ marginBottom: "10px" }}>
          <Button
            primary={true}
            disabled={
              criteriaBox1 === null ||
              (operation !== GlobalOperationType.REMOVE_CONTENT &&
                detailsNewValue === null) ||
              selectedRecords === 0
            }
            style={{ width: BUTTON_WIDTH }}
            onClick={onApplyChangesClick}
          >
            {CarbonIcons.Done} Apply Changes
          </Button>
        </div>
        <div
          title="Restore history to Nodes affected by last operation"
          style={{ marginBottom: "10px" }}
        >
          <Button
            disabled={props.lastResultNodes === null}
            style={{ width: BUTTON_WIDTH }}
            onClick={() => props.setShowRestoreConfirmationDialog(true)}
          >
            {CarbonIcons.Restore} Revert Changes
          </Button>
        </div>
        <div title="Publish changes to Nodes affected by last operation">
          <Button
            disabled={props.lastResultNodes === null}
            style={{ width: BUTTON_WIDTH }}
            onClick={() => props.setShowPublishConfirmationDialog(true)}
          >
            {CarbonIcons.Publish} Publish Changes
          </Button>
        </div>
      </Box>
    </Grid>
  );
};

export default GlobalChangesMediaTab;
