import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { FC } from "react";
// import { Box } from "grommet";
import { CarbonIcons } from "../../../constants";

interface IStringGridProps {
  title?: string;
  bodyArray?: string[];
  okText?: string;
  dialogWidth?: string;
  dialogMaxHeight?: string;
  spaceBetweenRows?: string;
  onAcceptCallback: () => void;
}

const StringArrayGridDialog: FC<IStringGridProps> = ({
  title,
  bodyArray,
  okText,
  dialogWidth,
  dialogMaxHeight,
  spaceBetweenRows,
  onAcceptCallback
}) => {
  const CustomTitleBar = () => {
    return <h1>{title || "Contents"}</h1>;
  };

  return (
    <Dialog title={<CustomTitleBar />} closeIcon={false}>
      <div
        style={{
          width: dialogWidth || "820px",
          overflowY: "scroll",
          maxHeight: dialogMaxHeight || "600px"
        }}
      >
        {bodyArray?.map((item, i) => {
          return (
            <div
              style={{
                float: "left",
                width: spaceBetweenRows || "200px",
                paddingBottom: "4px"
              }}
              key={i}
            >
              {item}
            </div>
          );
        })}
      </div>
      <DialogActionsBar>
        <Button autoFocus={true} primary={true} onClick={onAcceptCallback}>
          {CarbonIcons.Close}
          {okText || "Close"}
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
};

export default StringArrayGridDialog;
