import React, { useEffect } from "react";
import { useRoutes, useLocation } from "react-router";
import { Grommet } from "grommet";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";
import * as Sentry from "@sentry/react";
import config from "./config";
import routes from "./routes";

// Components
import ScrollToTop from "./components/Util/ScrollToTop";

// Styles
import "./styles/app.scss";

//  Error Handling
import Fallback from "./pages/Fallback";

//  Notifications
import Notifications from "./components/Util/Notifications";

// Hooks & Constants
import { useStore } from "./contexts/store";
import useLogin from "./hooks/auth/useLogin";
import { AppLocalStore, StoreActions } from "./constants";

const customBreakpoints = {
  global: {
    breakpoints: {
      xsmall: {
        value: 500,
        edgeSize: {
          none: "0px",
          small: "12px",
          medium: "24px",
          large: "48px"
        }
      },
      small: {
        value: 900,
        edgeSize: {
          none: "0px",
          small: "12px",
          medium: "24px",
          large: "48px"
        }
      }
    }
  }
};

function App() {
  const { store, dispatch } = useStore();
  const { userLoginByToken } = useLogin();
  const { pathname } = useLocation();

  // notifications should not persist on navigate
  useEffect(() => {
    dispatch({
      type: StoreActions.removeAllNotifications,
      payload: {}
    });
  }, [pathname]);

  const refetchUserDetails = async (token: string, refreshToken: string) => {
    const result = await userLoginByToken(token, refreshToken);
    if (result.type === "success") {
      await dispatch({
        type: StoreActions.setUser,
        payload: result.value
      });
    } else {
      // token is expired, force user to re-enter credentials and login
      localStorage.removeItem(AppLocalStore.Token);
      localStorage.removeItem(AppLocalStore.RefreshToken);
      dispatch({ type: StoreActions.setUser, payload: null });
    }
  };

  useEffect(() => {
    const token = localStorage.getItem(AppLocalStore.Token);
    const refreshToken = localStorage.getItem(AppLocalStore.RefreshToken);
    if (token && refreshToken && !store.user) {
      refetchUserDetails(token, refreshToken);
    }
  }, []);

  const routing = useRoutes(
    routes(
      store.user != null || localStorage.getItem(AppLocalStore.Token) != null
    )
  );

  const errorHandler = (error: any, errorInfo: any) => {
    console.log("Logging", error, errorInfo);
  };

  const user = store.user || null;
  return (
    <SiteWrapper theme={customBreakpoints} location={pathname}>
      <Helmet>
        {pathname !== "/login" ? (
          <meta
            name="viewport"
            content="initial-scale=1.0, width=768, user-scalable=yes"
          />
        ) : (
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        )}
        <script src={"/static/js/previewer.js"} type="text/javascript" />
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${config.gaId}`}
        />
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments)}
            gtag('js', new Date());
            gtag('config', "${config.gaId}");
          `}
        </script>
      </Helmet>
      <Sentry.ErrorBoundary
        fallback={Fallback}
        showDialog
        onError={errorHandler}
        beforeCapture={(scope: Sentry.Scope): void => {
          scope.setTag("user", user?.username ?? "unknown");
          scope.setTag("company", user?.companyName ?? "unknown");
        }}
      >
        <Notifications />
        <ScrollToTop />
        {routing}
      </Sentry.ErrorBoundary>
    </SiteWrapper>
  );
}

const SiteWrapper = styled(Grommet)<{ location: string }>`
  height: ${(props) => (props.location !== "/login" ? "100%" : "auto")};
`;

export default App;
