/* eslint-disable */
import { createContext, FC, useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { DraftFunction, Updater, useImmer } from "use-immer";
import apiClient from "../api";
import {
  GridActions,
  GridIDs,
  StoreActions,
  UserPermissions
} from "../constants";
import { useGrid } from "../contexts/grid/useGrid";
import { useStore } from "../contexts/store";
import useApi from "./api/useApi";
import usePermissions from "./auth/usePermissions";
import useUpdateEffect from "./utils/useUpdateEffect";

interface IUseDayGroups {
  dayGroups: IDayGroup[];
  setDayGroups: (arg: IDayGroup[] | DraftFunction<IDayGroup[]>) => void;
  nodeDayParts: ILookupNodeDayPartDBModel[];
  handleDayPartClick: (
    clickedOnDayGroup: IDayGroup,
    clickedOnDayPart: IDayPart,
    dayGroupDayPartId: string | undefined
  ) => void;
  handleDayClick: (day: IDay, dayGroup: IDayGroup) => Promise<void>;
  handleDeleteDayPart: () => Promise<void>;
  handleDuplicateDayGroupDone: () => Promise<void>;
  handleDayPartSelectDone: () => void;
  deleteDayGroup: () => Promise<void>;
  showDeleteDayGroupDialog: boolean;
  setShowDeleteDayGroupDialog: Updater<boolean>;
  setShowSelectDayPartsDialog: Updater<boolean>;
  setShowDuplicateDayGroupDialog: Updater<boolean>;
  setShowDayPartModificationConfirmationDialog: Updater<boolean>;
  showDuplicateDayGroupDialog: boolean;
  showSelectDayPartsDialog: boolean;
  showDayPartModificationConfirmationDialog: boolean;
  dayPartsDraft: IDayPartDraft;
  duplicateDayGroupDraft: IDuplicateDayGroupDraft;
  deleteDayGroupDraft: IDeleteDayGroupDraft;
  setDayPartsDraft: Updater<IDayPartDraft>;
  setDuplicateDayGroupDraft: Updater<IDuplicateDayGroupDraft>;
  setDeleteDayGroupDraft: Updater<IDeleteDayGroupDraft>;
  setSelectedDayGroup: Updater<IDayGroup>;
  selectedDayGroup: IDayGroup;
  loading: boolean;
  generateNodeDayGroupDayPartId: (
    dayGroupSequence: number,
    dayPartId: number
  ) => string;
  refreshDayPartStatuses: (nodeID: number) => Promise<void>;
  loadInitialData: () => Promise<void>;
  activeDayPart: IDayPart;
  getDayGroupWithActiveDayPart: () => IDayGroup | undefined; // this is the day group with the active daypart
  refreshNodeDayPartsList: () => void;
  setNodeId: (nodeId: number) => void;
}

export enum DayPartStatusTextEnum {
  Empty = "Empty",
  Expired = "Expired",
  Current = "Current",
  Future = "Future"
}

export enum DayPartStatusColorEnum {
  Empty = "var(--carbon-mediumgray)",
  Expired = "var(--carbon-red)",
  Current = "var(--carbon-green)",
  Future = "var(--carbon-blue)"
}

export const WeekDayMap = new Map([
  ["Monday", "M"],
  ["Tuesday", "T"],
  ["Wednesday", "W"],
  ["Thursday", "T"],
  ["Friday", "F"],
  ["Saturday", "S"],
  ["Sunday", "S"]
]);

export interface IDay {
  title: string;
  titleKey: string;
  selected: boolean;
}

export interface IDayPart {
  id: number;
  dayGroupDayPartId?: string;
  title: string;
  isActive: boolean;
  nodeScheduleIDs: number[];
  isReal: boolean;
  status: string;
}

export interface IDayGroup {
  id: number;
  sequence: number;
  title: string;
  days: IDay[];
  dayParts: IDayPart[];
  isReal: boolean;
}

export interface IDeleteDayGroupDraft {
  lastSelectedDay?: IDay;
  newDayGroup?: IDayGroup;
  oldDayGroup?: IDayGroup;
}

export interface IDayGroupDBModel {
  DayGroupSequence: number;
  DayGroupName: string;
  NodeID: number;
  Monday: boolean;
  Tuesday: boolean;
  Wednesday: boolean;
  Thursday: boolean;
  Friday: boolean;
  Saturday: boolean;
  Sunday: boolean;
  DayParts: IDayPartDBModel[];
  DayGroupHashCode: number;
}

export interface IDayPartDBModel {
  NodeDayPartID: number;
  NodeDayPartName: string;
  NodeScheduleIDs: number[];
  Status: string;
}

export interface ILookupNodeDayPartDBModel {
  NodeDayPartID: number;
  NodeDayPartName: string;
}

export interface IDuplicateDayGroupDraft {
  sourceDayGroup?: IDayGroup;
  targetDayGroup?: IDayGroup;
}

export interface IDayPartDraft {
  dayPartsToAdd: IDayPart[];
  dayPartsToDelete: IDayPart[];
  dayGroup: IDayGroup;
}

const generateNodeDayGroupDayPartId = (
  dayGroupSequence: number,
  dayPartId: number
): string => {
  return `daygroup-${dayGroupSequence}-daypart-${dayPartId}`;
};

// empty defualt deleteDayGroupDraft
const emptyDeleteDayGroupDraft: IDeleteDayGroupDraft = {
  lastSelectedDay: undefined,
  newDayGroup: undefined,
  oldDayGroup: undefined
};

const DayGroupsContext = createContext({} as IUseDayGroups);

const DayGroupsProvider: FC = ({ children }) => {
  const { grids, setGrid } = useGrid();
  const [dayGroups, setDayGroups] = useImmer<IDayGroup[]>([]);
  const [nodeDayParts, setNodeDayParts] = useImmer<ILookupNodeDayPartDBModel[]>(
    []
  );
  const [deleteDayGroupDraft, setDeleteDayGroupDraft] =
    useImmer<IDeleteDayGroupDraft>(emptyDeleteDayGroupDraft);
  // selected day group is only set when the user clicks the '...' menu to the right of each day group's days
  const [selectedDayGroup, setSelectedDayGroup] = useImmer<IDayGroup>(
    {} as IDayGroup
  );
  const [dayPartsDraft, setDayPartsDraft] = useImmer<IDayPartDraft>(
    {} as IDayPartDraft
  );
  const [duplicateDayGroupDraft, setDuplicateDayGroupDraft] =
    useImmer<IDuplicateDayGroupDraft>({} as IDuplicateDayGroupDraft);

  /** Dialogs */
  const [showDeleteDayGroupDialog, setShowDeleteDayGroupDialog] =
    useImmer(false);

  const [showSelectDayPartsDialog, setShowSelectDayPartsDialog] =
    useImmer<boolean>(false);
  const [showDuplicateDayGroupDialog, setShowDuplicateDayGroupDialog] =
    useImmer<boolean>(false);
  const [
    showDayPartModificationConfirmationDialog,
    setShowDayPartModificationConfirmationDialog
  ] = useImmer(false);
  const [nodeId, setNodeId] = useState(0);
  const { isGranted } = usePermissions();

  /** Dialogs End */

  const { store, dispatch } = useStore();
  const [activeDayPart, setActiveDayPart] = useImmer<IDayPart>({} as IDayPart);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (
      grids.get(GridIDs.NodeTemplates)?.state.parentSelectedRowData &&
      store.user?.companyID !== undefined
    ) {
      if (
        nodeId !==
        grids.get(GridIDs.NodeTemplates)?.state.parentSelectedRowData?.NodeID
      ) {
        setNodeId(
          grids.get(GridIDs.NodeTemplates)?.state.parentSelectedRowData?.NodeID
        );
      }
    }
  }, [
    grids.get(GridIDs.NodeTemplates)?.state.parentSelectedRowData,
    store.user?.companyID
  ]);

  useEffect(() => {
    if (nodeId !== 0) {
      loadInitialData();
    }
  }, [nodeId]);

  // useEffect to trigger data fetch for nodeschedules
  useUpdateEffect(() => {
    if (grids.get(GridIDs.NodeTemplates)?.state.nodeScheduleIds) {
      // If the user just performed an insert or delete on the template records, the nodeScheduleIDs in the grid will be accurate but the
      //   the selected day part's nodeScheduleIDs will not be.  Here we will compare them and, if different, update the day part's id array.
      //   Note we order the arrays before comparison to ensure the numbers are in the same order in each array.
      const gridNodeScheduleIDs =
        [...grids!.get(GridIDs.NodeTemplates)!.state.nodeScheduleIds!].sort(
          (a: number, b: number) => a - b
        ) ?? [];
      const dayPartNodeScheduleIDs =
        activeDayPart && Object.keys(activeDayPart).length > 0
          ? [...activeDayPart.nodeScheduleIDs].sort((a, b) => a - b)
          : [];
      if (
        JSON.stringify(gridNodeScheduleIDs) !==
        JSON.stringify(dayPartNodeScheduleIDs)
      ) {
        console.log(
          `Grid NodeScheduleIDs are different from select day part's, so updating day part array. grid: ${JSON.stringify(
            gridNodeScheduleIDs
          )}, day part: ${JSON.stringify(dayPartNodeScheduleIDs)}`
        );
        /** modify the active day part to refelect the updated nodescheduleId's */
        setDayGroups((draft) => {
          // find the active daypart
          draft.forEach((val) => {
            const tempDayPart = val.dayParts.find(
              (val) => val.dayGroupDayPartId === activeDayPart.dayGroupDayPartId
            );
            if (tempDayPart) {
              tempDayPart.nodeScheduleIDs = gridNodeScheduleIDs;
            }
          });
        });
      }

      // jon, 1/16/22: I am now initializing the grid with an empty array [] so we can tell the difference between no nodescheduleids and first
      //   load when initializing the grid.  Before, two refreshes were occurring in a row, which would sometimes override the good call and set
      //   the grid to empty.  Now, the initial grid refresh will never happen and either -1 or an actual array will be set in the nodescheduleids
      //   after that, making it load data correctly.
      if (gridNodeScheduleIDs.length > 0) {
        console.log("refreshing the grid");
        setGrid({
          type: GridActions.toggleRefreshGrid,
          payload: { gridId: GridIDs.NodeTemplates, gridData: true }
        });
      }
    }
  }, [grids.get(GridIDs.NodeTemplates)?.state.nodeScheduleIds]);

  // useEffect to track the active day part
  useEffect(() => {
    try {
      console.log("the active daypart", activeDayPart);
      if (activeDayPart && Object.keys(activeDayPart).length > 0) {
        setGrid({
          type: GridActions.updateNodeScheduleIds,
          payload: {
            gridId: GridIDs.NodeTemplates,
            gridData: {
              data: activeDayPart.nodeScheduleIDs
            }
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [activeDayPart]);

  /** useEffect Section */

  /** useEffect Section End */

  const { request: dayGroupRequest } = useApi(
    async () =>
      await apiClient.get(
        `/api/${store.user?.companyID}/nodedaygroups/${
          grids.get(GridIDs.NodeTemplates)?.state.parentSelectedRowData?.NodeID
        }`
      )
  );

  const { request: nodeDayPartRequest } = useApi(
    async () =>
      await apiClient.get(
        `/api/${store.user?.companyID}/lookupnodedayparts/${
          grids.get(GridIDs.NodeTemplates)?.state.parentSelectedRowData?.NodeID
        }`
      )
  );

  const loadInitialData = async () => {
    console.log("nodeId from useDayGroups", nodeId);
    try {
      setLoading(true);
      // jon, 3/9/22: Change all direct apiClient calls to use the useApi hook for proper refresh token handling.
      const dayGroupResult = await dayGroupRequest();
      if (dayGroupResult.type === "error") return;
      const daygroupData = dayGroupResult.value.data;

      const nodeDayPartResult = await nodeDayPartRequest();
      if (nodeDayPartResult.type === "error") return;
      const nodeDayPartData = nodeDayPartResult.value.data;

      const dayGroupsFromDb = daygroupData.DayGroups as IDayGroupDBModel[];
      const dayPartsFromDb = nodeDayPartData as ILookupNodeDayPartDBModel[];

      let dayGroups: IDayGroup[] = [];

      if (dayGroupsFromDb.length === 0) {
        /**
         * New Node--this node has no day groups associated with it,
         * so this means we must create a dummy daygroup
         * so the user can add a new template. Once a new template is added,
         * the daygroup will be generated from the backend
         *
         */
        const tempDayGroup = {} as IDayGroup;
        tempDayGroup.days = [];
        // set the day group days to all be selected (default)
        WeekDayMap.forEach((val, key) => {
          tempDayGroup.days.push({
            selected: true,
            title: val,
            titleKey: key
          });
        });

        // set day parts (there should be only be one here, the defualt day part, but just in case.. run .map)
        tempDayGroup.dayParts = dayPartsFromDb.map((dayPartFromDb, index) => {
          const dayPart = {} as IDayPart;
          dayPart.id = dayPartFromDb.NodeDayPartID;
          dayPart.dayGroupDayPartId = generateNodeDayGroupDayPartId(
            1,
            dayPartFromDb.NodeDayPartID
          );
          dayPart.isActive = false;
          dayPart.title = dayPartFromDb.NodeDayPartName;
          dayPart.nodeScheduleIDs = [-1];
          dayPart.isReal = false;
          dayPart.status = DayPartStatusTextEnum.Empty;
          return dayPart;
        });

        // finally, set the day group props
        tempDayGroup.sequence = 1;
        tempDayGroup.id = -1111111;
        tempDayGroup.title = "Day Group 1";
        tempDayGroup.isReal = false;

        dayGroups.push(tempDayGroup);
      } else {
        dayGroups = dayGroupsFromDb.map((dayGroupFromDb) => {
          const tempDayGroup = {} as IDayGroup;

          tempDayGroup.days = [];
          // set the day group days
          WeekDayMap.forEach((val, key) => {
            const keyTyped = key as keyof typeof dayGroupFromDb;
            const isDaySelected = dayGroupFromDb[keyTyped] as boolean;
            tempDayGroup.days.push({
              selected: isDaySelected,
              title: val,
              titleKey: key
            });
          });

          // set day parts
          tempDayGroup.dayParts = dayGroupFromDb.DayParts.map(
            (dayPartFromDb, index) => {
              const dayPart = {} as IDayPart;
              dayPart.id = dayPartFromDb.NodeDayPartID;
              dayPart.dayGroupDayPartId = generateNodeDayGroupDayPartId(
                dayGroupFromDb.DayGroupSequence,
                dayPartFromDb.NodeDayPartID
              );
              dayPart.isActive = false;
              dayPart.title = dayPartFromDb.NodeDayPartName;
              dayPart.nodeScheduleIDs = dayPartFromDb.NodeScheduleIDs;
              dayPart.isReal = true;
              dayPart.status = dayPartFromDb.Status;
              return dayPart;
            }
          );

          // finally, set the day group props
          tempDayGroup.sequence = dayGroupFromDb.DayGroupSequence;
          tempDayGroup.id = dayGroupFromDb.DayGroupHashCode;
          tempDayGroup.title = dayGroupFromDb.DayGroupName;
          tempDayGroup.isReal = true;
          return tempDayGroup;
        });
      }

      setDayGroups(dayGroups);
      setNodeDayParts(dayPartsFromDb);
      setLoading(false);

      // Select first day part in first day group on initial load (if they exist)
      if (dayGroups && dayGroups.length > 0) {
        if (dayGroups[0].dayParts && dayGroups[0].dayParts.length > 0) {
          handleDayPartClick(
            dayGroups[0],
            dayGroups[0].dayParts[0],
            dayGroups[0].dayParts[0].dayGroupDayPartId
          );
        }
      }
    } catch (error) {
      setLoading(false);
      dispatch({
        type: StoreActions.addNotification,
        payload: {
          message: `Node day groups for node with NodeID:${nodeId} could not be loaded. (${error})`,
          messageType: "warning",
          closable: true
        }
      });
    }
  };

  const { request: lookupNodeDaypartsRequest } = useApi(
    async () =>
      await apiClient.get(
        `/api/${store.user?.companyID}/lookupnodedayparts/${
          grids.get(GridIDs.NodeTemplates)?.state.parentSelectedRowData?.NodeID
        }`
      )
  );

  const refreshNodeDayPartsList = async () => {
    // jon, 3/9/22: Change all direct apiClient calls to use the useApi hook for proper refresh token handling.
    const lookupNodeDaypartsResult = await lookupNodeDaypartsRequest();
    if (lookupNodeDaypartsResult.type === "error") return;
    const nodeDayPartData = lookupNodeDaypartsResult.value.data;

    const dayPartsFromDb = nodeDayPartData as ILookupNodeDayPartDBModel[];
    setNodeDayParts(dayPartsFromDb);
  };

  const { request: createDaypartRequest } = useApi(
    async (titleKey: string, sourceDays: string[], isReal: boolean) =>
      await apiClient.post(
        `/api/${nodeId}/nodedaygroups/create?day=${titleKey}&sourcedays=${sourceDays}&sourceexists=${isReal}`
      )
  );

  const { request: updateDaypartRequest } = useApi(
    async (
      nodeId: number,
      titleKey: string,
      targetDays: string[],
      sourceDays: string[],
      sourceExists: boolean,
      targetExists: boolean
    ) =>
      await apiClient.post(
        `/api/${nodeId}/nodedaygroups/update?day=${titleKey}&targetdays=${targetDays}&sourcedays=${sourceDays}&sourceexists=${sourceExists}&targetexists=${targetExists}`
      )
  );

  /** This medthod handles all day clicks. day param is the clicked on day, daygroup is the day group containing the day */
  const handleDayClick = async (day: IDay, dayGroup: IDayGroup) => {
    if (!isGranted(UserPermissions.CanManageNodeTemplates)) {
      return;
    }
    let targetDayGroupDayPartsData: IDayPartDBModel[] = [];
    let previousDayGroupDayPartsData: IDayPartDBModel[] = [];

    const numOfSelectedDays = dayGroup.days.reduce(
      (accumulator, day) =>
        day.selected === true ? ++accumulator : accumulator,
      0
    );
    /* if this is the single active (selected) day among the day group's days, then do nothing.
        the only way to delete this day group is to select the same day in another day group
    */
    if (numOfSelectedDays === 1 && day.selected) {
      /**
       * if we made it here, this means the user attempted to deselect the last selected day in the day group, this is an illegal move, warn the user and do nothing
       */

      return;
    }

    /**
     * if a day is already selected and the user just it deselected from a day group, we create a new daygroup with an empty template with that day
     */
    if (day.selected) {
      /**
       * if we made it this far, it means the user just deselected this day from the day group, and there are other selected days in the day group
       * create new day group in DB
       * deselect this day from this day group, and create a new day group with just this day selected
       */

      /** Make sure the day groups are all real. If the user is attempting to manipulate the day groups while there is
       *  a fake group already existing, prevent this action and warn the user
       */
      const fakeDayGroup = dayGroups.find((val) => val.isReal === false);
      if (fakeDayGroup) {
        // cheeck if user added any templates the fake dayGroup
        let templatesExist = false;
        fakeDayGroup.dayParts.forEach((dayPartVal) => {
          dayPartVal.nodeScheduleIDs.forEach((nodeScheduleIdVal) => {
            if (nodeScheduleIdVal != -1) {
              templatesExist = true;
              /** make the day group real in the UI */
              setDayGroups((draft) => {
                const draftDayGroup = draft.find(
                  (val) => val.id === fakeDayGroup.id
                );
                if (draftDayGroup) {
                  draftDayGroup.isReal = true;
                }
              });
            }
          });
        });

        if (!templatesExist) {
          /** display warning notification then return */
          dispatch({
            type: StoreActions.addNotification,
            payload: {
              message: `Please add a template to ${fakeDayGroup.title} before creating more day groups.`,
              messageType: "warning",
              closable: true
            }
          });
          return;
        }
      }

      setLoading(true);
      const sourceDays = dayGroup.days
        .filter((val) => val.selected === true)
        .map((val) => val.titleKey);

      // jon, 3/9/22: Change all direct apiClient calls to use the useApi hook for proper refresh token handling.
      const createDaypartResponse = await createDaypartRequest(
        day.titleKey,
        sourceDays,
        dayGroup.isReal
      );
      if (createDaypartResponse.type === "error") return;
      const createDaypartResult = createDaypartResponse.value;

      if (createDaypartResult.status !== 200) {
        loadInitialData();
        dispatch({
          type: StoreActions.addNotification,
          payload: {
            message: `Could not create new day group for ${day.titleKey}.)`,
            messageType: "warning",
            closable: true
          }
        });
        setLoading(false);
        return;
      }
      setLoading(false);

      const newDayPartsData: IDayPartDBModel[] =
        createDaypartResult.data.PreviousDayGroupDayParts;
      /* update the group's day part's node schedule ids 
         If the user clicks on  day (deselected a day) in day group, and that day happens to hold 
         the associated nodescheduleid in the db, this leaves our daypart's nodescheduleids obsolete
         so we must update it's schedule ids with new schedule ids
      */
      setDayGroups((draft) => {
        const currentDayGroup = draft.find((val) => val.id === dayGroup.id);
        currentDayGroup?.dayParts.forEach((dayPart) => {
          const dayPartFromDb = newDayPartsData.find(
            (dbDayPart) => dbDayPart.NodeDayPartID === dayPart.id
          );
          if (dayPartFromDb) {
            dayPart.nodeScheduleIDs = dayPartFromDb.NodeScheduleIDs;
          }
        });
      });

      setDayGroups((draft) => {
        // unselect the day among this day group
        const currentDayGroup = draft.find((val) => val.id === dayGroup.id);
        const clickedOnDay = currentDayGroup?.days.find(
          (val) => val.titleKey === day.titleKey
        );
        clickedOnDay!.selected = false;

        // create the new dummy day group, this will only turn into a real day group
        const initDays: IDay[] = [];

        const newDayGroupId = Math.floor(Math.random() * 1000); // Not from API since this day group does not exist until templates are added
        const nextDaySequence =
          Math.max(...dayGroups.map((val) => val.sequence)) + 1;

        let newSelectedDayPartID: string = "";
        const newDayParts: IDayPart[] = currentDayGroup!.dayParts.map(
          (val, index) => {
            const tempDayPart: IDayPart = {
              id: val.id,
              title: val.title,
              isActive: index === 0,
              isReal: false,
              nodeScheduleIDs: [-1],
              dayGroupDayPartId: generateNodeDayGroupDayPartId(
                nextDaySequence,
                val.id
              ),
              status: DayPartStatusTextEnum.Empty
            };

            // select the first day part
            if (index === 0) {
              newSelectedDayPartID = tempDayPart.dayGroupDayPartId!;
            }

            return tempDayPart;
          }
        );

        // We just selected the first day part in the new day group - unselect all selected ones
        draft.forEach((dayGroup: IDayGroup) => {
          dayGroup.dayParts.forEach((dayPart: IDayPart) => {
            if (dayPart.dayGroupDayPartId === newSelectedDayPartID) {
              dayPart.isActive = true;
            } else {
              dayPart.isActive = false;
            }
          });
        });

        // initialize days on this specific day selected among the group
        WeekDayMap.forEach((val, key) => {
          initDays.push({
            selected: key === day.titleKey ? true : false,
            title: val,
            titleKey: key
          });
        });

        const newDayGroup: IDayGroup = {
          id: newDayGroupId,
          title: `Day Group ${nextDaySequence}`,
          dayParts: newDayParts,
          days: initDays,
          sequence: nextDaySequence,
          isReal: false
        };

        // add new day group to draft
        draft.push(newDayGroup);

        // select the day part and trigger grid state changes
        handleDayPartClick(
          newDayGroup!,
          newDayGroup.dayParts[0],
          newDayGroup.dayParts[0].dayGroupDayPartId
        );
      });
    } else if (!day.selected) {
      /**
       * if we made it here, this means the user attempted to select a day that was not previously selected in this day group
       * so, unselect this day among any existing day groups if it's already selected, and mark it selected in this day group
       */

      let sourceDayGroup = {} as IDayGroup;
      let targetDayGroup = dayGroup;

      // find which daygroup has this day selected, that will make it the source day group
      dayGroups.forEach((dayGroupVal) => {
        dayGroupVal.days.forEach((dayVal) => {
          if (dayVal.titleKey === day.titleKey && dayVal.selected === true) {
            sourceDayGroup = dayGroupVal;
          }
        });
      });

      // take the update path if this action will NOT result in the last selected day from the source group, else, let the program flow and handle delete
      // we know that this day will eventually be deselected from the source day group, so we must ensure there is AT LEAST another selected day in the source group
      if (
        sourceDayGroup.days.filter((val) => val.selected === true).length >= 2
      ) {
        // the update path
        setLoading(true);
        // Need full set of days selected in source and target groups here so backend does not have to rebuild day groups, which can mess up data if the day groups are not "real".
        const sourceDays = sourceDayGroup.days
          .filter((val) => val.selected === true)
          .map((val) => val.titleKey);
        const targetDays = dayGroup.days
          .filter((val) => val.selected === true)
          .map((val) => val.titleKey);

        // jon, 3/9/22: Change all direct apiClient calls to use the useApi hook for proper refresh token handling.
        const updateDaypartResponse = await updateDaypartRequest(
          nodeId,
          day.titleKey,
          targetDays,
          sourceDays,
          sourceDayGroup.isReal,
          dayGroup.isReal
        );
        if (updateDaypartResponse.type === "error") return;
        const updateDaypartResult = updateDaypartResponse.value;

        if (updateDaypartResult.status !== 200) {
          loadInitialData();
          dispatch({
            type: StoreActions.addNotification,
            payload: {
              message: `Could not copy ${day.titleKey} to Day Group ${dayGroup.sequence}.`,
              messageType: "warning",
              closable: true
            }
          });
          setLoading(false);
          return;
        }
        setLoading(false);

        previousDayGroupDayPartsData =
          updateDaypartResult.data.PreviousDayGroupDayParts;
        targetDayGroupDayPartsData =
          updateDaypartResult.data.TargetDayGroupDayParts;

        setDayGroups((dayGroupsDraft) => {
          /** retrieve the draft references and update them based on our source/target groups */
          const sourceDayGroupDraft = dayGroupsDraft.find(
            (val) => val.id === sourceDayGroup.id
          );
          const targetDayGroupDraft = dayGroupsDraft.find(
            (val) => val.id === targetDayGroup.id
          );

          if (sourceDayGroupDraft?.isReal === true) {
            sourceDayGroupDraft?.dayParts.forEach((dayPartVal) => {
              const dayPartFromDb = previousDayGroupDayPartsData.find(
                (dbDayPart) => dbDayPart.NodeDayPartID === dayPartVal.id
              );
              if (dayPartFromDb) {
                dayPartVal.nodeScheduleIDs = dayPartFromDb.NodeScheduleIDs;
              }
            });
          }

          if (targetDayGroupDraft?.isReal === true) {
            targetDayGroupDraft?.dayParts.forEach((dayPartVal) => {
              const dayPartFromDb = targetDayGroupDayPartsData.find(
                (dbDayPart) => dbDayPart.NodeDayPartID === dayPartVal.id
              );
              if (dayPartFromDb) {
                dayPartVal.nodeScheduleIDs = dayPartFromDb.NodeScheduleIDs;
              }
            });
          }
        });
      }

      setDayGroups((draft) => {
        // unselect this day if it is selected in any other day group
        draft.forEach(async (draftDayGroup) => {
          if (draftDayGroup.id !== dayGroup.id) {
            const selectedDayInOtherDayGroup = draftDayGroup.days.find(
              (val) => val.titleKey == day.titleKey && val.selected
            );

            if (selectedDayInOtherDayGroup) {
              /**
               * before unselecting this day, we must check and see if it's the last day selected in this day group
               * if it is, we must prompt the user with a confirmation dialog because the day group and it's associated
               * day parts + templates (schedules) will be deleted if the user proceeds with this action
               */

              const numOfSelectedDaysTemp = draftDayGroup.days.reduce(
                (accumulator, day) =>
                  day.selected === true ? ++accumulator : accumulator,
                0
              );

              if (numOfSelectedDaysTemp > 1) {
                /* if there are more than one selected day in the group, then proceed normally */
                selectedDayInOtherDayGroup.selected = false;
                // select the day among this day group
                const currentDayGroup = draft.find(
                  (val) => val.id === dayGroup.id
                );
                const clickedOnDay = currentDayGroup?.days.find(
                  (val) => val.titleKey === day.titleKey
                );
                //make the clicked on day the selected day in the new day group
                clickedOnDay!.selected = true;
              } else {
                /** else, terminate this operation and prompt the user with a warning/confirmation message */
                setDeleteDayGroupDraft({
                  lastSelectedDay: { ...selectedDayInOtherDayGroup },
                  newDayGroup: dayGroup,
                  oldDayGroup: JSON.parse(JSON.stringify(draftDayGroup))
                });
                setShowDeleteDayGroupDialog(true);
              }
            }
          }
        });
      });
    }
  };

  const handleDayPartClick = (
    clickedOnDayGroup: IDayGroup,
    clickedOnDayPart: IDayPart,
    dayGroupDayPartId: string | undefined
  ) => {
    setActiveDayPart(clickedOnDayPart);
    setGrid({
      type: GridActions.setActiveDayGroupData,
      payload: {
        gridId: GridIDs.NodeTemplates,
        gridData: {
          selectedDayGroupName: clickedOnDayGroup.title,
          selectedDays: clickedOnDayGroup.days
            .filter((val) => val.selected === true)
            .map((val) => val.titleKey),
          activeDayPart: clickedOnDayPart
        }
      }
    });

    // make the selected day part of this day group the active day part and deactivate the rest
    setDayGroups((draft) => {
      draft.forEach((dayGroup: IDayGroup) => {
        dayGroup.dayParts.forEach((dayPart: IDayPart) => {
          if (dayPart.dayGroupDayPartId === dayGroupDayPartId) {
            dayPart.isActive = true;
          } else {
            dayPart.isActive = false;
          }
        });
      });
    });
  };

  const { request: deleteDaygroupRequest } = useApi(
    async (
      nodeId: number,
      titleKey: string,
      targetDays: string[],
      sourceDays: string[],
      sourceExists: boolean,
      targetExists: boolean
    ) =>
      await apiClient.post(
        `/api/${nodeId}/nodedaygroups/delete?day=${titleKey}&targetdays=${targetDays}&sourcedays=${sourceDays}&sourceexists=${sourceExists}&targetexists=${targetExists}`
      )
  );

  const deleteDayGroup = async () => {
    /** There must be only one selected day in this day group */
    const day = deleteDayGroupDraft.oldDayGroup?.days.find(
      (val) => val.selected === true
    );

    setLoading(true);
    // Need full set of days selected in source and target groups here so backend does not have to rebuild day groups, which can mess up data if the day groups are not "real".
    const sourceDays = deleteDayGroupDraft.oldDayGroup?.days
      .filter((val) => val.selected === true)
      .map((val) => val.titleKey);
    const targetDays = deleteDayGroupDraft.newDayGroup?.days
      .filter((val) => val.selected === true)
      .map((val) => val.titleKey);

    // jon, 3/9/22: Change all direct apiClient calls to use the useApi hook for proper refresh token handling.
    const deleteDaygroupResponse = await deleteDaygroupRequest(
      nodeId,
      day!.titleKey,
      targetDays,
      sourceDays,
      deleteDayGroupDraft.oldDayGroup?.isReal,
      deleteDayGroupDraft.newDayGroup?.isReal
    );
    if (deleteDaygroupResponse.type === "error") return;
    const deleteDaygroupResult = deleteDaygroupResponse.value;

    if (deleteDaygroupResult.status !== 200) {
      loadInitialData();
      dispatch({
        type: StoreActions.addNotification,
        payload: {
          message: `Could not delete Day Group ${
            deleteDayGroupDraft.newDayGroup!.sequence
          }. (${deleteDaygroupResult.status})`,
          messageType: "warning",
          closable: true
        }
      });
    }
    setLoading(false);

    // check and see if the active day part is part of the old day group that's about to be deleted
    const isActiveDayPartDeleted = dayGroups
      .find((val) => val.id === deleteDayGroupDraft.oldDayGroup?.id)
      ?.dayParts.find((val) => val.isActive === true);

    //remove old day group
    setDayGroups(
      dayGroups.filter((val) => val.id !== deleteDayGroupDraft.oldDayGroup?.id)
    );

    setDayGroups((draft) => {
      const newDayGroup = draft.find(
        (val) => val.id === deleteDayGroupDraft.newDayGroup?.id
      );

      const newSelectedDay = newDayGroup?.days.find(
        (val) => val.titleKey === deleteDayGroupDraft.lastSelectedDay?.titleKey
      );

      newSelectedDay!.selected = true;

      // check and see if the active day part was part of this deleted day group (old day group), if it was then set the active day part to the
      // first day part in the new day group that this selected day has moved to
      if (isActiveDayPartDeleted) {
        // set the first daypart as the active day part
        newDayGroup!.dayParts![0].isActive = true;
        setActiveDayPart(JSON.parse(JSON.stringify(newDayGroup!.dayParts![0])));
      }
    });

    setDeleteDayGroupDraft(emptyDeleteDayGroupDraft);
    setShowDeleteDayGroupDialog(false);
  };

  const { request: deleteDaypartRequest } = useApi(
    async (
      nodeId: number,
      sourceDays: string[],
      sourceExists: boolean,
      daypartId: number
    ) =>
      await apiClient.post(
        `/api/${nodeId}/nodedaygroups/deletedaypart?sourcedays=${sourceDays}&sourceexists=${sourceExists}&daypartid=${daypartId}`
      )
  );

  // delete each daypart (unselected from daypart selector) from this daygroup
  const handleDeleteDayPart = async () => {
    // Need full set of days selected in source and target groups here so backend does not have to rebuild day groups, which can mess up data if the day groups are not "real".
    const sourceDays = selectedDayGroup.days
      .filter((val) => val.selected === true)
      .map((val) => val.titleKey);

    // jon, 3/9/22: Change all direct apiClient calls to use the useApi hook for proper refresh token handling.
    dayPartsDraft.dayPartsToDelete
      .filter((val) => val?.isReal === true) // ignore fake dayparts that hold no templates yet
      .forEach(async (val) => {
        const deleteDaypartResponse = await deleteDaypartRequest(
          nodeId,
          sourceDays,
          selectedDayGroup.isReal,
          val.id
        );
        if (deleteDaypartResponse.type === "error") return;
        const deleteDaygroupResult = deleteDaypartResponse.value;
        if (deleteDaygroupResult.status !== 200) {
          dispatch({
            type: StoreActions.addNotification,
            payload: {
              message: `Day parts (${dayPartsDraft.dayPartsToDelete}) for NodeID:${nodeId} could not be deleted.`,
              messageType: "warning",
              closable: true
            }
          });
        }
      });

    try {
      setLoading(true);
      setShowDayPartModificationConfirmationDialog(false);

      // clean the sidebar UI to reflect updated data
      setDayGroups((draft) => {
        const dayGroup = draft.find((val) => val.id === selectedDayGroup.id);
        const deletedDayPartsIds = dayPartsDraft.dayPartsToDelete.map(
          (val) => val.id
        );
        if (dayGroup) {
          dayGroup.dayParts = dayGroup?.dayParts.filter(
            (val) => !deletedDayPartsIds.includes(val.id)
          );
        }
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch({
        type: StoreActions.addNotification,
        payload: {
          message: `Day parts (${dayPartsDraft.dayPartsToDelete}) for NodeID:${nodeId} could not be deleted. (${error})`,
          messageType: "warning",
          closable: true
        }
      });
      setShowDayPartModificationConfirmationDialog(false);
    }
  };

  const { request: duplicateDaygroupRequest } = useApi(
    async (
      nodeId: number,
      targetDays: string[],
      sourceDays: string[],
      isReal: boolean
    ) =>
      await apiClient.post(
        `/api/${nodeId}/nodedaygroups/duplicate?targetdays=${targetDays}&sourcedays=${sourceDays}&targetexists=${isReal}`
      )
  );

  const handleDuplicateDayGroupDone = async () => {
    const sourceDayGroup: IDayGroup =
      duplicateDayGroupDraft.sourceDayGroup || dayGroups[0];
    const targetDayGroup: IDayGroup =
      duplicateDayGroupDraft.targetDayGroup! || selectedDayGroup;

    setLoading(true);
    const sourceDays = sourceDayGroup.days
      .filter((val) => val.selected === true)
      .map((val) => val.titleKey);

    const targetDays = targetDayGroup.days
      .filter((val) => val.selected === true)
      .map((val) => val.titleKey);

    // console.log(
    //   "duplicate",
    //   `/api/${nodeId}/nodedaygroups/duplicate?targetdays=${targetDays}&sourcedays=${sourceDays}&targetexists=${targetDayGroup.isReal}`
    // );

    setShowDuplicateDayGroupDialog(false);

    // jon, 3/9/22: Change all direct apiClient calls to use the useApi hook for proper refresh token handling.
    const duplicateDaygroupResponse = await duplicateDaygroupRequest(
      nodeId,
      targetDays,
      sourceDays,
      targetDayGroup.isReal
    );
    if (duplicateDaygroupResponse.type === "error") return;
    const duplicateDaygroupResult = duplicateDaygroupResponse.value;

    try {
      if (duplicateDaygroupResult.status !== 200) {
        loadInitialData();
        dispatch({
          type: StoreActions.addNotification,
          payload: {
            message: `Could not copy schedules from ${sourceDayGroup.title} to ${targetDayGroup.title}.)`,
            messageType: "warning",
            closable: true
          }
        });
        setLoading(false);
        return;
      } else {
        // update the daygroup's day parts
        setDayGroups((draft) => {
          const dayGroup = draft.find((val) => val.id === targetDayGroup.id);
          const dayPartsFromDB: IDayPartDBModel[] =
            duplicateDaygroupResult.data.TargetDayGroupDayParts;
          if (dayGroup) {
            // jon, 3/22/22: Once templates exist in a day group, it is considered "real". This affects the server-side API operations and fixes a problem where
            //   duplicating a day group and then moving all days to it causes issues with subsequent day group creation because the source day group was considered not real
            //   and no gaps were generated on the server when creating new day groups from this one.
            dayGroup.isReal = true;
            dayGroup.dayParts = dayPartsFromDB.map((val) => {
              const tempDayPart: IDayPart = {
                title: val.NodeDayPartName,
                dayGroupDayPartId: generateNodeDayGroupDayPartId(
                  targetDayGroup.sequence,
                  val.NodeDayPartID
                ),
                id: val.NodeDayPartID,
                isActive: false,
                isReal: true,
                nodeScheduleIDs: val.NodeScheduleIDs,
                status: val.Status
              };

              return tempDayPart;
            });

            // make the first day part in this day group the active day part
            handleDayPartClick(
              dayGroup,
              dayGroup.dayParts[0],
              dayGroup.dayParts[0].dayGroupDayPartId
            );
          }
        });
        dispatch({
          type: StoreActions.addNotification,
          payload: {
            message: `Schedules from from ${sourceDayGroup.title} have been copied successfully to ${targetDayGroup.title}.`,
            messageType: "success",
            closable: false
          }
        });
      }
      setLoading(false);
    } catch (error) {
    } finally {
      setLoading(false);
      setShowDuplicateDayGroupDialog(false);
    }
  };

  const handleDayPartSelectDone = () => {
    // get the current dayGroup and compare it to the draft (selectedDayGroup)
    const dayGroup = dayGroups.find((val) => val.id === selectedDayGroup.id);
    let dayPartsToAddDraft = [] as IDayPart[];
    let dayPartsToDeleteDraft = [] as IDayPart[];

    dayGroup?.dayParts.forEach((dayPart) => {
      const tempDayPart = selectedDayGroup.dayParts.find(
        (val) => val.id === dayPart.id
      );
      // if this day part still exists in the draft (which means we didnt do anything with it), then dont do anything
      if (!tempDayPart) {
        // if this day part no longer exists in the draft, delete it from the day group
        dayPartsToDeleteDraft.push(dayPart);
      }
    });

    selectedDayGroup.dayParts.forEach((dayPart) => {
      // search through the draft for new dayparts that were added, then add them to the actual daygroup
      const tempDayPart = dayGroup?.dayParts.find(
        (val) => val.id === dayPart.id
      );
      if (!tempDayPart) {
        // if this dayPart doesnt exist in the real daygroup, then add it
        dayPartsToAddDraft.push(dayPart);
      }
    });

    setDayPartsDraft({
      dayPartsToAdd: dayPartsToAddDraft,
      dayPartsToDelete: dayPartsToDeleteDraft,
      dayGroup: selectedDayGroup
    });

    if (dayPartsToAddDraft.length) {
      /** we need to add these dummy dayparts to this day group so the user can add new templates to them */
      setDayGroups((draft) => {
        const tempSelectedDayGroup = draft.find(
          (val) => val.id === selectedDayGroup.id
        );
        if (tempSelectedDayGroup) {
          tempSelectedDayGroup.dayParts =
            tempSelectedDayGroup.dayParts.concat(dayPartsToAddDraft);
        }
      });
    }

    if (dayPartsToDeleteDraft.length)
      setShowDayPartModificationConfirmationDialog(true);
    setShowSelectDayPartsDialog(false);
  };

  const { request: getDaygroupStatusesRequest } = useApi(
    async (nodeID: number, dayGroups: IDayGroup[]) =>
      await apiClient.post(`/api/${nodeID}/nodedaygroups/getstatuses`, {
        dayGroups: dayGroups
      })
  );

  // jon, 12/15/21: Adding nodeID here as a parameter since the one from the template parent row data is unreliable and is sometimes 0, causing a 404
  //   when updating zones.
  const refreshDayPartStatuses = async (nodeID: number) => {
    setLoading(true);
    try {
      // jon, 3/9/22: Change all direct apiClient calls to use the useApi hook for proper refresh token handling.
      const getDaygroupStatusesResponse = await getDaygroupStatusesRequest(
        nodeID,
        dayGroups
      );
      if (getDaygroupStatusesResponse.type === "error") return;
      const getDaygroupStatusesResult = getDaygroupStatusesResponse.value;

      if (getDaygroupStatusesResult.status !== 200) {
        loadInitialData();
        dispatch({
          type: StoreActions.addNotification,
          payload: {
            message: `Could not day part statuses.`,
            messageType: "error",
            closable: true
          }
        });

        return;
      }

      const dayGroupsFromDB: IDayGroup[] =
        getDaygroupStatusesResult.data.dayGroups;

      // update daygroups with updated daypart statuses
      setDayGroups((draft) => {
        dayGroupsFromDB.forEach((dayGroupFromDBVal) => {
          // get the draft reference for the day group
          const tempDayGroup = draft.find(
            (val) => val.id === dayGroupFromDBVal.id
          );
          if (tempDayGroup) {
            // update this dayGroup's statuses
            dayGroupFromDBVal.dayParts.forEach((dayPartVal) => {
              const tempDayPart = tempDayGroup.dayParts.find(
                (val) => val.id === dayPartVal.id
              );
              console.log("temp day part data", JSON.stringify(tempDayPart));
              // check if this day part's last template was just deleted
              if (
                tempDayPart?.nodeScheduleIDs.length === 0 &&
                tempDayPart.status !== DayPartStatusTextEnum.Empty
              ) {
                tempDayPart.status = DayPartStatusTextEnum.Empty;
              } else if (tempDayPart && tempDayPart.isReal) {
                tempDayPart.status = dayPartVal.status;
              }

              // check if the current status and empty and the node schedule ids are populated
              // empty dayparts will always hold a -1 placeholder in their nodeScheduleIds array
              else if (
                tempDayPart?.status === DayPartStatusTextEnum.Empty &&
                tempDayPart.nodeScheduleIDs.length > 1
              ) {
                // if there are nodeSchuldeIds and the status is empty, it means the user just added a template to this daypart,
                // so mark it real and update the status
                tempDayPart.isReal = true;
                tempDayPart.status = dayPartVal.status;
                // remove the placeholder
                tempDayPart.nodeScheduleIDs =
                  tempDayPart.nodeScheduleIDs.filter((val) => val !== -1);
              }
            });
          }
        });
      });
    } catch (ex) {
      console.log("Could not update day part statuses", ex);
      dispatch({
        type: StoreActions.addNotification,
        payload: {
          message: `Could not update day part statuses.`,
          messageType: "error",
          closable: true
        }
      });
    } finally {
      setLoading(false);
    }
  };

  const getDayGroupWithActiveDayPart = (): IDayGroup | undefined => {
    let activeDayGroup: IDayGroup = {} as IDayGroup;
    dayGroups.forEach((val) => {
      const foundActiveDayPart = val.dayParts.find(
        (dayPartVal) => dayPartVal.isActive === true
      );
      if (foundActiveDayPart) {
        activeDayGroup = val;
      }
    });
    return activeDayGroup;
  };

  return (
    <DayGroupsContext.Provider
      value={{
        dayGroups,
        setDayGroups,
        activeDayPart,
        nodeDayParts,
        handleDayPartClick,
        handleDayClick,
        handleDeleteDayPart,
        handleDuplicateDayGroupDone,
        handleDayPartSelectDone,
        deleteDayGroup,
        showDeleteDayGroupDialog,
        setShowDeleteDayGroupDialog,
        setShowSelectDayPartsDialog,
        setShowDuplicateDayGroupDialog,
        setShowDayPartModificationConfirmationDialog,
        showDuplicateDayGroupDialog,
        showSelectDayPartsDialog,
        showDayPartModificationConfirmationDialog,
        dayPartsDraft,
        duplicateDayGroupDraft,
        deleteDayGroupDraft,
        setDayPartsDraft,
        setDuplicateDayGroupDraft,
        setDeleteDayGroupDraft,
        setSelectedDayGroup,
        selectedDayGroup,
        loading,
        generateNodeDayGroupDayPartId,
        refreshDayPartStatuses,
        loadInitialData,
        getDayGroupWithActiveDayPart,
        refreshNodeDayPartsList,
        setNodeId
      }}
    >
      {children}
    </DayGroupsContext.Provider>
  );
};

export default DayGroupsProvider;
export const useDayGroups = () => useContext(DayGroupsContext);
