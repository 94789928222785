import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { FC, useEffect, useState } from "react";
import { Box } from "grommet";
import {
  CarbonIcons,
  GridActions,
  GridIDs,
  Placeholders,
  StoreActions
} from "../../../constants";
import { useGrid } from "../../../contexts/grid/useGrid";
import { LookupField } from "../../Fields/LookupField";
import apiClient from "../../../api";
import { TextField } from "../../Fields/TextField";
import { useStore } from "../../../contexts/store";
import { Loader } from "@progress/kendo-react-indicators";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import DropDownField from "../../Fields/DropDownField";
import {
  Notification,
  NotificationGroup
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import useApi from "../../../hooks/api/useApi";

interface ICopyRecordDialogProps {
  title?: string;
  gridId: string;
}

const CopyRecordDialog: FC<ICopyRecordDialogProps> = ({ title, gridId }) => {
  const { grids, setGrid } = useGrid();
  const { store, dispatch } = useStore();
  const currentGrid = grids.get(gridId);
  const [uniqueColumns, setUniqueColumns] = useState([] as JSX.Element[]);
  const [nameFieldText, setNameFieldText] = useState<string>();
  const [nameFieldId, setNameFieldId] = useState<string>();
  const [companyFieldId, setCompanyFieldId] = useState<number>(
    currentGrid?.state.selectedRowData!.CompanyID
  );
  const [companyFieldText, setCompanyFieldText] = useState<string>(
    currentGrid?.state.selectedRowData!.CompanyName
  );
  const [companyTextFieldName, setCompanyTextFieldName] = useState<string>();
  const [userDefinedTypeScreenValue, setUserDefinedTypeScreenValue] = useState(
    {} as any
  );
  const [loadingFields, setLoadingFeilds] = useState<boolean>(false);
  const [loadingDuplication, setLoadingDuplication] = useState(false);
  const [errorValue, setErrorValue] = useState("");

  const { request: lookupCompaniesRequest } = useApi(
    async () => await apiClient.get("/api/-1/lookupcompanies")
  );

  const renderFields = async () => {
    setLoadingFeilds(true);
    const fieldsToRender: JSX.Element[] = [];
    const selectedRowData = currentGrid?.state.selectedRowData;

    // get unique columns
    let keyColumns = currentGrid?.columns?.filter(
      (column) => column.isPartOfUniqueKey === true
    );

    // check if company field exists as a unique key
    const companyLookup = keyColumns?.find(
      (column) => column.field === "CompanyID" || column.field === "CompanyName"
    );

    // populate company lookup field and add to fields array if company is a unique key and the current grid is not the Companies grid
    if (companyLookup && gridId !== GridIDs.Companies) {
      // jon, 3/11/22: Change all direct apiClient calls to use the useApi hook for proper refresh token handling.
      const lookupCompaniesResponse = await lookupCompaniesRequest();
      if (lookupCompaniesResponse.type === "error") return;
      const companiesLookupResponse = lookupCompaniesResponse.value;

      const lookupData = companiesLookupResponse.data.map((value: any) => {
        return {
          id: value.CompanyID,
          text: value.CompanyName
        };
      });

      fieldsToRender.push(
        <LookupField
          labelWidth="130px"
          key={`CopyCompanyLookup${gridId}`}
          id={`CopyCompanyLookup${gridId}`}
          label="Company"
          lookupData={lookupData}
          valueFieldInLookup="id"
          textFieldInLookup="text"
          // defaultValue={currentGrid?.state.selectedRowData!.CompanyID}
          defaultValue={companyFieldId!}
          defaultText={companyFieldText}
          onChange={handleOnChangeLookup}
        />
      );
    } else {
      if (gridId !== GridIDs.Roles) {
        // current grid is the companies grid, so companies field is not a lookup, but a simple text field
        fieldsToRender.push(
          <TextField
            labelWidth={"130px"}
            key={`CopyFieldCompanyName`}
            id={`CopyFieldCompanyName`}
            label={"Company"}
            defaultValue={currentGrid?.state.selectedRowData!.CompanyName}
            onChange={handleOnChangeCompanyTextField}
          />
        );
      }
    }

    // set default current row selected company id
    setCompanyFieldId(currentGrid?.state.selectedRowData!.CompanyID);

    // remove company fields from unique fields
    keyColumns = keyColumns?.filter(
      (column) => column.field !== "CompanyID" && column.field !== "CompanyName"
    );

    // keyColumns, after removing company id and company there will be only two values
    // one for the name field text, and the other for the name field id
    // current implementation only supports one unique field other than company, unless
    // specified in special cases below

    const nameFieldColumn = keyColumns?.find(
      (value) => value.defaultShow === true
    );
    if (nameFieldColumn) {
      setNameFieldText(selectedRowData![nameFieldColumn.field]);
      fieldsToRender.push(
        <TextField
          labelWidth={"130px"}
          key={`CopyField${nameFieldColumn.field}`}
          id={`CopyField${nameFieldColumn.field}`}
          label={nameFieldColumn.title}
          defaultValue={
            currentGrid?.state.selectedRowData![nameFieldColumn.field]
          }
          onChange={handleOnChangeTextField}
        />
      );
    }

    const idFieldColumn = keyColumns?.find(
      (value) => value.defaultShow === false
    );
    if (idFieldColumn) {
      setNameFieldId(selectedRowData![idFieldColumn.field]);
    }

    // Special screens, do any remaining special logic here
    switch (gridId) {
      case GridIDs.Companies: {
        // Companies Copy
        setNameFieldId(selectedRowData!.CompanyID);
        setCompanyTextFieldName(selectedRowData!.CompanyName);
        break;
      }
      case GridIDs.UserDefinedTypes: {
        // User Defined Types Copy
        const screenDropdownItems = currentGrid?.columns?.find(
          (value) => value.field === "Screen"
        )?.dropItems;

        const selectedScreenItem = screenDropdownItems?.find(
          (item) => item.value === selectedRowData!.Screen
        );

        setUserDefinedTypeScreenValue(selectedScreenItem);

        // push screen dropdown column to render
        fieldsToRender.push(
          <DropDownField
            onChange={(event) => {
              handleScreenDropDownChange(event);
            }}
            data={screenDropdownItems?.map((screen) => screen.text)}
            value={userDefinedTypeScreenValue?.text}
            defaultValue={selectedScreenItem?.text}
            label="Screen"
            labelWidth={"130px"}
          />
        );
        break;
      }
      default: {
        break;
      }
    }

    setUniqueColumns(fieldsToRender);
    setLoadingFeilds(false);
  };

  useEffect(() => {
    renderFields();
  }, []);

  // only for User Defined Types Screen
  const handleScreenDropDownChange = (event: DropDownListChangeEvent) => {
    const screenDropdownItems = currentGrid?.columns?.find(
      (value) => value.field === "Screen"
    )?.dropItems;

    const selectedScreen = screenDropdownItems?.find(
      (screen) => screen.text === event.value
    );
    setUserDefinedTypeScreenValue(selectedScreen);
  };

  // this will always be the company lookup for the current implementation
  const handleOnChangeLookup = (value: number | null, text: string) => {
    setCompanyFieldId(value!);
    setCompanyFieldText(text!);
  };

  // this will always be the unique name, for the current implementation
  const handleOnChangeTextField = (value: string) => {
    setNameFieldText(value);
  };

  // special case for companies grid
  const handleOnChangeCompanyTextField = (value: string) => {
    setCompanyTextFieldName(value);
  };

  const onCancel = () => {
    setGrid({
      type: GridActions.toggleCopyRecordDialog,
      payload: {
        gridId: gridId,
        gridData: { data: false }
      }
    });
  };

  const { request: copySaveRequest } = useApi(
    async (copyEndpoint: string, copyObj: any) =>
      await apiClient.post(copyEndpoint, copyObj)
  );

  const onSave = async () => {
    console.log("selected row data", currentGrid?.state?.selectedRowData);
    setLoadingDuplication(true);
    const companyId =
      currentGrid?.state?.selectedRowData?.CompanyID ||
      store.activeCompany!.companyId;
    const copyEndpoint = `${currentGrid?.endpoints.gridApiEndpoint?.replace(
      Placeholders.companyID,
      `${companyId}`
    )}/copy/${nameFieldId}`;

    let copyObj = {};
    // Special screens
    switch (gridId) {
      case GridIDs.Companies: {
        // Companies Copy object
        copyObj = {
          CompanyNewName: companyTextFieldName,
          CompanyCodeNewName: nameFieldText
        };

        break;
      }
      case GridIDs.UserDefinedTypes: {
        // UDTs Copy object
        copyObj = {
          CopyToCompanyID: companyFieldId,
          CopyToNewName: nameFieldText,
          CopyToScreenValue: userDefinedTypeScreenValue?.value
        };

        break;
      }
      case GridIDs.Roles: {
        // Roles Copy object
        copyObj = {
          CopyToNewName: nameFieldText
        };

        break;
      }
      default: {
        // generic copy object applicable to most screens and copies.
        copyObj = {
          CopyToCompanyID: companyFieldId,
          CopyToNewName: nameFieldText
        };

        break;
      }
    }

    try {
      // jon, 3/11/22: Change all direct apiClient calls to use the useApi hook for proper refresh token handling.
      const copySaveResponse = await copySaveRequest(copyEndpoint, copyObj);
      if (copySaveResponse.type === "error") return;
      const response = copySaveResponse.value;

      if (response.status === 200) {
        onCancel();
        setGrid({
          type: GridActions.onAfterRecordCopy,
          payload: {
            gridId: gridId,
            gridData: { data: response.data }
          }
        });
        dispatch({
          type: StoreActions.addNotification,
          payload: {
            message: "Record duplicated successfully",
            messageType: "success",
            closable: false
          }
        });
      } else {
        onCancel();
        setErrorValue(response.data);
        dispatch({
          type: StoreActions.addNotification,
          payload: {
            message: `Failed to duplicate record. ${response.data}`,
            messageType: "error",
            closable: true
          }
        });
      }
    } catch (error: any) {
      onCancel();
      setErrorValue(error.response.data);
      dispatch({
        type: StoreActions.addNotification,
        payload: {
          message: `Network Error. ${JSON.stringify(error.message)}`,
          messageType: "error",
          closable: true
        }
      });
    } finally {
      setLoadingDuplication(false);
    }
  };

  return (
    <>
      {loadingDuplication ? (
        <Dialog
          width={"600px"}
          title={
            <h1 style={{ marginTop: "30px" }}>
              {" "}
              {CarbonIcons.Copy}
              <br />
              {title || "Duplicating Record"}
            </h1>
          }
          closeIcon={false}
        >
          <Box fill justify="center" align="center">
            <Loader size="large" type="pulsing" />
          </Box>
        </Dialog>
      ) : (
        <Dialog
          width={"600px"}
          title={
            <h1 style={{ marginTop: "30px" }}>
              {" "}
              {CarbonIcons.Copy}
              <br />
              {title || "Duplicate Record"}
            </h1>
          }
          closeIcon={false}
        >
          {errorValue.length > 0 && (
            <NotificationGroup
              style={{
                alignItems: "flex-start",
                flexWrap: "wrap-reverse",
                position: "relative",
                width: "100%",
                paddingRight: 0,
                paddingBottom: 30
              }}
            >
              <Fade className={"file-upload-warning"}>
                <Notification
                  type={{ style: "warning", icon: true }}
                  closable={true}
                  style={{ width: "100%" }}
                  onClose={() => {
                    setErrorValue("");
                  }}
                >
                  <span>{errorValue}</span>
                </Notification>
              </Fade>
            </NotificationGroup>
          )}
          <Box alignSelf="center" align={"center"} justify={"center"}>
            {loadingFields ? (
              <Loader size="large" type="converging-spinner" />
            ) : (
              uniqueColumns.map((value) => {
                return value;
              })
            )}
          </Box>
          <DialogActionsBar>
            <Box direction="row" style={{ marginBottom: "30px" }}>
              <Button onClick={onCancel}>
                {CarbonIcons.Close}
                {"Cancel"}
              </Button>
              <Button
                autoFocus={true}
                primary={true}
                onClick={() => {
                  onSave();
                }}
              >
                {CarbonIcons.Check}
                {"Duplicate"}
              </Button>
            </Box>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
};

export default CopyRecordDialog;
