// base
import { FC, useState, useEffect } from "react";
import styled from "styled-components";

// Kendo/Grommet
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import {
  RadioButton,
  RadioButtonChangeEvent,
  Checkbox
} from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";

import {
  Notification,
  NotificationGroup
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Box } from "grommet";

// Custom components
import { LookupField } from "../../Fields/LookupField";
import OctopusLoader from "../OctopusLoader";

// Hooks
import useNodeCopyDayPartApi from "../../../hooks/nodes/useNodeCopyToDayPart";

// Constants
import { CarbonIcons, GridActions, GridIDs } from "../../../constants";
import { NodeCopyDayPartContract } from "../../../types";
import { useDayGroups } from "../../../hooks/useDayGroups";

// Contexts
import { useGrid } from "../../../contexts/grid/useGrid";

export interface ISectionDataProps {
  label: string;
  value: number;
}

interface INodeCopyToDayPartDialogProps {
  nodeId: number;
  companyId: number;
  nodeScheduleId: number;
  selectedDays: string;
  selectedDayPartName: string;
  selectedDayPartId: number;
  onAcceptCallback: (message: string) => void;
  onRejectCallback: () => void;
}

const NodeCopyToDayPartDialog: FC<INodeCopyToDayPartDialogProps> = ({
  nodeId,
  companyId,
  nodeScheduleId,
  selectedDays,
  selectedDayPartName,
  selectedDayPartId,
  onAcceptCallback,
  onRejectCallback
}) => {
  const { setGrid } = useGrid();
  const { getDayGroupWithActiveDayPart } = useDayGroups();
  const [dayPartData, setDayPartData] = useState<any[]>([]);
  const [errorValue, setErrorValue] = useState("");
  const [errorDetails, setErrorDetails] = useState<string[] | null>(null);
  const [destDayPartId, setDestDayPartId] = useState<number | null>(null);

  // API requirements
  const {
    getDayPartData,
    isLoadingDayPartData,
    processNodeCopyDayPart,
    isLoadingNodeCopyDayPart
  } = useNodeCopyDayPartApi();
  const [overwriteChecked, setOverwriteChecked] = useState(false);
  const [exportTypeSelect, setExportTypeSelect] = useState(-1);

  useEffect(() => {
    const dayPartsApiResult = getDayPartData(companyId, nodeId);
    dayPartsApiResult?.then((res) => {
      if (res.type === "success") {
        const response: any = res.value;
        const data: any[] = response.data;

        // filter the day parts to what the active day group already contains
        const activeDayGroup = getDayGroupWithActiveDayPart();
        setDayPartData(
          data.filter((val) =>
            activeDayGroup?.dayParts.find(
              (dayPart) => val.NodeDayPartID === dayPart.id
            )
          )
        );
      }
    });
  }, []);

  // EVENT HANDLERS
  const onSubmitButtonClick = async () => {
    if (destDayPartId === null) {
      console.log(
        "Destination day part id is null. Should not be possible since submit button was disabled!"
      );
      return;
    }
    const payload: NodeCopyDayPartContract = {
      NodeId: nodeId,
      DestDayPartId: destDayPartId,
      Overwrite: overwriteChecked,
      SelectionType: exportTypeSelect,
      NodeScheduleID: nodeScheduleId,
      SelectedDays: selectedDays,
      SelectedDayPartId: selectedDayPartId
    };

    const result = await processNodeCopyDayPart(payload);
    if (result.type === "success") {
      const resultValue: any = result.value;
      const message: string = resultValue.data;
      if (message.includes("ERROR")) {
        setErrorValue(
          message.substring(0, message.indexOf("successfully.") + 13)
        );
        setErrorDetails(
          message.substring(message.indexOf("successfully.") + 22).split("Copy")
        );
      } else {
        // will, 2/8/22: added for soft keyboard bug fix (see NodeSchedules.tsx)
        setGrid({
          type: GridActions.toggleGridResize,
          payload: {
            gridId: GridIDs.NodeTemplates,
            gridData: { data: false }
          }
        });

        onAcceptCallback(message);
      }
    }
  };

  // will, 2/8/22: added for soft keyboard bug fix (see NodeSchedules.tsx)
  const onCancelButtonClick = () => {
    setGrid({
      type: GridActions.toggleGridResize,
      payload: {
        gridId: GridIDs.NodeTemplates,
        gridData: { data: false }
      }
    });

    onRejectCallback();
  };

  const handleNodeDayPartDestChange = (value: number | null) => {
    setDestDayPartId(value);
  };

  return (
    <Dialog
      title={
        <h1 style={{ marginTop: "30px" }}>
          {CarbonIcons.Copy}
          <br />
          {"Duplicate Day Part"}
        </h1>
      }
      closeIcon={false}
      width={"40%"}
      minWidth={"400px"}
    >
      <Box style={{ position: "relative" }}>
        <Box>
          {errorValue.length > 0 && (
            <NotificationGroup
              style={{
                alignItems: "flex-start",
                flexWrap: "wrap-reverse",
                position: "relative",
                width: "100%",
                paddingRight: 0,
                paddingBottom: 10
              }}
            >
              <Fade className={"file-upload-warning"}>
                <Notification
                  type={{ style: "warning", icon: true }}
                  closable={true}
                  style={{ width: "100%" }}
                  onClose={() => {
                    setErrorValue("");
                    setErrorDetails(null);
                  }}
                >
                  <span>{errorValue}</span>
                  {errorDetails !== null && (
                    <Box
                      gap="xsmall"
                      style={{
                        height: "60px",
                        overflowY: "scroll"
                      }}
                      border="top"
                    >
                      {errorDetails.map((detail) => `Copy ${detail}`)}
                    </Box>
                  )}
                </Notification>
              </Fade>
            </NotificationGroup>
          )}
        </Box>
        {(isLoadingDayPartData || isLoadingNodeCopyDayPart) && (
          <OctopusLoader />
        )}
        <Box
          gap="small"
          border="bottom"
          pad={{ bottom: "15px" }}
          margin={{ bottom: "10px" }}
        >
          <Box pad={{ bottom: "15px" }} gap="xsmall" border="bottom">
            <Label
              style={{ fontSize: "16px", fontWeight: "bold", opacity: "0.75" }}
            >
              1. TEMPLATE
            </Label>
            <Box direction="row">
              <RadioButton
                name="typeSelectRadioGroup"
                label="Selected template record"
                value={0}
                onChange={(e: RadioButtonChangeEvent) => {
                  console.log(e.value);
                  setExportTypeSelect(e.value);
                }}
              />
            </Box>
            <Box direction="row">
              <RadioButton
                name="typeSelectRadioGroup"
                id="rb2"
                value={1}
                onChange={(e: RadioButtonChangeEvent) => {
                  console.log(e.value);
                  setExportTypeSelect(e.value);
                }}
              >
                <label
                  htmlFor="rb2"
                  className={"k-radio-label"}
                  style={{ display: "inline-block" }}
                >
                  All templates for selected day part:{" "}
                  <b>{selectedDayPartName}</b>
                </label>
              </RadioButton>
            </Box>
          </Box>
          <Label
            style={{ fontSize: "16px", fontWeight: "bold", opacity: "0.75" }}
          >
            2. DESTINATION
          </Label>
          <Box direction="row" gap="medium">
            <StyledLookupFieldWrapper>
              <LookupField
                labelWidth="0px"
                key="NodeDayPartDestSelect"
                id="NodeDayParttDestSelect"
                label=""
                lookupData={dayPartData}
                valueFieldInLookup={"NodeDayPartID"}
                textFieldInLookup={"NodeDayPartName"}
                defaultValue={-1}
                defaultText=""
                onChange={handleNodeDayPartDestChange}
                fieldWidth="100%"
              />
            </StyledLookupFieldWrapper>
          </Box>
          <Checkbox
            label="Overwrite existing templates (when copying to a different day part)"
            value={overwriteChecked}
            onChange={() => setOverwriteChecked(!overwriteChecked)}
          />
          {overwriteChecked && (
            <p>
              <strong>WARNING:</strong> This will overwrite any existing
              templates in the destination day part!
            </p>
          )}
        </Box>
        <Box margin={{ top: "10px" }}>
          <DialogActionsBar>
            <Button onClick={onCancelButtonClick}>
              {CarbonIcons.Close}
              {"Cancel"}
            </Button>
            <Button
              primary={true}
              onClick={onSubmitButtonClick}
              disabled={exportTypeSelect === -1 || destDayPartId === null}
            >
              {CarbonIcons.Check}
              {"Yes, proceed"}
            </Button>
          </DialogActionsBar>
        </Box>
      </Box>
    </Dialog>
  );
};

const StyledLookupFieldWrapper = styled(FieldWrapper)`
  margin-top: -20px;
  width: 80%;
`;

export default NodeCopyToDayPartDialog;
