import { Button } from "@progress/kendo-react-buttons";
import { Api, CarbonIcons } from "../../constants";
import { createRef, FC, useState } from "react";
import {
  ExternalDropZone,
  Upload,
  UploadFileInfo,
  UploadFileStatus,
  UploadOnAddEvent,
  UploadOnProgressEvent,
  UploadOnRemoveEvent,
  UploadOnStatusChangeEvent
} from "@progress/kendo-react-upload";
import apiClient from "../../api";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import styled from "styled-components";
import {
  Notification,
  NotificationGroup
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import { useStore } from "../../contexts/store";
import useApi from "../../hooks/api/useApi";

interface FontFilesUploadDialogueProps {
  onPostUploadFiles: (fontId: number | null) => void;
}

const FontFilesUploadDialogue: FC<FontFilesUploadDialogueProps> = ({
  onPostUploadFiles
}) => {
  const { store } = useStore();
  const [files, setFiles] = useState<Array<UploadFileInfo>>([]);
  const uploadRef = createRef<Upload>();
  const [fileUploadErrors, setFileUploadErrors] = useState([] as string[]);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [idOfFirstFileUploaded, setIdOfFirstFileUploaded] = useState<
    number | null
  >(null);

  const nameValidator = (filename: string) => {
    const pattern = /^[-._ A-Za-z0-9]+$/;
    const regexp = new RegExp(pattern);
    const result = regexp.test(filename);

    if (!result) {
      return `The file name '${filename}' is not valid. Only letters, numbers, space, period (.), underscore (_), and hyphen (-) are allowed, and the filename must contain exactly one period.`;
    } else {
      // Allow exactly one period in the filename since multiple ones mess up the extension detection
      // Using split here to divide the filename into an array on the . char. If only one . the array will have 2 elements. Anything else is invalid.
      // LEGACY COMMENT ^^
      if (filename.split(".").length !== 2) {
        return `Invalid file name '${filename}'. File name must contain only one '.'`;
      }
    }

    // valid name, continue
    return null;
  };

  const hint = <span>Drag and Drop Files Here</span>;

  const handleUploadStatusChange = (event: UploadOnStatusChangeEvent) => {
    setFiles(event.newState);
    let doneUploading = true;
    let errorUploading = false;
    for (const file of event.newState) {
      if (file.status === UploadFileStatus.Uploaded) {
        // Set the font id of the first file successfully uploaded so we can use it later (in onPostUploadFiles) to filter the grid post-insert.
        const fontId = parseInt(event.response?.response, 10);
        if (idOfFirstFileUploaded === null || fontId < idOfFirstFileUploaded)
          setIdOfFirstFileUploaded(fontId);
      } else {
        doneUploading = false;
      }
      if (file.status === UploadFileStatus.UploadFailed) {
        errorUploading = true;
      }
    }
    if (doneUploading || errorUploading) {
      setFileUploadErrors([]);
      setUploadInProgress(false);
    }
  };

  const handleOnRemove = (event: UploadOnRemoveEvent) => {
    setFiles(event.newState);
  };

  const { request: getFileExistsRequest } = useApi(
    async (filename: string) =>
      await apiClient.get(
        `${Api.baseUrl}/api/${store.activeCompany?.companyId}/files/font-file-exists?fileName=${filename}`
      )
  );

  const handleOnAdd = async (event: UploadOnAddEvent) => {
    const warningsArray: string[] = [];
    let filteredNewState: UploadFileInfo[] = event.newState;

    for (const file of event.affectedFiles) {
      const nameValidatorResult = nameValidator(file.name);
      if (nameValidatorResult == null) {
        // jon, 3/14/22: Change all direct apiClient calls to use the useApi hook for proper refresh token handling.
        const getFileExistsResponse = await getFileExistsRequest(file.name);
        if (getFileExistsResponse.type === "error") return;
        const result = getFileExistsResponse.value;

        if (result.data === true) {
          warningsArray.push(
            `The file '${file.name}' already exists on the server and will be overwritten if it is not removed from the list below.`
          );
        }
      } else {
        filteredNewState = filteredNewState.filter(
          (item) => item.name !== file.name
        );
        warningsArray.push(nameValidatorResult);
      }
    }

    setFileUploadErrors([...fileUploadErrors, ...warningsArray]);
    setFiles(filteredNewState);
  };

  const handleOnProgress = (event: UploadOnProgressEvent) => {
    setFiles(event.newState);
    setUploadInProgress(true);
  };

  const handleOnBeforeUpload = () => {
    setFileUploadErrors([]);
  };

  const uploadZoneCleanup = () => {
    onPostUploadFiles(idOfFirstFileUploaded);
    setFileUploadErrors([]);
    setFiles([]);
  };

  return (
    <Dialog
      title={"Upload Fonts"}
      onClose={() => {
        uploadZoneCleanup();
      }}
      closeIcon={false}
      width={800}
      height={800}
    >
      <div>
        <StyledDropzone
          uploadRef={uploadRef}
          customHint={hint}
          customNote={<span />}
          style={{
            backgroundColor: "var(--carbon-lightblue)"
          }}
        />
        {fileUploadErrors.length > 0 && (
          <NotificationGroup
            style={{
              alignItems: "flex-start",
              flexWrap: "wrap-reverse",
              position: "relative",
              width: "100%",
              paddingRight: 0
            }}
          >
            <Fade className={"file-upload-warning"}>
              {fileUploadErrors.map((item, index) => {
                return (
                  <Notification
                    type={{ style: "warning", icon: true }}
                    closable={true}
                    onClose={() => {
                      const newArray = fileUploadErrors;
                      // delete item
                      const newArrayState = newArray.filter(
                        (value, theIndex) => {
                          return index !== theIndex;
                        }
                      );
                      console.log(newArrayState);
                      setFileUploadErrors(newArrayState);
                    }}
                    style={{ width: "100%" }}
                    key={index}
                  >
                    <span>{item}</span>
                  </Notification>
                );
              })}
            </Fade>
          </NotificationGroup>
        )}
        <Upload
          restrictions={{}}
          onProgress={handleOnProgress}
          onStatusChange={handleUploadStatusChange}
          onAdd={handleOnAdd}
          onRemove={handleOnRemove}
          onBeforeUpload={handleOnBeforeUpload}
          autoUpload={false}
          ref={uploadRef}
          batch={false}
          multiple={true}
          withCredentials={false}
          files={files}
          saveHeaders={{
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }}
          saveUrl={`${Api.baseUrl}/api/${store.activeCompany?.companyId}/files/fonts`}
        />
      </div>
      <DialogActionsBar>
        <Button disabled={uploadInProgress} onClick={() => uploadZoneCleanup()}>
          {CarbonIcons.Close}
          {"Close"}
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
};

const StyledDropzone = styled(ExternalDropZone)`
  height: 150px;
  margin-bottom: 10px;
`;

export default FontFilesUploadDialogue;
