import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  {
    field: "TemplateName",
    title: "Template",
    titlePlural: "Templates",
    width: "435px",
    defaultShow: true,
    filter: "text",
    editable: false,
    editableInInsertOnly: true,
    editor: "lookup",
    required: true,
    valueField: "TemplateID",
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "DisplayOrder",
    title: "Display",
    titlePlural: "Displays",
    width: "155px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "lookup",
    required: true,
    valueField: "NodeDisplayID",
    canBeSaved: false
  },
  {
    field: "ContentStatus",
    title: "Status",
    width: "170px",
    defaultShow: true,
    customFilter: "status",
    filterable: true,
    editable: false,
    editor: "status",
    required: false,
    canBeSaved: false,
    defaultValue: "reddot.gif",
    statuses: [
      {
        value: "greendot.gif",
        color: "var(--carbon-green)",
        text: "Current"
      },
      {
        value: "bluedot.gif",
        color: "var(--carbon-blue)",
        text: "Future"
      },
      {
        value: "reddot.gif",
        color: "var(--carbon-red)",
        text: "Expired"
      }
    ]
  },
  {
    field: "ZIndex",
    title: "Z Index",
    width: "125px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: true,
    canBeSaved: true,
    defaultValue: 0
  },
  {
    field: "HorizontalOffset",
    title: "Horz. Offset",
    width: "150px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: true,
    canBeSaved: true,
    defaultValue: 0,
    minValue: 0
  },
  {
    field: "StartDate",
    title: "Start",
    width: "200px",
    defaultShow: true,
    filter: "date",
    editable: true,
    editor: "date",
    required: false,
    canBeSaved: true
  },
  {
    field: "EndDate",
    title: "End",
    width: "200px",
    defaultShow: true,
    filter: "date",
    editable: true,
    editor: "date",
    required: false,
    canBeSaved: true
  },
  {
    field: "NodeScheduleID",
    title: "Node Schedule ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: -1
  },
  // {
  //   field: "NodeID",
  //   title: "NodeID",
  //   width: "150px",
  //   defaultShow: false,
  //   filter: "numeric",
  //   editable: false,
  //   required: false,
  //   canBeSaved: true,
  //   defaultValue: "[grdNodes.NodeID]"
  // },
  {
    field: "ScheduleWkDay",
    title: "Day",
    width: "150px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "dropdown",
    required: true,
    canBeSaved: true,
    dropItems: [
      {
        value: "Sunday",
        text: "Sunday"
      },
      {
        value: "Monday",
        text: "Monday"
      },
      {
        value: "Tuesday",
        text: "Tuesday"
      },
      {
        value: "Wednesday",
        text: "Wednesday"
      },
      {
        value: "Thursday",
        text: "Thursday"
      },
      {
        value: "Friday",
        text: "Friday"
      },
      {
        value: "Saturday",
        text: "Saturday"
      }
    ]
  },
  {
    field: "ScheduleDate",
    title: "Date",
    width: "150px",
    defaultShow: false,
    filter: "date",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "NodeDayPartID",
    title: "Node Day Part ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "NodeDayPartName",
    title: "Day Part",
    titlePlural: "Day Parts",
    width: "200px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: true,
    valueField: "NodeDayPartID",
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "TemplateID",
    title: "Template ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "NodeDisplayID",
    title: "Node Display ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "OffsetMinutes",
    title: "Offset Time",
    width: "120px",
    defaultShow: false,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: false,
    canBeSaved: true,
    defaultValue: 0,
    minValue: 0
  },
  {
    field: "StartDate_Sort",
    title: "StartDate Sort",
    width: "250px",
    defaultShow: false,
    filter: "date",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "EndDate_Sort",
    title: "EndDate Sort",
    width: "250px",
    defaultShow: false,
    filter: "date",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "ContentStatusMessage",
    title: "Content Status Message",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  }
];
