import apiClient from "..";
import { useParams } from "react-router-dom";
import { GridGetLookupDataContract, Result, ResultError } from "../../types";
import { AxiosResponse } from "axios";
import useApi from "../../hooks/api/useApi";
import { useStore } from "../../contexts/store";
import { Placeholders } from "../../constants";

const GetLookupDataApi = () => {
  const { store } = useStore();
  const {
    nodeId,
    userId,
    roleId,
    patchId,
    typeId,
    templateId,
    stencilId,
    mediaId,
    packageId,
    priceScheduleId
  } = useParams();

  const fetchDataGet = (
    getLookupDataContract: GridGetLookupDataContract
  ): Promise<AxiosResponse> => {
    let endpoint = `${getLookupDataContract.endpoint.replace(
      Placeholders.companyID,
      `${store.activeCompany!.companyId}`
    )}`;

    // Tab Data - Tabbed screens use route data in API call
    endpoint = endpoint.replace(Placeholders.nodeTabNodeId, `${nodeId}`);
    endpoint = endpoint.replace(Placeholders.userTabUserId, `${userId}`);
    endpoint = endpoint.replace(Placeholders.roleTabRoleId, `${roleId}`);
    endpoint = endpoint.replace(Placeholders.patchTabPatchId, `${patchId}`);
    endpoint = endpoint.replace(
      Placeholders.userDefinedTypesTabUdtId,
      `${typeId}`
    );
    endpoint = endpoint.replace(
      Placeholders.templatesTabTemplateId,
      `${templateId}`
    );
    endpoint = endpoint.replace(
      Placeholders.stencilsTabStencilId,
      `${stencilId}`
    );
    endpoint = endpoint.replace(Placeholders.mediaTabMediaId, `${mediaId}`);
    endpoint = endpoint.replace(
      Placeholders.packagesTabPackageId,
      `${packageId}`
    );
    endpoint = endpoint.replace(
      Placeholders.priceSchedulesTabPriceScheduleId,
      `${priceScheduleId}`
    );

    console.log(
      `GET GRID LOOKUP DATA for ${getLookupDataContract.lookupField}: ${endpoint}`
    );
    const response = apiClient.get(endpoint);
    return response;
  };

  const { loading: isLoading, request: gridRequest } = useApi(fetchDataGet);

  const getGridLookupData = async (
    getLookupDataContract: GridGetLookupDataContract
  ): Promise<Result<string>> => {
    let result: Result<string>;
    try {
      result = await gridRequest(getLookupDataContract);
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }
    return result;
  };

  return {
    getGridLookupData,
    isLoading
  };
};

export default GetLookupDataApi;
