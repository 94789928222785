import {
  NumericTextBox,
  NumericTextBoxChangeEvent
} from "@progress/kendo-react-inputs";
import React, { useEffect, useState } from "react";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Label } from "@progress/kendo-react-labels";
import styled from "styled-components";

export interface INumberFieldProps {
  id: string;
  label: string;
  labelWidth?: string;
  defaultValue: number | null;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  minValue?: number;
  maxValue?: number;
  onChange: (value: number | null) => void;
}

export const NumberField = (props: INumberFieldProps) => {
  const [selectedValue, setSelectedValue] = useState<number | null>(
    props.defaultValue
  );
  const labelWidth = props.labelWidth ?? "100px";
  const disabled = props.disabled ?? false;
  const required = props.required ?? false;
  const placeholder = props.placeholder ?? "";

  // Effect allows default value to be changed by calling page so the value here can be updated.
  useEffect(() => {
    if (selectedValue !== props.defaultValue) {
      setSelectedValue(props.defaultValue);
    }
  }, [props.defaultValue]);

  // Layout effect to set initial focus
  // useLayoutEffect(() => {
  //   if (inputRef !== null) {
  //     inputRef.focus();
  //   }
  // }, []);

  const onChange = async (event: NumericTextBoxChangeEvent) => {
    let newValue: number | null = null;
    if (event.value) newValue = event.value;

    // Update selected value
    setSelectedValue(newValue);
    props.onChange(newValue);
  };

  const label = required ? (
    <span style={{ fontWeight: "bold" }}>
      <span
        style={{
          color: "var(--carbon-orange)",
          display: "inline-block",
          marginLeft: "-12px"
        }}
      >
        *&nbsp;
      </span>
      <Label
        style={{ width: labelWidth, fontWeight: "bold" }}
        editorId={props.id}
      >
        {props.label}
      </Label>
    </span>
  ) : (
    <Label
      style={{ width: labelWidth, fontWeight: "bold" }}
      editorId={props.id}
    >
      {props.label}
    </Label>
  );

  const elem = (
    <StyledFieldWrapper>
      {label}
      <NumericTextBox
        disabled={disabled}
        tabIndex={0}
        value={selectedValue}
        onChange={onChange}
        placeholder={placeholder}
        min={props.minValue}
        max={props.maxValue}
      />
    </StyledFieldWrapper>
  );

  return React.cloneElement(elem);
};

const StyledFieldWrapper = styled(FieldWrapper)`
  margin-bottom: 8px;

  label {
    color: var(--carbon-darkgray);
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-right: 10px;
    display: inline-block;
  }

  .k-numerictextbox {
    max-width: 120px;
  }

  input.k-input {
    display: inline-block;
    margin-top: -4px;
    font-size: 16px;
    padding-left: 4px;
    background: var(--carbon-white);
    border: 1px solid var(--carbon-lightgray) !important;
    box-sizing: border-box;
    border-radius: 6px;
    height: 28px;
  }
`;
