import { EndpointResources } from "../../constants";

const getTabs = (parent: string, id: string) => {
  const tabs = [
    {
      resourceEndpoint: `/app/${parent}/${id}/${EndpointResources.TemplateZones}`,
      tabTitle: "Template Zones"
    }
  ];

  return tabs;
};

export default getTabs;
