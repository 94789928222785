import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { enableMapSet } from "immer";
import StoreProvider from "./contexts/store";
import GridProvider from "./contexts/grid/useGrid";
import reportWebVitals from "./reportWebVitals";
import PreviewProvider from "./contexts/previewer/usePreviewer";
import App from "./App";
import DayGroupsProvider from "./hooks/useDayGroups";
import config from "./config";

enableMapSet();

const SENTRY_DSN = config.sentry.dsn;
const SENTRY_LEVEL = config.sentry.level;
const ENVIRONMENT = config.environment;

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT,
    integrations: [new Integrations.BrowserTracing()],

    // DSN: "https://49cf9e29e4264524bd50d72e98e26e94@o1028316.ingest.sentry.io/5995673"
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: parseFloat(SENTRY_LEVEL)
  });
}

// will, 3/1/22: makes console.log to return nothing unless you are in a dev environment
if (process.env.NODE_ENV !== "development") console.log = () => {};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <StoreProvider>
        <PreviewProvider>
          <GridProvider>
            <DayGroupsProvider>
              <HelmetProvider>
                <App />
              </HelmetProvider>
            </DayGroupsProvider>
          </GridProvider>
        </PreviewProvider>
      </StoreProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
