import { GridColumns } from "../../types/grid";
import { CarbonIcons } from "../../constants";

export const gridColumns: GridColumns[] = [
  {
    field: "NodeDisplayID",
    title: "NodeDisplayID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: -1
  },
  {
    field: "DisplayOrder",
    title: "Order",
    width: "85px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: true,
    canBeSaved: true
  },
  {
    field: "MicroDesc",
    title: "Player Desc.",
    width: "150px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true
  },
  {
    field: "MicroHealth",
    title: "Health",
    width: "120px",
    defaultShow: false,
    filterable: true,
    customFilter: "status",
    sortable: true,
    resizable: true,
    editable: false,
    editor: "status",
    required: false,
    canBeSaved: false,
    defaultValue: "reddot.gif",
    statuses: [
      { value: "greendot.gif", color: "var(--carbon-green)", text: "Online" },
      {
        value: "yellowdot.gif",
        color: "var(--carbon-black)",
        text: "Error"
      },
      { value: "reddot.gif", color: "var(--carbon-red)", text: "Offline" }
    ]
  },
  {
    field: "UpdateStatus",
    title: "Updates",
    width: "150px",
    defaultShow: false,
    filterable: true,
    customFilter: "status",
    sortable: true,
    resizable: true,
    editable: false,
    editor: "status",
    required: false,
    canBeSaved: false,
    defaultValue: "Unprocessed",
    statuses: [
      {
        value: "Unprocessed",
        color: "var(--carbon-black)",
        text: "Unprocessed"
      },
      {
        value: "Confirmed",
        color: "var(--carbon-green)",
        text: "Confirmed"
      },
      {
        value: "Processing",
        color: "var(--carbon-yellow)",
        text: "Processing"
      },
      {
        value: "Ready to Send",
        color: "var(--carbon-yellow)",
        text: "Sending"
      },
      {
        value: "Sent",
        color: "var(--carbon-yellowgreen)",
        text: "Received"
      },
      {
        value: "Delivered",
        color: "var(--carbon-yellowgreen)",
        text: "Downloading"
      },
      { value: "Error", color: "var(--carbon-red)", text: "Error" },
      { value: "Pending", color: "var(--carbon-blue)", text: "Scheduled" }
    ]
  },
  {
    field: "MicroPlayerID",
    title: "MicroPlayerID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true
  },
  {
    field: "CarbonPlayerID",
    title: "Player ID",
    titlePlural: "Player IDs",
    width: "150px",
    maxLength: 50,
    defaultShow: false,
    filter: "numeric",
    editable: false,
    editor: "lookup",
    required: false,
    canBeSaved: false,
    valueField: "MicroPlayerID"
  },
  {
    field: "Orientation",
    title: "Orientation",
    width: "185px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "dropdown",
    required: false,
    canBeSaved: true,
    dropItems: [
      { value: "L", text: "Landscape" },
      { value: "P", text: "Portrait" }
    ]
  },
  {
    field: "HResolution",
    title: "Hor. Resolution",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true
  },
  {
    field: "VResolution",
    title: "Vert. Resolution",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true
  },
  {
    field: "ColorDepth",
    title: "Color Depth",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true
  },
  {
    field: "SerialNumber",
    title: "Player Serial #",
    width: "200px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "IsParent",
    title: "Parent",
    width: "150px",
    defaultShow: false,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true,
    defaultValue: false
  },
  {
    field: "MicroLastProcessed",
    title: "Last Processed",
    width: "250px",
    defaultShow: false,
    filter: "date",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "PatchID",
    title: "PatchID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "PatchVersion",
    title: "Patch Version",
    width: "150px",
    maxLength: 50,
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "MicroProperties",
    title: "Properties",
    width: "150px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  }
];

export const microplayerColumns: GridColumns[] = [
  {
    field: "NodeDisplayID",
    title: "NodeDisplayID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: -1
  },
  {
    field: "DisplayOrder",
    title: "Order",
    width: "80px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    canBeSaved: true
  },
  {
    field: "MicroDesc",
    title: "Player Desc.",
    width: "285px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true
  },
  {
    field: "MicroHealth",
    title: "Health",
    width: "150px",
    defaultShow: true,
    filterable: true,
    customFilter: "status",
    sortable: true,
    resizable: true,
    editable: false,
    editor: "status",
    required: false,
    canBeSaved: false,
    defaultValue: "reddot.gif",
    statuses: [
      { value: "greendot.gif", color: "var(--carbon-green)", text: "Online" },
      {
        value: "yellowdot.gif",
        color: "var(--carbon-black)",
        text: "Error"
      },
      { value: "reddot.gif", color: "var(--carbon-red)", text: "Offline" }
    ]
  },
  {
    field: "UpdateStatus",
    title: "Updates",
    width: "185px",
    defaultShow: true,
    filterable: true,
    customFilter: "status",
    sortable: true,
    resizable: true,
    editable: false,
    editor: "status",
    required: false,
    canBeSaved: false,
    defaultValue: "Unprocessed",
    statuses: [
      {
        value: "Unprocessed",
        color: "var(--carbon-black)",
        text: "Unprocessed"
      },
      {
        value: "Confirmed",
        color: "var(--carbon-green)",
        text: "Confirmed"
      },
      {
        value: "Processing",
        color: "var(--carbon-yellow)",
        text: "Processing"
      },
      {
        value: "Ready to Send",
        color: "var(--carbon-yellow)",
        text: "Sending"
      },
      {
        value: "Sent",
        color: "var(--carbon-yellowgreen)",
        text: "Received"
      },
      {
        value: "Delivered",
        color: "var(--carbon-yellowgreen)",
        text: "Downloading"
      },
      { value: "Error", color: "var(--carbon-red)", text: "Error" },
      { value: "Pending", color: "var(--carbon-blue)", text: "Scheduled" }
    ]
  },
  {
    field: "MicroDownloadPercentage",
    title: "Downloading Status",
    width: "200px",
    defaultShow: true,
    resizable: false,
    editable: false,
    editor: "progressBar",
    progressBarSettings: {
      color: "var(--carbon-yellowgreen)",
      showPercentage: true,
      relatedField: {
        column: "UpdateStatus",
        compareValue: "Delivered"
      }
    },
    required: false,
    canBeSaved: false
  },
  {
    field: "MicroPlayerID",
    title: "MicroPlayerID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true
  },
  {
    field: "CarbonPlayerID",
    title: "Player ID",
    titlePlural: "Player IDs",
    width: "110px",
    maxLength: 50,
    defaultShow: true,
    filter: "numeric",
    editable: false,
    editor: "lookup",
    required: false,
    canBeSaved: false,
    valueField: "MicroPlayerID"
  },
  {
    field: "Orientation",
    title: "Orientation",
    width: "150px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "dropdown",
    required: false,
    canBeSaved: true,
    dropItems: [
      { value: "L", text: "Landscape" },
      { value: "P", text: "Portrait" }
    ]
  },
  {
    field: "HResolution",
    title: "Hor. Resolution",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true
  },
  {
    field: "VResolution",
    title: "Vert. Resolution",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true
  },
  {
    field: "ColorDepth",
    title: "Color Depth",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true
  },
  {
    field: "SerialNumber",
    title: "Player Serial #",
    width: "200px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "IsParent",
    title: "Parent",
    width: "150px",
    defaultShow: false,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true,
    defaultValue: false
  },
  {
    field: "MicroLastProcessed",
    title: "Last Processed",
    width: "250px",
    defaultShow: false,
    filter: "date",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "PatchID",
    title: "PatchID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "PatchVersion",
    title: "Patch Version",
    width: "150px",
    maxLength: 50,
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "MicroProperties",
    title: "Properties",
    width: "150px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "MicroVersionNumber",
    title: "Version No.",
    width: "110px",
    defaultShow: true,
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "MicroIPAddress",
    title: "IP Address",
    width: "185px",
    defaultShow: true,
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "MicroMacAddress",
    title: "MAC Address",
    width: "250px",
    defaultShow: true,
    editable: false,
    required: false,
    canBeSaved: false
  }
];

export const detailGridColumnsPC: GridColumns[] = [
  {
    field: "UpdatesFilter",
    title: "Downloading Status",
    width: "225px",
    defaultShow: true,
    filterable: false,
    sortable: true,
    resizable: true,
    editable: false,
    editor: "icon",
    required: false,
    canBeSaved: false,
    defaultValue: "Unprocessed",
    icons: [
      {
        value: "Delivered",
        icon: CarbonIcons.InProgress,
        label: "In Progress"
      }
    ]
  },
  {
    field: "MachineSerialNumber",
    title: "Serial No.",
    width: "250px",
    maxLength: 100,
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "MachineMACAddress",
    title: "MAC Address",
    width: "250px",
    maxLength: 30,
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "MachineIPAddress",
    title: "IP Address",
    width: "250px",
    maxLength: 30,
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "VigilixID",
    title: "PC ID",
    titlePlural: "PC IDs",
    width: "185px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true,
    valueField: "VigilixID"
  }
];
