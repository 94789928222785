import { Result, ResultError } from "../../types";
import useApi from "../../hooks/api/useApi";
import PostNodePatchResend from "../../api/Nodes/NodePatchResendAPI";

interface INodePatchResendProps {
  api: string;
  nodeId: string;
}

const useNodePatchResend = () => {
  const { loading: nodePatchResendLoading, request: nodePatchResendRequest } =
    useApi(PostNodePatchResend);

  const postNodePatchResend = async ({
    api,
    nodeId
  }: INodePatchResendProps): Promise<Result<string>> => {
    let result: Result<string>;
    try {
      result = await nodePatchResendRequest({ endpoint: `${api}/${nodeId}` });
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }
    return result;
  };

  const isLoading = (): boolean => {
    return nodePatchResendLoading;
  };

  return {
    postNodePatchResend,
    isLoading
  };
};

export default useNodePatchResend;
