// Base
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

// Grommet/Kendo Components
import { Box } from "grommet";
import { MenuItem, MenuSelectEvent, Menu } from "@progress/kendo-react-layout";

// Custom Components
import ChangePasswordDialog from "../Util/Dialogs/ChangePasswordDialog";

// Constants/Contexts
import {
  AppLocalStore,
  CarbonIcons,
  GridActions,
  StoreActions
} from "../../constants";
import { useStore } from "../../contexts/store";
import { useGrid } from "../../contexts/grid/useGrid";
import { useDayGroups } from "../../hooks/useDayGroups";

interface IUserOptionsMenu {
  renderChangePassword: boolean;
  changePassword: () => void;
  screenSize: string;
}

const UserOptionsMenu = ({
  renderChangePassword,
  changePassword,
  screenSize
}: IUserOptionsMenu) => {
  const navigate = useNavigate();
  const { store, dispatch } = useStore();
  const { grids, setGrid } = useGrid();
  const { setNodeId } = useDayGroups();

  const UserOptionsMenuItemHeader = () => {
    return (
      <MenuHeaderContainer
        direction="row"
        align="center"
        justify="center"
        gap="xsmall"
      >
        {screenSize === "xsmall" || screenSize === "small"
          ? CarbonIcons.User
          : store.user?.username}
        {CarbonIcons.ExpandMore}
      </MenuHeaderContainer>
    );
  };

  const onSelect = async (event: MenuSelectEvent) => {
    switch (event.item.text) {
      case "Logout":
        // On logout, clear all grids so they are not rememebered across sessions
        if (grids) {
          [...grids.keys()].forEach((gridId) => {
            setGrid({
              type: GridActions.delete,
              payload: {
                gridId: gridId,
                gridData: null
              }
            });
          });
        }
        dispatch({ type: StoreActions.setUser, payload: null });
        localStorage.removeItem(AppLocalStore.Token);
        localStorage.removeItem(AppLocalStore.RefreshToken);
        localStorage.removeItem(AppLocalStore.CarbonRememberMe);
        localStorage.removeItem(AppLocalStore.ActiveCompany);

        // jon, 4/1/22: Reset day group node on logout. Otherwise, the last node gets remembered and templates will not load if user immediately revists that node.
        setNodeId(0);

        // close previewer popout window if exists
        if (store.previewerWindow) {
          store.previewerWindow.close();
        }
        break;
      case "Change Password":
        changePassword();
        break;
      case "Help":
        navigate("/app/help", { replace: true });
        // window.open(config.helpLink);
        break;
      default:
        break;
    }
  };

  const closeDialog = () => {
    changePassword();
  };

  return (
    <>
      <Menu
        className="carbon-main-menu"
        style={{ height: "100%" }}
        onSelect={onSelect}
      >
        <MenuItem linkRender={UserOptionsMenuItemHeader}>
          <MenuItem text="Help" />
          <MenuItem text="Change Password" />
          {/* <MenuItem text="Settings" /> */}
          <MenuItem text="Logout" />
        </MenuItem>
      </Menu>
      {renderChangePassword && (
        <ChangePasswordDialog closeDialog={closeDialog} confirmation={true} />
      )}
    </>
  );
};

const MenuHeaderContainer = styled(Box)`
  color: var(--carbon-white);
  padding: 6px 6px 6px 8px;
  font-size: 16px;
  white-space: nowrap;

  span[class^="material-icons"] {
    color: var(--carbon-white);
    font-size: 16px;
  }
`;

export default UserOptionsMenu;
