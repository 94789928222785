/* eslint-disable */

import {
  BooleanFilter,
  DateFilter,
  FieldSettings,
  Filter,
  FilterChangeEvent,
  FilterOperator,
  NumericFilter,
  Operators,
  TextFilter
} from "@progress/kendo-react-data-tools";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Box } from "grommet";
import { FC, useEffect, useState } from "react";
import {
  CompositeFilterDescriptor,
  filterBy
} from "@progress/kendo-data-query";
import { useGrid } from "../../contexts/grid/useGrid";
import usePermissions from "../../hooks/auth/usePermissions";
import {
  CarbonIcons,
  GridActions,
  GridIDs,
  StoreActions,
  UserPermissions
} from "../../constants";
import {
  DropDownList,
  DropDownListChangeEvent,
  MultiSelect,
  MultiSelectChangeEvent,
  MultiSelectFilterChangeEvent
} from "@progress/kendo-react-dropdowns";
import { useImmer } from "use-immer";
import { GridColumns } from "../../types/grid";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import { gridColumns } from "./GlobalChangesZonesGridColumns";
import { IFilterItem } from "./GlobalChangesFilterDropdown";
import { useStore } from "../../contexts/store";
import apiClient from "../../api";
import { Loader } from "@progress/kendo-react-indicators";
import { getGridFieldSettings } from "../../helpers/Util";
import useApi from "../../hooks/api/useApi";

interface IGlobalChangesFilterDialog {
  setShowDialog: (value: boolean) => void;
}

// database object (array of objects) we receive from fetching user saved filters
interface ICarbonGridFilterModelDB {
  FilterID: number;
  FilterName: string;
  GridID: GridIDs;
  UserID: number;
  CompanyID: number;
  IsFilterPrivate: string;
  Filter: string;
}

// ui representation for each filter item (each table we filter by)
interface ICarbonGridFilter {
  gridId: GridIDs;
  title: string;
  filter?: CompositeFilterDescriptor;
  filterFieldSettings: FieldSettings[];
  showFilterArea: boolean;
}

// interface for filter objects saved in the database
interface IFilterDataTransfer {
  gridId: GridIDs;
  filter: CompositeFilterDescriptor;
}

// overall user filter.
interface IUserFilter {
  filterItem: IFilterItem;
  isFilterPrivate: boolean;
  filterData: ICarbonGridFilter[];
}

const GlobalChangesFilterDialog: FC<IGlobalChangesFilterDialog> = ({
  setShowDialog
}) => {
  const { grids, setGrid } = useGrid();
  const { store, dispatch } = useStore();
  const { isGranted } = usePermissions();
  const grid = grids.get(GridIDs.GlobalChanges);
  const addingNewFilter = grid?.state.addingNewFilter;
  const selectedFilterId = grid?.state.activeFilterId;
  const [savingFilterError, setSavingFilterError] = useState("");
  const [loadingFilterData, setLoadingFilterData] = useState(false);

  const initialFilters: ICarbonGridFilter[] = [
    {
      gridId: GridIDs.GlobalChanges,
      title: "Schedule Zones",
      filter: undefined,
      filterFieldSettings: getGridFieldSettings(gridColumns),
      showFilterArea: false
    }
  ];

  const multiSelectItems = initialFilters.map((filterObj) => {
    return filterObj.title;
  });

  const [multiSelectData, setMultiSelectData] = useState(multiSelectItems);

  const [filters, setFilters] = useImmer<ICarbonGridFilter[]>(initialFilters);
  // default active user filter if user is adding a new filter
  const [activeUserFilter, setActiveUserFilter] = useImmer<IUserFilter>({
    filterItem: { filterId: -1, filterTitle: "" },
    isFilterPrivate: true,
    filterData: filters
  });
  // default user filters to an array of one empty user filter for a new filter add action
  const [userFilters, setUserFilters] = useImmer<IUserFilter[]>([
    activeUserFilter
  ]);

  const defaultEmptyFilter: CompositeFilterDescriptor = {
    logic: "and",
    filters: []
  };

  const filterIsPublicPrivateVals = [
    { text: "Private", value: "private" },
    { text: "Public", value: "public" }
  ];

  const [selectedMultiSelectValues, setSelectedMultiSelectValues] = useState<
    string[]
  >([]);
  const [filterName, setFilterName] = useState("");
  const [loadingSaveFilter, setLoadingSaveFilter] = useState(false);

  useEffect(() => {
    // fetch filters if this is not a 'add new user filter' event
    if (addingNewFilter === false) {
      setLoadingFilterData(true);
      loadFilter();
    } else {
      // Always open filter area since user can only filter by one thing
      setActiveUserFilter((draft) => {
        draft.filterData.forEach((filterObj) => {
          filterObj.showFilterArea = true;
        });
      });
    }

    return () => {
      setLoadingFilterData(false);
    };
  }, []);

  const { request: getFilterRequest } = useApi(
    async (filterId: number) =>
      await apiClient.get(`/api/-1/userfilters/layout?filterId=${filterId}`)
  );

  const loadFilter = async () => {
    setLoadingFilterData(true);
    const grid = grids.get(GridIDs.GlobalChanges);

    // jon, 3/15/22: Change all direct apiClient calls to use the useApi hook for proper refresh token handling.
    const getFilterResponse = await getFilterRequest(
      grid?.state.activeFilterId ?? -1
    );
    if (getFilterResponse.type === "error") return;
    const response = getFilterResponse.value;

    const responseData: ICarbonGridFilterModelDB = response.data;
    const dbFilterData: IFilterDataTransfer[] = JSON.parse(responseData.Filter);

    // loop through default filters and return a modified object based on database values
    const userFilterData: ICarbonGridFilter[] = filters.map((val) => {
      const filterData = dbFilterData.find(
        (dbFilter) => dbFilter.gridId === val.gridId
      );
      if (filterData) {
        const tempFilter = Object.assign({}, val);
        tempFilter.showFilterArea = true;
        tempFilter.filter = filterData.filter;
        return tempFilter;
      } else {
        return val;
      }
    });

    const userFilter: IUserFilter = {
      filterItem: {
        filterId: responseData.FilterID,
        filterTitle: responseData.FilterName
      },
      isFilterPrivate: responseData.IsFilterPrivate === "Y" ? true : false,
      filterData: userFilterData
    };
    setActiveUserFilter(userFilter);
    // set the active multiselect values
    const multiSelectValues = userFilter.filterData.flatMap((val) => {
      if (val.showFilterArea) {
        return val.title;
      } else {
        return [];
      }
    });
    setSelectedMultiSelectValues(multiSelectValues);
    setLoadingFilterData(false);
  };

  // this event is called every time a specific filter's conditions (expressions) change
  const onFilterChange = (gridId: GridIDs, event: FilterChangeEvent) => {
    // modify the active userfilter's filters
    setActiveUserFilter((draft) => {
      const filterObj = draft.filterData.find((val) => val.gridId === gridId);
      if (filterObj) {
        // This has a bug when switching filter operators with no expressions listed
        // workaround is to check  filterObj.filter?.logic === event.filter.logic and prevent the filter from clearing if the user simply switches operators with no expressions set yet
        if (
          event.filter.filters.length === 0 &&
          filterObj.filter?.logic === event.filter.logic
        ) {
          // there is no onClose event for the kendo react filter, so if the filter length here is 0, it means the user attempted to remove the last filter object
          // so clean the filter from memory and deselect it's associated selected chip from the multiselect
          filterObj.filter = undefined;
          filterObj.showFilterArea = false;
          const newSelectedValues = selectedMultiSelectValues.filter(
            (val) => val !== filterObj.title
          );
          setSelectedMultiSelectValues(newSelectedValues);
        } else {
          filterObj.filter = event.filter;
        }
      }
    });
  };

  const onChangeMultiSelect = (event: MultiSelectChangeEvent) => {
    const selectedMultiSelectValues = event.value as string[];
    setActiveUserFilter((draft) => {
      draft.filterData.forEach((filterObj) => {
        if (selectedMultiSelectValues.includes(filterObj.title)) {
          filterObj.showFilterArea = true;
        } else {
          filterObj.showFilterArea = false;
        }
      });
    });
    setSelectedMultiSelectValues(event.value);
  };

  const handleDialogClose = () => {
    setGrid({
      type: GridActions.onCloseFilterDialog,
      payload: {
        gridId: GridIDs.GlobalChanges,
        gridData: {
          undefined
        }
      }
    });
    setGrid({
      type: GridActions.toggleRefreshGrid,
      payload: { gridId: GridIDs.GlobalChanges, gridData: true }
    });
  };

  const onMultiSelectFilterChange = (event: MultiSelectFilterChangeEvent) => {
    const filter = event.filter;
    const allData = [...multiSelectItems];
    const newData = filterBy(allData, filter);
    setMultiSelectData(newData);
  };

  // private/public filter status change
  const handleFilterPublicPrivateChange = (event: DropDownListChangeEvent) => {
    console.log(event);
    setActiveUserFilter((draft) => {
      draft.isFilterPrivate = event.value === "Private" ? true : false;
    });
  };

  const handleNewFilterNameChange = (event: InputChangeEvent) => {
    setActiveUserFilter((draft) => {
      draft.filterItem.filterTitle = event.value;
    });
  };

  const { request: saveFilterRequest } = useApi(
    async (userFilterObj: any) =>
      await apiClient.post(`/api/-1/userfilters`, userFilterObj)
  );

  const handleFiltersSave = async () => {
    setLoadingSaveFilter(true);

    const filterObj = activeUserFilter.filterData
      .filter((val) => val.filter !== undefined)
      .map((filteredVal) => {
        return {
          gridId: filteredVal.gridId,
          filter: filteredVal.filter
        };
      });

    const userFilterObj = {
      FilterID: activeUserFilter.filterItem.filterId,
      GridID: GridIDs.GlobalChanges,
      FilterName: activeUserFilter.filterItem.filterTitle,
      CompanyID: store.user?.companyID,
      IsFilterPrivate: activeUserFilter.isFilterPrivate,
      Filter: filterObj
    };

    try {
      // jon, 3/15/22: Change all direct apiClient calls to use the useApi hook for proper refresh token handling.
      const saveFilterResponse = await saveFilterRequest(userFilterObj);
      if (saveFilterResponse.type === "error") return;
      const response = saveFilterResponse.value;

      if (response.status === 200) {
        const responseObj: ICarbonGridFilterModelDB = response.data;
        setGrid({
          type: GridActions.onSaveAdvancedFilter,
          payload: {
            gridId: GridIDs.GlobalChanges,
            gridData: { newFilterId: responseObj.FilterID }
          }
        });
        handleDialogClose();
        dispatch({
          type: StoreActions.addNotification,
          payload: {
            message: `Saved '${activeUserFilter.filterItem.filterTitle}' successfully.`,
            messageType: "success",
            closable: false
          }
        });
      }
    } catch (error: any) {
      setSavingFilterError(error.response.data);
    } finally {
      setLoadingSaveFilter(false);
    }
  };

  // will, 3/16/22: added check for the filter name so filters with no name cannot be created
  return (
    <>
      {loadingFilterData ? (
        <Dialog
          width={"1000px"}
          height={"700px"}
          title={
            <Box fill align="center">
              <span
                style={{ color: "var(--carbon-orange)" }}
                className="material-icons-outlined"
              >
                filter_alt
              </span>
              <h1 style={{ paddingTop: "5px", color: "var(--carbon-orange)" }}>
                {"Global Change Filters"}
              </h1>
            </Box>
          }
          closeIcon={false}
        >
          <Box fill align={"center"} justify={"center"}>
            <Loader size="large" type="converging-spinner" />
          </Box>
        </Dialog>
      ) : (
        <Dialog
          width={"1000px"}
          height={"700px"}
          title={
            <Box fill align="center">
              <span
                style={{ color: "var(--carbon-orange)" }}
                className="material-icons-outlined"
              >
                filter_alt
              </span>
              <h1 style={{ paddingTop: "5px", color: "var(--carbon-orange)" }}>
                {"Global Change Filters"}
              </h1>
              <Box
                margin={{ bottom: "small" }}
                fill
                direction="row"
                justify="between"
                align="center"
              >
                <Input
                  value={activeUserFilter.filterItem.filterTitle}
                  onChange={handleNewFilterNameChange}
                  autoFocus={true}
                  label={"Filter Name"}
                  width={"210px"}
                />
                {isGranted(UserPermissions.CanCreatePubFilters) && (
                  <DropDownList
                    onChange={handleFilterPublicPrivateChange}
                    defaultValue={
                      activeUserFilter.isFilterPrivate ? "Private" : "Public"
                    }
                    data={filterIsPublicPrivateVals.map((val) => val.text)}
                    label={"Filter is"}
                  />
                )}
              </Box>
              <MultiSelect
                filterable={true}
                disabled={activeUserFilter.filterItem.filterTitle.length === 0}
                label={"Filter By"}
                style={{ width: "100%" }}
                onChange={onChangeMultiSelect}
                onFilterChange={onMultiSelectFilterChange}
                data={multiSelectData}
                value={selectedMultiSelectValues}
              />
            </Box>
          }
          closeIcon={false}
        >
          {selectedMultiSelectValues.length === 0 ? (
            <Box fill justify="center" align="center">
              <h1 style={{ color: "GrayText" }}>No Filters Have Been Set</h1>
            </Box>
          ) : (
            <>
              {activeUserFilter.filterData.map((filter, key) => {
                if (filter.showFilterArea) {
                  return (
                    <Box key={key}>
                      <h1 style={{ marginTop: "5px" }}>{filter.title}</h1>
                      <Filter
                        key={key}
                        value={filter.filter || defaultEmptyFilter}
                        onChange={(event) =>
                          onFilterChange(filter.gridId, event)
                        }
                        fields={filter.filterFieldSettings}
                      />
                      <hr />
                    </Box>
                  );
                }
              })}
            </>
          )}

          <DialogActionsBar>
            <Box direction="row" style={{ marginBottom: "30px" }}>
              <Button onClick={handleDialogClose}>
                {CarbonIcons.Close}
                {"Cancel"}
              </Button>
              <Button
                primary={true}
                onClick={() => {
                  handleFiltersSave();
                }}
                disabled={
                  selectedMultiSelectValues.length === 0 ||
                  activeUserFilter.filterItem.filterTitle.length === 0
                }
              >
                {CarbonIcons.Check}
                {"Save & Apply"}
              </Button>
            </Box>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
};

export default GlobalChangesFilterDialog;
