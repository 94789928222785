import React from "react";
import { CellRenderProps } from "./CellRender";
import styled from "styled-components";
import { useGrid } from "../../../contexts/grid/useGrid";
import { GridActions } from "../../../constants";

export const StatusRender = ({
  originalProps,
  td,
  gridId,
  colDefinition
}: CellRenderProps) => {
  const { grids, setGrid } = useGrid();

  let value = originalProps.dataItem[originalProps.field!];
  if (!value || value === null) value = "";

  const currentStatus = colDefinition!.statuses!.find(
    (item) => item.value === value
  );

  if (!currentStatus) {
    throw new Error(`Status not found for value in StatusRender: ${value}`);
  }

  // jon, 4/5/22: Clicking the status bubble in the Nodes grid causes the currently-displaying subgrid to show under the row where this status bubble
  //  was just clicked.  This new code mimics the behavior of clicking the row anywhere else, which simply collapses the subgrid.
  const additionalProps = {
    onClick: (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      if (
        grids.get(gridId) &&
        grids.get(gridId)?.state.selectedRowIsExpanded === true
      ) {
        setGrid({
          type: GridActions.onExpandRow,
          payload: {
            gridId,
            gridData: { value: false, dataItem: {} }
          }
        });
      }
    }
  };

  return React.cloneElement(
    td,
    { ...td.props, ...additionalProps },
    <StyledStatusDiv>
      <span style={{ backgroundColor: currentStatus!.color }}>
        {currentStatus!.text}
      </span>
    </StyledStatusDiv>
  );
};

const StyledStatusDiv = styled.div`
  text-align: center;
  display: inline-flex;
  vertical-align: middle;
  margin-left: -10px;

  span {
    border-radius: 10px;
    padding: 1px 8px;
    font-size: 14px;
    color: var(--carbon-white);
    line-height: 18px;
    font-weight: 600;
  }
`;
