import apiClient from "..";
import { ChangePasswordContract } from "../../types";
import { AxiosResponse } from "axios";

const ChangePasswordApi = (
  changePasswordContract: ChangePasswordContract,
  endpoint: string
): Promise<AxiosResponse> => {
  const data: Object = {
    UserID: changePasswordContract.userId,
    CurrentPassword: changePasswordContract.currentPassword,
    NewPassword: changePasswordContract.newPassword
  };

  const response = apiClient.put(endpoint, JSON.stringify(data));

  return response;
};

export default ChangePasswordApi;
