import { Placeholders } from "../../constants";
import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  {
    field: "StencilName",
    title: "Stencil",
    width: "455px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    maxLength: 50,
    canBeSaved: true,
    isPartOfUniqueKey: true
  },
  {
    field: "HSize",
    title: "Width",
    width: "160px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: true,
    canBeSaved: true,
    minValue: 0
  },
  {
    field: "VSize",
    title: "Height",
    width: "175px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: true,
    canBeSaved: true,
    minValue: 0
  },
  {
    field: "StencilDesc",
    title: "Description",
    width: "1030px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 256,
    canBeSaved: true
  },
  {
    field: "StencilID",
    title: "ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: -1,
    isPartOfUniqueKey: true
  },
  {
    field: "CompanyID",
    title: "CompanyID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    company: true,
    defaultValue: Placeholders.companyID,
    isPartOfUniqueKey: true,
    systemHidden: true
  },
  {
    field: "CompanyName",
    title: "Company",
    titlePlural: "Companies",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    editor: "lookup",
    required: false,
    valueField: "CompanyID",
    maxLength: 50,
    canBeSaved: false,
    companyMaster: true,
    defaultValue: Placeholders.companyName
  },
  {
    field: "StencilTypeID",
    title: "StencilTypeID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "StencilTypeName",
    title: "Stencil Type",
    titlePlural: "Stencil Types",
    width: "150px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    valueField: "StencilTypeID",
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "IsActive",
    title: "Active",
    width: "250px",
    defaultShow: false,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true,
    defaultValue: "Y"
  }
];
