import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  {
    field: "ScheduleZoneID",
    title: "ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "SeqNum",
    title: "No.",
    width: "125x",
    defaultShow: true,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "TemplateZoneID",
    title: "Template Zone ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "ZoneName",
    title: "Zone",
    width: "120px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "ContentSeqNum",
    title: "Sequence",
    width: "100px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    minValue: 1,
    maxValue: 9999,
    required: true,
    canBeSaved: true,
    defaultValue: 1
  },
  {
    field: "MediaID",
    title: "Media ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "PackageID",
    title: "Package ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "ZoneContent",
    title: "Content",
    width: "350px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "combinedcolumn",
    required: true,
    canBeSaved: false
  },
  {
    field: "MediaName",
    title: "Media",
    titlePlural: "Media",
    width: "250px",
    maxLength: 256,
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    canBeSaved: false,
    valueField: "MediaID",
    combinedCol: "ZoneContent",
    controlField: "ContentType",
    controlValue: "M",
    lookupFilterCol: "FilterMediaTypeID",
    lookupFilterDataName: "MediaTypeID"
  },
  {
    field: "PackageName",
    title: "Package",
    titlePlural: "Packages",
    width: "250px",
    maxLength: 50,
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    canBeSaved: false,
    valueField: "PackageID",
    combinedCol: "ZoneContent",
    controlField: "ContentType",
    controlValue: "P",
    lookupFilterCol: "FilterPackageTypeID",
    lookupFilterDataName: "PackageTypeID"
  },
  {
    field: "ContentStatus",
    title: "Status",
    width: "145px",
    defaultShow: true,
    customFilter: "status",
    editable: false,
    editor: "status",
    required: false,
    canBeSaved: false,
    defaultValue: "reddot.gif",
    statuses: [
      { value: "greendot.gif", color: "var(--carbon-green)", text: "Current" },
      {
        value: "bluedot.gif",
        color: "var(--carbon-blue)",
        text: "Future"
      },
      { value: "reddot.gif", color: "var(--carbon-red)", text: "Expired" }
    ]
  },
  {
    field: "ContentType",
    title: "Content Type",
    width: "150px",
    defaultShow: false,
    filter: "text",
    editable: false,
    editor: "dropdown",
    required: false,
    canBeSaved: false,
    defaultValue: "M",
    dropItems: [
      { value: "M", text: "Media" },
      { value: "P", text: "Package" }
    ]
  },
  {
    field: "StaticDisplayTime",
    title: "Static Dis.",
    width: "100px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    minValue: 0,
    required: true,
    canBeSaved: true,
    defaultValue: 10
  },
  {
    field: "StartDate",
    title: "Start",
    width: "155px",
    defaultShow: true,
    filter: "date",
    editable: true,
    editor: "date",
    required: false,
    canBeSaved: true
  },
  {
    field: "EndDate",
    title: "End",
    width: "155px",
    defaultShow: true,
    filter: "date",
    editable: true,
    editor: "date",
    required: false,
    canBeSaved: true
  },
  {
    field: "Volume",
    title: "Volume",
    width: "120px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    minValue: 0,
    required: false,
    canBeSaved: true,
    defaultValue: 0
  },
  {
    field: "StartDate_Sort",
    title: "StartDate Sort",
    width: "250px",
    defaultShow: false,
    filter: "date",
    editable: false,
    required: false,
    canBeSaved: true
  },
  {
    field: "EndDate_Sort",
    title: "EndDate Sort",
    width: "250px",
    defaultShow: false,
    filter: "date",
    editable: false,
    required: false,
    canBeSaved: true
  },
  {
    field: "OffsetMinutes",
    title: "Offset Time",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: true,
    editor: "number",
    minValue: 0,
    required: true,
    canBeSaved: true,
    defaultValue: 0
  },
  {
    field: "ContentCategory",
    title: "Category",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: "S"
  },
  {
    field: "AssocStaticContentType",
    title: "Assoc. Content Type",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: "M",
    dropItems: [
      { value: "S", text: "Static" },
      { value: "A", text: "Animated" },
      { value: "M", text: "Media" },
      { value: "P", text: "Package" }
    ]
  },
  {
    field: "AssocStaticContent1",
    title: "Assoc. Static Content 1",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  // {
  //   field: "MediaName1",
  //   title: "Media",
  //   titlePlural: "Media",
  //   width: "250px",
  //   maxLength: 256,
  //   defaultShow: true,
  //   filter: "text",
  //   editable: true,
  //   editor: "lookup",
  //   required: false,
  //   canBeSaved: false,
  //   lookupValues: "media1",
  //   valueField: "AssocStaticContent1MediaID"
  // },
  // {
  //   field: "PackageName1",
  //   title: "Package",
  //   titlePlural: "Packages",
  //   width: "250px",
  //   maxLength: 256,
  //   defaultShow: true,
  //   filter: "text",
  //   editable: true,
  //   editor: "lookup",
  //   required: false,
  //   canBeSaved: false,
  //   lookupValues: "packages1",
  //   valueField: "AssocStaticContent1PackageID"
  // },
  // {
  //   field: "AssocStaticContent2",
  //   title: "Assoc. Static Content 2",
  //   width: "250px",
  //   defaultShow: false,
  //   filter: "text",
  //   editable: false,
  //   required: false,
  //   canBeSaved: false
  // },
  // {
  //   field: "MediaName2",
  //   title: "Media",
  //   titlePlural: "Media",
  //   width: "250px",
  //   maxLength: 256,
  //   defaultShow: true,
  //   filter: "text",
  //   editable: true,
  //   editor: "lookup",
  //   required: false,
  //   canBeSaved: false,
  //   lookupValues: "media2",
  //   valueField: "AssocStaticContent2MediaID"
  // },
  // {
  //   field: "PackageName2",
  //   title: "Package",
  //   titlePlural: "Packages",
  //   width: "250px",
  //   maxLength: 256,
  //   defaultShow: true,
  //   filter: "text",
  //   editable: true,
  //   editor: "lookup",
  //   required: false,
  //   canBeSaved: false,
  //   lookupValues: "packages2",
  //   valueField: "AssocStaticContent2PackageID"
  // },
  {
    field: "ZoneDesc",
    title: "Zone Description",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "FilterMediaTypeID",
    title: "Filter Media Type ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "FilterPackageTypeID",
    title: "Filter Package Type ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "FilterMediaTypeName",
    title: "Filter Media Type Name",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: true
  },
  {
    field: "HideZoneWhenInactive",
    title: "Hide When Inactive",
    width: "250px",
    defaultShow: false,
    filter: "boolean",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: "N"
  },
  {
    field: "HideOtherZonesWhenActive",
    title: "Hide Other Zones When Active",
    width: "250px",
    defaultShow: false,
    filter: "boolean",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: "N"
  },
  {
    field: "RandomizeContentOrder",
    title: "Randomize Order",
    width: "250px",
    defaultShow: false,
    filter: "boolean",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: "N"
  },
  {
    field: "ContentStatusMessage",
    title: "Content Status Message",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: true
  }
];
