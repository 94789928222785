import { Button } from "@progress/kendo-react-buttons";
import { Api, CarbonIcons } from "../../constants";
import { createRef, FC, ReactElement, useEffect, useState } from "react";
import {
  ExternalDropZone,
  Upload,
  UploadFileInfo,
  UploadFileStatus,
  UploadOnAddEvent,
  UploadOnProgressEvent,
  UploadOnRemoveEvent,
  UploadOnStatusChangeEvent
} from "@progress/kendo-react-upload";
import apiClient from "../../api";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import styled from "styled-components";
import {
  Notification,
  NotificationGroup
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import {
  DropDownList,
  DropDownListChangeEvent,
  DropDownListProps
} from "@progress/kendo-react-dropdowns";
import { Box } from "grommet";
import { useStore } from "../../contexts/store";
import { Company } from "../../types";
import useApi from "../../hooks/api/useApi";

interface MediaFilesUploadDialogProps {
  onPostUploadFiles: (uploadDate: Date | null) => void;
}

const MediaFilesUploadDialogue: FC<MediaFilesUploadDialogProps> = ({
  onPostUploadFiles
}) => {
  const [files, setFiles] = useState<Array<UploadFileInfo>>([]);
  const uploadRef = createRef<Upload>();
  const [fileUploadErrors, setFileUploadErrors] = useState([] as string[]);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [dateOfFirstFileUploaded, setDateOfFirstFileUploaded] =
    useState<Date | null>(null);
  const { store } = useStore();

  const companies = store.user?.companies.map((company) => {
    if (company.companyId === -1) {
      // replace with 'Select Company' name, this is 'All Companies' company which is not allowed
      const newCompany: Company = {
        companyId: company.companyId,
        companyName: "-Select Company-"
      };
      return newCompany;
    }
    return company;
  });

  const [selectedCompany, setSelectedCompany] = useState<Company>(
    // search our filtered companies array
    companies?.find(
      (item) => item.companyId === store.activeCompany?.companyId
    )!
  );
  const [uploadAreaContentVisible, setUploadAreaContentVisible] = useState(
    () => {
      /* hide upload area if active company is "All Companies", force user to select a company to upload files to */
      return store.activeCompany?.companyId !== -1;
    }
  );

  useEffect(() => {
    selectedCompany.companyId !== -1
      ? setUploadAreaContentVisible(true)
      : setUploadAreaContentVisible(false);
  }, [selectedCompany]);

  const nameValidator = (filename: string) => {
    const pattern = /^[-._ A-Za-z0-9]+$/;
    const regexp = new RegExp(pattern);
    const result = regexp.test(filename);

    if (!result) {
      return `The file name '${filename}' is not valid. Only letters, numbers, space, period (.), underscore (_), and hyphen (-) are allowed, and the filename must contain exactly one period.`;
    } else {
      // Allow exactly one period in the filename since multiple ones mess up the extension detection
      // Using split here to divide the filename into an array on the . char. If only one . the array will have 2 elements. Anything else is invalid.
      // LEGACY COMMENT ^^
      if (filename.split(".").length !== 2) {
        return `Invalid file name '${filename}'. File name must contain only one '.'`;
      }
    }

    // valid name, continue
    return null;
  };

  const hint = <span>Drag and Drop Files Here</span>;

  const handleUploadStatusChange = (event: UploadOnStatusChangeEvent) => {
    setFiles(event.newState);
    let doneUploading = true;
    let errorUploading = false;
    for (const file of event.newState) {
      if (file.status === UploadFileStatus.Uploaded) {
        // Set the date of the first file successfully uploaded, so we can use it later (in onPostUploadFiles) to filter the grid post-insert.
        // jon, 3/1/22: We are now passing the actual date back from the server, so use that instead of getting client-side date since sometimes these were off by a second
        //    or so and would cause the media file to not display in the post-upload filter.
        const uploadDate: Date = event.response?.response ?? new Date();
        if (dateOfFirstFileUploaded === null)
          setDateOfFirstFileUploaded(uploadDate);
      } else {
        doneUploading = false;
      }
      if (file.status === UploadFileStatus.UploadFailed) {
        errorUploading = true;
      }
    }
    if (doneUploading || errorUploading) {
      setFileUploadErrors([]);
      setUploadInProgress(false);
    }
  };

  const handleOnRemove = (event: UploadOnRemoveEvent) => {
    setFiles(event.newState);

    // will, 3/16/22: Added below check to re-enable the dialog's close button if there are no files being uploaded
    // Filter out all items in the file list not in 'Uploading' status, so we can see if dialog close should be enabled
    // Status = 3 -> 'Uploading'
    const filesBeingUploaded = event.newState.filter(
      (file) => file.status === 3
    );

    if (filesBeingUploaded.length === 0) {
      setUploadInProgress(false);
    }
  };

  const { request: getFileExistsRequest } = useApi(
    async (filename: string) =>
      await apiClient.get(
        `${Api.baseUrl}/api/${selectedCompany.companyId}/files/media-file-exists?fileName=${filename}`
      )
  );

  const handleOnAdd = async (event: UploadOnAddEvent) => {
    const warningsArray: string[] = [];
    let filteredNewState: UploadFileInfo[] = event.newState;

    for (const file of event.affectedFiles) {
      const nameValidatorResult = nameValidator(file.name);
      if (nameValidatorResult == null) {
        // jon, 3/14/22: Change all direct apiClient calls to use the useApi hook for proper refresh token handling.
        const getFileExistsResponse = await getFileExistsRequest(file.name);
        if (getFileExistsResponse.type === "error") return;
        const result = getFileExistsResponse.value;

        if (result.data === true) {
          warningsArray.push(
            `The file '${file.name}' already exists on the server and will be overwritten if it is not removed from the list below.`
          );
        }
      } else {
        filteredNewState = filteredNewState.filter(
          (item) => item.name !== file.name
        );
        warningsArray.push(nameValidatorResult);
      }
    }

    setFileUploadErrors([...fileUploadErrors, ...warningsArray]);
    setFiles(filteredNewState);
  };

  const handleOnProgress = (event: UploadOnProgressEvent) => {
    setFiles(event.newState);
    setUploadInProgress(true);
  };

  const handleOnBeforeUpload = () => {
    setFileUploadErrors([]);
  };

  const uploadZoneCleanup = () => {
    onPostUploadFiles(dateOfFirstFileUploaded);
    setFileUploadErrors([]);
    setFiles([]);
  };

  const CarbonCompaniesList: FC<DropDownListProps> = ({ ...props }) => {
    const valueRender = (
      element: ReactElement<HTMLSpanElement>,
      value: any
    ) => {
      return (
        <Box pad={{ left: "small" }} justify="center" align="start" fill>
          {value}
        </Box>
      );
    };

    return (
      <DropDownList
        style={{ backgroundColor: "var(--carbon-white)", borderRadius: 5 }}
        valueRender={valueRender}
        {...props}
      />
    );
  };

  const handleCompanyChange = (event: DropDownListChangeEvent) => {
    const company = companies?.find(
      (company) => company.companyName === event.value
    );
    setSelectedCompany(company!);
    setFileUploadErrors([]);
    setFiles([]);
  };

  return (
    <Dialog
      title={"Upload Media Files"}
      onClose={() => {
        uploadZoneCleanup();
      }}
      closeIcon={false}
      width={800}
      height={800}
    >
      <div>
        <Box margin={{ bottom: "large" }} direction="row" align="center">
          <span style={{ fontSize: 16, fontWeight: "bold" }}>
            Upload Files to
          </span>
          <CarbonCompaniesList
            onChange={(event) => {
              handleCompanyChange(event);
            }}
            data={companies?.map((company) => company.companyName)}
            defaultValue={() => {
              return store.activeCompany?.companyId === -1
                ? "-Select Company-"
                : store.activeCompany?.companyName;
            }}
            value={selectedCompany.companyName}
          />
        </Box>
        {uploadAreaContentVisible && (
          <StyledUploadArea>
            <StyledDropzone
              uploadRef={uploadRef}
              customHint={hint}
              customNote={<span />}
              style={{
                backgroundColor: "var(--carbon-lightblue)"
              }}
            />
            {fileUploadErrors.length > 0 && (
              <NotificationGroup
                style={{
                  alignItems: "flex-start",
                  flexWrap: "wrap-reverse",
                  position: "relative",
                  width: "100%",
                  paddingRight: 0
                }}
              >
                <Fade className={"file-upload-warning"}>
                  {fileUploadErrors.map((item, index) => {
                    return (
                      <Notification
                        type={{ style: "warning", icon: true }}
                        closable={true}
                        onClose={() => {
                          // delete item
                          const newArrayState = fileUploadErrors.filter(
                            (value, theIndex) => {
                              return index !== theIndex;
                            }
                          );
                          setFileUploadErrors(newArrayState);
                        }}
                        style={{ width: "100%" }}
                        key={index}
                      >
                        <span>{item}</span>
                      </Notification>
                    );
                  })}
                </Fade>
              </NotificationGroup>
            )}
            <Upload
              restrictions={{}}
              onProgress={handleOnProgress}
              onStatusChange={handleUploadStatusChange}
              onAdd={handleOnAdd}
              onRemove={handleOnRemove}
              onBeforeUpload={handleOnBeforeUpload}
              autoUpload={false}
              ref={uploadRef}
              batch={false}
              multiple={true}
              withCredentials={false}
              files={files}
              saveHeaders={{
                Authorization: `Bearer ${localStorage.getItem("token")}`
              }}
              saveUrl={`${Api.baseUrl}/api/${selectedCompany.companyId}/files/media`}
            />
          </StyledUploadArea>
        )}
      </div>
      <DialogActionsBar>
        <Button disabled={uploadInProgress} onClick={() => uploadZoneCleanup()}>
          {CarbonIcons.Close}
          {"Close"}
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
};

const StyledDropzone = styled(ExternalDropZone)`
  height: 150px;
  margin-bottom: 10px;
`;

const StyledUploadArea = styled.div`
  .k-upload {
    .k-upload-files {
      .k-file-name {
        white-space: pre;
      }
    }
  }
`;

export default MediaFilesUploadDialogue;
