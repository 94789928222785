import React, { useEffect, useState } from "react";
import { useGrid } from "../../../contexts/grid/useGrid";
import { GridActions } from "../../../constants";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { HeaderCellRenderProps } from "./HeaderCellRender";

export const HeaderSelectAllCheckboxRender = ({
  gridId,
  th,
  colDefinition
}: HeaderCellRenderProps) => {
  const { grids, setGrid } = useGrid();
  const [checked, setChecked] = useState<boolean>(
    grids.get(gridId)?.state.selectAllChecked ?? false
  );

  // jon, 1/14/22: Effect listens for selectAllChecked changing in grid and makes sure the header checkbox shows the same state. Fixes issue where
  //    checkbox was staying checked after applying global change.
  useEffect(() => {
    if (grids.get(gridId)) {
      if (checked !== grids.get(gridId)!.state.selectAllChecked) {
        setChecked(grids.get(gridId)!.state.selectAllChecked ?? false);
      }
    }
  }, [grids.get(gridId)?.state.selectAllChecked]);

  const onCheckChanged = (event: CheckboxChangeEvent) => {
    // console.log(`Header Select All Checkbox Changed: `, event.value);
    setChecked(event.value);

    setGrid({
      type: GridActions.toggleSelectAllCheckbox,
      payload: {
        gridId,
        gridData: {
          field: colDefinition?.field,
          value: event.value
        }
      }
    });
    // Build the selected pk list.
    setGrid({
      type: GridActions.buildSelectedPKList,
      payload: { gridId, gridData: null }
    });
  };

  const checkChildren: JSX.Element = (
    <div style={{ textAlign: "center" }}>
      <Checkbox
        title="Select/Unselect all zones"
        value={checked}
        onChange={onCheckChanged}
      />
    </div>
  );

  return React.cloneElement(th, th.props, checkChildren);
};
