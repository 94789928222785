import { Placeholders } from "../../constants";
import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  {
    field: "PriceScheduleID",
    title: "PriceScheduleID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: -1,
    isPartOfUniqueKey: true
  },
  {
    field: "CompanyID",
    title: "CompanyID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    company: true,
    defaultValue: Placeholders.companyID,
    isPartOfUniqueKey: true,
    systemHidden: true
  },
  {
    field: "CompanyName",
    title: "Company",
    titlePlural: "Companies",
    width: "460px",
    defaultShow: true,
    filter: "text",
    editable: false,
    editor: "lookup",
    required: false,
    valueField: "CompanyID",
    maxLength: 50,
    canBeSaved: false,
    companyMaster: true,
    defaultValue: Placeholders.companyName
  },
  {
    field: "PriceScheduleName",
    title: "Price Schedule",
    width: "1370px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    maxLength: 50,
    canBeSaved: true,
    isPartOfUniqueKey: true
  },
  {
    field: "PriceScheduleDesc",
    title: "Description",
    width: "350px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 256,
    canBeSaved: true
  },
  {
    field: "IsActive",
    title: "Active",
    width: "140px",
    defaultShow: false,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true,
    defaultValue: true
  }
];
