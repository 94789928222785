import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { FC } from "react";
import { Box } from "grommet";
import { CarbonIcons } from "../../../constants";
import {
  GlobalChangesRestoreScheduleContract,
  LookupNodeType
} from "../../../types";
import useGlobalChangesApi from "../../../hooks/globalChanges/useGlobalChangesApi";
import OctopusLoader from "../OctopusLoader";

export interface IGlobalChangeRestoreDialogProps {
  nodes: LookupNodeType[];
  onAcceptCallback: () => void;
  onRejectCallback: () => void;
}

const GlobalChangeRestoreDialog: FC<IGlobalChangeRestoreDialogProps> = ({
  nodes,
  onAcceptCallback,
  onRejectCallback
}) => {
  const { restoreScheduleToNodes, isLoading } = useGlobalChangesApi();

  const onClickApplyButton = async () => {
    // All actions require NodeProcessUpdateID
    // Default date to NOW
    const payload: GlobalChangesRestoreScheduleContract = {
      Nodes: nodes.map((node) => node.NodeID)
    };

    const result = await restoreScheduleToNodes(payload);
    if (result.type === "success") {
      onAcceptCallback();
    }
  };

  const CustomTitleBar = () => {
    return (
      <h1>
        {CarbonIcons.WarningDialog}
        <br />
        {"Please Confirm"}
      </h1>
    );
  };

  return (
    <Dialog title={<CustomTitleBar />} closeIcon={false}>
      <Box style={{ position: "relative" }}>
        {isLoading() && <OctopusLoader />}
        <p>
          The following Nodes were affected by the last global change operation
          and will be restored to their last published schedules.
        </p>
        <p>
          <strong>WARNING:</strong> This will overwrite ALL changes made to
          these nodes since the last time they were published! Do you want to
          continue?
        </p>
        <div
          style={{
            maxHeight: "400px",
            overflowY: "scroll",
            marginTop: "20px",
            marginBottom: "20px"
          }}
        >
          {nodes?.map((node, i) => {
            return (
              <div
                style={{
                  paddingBottom: "4px"
                }}
                key={i}
              >
                {node.NodeName}
              </div>
            );
          })}
        </div>
      </Box>
      <DialogActionsBar>
        <Button onClick={onRejectCallback}>
          {CarbonIcons.Close}
          {"Cancel"}
        </Button>
        <Button autoFocus={true} primary={true} onClick={onClickApplyButton}>
          {CarbonIcons.Check}
          {"Yes, proceed"}
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
};

export default GlobalChangeRestoreDialog;
