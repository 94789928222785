import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { CarbonIcons, GridActions, StoreActions } from "../../../constants";
import { useGrid } from "../../../contexts/grid/useGrid";
import { CellRenderProps } from "./CellRender";
import { unlockGridIfLockedOut } from "../Utility/GridUtility";
import { GridValidationMessagePosType } from "../../../types/grid";
import {
  NumericTextBox,
  NumericTextBoxChangeEvent
} from "@progress/kendo-react-inputs";
import { useStore } from "../../../contexts/store";

type UnMountSaveData = {
  previousDataItem: { [key: string]: any };
  dataItem: { [key: string]: any };
  value: number | null;
  isThisRowBeingEdited: boolean;
  saving: boolean;
};

export const NumberRender = ({
  originalProps,
  td,
  gridId,
  colDefinition,
  exitEdit
}: CellRenderProps) => {
  const { dispatch } = useStore();
  const { grids, setGrid } = useGrid();
  const [previousDataItem, setPreviousDataItem] = useState<{
    [key: string]: any;
  }>({});
  const [value, setValue] = React.useState<number | null>(null);
  const [validationMsg, setValidationMsg] = useState<string | null>(null);
  const [validationPos, setValidationPos] =
    useState<GridValidationMessagePosType | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const mounted = useRef(false);
  const saveData = useRef<UnMountSaveData>();

  let inputRef: HTMLSpanElement | null;

  const isThisRowBeingEdited =
    grids.get(gridId)!.state.editMode &&
    !(grids.get(gridId)!.state.selectedRowIsExpanded ?? false) &&
    originalProps.dataItem[grids.get(gridId)!.dataItemKey] ===
      grids.get(gridId)!.state.selectedRow;

  // jon, 4/3/22: Save data needed for possible save on unmount since this must use a ref because useEffect below only knows values as they were on mount.
  useEffect(() => {
    saveData.current = {
      previousDataItem: previousDataItem,
      dataItem: originalProps.dataItem,
      value: value,
      isThisRowBeingEdited: isThisRowBeingEdited,
      saving: saving
    };
  }, [
    previousDataItem,
    originalProps.dataItem,
    value,
    isThisRowBeingEdited,
    saving
  ]);

  // jon, 4/3/22: Added effect to monitor if component is mounted. Save can now be initiated by scrolling off the grid, so need to check for mounted and
  //   initiate a save if value has changed.
  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;

      if (
        saveData.current &&
        saveData.current.isThisRowBeingEdited &&
        !saveData.current.saving &&
        saveData.current.previousDataItem[originalProps.field!] !== undefined &&
        saveData.current.value !==
          saveData.current.previousDataItem[originalProps.field!]
      ) {
        console.log(
          `Saving changes to number field due to control unmounting...`
        );
        setSaving(true);
        doBlur(saveData.current.previousDataItem, saveData.current.dataItem);
      }
    };
  }, []);

  // will, 2/15/22: Added effect to monitor if component is mounted. Mobile causes unmounting issues.
  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  // Effect runs after validation msg changes. This gives us an opportunity to set the validation message position correctly relative to input.
  useEffect(() => {
    if (validationMsg !== null && inputRef !== null) {
      setValidationPos({
        posTop: inputRef.offsetTop + 36,
        posLeft: inputRef.offsetLeft
      });
    }
  }, [validationMsg]);

  // Layout Effect runs after validation position changes. This gives us an opportunity to set focus in the input after all renders.
  useLayoutEffect(() => {
    if (validationPos !== null && inputRef !== null) {
      // set focus in input where validation error occurred
      inputRef.querySelector("input")!.focus();
    }
  }, [validationPos]);

  // Effect runs on first load and when field value changes to set the value
  useEffect(() => {
    if (isThisRowBeingEdited) {
      if (
        originalProps.dataItem[originalProps.field!] !== undefined &&
        originalProps.dataItem[originalProps.field!] !== value
      ) {
        const numberVal =
          originalProps.dataItem[originalProps.field!] !== undefined &&
          originalProps.dataItem[originalProps.field!] !== null &&
          originalProps.dataItem[originalProps.field!] !== "" &&
          !isNaN(originalProps.dataItem[originalProps.field!])
            ? parseInt(originalProps.dataItem[originalProps.field!], 10)
            : null;
        setValue(numberVal);
      }
    }
  }, [originalProps.dataItem[originalProps.field!]]);

  // Layout Effect runs when the grid's current edit field changes so we can see if we should set the focus if this is that field in insert mode
  useLayoutEffect(() => {
    if (
      grids.get(gridId)!.state.editFieldName === originalProps.field! &&
      grids.get(gridId)!.state.insertMode === true &&
      inputRef !== null
    ) {
      inputRef.querySelector("input")!.focus();
    }
  }, [grids.get(gridId)!.state.editFieldName]);

  const enterEdit = (
    type: string,
    dataItem: { [key: string]: any },
    field: string | undefined
  ): void => {
    // console.log(
    //   `NUMBER CELL ENTER EDIT ${colDefinition?.field} - ${type}: `,
    //   dataItem,
    //   field
    // );
    setPreviousDataItem(dataItem);
    setGrid({
      type: GridActions.editFieldName,
      payload: { gridId, gridData: field }
    });
  };

  const doBlur = async (
    prevDataItem: { [key: string]: any },
    curDataItem: { [key: string]: any }
  ) => {
    if (validationMsg !== null) {
      // If this cell is/was invalid, unlock grid. If cell is still invalid, the exitEdit will re-lock the grid after save attempt.
      unlockGridIfLockedOut(gridId, grids, setGrid);
    }
    setValidationPos(null);
    setValidationMsg(null);
    const result = await exitEdit(
      "ON BLUR",
      originalProps.field!,
      prevDataItem,
      curDataItem,
      [originalProps.field!]
    );

    if (result !== null) {
      // will, 2/15/22: Added mount check for mobile events causing component to unmount before validation
      if (mounted.current) {
        // Validation on this field failed so set the message which fires the effects above to re-focus the input
        setValidationMsg(result);
        enterEdit(
          "ON BLUR VALIDATION FAILED",
          previousDataItem,
          originalProps.field!
        );
      } else {
        handleUnmountedUpdate(result);
      }
    } else {
      setPreviousDataItem(curDataItem);
    }

    if (mounted.current) setSaving(false);
  };

  const additionalProps = {
    ref: (tdElem: HTMLTableCellElement) => {
      inputRef = tdElem && tdElem.querySelector(".k-numerictextbox");
      inputRef?.addEventListener("keydown", (e) => {
        if ((e.key === "ArrowDown" || e.key === "ArrowUp") && e.ctrlKey) {
          e.stopImmediatePropagation();
          const target: Element = e.target as Element;
          const columnIndex = target
            .closest("[data-grid-col-index]")
            ?.getAttribute("data-grid-col-index");
          // trigger blur to initiate save
          (e.target as HTMLElement).blur();
          // jon, 3/23/22: I added a timeout here to allow for the save and then the navigation event since sometimes the nav would stop working afterwards.
          window.setTimeout(() => {
            setGrid({
              type: GridActions.onKeyNavigation,
              payload: {
                gridId,
                gridData: { key: e.key, colIndex: columnIndex }
              }
            });
          }, 1);
        }
      });
    },
    onFocus: () => {
      // Do not fire focus event if validation message showing since this resets the "previous" value to the new, incorrect one.
      if (validationMsg === null) {
        enterEdit("ON FOCUS", originalProps.dataItem, originalProps.field!);
      }
    },
    onBlur: async () => {
      // jon, 4/11/22: Set saving flag so unmount does not also fire update if input is blurred and then unmounted immediately. Can cause Unique Key violation when none exists.
      setSaving(true);
      await doBlur(previousDataItem, originalProps.dataItem);
    },
    onKeyDown: (e: KeyboardEvent) => {
      // If user hits ESC, undo any changes made to this cell and do not save
      if (e.key === "Escape") {
        setValidationPos(null);
        setValidationMsg(null);

        unlockGridIfLockedOut(gridId, grids, setGrid);
        handleItemChange(previousDataItem[originalProps.field!], true);
      }
    }
  };

  // will, 2/15/22: Reformatted handleItemChange() without any setState functions to be usable when component is unmounted
  // and to set failure message
  const handleUnmountedUpdate = (failureMessage: string) => {
    // This sets the new value in the grid data
    const newDataItem = { ...saveData.current?.dataItem };
    newDataItem[originalProps.field!] =
      saveData.current?.previousDataItem[originalProps.field!];

    const grid = grids.get(gridId)!;

    if (grid.state.insertMode) {
      setGrid({
        type: GridActions.onInsertItemChange,
        payload: { gridId, gridData: newDataItem }
      });
    } else {
      const editedRecordID = saveData.current?.dataItem[grid.dataItemKey!];
      const records = grid.records.map((record) => {
        let newRecord = record;
        if (record[grid.dataItemKey!] === editedRecordID) {
          newRecord = newDataItem;
        }
        return newRecord;
      });

      setGrid({
        type: GridActions.onItemChange,
        payload: { gridId, gridData: records }
      });
    }

    setGrid({
      type: GridActions.toggleGridLockoutMode,
      payload: { gridId, gridData: false }
    });

    dispatch({
      type: StoreActions.addNotification,
      payload: {
        message: "Save failed with the following message:\n" + failureMessage,
        messageType: "warning",
        closable: true
      }
    });
  };

  const handleItemChange = (
    newValue: number | null,
    resetPreviousDataItem: boolean
  ) => {
    unlockGridIfLockedOut(gridId, grids, setGrid);
    setValue(newValue);

    // This sets the new value in the grid data
    const newDataItem = { ...originalProps.dataItem };
    newDataItem[originalProps.field!] = newValue;

    // This reset is used when user clicks ESC
    if (resetPreviousDataItem) {
      setPreviousDataItem(newDataItem);
    }

    const grid = grids.get(gridId)!;

    if (grid.state.insertMode) {
      setGrid({
        type: GridActions.onInsertItemChange,
        payload: { gridId, gridData: newDataItem }
      });
    } else {
      const editedRecordID = originalProps.dataItem[grid.dataItemKey!];
      const records = grid.records.map((record) => {
        let newRecord = record;
        if (record[grid.dataItemKey!] === editedRecordID) {
          newRecord = newDataItem;
        }
        return newRecord;
      });

      setGrid({
        type: GridActions.onItemChange,
        payload: { gridId, gridData: records }
      });
    }
  };

  const onChange = async (newValue: number | null) => {
    handleItemChange(newValue, false);
  };

  // We don't show the number control if the field is not editable
  const isEditable =
    colDefinition?.editable === true ||
    (colDefinition?.editableInInsertOnly === true &&
      grids.get(gridId)!.state.insertMode === true);

  const showEditControl: boolean = isThisRowBeingEdited && isEditable === true;

  if (!showEditControl)
    return React.cloneElement(
      td,
      { ...td.props, ...additionalProps },
      <>
        {originalProps.dataItem[originalProps.field!] !== undefined &&
        originalProps.dataItem[originalProps.field!] !== null &&
        originalProps.dataItem[originalProps.field!] !== "" &&
        !isNaN(originalProps.dataItem[originalProps.field!])
          ? parseInt(
              originalProps.dataItem[originalProps.field!],
              10
            ).toLocaleString()
          : ""}
      </>
    );

  const elem = (
    <NumericTextBox
      value={value}
      onChange={(event: NumericTextBoxChangeEvent) => onChange(event.value)}
      min={colDefinition!.minValue}
      max={colDefinition!.maxValue}
    />
  );

  const validationWrapper =
    validationPos !== null && validationMsg !== null ? (
      <>
        <div
          className="validation-msg"
          style={{
            top: `${validationPos!.posTop}px`,
            left: `${validationPos!.posLeft}px`
          }}
        >
          {CarbonIcons.Warning}
          {validationMsg}
        </div>
        {elem}
      </>
    ) : (
      <>{elem}</>
    );

  const { className, ...tdprops } = td.props;

  const finalProps = {
    ...tdprops,
    className:
      validationMsg !== null ? className + " validation-error-cell" : className
  };

  return React.cloneElement(
    td,
    { ...finalProps, ...additionalProps },
    validationWrapper
  );
};
