import apiClient from "..";
import { AxiosResponse } from "axios";

export const RefreshZonesFromTemplate = (
  url: string
): Promise<AxiosResponse> => {
  const response = apiClient.post(url);
  return response;
};

export const InsertScheduleZoneRecords = (
  url: string
): Promise<AxiosResponse> => {
  const response = apiClient.post(url);
  return response;
};
