import { Helmet } from "react-helmet-async";
import { Box } from "grommet";
import { useNavigate } from "react-router";

// Components
import CarbonGridToolbar, {
  GridToolbarActionLabels,
  GridToolbarItem
} from "../../components/CarbonGrid/Toolbar/CarbonGridToolbar";
import CarbonGrid from "../../components/CarbonGrid/CarbonGrid";
import Authorizer from "../../components/Util/Authorizer";

// Types & Constants
import { GridType } from "../../types/grid";
import { gridColumns } from "./PackagesGridColumns";

// User/Permissions
import usePermissions from "../../hooks/auth/usePermissions";
import {
  CarbonIcons,
  EndpointResources,
  GridIDs,
  Placeholders,
  UserPermissions
} from "../../constants";
import {
  GridActionBarEvent,
  GridActionBarItem
} from "../../components/CarbonGrid/Toolbar/CarbonGridRowActionBar";
import { onPackageRowChange } from "./PackagesCustomEvents";

const Packages = (): JSX.Element => {
  const { canInsert, canDelete } = usePermissions();
  const navigate = useNavigate();
  const pagePermission = UserPermissions.PackagesPerms;

  const actionLabels: GridToolbarActionLabels = {
    singular: "package",
    plural: "packages",
    titleSingular: "Package",
    titlePlural: "Packages"
  };

  const actions: GridActionBarItem[] = [
    {
      show: true,
      label: "Zones",
      includeInExtrasMenu: false,
      icon: <span className="material-icons-outlined">highlight_alt</span>,
      onClick: (event: GridActionBarEvent) => {
        navigate(
          `/app/packages/${event.rowData.PackageID}/${EndpointResources.PackageZones}`
        );
      }
    }
  ];

  // will, 3/18/22: added default filter for IsActive
  const gridSettings: GridType = {
    actions,
    endpoints: {
      gridODataEndpoint: `/odata/${Placeholders.companyID}/gridpackages?$count=true&`,
      gridApiEndpoint: `/api/${Placeholders.companyID}/packages`,
      lookupEndpoints: [
        {
          lookupField: "StencilName",
          endpoint: `/api/${Placeholders.companyID}/lookupstencils`
        },
        {
          lookupField: "CampaignName",
          endpoint: `/api/${Placeholders.companyID}/lookupcampaigns`
        },
        {
          lookupField: "PackageTypeName",
          endpoint: `/api/${Placeholders.companyID}/lookuppackagetypes`
        },
        {
          lookupField: "MediaName1",
          endpoint: `/api/${Placeholders.companyID}/lookupmedia/static`
        },
        {
          lookupField: "MediaName2",
          endpoint: `/api/${Placeholders.companyID}/lookupmedia/static`
        },
        {
          lookupField: "PackageName1",
          endpoint: `/api/${Placeholders.companyID}/lookuppackages/static`
        },
        {
          lookupField: "PackageName2",
          endpoint: `/api/${Placeholders.companyID}/lookuppackages/static`
        }
      ]
    },
    records: [],
    total: 0,
    data: [],
    lookups: {},
    dataState: {
      pageSize: 25,
      take: 50,
      skip: 0,
      sort: [
        { field: "CompanyName", dir: "asc" },
        { field: "PackageName", dir: "asc" }
      ],
      filter: {
        logic: "and",
        filters: [{ field: "IsActive", operator: "equals", value: true }]
      }
    },
    dataItemKey: "PackageID",
    singularEntityName: "Package",
    pluralEntityName: "Packages",
    columns: gridColumns,
    showCopyBtn: canInsert(pagePermission),
    showDeleteBtn: canDelete(pagePermission),
    onRowChange: onPackageRowChange,
    state: {
      editMode: false,
      insertMode: false,
      selectedState: {},
      selectedRow: -1,
      showQuickFilter: false,
      lastSaveDate: null,
      showSavingIndicator: false,
      lockoutMode: false
    }
  };

  const previewBtn: GridToolbarItem = {
    show: true,
    enable: true,
    tooltip: "Open the viewer to preview the selected package",
    toggledOn: false
  };

  return (
    <>
      <Helmet>
        <title>Carbon | Packages</title>
      </Helmet>
      <Authorizer
        canView={pagePermission}
        auditLabel={`${actionLabels.titlePlural} screen`}
      >
        <Box align="start" fill>
          <CarbonGridToolbar
            gridId={GridIDs.Packages}
            screenIcon={CarbonIcons.Packages}
            screenTitle={actionLabels.titlePlural}
            actionLabels={actionLabels}
            permissions={pagePermission}
            previewBtn={previewBtn}
          />
          <Box fill className="carbon-grid-wrapper">
            <CarbonGrid
              gridId={GridIDs.Packages}
              gridSettings={gridSettings}
              gridClassName={"grid-with-actionbar"}
            />
          </Box>
        </Box>
      </Authorizer>
    </>
  );
};

export default Packages;
