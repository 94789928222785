import React, { createContext, useContext } from "react";
import { useImmerReducer } from "use-immer";
import { getSelectedState } from "@progress/kendo-react-grid";
import { GridActions, Placeholders } from "../../constants";
import { GridColumns, GridType } from "../../types/grid";
import { GridSelectorColumn } from "../../components/CarbonGrid/GridColumnSelector";
import { SortDescriptor } from "@progress/kendo-data-query";
import { GridLayout } from "../../types";

export type GridStateType = Map<string, GridType>;

export type GridAction = {
  type: GridActions;
  payload: {
    gridId: string;
    gridData: any;
  };
};

type GridContext = {
  grids: GridStateType;
  setGrid: React.Dispatch<GridAction>;
};

const getPageData = (draft: GridType): { [key: string]: any }[] => {
  // console.log("GET PAGE DATA: " + toODataString(draft.dataState));

  // Handle insert mode with an empty grid - don't splice out the insert row.
  const startSplice = draft.dataState.skip;
  const endSplice = Math.min(
    draft.dataState.skip! + draft.dataState.pageSize!,
    draft.total > draft.records.length ? draft.total : draft.records.length
  );
  const data = draft.records.slice(startSplice, endSplice);
  const selectedID = draft.state.selectedRow;
  const isDetailGridShowing = draft.state.selectedRowIsExpanded ?? false;

  const gridData = data.map((record) => {
    return {
      ...record,
      inEdit: draft.state.editMode && record[draft.dataItemKey!] === selectedID,
      selected: record[draft.dataItemKey!] === selectedID,
      expanded: isDetailGridShowing && record[draft.dataItemKey!] === selectedID
    };
  });

  const dataRows = [...gridData];
  if (draft.insertRow) {
    dataRows.unshift({
      ...draft.insertRow,
      inEdit: true,
      selected: true,
      expanded: false
    });
  }

  return dataRows;
};

const buildGridDataArrayFromFetchedData = (
  existingRecords: { [key: string]: any }[],
  dataItemKey: string,
  fetchedData: { [key: string]: any }[],
  newTotal: number,
  skip: number
): { [key: string]: any }[] => {
  let data: { [key: string]: any }[] = [];

  // If no records were returned, just send back empty array to
  if (newTotal === 0) return data;

  if (fetchedData.length === newTotal) {
    // If we fetched a number of records less than or equal to our page size (i.e., fetch array length is equal to new total),
    //  just use the fetched data.
    // jon, 4/12/22: If the initial data page is small so that ALL records are returned, we are never adding the Index property to the row which makes row
    //   navigation impossible using the arrow keys. This is most noticeable on Node Schedules, but it happens everywhere the grid initially loads < 50 records.
    //   Solution is to add the Index here.
    data = fetchedData;
    fetchedData.forEach((record, i) => {
      return (data[i] = {
        Index: i,
        ...record
      });
    });
  } else {
    // Initialize new records array of the correct length
    data = new Array(newTotal).fill({}).map((e, i) => ({
      Index: i
    }));
  }

  // Copy into this new data array any existing records we previously fetched
  if (existingRecords.length > 0) {
    existingRecords.forEach((record, i) => {
      if (record[dataItemKey] !== undefined) {
        return (data[i] = {
          Index: i,
          ...record
        });
      }
    });
  }

  // Now we are ready to populate the array with the new page of data
  fetchedData.forEach((record, i) => {
    // jon, 3/31/22: Because we are generally paging by 25 records at a time but fetching 50 records, the new data is always overlapping the existing data.
    //    This causes issues in edit mode where if you make a change in a field, but do not cause a blur event, and then scroll down, the next page load will
    //    reset the edit you just made (unless it goes off the top of the screen which now triggers a save).  To fix this, I am now checking here to see if
    //    the newly-fetched record's key already exists in the existingRecords array we used to populate the data array above.  If it does, I don't update
    //    that record again with fetched data from the server.  This should ensure edits do not ever get overwritten.
    //    Note that this also fixes the issue with the select checkbox on Global Changes, so I am removing that fix.
    if (
      record[dataItemKey] !== undefined &&
      !data.find((r) => r[dataItemKey] === record[dataItemKey])
    ) {
      return (data[i + skip] = {
        Index: i + skip,
        ...record
      });
    }
  });

  return data;
};

const buildInitialInsertRow = (
  draft: GridType,
  customDefaultValues?: { [key: string]: any }
): { [key: string]: any } => {
  const newRow: { [key: string]: any } = draft.columns!.reduce(function (
    row: { [key: string]: any },
    column: GridColumns
  ) {
    if (customDefaultValues?.[column.field]) {
      // if a custom default value exists, then set it, else use the default value set in the static columns
      row[column.field] = customDefaultValues[column.field];
    } else {
      row[column.field] = getDefaultColumnValue(draft, column);
    }
    return row;
  },
  {});

  newRow.Index =
    !draft.dataState.skip || draft.dataState.skip === 0
      ? 0
      : draft.dataState.skip!;

  return newRow;
};

const getDefaultColumnValue = (draft: GridType, column: GridColumns): any => {
  let value: any = "";
  // const editor: string = column.editor ? column.editor : "text";

  // If the column has a default value, use that (replace the placeholders with actual values). For company placeholders, we replace them
  //  with the values from the company dropdown selected value - unless All Companies is showing.
  if (column.defaultValue !== undefined) {
    const stringVal = `${column.defaultValue!}`;
    if (stringVal === Placeholders.companyID)
      value =
        draft.state.activeCompany && draft.state.activeCompany.companyId !== -1
          ? draft.state.activeCompany.companyId
          : null;
    else if (stringVal === Placeholders.companyName)
      value =
        draft.state.activeCompany && draft.state.activeCompany.companyId !== -1
          ? draft.state.activeCompany.companyName
          : null;
    else value = column.defaultValue!;
  } else {
    value = null;
  }

  return value;
};

const selectRowInGrid = (grid: GridType, rowIndex: number) => {
  // Select first record in grid when initial data is loaded
  //  Note: this currently only works for loaded records - it cannot be used to seek a row that is not loaded in the buffered rows.
  if (grid.isReadonly !== true) {
    if (grid.records.length > 0) {
      grid.state.selectedRow = grid.records[rowIndex][grid.dataItemKey!];
      grid.state.selectedRowData = grid.records[rowIndex];
    } else {
      grid.state.selectedRow = undefined;
      grid.state.selectedRowData = undefined;
    }
  }
};

function reducer(draft: GridStateType, action: GridAction): GridStateType {
  const gridId = action.payload.gridId;
  const event = action.payload.gridData;
  const grid = draft.get(gridId);

  switch (action.type) {
    case GridActions.resizeGrid:
      // console.log(`REDUCER - RESIZE GRID: `, event);
      if (grid) {
        grid!.height = event;
      }
      return draft;
    case GridActions.setGridWidth:
      if (grid) {
        grid!.state.gridWidth = event;
      }
      return draft;
    case GridActions.delete:
      console.log("REDUCER - DELETE GRID: ", action.payload);
      draft.delete(gridId);
      return draft;
    case GridActions.createGrid:
      console.log("REDUCER - CREATE GRID: ", action.payload);
      draft.set(gridId, event.settings);
      return draft;
    case GridActions.setInitialGridSettings:
      console.log("REDUCER - SET INITIAL GRID SETTINGS: ", action.payload);
      if (!grid) return draft;

      grid!.state.pathname = event.pathname;
      grid!.state.visibilitySelectorColumns = event.visibleColumns;
      grid!.dataState.sort = event.initialSort;
      grid!.dataState.filter = event.initialFilter;
      return draft;
    case GridActions.initialData:
      console.log("REDUCER - INITIAL GRID DATA: ", action.payload);
      if (grid) {
        grid!.records = buildGridDataArrayFromFetchedData(
          [],
          grid.dataItemKey,
          event.data.value,
          event.data["@odata.count"],
          0
        );
        grid!.total = event.data["@odata.count"];

        // Select first record in grid when initial data is loaded
        selectRowInGrid(grid!, 0);
      }
      return draft;
    case GridActions.updateData:
      if (!grid) return draft;
      if (grid!.state.lockoutMode) return draft;
      console.log("REDUCER - UPDATE DATA: ", action.payload);
      grid!.records = buildGridDataArrayFromFetchedData(
        event.isReset ? [] : grid!.records,
        grid!.dataItemKey,
        event.data.value,
        event.data["@odata.count"],
        event.pageStart
      );
      grid!.total = event.data["@odata.count"];
      grid!.data = getPageData(grid!);
      return draft;
    case GridActions.updateDataPage:
      if (grid) {
        if (grid!.state.lockoutMode) return draft;
        // console.log("REDUCER - UPDATE DATA PAGE: ", action.payload);
        grid!.data = getPageData(grid!);
      }
      return draft;
    case GridActions.updateLookupData:
      console.log("REDUCER - UPDATE GRID LOOKUP DATA: ", action.payload);
      // jon, 1/14/22: When subgrids are opened and closed quickly, lookup data that was loaded may try to get set in a grid that does not exist.
      if (grid && grid.lookups) {
        grid.lookups[event.lookupField] = event.lookupData;
      }
      return draft;
    case GridActions.updateActiveCompany:
      console.log("REDUCER - UPDATE ACTIVE COMPANY: ", action.payload);
      if (grid && grid.state) {
        grid.state.activeCompany = event.activeCompany;

        // jon, 1/14/22: If active company changes in dropdown, make sure each grid is forced out of lockout, insert, edit, and subgrid mode.
        grid.state.lockoutMode = false;
        grid.state.insertMode = false;
        grid!.insertRow = undefined;
        grid.state.editMode = false;
        grid.state.selectedRowIsExpanded = false;

        // will, 2/28/22: Triggers a gird refresh so that if you are in edit/insert mode that it preserves the current quickfilter settings. This
        // was moved here to ensure the refresh did not effect insert/update functionality.
        if (event.refresh) {
          grid!.state.refreshGrid = event.refresh;
          grid!.dataState.skip = 0;
          grid!.lookups = {};
        }
      }
      return draft;
    case GridActions.updateConfig:
      if (!grid) return draft;
      if (grid!.state.lockoutMode) return draft;
      console.log("REDUCER - UPDATE GRID CONFIG: ", action.payload);
      grid!.dataState = event;
      return draft;
    case GridActions.editFieldName: {
      if (!grid) return draft;
      console.log("REDUCER - EDIT FIELD NAME: ", action.payload);
      grid!.state.editFieldName = event;
      return draft;
    }
    case GridActions.updateColumns: {
      if (!grid) return draft;
      console.log("REDUCER - UPDATE COLUMNS: ", action.payload);
      // update the grids default columns with the grid's current state columns
      let initialColumnsCopy = [...grid!.columns!]; // [...currentActiveGrid?.columns!];
      // const initialGridSortCopy = [...grid?.dataState.sort!];
      const userLayouts: GridLayout = event.userLayouts;
      if (userLayouts.columnOrderLayout) {
        const orderedInitialColumns = [];
        const customColumnOrderLayout = userLayouts.columnOrderLayout;

        // sort the custom layout array by the orderIndex field
        customColumnOrderLayout.sort((a: any, b: any) => {
          return a.orderIndex - b.orderIndex;
        });
        // append the sorted, visible columns to the array first
        for (const col of customColumnOrderLayout) {
          const realColumn = initialColumnsCopy.find(
            (val: any) => val.field === col.field
          );
          orderedInitialColumns.push(realColumn!);
        }

        // append remaining columns (invisible columns)
        initialColumnsCopy.forEach((col: any) => {
          const column = customColumnOrderLayout.find(
            (val: any) => col.field === val.field
          );
          // column does not exist in sorted, visible array, so append to end
          if (!column) {
            orderedInitialColumns.push(col);
          }
        });
        initialColumnsCopy = orderedInitialColumns;
      }

      // custom column visibility layout
      if (userLayouts.columnVisibilityLayout) {
        // user has custom column vibiility settings set for this grid
        // so we must override the initial default
        const userColumns = userLayouts.columnVisibilityLayout;
        initialColumnsCopy = initialColumnsCopy.map((column: any) => {
          const customShowProperty = userColumns.find(
            (value: any) => value.field === column.field
          );
          const tempColumn = { ...column };
          if (customShowProperty) {
            tempColumn.show = customShowProperty.show;
          } else {
            tempColumn.show = column.show;
          }
          return tempColumn;
        });
      }

      // custom column size layout
      if (userLayouts.columnResizeLayout) {
        const userColumns = userLayouts.columnResizeLayout;
        initialColumnsCopy = initialColumnsCopy.map((column: any) => {
          const customWidthProperty = userColumns.find(
            (value: any) => value.field === column.field
          );
          const tempColumn = { ...column };
          if (customWidthProperty) {
            tempColumn.width = customWidthProperty.width;
          } else {
            tempColumn.width = column.width;
          }
          return tempColumn;
        });
      }

      // custom grid sort settings set by user
      if (userLayouts.gridSort) {
        grid!.dataState.sort = userLayouts.gridSort as SortDescriptor[];
      }

      const currentSelectorColumns = grid!.state.visibilitySelectorColumns;
      const gridSelectorColumns: GridSelectorColumn[] = initialColumnsCopy.map(
        (col) => {
          const columnSelector = currentSelectorColumns?.find(
            (selector) => selector.field === col.field
          );
          const tempCol = {} as GridSelectorColumn;
          tempCol.field = col.field!;
          tempCol.defaultShow = columnSelector!.defaultShow!;
          tempCol.required = col.required!;
          tempCol.show = columnSelector!.show!;
          tempCol.isCombinedCol = columnSelector?.isCombinedCol!;
          tempCol.systemHidden = col.systemHidden === true;
          tempCol.title = col.title!;
          return tempCol;
        }
      );

      grid!.state.visibilitySelectorColumns = gridSelectorColumns;
      grid!.columns = initialColumnsCopy;

      return draft;
    }
    case GridActions.onUpdateVisibleColumns: {
      if (!grid) return draft;
      if (grid!.state.lockoutMode) return draft;
      console.log("REDUCER - UPDATE VISIBLE COLS: ", event.columns);
      const selectorColumns: GridSelectorColumn[] = event.columns;
      grid!.state.visibilitySelectorColumns = selectorColumns;
      grid!.columns = grid!.columns!.map((column) => {
        return {
          ...column,
          show:
            selectorColumns.find((x) => x.field === column.field)!.show === true
        };
      });
      return draft;
    }
    case GridActions.onItemChange: {
      // jon, 1/18/22: Removed check for lockoutMode here since this action must be called to update the data in the grid to fix the validation error.
      //   Otherwise, the user cannot type into any fields to fix the problems we are warning about.
      // jon, 2/10/22: If user collapses just-edited subgrid in parent grid quickly enough, the subgrid may no longer exist, so checking for that here.
      if (grid) {
        console.log("REDUCER - ON ITEM CHANGE: ", action.payload);
        grid!.records = action.payload.gridData;
        grid!.data = getPageData(grid!);
      }
      return draft;
    }
    case GridActions.onInsertItemChange: {
      if (!grid) return draft;
      if (!grid!.state.insertMode) return draft;
      console.log("REDUCER - ON INSERT ITEM CHANGE: ", action.payload);
      grid!.insertRow = action.payload.gridData;
      grid!.data = getPageData(grid!);
      return draft;
    }
    case GridActions.onExpandRow: {
      if (!grid) return draft;
      if (grid!.state.lockoutMode || grid!.state.insertMode) return draft;
      console.log("REDUCER - ON EXPAND ROW: ", action.payload);
      grid!.state.selectedRowIsExpanded = event.value;
      if (grid!.isReadonly !== true) {
        grid!.state.selectedRow = event.dataItem[grid!.dataItemKey!];
        grid!.state.selectedRowData = event.dataItem;
      }
      grid!.data = getPageData(grid!);
      return draft;
    }
    case GridActions.onRowClick: {
      // will, 2/9/22: Added check for grid's existance because the Campaigns Dashboard has an action row but does not create an official "grid"
      if (grid) {
        if (
          grid!.state.lockoutMode ||
          grid!.state.insertMode ||
          grid!.isReadonly === true
        )
          return draft;
        // console.log("REDUCER - ON ROW CLICK: ", action.payload);

        grid!.state.selectedRow = event.dataItem[grid!.dataItemKey!];
        grid!.state.selectedRowData = event.dataItem;
        grid!.data = getPageData(grid!);
      }
      return draft;
    }
    case GridActions.onSelectionChange: {
      // console.log("REDUCER - ON SELECTION CHANGE 1 ");
      if (!grid) return draft;

      // jon, 3/30/22: Do not change selection if in edit mode.  This is only needed when changing rows while NOT in edit mode and it was messing up saving on
      //   mobile when clicking to a new row to save.
      if (
        grid!.state.lockoutMode ||
        grid!.state.insertMode ||
        grid!.isReadonly === true ||
        grid!.state.editMode
      )
        return draft;
      // console.log("REDUCER - ON SELECTION CHANGE 2: ", action.payload);
      grid!.state.selectedState = getSelectedState({
        event,
        selectedState: grid!.state.selectedState!,
        dataItemKey: grid!.dataItemKey!
      });
      grid!.state.selectedRow = Number(
        Object.keys(grid!.state.selectedState!)[0]
      );
      grid!.state.selectedRowData = grid!.records.find(
        (record) => record[grid!.dataItemKey!] === grid!.state.selectedRow
      );
      grid!.data = getPageData(grid!);
      return draft;
    }
    case GridActions.onKeyNavigation: {
      if (!grid) return draft;
      if (
        grid!.state.lockoutMode ||
        grid!.state.insertMode ||
        grid!.isReadonly === true
      )
        return draft;

      console.log(`REDUCER - ON KEY NAVIGATION: `, action.payload);
      const key: string = event.key; // either ArrowUp or ArrowDown
      const colIndex = event.colIndex;
      let newIndex: number = -1;

      if (key === "ArrowUp") {
        // decrement index
        newIndex = grid!.state.selectedRowData!.Index - 1;
      } else if (key === "ArrowDown") {
        // increment index
        newIndex = grid!.state.selectedRowData!.Index + 1;
      }

      if (newIndex >= 0 && newIndex < grid!.total) {
        const newSelectedRecord = grid!.records.find(
          (record) => record.Index === newIndex
        );
        if (newSelectedRecord) {
          const newRecordId = newSelectedRecord![grid!.dataItemKey];
          grid!.state.selectedState = { [newRecordId]: true };
          grid!.state.selectedRow = Number(
            Object.keys(grid!.state.selectedState!)[0]
          );
          grid!.state.selectedRowData = grid!.records.find(
            (record) => record[grid!.dataItemKey!] === grid!.state.selectedRow
          );

          grid!.state!.focusedColumnIndex = colIndex;
          console.log(
            `KEY NAVIGATION: Successfully navigated to row with index ${newIndex}.`
          );
        } else {
          console.log(
            `KEY NAVIGATION: Unable to navigate to record. Index ${newIndex} not found in grid records.`
          );
        }
      } else {
        console.log(
          `KEY NAVIGATION: Unable to navigate to record. New row index is out of range (0-${
            grid!.total
          }): ${newIndex}`
        );
      }

      grid!.data = getPageData(grid!);
      return draft;
    }
    case GridActions.onDataStateChange: {
      if (!grid) return draft;
      if (grid!.state.lockoutMode) return draft;
      // console.log("REDUCER - ON DATA STATE CHANGE: ", action.payload);
      grid!.dataState = event.dataState;
      grid!.data = getPageData(grid!);
      return draft;
    }
    case GridActions.onDeleteRecord: {
      if (!grid) return draft;
      if (grid!.state.lockoutMode || grid!.state.insertMode) return draft;
      console.log("REDUCER - ON DELETE RECORD: ", action.payload);

      // If we deleted the record successfully, remove it from the grid.
      if (event.recordDeleted) {
        const index = grid!.records.findIndex(
          (item) => item[grid!.dataItemKey!] === grid!.state.recordIdToDelete
        );
        if (index === -1)
          console.log(
            `Record with id ${
              grid!.state.recordIdToDelete
            } to delete was not found in records array!`
          );
        grid!.records.splice(index, 1);
        grid!.data = getPageData(grid!);
      }
      grid!.state.recordIdToDelete = event.id;
      return draft;
    }
    case GridActions.toggleQuickFilter: {
      if (!grid) return draft;
      if (grid!.state.lockoutMode || grid!.state.insertMode) return draft;
      // console.log("REDUCER - TOGGLE QUICK FILTER: ", action.payload);
      grid!.state.showQuickFilter = !grid!.state.showQuickFilter;
      return draft;
    }
    case GridActions.toggleCopyRecordDialog: {
      if (!grid) return draft;
      // console.log("REDUCER - TOGGLE COPY RECORD DIALOG: ", action.payload);
      const showCopyRecordDialog = event.data;
      grid!.state.showCopyRecordDialog = showCopyRecordDialog;
      return draft;
    }
    case GridActions.togglePreviewer: {
      if (!grid || grid!.state.lockoutMode || grid!.state.insertMode)
        return draft;
      // console.log("REDUCER - TOGGLE PREVIEWER: ", action.payload);
      const value = event.value;
      grid!.state.showPreviewer = value;
      return draft;
    }
    case GridActions.toggleEditMode: {
      if (!grid) return draft;
      if (grid!.state.lockoutMode || grid!.state.insertMode) return draft;
      // console.log("REDUCER - TOGGLE EDIT MODE: ", action.payload);
      grid!.state.editMode = !grid!.state.editMode;
      grid!.data = getPageData(grid!);
      return draft;
    }
    case GridActions.toggleInsertMode: {
      if (!grid) return draft;
      // console.log("REDUCER - TOGGLE INSERT MODE: ", action.payload);
      const isInsertOn: boolean = event.insertOn;
      const data: null | { [key: string]: any } = event.data;
      const customDefaultValues: { [key: string]: any } =
        event.customDefaultValues;

      if (isInsertOn) {
        // Don't do anything if we are already locking out the grid (due to insert mode being on)
        if (grid!.state.lockoutMode) return draft;
        grid!.state.selectedRow = -1;
        grid!.state.selectedRowData = undefined;
        // Insert mode also uses edit mode so both are enabled here. We also lock out the grid in insert mode so user must Cancel or Save to exit.
        grid!.state.editMode = true;
        grid!.state.insertMode = true;

        // jon, 1/13/22: Remove expanded row for any subgrids
        grid!.state.selectedRowIsExpanded = false;

        // Insert row is tracked separate from main set of records so we can always draw it at the top of the grid in onPageData
        grid!.insertRow = buildInitialInsertRow(grid!, customDefaultValues);
        // console.log("insert row", grid!.insertRow);
        grid!.state.lockoutMode = true;
      } else {
        // Insert mode done - either through cancel or successful save
        grid!.state.insertMode = false;
        grid!.state.lockoutMode = false;
        grid!.state.editMode = false;
        grid!.insertRow = undefined;

        if (data !== null) {
          // Insert was successful. Merge the returned data into a new row at the current position in the grid.
          // jon, 2/7/22: Inserted row was missing Index property which made the ctrl-arrow key not work on it. The indexes for rows above this one
          //   also need to be incremented for navigation to work, otherwise we end up with a duplicate index.
          const newRecords: { [key: string]: any }[] = [];
          grid!.records.forEach((record, i) => {
            if (record[grid!.dataItemKey!] !== undefined) {
              if (i < grid!.dataState.skip!) {
                return (newRecords[i] = {
                  ...record,
                  Index: i
                });
              } else {
                // Add 1 to the indexes above our insertion point
                return (newRecords[i] = {
                  ...record,
                  Index: i + 1
                });
              }
            }
          });

          // Splice in the new row and give it an index that matches the skip value
          newRecords.splice(grid!.dataState.skip!, 0, {
            Index: grid!.dataState.skip!,
            ...data
          });

          grid!.records = newRecords;
          grid!.total = grid!.total + 1;
        }

        selectRowInGrid(grid!, grid!.dataState.skip!);
      }

      grid!.data = getPageData(grid!);
      return draft;
    }
    case GridActions.onAfterRecordCopy: {
      if (!grid) return draft;
      // console.log("REDUCER - SHOW NEW DATA AT TOP: ", action.payload);
      const data: null | { [key: string]: any } = event.data;
      if (data !== null) {
        // copy was successful. Merge the returned data into a new row at the current position in the grid.
        const index = grid!.records.findIndex(
          (item) => item[grid!.dataItemKey!] === grid!.state.selectedRow
        );
        grid!.records.splice(index + 1, 0, data);
        grid!.total = grid!.total + 1;
        selectRowInGrid(grid!, index + 1);
        grid!.data = getPageData(grid!);
        grid!.state.showCopyRecordDialog = false;
      }
      return draft;
    }
    case GridActions.toggleSavingIndicator: {
      // console.log("REDUCER - TOGGLE SAVING INDICATOR: ", action.payload);
      // jon, 2/10/22: If user collapses just-edited subgrid in parent grid quickly enough, the subgrid may no longer exist, so checking for that here.
      if (grid) {
        grid!.state.showSavingIndicator = event.show;
      }
      return draft;
    }
    case GridActions.updateLastSaveDate: {
      // console.log("REDUCER - UPDATE LAST SAVE DATE: ", action.payload);
      // jon, 2/10/22: If user collapses just-edited subgrid in parent grid quickly enough, the subgrid may no longer exist, so checking for that here.
      if (grid) {
        grid!.state.lastSaveDate = event.date;
      }
      return draft;
    }
    case GridActions.toggleGridLockoutMode: {
      if (!grid) return draft;
      if (grid!.state.insertMode) return draft;
      // console.log("REDUCER - TOGGLE GRID LOCKOUT MODE: ", action.payload);
      grid!.state.lockoutMode = event;
      return draft;
    }
    case GridActions.toggleRefreshGrid: {
      if (!grid) return draft;
      if (grid!.state.lockoutMode || grid!.state.insertMode) return draft;
      // console.log("REDUCER - TOGGLE GRID REFRESH: ", action.payload);
      grid!.state.refreshGrid = event;
      grid!.state.editMode = false;
      grid!.state.selectedRowIsExpanded = false;
      grid!.dataState.skip = 0;
      grid!.lookups = {};

      // Unselected any selected values if this grid has selected checkbox column
      const selectColumn = grid!.columns!.find(
        (col) => col.editor === "selectcheckbox"
      );
      if (selectColumn) {
        grid!.state.selectedPKList = [];
        grid!.state.selectAllChecked = false;
        const records = grid!.records.map((record) =>
          record !== undefined && record[grid!.dataItemKey] !== undefined
            ? {
                ...record,
                [selectColumn.field]: false
              }
            : record
        );
        grid!.records = records;
      }

      return draft;
    }
    case GridActions.saveGridScrollPosition: {
      if (!grid) return draft;
      // This is used to save the grid scroll position for resetting after insert and when coming back from a "tab" page. The grid likes to scroll itself to the top in
      //  these cases and that messes up the virtual scroll since the grid will show data as if scrolled, but really be at the top.  Subsequent scrolling will jump back
      //  to the top.
      // console.log("REDUCER - SAVE SCROLL POSITION: ", action.payload);
      grid!.state.scrollTopPosition = event;
      return draft;
    }
    case GridActions.setCustomFilter: {
      if (!grid) return draft;
      // This is used to programmatically set a filter on the grid and run it - used for stuff like showing just-upload files after media insert.
      //  The refreshGrid setting below triggers the server reload in CarbonGrid to actually load/filter the data.
      console.log("REDUCER - SET CUSTOM FILTER: ", action.payload);
      grid!.dataState.filter = event;
      grid!.dataState.skip = 0;
      grid!.state.showQuickFilter = true;
      grid!.state.refreshGrid = true;
      return draft;
    }
    case GridActions.setColumnOrderLayout: {
      if (!grid) return draft;
      grid!.state.columnOrderLayout = event.columns;
      grid!.columns = event.columns;
      return draft;
    }
    case GridActions.setSortOrderLayout: {
      if (!grid) return draft;
      grid!.state.gridSortLayout = event.sortLayout;
      return draft;
    }
    case GridActions.setColumnResizeLayout: {
      if (!grid) return draft;
      let resizeLayout = grid!.state?.columnResizeLayout;
      const newColumn = event.data;
      if (!newColumn || newColumn.field === "") return draft;

      if (!resizeLayout) {
        resizeLayout = [];
      }

      const itemIndex = resizeLayout.findIndex(
        (val) => val.field === newColumn.field
      );

      if (itemIndex !== -1) {
        // item already exists, replace it
        resizeLayout[itemIndex] = newColumn;
      } else {
        resizeLayout.push(newColumn);
      }

      grid!.state!.columnResizeLayout = [...resizeLayout];

      const realColIndex = grid!.columns?.findIndex(
        (val) => val.field === newColumn.field
      );

      // update real draft value in columns array
      grid!.columns![realColIndex!].width = newColumn.width;
      return draft;
    }
    case GridActions.setDefaultColumnsAndGridProps: {
      if (!grid) return draft;
      // set the default columns and grid props prior to altering the grid state with custom
      // user settings
      grid!.defaultColumns = event.columns;
      grid!.defaultSort = event.sort;
      return draft;
    }
    case GridActions.restoreLayout: {
      if (!grid) return draft;
      // will, 2/2/22: on layout restore, since the grid sets the 'show' values when creating
      // the grid, need to reset them since we are actively viewing the grid
      grid!.columns = grid!.defaultColumns!.map((column) => {
        const tempColumn = { ...column };
        if (column.defaultShow) tempColumn.show = true;
        return tempColumn;
      });

      grid!.dataState.sort = grid!.defaultSort!;

      const defaultSelectorColumns: GridSelectorColumn[] =
        grid!.defaultColumns!.map((val) => {
          const tempGridSelectorColumn: GridSelectorColumn = {
            field: val.field,
            defaultShow: val.defaultShow,
            required: val.required,
            show: val.defaultShow,
            title: val.title,
            isCombinedCol: val.combinedCol !== undefined,
            systemHidden: val.systemHidden === true
          };

          return tempGridSelectorColumn;
        });
      grid!.state.visibilitySelectorColumns = defaultSelectorColumns;
      return draft;
    }
    case GridActions.updateColumnState: {
      if (!grid) return draft;
      console.log("REDUCER - UPDATE COLUMN STATE: ", action.payload);
      grid!.columns = event.columns;
      grid!.dataState.sort = event.sort;
      // leaving this commented out for now just in case it breaks something -Ali
      // update selectors (visiblity checkbox selectors in layout menu) with column state change
      // const selectorColumns: GridSelectorColumn[] = grid!.columns!.map(
      //   (val) => {
      //     const tempGridSelectorColumn: GridSelectorColumn = {
      //       field: val.field,
      //       defaultShow: val.defaultShow,
      //       required: val.required,
      //       show: val.defaultShow,
      //       title: val.title,
      //       isCombinedCol: val.combinedCol !== undefined,
      //       systemHidden: val.systemHidden === true
      //     };

      //     return tempGridSelectorColumn;
      //   }
      // );
      // grid!.state.visibilitySelectorColumns = selectorColumns;
      return draft;
    }
    case GridActions.setVisiblitySelectorColumns: {
      if (!grid) return draft;
      console.log("REDUCER - SET VISIBILITY COLUMNS: ", action.payload);
      grid!.state.visibilitySelectorColumns = event.columns;
      return draft;
    }
    case GridActions.setParentRowData: {
      if (!grid) return draft;
      // This sets the selected row data for a subgrid's parent grid.
      // console.log("REDUCER - SET PARENT ROW DATA: ", action.payload);
      grid!.state.parentSelectedRowData = event;
      return draft;
    }
    case GridActions.onSetTextareaDialogParams: {
      if (!grid) return draft;
      if (grid!.state.lockoutMode || grid!.state.insertMode) return draft;
      // console.log("REDUCER - ON SET TEXTAREA DIALOG PARAMS: ", action.payload);
      grid!.state.textAreaDialogParams = event;
      return draft;
    }
    case GridActions.onAddNewNodeFilter: {
      if (!grid) return draft;
      grid!.state.showAdvancedFilterDialog = true;
      // new filter addition
      grid!.state.addingNewFilter = true;
      return draft;
    }
    case GridActions.onEditNodeFilter: {
      if (!grid) return draft;
      grid!.state.showAdvancedFilterDialog = true;
      // edit existing filter
      grid!.state.activeFilterId = event.nodeFilterId;
      grid!.state.editingFilter = true;
      return draft;
    }
    case GridActions.onCloseFilterDialog: {
      if (!grid) return draft;
      grid!.state.showAdvancedFilterDialog = false;
      grid!.state.addingNewFilter = false;
      grid!.state.editingFilter = false;
      return draft;
    }
    case GridActions.onNodeFilterDropdownChange: {
      if (!grid) return draft;
      grid!.state.activeFilterId = event.nodeFilterId;
      return draft;
    }
    case GridActions.onSaveAdvancedFilter: {
      if (!grid) return draft;
      grid!.state.activeFilterId = event.newFilterId;
      return draft;
    }
    case GridActions.toggleSelectAllCheckbox: {
      if (!grid) return draft;
      if (grid!.state.lockoutMode) return draft;
      // ("REDUCER - TOGGLE SELECT ALL CHECKBOX: ", action.payload);

      grid!.state.selectAllChecked = event.value === true;

      // Check or uncheck all the select checkboxes (indicated by event field) in the loaded records
      const records = grid!.records.map((record) =>
        record !== undefined && record[grid!.dataItemKey] !== undefined
          ? {
              ...record,
              [event.field]: event.value
            }
          : record
      );

      grid!.records = records;
      grid!.data = getPageData(grid!);
      return draft;
    }
    case GridActions.buildSelectedPKList: {
      if (!grid) return draft;
      // console.log("REDUCER - BUILD SELECTED PK LIST: ", action.payload);

      // Locate selection checkbox column
      const selectColumnName = grid!.columns!.find(
        (col: GridColumns) => col.editor === "selectcheckbox"
      )?.field;

      if (selectColumnName === undefined) {
        throw new Error(
          `DEVELOPER: You must define a grid column with editor='selectcheckbox' to call buildSelectedPKList.`
        );
      }

      // Build the array of all selected primary key values selected with the select checkbox or select all
      // If select all is checked, we collect all the unchecked values in the pklist.  Could be empty, indicating we truly want ALL records.
      // Otherwise, we collect all the checked values only
      const wantChecked: boolean = grid!.state.selectAllChecked !== true;
      const pklist: number[] = [];

      for (let i = 0; i < grid!.records.length; i++) {
        if (
          grid!.records[i] !== undefined &&
          grid!.records[i][grid!.dataItemKey] !== undefined
        ) {
          if (wantChecked && grid!.records[i][selectColumnName] === true) {
            pklist.push(grid!.records[i][grid!.dataItemKey]);
            continue;
          }

          // grid!.records[i][selectColumnName] === null ||
          if (!wantChecked && grid!.records[i][selectColumnName] === false) {
            pklist.push(grid!.records[i][grid!.dataItemKey]);
            continue;
          }
        }
      }
      grid!.state.selectedPKList = pklist;
      // console.log(
      //   "***New selected checkbox ID list",
      //   grid!.state.selectedPKList
      // );

      return draft;
    }
    case GridActions.setGridEndpoint: {
      if (!grid) return draft;
      console.log("REDUCER - SET GRID ENDPOINT: ", action.payload);

      if (grid!.endpoints) {
        grid!.endpoints.gridODataEndpoint = event;
        grid!.state.refreshGrid = true;
        grid!.state.selectedRowIsExpanded = false;
      }
      return draft;
    }
    case GridActions.updateNodeScheduleIds: {
      if (!grid) return draft;
      console.log("REDUCER - UPDATE GRID NODE SCHEDULE IDS: ", action.payload);
      grid!.state.nodeScheduleIds = event.data;

      // if insert just completed, turn off insert mode since we cannot call the normal one in this case
      if (grid!.state.insertMode === true) {
        grid!.state.insertMode = false;
        grid!.state.lockoutMode = false;
        grid!.state.editMode = false;
        grid!.insertRow = undefined;
      }

      return draft;
    }
    case GridActions.setGridRef: {
      // console.log("REDUCER - SET GRID REF: ", action.payload);
      if (grid) {
        grid!.gridRef = event.gridRef;
      }
      return draft;
    }
    case GridActions.setActiveDayGroupData: {
      console.log("REDUCER - SET DAY GROUP DATA: ", action.payload);
      if (grid) {
        grid!.state.selectedDayGroupName = event.selectedDayGroupName;
        grid!.state.selectedDayGroupDays = event.selectedDays;
        grid!.state.activeDayPart = event.activeDayPart;
      }
      return draft;
    }
    case GridActions.clearSelectedRow: {
      // console.log("REDUCER - CLEAR SELECTED ROW: ", action.payload);
      if (grid) {
        grid!.state.selectedRow = undefined;
        grid!.state.selectedRowData = undefined;
      }
      return draft;
    }
    case GridActions.setGridActions: {
      // console.log("REDUCER - SET GRID ACTIONS: ", action.payload);
      if (grid) {
        grid.actions = event.actions;
      }
      return draft;
    }
    case GridActions.updateSelectedRowData: {
      // console.log("REDUCER - UPDATE SELECTED ROW DATA: ", action.payload);
      if (grid) {
        if (event.newFieldValue) {
          // we only have the new field value here
          // let newRow = grid.state.selectedRowData;

          const keys = Object.keys(event.newFieldValue);
          grid.state!.selectedRowData![keys[0]] = event.newFieldValue[keys[0]];
          // console.log("new record", JSON.stringify(grid.state.selectedRowData));
        } else if (event.rowData) {
          // jon, 4/19/22: The updateSelectedRowData event sometimes fires on an update after a key navigation event has just occurred causing the wrong
          //   data to get set. This makes the index get off by one which inteferes with the next navigation event. If this event fires late so the row
          //   has already changed, do not update the selectedRowData object. This can be determined by checking the index property which must match.
          if (event.rowData.Index !== grid.state.selectedRowData!.Index) {
            console.log(
              `Skipping selected row data update since indexes do not match. Current: ${
                grid.state.selectedRowData!.Index
              }, New: ${event.rowData.Index}`
            );
          } else {
            grid.state.selectedRowData = event.rowData;
          }
        }
      }
      return draft;
    }
    // will, 2/8/22: added for soft keyboard bug fix (see NodeSchedules.tsx)
    case GridActions.toggleGridResize: {
      if (grid) {
        // console.log("REDUCER - TOGGLE GRID RESIZE: ", event.data);
        const resizeDisabled = event.data;
        grid!.state.resizeDisabled = resizeDisabled;
      }
      return draft;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const initialGridState: GridStateType = new Map();

const GridState = createContext<GridContext>({
  grids: initialGridState,
  setGrid: () => null
});

// eslint-disable-next-line react/prop-types
const GridProvider: React.FC = ({ children }): JSX.Element => {
  const [grids, setGrid] = useImmerReducer(reducer, initialGridState);

  return (
    <GridState.Provider value={{ grids, setGrid }}>
      {children}
    </GridState.Provider>
  );
};

export default GridProvider;
export const useGrid = () => useContext(GridState);
