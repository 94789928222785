import { AxiosResponse } from "axios";
import { useState } from "react";
import { Result } from "../../types";
import { AppLocalStore, GridActions, StoreActions } from "../../constants";
import { useStore } from "../../contexts/store";
import { useGrid } from "../../contexts/grid/useGrid";
import { authEndpoints } from "../../constants/endpoints";
import qs from "qs";
import apiClient from "../../api";
import { useDayGroups } from "../useDayGroups";

interface ICarbonApiResponse {
  message?: string;
  loading: boolean;
  request: Function;
}

const useApi = (apiFunc: Function): ICarbonApiResponse => {
  const [loading, setLoading] = useState(false);
  const { store, dispatch } = useStore();
  const { grids, setGrid } = useGrid();
  const { setNodeId } = useDayGroups();

  const setErrorNotification = (message: string, messageType: string) => {
    dispatch({
      type: StoreActions.addNotification,
      payload: {
        message: message,
        messageType,
        closable: true
      }
    });
  };

  // return type is not enforced by typescript when we do something like this..
  // must look more into it -Ali
  const request = async (...args: any[]): Promise<Result<AxiosResponse>> => {
    let result: Result<AxiosResponse>;
    try {
      setLoading(true);
      const response: AxiosResponse = await apiFunc(...args);
      setLoading(false);
      result = { type: "success", value: response };
      return result;
    } catch (error: any) {
      result = await handleAPIResponseError(args, error, 1);
      return result;
    } finally {
      // clean up
      setLoading(false);
    }
  };

  const handleAPIResponseError = async (
    args: any[],
    error: any,
    attemptNumber: number
  ): Promise<Result<AxiosResponse>> => {
    // If there was a "thrown" error, we will not have the normal APi response stuff, so just show a special message and exit
    if (!error.response) {
      return {
        type: "error",
        error: error
      };
    }

    // we should introduce better error handling
    // are there any situations where we'd want to throw?
    const errorResponse = error.response.data
      ? error.response.data
      : error.response;

    let result: Result<AxiosResponse> = {
      type: "error",
      error: {
        message: errorResponse.Message ? errorResponse.Message : errorResponse,
        name: ""
      }
    };

    const errorMsgInit = `API Error calling ${error.config.baseURL}${error.config.url}\n\nResponse: (${error.response.status}) ${result.error.message}`;
    const errorMsg = errorMsgInit.replaceAll("!CRLF!", "\n");
    console.log(errorMsg);
    //  user display: generic error
    //  want to ignore errors for status types: 400 (bad request), 401 (forbidden), 403 (unauthorized), 409 (conflict)
    if (error.response) {
      if ([401, 403].indexOf(error.response.status) !== -1) {
        // If there is no refresh token, no need to continue and attempt a retry
        if (localStorage.getItem(AppLocalStore.RefreshToken) === null) {
          const dummy: any = null;
          return {
            type: "success",
            value: dummy
          };
        }

        if (attemptNumber === 1) {
          // Attempt to refresh the token and then try the API call again
          if ((await handleTokenRefresh()) === true) {
            try {
              console.log(
                "Retrying last API call after refreshing access token..."
              );
              const response: AxiosResponse = await apiFunc(...args);
              result = { type: "success", value: response };
              return result;
            } catch (error: any) {
              result = await handleAPIResponseError(args, error, 2);
              return result;
            }
          } else {
            // No refresh token was found
            result.error.message =
              "Your session has expired. Please login again.";
            logoutUser();
          }
        } else {
          // The refresh token has expired so log the user out
          result.error.message =
            "Your session has expired. Please login again.";
          logoutUser();
        }
        return result;
      } else if ([400, 409].indexOf(error.response.status) === -1) {
        setErrorNotification(errorMsg, "error");
      }
    } else {
      setErrorNotification(errorMsg, "error");
    }

    return result;
  };

  const handleTokenRefresh = async (): Promise<boolean> => {
    console.log("Refreshing access token since previous one has expired.");

    const refreshToken = localStorage.getItem(AppLocalStore.RefreshToken);
    if (refreshToken === null) {
      console.log(`Refresh token not found in local storage!`);
      return false;
    }

    const tokenData: Object = {
      refresh_token: localStorage.getItem(AppLocalStore.RefreshToken),
      grant_type: "refresh_token"
    };

    try {
      const tokenResult = await apiClient.post(
        authEndpoints.tokenEndpoint,
        qs.stringify(tokenData)
      );
      if (tokenResult.status !== 200) {
        console.log(`Token refresh failed`, tokenResult);
        return false;
      }

      // Get the new access and refresh tokens
      const tokenResponseData = tokenResult.data;
      const token: string = tokenResponseData?.access_token;
      const refreshToken: string = tokenResponseData?.refresh_token;
      // console.log(
      //   `New refresh token: ${refreshToken}. New access token:`,
      //   token
      // );
      dispatch({ type: StoreActions.setToken, payload: token });
      dispatch({ type: StoreActions.setRefreshToken, payload: refreshToken });

      return true;
    } catch (error: any) {
      console.log("Error refreshing token", error);
      return false;
    }
  };

  const logoutUser = () => {
    // On logout, clear all grids so they are not rememebered across sessions
    if (grids) {
      [...grids.keys()].forEach((gridId) => {
        setGrid({
          type: GridActions.delete,
          payload: {
            gridId: gridId,
            gridData: null
          }
        });
      });
    }

    // Only remove tokens here and not the active company or remember me setting.
    localStorage.removeItem(AppLocalStore.Token);
    localStorage.removeItem(AppLocalStore.RefreshToken);

    // jon, 4/1/22: Reset day group node on logout. Otherwise, the last node gets remembered and templates will not load if user immediately revists that node.
    setNodeId(0);

    // Set session expired message in local storage to show on login screen
    localStorage.setItem(
      AppLocalStore.LoginScreenMessage,
      "Your session has expired."
    );

    // close previewer popout window if exists
    if (store.previewerWindow) {
      store.previewerWindow.close();
    }

    dispatch({ type: StoreActions.setUser, payload: null });
  };

  return { loading, request };
};

export default useApi;
