import React, { useState, useEffect } from "react";
import { CellRenderProps } from "./CellRender";
import { useGrid } from "../../../contexts/grid/useGrid";
import { GridActions, CarbonIcons } from "../../../constants";

export const CheckboxRender = ({
  originalProps,
  gridId,
  td,
  colDefinition,
  exitEdit
}: CellRenderProps) => {
  const [previousDataItem, setPreviousDataItem] = useState<{
    [key: string]: any;
  }>({});
  const [clicked, setClicked] = useState<boolean>(false);
  const { grids, setGrid } = useGrid();

  // Effect runs on initial render to save initial value of checkbox
  useEffect(() => {
    setPreviousDataItem(originalProps.dataItem);
  }, [originalProps.dataItem[originalProps.field!]]);

  // Effect runs after user has changed the value of the checkbox so we can get the new value
  useEffect(() => {
    if (clicked) {
      exitEdit(
        "ON CHANGE CHECKBOX",
        originalProps.field!,
        previousDataItem,
        originalProps.dataItem,
        [originalProps.field!]
      );
    }
    return () => {
      setClicked(false);
    };
  }, [clicked]);

  const additionalProps = {
    onClick: () => {
      if (isRowBeingEdited) setClicked(true);

      // Row click event does not fire on the grid when clicking the checkbox, even in read-only mode. So fire it here manually.
      setGrid({
        type: GridActions.onRowClick,
        payload: { gridId, gridData: { dataItem: originalProps.dataItem } }
      });
    }
  };

  // If row is not being edited, render a checkmark in place of true and nothing for false
  const grid = grids.get(gridId)!;
  const isRowBeingEdited =
    grid.state.editMode &&
    !(grids.get(gridId)!.state.selectedRowIsExpanded ?? false) &&
    originalProps.dataItem[grid.dataItemKey!] === grid.state.selectedRow;
  let checkChildren: JSX.Element = (
    <div style={{ textAlign: "center" }}>{td.props.children}</div>
  );

  const isEditable =
    colDefinition?.editable === true ||
    (colDefinition?.editableInInsertOnly === true &&
      grid.state.insertMode === true);

  if (!isRowBeingEdited || isEditable === false) {
    checkChildren =
      originalProps.dataItem[originalProps.field!] === true ? (
        <div style={{ textAlign: "center" }}>{CarbonIcons.Check}</div>
      ) : (
        <></>
      );
  }

  return React.cloneElement(
    td,
    { ...td.props, ...additionalProps },
    checkChildren
  );
};
