import { Box } from "grommet";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { CarbonIcons } from "../../constants";

interface CarbonBreadcrumbProps {
  backLinkTitle: string;
  backLinkUrl: string;
  pageTitle: string;
}

const CarbonBreadcrumb: React.FC<CarbonBreadcrumbProps> = (
  props
): JSX.Element => {
  return (
    <Box direction="row" style={{ marginTop: "-20px", paddingBottom: "1px" }}>
      <StyledLink to={props.backLinkUrl}>{props.backLinkTitle}</StyledLink>
      {CarbonIcons.ChevronRight}
      <span
        style={{
          fontSize: 14,
          fontWeight: 400,
          color: "var(--carbon-orange)"
        }}
      >
        {props.pageTitle}
      </span>
    </Box>
  );
};

const StyledLink = styled(Link)`
  font-size: 14px;
`;

export default CarbonBreadcrumb;
