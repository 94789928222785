import { Result, ResultError, NodeHistoriesRestoreContract } from "../../types";
import useApi from "../api/useApi";
import { CarbonGenericApiPost } from "../../api/carbonGenericApis";
import { useStore } from "../../contexts/store";
import { StoreActions } from "../../constants";

const useNodeHistoryRestoretApi = () => {
  const { dispatch } = useStore();
  const setNotification = (message: string, type: string) => {
    dispatch({
      type: StoreActions.addNotification,
      payload: {
        message: message,
        messageType: type,
        closable: true
      }
    });
  };

  const { loading: isLoading, request: postNodeList } =
    useApi(CarbonGenericApiPost);

  const postNodeHistoriesRestore = async (
    nodeId: string,
    payload: NodeHistoriesRestoreContract
  ): Promise<Result<string>> => {
    const url = `api/-1/nodetemplates/nodetemplatesrestore/${nodeId}`;

    let result: Result<string>;
    try {
      result = await postNodeList(url, payload);
      if (result.type === "success") {
        const resultValue: any = result.value;
        const message: string = resultValue.data;
        if (message.includes("RestoreScheduleFromHistory failed")) {
          setNotification(resultValue.data, "error");
        } else {
          setNotification("History restoration successful", "success");
        }
      }
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }

    return result;
  };

  return {
    postNodeHistoriesRestore,
    isLoading
  };
};

export default useNodeHistoryRestoretApi;
