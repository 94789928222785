import { Placeholders } from "../../constants";
import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  {
    field: "CampaignID",
    title: "CampaignID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: -1,
    isPartOfUniqueKey: true
  },
  {
    field: "CompanyID",
    title: "CompanyID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    company: true,
    defaultValue: Placeholders.companyID,
    companyMaster: true,
    isPartOfUniqueKey: true,
    systemHidden: true
  },
  {
    field: "CompanyName",
    title: "Company",
    titlePlural: "Companies",
    width: "310px",
    maxLength: 50,
    defaultShow: true,
    filter: "text",
    editable: false,
    editor: "lookup",
    required: false,
    canBeSaved: false,
    companyMaster: true,
    valueField: "CompanyID",
    defaultValue: Placeholders.companyName
  },
  {
    field: "CampaignName",
    title: "Campaign",
    width: "310px",
    maxLength: 50,
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    canBeSaved: true,
    isPartOfUniqueKey: true
  },
  {
    field: "CampaignDesc",
    title: "Description",
    width: "350px",
    maxLength: 256,
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true
  },
  {
    field: "DayPart",
    title: "Day Part",
    width: "175px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "dropdown",
    required: true,
    canBeSaved: true,
    dropItems: [
      { value: "All Day", text: "All Day" },
      { value: "AM", text: "AM" },
      { value: "PM", text: "PM" }
    ]
  },
  {
    field: "CampaignTypeID",
    title: "CampaignTypeID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "CampaignTypeName",
    title: "Campaign Type",
    titlePlural: "Campaign Types",
    width: "325px",
    maxLength: 50,
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    canBeSaved: false,
    valueField: "CampaignTypeID"
  },
  {
    field: "IsActive",
    title: "Active",
    width: "175px",
    defaultShow: true,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true,
    defaultValue: true
  }
];
