import {
  Result,
  ResultError,
  GlobalChangesGridFilterContract,
  GlobalChangesApplyChangesByMediaPackageContract,
  GlobalChangesApplyChangesByTemplateZoneContract,
  GlobalChangesApplyChangesByTypeContract,
  GlobalChangesProcessUpdatesContract,
  GlobalChangesRestoreScheduleContract
} from "../../types";
import useApi from "../../hooks/api/useApi";
import { useStore } from "../../contexts/store";
import {
  GetAffectedCount,
  ApplyChangesByMediaPackage,
  ApplyChangesByTemplateZone,
  ApplyChangesByType,
  PublishUpdatesToNodes,
  RestoreScheduleToNodes
} from "../../api/GlobalChanges/globalChangesApi";

const useGlobalChangesApi = () => {
  const { store } = useStore();

  const { loading: isLoadingCount, request: getAffectedCountRequest } =
    useApi(GetAffectedCount);

  const { loading: isLoadingApplyMedia, request: applyChangesByMediaRequest } =
    useApi(ApplyChangesByMediaPackage);

  const {
    loading: isLoadingApplyPackage,
    request: applyChangesByPackageRequest
  } = useApi(ApplyChangesByMediaPackage);

  const {
    loading: isLoadingApplyTemplateZone,
    request: applyChangesByTemplateZoneRequest
  } = useApi(ApplyChangesByTemplateZone);

  const { loading: isLoadingApplyType, request: applyChangesByTypeRequest } =
    useApi(ApplyChangesByType);

  const {
    loading: isLoadingPublishNodes,
    request: publishUpdatesToNodesRequest
  } = useApi(PublishUpdatesToNodes);

  const {
    loading: isLoadingRestoreNodes,
    request: restoreScheduleToNodesRequest
  } = useApi(RestoreScheduleToNodes);

  const getGlobalChangesAffectedCount = async (
    payload: GlobalChangesGridFilterContract
  ): Promise<Result<any>> => {
    const url = `/api/${store.activeCompany!.companyId}/globalchanges/count`;

    let result: Result<any>;
    try {
      result = await getAffectedCountRequest(url, payload);
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }

    return result;
  };

  const applyChangesByMedia = async (
    payload: GlobalChangesApplyChangesByMediaPackageContract
  ): Promise<Result<any>> => {
    const url = `/api/${store.activeCompany!.companyId}/globalchanges/bymedia`;

    let result: Result<any>;
    try {
      result = await applyChangesByMediaRequest(url, payload);
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }

    return result;
  };

  const applyChangesByPackage = async (
    payload: GlobalChangesApplyChangesByMediaPackageContract
  ): Promise<Result<any>> => {
    const url = `/api/${
      store.activeCompany!.companyId
    }/globalchanges/bypackage`;

    let result: Result<any>;
    try {
      result = await applyChangesByPackageRequest(url, payload);
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }

    return result;
  };

  const applyChangesByTemplateZone = async (
    payload: GlobalChangesApplyChangesByTemplateZoneContract
  ): Promise<Result<any>> => {
    const url = `/api/${
      store.activeCompany!.companyId
    }/globalchanges/bytemplatezone`;

    let result: Result<any>;
    try {
      result = await applyChangesByTemplateZoneRequest(url, payload);
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }

    return result;
  };

  const applyChangesByType = async (
    payload: GlobalChangesApplyChangesByTypeContract
  ): Promise<Result<any>> => {
    const url = `/api/${store.activeCompany!.companyId}/globalchanges/bytype`;

    let result: Result<any>;
    try {
      result = await applyChangesByTypeRequest(url, payload);
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }

    return result;
  };

  const publishUpdatesToNodes = async (
    payload: GlobalChangesProcessUpdatesContract
  ): Promise<Result<any>> => {
    const url = `/api/${store.activeCompany!.companyId}/globalchanges/publish`;

    let result: Result<any>;
    try {
      result = await publishUpdatesToNodesRequest(url, payload);
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }

    return result;
  };

  const restoreScheduleToNodes = async (
    payload: GlobalChangesRestoreScheduleContract
  ): Promise<Result<any>> => {
    const url = `/api/${store.activeCompany!.companyId}/globalchanges/restore`;

    let result: Result<any>;
    try {
      result = await restoreScheduleToNodesRequest(url, payload);
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }

    return result;
  };

  const isLoading = (): boolean => {
    return (
      isLoadingCount ||
      isLoadingApplyMedia ||
      isLoadingApplyPackage ||
      isLoadingApplyTemplateZone ||
      isLoadingApplyType ||
      isLoadingPublishNodes ||
      isLoadingRestoreNodes
    );
  };

  return {
    getGlobalChangesAffectedCount,
    applyChangesByMedia,
    applyChangesByPackage,
    applyChangesByTemplateZone,
    applyChangesByType,
    publishUpdatesToNodes,
    restoreScheduleToNodes,
    isLoading
  };
};

export default useGlobalChangesApi;
