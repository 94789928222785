import { UserPermissions } from "../../constants";
import { useStore } from "../../contexts/store";

/**
 * The usePermissions hook handles all our permissions requirements
 * while exposing only what we need in modules and components
 */
const usePermissions = () => {
  const { store } = useStore();
  const userPermissions = store.user?.permissions;

  const canView = (permission: UserPermissions) => {
    const permissionObj = userPermissions?.find(
      (permissionEntry) => permissionEntry.permissionName === permission
    );
    if (permissionObj?.canView) {
      return true;
    } else {
      return false;
    }
  };

  const canDelete = (permission: UserPermissions) => {
    const permissionObj = userPermissions?.find(
      (permissionEntry) => permissionEntry.permissionName === permission
    );
    if (permissionObj?.canDelete) {
      return true;
    } else {
      return false;
    }
  };

  const canUpdate = (permission: UserPermissions) => {
    const permissionObj = userPermissions?.find(
      (permissionEntry) => permissionEntry.permissionName === permission
    );
    if (permissionObj?.canUpdate) {
      return true;
    } else {
      return false;
    }
  };

  const canInsert = (permission: UserPermissions) => {
    const permissionObj = userPermissions?.find(
      (permissionEntry) => permissionEntry.permissionName === permission
    );
    if (permissionObj?.canInsert) {
      return true;
    } else {
      return false;
    }
  };

  const isGranted = (permission: UserPermissions) => {
    const permissionObj = userPermissions?.find(
      (permissionEntry) => permissionEntry.permissionName === permission
    );
    if (permissionObj?.isGranted) {
      return true;
    } else {
      return false;
    }
  };

  return {
    canView,
    canDelete,
    canUpdate,
    canInsert,
    isGranted
  };
};

export default usePermissions;
