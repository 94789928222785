import React, { FC, useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Grid,
  GridColumn,
  GridSelectionChangeEvent,
  GridDataStateChangeEvent,
  GridCellProps,
  GridItemChangeEvent,
  GridRowProps,
  GridRowClickEvent,
  GridNoRecords,
  GridProps,
  GridEvent,
  GridFilterOperators,
  GridColumnReorderEvent,
  GridColumnResizeEvent,
  GridExpandChangeEvent,
  GridFilterCellProps
} from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  SortDescriptor,
  toODataString
} from "@progress/kendo-data-query";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Label } from "@progress/kendo-react-labels";
import styled from "styled-components";

// Components
import OctopusLoader from "../Util/OctopusLoader";
import { CellRender } from "./Renderers/CellRender";
import { RowRender } from "./Renderers/RowRender";

// Contexts
import { useGrid } from "../../contexts/grid/useGrid";

// Types & Constants
import {
  GridActions,
  GridIDs,
  ResultTypes,
  StoreActions
} from "../../constants";
import { GridColumns, GridType, IGridLookupEndpoint } from "../../types/grid";
import {
  ColumnOrderLayout,
  ColumnResizeLayout,
  NodeSchedulesRefreshZonesContract,
  Result
} from "../../types";

// Data
import GridColumnSelector, { GridSelectorColumn } from "./GridColumnSelector";
import GetGridDataApi from "../../api/grid/getDataApi";
import UpdateGridRecordApi from "../../api/grid/updateRecordApi";
import InsertGridRecordApi from "../../api/grid/insertRecordApi";
import DeleteGridRecordApi from "../../api/grid/deleteRecordApi";
import useGridRecordApiGet from "../../hooks/grid/useGridRecordApiGet";

// Hooks
import { useWindowSize } from "../../hooks/windowSize/useWindowSize";
import { useStore } from "../../contexts/store";
import GetLookupDataApi from "../../api/grid/getLookupDataApi";
import { HeaderCellRender } from "./Renderers/HeaderCellRender";
import ConfirmationDialog from "../Util/Dialogs/ConfirmationDialog";
import TextareaDialog from "../Util/Dialogs/TextareaDialog";
import CopyRecordDialog from "../Util/Dialogs/CopyRecordDialog";

import {
  DropdownStatusFilterCell,
  DropdownFilterCell
} from "./Utility/GridFilterCells";
import useNodeSchedulesOps from "../../hooks/nodes/useNodeSchedulesOps";

interface CarbonGridProps {
  gridId: string;
  gridSettings: GridType;
  gridClassName?: string;
  onEmptyGridRender?: any;
  onAfterUpdate?: (rowData: { [key: string]: any }) => void;
  onAfterDelete?: (deletedRecordId: number) => void;
  onAfterInsert?: (rowData: { [key: string]: any }) => void;
}

type GridSortAndFilter = {
  sort: SortDescriptor[];
  filter: CompositeFilterDescriptor;
};

export type CarbonGridStateType = {
  lastGridDataState: GridProps | undefined; // Tracks changes made to the filter by the user to the grid while waiting for the timeout interval to elapse.
  lastAPIRequestDataState: string; // Tracks the last request made to the API for this grid so we can debounce requests
  previousSortAndFilter: GridSortAndFilter | undefined; // Tracks the previous sort and filter of grid so we can tell they changed.
  showColumnSelector: boolean; // Tracks state of button/dialog to hide/show columns
};

const FILTER_CHANGE_TIMEOUT_DELAY_MS: number = 500;
const GRID_NORMAL_ROW_HEIGHT: number = 51;
// const GRID_COMPACT_ROW_HEIGHT: number = 24;

const CarbonGrid: FC<CarbonGridProps> = ({
  gridId,
  gridSettings,
  gridClassName,
  onEmptyGridRender,
  onAfterUpdate,
  onAfterInsert,
  onAfterDelete
}: CarbonGridProps): JSX.Element => {
  // Note: Anything put into state here should be things that do NOT need to be remembered for any length of time since they get reset to their initial
  //  values when navigating away and coming back.  It is ok to leave stuff like the loading indicator and even the de-bouncing stuff since we probably
  //  want those to reset on a screen switch.
  const initialState: CarbonGridStateType = {
    lastGridDataState: undefined,
    lastAPIRequestDataState: "",
    previousSortAndFilter: undefined,
    showColumnSelector: false
  };
  const { pathname } = useLocation();
  const [state, setState] = useState<CarbonGridStateType>(initialState);
  const [loading, setLoading] = useState<boolean>(true);
  const [populated, setPopulated] = useState<boolean>(false);
  const [hoverRowId, setHoverRowId] = useState<number>(-1);
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] =
    useState<boolean>(false);
  const [showCopyRecordDialog, setShowCopyRecordDialog] =
    useState<boolean>(false);
  const [showTextareaDialog, setShowTextareaDialog] = useState<boolean>(false);

  const { grids, setGrid } = useGrid();
  const [gridRef, setGridRef] = useState<HTMLDivElement | undefined>(undefined);
  const [presentationRef, setPresentationRef] = useState<Element | undefined>(
    undefined
  );
  const [kendoGridRef, setKendoGridRef] = useState<Grid>();
  const { windowHeight, windowWidth } = useWindowSize();
  const { store, dispatch } = useStore();
  const { getGridData, isLoading: gridODataIsLoading } = GetGridDataApi(gridId);
  const { updateRecord } = UpdateGridRecordApi(gridId);
  const { insertRecord } = InsertGridRecordApi(gridId);
  const { deleteRecord } = DeleteGridRecordApi(gridId);
  const { getGridLookupData } = GetLookupDataApi();
  const { getGridRecord } = useGridRecordApiGet();
  const { refreshZonesFromTemplate } = useNodeSchedulesOps();

  // Get and populate grid data using the sort and filter specified in the dataState parameter - but return user to first page of data.
  const populateGrid = async (dataState: GridProps) => {
    console.log("Populating grid with data...");
    const gridEndpoint = grids.get(gridId)?.endpoints?.gridODataEndpoint
      ? grids.get(gridId)!.endpoints.gridODataEndpoint
      : gridSettings.endpoints.gridODataEndpoint;

    let dataResponse: any = { data: { "@odata.count": 0, value: [] } };

    // jon, 1/4/22: Do not call API if endpoint is empty. This is used on NodeScheduleHistory since the tabbed grids are initially blank before a record is selected.
    //   This new feature can be used for any grid that is not initially populated when the screen loads but is loaded via user action or after something else occurs.
    if (gridEndpoint !== "") {
      const rawResponse: Result<string> = await getGridData(gridEndpoint, {
        odataString: toODataString(fixStringFilters(dataState))
      });

      if (rawResponse.type !== ResultTypes.Success) {
        setLoading(false);
        const msg = "Loading data failed with the following message(s):\n\n";
        const errors = replaceFieldsInErrorMsg(rawResponse.error.message).split(
          ";"
        );

        // jon, 1/16/22: If this populate was from a refresh, toggle refresh off because we are done
        if (grids.get(gridId)?.state.refreshGrid === true) {
          setGrid({
            type: GridActions.toggleRefreshGrid,
            payload: { gridId: gridId, gridData: false }
          });
        }

        console.log(msg + errors.join("\n"));

        // jon, 1/20/22: Unauthorized exceptions have already been handled in useApi. If message already handled, it was set to blank there.
        if (rawResponse.error.message !== "") {
          dispatch({
            type: StoreActions.addNotification,
            payload: {
              message: msg + errors.join("\n"),
              messageType: "error",
              closable: true
            }
          });
        }
        return;
      }

      dataResponse = rawResponse.value;
    }

    // jon, 1/16/22: If this populate was from a refresh, toggle refresh off because we are done
    if (grids.get(gridId)?.state.refreshGrid === true) {
      setGrid({
        type: GridActions.toggleRefreshGrid,
        payload: { gridId: gridId, gridData: false }
      });
    }

    setState({
      ...state,
      lastAPIRequestDataState: toODataString(dataState)
    });

    if (dataResponse) {
      setGrid({
        type: GridActions.initialData,
        payload: {
          gridId,
          gridData: {
            data: dataResponse.data
          }
        }
      });

      setPopulated(true);
      setGridHeight();

      if (dataResponse.data.value.length === 0) {
        setGrid({
          type: GridActions.clearSelectedRow,
          payload: {
            gridId,
            gridData: false
          }
        });
      }
    }
  };

  // Effect runs when the grid's width changes so we can store the width in state. This width is used by subgrids to set their max width.
  useEffect(() => {
    if (gridRef && presentationRef) {
      let width: number = gridRef.clientWidth;

      // We want the smaller of the grid width and the viewable width (in case of scroll)
      if (presentationRef.clientWidth < width) {
        width = presentationRef.clientWidth;
      }

      // console.log(`Grid ${gridId} width: ${width}`);
      setGrid({
        type: GridActions.setGridWidth,
        payload: { gridId, gridData: width - 60 }
      });
    }
  }, [gridRef?.clientWidth, presentationRef?.clientWidth]);

  // Effect runs when the active company changes in the global company dropdown to reset the grid to initial state.
  useEffect(() => {
    if (
      store.activeCompany !== null &&
      grids.get(gridId) &&
      store.activeCompany.companyId !==
        grids.get(gridId)?.state.activeCompany?.companyId
    ) {
      console.log(
        `Active Company changed to: ${
          store.activeCompany!.companyName
        }. Reloading grid...`
      );

      setLoading(true);
      setPopulated(false);

      // jon, 8/15/21: Setting active company in grid object because I currently have a problem where I cannot use the Store in the grid reducer.
      setGrid({
        type: GridActions.updateActiveCompany,
        payload: {
          gridId,
          gridData: { activeCompany: store.activeCompany!, refresh: true }
        }
      });
    }
  }, [store.activeCompany?.companyId]);

  // Effect runs after initial load to set the page data in the grid
  useEffect(() => {
    if (populated) {
      setGrid({
        type: GridActions.updateDataPage,
        payload: { gridId, gridData: {} }
      });
      setLoading(false);
    }
  }, [populated]);

  // This effect runs on first load and after navigating back from a tab so we can reset the current scroll position if user was previously scrolled down.
  //   Otherwise, the Kendo grid wants to always reset back to the top, which messes up the virtual scroll since the data showing could be from farther down.
  useEffect(() => {
    if (grids.get(gridId) && gridRef) {
      const gridContent = gridRef.querySelector("div.k-grid-content");
      if (!gridContent) {
        console.log("div.k-grid-content not found in grid!");
        return;
      }

      const scrollPos =
        grids.get(gridId) && grids.get(gridId)!.state.scrollTopPosition
          ? grids.get(gridId)!.state.scrollTopPosition!
          : 0;
      // console.log(`Setting grid scroll position to ${scrollPos}`);
      gridContent.scrollTop = scrollPos;
    }
  }, [gridRef]);

  // This effect runs when the route changes so a tabbed grid will refresh as user changes records and goes to the tab. We want a full reset here.
  // jon, 1/16/22: Added autoRefreshOnPathChange grid parameter. Skip refresh of grid if this is set to false (default is true). This is used on
  //    subgrids that are completely controlled by the selection of a record in another grid - see Node Schedule History - templates grid.
  useEffect(() => {
    if (grids.get(gridId) && grids.get(gridId)!.state.pathname !== pathname) {
      if (grids.get(gridId)!.autoRefreshOnPathChange === false) {
        console.log(
          `Route has changed in grid but skipping reload because autoRefreshOnPathChange is true...`,
          pathname
        );
      } else {
        console.log(`Route has changed in grid. Reloading...`, pathname);
        setLoading(true);
        setPopulated(false);
        populateGrid(gridSettings.dataState);

        // Scroll grid to top
        if (gridRef) {
          const gridContent = gridRef.querySelector("div.k-grid-content");
          gridContent!.scrollTop = 0;
        }
      }

      // when grids with no data are fetched, the pathname is not being updated. this is double checking that it gets updated so the refresh is triggered
      if (grids.get(gridId)!.state.pathname !== pathname) {
        setGrid({
          type: GridActions.setInitialGridSettings,
          payload: {
            gridId,
            gridData: {
              pathname: pathname
            }
          }
        });
      }

      // jon, 1/4/22: If this existing grid is a subgrid (i.e., it has a parent grid id property), populate the parent grid row data for the selected parent row.
      //   This attempts to fix issue where sometimes parent row data is set to a previous record's value when switching node rows and tabs.
      if (grids.get(gridId)!.parentGridId) {
        handleParentSelectedGridRow(
          grids.get(gridId)!.parentGridId!,
          grids.get(gridId)!.endpoints?.parentGridApiEndpoint
        );
      }
    }
  }, [pathname]);

  // Effect creates grid on initial load, but uses cached grid after first creation if component is re-rendered. This effect also runs when
  //  the Refresh button is clicked.  In "Refresh" mode, we will re-populate the grid with data.
  useEffect(() => {
    // console.log(`${gridId}`, grids.get(gridId));
    if (grids.get(gridId)) {
      // On initial load, check for existing grid in context by its id
      console.log(`Grid ${gridId} exists in grid context...`);

      // Is this a refresh? If so, reload the data.
      if (grids.get(gridId) && grids.get(gridId)!.state.refreshGrid === true) {
        console.log(`Grid ${gridId} is being refreshed...`);
        setLoading(true);
        setPopulated(false);
        clearSubgridIfExists();
        populateGrid(grids.get(gridId)!.dataState); // Use user's current sort and filter on refresh

        // Scroll grid to top on refresh
        if (gridRef) {
          const gridContent = gridRef.querySelector("div.k-grid-content");
          gridContent!.scrollTop = 0;
        }

        // jon, 1/16/22: Moved toggling Refresh to false to after grid population is done since this code ran immediately after populate call
        //   above before since that method makes an async call instead of waiting until population is completely done.
      } else {
        setLoading(false);
      }

      // If this existing grid is a subgrid (i.e., it has a parent grid id property), populate the parent grid row data for the selected parent row
      if (grids.get(gridId)?.parentGridId) {
        handleParentSelectedGridRow(
          grids.get(gridId)!.parentGridId!,
          grids.get(gridId)!.endpoints?.parentGridApiEndpoint
        );
      }
    } else {
      console.log(
        `Grid ${gridId} does not exist in grid context... create and populate it.`
      );

      const userHasSingleCompany = store.user?.companies.length === 1;
      const userLayouts = store.user?.userSettings.layouts.get(gridId);
      let initialVisibleColumns = [] as GridSelectorColumn[];

      initialVisibleColumns = gridSettings.columns!.map((column) => {
        let defaultShow =
          column.field === "CompanyName" && userHasSingleCompany
            ? false
            : column.defaultShow;

        // check if user has saved visibility layout
        if (userLayouts?.columnVisibilityLayout) {
          const foundField = userLayouts.columnVisibilityLayout.find(
            (val) => val.field === column.field
          );
          if (foundField !== undefined) {
            defaultShow = foundField.show;
          } else {
            defaultShow = column.defaultShow;
          }
        }

        const selCol: GridSelectorColumn = {
          field: column.field,
          defaultShow: defaultShow,
          required: column.required,
          show: defaultShow,
          title: column.title,
          isCombinedCol: column.combinedCol !== undefined,
          systemHidden: column.systemHidden === true
        };

        return selCol;
      });

      const initialGridSelectorColumns = [...initialVisibleColumns];
      const initialGridSort = [...gridSettings.dataState.sort!];

      // will, 1/31/22: the gird sort settings are being set to the default and then updated after the grid has been set. Need to set the sort before this because the sort is not done in the grid, it is done in the API call.
      // NOTE: should only be used here and not when setting up the grid defaults so that the 'Restore Layout' button still works
      const gridSettingsWithLayoutSort = gridSettings;
      gridSettingsWithLayoutSort.dataState.sort = userLayouts?.gridSort
        ? (userLayouts!.gridSort! as SortDescriptor[])
        : [...gridSettings.dataState.sort!];

      // create the grid with default grid settings
      setGrid({
        type: GridActions.createGrid,
        payload: {
          gridId,
          gridData: {
            settings: gridSettingsWithLayoutSort
          }
        }
      });

      // set default grid columns and gridProps prior to possibly altering the grid state because of user specified settings
      setGrid({
        type: GridActions.setDefaultColumnsAndGridProps,
        payload: {
          gridId,
          gridData: {
            columns: gridSettings.columns,
            sort: initialGridSort
          }
        }
      });

      setGrid({
        type: GridActions.setInitialGridSettings,
        payload: {
          gridId,
          gridData: {
            pathname: pathname,
            visibleColumns: initialGridSelectorColumns,
            initialSort: gridSettings.dataState.sort,
            initialFilter: gridSettings.dataState.filter
          }
        }
      });
      setGrid({
        type: GridActions.updateActiveCompany,
        payload: {
          gridId,
          gridData: { activeCompany: store.activeCompany! }
        }
      });

      // action below mutates the real grid columns
      setGrid({
        type: GridActions.onUpdateVisibleColumns,
        payload: { gridId, gridData: { columns: initialGridSelectorColumns } }
      });

      // action below mutates the grid columns with the user column layouts (if exists)
      if (userLayouts) {
        setGrid({
          type: GridActions.updateColumns,
          payload: {
            gridId,
            gridData: {
              userLayouts
            }
          }
        });
      }
      // If this new grid is a subgrid (i.e., it has a parent grid id property), populate the parent grid row data for the selected parent row
      if (gridSettings.parentGridId) {
        handleParentSelectedGridRow(
          gridSettings.parentGridId!,
          gridSettings.endpoints?.parentGridApiEndpoint
        );
      }

      // jon, 3/22/22: If a grid is completely controlled by the selection of another record, do not try to populate the grid on create because this request
      //   could actually come in after the "real" one and show a blank grid.  See Node Schedules grid where day group selection drives grid population.
      if ((gridSettings.autoRefreshOnPathChange ?? true) !== false) {
        populateGrid(gridSettings.dataState);
      } else {
        setLoading(false);
      }
    }
  }, [grids.get(gridId)?.state.refreshGrid]);

  // Effect runs everytime last grid datastate changes. Each time this happens, we reset the timer and start waiting again.
  //  Once changes stop coming in for the DELAY interval, we will process the datastate change, which might result in calling the API.
  useEffect(() => {
    if (state.lastGridDataState) {
      const timer1 = setTimeout(() => {
        fetchGridPageDataFromServerIfNeeded(state.lastGridDataState!);
      }, FILTER_CHANGE_TIMEOUT_DELAY_MS);

      // Clear the timer when datastate changes so we can start the timer over. Also runs on unmount.
      return () => {
        clearTimeout(timer1);
      };
    }
  }, [state.lastGridDataState]);

  // Effect runs when the selected row in the grid changes or when edit/insert mode is toggled to check for custom row change events
  useEffect(() => {
    if (
      grids.get(gridId) &&
      grids.get(gridId)!.state.selectedRow &&
      grids.get(gridId)!.state.selectedRowData &&
      grids.get(gridId)!.onRowChange
    ) {
      const { changedColumns } = grids.get(gridId)!.onRowChange!(
        grids.get(gridId)!.state.selectedRowData!,
        grids.get(gridId)!.columns!,
        grids.get(gridId)!.state.parentSelectedRowData,
        gridSettings.isSuperUser
      );
      updateColumnPropertiesOnRowDataChange(changedColumns);
    }
  }, [
    grids.get(gridId)?.state.selectedRowData,
    grids.get(gridId)?.state.editMode
  ]);

  useEffect(() => {
    if (kendoGridRef) {
      setGrid({
        type: GridActions.setGridRef,
        payload: { gridId, gridData: { gridRef: kendoGridRef } }
      });
    }
  }, [kendoGridRef]);

  // Track selected row and only scroll to row if the row is not visible on screen (primarily for keyboard navigation)
  useEffect(() => {
    // jon, 12/9/21: The scrollIntoView line fails on subgrids when clicking on fields during editing. Do not run this on subgrids.
    if (
      grids.get(gridId) &&
      grids.get(gridId)!.state.selectedRow &&
      grids.get(gridId)!.state.selectedRowData &&
      grids.get(gridId)!.state.editMode &&
      (grids.get(gridId)!.isSubgrid ?? false) !== true
    ) {
      const virtualContent = document.querySelector(".k-virtual-content")!;
      const row = virtualContent.querySelector(`tr.k-state-selected`);
      if (virtualContent && row) {
        const rowsInVirtualContent =
          virtualContent?.clientHeight / row!.clientHeight;
        const indexInPage = parseInt(
          row!.getAttribute("data-grid-row-index") as string
        );
        const visible = indexInPage > 0 && indexInPage < rowsInVirtualContent;
        if (!visible) {
          virtualContent.querySelector(`:focus`)?.scrollIntoView();
        }
      } else if (!row) {
        // the row is off the screen is not rendered yet (when user navigates up on first row in vgrid)
        grids.get(gridId)?.gridRef.scrollIntoView({
          rowIndex: grids.get(gridId)?.state.selectedRowData?.Index
        });
      }
    }
  }, [grids.get(gridId)?.state.selectedRowData]);

  // Effect runs when the Quick Filter toggle button is clicked to either show filter bar (and focus) or stop showing the filter toolbar.
  useEffect(() => {
    if (grids.get(gridId)) {
      if (grids.get(gridId)?.state.showQuickFilter) {
        // Focus first filter field
        const firstFilterInput = document.querySelector(
          `table thead tr.k-filter-row th:first-child .k-filtercell input.k-textbox`
        ) as HTMLInputElement;
        if (firstFilterInput != null) {
          firstFilterInput.focus();
          firstFilterInput.select();
        }
      } else {
        // Clear all existing filters by resetting back to initial filter
        const newDataState = {
          ...grids.get(gridId)!.dataState,
          filter: gridSettings.dataState.filter
        };

        setGridDataState(newDataState);
      }
    }
  }, [grids.get(gridId)?.state.showQuickFilter]);

  // Effect runs when the Edit Mode toggle button is clicked so we can set focus on first editable field and load any missing lookup data.
  useEffect(() => {
    if (grids.get(gridId) && grids.get(gridId)!.state.editMode) {
      // Can't use await in an effect but the load lookup needs to be here instead of in the toolbar so we can show the loading indicator.
      //  Therefore, the await will occur in the loadLookupData and this effect will finish quickly.
      loadLookupData();
    }
  }, [grids.get(gridId)?.state.editMode]);

  // Effect runs when saving indicator displays and we use it to check for insert mode and kick off the insert row save.
  useEffect(() => {
    if (
      grids.get(gridId)?.state.showSavingIndicator &&
      grids.get(gridId)?.state.insertMode
    ) {
      // Get the insert row and filter out all columns that have canBeSaved = false
      const grid = grids.get(gridId)!;
      const insertRow: { [key: string]: any } = {};
      for (let i = 0; i < grid.columns!.length; i++) {
        if (grid.columns![i].canBeSaved === true) {
          insertRow[grid.columns![i].field] =
            grid.insertRow![grid.columns![i].field];
        }
      }

      // console.log(`SAVING INSERT ROW: `, insertRow);

      saveInsertRow(insertRow!).then((data: null | { [key: string]: any }) => {
        // Update last save date
        if (data !== null) {
          setGrid({
            type: GridActions.updateLastSaveDate,
            payload: { gridId, gridData: { date: new Date() } }
          });

          // If grid has any node schedule ids defined, update them so new row can show
          if (grids.get(gridId)?.state.nodeScheduleIds) {
            const id: number = data.NodeScheduleID;
            const nodeScheduleIDs = [
              ...grids.get(gridId)!.state.nodeScheduleIds!
            ];
            nodeScheduleIDs.push(id);

            setGrid({
              type: GridActions.updateNodeScheduleIds,
              payload: {
                gridId,
                gridData: {
                  data: nodeScheduleIDs
                }
              }
            });
          } else {
            setGrid({
              type: GridActions.toggleInsertMode,
              payload: { gridId, gridData: { insertOn: false, data } }
            });
          }

          // Call any post-insert custom event defined in grid
          if (grids.get(gridId) && onAfterInsert) {
            onAfterInsert(data);
          }
        }

        // Either way, remove saving indicator
        setGrid({
          type: GridActions.toggleSavingIndicator,
          payload: { gridId, gridData: { show: false } }
        });
      });
    }
  }, [grids.get(gridId)?.state.showSavingIndicator]);

  useEffect(() => {
    if (grids.get(gridId) && grids.get(gridId)?.state.recordIdToDelete) {
      setShowDeleteConfirmDialog(true);
    }
  }, [grids.get(gridId)?.state.recordIdToDelete]);

  useEffect(() => {
    setShowCopyRecordDialog(
      grids.get(gridId)?.state.showCopyRecordDialog || false
    );
  }, [grids.get(gridId)?.state.showCopyRecordDialog]);

  useEffect(() => {
    if (grids.get(gridId) && grids.get(gridId)?.state.textAreaDialogParams) {
      setShowTextareaDialog(true);
    }
  }, [grids.get(gridId)?.state.textAreaDialogParams?.title]);

  // Layout Effect runs when grid is put into insert mode so we can scroll to that row. This always keeps the full insert row visible at the top.
  useLayoutEffect(() => {
    if (grids.get(gridId)) {
      // will, 3/31/22: Added the following condition so that grid custom events will take place when inserting a new row so responsive fields will update correctly
      if (
        grids.get(gridId)!.state.insertMode &&
        grids.get(gridId)!.insertRow &&
        grids.get(gridId)!.onRowChange
      ) {
        const { changedColumns } = grids.get(gridId)!.onRowChange!(
          grids.get(gridId)!.insertRow!,
          grids.get(gridId)!.columns!,
          grids.get(gridId)!.state.parentSelectedRowData,
          gridSettings.isSuperUser
        );
        updateColumnPropertiesOnRowDataChange(changedColumns);
      }

      if (gridRef) {
        const gridContent = gridRef.querySelector("div.k-grid-content");
        if (!gridContent) {
          console.log("div.k-grid-content not found in grid!");
          return;
        }

        // If going into insert mode, scroll to top. Save the scrolltop position so we can reset it afterwards. Otherwise, grid thinks it is at the top
        //   afterwards (even though it might not be) and scrolling results in weird behavior like it immediately jumping back to the first page.
        if (grids.get(gridId)?.state.insertMode === true) {
          if (gridContent.scrollTop > 0) {
            // Current grid position is now set in onScroll.
            gridContent.scrollTop = 0;
          }
        } else {
          // If going out of insert mode, reset scroll to previous position
          if (
            gridContent.scrollTop !==
            grids.get(gridId)?.state.scrollTopPosition!
          ) {
            gridContent.scrollTop = grids.get(gridId)?.state.scrollTopPosition!;
          }
        }
      }
    }
  }, [grids.get(gridId)?.state.insertMode]);

  // toggle refresh on active filter id change
  // useUpdateEffect(() => {
  //   console.log("ran update effect");
  //   setGrid({
  //     type: GridActions.toggleRefreshGrid,
  //     payload: { gridId: gridId, gridData: true }
  //   });
  // }, [grids.get(gridId)?.state.activeFilterId]);

  // Layout Effect runs on first load and on window resize to resize grid to fill up available screen space.
  // FUTURE: We need to tie into this event to set the pageSize of the grid so veritical screens will show enough rows. But we need to
  //  otherwise keep the pageSize as low as possible because more rows creates performance issues (very noticeable when scrolling up).
  // jon, 2/22/22: Also resize grid based on width changing because this can cause the menu to wrap, which means grid needs to be shorter.
  useLayoutEffect(() => {
    // will, 2/8/22: added condition for soft keyboard bug fix (See NodeSchedules.tsx)
    if (!grids.get(gridId)?.state.resizeDisabled) {
      setGridHeight();
    }
  }, [
    windowHeight,
    windowWidth,
    grids.get(gridId)?.state.selectedRowIsExpanded,
    gridRef
  ]);

  // jon, 2/22/22: Need to check grid wrapper size in a setTimeout so page is fully rendered in order to get proper content height from DashboardLayout.
  const setGridHeight = () => {
    if (gridRef) {
      window.setTimeout(() => {
        const gridWrapper = gridRef.closest(
          "div.carbon-grid-wrapper"
        ) as HTMLDivElement;
        if (gridWrapper) {
          const newHeight = gridWrapper.offsetHeight - 10;

          console.log(`Set new grid height: ${newHeight}`);
          setGrid({
            type: GridActions.resizeGrid,
            payload: { gridId, gridData: newHeight }
          });
        }
      }, 100);
    }
  };

  const handleParentSelectedGridRow = async (
    parentGridId: string,
    parentGridApiEndpoint: string | undefined
  ) => {
    // Does the parent grid exist? It will not if this grid's page was loaded directly. In that case, we need to load the data for the selected row.
    if (grids.get(parentGridId)) {
      // console.log(
      //   `Parent grid ${parentGridId} exists. Setting selected row data...`
      // );

      setGrid({
        type: GridActions.setParentRowData,
        payload: {
          gridId,
          gridData: grids.get(parentGridId)!.state.selectedRowData
        }
      });
    } else {
      // console.log(
      //   `Parent grid ${parentGridId} does not exist. Loading data from API...`
      // );

      if (!parentGridApiEndpoint) {
        throw new Error(
          "DEVELOPER: You must define parentGridApiEndpoint in page grid endpoints!"
        );
      }

      // Fetch parent row data from API
      const result = getGridRecord(parentGridApiEndpoint);
      result.then((response) => {
        if (response.type === ResultTypes.Success) {
          const responseResult = response.value;

          setGrid({
            type: GridActions.setParentRowData,
            payload: {
              gridId,
              gridData: responseResult?.data
            }
          });
        }
      });
    }
  };

  const loadLookupData = async () => {
    // Determine which lookups need data
    setLoading(true);

    // If this grid has a company field, set its lookup data to the same company list as is shown in the Company dropdown (used for inserts only).
    if (
      grids
        .get(gridId)!
        .columns!.filter((column) => column.field === "CompanyName").length >
        0 &&
      !(grids.get(gridId)!.lookups && grids.get(gridId)!.lookups.CompanyName)
    ) {
      setGrid({
        type: GridActions.updateLookupData,
        payload: {
          gridId,
          gridData: {
            lookupField: "CompanyName",
            lookupData: store
              .user!.companies.map((company) => {
                return {
                  CompanyID: company.companyId,
                  CompanyName: company.companyName
                };
              })
              .filter((company) => company.CompanyID !== -1)
          }
        }
      });

      // console.log(`LOOKUP CompanyName data loaded:`, store.user!.companies);
    }

    // Load non-company lookups
    const gridLookupEndpoints = grids.get(gridId)!.endpoints.lookupEndpoints;
    if (!gridLookupEndpoints || gridLookupEndpoints.length < 1) {
      setLoading(false);
      return;
    }

    // Load all lookups simultaneously if we don't already have the data
    await Promise.all(
      gridLookupEndpoints.map(async (endpoint: IGridLookupEndpoint) => {
        const rawResponse = await getGridLookupData(endpoint);
        if (rawResponse.type === "success") {
          const dataResponse: any = rawResponse.value;

          console.log(
            `LOOKUP ${endpoint.lookupField} data loaded:`,
            dataResponse.data
          );
          setGrid({
            type: GridActions.updateLookupData,
            payload: {
              gridId,
              gridData: {
                lookupField: endpoint.lookupField,
                lookupData: dataResponse.data
              }
            }
          });
        }
      })
    );

    setLoading(false);
  };

  const onDataStateChange = async (event: GridDataStateChangeEvent) => {
    setGrid({
      type: GridActions.setSortOrderLayout,
      payload: {
        gridId,
        gridData: {
          sortLayout: event.dataState.sort
        }
      }
    });
    const newDataState = {
      ...grids.get(gridId)!.dataState,
      ...event.dataState
    };
    setGridDataState(newDataState);
  };

  const setGridDataState = (newDataState: GridProps) => {
    // Save the previous sort and filter before we set them in the grid data state so we can tell if they have changed.
    const prevSortAndFilter: GridSortAndFilter = {
      sort: grids.get(gridId)!.dataState.sort!,
      filter: grids.get(gridId)!.dataState.filter!
    };

    setGrid({
      type: GridActions.onDataStateChange,
      payload: {
        gridId,
        gridData: {
          dataState: newDataState
        }
      }
    });

    // Set the latest data state. This will trigger the useEffect above that waits for the delay interval before processing.
    setState({
      ...state,
      lastGridDataState: newDataState,
      previousSortAndFilter: prevSortAndFilter
    });
  };

  const fetchGridPageDataFromServerIfNeeded = async (dataState: any) => {
    if (loading) return;
    const grid = grids.get(gridId)!;

    // Reload starting with the first page when the user changes the sort or filter.
    // jon, 1/14/22: Use stringify for compare of sorts and filters since object comparison was usually returning differences when actually the same.
    if (
      state.previousSortAndFilter &&
      (JSON.stringify(dataState.sort) !==
        JSON.stringify(state.previousSortAndFilter.sort) ||
        JSON.stringify(dataState.filter) !==
          JSON.stringify(state.previousSortAndFilter.filter))
    ) {
      // console.log("Filter/Sort change - reload data from first page.");
      const newDataState = {
        ...grids.get(gridId)!.dataState,
        skip: 0,
        take: gridSettings.dataState.take
      };
      await fetchGridPageDataFromServer(newDataState, 0, true);
      return;
    }

    // The skip value indicates how far the user has scrolled the grid, but we only want to fetch on page boundaries, so
    //  find skip value of beginning of page to fetch and see if we have all records for that page.
    const pageStart =
      Math.floor(dataState.skip / grid.dataState.pageSize!) *
      grid.dataState.pageSize!;

    // Always take one additional page for smoother scrolling and so user does not see blank records from next page.
    const fetchEnd = pageStart + grid.dataState.pageSize! * 2;
    const fetchTake = grid.dataState.pageSize! * 2;

    // To make scrolling smoother, we always want to fetch the previous page and next page, so we will be "taking" either
    //  2x the page size if we are at the beginning of the grid or 3x the page size if we have scrolled past the first page.
    // if (pageStart >= grid.dataState.pageSize!) {
    //   // Take one page back
    //   pageStart = pageStart - grid.dataState.pageSize!;
    //   fetchTake = grid.dataState.pageSize! * 3;
    // }
    // console.log(
    //   `Checking for blank records between ${pageStart} and ${fetchEnd}...`
    // );

    for (let i = pageStart; i < fetchEnd && i < grid.records.length; i++) {
      if (grid.records[i][grid.dataItemKey] === undefined) {
        // console.log(
        //   `JON: Found blank records in page at position ${i} so loading pages from server starting at: ${pageStart}`
        // );

        // Note that this data state will be the one used to fetch the page of data from the server and to track our last
        //  data request, so we are using the beginning of the page skip value here instead of the real skip value. We also
        //  use a custom take value.
        const newDataState = {
          ...grids.get(gridId)!.dataState,
          skip: pageStart,
          take: fetchTake
        };

        await fetchGridPageDataFromServer(newDataState, pageStart, false);
        return;
      }
    }
  };

  // jon, 3/30/22:  See my comment in fixStringFilters below for the purpose of these fun-filled, filter-fixing functions.
  const convertSingleFilterToIgnoreCase = (
    filter: FilterDescriptor
  ): FilterDescriptor => {
    let newFilter = filter;
    const stringOperators = [
      "contains",
      "doesnotcontain",
      "startswith",
      "endswith"
    ];
    const col = grids
      .get(gridId)
      ?.columns?.find((c) => c.field === filter.field);

    if (col && stringOperators.indexOf(filter.operator as string) >= 0) {
      newFilter = {
        ...filter,
        field: col.overrideFilterColumn
          ? col.overrideFilterColumn
          : filter.field,
        ignoreCase: true,
        value: (filter.value as string).toLowerCase()
      };
    }

    return newFilter;
  };

  const convertCompositeFilterToIgnoreCase = (
    filter: CompositeFilterDescriptor
  ): CompositeFilterDescriptor => {
    let newFilters = filter.filters;

    if (filter && filter.filters && filter.filters.length > 0) {
      newFilters = filter.filters.map((f) => {
        if ((f as any).filters !== undefined) {
          return convertCompositeFilterToIgnoreCase(
            f as CompositeFilterDescriptor
          );
        } else {
          return convertSingleFilterToIgnoreCase(f as FilterDescriptor);
        }
      });
    }

    return { ...filter, filters: newFilters };
  };

  const fixStringFilters = (dataState: GridProps): GridProps => {
    let newDataState = dataState;

    // jon, 3/30/22: We only want to "fix" the filter to force lower case if this grid has override columns since that is the only place
    //   where this issue exists. Normally SQL Server, being case-insensitive by defaul, handles the search using ODATA parameters correctly.
    //   But these override columns cause us to have to use raw SQL in the controller on the server and the string comparison is done in C#
    //   instead of SQL, so they are case-sensitive.  This fix change override columns to use a tolower on the column and value, and it switches
    //   the override column to use a special, new OverrideFilter* column to filter on since this col will contain the correct value based on
    //   if the value has been override or not and from which table in the case of price.
    if (
      dataState.filter &&
      (grids.get(gridId)?.columns?.findIndex((c) => c.overrideFilterColumn) ??
        -1) >= 0
    ) {
      newDataState = {
        ...dataState,
        filter: convertCompositeFilterToIgnoreCase(dataState.filter)
      };
    }

    return newDataState;
  };

  const fetchGridPageDataFromServer = async (
    dataState: any,
    pageStart: number,
    isReset: boolean
  ) => {
    // Only fetch from server if the new request differs from the previous one - de-bounce
    if (toODataString(dataState) === state.lastAPIRequestDataState) {
      // console.log(`Data already fetched: ${state.lastAPIRequestDataState}`);
      return;
    }

    setLoading(true);

    const gridEndpoint = grids.get(gridId)?.endpoints?.gridODataEndpoint
      ? grids.get(gridId)!.endpoints.gridODataEndpoint
      : gridSettings.endpoints.gridODataEndpoint;

    let dataResponse: any = { data: { "@odata.count": 0, value: [] } };

    // jon, 1/4/22: Do not call API if endpoint is empty. This is used on NodeScheduleHistory since the tabbed grids are initially blank before a record is selected.
    //   This new feature can be used for any grid that is not initially populated when the screen loads but is loaded via user action or after something else occurs.
    if (gridEndpoint !== "") {
      const rawResponse: Result<string> = await getGridData(gridEndpoint, {
        odataString: toODataString(fixStringFilters(dataState))
      });
      if (rawResponse.type !== ResultTypes.Success) return;

      dataResponse = rawResponse.value;
    }

    setState({
      ...state,
      lastAPIRequestDataState: toODataString(dataState)
    });

    if (dataResponse) {
      setGrid({
        type: GridActions.updateData,
        payload: {
          gridId,
          gridData: {
            data: dataResponse.data,
            pageStart,
            isReset
          }
        }
      });
    }
    setLoading(false);
  };

  // This event fires on every keystroke in a field.  Save actually occurs on blur.
  const onItemChange = (event: GridItemChangeEvent) => {
    const grid = grids.get(gridId)!;

    // Call a different onItemChange method for insert vs. edit mode since
    if (grid.state.insertMode === true) {
      const newRecord = grid.data[0];

      // @ts-ignore
      const inserted = { ...newRecord, ...{ [event.field]: event.value } };
      setGrid({
        type: GridActions.onInsertItemChange,
        payload: { gridId, gridData: inserted }
      });
    } else {
      // Edit mode
      const records = grid.records.map((record) => {
        let newRecord = record;
        if (record[grid.dataItemKey!] === event.dataItem[grid.dataItemKey!]) {
          // @ts-ignore
          newRecord = { ...record, ...{ [event.field]: event.value } };
        }
        return newRecord;
      });

      setGrid({
        type: GridActions.onItemChange,
        payload: { gridId, gridData: records }
      });
    }
  };

  // This event fires every time the grid is scrolled. It is used to keep track of the current scroll position so it can be reset after an insert
  //  or when returning to the main grid page after navigating to a "tab" page.
  const onGridScroll = (event: GridEvent) => {
    const gridContent = event.syntheticEvent.target as HTMLDivElement;

    if (grids.get(gridId)?.state.insertMode !== true) {
      // console.log(`onScroll event: ${gridContent.scrollTop}`);

      setGrid({
        type: GridActions.saveGridScrollPosition,
        payload: { gridId, gridData: gridContent.scrollTop }
      });
    }
  };

  const customHeaderCellRender: any = (
    th: React.ReactElement<HTMLTableHeaderCellElement>,
    props: GridCellProps
  ) => {
    // Pass the column definition from the column array defined in the Page to the header renderer for this cell.
    /* eslint-disable react/prop-types */
    const colDef = grids
      .get(gridId)!
      .columns!.find((col) => col.field === props.field!);

    return <HeaderCellRender gridId={gridId} th={th} colDefinition={colDef!} />;
  };

  const customCellRender: any = (
    td: React.ReactElement<HTMLTableCellElement>,
    props: GridCellProps
  ) => {
    // Pass the column definition from the column array defined in the Page to the cell renderer for this cell.
    /* eslint-disable react/prop-types */
    const colDef = grids
      .get(gridId)!
      .columns!.find((col) => col.field === props.field!);

    return (
      <CellRender
        originalProps={props}
        td={td}
        gridId={gridId}
        colDefinition={colDef!}
        exitEdit={exitCellInEditMode}
      />
    );
  };

  const customRowRender: any = (
    tr: React.ReactElement<HTMLTableRowElement>,
    props: GridRowProps
  ) => {
    return (
      <RowRender
        originalProps={props}
        tr={tr}
        gridId={gridId}
        hoverRowId={hoverRowId}
        onHoverRow={onHoverRow}
        exitEdit={exitRowInEditMode}
      />
    );
  };

  const updateColumnPropertiesOnRowDataChange = (
    changedColumns: GridColumns[] | undefined
  ) => {
    if (!changedColumns || changedColumns.length === 0) return;

    // Merge the changed columns into the existing column array
    const newColumns = grids.get(gridId)!.columns!.map((col) => {
      if (changedColumns.findIndex((c) => c.field === col.field) >= 0) {
        return {
          ...col,
          ...changedColumns.find((c) => c.field === col.field)!
        };
      } else {
        return col;
      }
    });

    // Update the grid columns to reflect the changes
    setGrid({
      type: GridActions.updateColumnState,
      payload: {
        gridId,
        gridData: {
          columns: newColumns,
          sort: grids.get(gridId)!.dataState.sort
        }
      }
    });
  };

  const onHoverRow = (rowId: number) => {
    setHoverRowId(rowId);
  };

  const exitCellInEditMode = async (
    type: string | undefined,
    field: string,
    previousDataItem: { [key: string]: any },
    dataItem: { [key: string]: any },
    fields: string[]
  ): Promise<string | null> => {
    let rowData = dataItem;
    let changedFields = fields;

    // Does this column have a custom onChange?
    const col = gridSettings.columns!.find((c) => c.field === field);
    if (col && col.onChange) {
      const { changedData, changedColumns } = col.onChange(
        dataItem[field],
        previousDataItem[field],
        dataItem,
        grids.get(gridId)!.columns!,
        grids,
        setGrid
      );

      // Get the changed data so it can be set in the grid for display and saved to the db
      if (changedData) {
        rowData = { ...rowData, ...changedData };
        changedFields = [...Object.keys(changedData), ...fields];

        // Update the grid display of changed fields
        if (grids.get(gridId)!.state.insertMode) {
          setGrid({
            type: GridActions.onInsertItemChange,
            payload: { gridId, gridData: rowData }
          });
        } else {
          const dataItemKey = grids.get(gridId)!.dataItemKey;
          const editedRecordID = dataItem[dataItemKey];
          const records = grids.get(gridId)!.records.map((record) => {
            let newRecord = record;
            if (record[dataItemKey] === editedRecordID) {
              newRecord = rowData;
            }
            return newRecord;
          });

          setGrid({
            type: GridActions.onItemChange,
            payload: { gridId, gridData: records }
          });
        }
      }

      // Get the changed columns so we can set them to read-only, for example.
      if (changedColumns) {
        updateColumnPropertiesOnRowDataChange(changedColumns);
      }
    }

    console.log(`GRID EXIT CELL - ${type} - Changes: `);
    for (let i = 0; i < changedFields.length; i++) {
      console.log(
        `  > ${changedFields[i]}: `,
        previousDataItem[changedFields[i]]
          ? previousDataItem[changedFields[i]]
          : "<EMPTY>",
        rowData[changedFields[i]] ? rowData[changedFields[i]] : "<EMPTY>"
      );
    }

    // Extract only changed values to save - these must be in the possible list of *fields* and have changed their values.
    // jon, 3/23/22: NodeDisplayID on NodeTemplates was being converted to a string so the save was triggering even while navigating
    //   even though the values were actually identical (except one was a string and the other an integer).  Converting to integers for
    //   that field here to avoid the unwanted save.
    const changes = Object.keys(rowData)
      .filter(
        (key) =>
          changedFields.includes(key) &&
          ((key !== "NodeDisplayID" &&
            rowData[key] !== previousDataItem[key]) ||
            (key === "NodeDisplayID" &&
              parseInt(rowData[key], 10) !==
                parseInt(previousDataItem[key], 10))) &&
          grids.get(gridId)!.columns!.find((c) => c.field === key)
            ?.canBeSaved === true
      )
      .reduce<{ [key: string]: any }>((obj, key) => {
        return {
          ...obj,
          [key]: rowData[key]
        };
      }, []);

    // If no changes OR we are in insert mode, do not save this field level change to the server.
    if (
      changes &&
      Object.keys(changes).length > 0 &&
      !grids.get(gridId)!.state.insertMode
    ) {
      // console.log(`***Saving changes:`);
      for (let i = 0; i < changes.length; i++) {
        console.log(`  > `, changes[i]);
      }

      // Save this cell change
      const saveResult = await onSave({
        id: rowData[gridSettings.dataItemKey],
        data: changes
      });

      return saveResult;
    }

    // console.log(
    //   `***No changes to save. Insert mode: ${
    //     grids.get(gridId)!.state.insertMode
    //   }`
    // );
    return null;
  };

  const onSave = async (event: {
    id: number;
    data: { [key: string]: any };
  }): Promise<string | null> => {
    setGrid({
      type: GridActions.toggleSavingIndicator,
      payload: { gridId, gridData: { show: true } }
    });

    try {
      const { id, data } = event;
      const rawResponse: Result<string> = await updateRecord({
        endpoint:
          gridSettings.endpoints.gridApiUpdateEndpointOverride ??
          gridSettings.endpoints.gridApiEndpoint ??
          "",
        id,
        data
      });

      // Handle validation as 400-level errors - these do not get caught by error handling in useApi.
      if (rawResponse.type !== ResultTypes.Success) {
        // Lock down the grid if validation failed so user must handle the error before performing another grid operation
        setGrid({
          type: GridActions.toggleGridLockoutMode,
          payload: { gridId, gridData: true }
        });
        const errors = replaceFieldsInErrorMsg(rawResponse.error.message).split(
          ";"
        );
        return `${errors}`;
      } else {
        // Extract new data from server after re-fetch of updated row
        const value: any = rawResponse.value;
        // console.log("GRID: Post UPDATE return value: ", value.data);
        if (value.data === "OK") {
          // data here will be an object with the new field value only
          setGrid({
            type: GridActions.updateSelectedRowData,
            payload: {
              gridId,
              gridData: { rowData: null, newFieldValue: data }
            }
          });
        }

        // Only do this for APIs that return the actual record. Some return OK.
        //  jon, 1/6/22: Some grids cannot support this because something besides OK is passed back - like NodeItems and PriceScheduleItems. Check the new grid property.
        if (
          value.data !== "OK" &&
          grids.get(gridId)?.replaceRowAfterUpdate !== false
        ) {
          const retData: { [key: string]: any } = value.data;

          // Set the returned data in the row to reflect any server-side data updates after this field was updated
          const grid = grids.get(gridId)!;
          const records = grid.records.map((record) => {
            let newRecord = record;
            if (record[grid.dataItemKey!] === id) {
              // jon, 1/19/22: Need to preserve the old row data's Index property for navigation to continue to work.
              newRecord = retData;
              newRecord.Index = record.Index;
            }
            return newRecord;
          });

          setGrid({
            type: GridActions.onItemChange,
            payload: { gridId, gridData: records }
          });

          setGrid({
            type: GridActions.updateSelectedRowData,
            payload: {
              gridId,
              gridData: { rowData: retData, newFieldValue: null }
            }
          });

          // Call any post-update custom event defined in grid
          if (grid && onAfterUpdate && grid.state.parentSelectedRowData) {
            onAfterUpdate(retData);
          }
        }
      }

      // Unlock grid (if it is locked out) because save was successful
      if (grids.get(gridId)!.state.lockoutMode) {
        setGrid({
          type: GridActions.toggleGridLockoutMode,
          payload: { gridId, gridData: false }
        });
      }

      // Update last save date
      setGrid({
        type: GridActions.updateLastSaveDate,
        payload: { gridId, gridData: { date: new Date() } }
      });
    } finally {
      setGrid({
        type: GridActions.toggleSavingIndicator,
        payload: { gridId, gridData: { show: false } }
      });
    }

    return null;
  };

  const saveInsertRow = async (data: {
    [key: string]: any;
  }): Promise<null | { [key: string]: any }> => {
    const rawResponse: Result<string> = await insertRecord({
      endpoint:
        gridSettings.endpoints.gridApiInsertEndpointOverride ??
        gridSettings.endpoints.gridApiEndpoint ??
        "",
      data
    });
    // console.log(`Save Insert Row response: `, rawResponse);

    if (rawResponse.type !== ResultTypes.Success) {
      const msg = "Save failed with the following message(s):\n\n";
      const errors = replaceFieldsInErrorMsg(rawResponse.error.message).split(
        ";"
      );

      console.log(msg + errors.join("\n"));
      dispatch({
        type: StoreActions.addNotification,
        payload: {
          message: msg + errors.join("\n"),
          messageType: "warning",
          closable: true
        }
      });
      return null;
    }

    // Extract the data returned from the server and set these values into the new row since some things might have changed on the server (like ID).
    const value: any = rawResponse.value;
    const retData: { [key: string]: any } = value.data;
    // console.log(`Insert Row server data: `, retData);

    return retData;
  };

  const replaceFieldsInErrorMsg = (message: string): string => {
    let msg = "" + message;
    msg = msg.replaceAll("!CRLF!", "\n");

    // Some messages contain a field reference like "[CompanyID] is required". Replace these references with the actual field label.
    //   Set focus in first field from the validation message by setting the grid's editFieldName property to this field. A LayoutEffect in the control
    //   checks for this change and sets focus.
    let isFirstValidationField: boolean = true;
    while (msg.indexOf("[") >= 0) {
      const field = msg.substring(msg.indexOf("[") + 1, msg.indexOf("]"));
      const label = grids
        .get(gridId)!
        .columns!.find((col) => col.field === field)?.title;
      msg = msg.replaceAll(`[${field}]`, label || field);

      if (isFirstValidationField) {
        isFirstValidationField = false;
        setGrid({
          type: GridActions.editFieldName,
          payload: { gridId, gridData: field }
        });
      }
    }
    return msg;
  };

  function exitRowInEditMode(
    type: string | undefined,
    previousDataItem: { [key: string]: any },
    dataItem: { [key: string]: any },
    field: object
  ) {
    console.log(`GRID EXIT ROW - ${type}: `, dataItem, field);
  }

  const isSubgrid = grids.get(gridId)?.isSubgrid ?? false;
  const isDetailGridShowing =
    grids.get(gridId)?.state.selectedRowIsExpanded ?? false;
  const isParentGrid = grids.get(gridId)?.detailRow !== undefined;
  const isReadonly = grids.get(gridId)?.isReadonly ?? false;
  const detailGridId =
    grids.get(gridId)?.detailRow?.subgridId !== undefined
      ? grids.get(gridId)?.detailRow?.subgridId![0]
      : "";

  // Get parent grid width for subgrids so we can set their max width - otherwise, the right side of the subgrid can get cut off. We want it to scroll instead.
  let parentGridWidth: number = 0;
  if (isSubgrid && grids.get(gridId) && grids.get(gridId)?.parentGridId) {
    parentGridWidth =
      grids.get(grids.get(gridId)!.parentGridId!)?.state.gridWidth ?? 0;
  }

  const count = grids.get(gridId) ? grids.get(gridId)!.total ?? 0 : 0;
  const recordCount = !isSubgrid && (
    <RecordCount>
      {count.toLocaleString()}{" "}
      {count === 1
        ? gridSettings.singularEntityName
        : gridSettings.pluralEntityName}
    </RecordCount>
  );

  const requiredColHelp = !isReadonly && !isSubgrid && (
    <RequiredColHelp>
      <span style={{ color: "var(--carbon-orange)" }}>*&nbsp;</span>Required
      field
    </RequiredColHelp>
  );

  // If this is the first load, just show the loader by itself since grid does not yet exist.
  if (!grids.get(gridId)?.columns) return <OctopusLoader />;

  const isGridLockedOut = grids.get(gridId)!.state.lockoutMode;

  const columnSelectorButton = (
    <ColumnSelectorButton
      selected={state.showColumnSelector}
      onClick={() => {
        // setVisibleColumns(grids.get(gridId)!.state.visibleColumns);
        setState({ ...state, showColumnSelector: !state.showColumnSelector });
      }}
    >
      <span className="k-icon k-i-more-horizontal" />
    </ColumnSelectorButton>
  );

  const onTextareaDialogClose = () => {
    setShowTextareaDialog(false);

    setGrid({
      type: GridActions.onSetTextareaDialogParams,
      payload: { gridId: gridId, gridData: undefined }
    });
  };

  const onDeleteRecordCancel = () => {
    setShowDeleteConfirmDialog(false);

    setGrid({
      type: GridActions.onDeleteRecord,
      payload: { gridId: gridId, gridData: { id: undefined } }
    });
  };

  const onDeleteRecord = async () => {
    setShowDeleteConfirmDialog(false);

    setGrid({
      type: GridActions.toggleSavingIndicator,
      payload: { gridId, gridData: { show: true } }
    });

    try {
      const rawResponse: Result<string> = await deleteRecord({
        endpoint:
          gridSettings.endpoints.gridApiDeleteEndpointOverride ??
          gridSettings.endpoints.gridApiEndpoint ??
          "",
        id: grids.get(gridId)!.state.recordIdToDelete!
      });
      if (rawResponse.type !== ResultTypes.Success) {
        const msg = "Delete failed with the following message(s):\n\n";
        const errors = replaceFieldsInErrorMsg(rawResponse.error.message).split(
          ";"
        );

        console.log(msg + errors.join("\n"));
        dispatch({
          type: StoreActions.addNotification,
          payload: {
            message: msg + errors.join("\n"),
            messageType: "warning",
            closable: true
          }
        });
      } else {
        // If this grid has node schedule ids, remove the deleted NodeScheduleID from the grid's state
        if (grids.get(gridId)?.state.nodeScheduleIds) {
          const nodeScheduleIDs = [
            ...grids.get(gridId)!.state.nodeScheduleIds!
          ];
          const index = nodeScheduleIDs.indexOf(
            grids.get(gridId)!.state.recordIdToDelete!
          );
          if (index >= 0) {
            nodeScheduleIDs.splice(index, 1);

            setGrid({
              type: GridActions.updateNodeScheduleIds,
              payload: {
                gridId,
                gridData: {
                  data: nodeScheduleIDs
                }
              }
            });
          }
        }

        // Call any post-delete custom event defined in grid
        if (
          grids.get(gridId) &&
          grids.get(gridId)?.state.recordIdToDelete &&
          onAfterDelete
        ) {
          onAfterDelete(grids.get(gridId)!.state.recordIdToDelete!);
        }
      }

      setGrid({
        type: GridActions.onDeleteRecord,
        payload: {
          gridId: gridId,
          gridData: {
            id: undefined,
            recordDeleted: rawResponse.type === ResultTypes.Success
          }
        }
      });

      // Update last save date if save was successful
      if (rawResponse.type === ResultTypes.Success) {
        setGrid({
          type: GridActions.updateLastSaveDate,
          payload: { gridId, gridData: { date: new Date() } }
        });
      }
    } finally {
      setGrid({
        type: GridActions.toggleSavingIndicator,
        payload: { gridId, gridData: { show: false } }
      });
    }
  };

  const filterOperators: GridFilterOperators = {
    // Adding custom filter operators for text types so we can filter by date string to find all dates >= date.
    text: [
      { text: "grid.filterContainsOperator", operator: "contains" },
      { text: "grid.filterNotContainsOperator", operator: "doesnotcontain" },
      { text: "grid.filterEqOperator", operator: "eq" },
      { text: "grid.filterNotEqOperator", operator: "neq" },
      { text: "grid.filterStartsWithOperator", operator: "startswith" },
      { text: "grid.filterEndsWithOperator", operator: "endswith" },
      { text: "grid.filterIsNullOperator", operator: "isnull" },
      { text: "grid.filterIsNotNullOperator", operator: "isnotnull" },
      { text: "grid.filterIsEmptyOperator", operator: "isempty" },
      { text: "grid.filterIsNotEmptyOperator", operator: "isnotempty" },
      { text: "grid.filterGteOperator", operator: "gte" },
      { text: "grid.filterGtOperator", operator: "gt" },
      { text: "grid.filterLteOperator", operator: "lte" },
      { text: "grid.filterLtOperator", operator: "lt" }
    ],
    numeric: [
      { text: "grid.filterEqOperator", operator: "eq" },
      { text: "grid.filterNotEqOperator", operator: "neq" },
      { text: "grid.filterGteOperator", operator: "gte" },
      { text: "grid.filterGtOperator", operator: "gt" },
      { text: "grid.filterLteOperator", operator: "lte" },
      { text: "grid.filterLtOperator", operator: "lt" },
      { text: "grid.filterIsNullOperator", operator: "isnull" },
      { text: "grid.filterIsNotNullOperator", operator: "isnotnull" }
    ],
    date: [
      { text: "grid.filterEqOperator", operator: "eq" },
      { text: "grid.filterNotEqOperator", operator: "neq" },
      { text: "grid.filterAfterOrEqualOperator", operator: "gte" },
      { text: "grid.filterAfterOperator", operator: "gt" },
      { text: "grid.filterBeforeOperator", operator: "lt" },
      { text: "grid.filterBeforeOrEqualOperator", operator: "lte" },
      { text: "grid.filterIsNullOperator", operator: "isnull" },
      { text: "grid.filterIsNotNullOperator", operator: "isnotnull" }
    ],
    boolean: [{ text: "grid.filterEqOperator", operator: "eq" }]
  };

  // This loader shows on top of the empty grid while it is loading data
  const loader = loading || gridODataIsLoading ? <OctopusLoader /> : null;

  const handleColumnReorder = (event: GridColumnReorderEvent) => {
    const currentActiveGridColumns = grids.get(gridId)?.columns;

    const columnOrderLayout: ColumnOrderLayout[] = event.columns.map(
      (column) => {
        return {
          field: column.field!,
          orderIndex: column.orderIndex!
        };
      }
    );

    const orderedColumns = [];
    const customColumnOrderLayout = [...columnOrderLayout];

    // sort the custom layout array by the orderIndex field
    customColumnOrderLayout.sort((a, b) => {
      return a.orderIndex - b.orderIndex;
    });

    // append the sorted, visible columns to the array first
    for (const col of customColumnOrderLayout) {
      const realColumn = currentActiveGridColumns?.find(
        (val) => val.field === col.field
      );
      const tempCol = { ...realColumn };
      tempCol.orderIndex = col.orderIndex;
      orderedColumns.push(tempCol!);
    }

    // append remaining columns (invisible columns)
    currentActiveGridColumns?.forEach((col) => {
      const column = customColumnOrderLayout.find(
        (val) => col.field === val.field
      );
      // column does not exist in sorted, visible array, so append to end
      if (!column) {
        orderedColumns.push(col);
      }
    });

    // set the visible columns and order for the gridselector component
    const currentSelectorColumns =
      grids.get(gridId)?.state.visibilitySelectorColumns;
    const gridSelectorColumns: GridSelectorColumn[] = orderedColumns.map(
      (col) => {
        const columnSelector = currentSelectorColumns?.find(
          (selector) => selector.field === col.field
        );
        const tempCol = {} as GridSelectorColumn;
        tempCol.field = col.field!;
        tempCol.defaultShow = col.defaultShow!;
        tempCol.required = col.required!;
        tempCol.show = col.show!;
        tempCol.isCombinedCol = columnSelector?.isCombinedCol!;
        tempCol.title = col.title!;
        // will, 2/2/22: reordering caused system hidden fields to unhide
        tempCol.systemHidden = col.systemHidden ?? false;
        return tempCol;
      }
    );

    setGrid({
      type: GridActions.setVisiblitySelectorColumns,
      payload: {
        gridId,
        gridData: {
          columns: gridSelectorColumns
        }
      }
    });

    setGrid({
      type: GridActions.setColumnOrderLayout,
      payload: {
        gridId,
        gridData: {
          columns: orderedColumns
        }
      }
    });
  };

  const handleColumnResize = (event: GridColumnResizeEvent) => {
    /**
     * there's a bug in the kendo grid where it hides the column on double click
     * prevent double click behavior on column resize
     */
    if (event.nativeEvent.type === "dblclick") {
      return;
    }

    // console.log("resizeing", event);

    const resizedColumn = event.columns.find(
      (val) => val.id === event.targetColumnId
    );
    const newWidth = event.newWidth;

    const columnResizeLayout: ColumnResizeLayout = {
      field: resizedColumn?.field! ?? "",
      width: `${newWidth}px`
    };

    if (event.end) {
      setGrid({
        type: GridActions.setColumnResizeLayout,
        payload: {
          gridId,
          gridData: {
            data: columnResizeLayout
          }
        }
      });
    }
  };

  const clearSubgridIfExists = () => {
    if (grids.get(gridId)!.detailRow) {
      grids.get(gridId)!.detailRow?.subgridId?.forEach((subgridId) => {
        if (grids.get(subgridId ?? "")) {
          setGrid({
            type: GridActions.delete,
            payload: {
              gridId: subgridId,
              gridData: null
            }
          });
        }
      });
    }
  };

  // jon, 4/5/22: Rearranged the expand row event so that the old subgrid can be cleared and the new parent grid selectedRowData can be populated BEFORE
  //   the new subgrid is expanded. This fixes the issue where a zone operation like adding multiple zone records messes up the new subgrid if another
  //   template row is immediately selected afterwards - the previous subgrid's data was showing instead.
  const expandChangeAfterTimeout = async (event: GridExpandChangeEvent) => {
    // Remove old detail grid(s) if there is one
    clearSubgridIfExists();

    setGrid({
      type: GridActions.onExpandRow,
      payload: {
        gridId,
        gridData: event
      }
    });

    window.setTimeout(async () => {
      await expandChange(event);
    }, 100);
  };

  const expandChange = async (event: GridExpandChangeEvent) => {
    // If this is the node schedules grid, refresh the zones (synchronously) when expanding the row
    if (event.value && gridId === GridIDs.NodeTemplates) {
      setLoading(true);
      const nodeScheduleID =
        grids.get(gridId)?.state.selectedRowData?.NodeScheduleID;
      const days = `${grids.get(gridId)!.state.selectedDayGroupDays}`;
      const daypartid = grids.get(gridId)!.state.activeDayPart!.id;
      // console.log(`Expand Change NodeScheduleID: ${nodeScheduleID}`);

      if (nodeScheduleID) {
        const payload: NodeSchedulesRefreshZonesContract = {
          nodeScheduleID,
          days,
          daypartid
        };

        console.log(
          `Refreshing zones from template onExpandRow for NodeScheduleID ${nodeScheduleID}`,
          payload
        );
        await refreshZonesFromTemplate(payload);
      }
      setLoading(false);
    }
  };

  const customGridColumnFilter = (props: GridFilterCellProps, column: any) => {
    const filter = column.customFilter;
    switch (filter) {
      case "status":
        return <DropdownStatusFilterCell {...props} data={column.statuses} />;
      case "dropdown":
        return <DropdownStatusFilterCell {...props} data={column.dropItems} />;
      case "default":
        return <DropdownFilterCell {...props} data={column.customFilterData} />;
    }
  };

  return (
    <>
      {onEmptyGridRender && !loading && grids.get(gridId)!.data.length === 0 ? (
        onEmptyGridRender()
      ) : (
        <div
          ref={(elem: HTMLDivElement) => {
            setGridRef(elem);

            // Find the actual table size that is showing content, not the full width of the grid, which is normally full screen width.
            if (elem) {
              const gridContent = elem.querySelector(
                "table[role=presentation]"
              );
              if (gridContent) {
                setPresentationRef(gridContent);
              }
            }
          }}
          style={{
            position: "relative",
            right: isSubgrid ? "20px" : "auto"
          }}
        >
          {showDeleteConfirmDialog && (
            <ConfirmationDialog
              bodyText="Are you sure you want to delete this record?"
              onAcceptCallback={onDeleteRecord}
              onRejectCallback={onDeleteRecordCancel}
            />
          )}
          {showCopyRecordDialog && <CopyRecordDialog gridId={gridId} />}
          {showTextareaDialog && (
            <TextareaDialog
              title={grids.get(gridId)!.state.textAreaDialogParams?.title}
              bodyText={grids.get(gridId)!.state.textAreaDialogParams?.value}
              onAcceptCallback={onTextareaDialogClose}
            />
          )}
          {requiredColHelp}
          {recordCount}
          {loader}
          {!isGridLockedOut && !isSubgrid && columnSelectorButton}
          {!isSubgrid && (
            <GridColumnSelector
              carbonGridState={state}
              setCarbonGridState={setState}
              gridId={gridId}
            />
          )}
          <Tooltip openDelay={1000} position="bottom" anchorElement="target">
            <Grid
              className={`k-carbon-grid ${gridClassName ?? ""}`}
              ref={(g) => {
                if (g) setKendoGridRef(g);
              }}
              onColumnResize={handleColumnResize}
              onColumnReorder={handleColumnReorder}
              key={gridId}
              {...grids.get(gridId)!.dataState}
              data={grids.get(gridId)!.data}
              total={grids.get(gridId)!.total}
              detail={grids.get(gridId)!.detailRow?.content ?? null}
              expandField="expanded"
              onExpandChange={
                grids.get(gridId)!.detailRow
                  ? expandChangeAfterTimeout
                  : undefined
              }
              // === Grid Functions:
              onSelectionChange={(event: GridSelectionChangeEvent) => {
                // console.log(`${gridId} onSelectionChange`, event);
                // jon, 10/5/21: I think there is a bug in the Kendo React grids that causes the onSelectionChange event to fire on the parent grid after clicking a row in the
                //   subgrid. Even worse, the row number in the event on the parent grid is set to the child row number, so clicking a subgrid row causes the parent row to change.
                //   The workaround is to check for a particular detail class in the event target's path for the parent grid. If that is present, we will not fire the selection
                //   change.  And we will stop the event propogation on subgrids.
                if (isSubgrid) {
                  event.nativeEvent.stopImmediatePropagation();
                }

                // jon, 1/31/22: Safari fix for path not defined when doing path.find below.
                const path =
                  event?.nativeEvent?.path ||
                  (event?.nativeEvent?.composedPath &&
                    event?.nativeEvent?.composedPath());
                // console.log(`event path: `, path);

                // jon, 2/4/22, check for list scroller class to handle case where dropdown selection was causing template grid row selection to fire which
                //   made the subgrid jump to the first template in the grid. Now, if that dropdown listbox is displaying, the row select will not fire.
                //   Also integrated the safari event path.
                // jon, 2/10/22: check for Grid Locked/Edit Mode button link as the source for this event. Same kendo grid bug will cause the row select event to
                //   fire on the parent grid (templates) and switch the row on us.  But we will ignore that event instead to prevent it.
                // jon, 3/2/22: Added check for calendar control being clicked in subgrid so it does not change rows in parent grid
                // jon, 4/18/22: Added check to make sure user is actually clicking within a grid when this event is fired. There is a case on the Nodes main screen where
                //   after clicking in a subgrid and then clicking somewhere outside the grid, this event fires and changes the row of the parent grid to the subgrid's row index.
                if (
                  !isParentGrid ||
                  (isParentGrid &&
                    !path.find(
                      (item: any) =>
                        item.className === "k-detail-cell" ||
                        item.className === "k-list-scroller" ||
                        item.className === "k-button carbon-link-btn" ||
                        item.className === "k-calendar-table"
                    ) &&
                    path.find(
                      (item: any) => item.className === "table.k-grid-table"
                    ))
                ) {
                  // jon, 4/7/22: One more check to make sure this isn't a parent grid with the subgrid in edit mode. If so, we don't want to change parent records!
                  //   This fixes the problem on Node Schedules where the second template's subgrid is moved to the first template in the parent grid after moving to
                  //   another screen and back while editing subgrid.
                  if (
                    isParentGrid &&
                    (detailGridId ?? "") !== "" &&
                    grids.get(detailGridId!)?.state.editMode === true
                  ) {
                    console.log(
                      "OnSelectionChanged: Skipping selection change for parent grid since subgrid is in edit mode"
                    );
                  } else {
                    // jon, 3/23/22: Without the delay introduces by the setTimeout below, this selection change was firing before the blur event could be
                    //   triggered on mobile so save was not working when clicking to a new row after a change.
                    console.log(
                      "OnSelectionChanged: Continuing with selection change"
                    );
                    window.setTimeout(() => {
                      setGrid({
                        type: GridActions.onSelectionChange,
                        payload: { gridId, gridData: event }
                      });
                    }, 1);
                  }
                }
              }}
              onDataStateChange={(event: GridDataStateChangeEvent) =>
                onDataStateChange(event)
              }
              onRowClick={(event: GridRowClickEvent) => {
                setGrid({
                  type: GridActions.onExpandRow,
                  payload: {
                    gridId,
                    gridData: { value: false, dataItem: {} }
                  }
                });
                setGrid({
                  type: GridActions.onRowClick,
                  payload: { gridId, gridData: event }
                });
              }}
              onItemChange={(event: GridItemChangeEvent) => onItemChange(event)}
              onScroll={(event: GridEvent) => onGridScroll(event)}
              cellRender={customCellRender}
              headerCellRender={customHeaderCellRender}
              rowRender={customRowRender}
              // === Grid Settings
              style={{
                height: isSubgrid ? "auto" : grids.get(gridId)!.height,
                maxHeight: isSubgrid ? "340px" : "auto",
                width:
                  isSubgrid && parentGridWidth > 0
                    ? `${parentGridWidth}px`
                    : "auto",
                marginBottom: isSubgrid ? "10px" : "auto"
              }}
              filterable={
                !isGridLockedOut && grids.get(gridId)!.state.showQuickFilter
              }
              filterOperators={filterOperators}
              columnVirtualization={false}
              // jon, 1/15/22: Virtual scroll messes up the scrolling in the Node Templates grid when subgrids are open. Since we are already treating
              //   this grid as a non-virtual grid (i.e., we are loading up to 10K rows to be sure ALL are fetched), this grid can be changed to scrollable.
              scrollable={
                isSubgrid || gridId === GridIDs.NodeTemplates
                  ? "scrollable"
                  : "virtual"
              }
              selectedField={"selected"}
              selectable={{ mode: "single" }}
              resizable={!isGridLockedOut}
              reorderable={!isGridLockedOut}
              editField={"inEdit"}
              sortable={{
                allowUnsort: true,
                mode: "multiple"
              }}
              rowHeight={GRID_NORMAL_ROW_HEIGHT}
            >
              <GridNoRecords>
                {loading ? " " : "No results found."}
              </GridNoRecords>
              {grids.get(gridId)!.columns!.map((column: any, idx: any) => {
                const userHasSingleCompany = store.user?.companies.length === 1;
                const defaultShow =
                  column.field === "CompanyName" && userHasSingleCompany
                    ? false
                    : column.defaultShow;
                const showCol =
                  column.show === undefined ? defaultShow : column.show;
                const editorType =
                  column.editor === "boolean" ? column.editor : "text";
                return (
                  showCol &&
                  !(column.systemHidden === true) && (
                    <GridColumn
                      orderIndex={column.orderIndex}
                      key={idx}
                      field={column.field}
                      title={column.title}
                      filter={column.filter}
                      filterable={column.filterable ?? true}
                      sortable={column.sortable ?? true}
                      resizable={column.resizable ?? true}
                      width={column.width}
                      editable={
                        (column.editable ||
                          (column.editableInInsertOnly === true &&
                            grids.get(gridId)!.state.insertMode === true)) &&
                        (!isParentGrid ||
                          (isParentGrid && !isDetailGridShowing))
                      }
                      editor={editorType}
                      filterCell={
                        column.customFilter &&
                        ((props: GridFilterCellProps) =>
                          customGridColumnFilter(props, column))
                      }
                    />
                  )
                );
              })}
            </Grid>
          </Tooltip>
        </div>
      )}
    </>
  );
};

const RecordCount = styled(Label)`
  position: absolute;
  top: -22px;
  right: 2px;
  font-size: 12px;
  text-align: right;
`;

const RequiredColHelp = styled(Label)`
  position: absolute;
  bottom: -25px;
  left: 2px;
  font-size: 12px;
  text-align: left;
  font-weight: bold;
`;

const ColumnSelectorButton = styled(Button)`
  position: absolute;
  z-index: 2;
  top: 7px;
  right: 6px;
  height: 19px;
  width: 30px;
  padding: 0;
  cursor: pointer;
  text-align: center;
  border-radius: 3px;
  border: 1px solid var(--carbon-mediumgray);
  background-color: var(--carbon-white);

  ${({ selected }) =>
    selected &&
    `
    color: var(--carbon-white);
    background-color: var(--carbon-blue);
  `}

  &:hover, &:active {
    color: var(--carbon-white);
    background-color: var(--carbon-blue);
    border-color: var(--carbon-blue);
  }

  span.k-icon {
    padding: 2px 0 0 2px;
    font-size: 12px;
  }
`;

export default CarbonGrid;
