import {
  Result,
  ResultError,
  CampaignsDashboardDetailContract
} from "../../types";
import useApi from "../../hooks/api/useApi";
import { Placeholders } from "../../constants";
import { useStore } from "../../contexts/store";
import GetCampaignsDashboardData from "../../api/CampaignsDashboard/campaignsDashboardApi";
import { campaignsDashboardEndpoint } from "../../constants/endpoints/fileUpload";

const useCampaginsDashboardApi = () => {
  const { store } = useStore();

  const { loading: isLoading, request: campaignsDashboardRequest } = useApi(
    GetCampaignsDashboardData
  );

  const getCampaignsDashboardGridData = async (
    payload: CampaignsDashboardDetailContract
  ): Promise<Result<string>> => {
    const url = `${campaignsDashboardEndpoint.replace(
      Placeholders.companyID,
      `${store.activeCompany!.companyId}`
    )}`;

    let result: Result<string>;
    try {
      result = await campaignsDashboardRequest(url, payload);
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }

    return result;
  };

  return {
    getCampaignsDashboardGridData,
    isLoading
  };
};

export default useCampaginsDashboardApi;
