// Base
import { Navigate, Outlet } from "react-router-dom";

// Constants
import { EndpointResources as EndPts } from "./constants";

// Components
import DashboardLayout from "./components/Layouts/Dashboard/DashboardLayout";
import MainLayout from "./components/Layouts/Main/MainLayout";

// Pages
import Home from "./pages/Home";
import Login from "./pages/Login";

// Pages - Nodes
import Nodes from "./pages/Nodes/Nodes";
import NodeSchedules from "./pages/Nodes/NodeSchedules";
import NodeItems from "./pages/Nodes/NodeItems";
import NodeScheduleHistory from "./pages/Nodes/NodeScheduleHistory";
import NodeDayParts from "./pages/Nodes/NodeDayParts";
import NodePatchHistory from "./pages/Nodes/NodePatchHistory";
import NodeMovieCodes from "./pages/Nodes/NodeMovieCodes";
import NodeDisplays from "./pages/Nodes/NodeDisplays";
import NodeVideoCards from "./pages/Nodes/NodeVideoCards";

// Pages - Content
import MediaLibrary from "./pages/MediaLibrary/MediaLibrary";
import MediaMovieCodes from "./pages/MediaLibrary/MediaMovieCodes";
import Packages from "./pages/Packages/Packages";
import PackageZones from "./pages/Packages/PackageZones";
import Items from "./pages/Items/Items";
import PriceSchedules from "./pages/PriceSchedules/PriceSchedules";
import PriceScheduleItems from "./pages/PriceSchedules/PriceScheduleItems";

// Pages - Layouts
import Templates from "./pages/Templates/Templates";
import TemplateZones from "./pages/Templates/TemplateZones";
import Stencils from "./pages/Stencils/Stencils";
import StencilZones from "./pages/Stencils/StencilZones";
import TextStyles from "./pages/TextStyles/TextStyles";
import Fonts from "./pages/Fonts/Fonts";

// Pages - Tools
import GlobalChanges from "./pages/GlobalChanges/GlobalChanges";

// Pages - Campaigns
import Campaigns from "./pages/Campaigns/Campaigns";
import CampaignsDashboard from "./pages/CampaignsDashboard/CampaignsDashboard";

// Pages - Admin
import Companies from "./pages/Companies/Companies";
import Users from "./pages/Users/Users";
import UserManagedCompanies from "./pages/Users/UserManagedCompanies";
import UserNodeAssignments from "./pages/Users/UserNodeAssignments";
import Roles from "./pages/Roles/Roles";
import RolePermissions from "./pages/Roles/RolePermissions";
import Patches from "./pages/Patches/Patches";
import PatchCompanies from "./pages/Patches/PatchCompanies";
import AuditTrail from "./pages/AuditTrail/AuditTrail";
import UserDefinedTypes from "./pages/UserDefinedTypes/UserDefinedTypes";
import UserDefinedTypeCompanies from "./pages/UserDefinedTypes/UserDefinedTypeCompanies";
import PreviewerStandalone from "./components/Previewer/PreviewerStandalone";

// Pages - Standard
import PrivacyPolicy from "./pages/Legal/PrivacyPolicy";
import TermsOfUse from "./pages/Legal/TermsOfUse";
import HelpHome from "./pages/Help/HelpHome";

const routes = (isLoggedin: boolean) => [
  {
    path: EndPts.App,
    element: isLoggedin ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      { path: "/", element: <Navigate to="/app/home" /> },
      { path: EndPts.Home, element: <Home /> },
      { path: EndPts.Items, element: <Items /> },
      { path: EndPts.AuditTrail, element: <AuditTrail /> },
      { path: EndPts.Companies, element: <Companies /> },
      { path: EndPts.TextStyles, element: <TextStyles /> },
      { path: EndPts.Fonts, element: <Fonts /> },
      { path: EndPts.Campaigns, element: <Campaigns /> },
      { path: EndPts.GlobalChanges, element: <GlobalChanges /> },
      {
        path: EndPts.PriceSchedules,
        element: <Outlet />,
        children: [
          {
            path: "/",
            element: <PriceSchedules />
          },
          {
            path: `:priceScheduleId/${EndPts.PriceScheduleItems}`,
            element: <PriceScheduleItems />
          }
        ]
      },
      {
        path: EndPts.Roles,
        element: <Outlet />,
        children: [
          {
            path: "/",
            element: <Roles />
          },
          {
            path: `:roleId/${EndPts.RolePermissions}`,
            element: <RolePermissions />
          }
        ]
      },
      {
        path: EndPts.Patches,
        element: <Outlet />,
        children: [
          {
            path: "/",
            element: <Patches />
          },
          {
            path: `:patchId/${EndPts.PatchCompanies}`,
            element: <PatchCompanies />
          }
        ]
      },
      {
        path: EndPts.Users,
        element: <Outlet />,
        children: [
          {
            path: "/",
            element: <Users />
          },
          {
            path: ":userId",
            children: [
              {
                path: EndPts.UserManagedCompanies,
                element: <UserManagedCompanies />
              },
              {
                path: EndPts.UserNodeAssignment,
                element: <UserNodeAssignments />
              }
            ]
          }
        ]
      },
      {
        path: EndPts.Media,
        element: <Outlet />,
        children: [
          {
            path: "/",
            element: <MediaLibrary />
          },
          {
            path: `:mediaId/${EndPts.MediaMovieCodes}`,
            element: <MediaMovieCodes />
          }
        ]
      },
      {
        path: EndPts.Packages,
        element: <Outlet />,
        children: [
          {
            path: "/",
            element: <Packages />
          },
          {
            path: `:packageId/${EndPts.PackageZones}`,
            element: <PackageZones />
          }
        ]
      },
      {
        path: EndPts.Stencils,
        element: <Outlet />,
        children: [
          {
            path: "/",
            element: <Stencils />
          },
          {
            path: `:stencilId/${EndPts.StencilZones}`,
            element: <StencilZones />
          }
        ]
      },
      {
        path: EndPts.Templates,
        element: <Outlet />,
        children: [
          {
            path: "/",
            element: <Templates />
          },
          {
            path: `:templateId/${EndPts.TemplateZones}`,
            element: <TemplateZones />
          }
        ]
      },
      {
        path: EndPts.Nodes,
        element: <Outlet />,
        children: [
          {
            path: "/",
            element: <Nodes />
          },
          {
            path: ":nodeId",
            children: [
              {
                path: EndPts.NodeSchedules,
                element: <NodeSchedules />
              },
              {
                path: EndPts.NodeItems,
                element: <NodeItems />
              },
              {
                path: EndPts.NodeHistory,
                element: <NodeScheduleHistory />
              },
              {
                path: EndPts.NodeDayparts,
                element: <NodeDayParts />
              },
              {
                path: EndPts.NodePatchHistory,
                element: <NodePatchHistory />
              },
              {
                path: EndPts.NodeMovieCodes,
                element: <NodeMovieCodes />
              },
              {
                path: EndPts.NodeDisplays,
                element: <NodeDisplays />
              },
              {
                path: EndPts.NodeVideoCards,
                element: <NodeVideoCards />
              }
            ]
          }
        ]
      },
      {
        path: EndPts.UserDefinedTypes,
        element: <Outlet />,
        children: [
          {
            path: "/",
            element: <UserDefinedTypes />
          },
          {
            path: `:typeId/${EndPts.UserDefinedTypeCompanies}`,
            element: <UserDefinedTypeCompanies />
          }
        ]
      },
      {
        path: EndPts.CampaignsDashboard,
        element: <Outlet />,
        children: [
          {
            path: "/",
            element: <CampaignsDashboard />
          }
        ]
      },
      {
        path: "legal",
        element: <Outlet />,
        children: [
          {
            path: "/privacy-policy",
            element: <PrivacyPolicy />
          },
          {
            path: "/terms-of-use",
            element: <TermsOfUse />
          }
        ]
      },
      {
        path: "help",
        element: <Outlet />,
        children: [
          {
            path: "/",
            element: <HelpHome />
          }
        ]
      }
    ]
  },
  {
    path: "app/previewer",
    element: isLoggedin ? <PreviewerStandalone /> : <Navigate to="/login" />
  },
  {
    path: "/",
    element: !isLoggedin ? (
      <MainLayout />
    ) : (
      <Navigate to={`/${EndPts.App}/${EndPts.Home}`} />
    ),
    children: [
      { path: EndPts.Login, element: <Login /> },
      { path: "/", element: <Navigate to={`/${EndPts.Login}`} /> },
      { path: "*", element: <Navigate to={`/${EndPts.Login}`} /> }
    ]
  }
];

export default routes;
