// Base
import { FC, forwardRef, ReactElement, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import styled from "styled-components";
import config from "../../../config";

// Grommet/Kendo Components
import { Box, Image, ResponsiveContext } from "grommet";
import {
  DropDownList,
  DropDownListChangeEvent,
  DropDownListProps
} from "@progress/kendo-react-dropdowns";

// Custom Components
import CarbonMenu, { INodeGroupItem } from "../../CarbonMenu";
import UserOptionsMenu from "../../CarbonMenu/UserOptionsMenu";

// Constants/Contexts
import { useStore } from "../../../contexts/store";
import { AppLocalStore, GridActions, StoreActions } from "../../../constants";
import { useGrid } from "../../../contexts/grid/useGrid";
import { useDayGroups } from "../../../hooks/useDayGroups";

const DashboardNavbar = forwardRef((props, ref: any) => {
  const { store, dispatch } = useStore();
  const { grids, setGrid } = useGrid();
  const navigate = useNavigate();
  const location = useLocation();
  const { setNodeId } = useDayGroups();
  const [renderChangePasswordDialog, setRenderChangePasswordDialog] =
    useState(false);
  const UAT_MODE = config.uatMode === "true";

  const CarbonCompaniesList: FC<DropDownListProps> = ({ ...props }) => {
    const valueRender = (
      element: ReactElement<HTMLSpanElement>,
      value: any
    ) => {
      return (
        <Box pad={{ left: "small" }} justify="center" align="start" fill>
          {value}
        </Box>
      );
    };

    const newProps = {
      ...props,
      popupSettings: { ...props.popupSettings, height: "400px" }
    };

    return (
      <ResponsiveContext.Consumer>
        {(screenSize) => (
          <>
            <DropDownList
              style={{
                backgroundColor: "var(--carbon-white)",
                borderRadius: 5,
                width:
                  screenSize === "small" || screenSize === "xsmall"
                    ? "170px"
                    : "220px"
              }}
              valueRender={valueRender}
              {...newProps}
            />
          </>
        )}
      </ResponsiveContext.Consumer>
    );
  };

  const handleActiveCompanyChange = (event: DropDownListChangeEvent) => {
    const emptyNodeGroupItem: INodeGroupItem = {
      nodeGroupId: -1,
      nodeGroupName: ""
    };

    // jon, 4/7/22: Reset day group node on company change. Otherwise, the last node gets remembered and templates will not load if user immediately revists that node.
    setNodeId(0);

    // clear the active node group
    dispatch({
      type: StoreActions.onSelectNodeGroup,
      payload: {
        emptyNodeGroupItem
      }
    });

    const activeCompany = store.user?.companies.find(
      (company) => company.companyName === event.value
    );
    dispatch({ type: StoreActions.setActiveCompany, payload: activeCompany });
    localStorage.setItem(
      AppLocalStore.ActiveCompany,
      JSON.stringify(activeCompany)
    );

    const resourceArray = location.pathname
      .split("/")
      .filter((val) => val.length > 0);
    if (resourceArray.length > 2) {
      // jon, 4/1/22: When changing companies from a subscreen, delete all grids because going back to them can cause them to render weird since
      //   they will try to scroll to a position that might not match the new company's number of records. See 21191: Grid: Issues when changing companies
      if (grids) {
        [...grids.keys()].forEach((gridId) => {
          setGrid({
            type: GridActions.delete,
            payload: {
              gridId: gridId,
              gridData: null
            }
          });
        });
      }

      // this is a subscreen, navigate to parent screen on company change
      const navigationString = `/${resourceArray[0]}/${resourceArray[1]}`;
      navigate(navigationString);
    }
  };

  // console.log(`UAT_MODE: ${process.env.REACT_APP_UAT_MODE}`);
  return (
    <ResponsiveContext.Consumer>
      {(screenSize) => (
        <>
          <StyledAppBar
            ref={ref}
            {...props}
            style={{
              minHeight: "48px",
              backgroundColor: UAT_MODE
                ? "var(--carbon-blue)"
                : "var(--carbon-veryDarkGray)",
              justifyContent: "center"
            }}
          >
            <Box direction="row">
              {screenSize === "xsmall" || screenSize === "small" ? (
                <Box
                  focusIndicator={false}
                  onClick={() => navigate("/app/home")}
                  fill="vertical"
                  alignSelf="center"
                  margin={{ left: "medium", right: "small" }}
                  style={
                    UAT_MODE
                      ? { width: "140px" }
                      : {
                          minWidth: "42px",
                          maxWidth: "42px"
                        }
                  }
                >
                  <div style={{ padding: "6px" }}>
                    {UAT_MODE ? (
                      <h1
                        style={{
                          color: "var(--carbon-white)",
                          fontSize: "34px"
                        }}
                      >
                        UAT
                      </h1>
                    ) : (
                      <Image src="/static/images/c-carbon-icon.svg" />
                    )}
                  </div>
                </Box>
              ) : (
                <Box
                  focusIndicator={false}
                  onClick={() => navigate("/app/home")}
                  fill="vertical"
                  alignSelf="center"
                  margin={{ left: "medium", right: "small" }}
                  pad={{ vertical: "xsmall" }}
                  style={
                    UAT_MODE
                      ? {
                          width: "320px"
                        }
                      : {
                          minWidth: "144px",
                          maxWidth: "144px"
                        }
                  }
                >
                  <div style={{ padding: UAT_MODE ? "" : "6px" }}>
                    {UAT_MODE ? (
                      <span
                        style={{
                          color: "var(--carbon-white)",
                          fontSize: "30px",
                          fontWeight: 600
                        }}
                      >
                        CARBON UAT
                      </span>
                    ) : (
                      <Box>
                        <Image src="/static/images/carbon-logo.svg" />
                      </Box>
                    )}
                  </div>
                </Box>
              )}
              <Box fill style={{ minWidth: "120px" }}>
                <CarbonMenu screenSize={screenSize} />
              </Box>
              <div>
                {screenSize === "small" || screenSize === "xsmall" ? (
                  <Box
                    direction="row"
                    margin={{
                      right: "medium",
                      left: "small",
                      bottom: "xsmall"
                    }}
                    style={{ marginTop: "8px" }}
                  >
                    <CarbonCompaniesList
                      onChange={(event) => {
                        handleActiveCompanyChange(event);
                      }}
                      data={store.user?.companies.map(
                        (company) => company.companyName
                      )}
                      defaultValue={store.activeCompany?.companyName}
                    />
                    <UserOptionsMenu
                      renderChangePassword={renderChangePasswordDialog}
                      changePassword={() =>
                        setRenderChangePasswordDialog(
                          !renderChangePasswordDialog
                        )
                      }
                      screenSize={screenSize}
                    />
                  </Box>
                ) : (
                  <Box
                    direction="row"
                    margin={{ right: "medium", left: "small" }}
                    fill="vertical"
                  >
                    <Box justify="center" margin={{ right: "small" }}>
                      <CarbonCompaniesList
                        onChange={(event) => {
                          handleActiveCompanyChange(event);
                        }}
                        data={store.user?.companies.map(
                          (company) => company.companyName
                        )}
                        defaultValue={store.activeCompany?.companyName}
                      />
                    </Box>
                    <UserOptionsMenu
                      renderChangePassword={renderChangePasswordDialog}
                      changePassword={() =>
                        setRenderChangePasswordDialog(
                          !renderChangePasswordDialog
                        )
                      }
                      screenSize={screenSize}
                    />
                  </Box>
                )}
              </div>
            </Box>
          </StyledAppBar>
        </>
      )}
    </ResponsiveContext.Consumer>
  );
});

const StyledAppBar = styled(Box)`
  position: sticky;
  top: 0;
  z-index: 100;
`;

DashboardNavbar.displayName = "DashboardNavbar";

export default DashboardNavbar;
