import { Result, ResultError, NodeTemplatesExportContract } from "../../types";
import useApi from "../api/useApi";
import { Placeholders } from "../../constants";
import {
  CarbonGenericApiGet,
  CarbonGenericApiPost
} from "../../api/carbonGenericApis";
import {
  nodeExportLookupTemplatesEndpoint,
  nodeGroupsExportLookupEndpoint
} from "../../constants/endpoints/nodeExports";

const useNodeTemplatesExportApi = () => {
  const { loading: isLoadingNodeListData, request: getNodeList } =
    useApi(CarbonGenericApiGet);

  const { loading: isLoadingNodeGroupData, request: getNodeGroupList } =
    useApi(CarbonGenericApiGet);

  const { loading: isLoadingNodeTemplateExport, request: exportNodeTemplates } =
    useApi(CarbonGenericApiPost);

  const getNodeTemplatesExportData = async (
    companyId: string,
    nodeId?: string
  ): Promise<Result<string>> => {
    let url: string;
    if (nodeId) {
      url = `${nodeExportLookupTemplatesEndpoint.replace(
        Placeholders.companyID,
        `${companyId}`
      )}/${nodeId}`;
    } else {
      url = `${nodeGroupsExportLookupEndpoint.replace(
        Placeholders.companyID,
        `${companyId}`
      )}`;
    }

    let result: Result<string>;
    try {
      console.log("NodeTemplatesExport Lookup API: " + url);
      result = await getNodeList(url);
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }

    return result;
  };

  const getNodeGroupData = async (
    companyId: string,
    groupId: number,
    nodeId: string
  ): Promise<Result<string>> => {
    const url = `/odata/${companyId}/${groupId}/gridnodes?filterid=-1&$count=true&$orderby=NodeName&$filter=NodeID ne ${nodeId}`;

    let result: Result<string>;
    try {
      result = await getNodeGroupList(url);
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }

    return result;
  };

  const processNodeTemplatesExport = async (
    nodeId: string,
    companyId: string,
    payload: NodeTemplatesExportContract
  ): Promise<Result<string>> => {
    const url = `api/${companyId}/nodetemplates/nodetemplatesexport/${nodeId}`;

    let result: Result<string>;
    try {
      console.log("NodeTemplatesExport API: " + url);
      result = await exportNodeTemplates(url, payload);
      if (result.type !== "success") {
        console.log("Node Template Export Bad Request:", result.error);
      }
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }

    return result;
  };

  return {
    getNodeTemplatesExportData,
    isLoadingNodeListData,
    getNodeGroupData,
    isLoadingNodeGroupData,
    processNodeTemplatesExport,
    isLoadingNodeTemplateExport
  };
};

export default useNodeTemplatesExportApi;
