import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  {
    field: "AuditTrailID",
    title: "AuditTrailID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "UserID",
    title: "UserID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "Username",
    title: "Username",
    width: "310px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "AccessType",
    title: "Access Type",
    width: "375px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "AccessName",
    title: "Access Name",
    width: "375px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "AccessDate",
    title: "Access Date",
    width: "770px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  }
];
