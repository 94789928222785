import {
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent
} from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import React, { useState, useEffect } from "react";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Label } from "@progress/kendo-react-labels";
import styled from "styled-components";

export interface ILookupFieldProps {
  id: string;
  label: string;
  labelWidth?: string;
  fieldWidth?: string;
  lookupData: any[];
  valueFieldInLookup: string;
  textFieldInLookup: string;
  defaultValue: number | null;
  defaultText: string;
  filterByCompanyID?: number;
  disabled?: boolean;
  required?: boolean;
  onChange: (value: number | null, text: string) => void;
}

export const LookupField = (props: ILookupFieldProps) => {
  const [selectedItem, setSelectedItem] = useState<{
    value: number | null;
    text: string;
  }>({ value: props.defaultValue, text: props.defaultText });
  const [filteredListOfValues, setFilteredListOfValues] = useState<any[]>([]);
  const labelWidth = props.labelWidth ?? "100px";
  const fieldWidth = props.fieldWidth ?? "200px";
  const disabled = props.disabled ?? false;
  const required = props.required ?? false;

  const COMPANYID_FIELD: string = "CompanyID";

  // Effect allows default value to be changed by calling page so the value here can be updated.
  useEffect(() => {
    if (selectedItem?.value !== props.defaultValue) {
      const selectedText = props.lookupData.find(
        (item) => item[props.valueFieldInLookup] === props.defaultValue
      );
      if (selectedText) {
        setSelectedItem({
          value: props.defaultValue,
          text: selectedText[props.textFieldInLookup]
        });
      } else {
        setSelectedItem({
          value: null,
          text: ""
        });
      }
    }
  }, [props.defaultValue, props.lookupData]);

  // Effect runs on first load or when lookupData changes to set the initial set of user-filtered data to the lookup data list
  useEffect(() => {
    if (props.lookupData) {
      const filteredLookup = props.filterByCompanyID
        ? props.lookupData.filter(
            (item) =>
              item[COMPANYID_FIELD] === undefined ||
              parseInt(item[COMPANYID_FIELD], 10) === props.filterByCompanyID
          )
        : props.lookupData;
      setFilteredListOfValues(filteredLookup);
    }
  }, [props.lookupData]);

  const onFilterChange = (event: ComboBoxFilterChangeEvent) => {
    const data = props.lookupData.slice();
    setFilteredListOfValues(filterBy(data, event.filter));
  };

  const onChange = async (event: ComboBoxChangeEvent) => {
    let newValue: number | null = null;
    let newText: string = "";

    // Field names in lookup data may not match destination field names in table, so check for new definitions of these in the column.
    if (event.target.value !== null) {
      newValue = event.target.value[props.valueFieldInLookup];
      newText = event.target.value[props.textFieldInLookup];
    }

    // Update selected item
    setSelectedItem({
      value: newValue,
      text: newText
    });

    props.onChange(newValue, newText);
  };

  const listNoDataRender = (element: any) => {
    const noData = <strong>No values available</strong>;
    return React.cloneElement(element, { ...element.props }, noData);
  };

  // Field names in lookup data may not match destination field names in table, so check for new definitions of these in the column.
  const dataValue: { [key: string]: any } = {};
  dataValue[props.valueFieldInLookup] = selectedItem.value || null;
  dataValue[props.textFieldInLookup] = selectedItem.text || "";

  const label = required ? (
    <span style={{ fontWeight: "bold" }}>
      <span
        style={{
          color: "var(--carbon-orange)",
          display: "inline-block",
          marginLeft: "-12px"
        }}
      >
        *&nbsp;
      </span>
      <Label
        style={{ width: labelWidth, fontWeight: "bold" }}
        editorId={props.id}
      >
        {props.label}
      </Label>
    </span>
  ) : (
    <Label
      style={{ width: labelWidth, fontWeight: "bold" }}
      editorId={props.id}
    >
      {props.label}
    </Label>
  );

  const elem = (
    <StyledFieldWrapper>
      {label}
      <ComboBox
        disabled={disabled}
        style={{ width: fieldWidth }}
        tabIndex={0}
        data={filteredListOfValues}
        textField={props.textFieldInLookup}
        dataItemKey={props.valueFieldInLookup}
        filterable={true}
        onFilterChange={onFilterChange}
        suggest={true}
        value={dataValue}
        validityStyles={false}
        onChange={onChange}
        listNoDataRender={listNoDataRender}
      />
    </StyledFieldWrapper>
  );

  return elem;
};

const StyledFieldWrapper = styled(FieldWrapper)`
  margin-bottom: 8px;
  min-height: 28px;

  label {
    color: var(--carbon-darkgray);
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-right: 10px;
    display: inline-block;
  }

  span.k-combobox {
    display: inline-block;
    font-size: 16px;
    background: var(--carbon-white);
    border: 1px solid var(--carbon-lightgray);
    box-sizing: border-box;
    border-radius: 6px;
    height: 28px;

    span.k-dropdown-wrap {
      border-bottom-width: 0;
      margin-top: -4px;
    }

    input.k-input[role="combobox"] {
      margin-left: 4px;
    }
  }
`;
