import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";

// Components
import { Box } from "grommet";
import CarbonGridToolbar, {
  GridToolbarActionLabels,
  GridToolbarItem
} from "../../components/CarbonGrid/Toolbar/CarbonGridToolbar";
import CarbonGrid from "../../components/CarbonGrid/CarbonGrid";
import Authorizer from "../../components/Util/Authorizer";

// Types & Constants
import { GridColumns, GridType } from "../../types/grid";
import { gridColumns } from "./NodesGridColumns";
import {
  gridColumns as nodeDisplayColumns,
  microplayerColumns,
  detailGridColumnsPC
} from "./NodesSubgridMultiplayerDisplaysGridColumns";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import NodesFilterDialog from "../../components/Util/Dialogs/NodesFilterDialog";
import { INodeGroupItem } from "../../components/CarbonMenu";

// User/Permissions
import {
  EndpointResources,
  GridIDs,
  Placeholders,
  UserPermissions,
  CarbonIcons,
  GridActions,
  StoreActions
} from "../../constants";
import {
  GridActionBarEvent,
  GridActionBarItem
} from "../../components/CarbonGrid/Toolbar/CarbonGridRowActionBar";

// Hooks/Contexts
import usePermissions from "../../hooks/auth/usePermissions";
import { useGrid } from "../../contexts/grid/useGrid";
import useUpdateEffect from "../../hooks/utils/useUpdateEffect";
import { useStore } from "../../contexts/store";

const Nodes = (): JSX.Element => {
  const { isGranted, canInsert, canDelete, canView } = usePermissions();
  const [showFilterDialog, setShowFilterDialog] = useState(true);
  const navigate = useNavigate();
  const gridName = GridIDs.Nodes;
  const pagePermission = UserPermissions.NodesPerms;
  const nodeDisplayPermission = UserPermissions.CanManageNodeDisplays;
  const { grids, setGrid } = useGrid();
  const { store, dispatch } = useStore();

  useEffect(() => {
    setShowFilterDialog(
      grids.get(GridIDs.Nodes)?.state.showAdvancedFilterDialog || false
    );
  }, [grids.get(GridIDs.Nodes)?.state.showAdvancedFilterDialog]);

  useUpdateEffect(() => {
    setGrid({
      type: GridActions.toggleRefreshGrid,
      payload: { gridId: GridIDs.Nodes, gridData: true }
    });
  }, [store.activeNodeGroup]);

  const actionLabels: GridToolbarActionLabels = {
    singular: "node",
    plural: "nodes",
    titleSingular: "Node",
    titlePlural: "Nodes"
  };

  const actions: GridActionBarItem[] = [
    {
      show: isGranted(UserPermissions.CanManageNodeDayParts),
      label: "Dayparts",
      includeInExtrasMenu: true,
      icon: CarbonIcons.Dayparts,
      onClick: (event: GridActionBarEvent) => {
        console.log(`Dayparts onclick: `, event);
        navigate(
          `/app/nodes/${event.rowData.NodeID}/${EndpointResources.NodeDayparts}`
        );
      }
    },
    {
      show: true,
      label: "Patch History",
      includeInExtrasMenu: true,
      icon: CarbonIcons.Patches,
      onClick: (event: GridActionBarEvent) => {
        console.log(`Patch History onclick: `, event);
        navigate(
          `/app/nodes/${event.rowData.NodeID}/${EndpointResources.NodePatchHistory}`
        );
      }
    },
    {
      show: isGranted(UserPermissions.CanManageNodeMovieCodes),
      label: "Movie Codes",
      includeInExtrasMenu: true,
      icon: CarbonIcons.MovieCodes,
      onClick: (event: GridActionBarEvent) => {
        console.log(`Movie Codes onclick: `, event);
        navigate(
          `/app/nodes/${event.rowData.NodeID}/${EndpointResources.NodeMovieCodes}`
        );
      }
    },
    {
      show: isGranted(UserPermissions.CanManageNodeScheduleHistory),
      label: "History",
      includeInExtrasMenu: true,
      icon: CarbonIcons.History,
      onClick: (event: GridActionBarEvent) => {
        console.log(`History onclick: `, event);
        navigate(
          `/app/nodes/${event.rowData.NodeID}/${EndpointResources.NodeHistory}`
        );
      }
    },
    {
      show: isGranted(UserPermissions.CanManageNodeDisplays),
      label: "Displays",
      includeInExtrasMenu: false,
      icon: CarbonIcons.Displays,
      onClick: (event: GridActionBarEvent) => {
        console.log(`Displays onclick: `, event);
        navigate(
          `/app/nodes/${event.rowData.NodeID}/${EndpointResources.NodeDisplays}`
        );
      }
    },
    {
      show: isGranted(UserPermissions.CanManageNodePricing),
      label: "Text & Prices",
      includeInExtrasMenu: false,
      icon: CarbonIcons.Items,
      onClick: (event: GridActionBarEvent) => {
        console.log(`Text & Prices onclick: `, event);
        navigate(
          `/app/nodes/${event.rowData.NodeID}/${EndpointResources.NodeItems}`
        );
      }
    },
    {
      show: true,
      label: "Schedule",
      includeInExtrasMenu: false,
      icon: CarbonIcons.Schedule,
      onClick: (event: GridActionBarEvent) => {
        console.log(`Schedules onclick: `, event);
        navigate(
          `/app/nodes/${event.rowData.NodeID}/${EndpointResources.NodeSchedules}`
        );
      }
    },
    {
      show: isGranted(UserPermissions.CanManageNodeDisplays),
      label: "Video Cards",
      includeInExtrasMenu: true,
      icon: CarbonIcons.VideoCards,
      onClick: (event: GridActionBarEvent) => {
        console.log(`Video Cards onclick: `, event);
        navigate(
          `/app/nodes/${event.rowData.NodeID}/${EndpointResources.NodeVideoCards}`
        );
      }
    }
  ];

  const detailGridName = "DETAILGRID";
  const detailGridNamePC = "DETAILGRID-PC";

  const nodeSubgrid = (props: GridDetailRowProps): JSX.Element => {
    const dataItem = props.dataItem;
    const isCL1Player = dataItem.PlayerTypeID !== 1; // 1 is PC Windows, all others are Carbon-lite players
    console.log(`Subgrid for NodeID ${dataItem.NodeID}`);

    const submenuColumns: GridColumns[] = isCL1Player
      ? microplayerColumns
      : nodeDisplayColumns;

    const newNodeDisplayColumns: GridColumns[] = [
      {
        field: "NodeID",
        title: "NodeID",
        width: "150px",
        defaultShow: false,
        filter: "numeric",
        editable: false,
        required: false,
        canBeSaved: true,
        defaultValue: dataItem.NodeID
      },
      ...submenuColumns
    ];

    const newDetailGridColumnsPC: GridColumns[] = [
      {
        field: "NodeID",
        title: "NodeID",
        width: "150px",
        defaultShow: false,
        filter: "numeric",
        editable: false,
        required: false,
        canBeSaved: true,
        defaultValue: dataItem.NodeID
      },
      ...detailGridColumnsPC
    ];

    // will, 3/15/22: increased pageSize/take to 10000 so all displays would show
    const detailGridSettings: GridType = {
      endpoints: {
        gridODataEndpoint: `/odata/${Placeholders.companyID}/${dataItem.NodeID}/gridnodedisplays?$count=true&`
      },
      records: [],
      total: 0,
      data: [],
      lookups: {},
      dataState: {
        pageSize: 10000,
        take: 10000,
        skip: 0,
        sort: [{ field: "DisplayOrder", dir: "asc" }]
      },
      dataItemKey: "NodeDisplayID",
      singularEntityName: "node display",
      pluralEntityName: "node displays",
      columns: newNodeDisplayColumns,
      showCopyBtn: false,
      showDeleteBtn: false,
      isSubgrid: true,
      isReadonly: true,
      state: {
        editMode: false,
        insertMode: false,
        selectedState: {},
        selectedRow: -1,
        showQuickFilter: false,
        lastSaveDate: null,
        showSavingIndicator: false,
        lockoutMode: false
      }
    };

    const detailGridSettingsPC: GridType = {
      endpoints: {
        gridODataEndpoint: `/odata/${Placeholders.companyID}/${Placeholders.nodeGroupId}/gridnodes?filterId=${Placeholders.filterID}&$count=true&`
      },
      records: [],
      total: 0,
      data: [],
      lookups: {},
      dataState: {
        pageSize: 25,
        take: 1,
        skip: 0,
        sort: [],
        filter: {
          logic: "and",
          filters: [
            { field: "NodeID", operator: "equals", value: dataItem.NodeID }
          ]
        }
      },
      dataItemKey: "NodeID",
      singularEntityName: "node",
      pluralEntityName: "nodes",
      columns: newDetailGridColumnsPC,
      showCopyBtn: false,
      showDeleteBtn: false,
      isSubgrid: true,
      isReadonly: true,
      state: {
        editMode: false,
        insertMode: false,
        selectedState: {},
        selectedRow: -1,
        showQuickFilter: false,
        lastSaveDate: null,
        showSavingIndicator: false,
        lockoutMode: false
      }
    };

    return (
      <section>
        {isCL1Player ? (
          <CarbonGrid
            gridId={detailGridName}
            gridSettings={detailGridSettings}
          />
        ) : (
          <Box direction="row" gap="small">
            <Box>
              <span style={{ fontSize: "12px", marginLeft: "-16px" }}>
                Displays
              </span>
              <CarbonGrid
                gridId={detailGridName}
                gridSettings={detailGridSettings}
              />
            </Box>
            <Box>
              <span style={{ fontSize: "12px", marginLeft: "-16px" }}>
                Details
              </span>
              <CarbonGrid
                gridId={detailGridNamePC}
                gridSettings={detailGridSettingsPC}
              />
            </Box>
          </Box>
        )}
      </section>
    );
  };

  const gridSettings: GridType = {
    actions,
    endpoints: {
      gridODataEndpoint: `/odata/${Placeholders.companyID}/${Placeholders.nodeGroupId}/gridnodes?filterId=${Placeholders.filterID}&$count=true&`,
      gridApiEndpoint: `/api/${Placeholders.companyID}/nodes`,
      lookupEndpoints: [
        {
          lookupField: "VigilixID",
          endpoint: `/api/${Placeholders.companyID}/lookupvigilix/agents`
        },
        {
          lookupField: "NodeTypeName",
          endpoint: `/api/${Placeholders.companyID}/lookupnodetypes`
        },
        {
          lookupField: "PriceScheduleName",
          endpoint: `/api/${Placeholders.companyID}/lookuppriceschedules`
        },
        {
          lookupField: "MoviePosterFormatName",
          endpoint: `/api/${Placeholders.companyID}/lookupmovieposterformats`
        }
      ]
    },
    records: [],
    total: 0,
    data: [],
    lookups: {},
    dataState: {
      pageSize: 25,
      take: 50,
      skip: 0,
      sort: [
        { field: "CompanyName", dir: "asc" },
        { field: "NodeName", dir: "asc" }
      ],
      filter: {
        logic: "and",
        filters: [{ field: "IsActive", operator: "equals", value: true }]
      }
    },
    dataItemKey: "NodeID",
    singularEntityName: "node",
    pluralEntityName: "nodes",
    columns: gridColumns,
    showCopyBtn: canInsert(pagePermission),
    showDeleteBtn: canDelete(pagePermission),
    detailRow: canView(nodeDisplayPermission)
      ? {
          subgridId: [detailGridName, detailGridNamePC],
          content: nodeSubgrid
        }
      : undefined,
    isSuperUser: isGranted(UserPermissions.IsSuperUser),
    state: {
      editMode: false,
      insertMode: false,
      selectedState: {},
      selectedRow: -1,
      showQuickFilter: false,
      lastSaveDate: null,
      showSavingIndicator: false,
      lockoutMode: false,
      addingNewFilter: false,
      activeFilterId: -1
    }
  };

  const previewBtn: GridToolbarItem = {
    show: true,
    enable: true,
    tooltip: "Open the viewer to preview node schedule",
    toggledOn: false
  };

  const NodesHeader: FC = () => {
    const emptyNodeGroupItem: INodeGroupItem = {
      nodeGroupId: -1,
      nodeGroupName: ""
    };
    return (
      <Box
        justify="center"
        align="center"
        direction="row"
        style={{ marginTop: "-20px", paddingBottom: "1px" }}
      >
        <StyledLink
          onClick={() => {
            // clear the active node group
            dispatch({
              type: StoreActions.onSelectNodeGroup,
              payload: {
                nodeGroupItem: emptyNodeGroupItem
              }
            });
          }}
          to="/app/nodes"
        >
          All Nodes
        </StyledLink>
        {CarbonIcons.ChevronRight}
        <Box direction="row">
          <span
            style={{
              fontSize: 14,
              fontWeight: 400,
              color: "var(--carbon-orange)"
            }}
          >
            {store.activeNodeGroup?.nodeGroupName}
          </span>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Helmet>
        <title>Carbon | Nodes</title>
      </Helmet>
      <Authorizer
        canView={pagePermission}
        auditLabel={`${actionLabels.titlePlural} screen`}
      >
        {showFilterDialog && (
          <NodesFilterDialog setShowDialog={setShowFilterDialog} />
        )}
        <Box align="start" fill>
          {store.activeNodeGroup &&
            store.activeNodeGroup?.nodeGroupId !== -1 && <NodesHeader />}
          <CarbonGridToolbar
            gridId={gridName}
            screenIcon={CarbonIcons.Nodes}
            screenTitle={actionLabels.titlePlural}
            screenSubtitle={""}
            permissions={pagePermission}
            actionLabels={actionLabels}
            publishBtn={true}
            previewBtn={previewBtn}
          />
          <Box className="carbon-grid-wrapper" fill>
            <CarbonGrid
              gridId={gridName}
              gridSettings={gridSettings}
              gridClassName={"grid-with-actionbar"}
            />
          </Box>
        </Box>
      </Authorizer>
    </>
  );
};

const StyledLink = styled(Link)`
  font-size: 14px;
`;

export default Nodes;
