import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  {
    field: "NodeProcessUpdateHistoryID",
    title: "Node Process Update History ID",
    width: "250px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false
  },
  // {
  // 	field: "NodeID",
  // 	title: "NodeID",
  // 	width: "150px",
  // 	defaultShow: false,
  // 	filter: "numeric",
  // 	editable: false,
  // 	required: false,
  // 	canBeSaved: true,
  // 	defaultValue: "[grdNodes.NodeID]",
  // },
  {
    field: "StatusChangedDate",
    title: "Processed Datetime",
    width: "200px",
    defaultShow: true,
    filter: "date",
    editable: false,
    editor: "datetime",
    required: false,
    canBeSaved: true
  },
  {
    field: "NewStatus",
    title: "Status",
    width: "150px",
    defaultShow: true,
    filterable: true,
    customFilter: "status",
    resizable: true,
    editable: false,
    editor: "status",
    required: false,
    canBeSaved: false,
    defaultValue: "",
    statuses: [
      { value: "Confirmed", color: "var(--carbon-green)", text: "Confirmed" },
      { value: "Cancelled", color: "var(--carbon-yellow)", text: "Cancelled" },
      { value: "Processing", color: "var(--carbon-blue)", text: "Processing" },
      { value: "Error", color: "var(--carbon-red)", text: "Error" }
    ]
  },
  {
    field: "ErrorMessage",
    title: "Message",
    width: "300px",
    defaultShow: true,
    filter: "text",
    editable: false,
    editor: "text",
    required: false,
    canBeSaved: true
  }
];
