import { UserPermissions } from "../../constants";
import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  {
    field: "MediaMoviePosterID",
    title: "Media Movie Poster ID",
    width: "180px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: -1
  },
  {
    field: "NodeMoviePosterID",
    title: "Node Movie Poster ID",
    width: "180px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "NodeID",
    title: "Node ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "MediaName",
    title: "Media",
    width: "620px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    maxLength: 256,
    canBeSaved: false
  },
  {
    field: "NewMovieCode",
    title: "Movie Code",
    width: "510px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    maxLength: 100,
    canBeSaved: true,
    overrideColumn: "MovieCode",
    overrideFilterColumn: "OverrideFilterMovieCode",
    overridePermission: UserPermissions.CanManageNodeMovieCodes
  },
  {
    field: "MovieCode",
    title: "Default Movie Code",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "NewMovieTitle",
    title: "Movie Title",
    width: "700px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    maxLength: 256,
    canBeSaved: true,
    overrideColumn: "MovieTitle",
    overrideFilterColumn: "OverrideFilterMovieTitle",
    overridePermission: UserPermissions.CanManageNodeMovieCodes
  },
  {
    field: "MovieTitle",
    title: "Default Movie Title",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    maxLength: 256,
    canBeSaved: false
  }
];
