import apiClient from "..";
import { AxiosResponse } from "axios";

interface INodePatchResendAPIProps {
  endpoint: string;
}
const PostNodePatchResend = ({
  endpoint
}: INodePatchResendAPIProps): Promise<AxiosResponse> => {
  // console.log("Endpoint:", endpoint);
  const response = apiClient.post(endpoint);
  return response;
};

export default PostNodePatchResend;
