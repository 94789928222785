import { Placeholders } from "../../constants";
import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  {
    field: "TemplateName",
    title: "Template",
    width: "455px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    maxLength: 50,
    canBeSaved: true,
    isPartOfUniqueKey: true
  },
  {
    field: "HSize",
    title: "Width",
    width: "160px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: true,
    canBeSaved: true,
    minValue: 0
  },
  {
    field: "VSize",
    title: "Height",
    width: "180px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: true,
    canBeSaved: true,
    minValue: 0
  },
  {
    field: "TemplateDesc",
    title: "Description",
    width: "570px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 256,
    canBeSaved: true
  },
  {
    field: "Interruptive",
    title: "Interruptive",
    width: "215px",
    defaultShow: true,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true,
    defaultValue: false
  },
  {
    field: "InterruptIntervalSeconds",
    title: "Interrupt Interval",
    width: "245px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: false,
    canBeSaved: true,
    minValue: 0
  },
  {
    field: "TemplateID",
    title: "ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: -1,
    isPartOfUniqueKey: true
  },
  {
    field: "CompanyID",
    title: "CompanyID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    company: true,
    defaultValue: Placeholders.companyID,
    isPartOfUniqueKey: true,
    systemHidden: true
  },
  {
    field: "CompanyName",
    title: "Company",
    titlePlural: "Companies",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    editor: "lookup",
    required: false,
    valueField: "CompanyID",
    maxLength: 50,
    canBeSaved: false,
    companyMaster: true,
    defaultValue: Placeholders.companyName
  },
  {
    field: "TemplateTypeID",
    title: "TemplateTypeID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "TemplateTypeName",
    title: "Template Type",
    titlePlural: "Template Types",
    width: "150px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    valueField: "TemplateTypeID",
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "IsActive",
    title: "Active",
    width: "250px",
    defaultShow: false,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true,
    defaultValue: "Y"
  }
];
