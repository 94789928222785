/* eslint-disable */

import { useState } from "react";
import {
  Api,
  AppLocalStore,
  GridActions,
  ResultTypes,
  StoreActions
} from "../../constants";
import { useStore } from "../../contexts/store";
import { useGrid } from "../../contexts/grid/useGrid";
import {
  LoginContract,
  Result,
  ResultError,
  User,
  Permissions,
  Company,
  ResultSuccess,
  UserSettings,
  GridLayout,
  StartupDetails
} from "../../types";
import { authApi } from "../../api/auth";
import useApi from "../api/useApi";
import apiClient from "../../api";

/**
 * The useLogin hook handles all our user/auth login requirements
 * while exposing only what we need in our Views
 * It'll serve as our app logic service layer to condense code, imporove scalability,
 * and ease readability
 */
const useLogin = () => {
  const { dispatch } = useStore();
  const { grids, setGrid } = useGrid();
  const [loginResult, setLoginResult] = useState<Result<User>>(
    {} as Result<User>
  );

  const { loading: loginLoading, request: loginRequest } = useApi(
    authApi.userLogin
  );

  const { loading: userDetailsLoading, request: userDetailsRequest } = useApi(
    authApi.userDetailsGet
  );

  const userLoginByToken = async (
    token: string,
    refreshToken: string
  ): Promise<Result<User>> => {
    let result: Result<User>;
    try {
      // set authToken. Reducer will set token in local store (and in local storage)
      dispatch({ type: StoreActions.setToken, payload: token });
      dispatch({ type: StoreActions.setRefreshToken, payload: refreshToken });
      // token will be attached to request via axios interceptor
      // so no need to send token with each private request
      const userDetailsResult = (result = await userDetailsRequest());
      if (userDetailsResult.type === ResultTypes.Success) {
        const userDetailsResponse = userDetailsResult.value;
        const userDetailsObj = userDetailsResponse?.data;

        const userPermissions: Permissions[] = (
          userDetailsObj.ListPermissions as any[]
        ).map((permissionObject) => {
          const tempPermissions: Permissions = {
            permissionName: permissionObject.PermissionName,
            canView: permissionObject.CanView,
            canInsert: permissionObject.CanInsert,
            canUpdate: permissionObject.CanUpdate,
            canDelete: permissionObject.CanDelete,
            isGranted: permissionObject.IsGranted
          };
          return tempPermissions;
        });

        const availableCompanies: Company[] = (
          userDetailsObj.ViewCompanies as any[]
        ).map((companyObject) => {
          const tempCompany: Company = {
            companyId: companyObject.CompanyID,
            companyName: companyObject.CompanyName
          };
          return tempCompany;
        });

        const layoutMap: Map<string, GridLayout> = new Map();

        for (const gridLayoutObj of userDetailsObj.GridLayouts) {
          const gridId = gridLayoutObj.GridID;
          const layout: GridLayout = JSON.parse(gridLayoutObj.Layout);
          layout.gridUserLayoutId = gridLayoutObj.GridUserLayoutID;
          layoutMap.set(gridId, layout);
        }

        const userSettings: UserSettings = {
          layouts: layoutMap,
          previewerURL: userDetailsObj.PreviewerURL
        };

        const userDetails: User = {
          userID: userDetailsObj.UserID,
          username: userDetailsObj.Username,
          companyName: userDetailsObj.OwningCompanyName,
          companyID: userDetailsObj.OwningCompanyID,
          token: token,
          permissions: userPermissions,
          companies: availableCompanies,
          userSettings: userSettings
        };

        let activeCompanyString = localStorage.getItem(
          AppLocalStore.ActiveCompany
        );
        let activeCompany: Company = {} as Company;

        if (activeCompanyString) {
          activeCompany = JSON.parse(activeCompanyString);
        } else {
          activeCompany = {
            companyId: userDetailsObj.SelectedCompanyID,
            companyName: userDetailsObj.SelectedCompanyName
          };
        }

        Object.freeze(userDetails);
        await dispatch({
          type: StoreActions.setActiveCompany,
          payload: activeCompany
        });
        const successResult: ResultSuccess<User> = {
          type: "success",
          value: userDetails
        };

        // will, 2/10/22: added startup details to user details get so maintenance message is
        // fetched on hard reload
        const startupDetails: StartupDetails = {
          maintenanceMessage: userDetailsObj.MaintenanceMessage,
          preventLogin: userDetailsObj.PreventLogin
        };

        await dispatch({
          type: StoreActions.setStartupDetails,
          payload: startupDetails
        });

        result = successResult;
      }
      return result;
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }
  };

  const userLogin = async (
    loginContract: LoginContract
  ): Promise<Result<User>> => {
    let result: Result<User>;
    try {
      // first, get token
      const loginResult = (result = await loginRequest(loginContract));
      if (loginResult.type === ResultTypes.Success) {
        // 'value' will be the axios response. Its type is currently not being enforced by typescript
        // due to how its implemented (must look more into this) -Ali
        const loginResponseData = loginResult.value.data;
        const token: string = loginResponseData?.access_token;
        const refreshToken: string = loginResponseData?.refresh_token;
        // set authToken. Reducer will set token in local store (and in local storage)

        const loginByTokenResult: Result<User> = (result =
          await userLoginByToken(token, refreshToken));

        if (loginByTokenResult.type === "success") {
          // dispatching user in the store will rerender the routes and return the app stack
          await dispatch({
            type: StoreActions.setUser,
            payload: loginByTokenResult.value
          });
          clearAllGrids();
        }
      }
      setLoginResult(result);
      return result;
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      setLoginResult(result);
      return result;
    }
  };

  const clearAllGrids = () => {
    // On login, clear all grids so they are not rememebered across sessions
    if (grids) {
      [...grids.keys()].forEach((gridId) => {
        setGrid({
          type: GridActions.delete,
          payload: {
            gridId: gridId,
            gridData: null
          }
        });
      });
    }
  };

  const isLoading = (): boolean => {
    return userDetailsLoading || loginLoading;
  };

  return {
    userLogin,
    userLoginByToken,
    isLoading,
    loginResult
  };
};

export default useLogin;
