import { FC } from "react";
import { useNavigate } from "react-router-dom";

// Tabs
import {
  TabStrip,
  TabStripProps,
  TabStripSelectEventArguments,
  TabStripTab
} from "@progress/kendo-react-layout";

type CarbonTab = {
  resourceEndpoint: string;
  tabTitle: string;
};

interface ICarbonTabstripProps extends TabStripProps {
  carbonTabs: CarbonTab[];
  selectedTab: number;
}

const CarbonTabStrip: FC<ICarbonTabstripProps> = ({
  carbonTabs,
  selectedTab,
  ...props
}) => {
  const navigate = useNavigate();

  const handleSelect = (e: TabStripSelectEventArguments) => {
    navigate(carbonTabs[e.selected].resourceEndpoint);
  };
  return (
    <TabStrip
      selected={selectedTab}
      onSelect={handleSelect}
      {...props}
      style={{ marginTop: "-20px" }}
    >
      {carbonTabs.map((tabObj, index) => {
        return <TabStripTab title={tabObj.tabTitle} key={index} />;
      })}
    </TabStrip>
  );
};

export default CarbonTabStrip;
