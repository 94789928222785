import apiClient from "..";
import { AxiosResponse } from "axios";
import { CampaignsDashboardDetailContract } from "../../types";

const GetCampaignsDashboardData = (
  url: string,
  payload: CampaignsDashboardDetailContract
): Promise<AxiosResponse> => {
  const response = apiClient.post(url, JSON.stringify(payload));
  return response;
};

export default GetCampaignsDashboardData;
