import { GridColumns } from "../../types/grid";

export const onAssocStaticContentTypeChange = (
  value: any,
  previousValue: any
): { changedData?: { [key: string]: any }; changedColumns?: GridColumns[] } => {
  if (value === previousValue) return {};

  // Reset all associated static content fields when AssocStaticContentType changes
  const changedData = {
    AssocStaticContent1: null,
    AssocStaticContent1MediaID: null,
    AssocStaticContent1PackageID: null
  };

  return { changedData, changedColumns: [] };
};

export const onContentCategoryChange = (
  value: any,
  previousValue: any,
  rowData: { [key: string]: any },
  columns: GridColumns[]
): { changedData?: { [key: string]: any }; changedColumns?: GridColumns[] } => {
  if (value === previousValue) return {};

  // Reset all associated static content fields when content category changes
  const changedData = {
    AssocStaticContent1: null,
    AssocStaticContent1MediaID: null,
    AssocStaticContent1PackageID: null,
    AssocStaticContentType: "M"
  };

  // If content category is Static, associated content fields are read-only. Editable for Animated.
  const changedColumns = setGridColumnPropertiesByRowData(
    columns,
    value === "A"
  );
  return { changedData, changedColumns };
};

export const onPackageRowChange = (
  rowData: { [key: string]: any },
  columns: GridColumns[]
): { changedColumns?: GridColumns[] } => {
  // If content category is Static, associated content fields are read-only. Editable for Animated.
  const changedColumns = setGridColumnPropertiesByRowData(
    columns,
    rowData.ContentCategory === "A"
  );
  return { changedColumns };
};

export const setGridColumnPropertiesByRowData = (
  columns: GridColumns[],
  isAnimated: boolean
): GridColumns[] => {
  const changedColumns: GridColumns[] = [
    {
      ...columns.find((c) => c.field === "AssocStaticContent1")!,
      editable: isAnimated
    },
    {
      ...columns.find((c) => c.field === "MediaName1")!,
      editable: isAnimated
    },
    {
      ...columns.find((c) => c.field === "PackageName1")!,
      editable: isAnimated
    },
    {
      ...columns.find((c) => c.field === "MediaName2")!,
      editable: isAnimated
    },
    {
      ...columns.find((c) => c.field === "PackageName2")!,
      editable: isAnimated
    },
    {
      ...columns.find((c) => c.field === "AssocStaticContentType")!,
      editable: isAnimated
    }
  ];

  return changedColumns;
};
