// Base
import { FC, useState, useEffect } from "react";
import dayjs from "dayjs";
import styled from "styled-components";

// Grommet/Kendo Components
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Box } from "grommet";

// Components Custom
import OctopusLoader from "../OctopusLoader";
import DropDownField from "../../Fields/DropDownField";
import { DateField } from "../../Fields/DateField";

// Types/Constants
import { CarbonIcons } from "../../../constants";
import {
  LookupNodeType,
  GlobalChangesProcessUpdatesContract
} from "../../../types";

// Hooks
import useGlobalChangesApi from "../../../hooks/globalChanges/useGlobalChangesApi";

type ProcessingType = {
  label: string;
  details: JSX.Element;
};

interface IGlobalChangeDialogProps {
  nodes: LookupNodeType[];
  onAcceptCallback: () => void;
  onRejectCallback: () => void;
}

const GlobalChangePublishDialog: FC<IGlobalChangeDialogProps> = ({
  nodes,
  onAcceptCallback,
  onRejectCallback
}) => {
  const { publishUpdatesToNodes, isLoading } = useGlobalChangesApi();

  const [processingTypes, setProcessingTypes] = useState<ProcessingType[]>([]);
  const [processTypeDropdown, setProcessTypeDropdown] = useState<string>();
  const [processTypeDetail, setProcessTypeDetail] = useState<JSX.Element>();
  const [processDate, setProcessDate] = useState<Date | null>(
    dayjs(`${dayjs().format("YYYY-MM-DD")}T00:00:00`).toDate()
  );

  const ProcessNowAction: ProcessingType = {
    label: "Publish Now",
    details: (
      <label>
        The schedule content will be sent to the node(s) immediately.
      </label>
    )
  };
  const ProcessLaterAction: ProcessingType = {
    label: "Publish Later",
    details: (
      <label>
        The schedule content will be sent to the node(s) at the Publish Date and
        Time you specify above. This is the <b>LOCAL</b> time of the node(s)
        which uses each player machine&apos;s system time zone setting to
        determine the actual time to publish updates.
      </label>
    )
  };

  useEffect(() => {
    setProcessingTypes([ProcessNowAction, ProcessLaterAction]);
  }, []);

  useEffect(() => {
    if (processingTypes.length > 0) {
      setProcessTypeDropdown(processingTypes[0].label);
      setProcessTypeDetail(processingTypes[0].details);
    }
  }, [processingTypes]);

  const handleProcessingTypeChange = (event: DropDownListChangeEvent) => {
    console.log(`Type Change:`, event.value);

    setProcessTypeDropdown(event.value);
    setProcessTypeDetail(
      processingTypes.find((type) => type.label === event.value)!.details
    );
  };

  const onClickApplyButton = async () => {
    console.log("Selected Date:", processDate);

    // All actions require NodeProcessUpdateID
    // Default date to NOW
    const payload: GlobalChangesProcessUpdatesContract = {
      Nodes: nodes.map((node) => node.NodeID),
      ProcessNow: processTypeDropdown === "Publish Now",
      ProcessDate: `#${dayjs().format("MM/DD/YYYY")} 00:00:00#`,
      ProcessTime: "#1/1/1900 12:00:00 AM#"
    };

    // Process Now - Requires: IsNode, NodeGroup (if applicable)
    if (processTypeDropdown === "Publish Now") {
      console.log("Global Change Publish Now", payload);
      publishUpdates(payload);
    }

    // Process Later - Requires: IsNode, NodeGroup (if applicable), ProcessDate, ProcessTIme
    if (processTypeDropdown === "Publish Later") {
      payload.ProcessDate = `#${dayjs(processDate).format(
        "MM/DD/YYYY"
      )} 00:00:00#`;
      payload.ProcessTime = `#1/1/1900 ${dayjs(processDate).format(
        "HH:mm:ss"
      )}#`;

      console.log("Global Change Publish Later", payload);
      publishUpdates(payload);
    }
  };

  const publishUpdates = async (
    payload: GlobalChangesProcessUpdatesContract
  ) => {
    const result = await publishUpdatesToNodes(payload);
    if (result.type === "success") {
      onAcceptCallback();
    }
  };

  return (
    <Dialog
      title={
        <h1>
          {CarbonIcons.Publish}
          <br />
          {"Publish Updates"}
        </h1>
      }
      closeIcon={false}
      width={"40%"}
    >
      <Box style={{ position: "relative" }}>
        {isLoading() && <OctopusLoader />}
        <Box gap="small" border="bottom" margin={{ bottom: "10px" }}>
          <Box>
            <p>
              Select Publish Now or Publish Later to send the current schedule
              content to the node(s).
            </p>
          </Box>
          <Label
            style={{ fontSize: "16px", fontWeight: "bold", opacity: "0.75" }}
          >
            NODES
          </Label>
          <Box>
            <div
              style={{
                maxHeight: "400px",
                overflowY: "scroll",
                marginBottom: "20px"
              }}
            >
              {nodes?.map((node, i) => {
                return (
                  <div
                    style={{
                      paddingBottom: "4px"
                    }}
                    key={i}
                  >
                    {node.NodeName}
                  </div>
                );
              })}
            </div>
          </Box>
          <Label
            style={{ fontSize: "16px", fontWeight: "bold", opacity: "0.75" }}
          >
            PUBLISHING TYPE
          </Label>
          {processingTypes.length > 0 && (
            <Box direction="row" gap="medium">
              <DropDownField
                onChange={(event) => {
                  handleProcessingTypeChange(event);
                }}
                data={processingTypes.map((type) => type.label)}
                value={processTypeDropdown}
                defaultValue={processingTypes[0].label}
              />
              {processTypeDropdown === "Publish Later" && (
                <StyledFieldWrapper>
                  <DateField
                    id="ProcessDateTime"
                    label="Publish Date/Time"
                    labelWidth="auto"
                    fieldWidth="auto"
                    defaultValue={processDate}
                    datetime={true}
                    onChange={(newValue: Date | null) =>
                      setProcessDate(newValue)
                    }
                  />
                </StyledFieldWrapper>
              )}
            </Box>
          )}
          <Box>
            <p>{processTypeDetail}</p>
          </Box>
        </Box>
        <Box margin={{ top: "10px" }}>
          <DialogActionsBar>
            <Button onClick={onRejectCallback}>
              {CarbonIcons.Close}
              {"Cancel"}
            </Button>
            <Button
              autoFocus={true}
              primary={true}
              onClick={onClickApplyButton}
              disabled={false}
            >
              {CarbonIcons.Check}
              {"Apply"}
            </Button>
          </DialogActionsBar>
        </Box>
      </Box>
    </Dialog>
  );
};

const StyledFieldWrapper = styled(FieldWrapper)`
  label {
    margin-top: 3px;
  }
`;

export default GlobalChangePublishDialog;
