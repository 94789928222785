import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { FC } from "react";
import { Box } from "grommet";
import { CarbonIcons } from "../../../constants";

interface IConfirmationDialogProps {
  title?: string;
  bodyText?: string;
  acceptText?: string;
  rejectText?: string;
  onAcceptCallback: () => void;
  onRejectCallback: () => void;
}

const ConfirmationDialog: FC<IConfirmationDialogProps> = ({
  title,
  bodyText,
  acceptText,
  rejectText,
  onAcceptCallback,
  onRejectCallback
}) => {
  const CustomTitleBar = () => {
    return (
      <h1>
        {CarbonIcons.WarningDialog}
        <br />
        {title || "Please Confirm"}
      </h1>
    );
  };

  return (
    <Dialog title={<CustomTitleBar />} closeIcon={false}>
      <Box>
        <p>{bodyText || "Are you sure you want to continue?"}</p>
      </Box>
      <DialogActionsBar>
        <Button onClick={onRejectCallback}>
          {CarbonIcons.Close}
          {rejectText || "Cancel"}
        </Button>
        <Button autoFocus={true} primary={true} onClick={onAcceptCallback}>
          {CarbonIcons.Check}
          {acceptText || "Yes, proceed"}
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
};

export default ConfirmationDialog;
