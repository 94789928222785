import React, { createContext, useContext, useReducer } from "react";
import { Company, NotificationType, User, StartupDetails } from "../../types";
import { AppLocalStore, StoreActions } from "../../constants";
import { uuid } from "../../helpers/Util";
import { INodeGroupItem } from "../../components/CarbonMenu";

type State = {
  notifications: NotificationType[];
  user: User | null;
  token: string | null;
  refreshToken: string | null;
  activeCompany: Company | null;
  startupDetails: StartupDetails | null;
  activeNodeGroup: INodeGroupItem | null;
  addingNodeGroup: boolean;
  showNodeGroupDialog: boolean;
  editingNodeGroup: boolean;
  showPreviewerPopup: boolean;
  embeddedPreviewerURL: string;
  previewerWindow: Window | null;
  previewDate: Date;
};

type Action = {
  type: StoreActions;
  // this should change to something that can better scale
  // i.e. payload: User | NotificationType[] | string;
  payload: any;
};

type StoreContext = {
  store: State;
  dispatch: React.Dispatch<Action>;
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case StoreActions.addNotification:
      action.payload.id = uuid();

      // eslint-disable-next-line no-case-declarations
      const closableNotifications = state.notifications.filter(
        (item: NotificationType) => item.closable
      );

      // will, 2/3/22: limit the amount of closable notifications to 5
      if (closableNotifications.length >= 5 && action.payload.closable) {
        return {
          ...state,
          notifications: [...state.notifications, action.payload].filter(
            (item: NotificationType) => item.id !== closableNotifications[0].id
          )
        };
      }

      return {
        ...state,
        notifications: [...state.notifications, action.payload]
      };
    case StoreActions.removeNotification:
      // eslint-disable-next-line no-case-declarations
      const update = state.notifications.filter(
        (item: NotificationType) => item.id !== action.payload.id
      );
      return update.length
        ? { ...state, notifications: update }
        : { ...state, notifications: [] };
    case StoreActions.removeAllNotifications:
      return {
        ...state,
        notifications: []
      };
    case StoreActions.setMultiple:
      return { ...state, ...action.payload };
    case StoreActions.setUser:
      // jon, 4/1/22: remove active node group when setting user to null (i.e., logging out) since company will be reset to All Companies and node
      //   groups follow the company.
      if (action.payload === null) {
        return {
          ...state,
          user: null,
          activeNodeGroup: { nodeGroupId: -1, nodeGroupName: "" }
        };
      }

      return { ...state, user: action.payload };
    case StoreActions.setToken:
      // prep for 'remember me' feature
      localStorage.setItem(AppLocalStore.Token, action.payload);
      return { ...state, token: action.payload };
    case StoreActions.setRefreshToken:
      localStorage.setItem(AppLocalStore.RefreshToken, action.payload);
      return { ...state, refreshToken: action.payload };
    case StoreActions.setActiveCompany:
      localStorage.setItem(
        AppLocalStore.ActiveCompany,
        JSON.stringify(action.payload)
      );
      return { ...state, activeCompany: action.payload };
    case StoreActions.setStartupDetails:
      return { ...state, startupDetails: action.payload };
    case StoreActions.onSaveNodeGroup: {
      const nodeGroupItem: INodeGroupItem = action.payload.nodeGroupItem;
      return { ...state, activeNodeGroup: nodeGroupItem };
    }
    case StoreActions.onAddNewNodeGroup: {
      return {
        ...state,
        addingNodeGroup: true,
        editingNodeGroup: false,
        showNodeGroupDialog: true
      };
    }
    case StoreActions.onEditNodeGroup: {
      return {
        ...state,
        showNodeGroupDialog: true,
        editingNodeGroup: true,
        addingNodeGroup: false
      };
    }
    case StoreActions.onDeleteNodeGroup: {
      const nodeGroupItem: INodeGroupItem = action.payload.nodeGroupItem;
      return {
        ...state,
        showNodeGroupDialog: false,
        editingNodeGroup: false,
        addingNodeGroup: false,
        activeNodeGroup: nodeGroupItem
      };
    }
    case StoreActions.onCloseNodeGroupDialog: {
      return {
        ...state,
        showNodeGroupDialog: false,
        editingNodeGroup: false,
        addingNodeGroup: false
      };
    }
    case StoreActions.onSelectNodeGroup: {
      const nodeGroupItem: INodeGroupItem = action.payload.nodeGroupItem;
      console.log("selected node group", nodeGroupItem);
      return { ...state, activeNodeGroup: nodeGroupItem };
    }
    case StoreActions.showPreviewerPopup: {
      return { ...state, showPreviewerPopup: action.payload.value };
    }
    case StoreActions.setEmbeddedPreviewerURL: {
      return { ...state, embeddedPreviewerURL: action.payload.value };
    }
    case StoreActions.setPreviewerWindow: {
      return { ...state, previewerWindow: action.payload.value };
    }
    case StoreActions.setPreviewDate: {
      return { ...state, previewDate: action.payload.value };
    }
    default:
      throw new Error(`Unhandled action type: ${(action as any).toString()}`);
  }
}

const initialState: State = {
  notifications: [],
  user: null,
  token: null,
  refreshToken: null,
  activeCompany: null,
  startupDetails: null,
  addingNodeGroup: false,
  editingNodeGroup: false,
  showNodeGroupDialog: false,
  activeNodeGroup: { nodeGroupId: -1, nodeGroupName: "" } as INodeGroupItem,
  showPreviewerPopup: false,
  embeddedPreviewerURL: "",
  previewerWindow: null,
  previewDate: new Date()
};

const Store = createContext<StoreContext>({
  store: initialState,
  dispatch: () => null
});

// eslint-disable-next-line react/prop-types
const StoreProvider: React.FC = ({ children }): JSX.Element => {
  const [store, dispatch] = useReducer(reducer, initialState);

  return (
    <Store.Provider value={{ store, dispatch }}>{children}</Store.Provider>
  );
};

export default StoreProvider;
export const useStore = () => useContext(Store);
