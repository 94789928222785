import React, {
  useState,
  MouseEvent,
  useEffect,
  useLayoutEffect,
  useRef
} from "react";
import {
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent
} from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

// Constants/Types
import {
  CarbonIcons,
  GridActions,
  GridIDs,
  StoreActions
} from "../../../constants";
import { GridValidationMessagePosType } from "../../../types/grid";
import { CellRenderProps } from "./CellRender";

// Contexts/Utilities
import { useGrid } from "../../../contexts/grid/useGrid";
import { unlockGridIfLockedOut } from "../Utility/GridUtility";
import { useStore } from "../../../contexts/store";

type UnMountSaveData = {
  previousDataItem: { [key: string]: any };
  dataItem: { [key: string]: any };
  selectedItem: {
    value: number | null;
    text: string;
  };
  isThisRowBeingEdited: boolean;
  saving: boolean;
};

export const LookupRender = ({
  originalProps,
  td,
  gridId,
  colDefinition,
  exitEdit
}: CellRenderProps) => {
  const { grids, setGrid } = useGrid();
  const { store, dispatch } = useStore();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [previousDataItem, setPreviousDataItem] = useState<{
    [key: string]: any;
  }>({});
  const [lookupData, setLookupData] = useState<any[]>([]);
  const [filteredListOfValues, setFilteredListOfValues] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState<{
    value: number | null;
    text: string;
  }>({ value: null, text: "" });
  const [doSave, setDoSave] = useState<boolean>(false);
  const [onFocusComplete, setOnFocusComplete] = useState<boolean>(false);
  const [validationMsg, setValidationMsg] = useState<string | null>(null);
  const [validationPos, setValidationPos] =
    useState<GridValidationMessagePosType | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const mounted = useRef(false);
  const saveData = useRef<UnMountSaveData>();

  let inputRef: HTMLInputElement | null;
  let comboRef: HTMLSpanElement | null;

  const COMPANYID_FIELD: string = "CompanyID";
  const COMPANYNAME_FIELD: string = "CompanyName";
  const MEDIANAME_FIELD: string = "MediaName";

  const isThisRowBeingEdited =
    grids.get(gridId)!.state.editMode &&
    !(grids.get(gridId)!.state.selectedRowIsExpanded ?? false) &&
    originalProps.dataItem[grids.get(gridId)!.dataItemKey] ===
      grids.get(gridId)!.state.selectedRow;

  // jon, 4/3/22: Save data needed for possible save on unmount since this must use a ref because useEffect below only knows values as they were on mount.
  useEffect(() => {
    saveData.current = {
      previousDataItem: previousDataItem,
      dataItem: originalProps.dataItem,
      selectedItem: selectedItem,
      isThisRowBeingEdited: isThisRowBeingEdited,
      saving: saving
    };
  }, [
    previousDataItem,
    originalProps.dataItem,
    selectedItem,
    isThisRowBeingEdited,
    saving
  ]);

  // jon, 4/3/22: Added effect to monitor if component is mounted. Save can now be initiated by scrolling off the grid, so need to check for mounted and
  //   initiate a save if value has changed.
  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;

      if (
        saveData.current &&
        saveData.current.isThisRowBeingEdited &&
        !saveData.current.saving &&
        saveData.current.previousDataItem[originalProps.field!] !== undefined &&
        saveData.current.selectedItem.value !==
          saveData.current.previousDataItem[originalProps.field!]
      ) {
        console.log(
          `Saving changes to lookup field due to control unmounting...`
        );
        setSaving(true);
        doSaveOnExitEdit(
          saveData.current.previousDataItem,
          saveData.current.dataItem
        );
      }
    };
  }, []);

  // Effect runs on first load to set the initial set of user-filtered data to the lookup data list
  useEffect(() => {
    if (isThisRowBeingEdited) {
      if (lookupData) {
        setFilteredListOfValues(lookupData);
      }
    }
  }, []);

  // Effect runs after validation msg changes. This gives us an opportunity to set the validation message position correctly relative to input.
  useEffect(() => {
    if (validationMsg !== null && comboRef !== null) {
      setValidationPos({
        posTop: comboRef.offsetTop + 36, // Future: Consider compact mode
        posLeft: comboRef.offsetLeft
      });
    }
  }, [validationMsg]);

  // Layout Effect runs after validation position changes. This gives us an opportunity to set focus in the input after all renders.
  useLayoutEffect(() => {
    if (validationPos !== null && inputRef !== null) {
      // set focus in input where validation error occurred
      inputRef.focus();
    }
  }, [validationPos]);

  // Effect runs on first load and when field value changes to set the selected value/text
  useEffect(() => {
    if (isThisRowBeingEdited) {
      if (
        originalProps.dataItem[colDefinition!.valueField!] &&
        originalProps.dataItem[originalProps.field!] &&
        originalProps.dataItem[colDefinition!.valueField!] !==
          selectedItem.value
      ) {
        setSelectedItem({
          value: originalProps.dataItem[colDefinition!.valueField!],
          text: originalProps.dataItem[originalProps.field!]
        });
      } else if (originalProps.dataItem[colDefinition!.valueField!] === null) {
        setSelectedItem({
          value: null,
          text: ""
        });
      }
    }
  }, [
    originalProps.dataItem[colDefinition!.valueField!],
    originalProps.dataItem[originalProps.field!]
  ]);

  // Effect runs when the lookup data for this field loads/changes so the list can be updated.
  // FUTURE: Change this effect to use the CompanyMaster field instead of relying on name of field = CompanyID/CompanyName
  useEffect(() => {
    if (isThisRowBeingEdited) {
      const fieldLookupData = grids.get(gridId)!.lookups[originalProps.field!];
      if (fieldLookupData) {
        const companyID = getRecordCompanyID();

        // Filter lookup by company if grid has company master and this isn't the company field itself
        // jon, 3/22/22: Shared media (from company 0 - Deel) was being filtered out even though the API was returning the correct data. Always show shared media
        //    if this lookup is a MediaName field.
        let filteredLookup =
          companyID !== -1 && originalProps.field! !== COMPANYNAME_FIELD
            ? fieldLookupData.filter(
                (item) =>
                  item[COMPANYID_FIELD] === undefined ||
                  parseInt(item[COMPANYID_FIELD], 10) === companyID ||
                  (originalProps.field! === MEDIANAME_FIELD &&
                    parseInt(item[COMPANYID_FIELD], 10) === 0)
              )
            : fieldLookupData;

        // Filter lookup by type if a lookupFilterCol is specified on the column
        filteredLookup =
          colDefinition?.lookupFilterCol &&
          colDefinition?.lookupFilterDataName &&
          originalProps.dataItem[colDefinition?.lookupFilterCol] &&
          originalProps.dataItem[colDefinition?.lookupFilterCol] !== null
            ? filteredLookup.filter(
                (item) =>
                  item[colDefinition!.lookupFilterDataName!] ===
                  originalProps.dataItem[colDefinition!.lookupFilterCol!]
              )
            : filteredLookup;

        // Filter lookup by company if specifically is the Movie Codes grid -> Company Field
        // will, 2/4/22: Added another filter specifically for Movie Codes since it has the ability to insert for any company. Want to
        // only display the active company (or all companies if that is the currently selected active company option)
        filteredLookup =
          store.activeCompany?.companyId !== -1 &&
          gridId === GridIDs.MediaLibraryCodes &&
          colDefinition!.field === "CompanyName"
            ? filteredLookup.filter(
                (item) => item.CompanyID === store.activeCompany?.companyId
              )
            : filteredLookup;

        setLookupData(filteredLookup);
        setFilteredListOfValues(filteredLookup);
      }
    }
  }, [
    grids.get(gridId)!.lookups[originalProps.field!],
    isThisRowBeingEdited,
    originalProps.dataItem[colDefinition?.lookupFilterCol ?? ""],
    originalProps.dataItem[COMPANYID_FIELD]
  ]);

  // Effect runs when a save is required to allow for the selected item to get set on change when an on blur immediately follows - like
  //  when user changes the field to blank and then tabs off.  Both onChange and onBlur get called immediately and the selected item isn't
  //  set yet.
  useEffect(() => {
    if (doSave === true) {
      // jon, 4/11/22: Set saving flag so unmount does not also fire update if input is blurred and then unmounted immediately. Can cause Unique Key violation when none exists.
      setSaving(true);
      doSaveOnExitEdit(previousDataItem, originalProps.dataItem);
    }
  }, [doSave]);

  const getRecordCompanyID = (): number => {
    // jon, 1/31/22: Instead of checking for !companyID, compare to undefined instead since a companyID of 0 causes !companyID to be true, so lookups
    //   were not getting filtered for the Deel company.
    // If grid row has a CompanyID column, use it to filter the lookup values.
    let companyID = originalProps.dataItem[COMPANYID_FIELD];

    // If record does not have a company id column, check if it has a parent grid and that grid has a company id col
    if (companyID === undefined) {
      if (grids.get(gridId)!.state.parentSelectedRowData) {
        companyID =
          grids.get(gridId)!.state.parentSelectedRowData![COMPANYID_FIELD];

        // Finally, if we still don't have a company ID, check the parent grids parent row data since subgrids can go 3 deep in this system.
        if (companyID === undefined && grids.get(gridId)!.parentGridId) {
          const parentGrid = grids.get(grids.get(gridId)!.parentGridId!);
          if (parentGrid && parentGrid!.state.parentSelectedRowData) {
            companyID =
              parentGrid!.state.parentSelectedRowData![COMPANYID_FIELD];
          }
        }
      }
    }

    // if (companyID !== undefined) {
    //   console.log(
    //     `Lookup Company is ${
    //       originalProps.dataItem[COMPANYNAME_FIELD] ?? ""
    //     } (${companyID})`
    //   );
    // } else {
    //   console.log(`Lookup company is null!`);
    // }

    const retCompanyId = companyID !== undefined ? parseInt(companyID, 10) : -1;
    return retCompanyId;
  };

  const doSaveOnExitEdit = async (
    prevDataItem: { [key: string]: any },
    curDataItem: { [key: string]: any }
  ) => {
    if (validationMsg !== null) {
      // If this cell is/was invalid, unlock grid. If cell is still invalid, the exitEdit will re-lock the grid after save attempt.
      unlockGridIfLockedOut(gridId, grids, setGrid);
    }
    setValidationPos(null);
    setValidationMsg(null);

    const result = await exitEdit(
      "ON BLUR",
      originalProps.field!,
      prevDataItem,
      curDataItem,
      [colDefinition!.valueField!]
    );

    if (result !== null) {
      // will, 2/16/22: Added mount check for mobile events causing component to unmount before validation
      if (mounted.current) {
        // Validation on this field failed so set the message which fires the effects above to re-focus the input
        setValidationMsg(result);
        enterEdit(
          "ON BLUR VALIDATION FAILED",
          previousDataItem,
          originalProps.field!
        );
      } else {
        handleUnmountedUpdate(result);
      }
    } else {
      setPreviousDataItem(curDataItem);
    }

    if (mounted.current) {
      setDoSave(false);
      setSaving(false);
    }
  };

  // will, 2/16/22: Reformatted handleItemChange() without any setState functions to be usable when component is unmounted
  // and to set failure message
  const handleUnmountedUpdate = (failureMessage: string) => {
    // This sets the new value and text in the grid data
    const newDataItem = { ...saveData.current?.dataItem };
    newDataItem[originalProps.field!] =
      saveData.current?.previousDataItem[originalProps.field!];
    newDataItem[colDefinition!.valueField!] =
      saveData.current?.previousDataItem[colDefinition!.valueField!];

    // For combined columns, need to set that field's value as well for display
    if (colDefinition!.combinedCol) {
      newDataItem[colDefinition!.combinedCol] =
        saveData.current?.previousDataItem[originalProps.field!];
    }

    const grid = grids.get(gridId)!;

    if (grid.state.insertMode) {
      setGrid({
        type: GridActions.onInsertItemChange,
        payload: { gridId, gridData: newDataItem }
      });
    } else {
      const editedRecordID = saveData.current?.dataItem[grid.dataItemKey!];
      const records = grid.records.map((record) => {
        let newRecord = record;
        if (record[grid.dataItemKey!] === editedRecordID) {
          newRecord = newDataItem;
        }
        return newRecord;
      });

      setGrid({
        type: GridActions.onItemChange,
        payload: { gridId, gridData: records }
      });
    }

    setGrid({
      type: GridActions.toggleGridLockoutMode,
      payload: { gridId, gridData: false }
    });

    dispatch({
      type: StoreActions.addNotification,
      payload: {
        message: "Save failed with the following message:\n" + failureMessage,
        messageType: "warning",
        closable: true
      }
    });
  };

  // This lifecycle event sets default values for controlled lookups on grid insert mode
  useLayoutEffect(() => {
    const currentGridState = grids.get(gridId)!.state;
    if (currentGridState.insertMode === true && inputRef !== null) {
      /**
       * KENDO REACT COMPONENT NOTES:
       * Although the ComboBox component holds a default value field, this field/prop are only utilized for autonomous mode,
       * Once we set the Value prop for the ComboBox (or really most Kendo React components, this component enters Controlled Mode)
       */

      // set the default value for the company lookup field if the selected company is not All Companies
      // jon, 1/14/22: Changed grid state's editFieldName to originalProps.field since editFieldName represents only the field currently being edited,
      //    not the lookup field we are rendering here. This was causing ALL lookups in the grid to get set initially to the company name on insert.
      if (
        originalProps.field! === COMPANYNAME_FIELD &&
        currentGridState.activeCompany?.companyId !== -1
      ) {
        setSelectedItem({
          value: currentGridState.activeCompany?.companyId || -1,
          text: currentGridState.activeCompany?.companyName || ""
        });
      }
    }
  }, [originalProps.field, grids.get(gridId)!.state.insertMode]);

  // Layout Effect runs when the grid's current edit field changes so we can see if we should set the focus if this is that field in insert mode
  useLayoutEffect(() => {
    if (
      grids.get(gridId)!.state.editFieldName === originalProps.field! &&
      grids.get(gridId)!.state.insertMode === true &&
      inputRef !== null
    ) {
      inputRef.focus();
    }
  }, [grids.get(gridId)!.state.editFieldName]);

  const enterEdit = (
    type: string,
    dataItem: { [key: string]: any },
    field: string | undefined
  ): void => {
    // console.log(
    //   `LOOKUP CELL ENTER EDIT ${colDefinition?.field} - ${type}: `,
    //   dataItem,
    //   field
    // );
    setPreviousDataItem(dataItem);
    setGrid({
      type: GridActions.editFieldName,
      payload: { gridId, gridData: field }
    });

    // When this element becomes focused, we need to set a custom class on the parent k-combobox to show the orange bottom border since we can't do
    //  this in CSS because we can't affect parent elements.
    // NOTE: Add this class back in if needed when I figure out how to get the orange bottom border working on this element since theme overrides it.
    // if (inputRef) {
    //   const parentCombobox = inputRef.closest(".k-combobox");
    //   if (parentCombobox) {
    //     parentCombobox.className += " k-combobox-focused";
    //   }
    // }
  };

  const additionalProps = {
    ref: (tdElem: HTMLTableCellElement) => {
      inputRef = tdElem && tdElem.querySelector("input");
      inputRef?.addEventListener("keydown", (e) => {
        if ((e.key === "ArrowDown" || e.key === "ArrowUp") && e.ctrlKey) {
          // console.log("%c from lookuprender", "color: red; font-weight: bold;");
          e.stopImmediatePropagation();
          const target: Element = e.target as Element;
          const columnIndex = target
            .closest("[data-grid-col-index]")
            ?.getAttribute("data-grid-col-index");
          // trigger blur to initiate save
          (e.target as HTMLElement).blur();
          // jon, 3/23/22: I added a timeout here to allow for the save and then the navigation event since sometimes the nav would stop working afterwards.
          window.setTimeout(() => {
            setGrid({
              type: GridActions.onKeyNavigation,
              payload: {
                gridId,
                gridData: { key: e.key, colIndex: columnIndex }
              }
            });
          }, 1);
        }
      });
      comboRef = tdElem && tdElem.querySelector("span.k-combobox");
    },
    onBlur: async () => {
      // Only save the underlying value field, not the display text field
      //  Note: Lookups that return multiple values are handled in events in their calling page.
      setDoSave(true);
    },
    onFocus: () => {
      // Do not fire focus event if validation message showing since this resets the "previous" value to the new, incorrect one.
      if (validationMsg === null) {
        enterEdit("ON FOCUS", originalProps.dataItem, originalProps.field!);
      }
    },
    onKeyDown: (e: KeyboardEvent) => {
      // If user hits ESC, undo any changes made to this cell (including the lookup value field) and do not save
      if (e.key === "Escape") {
        setValidationPos(null);
        setValidationMsg(null);
        unlockGridIfLockedOut(gridId, grids, setGrid);
        handleItemChange(
          previousDataItem[colDefinition!.valueField!],
          previousDataItem[originalProps.field!],
          true
        );
      }
    },
    onMouseEnter: (e: MouseEvent<HTMLTableCellElement>) => {
      // Show tooltip only when cell is too small to show all contents
      const target = e.target as HTMLTableCellElement;
      setShowTooltip(target.offsetWidth < target.scrollWidth);
    },
    title: showTooltip ? originalProps.dataItem[originalProps.field!] : ""
  };

  const handleItemChange = (
    newValue: number | null,
    newText: string,
    resetPreviousDataItem: boolean
  ) => {
    unlockGridIfLockedOut(gridId, grids, setGrid);
    // This set the selected item for this combobox only
    setSelectedItem({
      value: newValue,
      text: newText
    });

    // This sets the new value and text in the grid data
    const newDataItem = { ...originalProps.dataItem };
    newDataItem[originalProps.field!] = newText;
    newDataItem[colDefinition!.valueField!] = newValue;

    // For combined columns, need to set that field's value as well for display
    if (colDefinition!.combinedCol) {
      newDataItem[colDefinition!.combinedCol] = newText;
    }

    // This reset is used when user clicks ESC
    if (resetPreviousDataItem) {
      setPreviousDataItem(newDataItem);
    }

    const grid = grids.get(gridId)!;

    if (grid.state.insertMode) {
      setGrid({
        type: GridActions.onInsertItemChange,
        payload: { gridId, gridData: newDataItem }
      });
    } else {
      const editedRecordID = originalProps.dataItem[grid.dataItemKey!];
      const records = grid.records.map((record) => {
        let newRecord = record;
        if (record[grid.dataItemKey!] === editedRecordID) {
          newRecord = newDataItem;
        }
        return newRecord;
      });

      setGrid({
        type: GridActions.onItemChange,
        payload: { gridId, gridData: records }
      });

      // Handle clear case where user clicks X - save immediately so we can check for required field - also, onblur never gets called
      if (newValue === null) {
        setDoSave(true);
      }
    }
  };

  const onFilterChange = (event: ComboBoxFilterChangeEvent) => {
    const data = lookupData.slice();
    setFilteredListOfValues(filterBy(data, event.filter));
  };

  const onChange = async (event: ComboBoxChangeEvent) => {
    let newValue: number | null = null;
    let newText: string = "";

    // For this combobox, the onchange acts as an onfocus since the clear and dropdown events fire here and focus does not, so it is our only
    //   opportunity to set the previous data item.
    // jon, 2/18/22: Make sure the ON FOCUS event that sets the previous data item only runs once on the initial change. Otherwise, it causes
    //   a problem when changing to a value and immediately changing it back to the old value. The system detects a change to save because on
    //   the second change, it would have updated the previous data item even though a database save had not happened yet. This was causing
    //   a unique key violation on the Node Displays screen (Player ID field) even when there should have been one.
    if (validationMsg === null && !onFocusComplete) {
      setOnFocusComplete(true);
      enterEdit("ON FOCUS", originalProps.dataItem, originalProps.field!);
    }

    // Field names in lookup data may not match destination field names in table, so check for new definitions of these in the column.
    if (event.target.value !== null) {
      newValue =
        event.target.value[
          colDefinition!.valueFieldInLookup ?? colDefinition!.valueField!
        ];
      newText =
        event.target.value[
          colDefinition!.fieldNameInLookup ?? originalProps.field!
        ];
    }

    // Update selected item and grid values but keep previous value for now so it can be reset with ESC (last parameter).
    handleItemChange(newValue, newText, false);
  };

  const listNoDataRender = (element: any) => {
    const noData = <strong>No values available</strong>;
    return React.cloneElement(element, { ...element.props }, noData);
  };

  // We don't show the lookup if the field is not editable UNLESS we are in insert mode and this is the company master field.
  let showLookup: boolean = isThisRowBeingEdited;
  const isEditable =
    colDefinition?.editable === true ||
    (colDefinition?.editableInInsertOnly === true &&
      grids.get(gridId)!.state.insertMode === true);

  if (
    isEditable === false &&
    !(grids.get(gridId)!.state.insertMode && colDefinition?.companyMaster)
  ) {
    showLookup = false;
  }

  if (!showLookup) {
    const { className, ...tdprops } = td.props;
    const finalProps = {
      ...tdprops,
      className: className + " carbon-gridcell-text"
    };
    return React.cloneElement(
      td,
      { ...finalProps, ...additionalProps },
      <>{td.props.children}</>
    );
  }

  // Field names in lookup data may not match destination field names in table, so check for new definitions of these in the column.
  const dataValue: { [key: string]: any } = {};
  const textFieldNameInLookup =
    colDefinition!.fieldNameInLookup ?? originalProps.field!;
  const valueFieldNameInLookup =
    colDefinition!.valueFieldInLookup ?? colDefinition!.valueField!;

  dataValue[valueFieldNameInLookup] = selectedItem.value || null;
  dataValue[textFieldNameInLookup] = selectedItem.text || "";

  const elem = (
    <ComboBox
      tabIndex={0}
      data={filteredListOfValues}
      textField={textFieldNameInLookup}
      dataItemKey={valueFieldNameInLookup}
      filterable={true}
      onFilterChange={onFilterChange}
      suggest={true}
      value={dataValue}
      validityStyles={false}
      onChange={onChange}
      listNoDataRender={listNoDataRender}
    />
  );

  const validationWrapper =
    validationPos !== null && validationMsg !== null ? (
      <>
        <div
          className="validation-msg"
          style={{
            top: `${validationPos!.posTop}px`,
            left: `${validationPos!.posLeft}px`
          }}
        >
          {CarbonIcons.Warning}
          {validationMsg}
        </div>
        {elem}
      </>
    ) : (
      <>{elem}</>
    );

  const { className, ...tdprops } = td.props;

  const finalProps = {
    ...tdprops,
    className:
      validationMsg !== null ? className + " validation-error-cell" : className
  };

  return React.cloneElement(
    td,
    { ...finalProps, ...additionalProps },
    validationWrapper
  );
};
