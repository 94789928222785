import { Helmet } from "react-helmet-async";
import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import { Box } from "grommet";

// Components
import CarbonTabStrip from "../../components/CarbonTabs/CarbonTabStrip";
import CarbonGrid from "../../components/CarbonGrid/CarbonGrid";
import Authorizer from "../../components/Util/Authorizer";
import CarbonBreadcrumb from "../../components/Breadcrumb/CarbonBreadcrumb";

// Types & Constants
import { GridType } from "../../types/grid";
import getTabs from "./StencilsTabs";
import { gridColumns } from "./StencilZonesGridColumns";

// User/Permissions
import usePermissions from "../../hooks/auth/usePermissions";
import {
  EndpointResources,
  Placeholders,
  UserPermissions,
  GridIDs,
  CarbonIcons,
  GridActions
} from "../../constants";

// Action bar
import CarbonGridToolbar, {
  GridToolbarActionLabels,
  GridToolbarItem
} from "../../components/CarbonGrid/Toolbar/CarbonGridToolbar";
import { onStencilZoneRowChange } from "./StencilZonesCustomEvents";
import {
  GridActionBarEvent,
  GridActionBarItem
} from "../../components/CarbonGrid/Toolbar/CarbonGridRowActionBar";

// Contexts
import { useGrid } from "../../contexts/grid/useGrid";
import { useStore } from "../../contexts/store";

const StencilZones = (): JSX.Element => {
  const { pathname } = useLocation();
  const { stencilId } = useParams();
  const { store } = useStore();
  const { canDelete } = usePermissions();
  const { grids, setGrid } = useGrid();
  const gridName = GridIDs.StencilZones;
  const tabs = getTabs(EndpointResources.Stencils, stencilId!);
  const pagePermission = UserPermissions.StencilsPerms;

  let newGridColumns = [
    {
      field: "StencilID",
      title: "StencilID",
      width: "150px",
      defaultShow: false,
      filter: "numeric",
      editable: false,
      required: false,
      canBeSaved: true,
      defaultValue: stencilId,
      systemHidden: true
    },
    ...gridColumns
  ];

  // jon, 1/17/22: Set default values for HSize and VSize columns to be the size of the stencil itself
  // useeffect that alters the grid columns based on selected parent row values
  // will, 2/2/22: Added dependency for user layout update so effect is run on restoreLayout so the
  // values re-update after defaults are restored
  useEffect(() => {
    if (
      grids.get(gridName)?.state.parentSelectedRowData &&
      grids.get(gridName)!.state.parentSelectedRowData!.HSize &&
      grids.get(gridName)!.state.parentSelectedRowData!.VSize &&
      grids.get(gridName)!.columns
    ) {
      // will, 2/2/22: updated to map from current columns so adjustments are preserved
      // Current column values
      newGridColumns = grids.get(gridName)!.columns!.map((column: any) => {
        const tempColumn = { ...column };
        if (tempColumn.field === "HSize") {
          tempColumn.defaultValue =
            grids.get(gridName)!.state.parentSelectedRowData!.HSize;
        } else if (tempColumn.field === "VSize") {
          tempColumn.defaultValue =
            grids.get(gridName)!.state.parentSelectedRowData!.VSize;
        }
        return tempColumn;
      });

      setGrid({
        type: GridActions.updateColumnState,
        payload: {
          gridId: gridName,
          gridData: { columns: newGridColumns }
        }
      });
    }
  }, [
    grids.get(gridName)?.state.parentSelectedRowData,
    store.user?.userSettings.layouts.get(gridName)
  ]);

  const currentTab = {
    index: tabs.findIndex((tab) => tab.resourceEndpoint === pathname),
    tab: tabs.find((tab) => tab.resourceEndpoint === pathname)
  };

  const handleCopyZoneDown = (event: GridActionBarEvent) => {
    const customValues = {
      VOffset: event.rowData.VSize + event.rowData.VOffset,
      ZoneName: `${event.rowData.ZoneName} - copy`,
      ZoneDesc: `${event.rowData.ZoneDesc || ""} - copy`,
      StencilZoneID: -1
    };

    // merge two objects. Key values from customValues will be copied over key values of event.rowData forming a new object (first param of .assign())
    const customRowValues: { [key: string]: any } = Object.assign(
      {},
      event.rowData,
      customValues
    );

    setGrid({
      type: GridActions.toggleInsertMode,
      payload: {
        gridId: GridIDs.StencilZones,
        gridData: {
          insertOn: true,
          data: null,
          customDefaultValues: customRowValues
        }
      }
    });
  };

  const handleCopyZoneRight = (event: GridActionBarEvent) => {
    const customValues = {
      HOffset: event.rowData.HSize + event.rowData.HOffset,
      ZoneName: `${event.rowData.ZoneName} - copy`,
      ZoneDesc: `${event.rowData.ZoneDesc || ""} - copy`,
      StencilZoneID: -1
    };

    // merge two objects. Key values from customValues will be copied over key values of event.rowData forming a new object (first param of .assign())
    const customRowValues: { [key: string]: any } = Object.assign(
      {},
      event.rowData,
      customValues
    );

    setGrid({
      type: GridActions.toggleInsertMode,
      payload: {
        gridId: GridIDs.StencilZones,
        gridData: {
          insertOn: true,
          data: null,
          customDefaultValues: customRowValues
        }
      }
    });
  };

  const previewBtn: GridToolbarItem = {
    show: true,
    enable: true,
    tooltip: "Open the viewer to preview selected stencil",
    toggledOn: false
  };

  const actions: GridActionBarItem[] = [
    {
      show: true,
      label: "Copy Zone Down",
      includeInExtrasMenu: true,
      icon: CarbonIcons.CopyDown,
      onClick: handleCopyZoneDown
    },
    {
      show: true,
      label: "Copy Zone Right",
      includeInExtrasMenu: true,
      icon: CarbonIcons.CopyRight,
      onClick: handleCopyZoneRight
    }
  ];

  const actionLabels: GridToolbarActionLabels = {
    singular: "stencil zone",
    plural: "stencil zones",
    titleSingular: "Stencil Zone",
    titlePlural: "Stencil Zones"
  };

  const gridSettings: GridType = {
    actions,
    endpoints: {
      gridODataEndpoint: `/odata/${Placeholders.companyID}/${Placeholders.stencilsTabStencilId}/gridstencilzones?$count=true&`,
      gridApiEndpoint: `/api/${Placeholders.stencilsTabStencilId}/stencilzones`,
      parentGridApiEndpoint: `/api/${Placeholders.companyID}/${EndpointResources.Stencils}/${Placeholders.stencilsTabStencilId}`,
      lookupEndpoints: [
        {
          lookupField: "TextStyleName",
          endpoint: `/api/${Placeholders.companyID}/lookuptextstyles`
        },
        {
          lookupField: "FilterMediaTypeName",
          endpoint: `/api/${Placeholders.companyID}/lookupmediatypes`
        },
        {
          lookupField: "FilterItemTypeName",
          endpoint: `/api/${Placeholders.companyID}/lookupitemtypes`
        },
        {
          lookupField: "PreviewContentMediaName",
          endpoint: `/api/${Placeholders.companyID}/lookupmedia/static`
        },
        {
          lookupField: "PreviewContentItemName",
          endpoint: `/api/${Placeholders.companyID}/lookupitems`
        }
      ]
    },
    records: [],
    total: 0,
    data: [],
    lookups: {},
    dataState: {
      pageSize: 25,
      take: 50,
      skip: 0,
      sort: [{ field: "ZoneName", dir: "asc" }]
    },
    dataItemKey: "StencilZoneID",
    singularEntityName: actionLabels.singular,
    pluralEntityName: actionLabels.plural,
    columns: newGridColumns,
    showCopyBtn: false,
    showDeleteBtn: canDelete(pagePermission), // will, 7/5/22: changed form canUpdate to canDelete to align with permission properties
    onRowChange: onStencilZoneRowChange,
    parentGridId: GridIDs.Stencils,
    state: {
      editMode: false,
      insertMode: false,
      selectedState: {},
      selectedRow: -1,
      showQuickFilter: false,
      lastSaveDate: null,
      showSavingIndicator: false,
      lockoutMode: false
    }
  };

  return (
    <>
      <Helmet>
        <title>Carbon | Stencil Zones</title>
      </Helmet>
      <Authorizer
        canView={pagePermission}
        auditLabel={`${actionLabels.plural} screen`}
      >
        <Box align="start" fill>
          <CarbonBreadcrumb
            backLinkTitle="Stencils"
            backLinkUrl="/app/stencils"
            pageTitle={currentTab.tab?.tabTitle ?? ""}
          />
          <CarbonGridToolbar
            gridId={gridName}
            screenIcon={CarbonIcons.StencilZones}
            getScreenTitleFromParentRow={(rowData: { [key: string]: any }) => {
              return rowData.StencilName;
            }}
            getScreenSubtitleFromParentRow={(rowData: {
              [key: string]: any;
            }) => {
              return rowData.StencilDesc;
            }}
            permissions={pagePermission}
            actionLabels={actionLabels}
            previewBtn={previewBtn}
          />

          <CarbonTabStrip carbonTabs={tabs} selectedTab={currentTab.index} />
          <Box fill className="carbon-grid-wrapper">
            <CarbonGrid gridId={gridName} gridSettings={gridSettings} />
          </Box>
        </Box>
      </Authorizer>
    </>
  );
};

export default StencilZones;
