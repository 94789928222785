import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  {
    field: "NodeID",
    title: "ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "ItemName",
    title: "Item",
    width: "130px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "Calories",
    title: "Item Calories",
    width: "150px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: true
  },
  {
    field: "Dynamic1",
    title: "Item Text 1",
    width: "200px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "Dynamic2",
    title: "Item Text 2",
    width: "200px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "ItemLabel",
    title: "Item Label",
    width: "120px",
    defaultShow: true,
    filter: "text",
    editable: true,
    required: true,
    canBeSaved: true
  },
  {
    field: "Price",
    title: "Item Price",
    width: "150px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: true
  },
  {
    field: "NewCalories",
    title: "New Calories",
    width: "150px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: true
  },
  {
    field: "NewDynamic1",
    title: "New Text 1",
    width: "250px",
    defaultShow: true,
    filter: "text",
    editable: true,
    required: true,
    canBeSaved: false
  },
  {
    field: "NewDynamic2",
    title: "New Text 2",
    width: "250px",
    defaultShow: true,
    filter: "text",
    editable: true,
    required: true,
    canBeSaved: false
  },
  {
    field: "NewPrice",
    title: "New Price",
    width: "250px",
    maxLength: 256,
    defaultShow: true,
    filter: "text",
    editable: true,
    required: false,
    canBeSaved: false
  }
];
