/*eslint-disable*/
// Base
import { Helmet } from "react-helmet-async";
import React, { useState, useEffect } from "react";
import styled from "styled-components";

// dayjs and plugins
import dayjs from "dayjs";
import minMax from "dayjs/plugin/minMax";
import isBetween from "dayjs/plugin/isBetween";

// Components - Kendo/Grommet
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridDetailRowProps,
  GridExpandChangeEvent
} from "@progress/kendo-react-grid";
import {
  Button,
  Toolbar,
  ToolbarItem,
  ToolbarSeparator
} from "@progress/kendo-react-buttons";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Label } from "@progress/kendo-react-labels";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Loader } from "@progress/kendo-react-indicators";
import { Box, Grid } from "grommet";

// Components - Custom
import Authorizer from "../../components/Util/Authorizer";
import DropDownField from "../../components/Fields/DropDownField";
import StringArrayGridDialog from "../../components/Util/Dialogs/StringArrayGridDialog";
import OctopusLoader from "../../components/Util/OctopusLoader";
import { DateField } from "../../components/Fields/DateField";
import { TextField } from "../../components/Fields/TextField";
import GridRowActionBar, {
  GridActionBarEvent
} from "../../components/CarbonGrid/Toolbar/CarbonGridRowActionBar";

// Constants
import {
  CarbonIcons,
  FileUploadTypes,
  StoreActions,
  UserPermissions,
  Api
} from "../../constants";
import { CampaignsDashboardDetailContract } from "../../types";

// Hooks
import { useStore } from "../../contexts/store";
import useCampaginsDashboardApi from "../../hooks/campaignsDashboard/useCampaginsDashboardApi";
import useFileDownload from "../../hooks/utils/useFileDownload";
import usePermissions from "../../hooks/auth/usePermissions";
import { useNavigate } from "react-router";
import { GlobalChangeType } from "../GlobalChanges/GlobalChangesToolbar";
import { useCrossTabState } from "../../hooks/utils/useCrossTabState";

interface IGridData {
  CampaignID: number;
  CampaignName: string;
  CampaignDesc: string;
  DayPart: string;
  CampaignTypeID: number;
  CampaignTypeName: string;
  IsActive: boolean;
  CompanyID: number;
  CompanyName: string;
  expanded?: boolean;
}

interface IDashboardCampaignDetail {
  EntityID: number;
  EntityName: string;
  AssociatedImageID: number;
  AssociatedImageName: string;
  IsMedia: boolean;
  IsVideo: boolean;
  IsMediaOnPackageOnly: boolean;
  NodeList: string[];
  StartDateList: string[];
  EndDateList: string[];
  NodeCount: number;
  EntityHSize: number;
  EntityVSize: number;
  AssociatedImageHSize: number;
  AssociatedImageVSize: number;
  ThumbnailImage: string;
}

const CampaignsDashboard = (): JSX.Element => {
  // dayjs plugins require being set manually
  dayjs.extend(minMax);
  dayjs.extend(isBetween);

  const sortByContent = [
    "Campaign Name",
    "Campaign Type",
    "Day Part",
    "Media/Package Name",
    "Start Date",
    "End Date"
  ];
  const allTypesFiller = "All Types";
  const dateFormat = "#YYYY-MM-DD#";
  const currentDate = dayjs();
  const startDateDefault = currentDate.subtract(7, "days");
  const endDateDefault = currentDate.add(1, "month");

  const { store, dispatch } = useStore();
  const { downloading, downloadFile } = useFileDownload(
    FileUploadTypes.CampaignsDashboard
  );
  const { isGranted } = usePermissions();
  const { getCampaignsDashboardGridData, isLoading } =
    useCampaginsDashboardApi();
  const navigate = useNavigate();

  // const [companyId, setCompanyId] = useState<number>(
  //   store.activeCompany?.companyId ?? -1
  // );
  const [callApi, setCallApi] = useState(true);
  const [gridBaseData, setGridBaseData] = useState<IGridData[]>([]);
  const [gridData, setGridData] = useState<IGridData[]>([]);
  const [gridDataCount, setGridDataCount] = useState(0);
  const [typeMenuContent, setTypeMenuContent] = useState([allTypesFiller]);
  const [typeValue, setTypeValue] = useState(allTypesFiller);
  const [sortByValue, setSortByValue] = useState(sortByContent[0]);
  const [mediaNodesList, setMediaNodesList] = useState<string[]>([]);
  const [selectedMedia, setSelectedMedia] = useState<string>();
  const [viewMediaNodes, setViewMediaNodes] = useState(false);
  const [sortAsc, setSortAsc] = useState(true);
  const [previewerUrl, setPreviewerUrl] = useCrossTabState("previewerUrl", "");

  // API payload fields
  const [campaignTypeID, setCampaignTypeID] = useState<number | undefined>();
  const [startDate, setStartDate] = useState<string | undefined>(
    startDateDefault.format(dateFormat)
  );
  const [endDate, setEndDate] = useState<string | undefined>(
    endDateDefault.format(dateFormat)
  );
  const [filterNodeName, setFilterNodeName] = useState<string>("");
  const [orderByName, setOrderByName] = useState<number>(0);
  const [orderByDir, setOrderByDir] = useState<number>(0);

  // -- EFFECTS --
  // Check for active company changed to something different than it was previously
  // Note: This does not currently work well when returning from global changes - filters and grid state are lost. I think the only way to
  //  fix it would be to either open global changes in a new tab (like the old system does - probably the best solution) or to save all the
  //  settings on this screen in global state like the grids do - but that is a major change.
  // useEffect(() => {
  //   if (
  //     store.activeCompany?.companyId &&
  //     store.activeCompany!.companyId !== companyId
  //   ) {
  //     setCompanyId(store.activeCompany!.companyId);

  //     if (store.activeCompany!.companyId === -1) {
  //       setGridData([]);
  //       setGridBaseData([]);
  //       setGridDataCount(0);
  //     } else {
  //       getGridData(true);
  //     }
  //   }
  // }, [store.activeCompany?.companyId]);

  // runs API for grid data by way of state change to ensure all states are
  // set prior to call
  useEffect(() => {
    if (store.activeCompany?.companyId) {
      if (store.activeCompany.companyId !== -1 && callApi === true) {
        getGridData(true); // false
        setCallApi(false);
      }
    }
  }, [callApi, store.activeCompany?.companyId]);

  // -- PRIVATE FUNCTIONS --
  // gets grid data from API and updates table contents
  const getGridData = (companyChangeLoad: boolean) => {
    const ApiContract: CampaignsDashboardDetailContract = {
      CampaignTypeID: campaignTypeID,
      StartDate: startDate === undefined ? "" : startDate,
      EndDate: endDate === undefined ? "" : endDate,
      FilterNodeName: filterNodeName === "" ? undefined : filterNodeName,
      OrderByName: orderByName,
      OrderByDir: orderByDir
    };

    console.log("Campaigns Dashboard Payload: ", ApiContract);

    getCampaignsDashboardGridData(ApiContract).then((res) => {
      if (res.type === "success") {
        const response: any = res.value;
        const data: any = response.data;

        setGridData(data);
        setGridDataCount(data.length);

        if (companyChangeLoad) {
          setGridBaseData(data);

          // populate the dropdown list
          const list: string[] = data.map((item: IGridData) => {
            return item.CampaignTypeName;
          });

          const filteredList: string[] = [...new Set(list)].filter(
            (elem) => elem !== ""
          );

          setTypeMenuContent([allTypesFiller, ...filteredList]);
        }
      }
    });
  };

  // Triggers useEffect() for calling the Api manually to ensure all data
  // has been set
  const deploy = () => {
    setCallApi(!callApi);
  };

  // Determines the type display for the detail grid
  const getDetailTypeDisplay = (
    isVideo: boolean,
    isMedia: boolean
  ): JSX.Element => {
    let icon: JSX.Element;
    let tag: string;

    // Rules:
    // If IsVideo, show Video
    // If IsMedia, show Image
    // Otherwise, show Package
    if (isVideo) {
      icon = CarbonIcons.Video;
      tag = "Video";
    } else if (isMedia) {
      icon = CarbonIcons.Image;
      tag = "Image";
    } else {
      icon = CarbonIcons.Packages;
      tag = "Package";
    }

    return (
      <span className="detail-icon">
        <Box direction="row" align="center" gap="xsmall">
          {icon}
          {tag}
        </Box>
      </span>
    );
  };

  // Formats the nodes display for the detail grid
  const getDetailNodesDisplay = (
    nodeCount: number,
    nodeList: string[],
    name: string
  ): JSX.Element => {
    return (
      <Box direction="row" align="center" gap="xxsmall">
        {`${nodeCount} | `}
        <Button
          className="carbon-link-btn"
          onClick={() =>
            handleViewMediaNodes({
              nodeList: nodeList,
              dialogTitleMedia: name
            })
          }
        >
          View
        </Button>
      </Box>
    );
  };

  // Determines the date display of the detail grid
  const getDetailDateDisplay = (dateList: string[]): string => {
    // Rules:
    // If there are no dates in the list, return an empty string
    // If there is exactly one date in the list, return that date
    // If there are multiple dates in the list, return MULTI
    if (dateList.length === 0) return "";
    else if (dateList.length === 1)
      return dayjs(dateList[0]).format("MMM D, YYYY");
    else return "Multiple Days";
  };

  // Determines the status of the detail grid
  const getDetailStatusDisplay = (
    nodeCount: number,
    startDateList: string[],
    endDateList: string[]
  ): JSX.Element => {
    const startDateDates = startDateList.map((date) => {
      return dayjs(date);
    });
    const endDateDates = endDateList.map((date) => {
      return dayjs(date);
    });

    const minStartDate = dayjs.min(startDateDates);
    const maxEndDate = dayjs.max(endDateDates);

    // Rules:
    // If this content does not appear on any nodes, color it dark gray to indicate we have no start/end dates
    // If the current date falls within the start (min if multiple and end dates (max if multiple), show as green for current
    // If current date is earlier than start date, show as blue for future
    // If current date is laster than end date, show as red for past
    let color: string;
    let text: string;
    if (nodeCount === 0) {
      color = "var(--carbon-mediumgray)";
      text = "Unused";
    } else if (currentDate.isBefore(minStartDate)) {
      color = "var(--carbon-blue)";
      text = "Future";
    } else if (currentDate.isAfter(maxEndDate)) {
      color = "var(--carbon-red)";
      text = "Expired";
    } else {
      color = "var(--carbon-green)";
      text = "Current";
    }

    return (
      <span className="status" style={{ backgroundColor: color }}>
        {text}
      </span>
    );
  };

  // -- EVENT HANDLERS --
  const handleResetFiltersButtonClick = () => {
    setTypeValue(allTypesFiller);
    setCampaignTypeID(undefined);
    setStartDate(startDateDefault.format(dateFormat));
    setEndDate(endDateDefault.format(dateFormat));
    setFilterNodeName("");
    setSortByValue(sortByContent[0]);
    setOrderByName(0);
    setSortAsc(true);
    setOrderByDir(0);

    deploy();
  };

  const handleExportTableButtonClick = async () => {
    const ApiContract: CampaignsDashboardDetailContract = {
      CampaignTypeID: campaignTypeID,
      StartDate: startDate === undefined ? "" : startDate,
      EndDate: endDate === undefined ? "" : endDate,
      FilterNodeName: filterNodeName === "" ? undefined : filterNodeName,
      OrderByName: orderByName,
      OrderByDir: orderByDir
    };

    await downloadFile(
      store.activeCompany!.companyId,
      -1,
      "post",
      JSON.stringify(ApiContract)
    );
  };

  const handleTypeDropDownChange = (event: DropDownListChangeEvent) => {
    const eventGridRow = gridBaseData.find(
      (item: IGridData) => item.CampaignTypeName === event.value
    );

    setTypeValue(event.value);
    setCampaignTypeID(eventGridRow?.CampaignTypeID);

    deploy();
  };

  const handleDirectionSortButtonClick = (button: string) => {
    if (button === "Asc" && !sortAsc) {
      setOrderByDir(!sortAsc ? 0 : 1);
      setSortAsc(!sortAsc);
      deploy();
    }

    if (button === "Desc" && sortAsc) {
      setOrderByDir(!sortAsc ? 0 : 1);
      setSortAsc(!sortAsc);
      deploy();
    }
  };

  const handleViewMediaNodes = ({ nodeList, dialogTitleMedia }: any) => {
    setMediaNodesList(nodeList);
    setSelectedMedia(dialogTitleMedia);
    setViewMediaNodes(true);
  };

  const handleViewMediaNodesClose = () => {
    setViewMediaNodes(false);
    setMediaNodesList([]);
    setSelectedMedia("");
  };

  const handleExpandAllButtonClick = () => {
    const newData = gridData.map((item: IGridData) => {
      item.expanded = true;
      return item;
    });

    setGridData(newData);
  };

  const handleCollapseAllButtonClick = () => {
    const newData = gridData.map((item: IGridData) => {
      item.expanded = false;
      return item;
    });

    setGridData(newData);
  };

  const handleExpandChange = (event: GridExpandChangeEvent) => {
    const newData = gridData.map((item: IGridData) => {
      if (item.CampaignID === event.dataItem.CampaignID) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });

    setGridData(newData);
  };

  // -- CUSTOM PAGE COMPONENTS --
  // Contains: Page Title, "Export" button, and "Reset Filters" button
  const TitleRow = (
    <Grid
      columns={{ count: 2, size: "auto" }}
      gap="small"
      style={{ marginBottom: "10px" }}
    >
      <Box align="start">
        <Box align="baseline" direction="row" gap="small">
          <h1>{CarbonIcons.Campaigns} Campaigns</h1>
        </Box>
      </Box>
      <Box direction="row-reverse" align="end" gap="small">
        <span title="Reset all filters and reload the page">
          <Button
            className="carbon-remove-button"
            onClick={handleResetFiltersButtonClick}
          >
            {CarbonIcons.Close}
            Reset Filters
          </Button>
        </span>
        <span
          title="Export the campaign results to Excel"
          onClick={handleExportTableButtonClick}
        >
          <Button>
            {CarbonIcons.Download}
            Export
          </Button>
        </span>
        {downloading && (
          <span className="saving-indicator">
            Downloading file
            <Loader size="medium" type="pulsing" />
          </span>
        )}
      </Box>
    </Grid>
  );

  // Contains: Type Dropdown, Date Range Filter, Node Filter, and Sort Dropdown,
  // and Sort Direction switch
  const TableFilters = (
    // will, 2/16/22: Replaced Grid component with styled box so flex direction could be updated based on viewport
    <StyledFilterBox fill="horizontal">
      <Box
        direction="row"
        align="start"
        gap="small"
        style={{ marginLeft: "-10px" }}
      >
        <DropDownField
          label=""
          labelWidth="0"
          onChange={(event) => {
            handleTypeDropDownChange(event);
          }}
          data={typeMenuContent}
          value={typeValue}
          defaultValue={typeMenuContent[0]}
        />
        <StyledFieldWrapper>
          <Box direction="row" gap="small" align="center">
            <DateField
              key="startDate"
              id="startDate"
              label=""
              labelWidth="0"
              onChange={(value: Date | null) => {
                setStartDate(
                  value === null ? undefined : dayjs(value).format(dateFormat)
                );
                deploy();
              }}
              defaultValue={
                startDate === undefined
                  ? null
                  : dayjs(startDate.replaceAll("#", "")).toDate() //Will 1/7/22: '#' causes issues in Firefox/Safari dayjs
              }
              placeholder="(All Past Dates)"
              fieldWidth={"160px"}
            />
            <Label
              style={{
                fontWeight: "bold",
                marginRight: "-8px",
                marginTop: "-7px"
              }}
            >
              to
            </Label>
            <DateField
              key="endDate"
              id="endDate"
              label=""
              labelWidth="0"
              onChange={(value: Date | null) => {
                setEndDate(
                  value === null ? undefined : dayjs(value).format(dateFormat)
                );
                deploy();
              }}
              defaultValue={
                endDate === undefined
                  ? null
                  : dayjs(endDate.replaceAll("#", "")).toDate() //Will 1/7/22: '#' causes issues in Firefox/Safari dayjs
              }
              placeholder="(All Future Dates)"
              fieldWidth={"160px"}
            />
          </Box>
        </StyledFieldWrapper>
        <Button
          className="carbon-link-btn"
          style={{ marginTop: "-3px" }}
          onClick={() => {
            setStartDate(undefined);
            setEndDate(undefined);
            deploy();
          }}
        >
          All Days
        </Button>
      </Box>
      <Box direction="row" align="center" gap="small">
        <StyledFindNodeInput>
          <TextField
            key="findNode"
            id="findNode"
            label=""
            labelWidth="0"
            defaultValue={filterNodeName}
            placeholder="Find Node"
            onChange={(value: string) => {
              setFilterNodeName(value);
            }}
          />
          <span onClick={deploy}>{CarbonIcons.Find}</span>
        </StyledFindNodeInput>
        <StyledSortToolbar
          style={{
            padding: 0,
            height: 28
          }}
        >
          <ToolbarItem>
            <DropDownField
              label=""
              labelWidth="0"
              onChange={(event: DropDownListChangeEvent) => {
                setSortByValue(event.value);
                setOrderByName(sortByContent.indexOf(event.value));
                deploy();
              }}
              data={sortByContent}
              value={sortByValue}
              defaultValue={sortByContent[0]}
            />
          </ToolbarItem>
          <ToolbarSeparator />
          <ToolbarItem>
            <Button
              className={
                sortAsc
                  ? "carbon-toolbar-item-btn selected"
                  : "carbon-toolbar-item-btn"
              }
              onClick={() => handleDirectionSortButtonClick("Asc")}
              title="Sort campaigns ascending"
            >
              {CarbonIcons.SortAsc}
            </Button>
          </ToolbarItem>
          <ToolbarItem>
            <Button
              className={
                !sortAsc
                  ? "carbon-toolbar-item-btn selected"
                  : "carbon-toolbar-item-btn"
              }
              onClick={() => handleDirectionSortButtonClick("Desc")}
              title="Sort campaigns descending"
              style={{ marginRight: "4px" }}
            >
              {CarbonIcons.SortDesc}
            </Button>
          </ToolbarItem>
        </StyledSortToolbar>
      </Box>
    </StyledFilterBox>
  );

  // Contains: 'Expand All' button, 'Collapse All' button, table row count
  const TableExtras = (
    <Grid columns={{ count: 2, size: "auto" }} gap="small">
      <Box direction="row" align="start" gap="small">
        <Button
          className="carbon-link-btn"
          onClick={handleExpandAllButtonClick}
          style={{ fontSize: "12px" }}
        >
          Expand All
        </Button>
        <Button
          className="carbon-link-btn"
          onClick={handleCollapseAllButtonClick}
          style={{ fontSize: "12px" }}
        >
          Collapse All
        </Button>
      </Box>
      <Box direction="row-reverse" align="end" gap="small">
        <span className="record-count"> {`${gridDataCount} Campaigns`} </span>
      </Box>
    </Grid>
  );

  const gotoGlobalChange = (
    entityID: number,
    entityName: string,
    isMedia: boolean,
    isMediaOnPackageOnly: boolean
  ) => {
    // If this is a media file and the media only shows on a package, that cannot be filtered directly on the global changes screen, so show message instead of link.
    if (isMedia && isMediaOnPackageOnly) {
      dispatch({
        type: StoreActions.addNotification,
        payload: {
          message: `Global changes cannot be made because this media file (${entityName}) exists on a package only.`,
          messageType: "warning",
          closable: true
        }
      });
    } else {
      const changeType = isMedia
        ? GlobalChangeType.BY_MEDIA
        : GlobalChangeType.BY_PACKAGE;
      navigate(
        `/app/global-changes?type=${changeType}&id=${entityID}&name=${encodeURIComponent(
          entityName
        )}`
      );
    }
  };

  // NOTE: this will cause the previewer to refresh every time you select a new thumbnail. Future update possibly required to only run the window.open if the window is not currently open.
  const openPreviewer = (id: number, previewType: string) => {
    const newWindow = window.open(
      `/app/previewer`,
      "previewerWindow",
      "width: 500,height: 500"
    );
    window.addEventListener("beforeunload", () => {
      newWindow?.close();
    });

    // since this page does not work if you have -All Companies- selected, useing the stored company works for both
    setPreviewerUrl(
      `${Api.baseUrl}/api/${store.activeCompany?.companyId}/preview?type=${previewType}&companyid=${store.activeCompany?.companyId}&id=${id}`
    );
  };

  const getImageHTML = (
    thumbnailImage: string,
    entityID: number,
    entityName: string,
    isPackage: boolean,
    entityHSize: number,
    entityVSize: number
  ) => {
    console.log(
      `Image HTML entityID=${entityID},  entityName=${entityName},  isPackage=${isPackage}, entityHSize=${entityHSize},  entityVSize=${entityVSize}`
    );

    return (
      <>
        {thumbnailImage === "" ? (
          <StyledPlaceholderDiv
            title={isPackage ? "Preview Package" : "Preview image"}
            onClick={() => openPreviewer(entityID, "media")}
          >
            {isPackage ? CarbonIcons.Packages : CarbonIcons.ImageNotSupported}
          </StyledPlaceholderDiv>
        ) : (
          <StyledThumbnailDiv
            title={isPackage ? "Preview Package" : "Preview image"}
            onClick={() => openPreviewer(entityID, "media")}
          >
            <img src={thumbnailImage} />
          </StyledThumbnailDiv>
        )}
      </>
    );
  };

  const getVideoHTML = (
    thumbnailImage: string,
    associatedImageID: number,
    associatedImageName: string,
    entityID: number,
    entityName: string,
    entityHSize: number,
    entityVSize: number
  ) => {
    const mediaID = associatedImageID === -1 ? entityID : associatedImageID;
    const mediaName =
      associatedImageID === -1 ? entityName : associatedImageName;

    console.log(
      "Assocaited Image Data:",
      associatedImageID,
      associatedImageName
    );
    console.log(
      `Video HTML entityID=${mediaID},  entityName=${mediaName},  entityHSize=${entityHSize},  entityVSize=${entityVSize}`
    );

    return (
      <>
        {thumbnailImage === "" ? (
          <StyledPlaceholderDiv
            title="Preview video"
            onClick={() => openPreviewer(mediaID, "media")}
          >
            {CarbonIcons.Video}
          </StyledPlaceholderDiv>
        ) : (
          <StyledThumbnailDiv
            title="Preview video"
            onClick={() => openPreviewer(mediaID, "media")}
          >
            <img src={thumbnailImage} />
          </StyledThumbnailDiv>
        )}
      </>
    );
  };

  const getPackageHTML = (
    entityID: number,
    entityHSize: number,
    entityVSize: number
  ) => {
    console.log(
      `Package HTML entityID=${entityID},  entityHSize=${entityHSize},  entityVSize=${entityVSize}`
    );
    return (
      <>
        <StyledPlaceholderDiv
          title="Preview package"
          onClick={() => openPreviewer(entityID, "package")}
        >
          {CarbonIcons.Packages}
        </StyledPlaceholderDiv>
      </>
    );
  };

  const ThumbnailImg = (item: IDashboardCampaignDetail): JSX.Element => {
    // If this is an image, show the entity name image
    // If this is a video or package, show the associated image if one exists
    // Otherwise, show the package placeholder for packages and the video placeholder for videos
    let image = getVideoHTML(
      item.ThumbnailImage,
      item.AssociatedImageID,
      item.AssociatedImageName,
      item.EntityID,
      item.EntityName,
      item.EntityHSize,
      item.EntityVSize
    );

    if (item.IsMedia && !item.IsVideo) {
      image = getImageHTML(
        item.ThumbnailImage,
        item.EntityID,
        item.EntityName,
        false,
        item.EntityHSize,
        item.EntityVSize
      );
    } else if (!item.IsMedia && item.AssociatedImageName !== "") {
      image = getImageHTML(
        item.ThumbnailImage,
        item.AssociatedImageID,
        item.AssociatedImageName,
        true,
        item.AssociatedImageHSize,
        item.AssociatedImageVSize
      );
    } else if (!item.IsMedia) {
      image = getPackageHTML(item.EntityID, item.EntityHSize, item.EntityVSize);
    }

    return React.cloneElement(image);
  };

  // Contains: Secondary grid for row expansion
  const TableDetailRow = (props: GridDetailRowProps): JSX.Element => {
    const dataItem = props.dataItem;

    return (
      <Box style={{ overflowX: "scroll" }}>
        <table style={{ tableLayout: "fixed", minWidth: "1650px" }}>
          <colgroup>
            <col width="400px" />
            <col width="400px" />
            <col width="120px" />
            <col width="120px" />
            <col width="120px" />
            <col width="140px" />
            <col width="140px" />
            <col width="40px" />
          </colgroup>
          <thead>
            <tr>
              <th>Thumbnail</th>
              <th>File</th>
              <th>Type</th>
              <th>Nodes</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Status</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {dataItem.Details.map((item: IDashboardCampaignDetail) => (
              <tr key={item.EntityID} className="k-master-row" role="row">
                <td role="gridcell">{ThumbnailImg(item)}</td>
                <td role="gridcell">{item.EntityName}</td>
                <td role="gridcell">
                  {getDetailTypeDisplay(item.IsVideo, item.IsMedia)}
                </td>
                <td role="gridcell">
                  {getDetailNodesDisplay(
                    item.NodeCount,
                    item.NodeList,
                    item.EntityName
                  )}
                </td>
                <td role="gridcell">
                  {getDetailDateDisplay(item.StartDateList)}
                </td>
                <td role="gridcell">
                  {getDetailDateDisplay(item.EndDateList)}
                </td>
                <td role="gridcell">
                  {getDetailStatusDisplay(
                    item.NodeCount,
                    item.StartDateList,
                    item.EndDateList
                  )}
                </td>
                <td className="campaign-action-bar">
                  {/* Only show link to global changes if user is allowed to go there */}
                  {isGranted(UserPermissions.CanMakeGlobalChanges) && (
                    <GridRowActionBar
                      gridId={""}
                      rowId={1}
                      rowData={{}}
                      position={0}
                      showCopyBtn={false}
                      showDeleteBtn={false}
                      actions={[
                        {
                          show: true,
                          label: "Go to Global Change",
                          includeInExtrasMenu: true,
                          icon: CarbonIcons.GlobalChange,
                          onClick: (event: GridActionBarEvent) => {
                            console.log(
                              `Go to global change for entity with id ${item.EntityID}`,
                              event
                            );
                            gotoGlobalChange(
                              item.EntityID,
                              item.EntityName,
                              item.IsMedia,
                              item.IsMediaOnPackageOnly
                            );
                          }
                        }
                      ]}
                      gridInsertMode={false}
                      gridEditMode={false}
                      isRowSelected={true}
                    ></GridRowActionBar>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    );
  };

  const Table = (
    <div style={{ position: "relative" }}>
      {isLoading && <OctopusLoader />}
      <StyledGrid
        data={gridBaseData.length === 0 ? null : gridData}
        scrollable="none"
        detail={TableDetailRow}
        expandField="expanded"
        onExpandChange={handleExpandChange}
      >
        <Column field="CampaignName" title="Campaign" />
        <Column field="EntityCount" title="Total Files" width="140px" />
        <Column field="CampaignDesc" title="Description" />
        <Column field="UniqueNodeCount" title="Nodes" width="100px" />
        <Column field="CampaignTypeName" title="Type" />
        <Column field="DayPart" title="Day Part" width="200px" />
      </StyledGrid>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>Carbon | Campaigns Dashboard</title>
      </Helmet>
      <Authorizer
        canView={UserPermissions.CanViewMarketingManagementDashboard}
        auditLabel="Campaigns Dashboard screen"
      >
        <StyledDivWrapper>
          {TitleRow}
          {TableFilters}
          {TableExtras}
          {store.activeCompany?.companyId === -1 && (
            <WarningLabel>
              <Box direction="row" gap="xsmall">
                {CarbonIcons.Warning}
                Please make a selection from the Company dropdown in order to
                view campaigns on the dashboard.
                {CarbonIcons.Warning}
              </Box>
            </WarningLabel>
          )}
          {Table}
          {viewMediaNodes && (
            <StringArrayGridDialog
              bodyArray={mediaNodesList}
              onAcceptCallback={handleViewMediaNodesClose}
              title={`${selectedMedia}`}
            />
          )}
        </StyledDivWrapper>
      </Authorizer>
    </>
  );
};

const StyledFilterBox = styled(Box)`
  display: flex;

  @media (max-width: 1150px) {
    flex-direction: column;
    justify-content: start;
  }

  @media (min-width: 1151px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const StyledSortToolbar = styled(Toolbar)`
  margin-top: -8px;

  .k-dropdown {
    margin-left: -10px;
  }

  .k-separator {
    margin-top: -8px;
  }

  button.k-button.carbon-toolbar-item-btn {
    margin-top: -8px;
  }
`;

const StyledFindNodeInput = styled.div`
  position: relative;
  margin-top: 2px;

  span[class^="material-icons"] {
    position: absolute;
    top: 0px;
    right: 12px;
    height: 13px;
    width: 13px;
    cursor: pointer;

    &:hover {
      color: var(--carbon-blue);
    }
  }

  input {
    width: 150px;
    padding-right: 24px;
  }
`;

const StyledDivWrapper = styled.div`
  h1 {
    span[class^="material-icons"] {
      display: inline-flex;
      vertical-align: middle;
      height: 30px;
      width: 30px;
      font-size: xx-large;
    }
  }

  span.saving-indicator {
    color: var(--carbon-orange);
    font-weight: bold;
  }

  span.status {
    text-align: center;
    display: inline-flex;
    vertical-align: middle;
    border-radius: 10px;
    padding: 1px 8px;
    font-size: 14px;
    color: var(--carbon-white);
    line-height: 18px;
    font-weight: 600;
  }

  span.detail-icon {
    span[class^="material-icons"] {
      opacity: 0.5;
    }
  }

  span.record-count {
    font-size: 11px;
  }
`;

const WarningLabel = styled(Box)`
  align-items: center;
  background-color: var(--carbon-yellow);
  margin: 5px 0 10px 0;
  padding: 3px 0px;
`;

const StyledFieldWrapper = styled(FieldWrapper)`
  margin-top: 2px;

  label {
    font-size: 16px;
    display: inline-block;
    color: var(--carbon-mediumgray);
    font-weight: normal !important;
  }

  input.k-textbox {
    display: inline-block;
    font-size: 16px;
    background-color: var(--carbon-white);
    margin-left: 4px;

    &:focus {
      border-bottom-color: var(--carbon-orange);
    }
  }
`;

const StyledThumbnailDiv = styled.div`
  text-align: left;
  width: auto;
  height: 80px;
  display: inline-flex;
  vertical-align: middle;

  img {
    position: relative;
    max-height: 200px;
    max-width: 390px;
    height: 80px;
    margin-top: auto;
    margin-bottom: auto;
    float: left;
    cursor: pointer;
    border: 2px solid transparent;

    &:hover {
      border: 2px solid var(--carbon-blue);
    }
  }
`;

const StyledPlaceholderDiv = styled.div`
  text-align: center;
  height: 80px;
  width: 124px;
  cursor: pointer;
  background-color: var(--carbon-lightgray);
  border: 2px solid transparent;
  zoom: 1;

  &:hover {
    border: 2px solid var(--carbon-blue);
  }

  span {
    display: inline-flex;
    margin-top: 20px;
    font-size: 32px;
    filter: alpha(opacity=50);
    opacity: 0.5;
  }

  &:hover span {
    color: var(--carbon-blue);
    filter: alpha(opacity=1);
    opacity: 1;
  }
`;

const StyledGrid = styled(KendoGrid)`
  box-shadow: 2px 2px 8px var(--carbon-griddropshadow);
  border-radius: 6px;

  /* Grid row expansion icon */
  .k-i-add:before,
  .k-i-plus:before,
  .k-plus:before {
    content: "\\E014";
  }

  /* Grid row shrink icon */
  .k-i-kpi-trend-equal:before,
  .k-i-minus:before,
  .k-minus:before {
    content: "\\E015";
  }

  /* Turn off default hover state on header row*/
  table tr:hover,
  table tr.k-state-hover,
  table td.k-state-focused,
  table th.k-state-focused,
  table th:focus,
  table .k-master-row > td:focus,
  table .k-grouping-row > td:focus,
  table .k-detail-row > td:focus,
  table .k-group-footer > td:focus {
    background-color: transparent;
  }

  table {
    table-layout: fixed;

    colgroup {
      col.k-sorted {
        background-color: var(--carbon-white);
      }
    }

    thead {
      tr {
        height: 26px !important;

        &.k-alt {
          background-color: var(--carbon-verylightgray);
        }

        th {
          color: var(--carbon-darkgray);
          font-size: 14px;
          font-weight: 600;
          padding: 2px 24px;

          &.k-header {
            &:hover {
              color: var(--carbon-white);
              background-color: var(--carbon-blue);

              .k-link {
                color: var(--carbon-white);
              }
            }
          }

          &.k-sorted {
            background-color: var(--carbon-white);

            .k-i-sort-asc-sm,
            .k-i-sort-desc-sm,
            .k-sort-order {
              color: var(--carbon-blue);
            }

            &:hover .k-sort-order {
              color: var(--carbon-white);
            }
          }

          &:first-child {
            border-left: 5px solid transparent;
          }
        }
      }
    }

    tbody {
      tr.k-detail-row td.k-detail-cell {
        padding: 10px;

        > div {
          background-color: var(--carbon-white);
        }
      }

      tr {
        height: 51px;
        cursor: pointer;
        position: relative;

        &:active,
        &:hover {
          background-color: var(--carbon-verylightgray);

          > td {
            &:first-child {
              border-left: 5px solid var(--carbon-mediumgray);
            }
          }
        }

        &.k-alt {
          background-color: var(--carbon-verylightgray);
        }

        &.k-grid-edit-row,
        &.k-state-selected,
        &.k-detail-row {
          background-color: var(--carbon-lightblue);

          > td {
            &:first-child {
              border-left: 5px solid var(--carbon-blue);
            }
          }

          table th {
            border-bottom-color: rgba(0, 0, 0, 0.12);
          }
        }

        &.k-grid-norecords td {
          text-align: left;
        }

        td {
          color: var(--carbon-black);
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 16px;
          font-weight: 400;
          padding: 8px 24px;

          &:first-child {
            border-left: 5px solid transparent;
          }
        }

        td.campaign-action-bar div {
          background-color: transparent;
          text-align: center;
        }
      }
    }
  }
`;

export default CampaignsDashboard;
