import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router";
import { Box } from "grommet";

// Components
import CarbonGridToolbar, {
  GridToolbarActionLabels
} from "../../components/CarbonGrid/Toolbar/CarbonGridToolbar";
import CarbonGrid from "../../components/CarbonGrid/CarbonGrid";
import Authorizer from "../../components/Util/Authorizer";

// Types & Constants
import { GridType } from "../../types/grid";
import { gridColumns } from "./UserDefinedTypesGridColumns";

// User/Permissions
import usePermissions from "../../hooks/auth/usePermissions";
import {
  EndpointResources,
  Placeholders,
  UserPermissions,
  GridIDs,
  CarbonIcons
} from "../../constants";

// Action bar
import {
  GridActionBarEvent,
  GridActionBarItem
} from "../../components/CarbonGrid/Toolbar/CarbonGridRowActionBar";

// Contexts
import { useStore } from "../../contexts/store";

const UserDefinedTypes = (): JSX.Element => {
  const { canInsert, canDelete } = usePermissions();
  const navigate = useNavigate();
  const gridName = GridIDs.UserDefinedTypes;
  const pagePermission = UserPermissions.UserDefinedTypesPerms;
  const { store } = useStore();

  const actionLabels: GridToolbarActionLabels = {
    singular: "user defined type",
    plural: "user defined types",
    titleSingular: "User Defined Type",
    titlePlural: "User Defined Types"
  };

  const actions: GridActionBarItem[] = [
    {
      show: store.user?.companyID === 0,
      label: "Companies (Shared Media)",
      includeInExtrasMenu: false,
      icon: CarbonIcons.Companies,
      onClick: (event: GridActionBarEvent) => {
        console.log(`User Defined Type Companies onclick: `, event);
        navigate(
          `/app/${EndpointResources.UserDefinedTypes}/${event.rowData.TypeID}/${EndpointResources.UserDefinedTypeCompanies}`
        );
      }
    }
  ];

  const gridSettings: GridType = {
    actions,
    endpoints: {
      gridODataEndpoint: `/odata/${Placeholders.companyID}/griduserdefinedtypes?$count=true&`,
      gridApiEndpoint: `/api/${Placeholders.companyID}/userdefinedtypes`
    },
    records: [],
    total: 0,
    data: [],
    lookups: {},
    dataState: {
      pageSize: 25,
      take: 50,
      skip: 0,
      sort: [
        { field: "CompanyName", dir: "asc" },
        { field: "Screen", dir: "asc" },
        { field: "TypeName", dir: "asc" }
      ]
    },
    dataItemKey: "TypeID",
    singularEntityName: actionLabels.singular,
    pluralEntityName: actionLabels.plural,
    columns: gridColumns,
    showCopyBtn: canInsert(pagePermission),
    showDeleteBtn: canDelete(pagePermission),
    state: {
      editMode: false,
      insertMode: false,
      selectedState: {},
      selectedRow: -1,
      showQuickFilter: false,
      lastSaveDate: null,
      showSavingIndicator: false,
      lockoutMode: false
    }
  };

  return (
    <>
      <Helmet>
        <title>Carbon | User Defined Types</title>
      </Helmet>
      <Authorizer
        canView={pagePermission}
        auditLabel={`${actionLabels.titlePlural} screen`}
      >
        <Box align="start" fill>
          <CarbonGridToolbar
            gridId={gridName}
            screenIcon={CarbonIcons.UserDefinedTypes}
            screenTitle={actionLabels.titlePlural}
            screenSubtitle={""}
            permissions={pagePermission}
            actionLabels={actionLabels}
          />
          <Box fill className="carbon-grid-wrapper">
            <CarbonGrid
              gridId={gridName}
              gridSettings={gridSettings}
              gridClassName={"grid-with-actionbar"}
            />
          </Box>
        </Box>
      </Authorizer>
    </>
  );
};

export default UserDefinedTypes;
