import { EndpointResources, UserPermissions } from "../../constants";
import usePermissions from "../../hooks/auth/usePermissions";

// jon, 1/27/22: Only show tabs if user has permission to see them so they don't get ugly Access Denied error.
const getTabs = (parent: string, id: string) => {
  const { canView } = usePermissions();

  // Users that can view the node can also view Schedule tab in the old system.
  const tabs = [
    {
      resourceEndpoint: `/app/${parent}/${id}/${EndpointResources.NodeSchedules}`,
      tabTitle: "Schedule"
    }
  ];

  if (canView(UserPermissions.CanManageNodePricing)) {
    tabs.push({
      resourceEndpoint: `/app/${parent}/${id}/${EndpointResources.NodeItems}`,
      tabTitle: "Text & Prices"
    });
  }

  if (canView(UserPermissions.CanManageNodeScheduleHistory)) {
    tabs.push({
      resourceEndpoint: `/app/${parent}/${id}/${EndpointResources.NodeHistory}`,
      tabTitle: "History"
    });
  }

  if (canView(UserPermissions.CanManageNodeDayParts)) {
    tabs.push({
      resourceEndpoint: `/app/${parent}/${id}/${EndpointResources.NodeDayparts}`,
      tabTitle: "Dayparts"
    });
  }

  // Users that can view the node can also view Patch History in the old system.
  tabs.push({
    resourceEndpoint: `/app/${parent}/${id}/${EndpointResources.NodePatchHistory}`,
    tabTitle: "Patch History"
  });

  if (canView(UserPermissions.CanManageNodeMovieCodes)) {
    tabs.push({
      resourceEndpoint: `/app/${parent}/${id}/${EndpointResources.NodeMovieCodes}`,
      tabTitle: "Movie Codes"
    });
  }

  if (canView(UserPermissions.CanManageNodeDisplays)) {
    tabs.push({
      resourceEndpoint: `/app/${parent}/${id}/${EndpointResources.NodeDisplays}`,
      tabTitle: "Displays"
    });
  }

  // Video Cards use node display permission because these were on the same tab in the old system.
  if (canView(UserPermissions.CanManageNodeDisplays)) {
    tabs.push({
      resourceEndpoint: `/app/${parent}/${id}/${EndpointResources.NodeVideoCards}`,
      tabTitle: "Video Cards"
    });
  }

  return tabs;
};

export default getTabs;
