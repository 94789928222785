import React from "react";
import styled from "styled-components";
import CarbonGridToolbar, {
  GridToolbarProps
} from "../../CarbonGrid/Toolbar/CarbonGridToolbar";

type ConditionalPageHeaderProps =
  | ({
      gridId?: string;
    } & GridToolbarProps)
  | {
      gridId?: never;
      screenIcon: JSX.Element;
      screenTitle: string;
      screenSubtitle?: string;
    };

const DashboardPageHeader = (props: ConditionalPageHeaderProps) => {
  return props.gridId ? (
    <CarbonGridToolbar {...props} />
  ) : (
    <PageHeaderWrapper>
      <StyledHeaderH1>
        {props.screenIcon} {props.screenTitle}
      </StyledHeaderH1>
      {props.screenSubtitle && (
        <StyledHeaderH2>{props.screenSubtitle}</StyledHeaderH2>
      )}
    </PageHeaderWrapper>
  );
};

const PageHeaderWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 5px;
  margin-bottom: 25px;
`;

const StyledHeaderH1 = styled.h1`
  span[class^="material-icons"] {
    display: inline-flex;
    vertical-align: middle;
    height: 30px;
    width: 30px;
  }
`;

const StyledHeaderH2 = styled.h2`
  font-weight: normal;
  font-size: 15px;
  margin-left: 12px;
`;

export default DashboardPageHeader;
