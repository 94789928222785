import {
  DropDownList,
  DropDownListChangeEvent
} from "@progress/kendo-react-dropdowns";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import { GridStatusItemType } from "../../../types/grid";

interface DropdownFilterCellProps extends GridFilterCellProps {
  data: string[];
  defaultOverride?: string;
}

export const DropdownFilterCell = (props: DropdownFilterCellProps) => {
  const defaultSelect = props.defaultOverride ?? "(All)";
  let hasValue: any = (value: any) => Boolean(value && value !== defaultSelect);

  const onChange = (event: DropDownListChangeEvent) => {
    hasValue = hasValue(event.target.value);
    props.onChange({
      value: hasValue ? event.target.value : "",
      operator: hasValue ? "eq" : "",
      syntheticEvent: event.syntheticEvent
    });
  };

  const onClearButtonClick = (event: any) => {
    event.preventDefault();
    props.onChange({
      value: "",
      operator: "",
      syntheticEvent: event
    });
  };
  return (
    <div className="k-filtercell">
      <DropDownList
        data={[defaultSelect, ...props.data]}
        onChange={onChange}
        value={props.value || defaultSelect}
        defaultItem={defaultSelect}
      />
      {hasValue(props.value) && (
        <button
          className="k-button k-button-icon k-clear-button-visible"
          title="Clear"
          onClick={onClearButtonClick}
        >
          <span className="k-icon k-i-filter-clear" />
        </button>
      )}
    </div>
  );
};

interface DropdownStatusFilterCellProps extends GridFilterCellProps {
  data: GridStatusItemType[];
  defaultOverride?: string;
}

export const DropdownStatusFilterCell = (
  props: DropdownStatusFilterCellProps
) => {
  const defaultStatus: GridStatusItemType = {
    value: "",
    color: "",
    text: props.defaultOverride ?? "(All)"
  };
  let hasValue: any = (value: any) => Boolean(value && value !== defaultStatus);

  const onChange = (event: DropDownListChangeEvent) => {
    hasValue = hasValue(event.target.value);
    props.onChange({
      value: hasValue ? event.value.value : "",
      operator: hasValue ? "eq" : "",
      syntheticEvent: event.syntheticEvent
    });
  };

  const onClearButtonClick = (event: any) => {
    event.preventDefault();
    props.onChange({
      value: "",
      operator: "",
      syntheticEvent: event
    });
  };

  return (
    <div className="k-filtercell">
      <DropDownList
        data={[defaultStatus, ...props.data]}
        onChange={onChange}
        value={
          props.data.find((item) => item.value === props.value) || defaultStatus
        }
        dataItemKey={"value"}
        textField={"text"}
      />
      {hasValue(props.value) && (
        <button
          className="k-button k-button-icon k-clear-button-visible"
          title="Clear"
          onClick={onClearButtonClick}
        >
          <span className="k-icon k-i-filter-clear" />
        </button>
      )}
    </div>
  );
};
