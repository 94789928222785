import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  {
    field: "NodeID",
    title: "ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "MovieCode",
    title: "Movie Code",
    width: "130px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "NewMovieCode",
    title: "New Movie Code",
    width: "130px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "MovieTitle",
    title: "Movie Title",
    width: "130px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "NewMovieTitle",
    title: "New Movie Title",
    width: "130px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  }
];
