import { Helmet } from "react-helmet-async";
import { Box, Card, Grid, ResponsiveContext } from "grommet";
import styled from "styled-components";

import { useStore } from "../contexts/store";
import { useContext } from "react";
import { useNavigate } from "react-router";
import MaintenanceMessage from "../components/Util/MaintenanceMessage";
import { AppLocalStore, CarbonIcons, UserPermissions } from "../constants";
import usePermissions from "../hooks/auth/usePermissions";
import Authorizer from "../components/Util/Authorizer";

const Home = () => {
  const { store } = useStore();
  const { canView } = usePermissions();
  const size = useContext(ResponsiveContext);
  const navigate = useNavigate();

  const navigationUri = localStorage.getItem(AppLocalStore.NavigationURI);
  if (navigationUri) {
    navigate(navigationUri, { replace: true });
    localStorage.removeItem(AppLocalStore.NavigationURI);
  }

  return (
    <>
      <Helmet>
        <title>Carbon | Home</title>
      </Helmet>
      <Authorizer>
        <MaintenanceMessage
          isLogin={false}
          message={
            store.startupDetails && store.startupDetails.maintenanceMessage
          }
        />
        <Box align="center">
          <Box
            margin="medium"
            style={{ fontSize: 36, color: "var(--carbon-orange)" }}
          >
            Welcome, {store?.user?.username}
          </Box>
          <Box
            margin="small"
            style={{
              fontSize: 18,
              color: "var(--carbon-black)",
              fontWeight: 600
            }}
          >
            Quicklinks
          </Box>
        </Box>
        <Box
          alignSelf="center"
          width="50%"
          margin={{ horizontal: "large" }}
          pad="large"
        >
          <Grid
            align="center"
            justify="center"
            columns={{
              count: size !== "large" ? "fit" : 3,
              size:
                size !== "small"
                  ? size === "medium"
                    ? "45%"
                    : "small"
                  : "100%"
            }}
            gap="small"
          >
            {canView(UserPermissions.NodesPerms) && (
              <StyledCard
                hoverIndicator={true}
                focusIndicator={false}
                onClick={() => {
                  navigate("/app/nodes");
                }}
                elevation="medium"
                align="center"
                pad="medium"
                width="medium"
              >
                <Box>{CarbonIcons.Nodes}</Box>
                <Box margin="small" style={{ textAlign: "center" }}>
                  All Nodes
                </Box>
              </StyledCard>
            )}
            {canView(UserPermissions.MediaPerms) && (
              <StyledCard
                hoverIndicator={true}
                focusIndicator={false}
                onClick={() => {
                  navigate("/app/media");
                }}
                elevation="medium"
                align="center"
                pad="medium"
                width="medium"
              >
                <Box>{CarbonIcons.Media}</Box>
                <Box margin="small" style={{ textAlign: "center" }}>
                  Media
                </Box>
              </StyledCard>
            )}
            {canView(UserPermissions.ItemsPerms) && (
              <StyledCard
                hoverIndicator={true}
                focusIndicator={false}
                onClick={() => {
                  navigate("/app/items");
                }}
                elevation="medium"
                align="center"
                pad="medium"
                width="medium"
              >
                <Box>{CarbonIcons.Items}</Box>
                <Box margin="small" style={{ textAlign: "center" }}>
                  Text & Prices
                </Box>
              </StyledCard>
            )}
            {canView(UserPermissions.TemplatesPerms) && (
              <StyledCard
                hoverIndicator={true}
                focusIndicator={false}
                onClick={() => {
                  navigate("/app/templates");
                }}
                elevation="medium"
                align="center"
                pad="medium"
                width="medium"
              >
                <Box>{CarbonIcons.Templates}</Box>
                <Box margin="small" style={{ textAlign: "center" }}>
                  Templates
                </Box>
              </StyledCard>
            )}
            {canView(UserPermissions.CanMakeGlobalChanges) && (
              <StyledCard
                hoverIndicator={true}
                focusIndicator={false}
                onClick={() => {
                  navigate("/app/global-changes");
                }}
                elevation="medium"
                align="center"
                pad="medium"
                width="medium"
              >
                <Box>{CarbonIcons.GlobalChange}</Box>
                <Box margin="small" style={{ textAlign: "center" }}>
                  Global Change
                </Box>
              </StyledCard>
            )}

            {/* <StyledCard
              hoverIndicator={true}
              focusIndicator={false}
              onClick={() => {}}
              elevation="medium"
              align="center"
              pad="medium"
              width="medium"
            >
              <Box>{CarbonIcons.Settings}</Box>
              <Box margin="small">My Settings</Box>
            </StyledCard> */}
            {canView(UserPermissions.CampaignsPerms) && (
              <StyledCard
                hoverIndicator={true}
                focusIndicator={false}
                onClick={() => {
                  navigate("/app/campaigns-dashboard");
                }}
                elevation="medium"
                align="center"
                pad="medium"
                width="medium"
              >
                <Box>{CarbonIcons.Campaigns}</Box>
                <Box margin="small" style={{ textAlign: "center" }}>
                  Campaigns
                </Box>
              </StyledCard>
            )}
          </Grid>
        </Box>
      </Authorizer>
    </>
  );
};

const StyledCard = styled(Card)`
  span[class^="material-icons"] {
    font-size: xx-large;
  }
`;

export default Home;
