import { ReactFragment, useEffect, useState } from "react";
import {
  Notification,
  NotificationGroup
} from "@progress/kendo-react-notification";
import { useStore } from "../../../contexts/store";
import { NotificationType } from "../../../types";
import { StoreActions } from "../../../constants";

type FaderProps = {
  onTransitionEnd: Function;
  children: ReactFragment;
  closable: boolean;
};

const Fader = ({
  onTransitionEnd,
  closable = false,
  children
}: FaderProps): JSX.Element => {
  const [displayed, setDisplayed] = useState<boolean>(true);

  useEffect(() => {
    if (!closable) {
      setTimeout(() => {
        setDisplayed(false);
      }, 3000);
    }
  }, [closable]);

  return (
    <div
      className={displayed ? "fade-in" : closable ? "" : "fade-out"}
      onTransitionEnd={() => onTransitionEnd()}
    >
      {children}
    </div>
  );
};

const Notifications = (): JSX.Element => {
  const { store, dispatch } = useStore();

  return (
    <NotificationGroup
      style={{
        right: 0,
        bottom: 0,
        alignItems: "flex-start",
        flexWrap: "wrap-reverse"
      }}
    >
      {store.notifications &&
        store.notifications.map((notification: NotificationType) => {
          const { id, message, messageType, closable = false } = notification;
          return (
            <Fader
              key={`fader-${id}`}
              closable={closable}
              onTransitionEnd={
                closable
                  ? () => null
                  : () =>
                      dispatch({
                        type: StoreActions.removeNotification,
                        payload: { id }
                      })
              }
            >
              <Notification
                type={{ style: messageType, icon: true }}
                closable={closable}
                onClose={() =>
                  dispatch({
                    type: StoreActions.removeNotification,
                    payload: { id }
                  })
                }
              >
                <span style={{ whiteSpace: "pre-line" }}>{message}</span>
              </Notification>
            </Fader>
          );
        })}
    </NotificationGroup>
  );
};

export default Notifications;
