import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  {
    field: "PatchCompanyID",
    title: "Patch Company ID",
    width: "180px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: -1
  },
  {
    field: "PatchID",
    title: "Patch ID",
    width: "100px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "CompanyID",
    title: "Company ID",
    width: "130px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    company: true,
    systemHidden: true
  },
  {
    field: "CompanyName",
    title: "Company",
    titlePlural: "Companies",
    width: "375px",
    maxLength: 50,
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: true,
    canBeSaved: false,
    valueField: "CompanyID"
  },
  {
    field: "AvailableDateTime",
    title: "Node Install Date/Time",
    width: "375px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "datetime",
    required: true,
    canBeSaved: true
  }
];
