export type Video = {
  title: string;
  description: string;
  videoLink: string;
  tags: string[];
};

export type PopularVideo = {
  title: string;
  videoLink: string;
};

export const HelpVideos: Video[] = [
  {
    title: "Global image Changes",
    description:
      "How to globally change an image to save time and update promos faster",
    videoLink: "https://www.youtube.com/embed/QU325bwRPkc",
    tags: ["media", "global changes"]
  },
  {
    title: "Price Schedules",
    description:
      "Price schedules make it easy to update prices over a large number of nodes without having to update each node individually",
    videoLink: "https://www.youtube.com/embed/Wz7NqUC12Bw",
    tags: ["schedules", "items & prices"]
  },
  {
    title: "Schedule Screen Overview",
    description:
      "Schedules can be a great way to edit what your displays to promote diffrent content or prices at diffrent times or days",
    videoLink: "https://www.youtube.com/embed/Df6K1NHfefg",
    tags: ["schedules"]
  },
  {
    title: "Start & End Dates",
    description:
      "Start and end dates are a great way to schedule content for the future or end content that is no longer needed",
    videoLink: "https://www.youtube.com/embed/TtXZOaOm_6c",
    tags: ["schedules"]
  },
  {
    title: "Copying Templates",
    description:
      "Copying templates is a great way to save time if you want to use a template somewhere else without having to recreate the whole thing",
    videoLink: "https://www.youtube.com/embed/BZWgYqWbsFg",
    tags: ["templates"]
  },
  {
    title: "Uploading Media",
    description:
      "Uploading your own media content to carbon is an easy process",
    videoLink: "https://www.youtube.com/embed/FeeeYx2Qi2Q",
    tags: ["media"]
  },
  {
    title: "Packages",
    description:
      "Packages are a very powerful tool to group and layout content that can easily be used one or many times",
    videoLink: "https://www.youtube.com/embed/UYfzqZAE0A4",
    tags: ["packages"]
  },
  {
    title: "Changing Your Password",
    description: "Carbon makes it easy to change your password",
    videoLink: "https://youtube.com/embed/K9kX6FW4clg",
    tags: ["account"]
  },
  // {
  //   title: "Changing Price Mobile",
  //   description: "Changing prices in Carbon Mobile Admin is a breeze",
  //   videoLink: "https://youtube.com/embed/HoiEliNB7q0",
  //   tags: ["items & prices", "mobile"]
  // },
  // {
  //   title: "Shared Changes with Carbon",
  //   description:
  //     "The mobile and standard Desktop web experince are syncrediesd so any changes made to one will change the other",
  //   videoLink: "https://youtube.com/embed/feIsUZS9C48",
  //   tags: ["mobile"]
  // },
  // {
  //   title: "Downloading the Mobile App",
  //   description:
  //     "The mobile app can be found in both the apple app store and google play store",
  //   videoLink: "https://youtube.com/embed/8jhAb-7BRKI",
  //   tags: ["mobile"]
  // },
  // {
  //   title: "Navigating Nodes Mobile",
  //   description:
  //     "We can easily access the nodes to check their status and view their content ",
  //   videoLink: "https://youtube.com/embed/IF2UrKxJ0c0",
  //   tags: ["mobile"]
  // },
  {
    title: "Weekly Schedules",
    description:
      "Using the day by day scheduling tool we are able to change the onscreen content based on the day of week",
    videoLink: "https://youtube.com/embed/y5PD5oxvhfI",
    tags: ["schedules"]
  },
  {
    title: "Creating Stencils",
    description:
      "Stencils are like the building blocks of how infomration will be displayed",
    videoLink: "https://youtube.com/embed/f5PhsU2lX34",
    tags: ["stencils"]
  },
  {
    title: "Advanced Stencils",
    description:
      "We can utilize media and text in a stencil to create a more advanced and interesting stencil",
    videoLink: "https://youtube.com/embed/YS4zZmDfmxU",
    tags: ["stencils", "advanced"]
  },
  {
    title: "Making Packages",
    description:
      "Packages are a complete set of media and/or  text that is grouped together and can be moved around the template",
    videoLink: "https://youtube.com/embed/D6Mtnu0QNPQ",
    tags: ["packages"]
  },
  {
    title: "Text Styles",
    description:
      "Use text styles to save time and  create a consistent look across your text elements",
    videoLink: "https://youtube.com/embed/mebfmG17YAw",
    tags: ["text styles"]
  },
  // {
  //   title: "Advanced Text Styles",
  //   description:
  //     "When setting up text styles we can use adavaced formating such a dropshawods and letter spacing.  ",
  //   videoLink: "https://youtube.com/embed/0hGD-ZdB2pU",
  //   tags: ["text styles", "advanced"]
  // },
  {
    title: "Special Events",
    description:
      "In this video tutorial we will talk about scheduling an event to run a promotion on a specific day",
    videoLink: "https://youtube.com/embed/UgnNxz6TM2s",
    tags: ["schedules", "special events"]
  },
  {
    title: "Creating Dynamic Items",
    description:
      "Dynamic items allow you to create an item that can be used throughout a company",
    videoLink: "https://youtube.com/embed/457D8MOfKvQ",
    tags: ["items & prices", "dynamic"]
  },
  {
    title: "Day Parts",
    description: "Day parts are a way of changing the menu based on the time",
    videoLink: "https://youtube.com/embed/wTXKWJGzHs8",
    tags: ["day parts", "schedules"]
  },
  {
    title: "Restoring Schedules",
    description:
      "Sometimes while editing a node or schedule you may make a mistake. You can restore your node to a previously processed state",
    videoLink: "https://youtube.com/embed/ZrNXOtAnRnI",
    tags: ["day parts", "schedules"]
  },
  {
    title: "Making Dynamic Edits",
    description:
      "Dynamic edits allow you to dynamically change items at certain locations without having to change each item entry manually",
    videoLink: "https://youtube.com/embed/h5glEGKpWZY",
    tags: ["items & prices", "dynamic"]
  },
  {
    title: "Preview a Node",
    description:
      "You can preview content in carbon to get an idea of what everything will look when processed by the players",
    videoLink: "https://youtube.com/embed/i-aFsZXQzoQ",
    tags: ["preview"]
  },
  {
    title: "Logging On",
    description:
      "Carbon is our website that allows you to make changes to your digital signage content with ease",
    videoLink: "https://youtube.com/embed/qx1mZGslfJs",
    tags: ["account"]
  }
];

export const HelpVideosPopular: PopularVideo[] = [
  {
    title: "Preview a Node",
    videoLink: "https://youtube.com/embed/i-aFsZXQzoQ"
  },
  {
    title: "Restoring Schedules",
    videoLink: "https://youtube.com/embed/ZrNXOtAnRnI"
  },
  {
    title: "Making Packages",
    videoLink: "https://youtube.com/embed/D6Mtnu0QNPQ"
  },
  {
    title: "Global image Changes",
    videoLink: "https://www.youtube.com/embed/QU325bwRPkc"
  },
  {
    title: "Schedule Screen Overview",
    videoLink: "https://www.youtube.com/embed/Df6K1NHfefg"
  },
  {
    title: "Start & End Dates",
    videoLink: "https://www.youtube.com/embed/TtXZOaOm_6c"
  }
];
