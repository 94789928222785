import { Helmet } from "react-helmet-async";
import { Box } from "grommet";

// Components
import { GridToolbarActionLabels } from "../../components/CarbonGrid/Toolbar/CarbonGridToolbar";
import CarbonGrid from "../../components/CarbonGrid/CarbonGrid";
import Authorizer from "../../components/Util/Authorizer";

// Types & Constants
import { GridType } from "../../types/grid";
import { gridColumns } from "./GlobalChangesZonesGridColumns";

// User/Permissions
import usePermissions from "../../hooks/auth/usePermissions";
import { Placeholders, UserPermissions, GridIDs } from "../../constants";
import GlobalChangesToolbar, {
  GlobalChangeAutoSearchParameters,
  GlobalChangeType
} from "./GlobalChangesToolbar";

// Hooks
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

const GlobalChanges = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const { isGranted } = usePermissions();
  const gridName = GridIDs.GlobalChanges;
  const pagePermission = UserPermissions.CanMakeGlobalChanges;
  const [autoSearchParams] = useState<GlobalChangeAutoSearchParameters>({
    type: searchParams.get("type") as GlobalChangeType,
    id: parseInt(searchParams.get("id") ?? "-1", 10),
    name: searchParams.get("name")
  });
  const [autoSearchComplete, setAutoSearchComplete] = useState<boolean>(
    searchParams.get("id") === null
  );

  useEffect(() => {
    if (autoSearchComplete) {
      console.log("Auto search is complete");
      setAutoSearchComplete(true);
    }
  }, [autoSearchComplete]);

  const actionLabels: GridToolbarActionLabels = {
    singular: "schedule zone",
    plural: "schedule zones",
    titleSingular: "Global Change",
    titlePlural: "Global Change"
  };

  // Build the OData endpoint string with placeholders and save it so we can change it as needed to filter the grid by change type, selected criteria, etc.
  //  Default endpoint has no filters.
  const gridODataEndpoint: string = `/odata/${Placeholders.companyID}/gridglobalchangeszones?filterId=${Placeholders.filterID}&changeType=${Placeholders.globalChangeType}&selectedId=${Placeholders.globalChangeSelectedId}&selectedSubId=${Placeholders.globalChangeSelectedSubId}&selectedContentType=${Placeholders.globalChangeSelectedContentType}&$count=true&`;
  const setDefaultGridParams = (endpoint: string): string => {
    let newEndpoint = endpoint;
    newEndpoint = newEndpoint.replaceAll(Placeholders.globalChangeType, "");
    newEndpoint = newEndpoint.replaceAll(
      Placeholders.globalChangeSelectedId,
      "-1"
    );
    newEndpoint = newEndpoint.replaceAll(
      Placeholders.globalChangeSelectedSubId,
      "-1"
    );
    newEndpoint = newEndpoint.replaceAll(
      Placeholders.globalChangeSelectedContentType,
      ""
    );
    return newEndpoint;
  };

  const gridSettings: GridType = {
    endpoints: {
      gridODataEndpoint: setDefaultGridParams(gridODataEndpoint),
      gridApiEndpoint: `/api/${Placeholders.companyID}/globalchangeszones`,
      lookupEndpoints: [
        {
          lookupField: "MediaName",
          endpoint: `/api/${Placeholders.companyID}/lookupmedia/all`
        },
        {
          lookupField: "PackageName",
          endpoint: `/api/${Placeholders.companyID}/lookuppackages`
        }
      ]
    },
    records: [],
    total: 0,
    data: [],
    lookups: {},
    dataState: {
      pageSize: 25,
      take: 50,
      skip: 0,
      sort: [
        /* ODATA requests support up to 5 sorts only */
        { field: "CompanyName", dir: "asc" },
        {
          field: "NodeName",
          dir: "asc"
        },
        {
          field: "TemplateName",
          dir: "asc"
        },
        {
          field: "ZoneName",
          dir: "asc"
        },
        {
          field: "ContentSeqNum",
          dir: "asc"
        }
      ]
    },
    dataItemKey: "ScheduleZoneID",
    singularEntityName: actionLabels.singular,
    pluralEntityName: actionLabels.plural,
    columns: gridColumns,
    showCopyBtn: false,
    showDeleteBtn: isGranted(pagePermission),
    state: {
      editMode: false,
      insertMode: false,
      selectedState: {},
      selectedRow: -1,
      showQuickFilter: false,
      lastSaveDate: null,
      showSavingIndicator: false,
      lockoutMode: false,
      addingNewFilter: false,
      activeFilterId: -1
    }
  };

  return (
    <>
      <Helmet>
        <title>Carbon | Global Change</title>
      </Helmet>

      <Authorizer
        canView={pagePermission}
        auditLabel={`${actionLabels.titlePlural} screen`}
      >
        <Box align="start" fill>
          <GlobalChangesToolbar
            gridId={gridName}
            gridODataEndpoint={gridODataEndpoint}
            autoSearchParams={autoSearchParams}
            autoSearchComplete={autoSearchComplete}
            setAutoSearchComplete={setAutoSearchComplete}
          />
          <Box fill className="carbon-grid-wrapper">
            <CarbonGrid gridId={gridName} gridSettings={gridSettings} />
          </Box>
        </Box>
      </Authorizer>
    </>
  );
};

export default GlobalChanges;
