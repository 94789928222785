import apiClient from "..";
import { authEndpoints } from "../../constants/endpoints";
import { AxiosResponse } from "axios";
import { AuditTrailRecordContract } from "../../types";

const PostAuditTrailRecord = (
  recordContract: AuditTrailRecordContract
): Promise<AxiosResponse> => {
  const recordData: Object = {
    AuditTrailType: recordContract.auditTrailType,
    AccessName: recordContract.accessName
  };

  const response = apiClient.post(
    authEndpoints.auditTrailEndpoint,
    JSON.stringify(recordData)
  );
  return response;
};

export default PostAuditTrailRecord;
