import { Helmet } from "react-helmet-async";
import { useLocation, useParams } from "react-router-dom";
import { Box } from "grommet";

// Components
import CarbonGridToolbar, {
  GridToolbarActionLabels,
  GridToolbarItem
} from "../../components/CarbonGrid/Toolbar/CarbonGridToolbar";
import CarbonTabStrip from "../../components/CarbonTabs/CarbonTabStrip";
import CarbonGrid from "../../components/CarbonGrid/CarbonGrid";
import Authorizer from "../../components/Util/Authorizer";

// Types & Constants
import { GridType } from "../../types/grid";
import getTabs from "./NodesTabs";
import { gridColumns } from "./NodeVideoCardsGridColumns";

// User/Permissions
import usePermissions from "../../hooks/auth/usePermissions";
import {
  EndpointResources,
  Placeholders,
  UserPermissions,
  GridIDs,
  CarbonIcons
} from "../../constants";
import { useGrid } from "../../contexts/grid/useGrid";
import { useStore } from "../../contexts/store";
import CarbonBreadcrumb from "../../components/Breadcrumb/CarbonBreadcrumb";

const NodeVideoCards = (): JSX.Element => {
  const { pathname } = useLocation();
  const { store } = useStore();
  const { nodeId } = useParams();
  const { canUpdate } = usePermissions();
  const gridName = GridIDs.NodeVideoCards;
  const tabs = getTabs(EndpointResources.Nodes, nodeId!);
  const pagePermission = UserPermissions.CanManageNodeDisplays;
  const { grids } = useGrid();
  const nodesGrid = grids.get(GridIDs.Nodes);
  let nodesTitle = "Nodes";

  if (nodesGrid) {
    if (store.activeNodeGroup?.nodeGroupName) {
      nodesTitle = store.activeNodeGroup?.nodeGroupName;
    }
  }

  const newGridColumns = [
    {
      field: "NodeID",
      title: "NodeID",
      width: "150px",
      defaultShow: false,
      filter: "numeric",
      editable: false,
      required: false,
      canBeSaved: true,
      defaultValue: nodeId,
      systemHidden: true
    },
    ...gridColumns
  ];

  const currentTab = {
    index: tabs.findIndex((tab) => tab.resourceEndpoint === pathname),
    tab: tabs.find((tab) => tab.resourceEndpoint === pathname)
  };

  const actionLabels: GridToolbarActionLabels = {
    singular: "node video card",
    plural: "node video cards",
    titleSingular: "Node Video Card",
    titlePlural: "Node Video Cards"
  };

  const gridSettings: GridType = {
    endpoints: {
      gridODataEndpoint: `/odata/${Placeholders.companyID}/${Placeholders.nodeTabNodeId}/gridnodevideocards?$count=true&`,
      gridApiEndpoint: `/api/${Placeholders.nodeTabNodeId}/nodevideocards/`,
      parentGridApiEndpoint: `/api/${Placeholders.companyID}/${EndpointResources.Nodes}/${Placeholders.nodeTabNodeId}`
    },
    records: [],
    total: 0,
    data: [],
    lookups: {},
    dataState: {
      pageSize: 25,
      take: 50,
      skip: 0,
      sort: [{ field: "VideoCardOrder", dir: "asc" }]
    },
    dataItemKey: "NodeVideoCardID",
    singularEntityName: actionLabels.singular,
    pluralEntityName: actionLabels.plural,
    columns: newGridColumns,
    showCopyBtn: false,
    showDeleteBtn: canUpdate(pagePermission),
    parentGridId: GridIDs.Nodes,
    state: {
      editMode: false,
      insertMode: false,
      selectedState: {},
      selectedRow: -1,
      showQuickFilter: false,
      lastSaveDate: null,
      showSavingIndicator: false,
      lockoutMode: false
    }
  };

  const previewBtn: GridToolbarItem = {
    show: true,
    enable: true,
    tooltip: "Open the viewer to preview node schedule",
    toggledOn: false
  };

  return (
    <>
      <Helmet>
        <title>Carbon | Node Video Cards</title>
      </Helmet>
      <Authorizer
        canView={pagePermission}
        auditLabel={`${actionLabels.titlePlural} screen`}
      >
        <Box align="start" fill>
          <CarbonBreadcrumb
            backLinkTitle={nodesTitle}
            backLinkUrl="/app/nodes"
            pageTitle={currentTab.tab?.tabTitle ?? ""}
          />
          <CarbonGridToolbar
            gridId={gridName}
            screenIcon={CarbonIcons.VideoCards}
            screenTitle={""}
            screenSubtitle={""}
            permissions={pagePermission}
            actionLabels={actionLabels}
            getScreenTitleFromParentRow={(rowData: { [key: string]: any }) => {
              return rowData.NodeName;
            }}
            getScreenSubtitleFromParentRow={(rowData: {
              [key: string]: any;
            }) => {
              return rowData.CompanyName;
            }}
            publishBtn={true}
            previewBtn={previewBtn}
          />
          <CarbonTabStrip carbonTabs={tabs} selectedTab={currentTab.index} />
          <Box fill className="carbon-grid-wrapper">
            <CarbonGrid gridId={gridName} gridSettings={gridSettings} />
          </Box>
        </Box>
      </Authorizer>
    </>
  );
};

export default NodeVideoCards;
