import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { FC, useState } from "react";
import { Box } from "grommet";
import { CarbonIcons } from "../../../constants";
import { NumberField } from "../../Fields/NumberField";

interface INodeSchedulesAddZonesDialogProps {
  onAcceptCallback: (numZones: number) => void;
  onRejectCallback: () => void;
}

const NodeSchedulesAddZonesDialog: FC<INodeSchedulesAddZonesDialogProps> = ({
  onAcceptCallback,
  onRejectCallback
}) => {
  const [numZonesToAdd, setNumZonesToAdd] = useState<number>(1);

  const CustomTitleBar = () => {
    return (
      <h1>
        {CarbonIcons.AddZoneMultiple}
        <br />
        {"Add Content to Zone"}
      </h1>
    );
  };

  return (
    <Dialog title={<CustomTitleBar />} closeIcon={false}>
      <Box>
        <p>{"Enter the number of records to add to this zone."}</p>
        <div style={{ padding: "0 10px" }}>
          <NumberField
            key="numZonesToAdd"
            id="numZonesToAdd"
            label="Zones"
            labelWidth="60px"
            required={true}
            defaultValue={numZonesToAdd}
            minValue={1}
            maxValue={999}
            placeholder="Number of zones"
            onChange={(value: number | null) => {
              setNumZonesToAdd(value === null ? 1 : value);
            }}
          />
        </div>
      </Box>
      <DialogActionsBar>
        <Button onClick={onRejectCallback}>
          {CarbonIcons.Close}
          {"Cancel"}
        </Button>
        <Button
          autoFocus={true}
          primary={true}
          onClick={() => onAcceptCallback(numZonesToAdd)}
        >
          {CarbonIcons.Check}
          {"Add Zones"}
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
};

export default NodeSchedulesAddZonesDialog;
