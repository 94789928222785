import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  {
    field: "UserCompanyID",
    title: "User Company ID",
    width: "150px",
    defaultShow: false,
    show: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: -1
  },
  {
    field: "UserID",
    title: "UserID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    systemHidden: true
  },
  {
    field: "CompanyID",
    title: "CompanyID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    company: true,
    systemHidden: true
  },
  {
    field: "CompanyName",
    title: "Company",
    titlePlural: "Companies",
    width: "500px",
    maxLength: 50,
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: true,
    canBeSaved: false,
    valueField: "CompanyID"
  }
];
