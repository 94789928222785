import { GridColumns } from "../../types/grid";

export const onSundayStartTimeChange = (
  value: any,
  previousValue: any,
  rowData: { [key: string]: any }
): { changedData?: { [key: string]: any }; changedColumns?: GridColumns[] } => {
  if (value === previousValue) return {};

  // Reset all associated static content fields when content category changes
  const changedData = {
    MondayStartTime: rowData.MondayStartTime ? rowData.MondayStartTime : value,
    TuesdayStartTime: rowData.TuesdayStartTime
      ? rowData.TuesdayStartTime
      : value,
    WednesdayStartTime: rowData.WednesdayStartTime
      ? rowData.WednesdayStartTime
      : value,
    ThursdayStartTime: rowData.ThursdayStartTime
      ? rowData.ThursdayStartTime
      : value,
    FridayStartTime: rowData.FridayStartTime ? rowData.FridayStartTime : value,
    SaturdayStartTime: rowData.SaturdayStartTime
      ? rowData.SaturdayStartTime
      : value
  };

  return { changedData };
};
