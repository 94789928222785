/* eslint-disable */

import {
  FieldSettings,
  Filter,
  FilterChangeEvent
} from "@progress/kendo-react-data-tools";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Box } from "grommet";
import { FC, useEffect, useState } from "react";
import {
  CompositeFilterDescriptor,
  filterBy
} from "@progress/kendo-data-query";
import { useGrid } from "../../../contexts/grid/useGrid";
import {
  Api,
  CarbonIcons,
  GridActions,
  GridIDs,
  StoreActions,
  UserPermissions
} from "../../../constants";
import {
  DropDownList,
  DropDownListChangeEvent,
  MultiSelect,
  MultiSelectChangeEvent,
  MultiSelectFilterChangeEvent
} from "@progress/kendo-react-dropdowns";
import { useImmer } from "use-immer";
import { GridColumns } from "../../../types/grid";
import {
  Input,
  InputChangeEvent,
  RadioButtonChangeEvent,
  RadioGroup,
  RadioGroupChangeEvent
} from "@progress/kendo-react-inputs";
import { gridColumns as nodesGridColumns } from "../../../pages/Nodes/NodesGridColumns";
import { gridColumns as nodeItemsGridColumns } from "../../../pages/Nodes/NodeItemsJoinFilterColumns";
import { gridColumns as nodeDayPartsGridColumns } from "../../../pages/Nodes/NodeDayPartsGridColumns";
import { gridColumns as nodeItemsHistoryGridColumns } from "../../../pages/Nodes/NodeItemsHistoryGridColumns";
import { gridColumns as nodeZonesGridColumns } from "../../../pages/Nodes/NodeZonesGridColumns";
import { gridColumns as nodesTemplatesGridColumns } from "../../../pages/Nodes/NodeTemplatesGridColumns";
import { gridColumns as templateZonesGridColumns } from "../../../pages/Templates/TemplateZonesGridColumns";
import { gridColumns as nodesScheduleHistoryGridColumns } from "../../../pages/Nodes/NodeScheduleHistoryGridColumns";
import { gridColumns as nodeDisplaysGridColumns } from "../../../pages/Nodes/NodeDisplaysGridColumns";
import { gridColumns as nodeVideoCardsGridColumns } from "../../../pages/Nodes/NodeVideoCardsGridColumns";
import { gridColumns as nodeZonesHistoryGridColumns } from "../../../pages/Nodes/NodeZonesHistoryGridColumns";
import { gridColumns as nodesPatchHistoryGridColumns } from "../../../pages/Nodes/NodePatchHistoryGridColumns";
import { gridColumns as nodesMovieCodesGridColumns } from "../../../pages/Nodes/NodeMovieCodesJoinFilterColumns";
import { IFilterItem } from "../../../pages/Nodes/NodeFilterDropDown";
import { useStore } from "../../../contexts/store";
import apiClient from "../../../api";
import { Loader } from "@progress/kendo-react-indicators";
import { INodeGroupItem } from "../../CarbonMenu";
import ConfirmationDialog from "./ConfirmationDialog";
import { getGridFieldSettings } from "../../../helpers/Util";
import usePermissions from "../../../hooks/auth/usePermissions";
import useApi from "../../../hooks/api/useApi";

interface INodesFilterDialog {
  // setShowDialog: (value: boolean) => void;
}

// database object (array of objects) we receive from fetching user saved filters
interface INodeGroupModelDB {
  NodeGroupID: number;
  GroupName: string;
  CompanyID: number;
  Filter: string;
}

// ui representation for each filter item (each table we filter by)
interface ICarbonGridFilter {
  gridId: GridIDs;
  title: string;
  filter?: CompositeFilterDescriptor;
  filterFieldSettings: FieldSettings[];
  showFilterArea: boolean;
}

// interface for filter objects saved in the database
interface IFilterDataTransfer {
  gridId: GridIDs;
  filter: CompositeFilterDescriptor;
}

// overall user filter.
interface INodeGroup {
  nodeGroupItem: INodeGroupItem;
  filterData: ICarbonGridFilter[];
}

const NodeGroupDialog: FC<INodesFilterDialog> = () => {
  const { grids, setGrid } = useGrid();
  const { store, dispatch } = useStore();
  const { canDelete } = usePermissions();
  const nodesGrid = grids.get(GridIDs.Nodes);
  const addingNewNodeGroup = store.addingNodeGroup;
  const [savingNodeGroupError, setSavingNodeGroupError] = useState("");
  const [loadingNodeGroupData, setLoadingNodeGroupData] = useState(false);

  const initialFilters: ICarbonGridFilter[] = [
    {
      gridId: GridIDs.Nodes,
      title: "Nodes",
      filter: undefined,
      filterFieldSettings: getGridFieldSettings(nodesGridColumns),
      showFilterArea: false
    },
    {
      gridId: GridIDs.NodeZones,
      title: "Node Zones",
      filter: undefined,
      filterFieldSettings: getGridFieldSettings(nodeZonesGridColumns),
      showFilterArea: false
    },
    {
      gridId: GridIDs.NodeTemplates,
      title: "Templates",
      filter: undefined,
      filterFieldSettings: getGridFieldSettings(nodesTemplatesGridColumns),
      showFilterArea: false
    },
    {
      gridId: GridIDs.NodeItems,
      title: "Items",
      filter: undefined,
      filterFieldSettings: getGridFieldSettings(nodeItemsGridColumns),
      showFilterArea: false
    },
    {
      gridId: GridIDs.NodeDayParts,
      title: "Day Parts",
      filter: undefined,
      filterFieldSettings: getGridFieldSettings(nodeDayPartsGridColumns),
      showFilterArea: false
    },
    {
      gridId: GridIDs.NodeDisplays,
      title: "Displays",
      filter: undefined,
      filterFieldSettings: getGridFieldSettings(nodeDisplaysGridColumns),
      showFilterArea: false
    },
    {
      gridId: GridIDs.NodeVideoCards,
      title: "Video Cards",
      filter: undefined,
      filterFieldSettings: getGridFieldSettings(nodeVideoCardsGridColumns),
      showFilterArea: false
    },
    {
      gridId: GridIDs.NodePatchHistory,
      title: "Patch History",
      filter: undefined,
      filterFieldSettings: getGridFieldSettings(nodesPatchHistoryGridColumns),
      showFilterArea: false
    },
    {
      gridId: GridIDs.NodeMovieCodes,
      title: "Movie Codes",
      filter: undefined,
      filterFieldSettings: getGridFieldSettings(nodesMovieCodesGridColumns),
      showFilterArea: false
    },
    {
      gridId: GridIDs.NodeItemHistory,
      title: "Item History",
      filter: undefined,
      filterFieldSettings: getGridFieldSettings(nodesMovieCodesGridColumns),
      showFilterArea: false
    }
  ];

  const multiSelectItems = initialFilters.map((filterObj) => {
    return filterObj.title;
  });

  const [multiSelectData, setMultiSelectData] = useState(multiSelectItems);
  const [showDeleteNodeGroupDialog, setShowDeleteNodeGroupDialog] =
    useState<boolean>(false);

  const [filters, setFilters] = useImmer<ICarbonGridFilter[]>(initialFilters);
  // default active user filter as if user is adding a new filter
  const [nodeGroup, setActiveNodeGroup] = useImmer<INodeGroup>({
    nodeGroupItem: { nodeGroupId: -1, nodeGroupName: "" },
    filterData: filters
  });

  const defaultEmptyFilter: CompositeFilterDescriptor = {
    logic: "and",
    filters: []
  };

  const [selectedMultiSelectValues, setSelectedMultiSelectValues] = useState<
    string[]
  >([]);

  useEffect(() => {
    // fetch node group data if this is not a 'add new node group' event
    if (addingNewNodeGroup === false) {
      setLoadingNodeGroupData(true);
      loadNodeGroup(store.activeNodeGroup?.nodeGroupId ?? -1);
    }

    return () => {
      setLoadingNodeGroupData(false);
    };
  }, []);

  const { request: getNodeGroupRequest } = useApi(
    async (nodeGroupId: number) =>
      await apiClient.get(`${Api.baseUrl}/api/-1/nodegroups/${nodeGroupId}`)
  );

  const loadNodeGroup = async (nodeGroupId: number) => {
    // jon, 3/11/22: Change all direct apiClient calls to use the useApi hook for proper refresh token handling.
    const getNodeGroupResponse = await getNodeGroupRequest(nodeGroupId);
    if (getNodeGroupResponse.type === "error") return;
    const response = getNodeGroupResponse.value;

    const responseData: INodeGroupModelDB = response.data;
    const dbFilterData: IFilterDataTransfer[] = JSON.parse(responseData.Filter);

    // loop through default filters and return a modified object based on database values
    const filterData: ICarbonGridFilter[] = filters.map((val) => {
      const filterData = dbFilterData.find(
        (dbFilter) => dbFilter.gridId === val.gridId
      );
      if (filterData) {
        const tempFilter = Object.assign({}, val);
        tempFilter.showFilterArea = true;
        tempFilter.filter = filterData.filter;
        return tempFilter;
      } else {
        return val;
      }
    });

    const nodeGroup: INodeGroup = {
      nodeGroupItem: {
        nodeGroupId: responseData.NodeGroupID,
        nodeGroupName: responseData.GroupName
      },
      filterData: filterData
    };
    setActiveNodeGroup(nodeGroup);
    // set the active multiselect values
    const multiSelectValues = nodeGroup.filterData.flatMap((val) => {
      if (val.showFilterArea) {
        return val.title;
      } else {
        return [];
      }
    });
    setSelectedMultiSelectValues(multiSelectValues);
    setLoadingNodeGroupData(false);
  };

  // this event is called every time a specific filter's conditions (expressions) change
  const onFilterChange = (gridId: GridIDs, event: FilterChangeEvent) => {
    // modify the active userfilter's filters
    setActiveNodeGroup((draft) => {
      const filterObj = draft.filterData.find((val) => val.gridId === gridId);
      // This has a bug when switching filter operators with no expressions listed
      // workaround is to check  filterObj.filter?.logic === event.filter.logic and prevent the filter from clearing if the user simply switches operators with no expressions set yet

      if (filterObj) {
        if (
          event.filter.filters.length === 0 &&
          event.filter.logic === filterObj.filter?.logic
        ) {
          // there is no onClose event for the kendo react filter, so if the filter length here is 0, it means the user attempted to remove the last filter object
          // so clean the filter from memory and deselect it's associated selected chip from the multiselect
          // NOTE make sure the filter logic didnt change, if it did, ignore this clean up
          filterObj.filter = undefined;
          filterObj.showFilterArea = false;
          const newSelectedValues = selectedMultiSelectValues.filter(
            (val) => val !== filterObj.title
          );
          setSelectedMultiSelectValues(newSelectedValues);
        } else {
          filterObj.filter = event.filter;
        }
      }
    });
  };

  const onChangeMultiSelect = (event: MultiSelectChangeEvent) => {
    const selectedMultiSelectValues = event.value as string[];
    setActiveNodeGroup((draft) => {
      draft.filterData.forEach((filterObj) => {
        if (selectedMultiSelectValues.includes(filterObj.title)) {
          filterObj.showFilterArea = true;
        } else {
          filterObj.showFilterArea = false;
        }
      });
    });
    setSelectedMultiSelectValues(event.value);
  };

  const handleDialogClose = () => {
    dispatch({
      type: StoreActions.onCloseNodeGroupDialog,
      payload: {
        undefined
      }
    });
  };

  const onMultiSelectFilterChange = (event: MultiSelectFilterChangeEvent) => {
    const filter = event.filter;
    const allData = [...multiSelectItems];
    const newData = filterBy(allData, filter);
    setMultiSelectData(newData);
  };

  const handleNodeGroupNameChange = (event: InputChangeEvent) => {
    setActiveNodeGroup((draft) => {
      draft.nodeGroupItem.nodeGroupName = event.value;
    });
  };

  const { request: saveNodeGroupRequest } = useApi(
    async (nodeGroupObj: any) =>
      await apiClient.post(
        `/api/${store.user?.companyID}/nodegroups`,
        nodeGroupObj
      )
  );

  const handleNodeGroupSave = async () => {
    // setLoadingSaveNodeGroup(true);
    const filterObj = nodeGroup.filterData
      .filter((val) => val.filter !== undefined)
      .map((filteredVal) => {
        return {
          gridId: filteredVal.gridId,
          filter: filteredVal.filter
        };
      });

    const userCompanyId =
      store.activeCompany?.companyId === -1
        ? store.user?.companyID
        : store.activeCompany?.companyId;

    const nodeGroupObj = {
      NodeGroupID: nodeGroup.nodeGroupItem.nodeGroupId,
      GroupName: nodeGroup.nodeGroupItem.nodeGroupName,
      CompanyID: userCompanyId,
      Filter: filterObj
    };

    try {
      // jon, 3/11/22: Change all direct apiClient calls to use the useApi hook for proper refresh token handling.
      const copySaveResponse = await saveNodeGroupRequest(nodeGroupObj);
      if (copySaveResponse.type === "error") return;
      const response = copySaveResponse.value;

      if (response.status === 200) {
        const responseObj: INodeGroupModelDB = response.data;
        const nodeGroupItem: INodeGroupItem = {
          nodeGroupId: responseObj.NodeGroupID,
          nodeGroupName: responseObj.GroupName
        };
        handleDialogClose();
        dispatch({
          type: StoreActions.onSaveNodeGroup,
          payload: {
            nodeGroupItem: nodeGroupItem
          }
        });
        dispatch({
          type: StoreActions.addNotification,
          payload: {
            message: `Saved '${nodeGroup.nodeGroupItem.nodeGroupName}' successfully.`,
            messageType: "success",
            closable: false
          }
        });
      }
    } catch (error: any) {
      setSavingNodeGroupError(error.response.data);
    } finally {
      setLoadingNodeGroupData(false);
    }
  };

  const { request: deleteNodeGroupRequest } = useApi(
    async (nodeGroupId: number) =>
      await apiClient.delete(`${Api.baseUrl}/api/-1/nodegroups/${nodeGroupId}`)
  );

  const handleDeleteNodeGroup = async () => {
    try {
      // jon, 3/11/22: Change all direct apiClient calls to use the useApi hook for proper refresh token handling.
      const deleteNodeGroupResponse = await deleteNodeGroupRequest(
        nodeGroup.nodeGroupItem.nodeGroupId
      );
      if (deleteNodeGroupResponse.type === "error") return;
      const response = deleteNodeGroupResponse.value;

      if (response.status === 200) {
        setShowDeleteNodeGroupDialog(false);
        // reset to empty all nodes
        const emptyNodeGroup: INodeGroupItem = {
          nodeGroupId: -1,
          nodeGroupName: ""
        };
        dispatch({
          type: StoreActions.onDeleteNodeGroup,
          payload: {
            nodeGroupItem: emptyNodeGroup
          }
        });
        dispatch({
          type: StoreActions.addNotification,
          payload: {
            message: `Deleted '${nodeGroup.nodeGroupItem.nodeGroupName}' successfully.`,
            messageType: "success",
            closable: false
          }
        });
      }
    } catch (error: any) {
      console.log(error.response.data);
    } finally {
      setShowDeleteNodeGroupDialog(false);
    }
  };

  return (
    <>
      {showDeleteNodeGroupDialog && (
        <ConfirmationDialog
          bodyText={`Are you sure you want to delete this node group ( ${nodeGroup.nodeGroupItem.nodeGroupName} ) ?`}
          onAcceptCallback={handleDeleteNodeGroup}
          onRejectCallback={() => {
            setShowDeleteNodeGroupDialog(false);
          }}
        />
      )}
      {!showDeleteNodeGroupDialog && (
        <>
          {loadingNodeGroupData ? (
            <Dialog
              width={"1000px"}
              height={"700px"}
              title={
                <Box fill align="center">
                  <span
                    style={{ color: "var(--carbon-orange)" }}
                    className="material-icons-outlined"
                  >
                    {CarbonIcons.Nodes}
                  </span>
                  <h1
                    style={{ paddingTop: "5px", color: "var(--carbon-orange)" }}
                  >
                    {"Node Group"}
                  </h1>
                </Box>
              }
              closeIcon={false}
            >
              <Box fill align={"center"} justify={"center"}>
                <Loader size="large" type="converging-spinner" />
              </Box>
            </Dialog>
          ) : (
            <Dialog
              width={"1000px"}
              height={"700px"}
              title={
                <Box fill align="center">
                  <span
                    style={{ color: "var(--carbon-orange)" }}
                    className="material-icons-outlined"
                  >
                    {CarbonIcons.Nodes}
                  </span>
                  <h1
                    style={{ paddingTop: "5px", color: "var(--carbon-orange)" }}
                  >
                    {"Node Group"}
                  </h1>
                  <Box
                    margin={{ bottom: "small" }}
                    fill
                    direction="row"
                    justify="between"
                    align="center"
                  >
                    <Input
                      value={nodeGroup.nodeGroupItem.nodeGroupName}
                      autoFocus={true}
                      onChange={handleNodeGroupNameChange}
                      label={"Group Name"}
                      style={{ width: "300px" }}
                    />
                    {nodeGroup.nodeGroupItem.nodeGroupId !== -1 &&
                      canDelete(UserPermissions.NodeGroupsPerms) && (
                        <Button
                          onClick={() => {
                            setShowDeleteNodeGroupDialog(true);
                          }}
                        >
                          {CarbonIcons.Delete}
                          {"Delete Node Group"}
                        </Button>
                      )}
                  </Box>
                  <MultiSelect
                    filterable={true}
                    disabled={
                      nodeGroup.nodeGroupItem.nodeGroupName.length === 0
                    }
                    label={"Filter By"}
                    style={{ width: "100%" }}
                    onChange={onChangeMultiSelect}
                    onFilterChange={onMultiSelectFilterChange}
                    data={multiSelectData}
                    value={selectedMultiSelectValues}
                  />
                </Box>
              }
              closeIcon={false}
            >
              {selectedMultiSelectValues.length === 0 ? (
                <Box fill justify="center" align="center">
                  <h1 style={{ color: "GrayText" }}>
                    No Filters Have Been Set
                  </h1>
                </Box>
              ) : (
                <>
                  {nodeGroup.filterData.map((filter, key) => {
                    if (filter.showFilterArea) {
                      return (
                        <Box key={key}>
                          <h1 style={{ marginTop: "5px" }}>{filter.title}</h1>
                          <Filter
                            key={key}
                            value={filter.filter || defaultEmptyFilter}
                            onChange={(event) =>
                              onFilterChange(filter.gridId, event)
                            }
                            fields={filter.filterFieldSettings}
                          />
                          <hr />
                        </Box>
                      );
                    }
                  })}
                </>
              )}

              <DialogActionsBar>
                <Box direction="row" style={{ marginBottom: "30px" }}>
                  <Button onClick={handleDialogClose}>
                    {CarbonIcons.Close}
                    {"Cancel"}
                  </Button>
                  <Button
                    primary={true}
                    onClick={() => {
                      handleNodeGroupSave();
                    }}
                    disabled={
                      selectedMultiSelectValues.length === 0 ||
                      nodeGroup.nodeGroupItem.nodeGroupName.length === 0
                    }
                  >
                    {CarbonIcons.Check}
                    {"Save & Apply"}
                  </Button>
                </Box>
              </DialogActionsBar>
            </Dialog>
          )}
        </>
      )}
    </>
  );
};

export default NodeGroupDialog;
