import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  {
    field: "NodeVideoCardID",
    title: "NodeVideoCardID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: -1
  },
  // {
  //   field: "NodeID",
  //   title: "NodeID",
  //   width: "150px",
  //   defaultShow: false,
  //   filter: "numeric",
  //   editable: false,
  //   required: false,
  //   canBeSaved: true,
  //   defaultValue: "[grdNodes.NodeID]"
  // },
  {
    field: "VideoCardOrder",
    title: "Order",
    width: "185px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: true,
    canBeSaved: true,
    minValue: 0
  },
  {
    field: "HResolution",
    title: "Hor. Resolution",
    width: "235px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: true,
    canBeSaved: true,
    minValue: 0
  },
  {
    field: "VResolution",
    title: "Vert. Resolution",
    width: "1405px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: true,
    canBeSaved: true,
    minValue: 0
  },
  {
    field: "ColorDepth",
    title: "Color Depth",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true
  }
];
