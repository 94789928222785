import apiClient from "..";
import { startupEndpoints } from "../../constants/endpoints";
import { AxiosResponse } from "axios";

const GetStartupDetails = (): Promise<AxiosResponse> => {
  const response = apiClient.get(startupEndpoints.startupDetailsEndpoint);
  return response;
};

export default GetStartupDetails;
