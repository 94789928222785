import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  {
    field: "NodePatchHistoryID",
    title: "Node Patch History ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: -1
  },
  // {
  //   field: "NodeID",
  //   title: "NodeID",
  //   width: "150px",
  //   defaultShow: false,
  //   filter: "numeric",
  //   editable: false,
  //   required: false,
  //   canBeSaved: true,
  //   defaultValue: "[grdNodes.NodeID]"
  // },
  {
    field: "PlayerTypeID",
    title: "Player Type",
    width: "160px",
    defaultShow: false,
    customFilter: "dropdown",
    editable: true,
    editor: "dropdown",
    required: false,
    canBeSaved: true,
    defaultValue: null,
    dropItems: [
      {
        value: null,
        text: "Choose Type"
      },
      {
        value: 1,
        text: "PC Windows"
      },
      {
        value: 2,
        text: "CL1"
      },
      {
        value: 3,
        text: "CL2"
      },
      {
        value: 4,
        text: "Tizen"
      },
      {
        value: 5,
        text: "WebOS"
      },
      {
        value: 6,
        text: "Android-SOC"
      }
    ]
  },
  {
    field: "PatchVersion",
    title: "Patch Version",
    width: "435px",
    defaultShow: true,
    filter: "text",
    editable: false,
    editor: "text",
    required: false,
    canBeSaved: true
  },
  {
    field: "PatchDesc",
    title: "Description",
    width: "820px",
    defaultShow: true,
    filter: "text",
    editable: false,
    editor: "text",
    required: false,
    canBeSaved: true
  },
  {
    field: "MicroPlayerID",
    title: "Micro Player ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "CarbonPlayerID",
    title: "Micro Player",
    titlePlural: "Micro Players",
    width: "185px",
    defaultShow: true,
    filter: "numeric",
    editable: false,
    editor: "text",
    required: false,
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "DateInstalled",
    title: "Installed Datetime",
    width: "410px",
    defaultShow: true,
    filter: "date",
    editable: false,
    editor: "datetime",
    required: false,
    canBeSaved: true
  }
];
