import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  {
    field: "MicroHealth",
    title: "Health",
    width: "150px",
    defaultShow: true,
    customFilter: "status",
    filterable: true,
    sortable: true,
    resizable: true,
    editable: false,
    editor: "status",
    required: false,
    canBeSaved: false,
    defaultValue: "reddot.gif",
    statuses: [
      {
        value: "greendot.gif",
        color: "var(--carbon-green)",
        text: "Online"
      },
      {
        value: "yellowdot.gif",
        color: "var(--carbon-black)",
        text: "Error"
      },
      {
        value: "reddot.gif",
        color: "var(--carbon-red)",
        text: "Offline"
      }
    ]
  },
  {
    field: "UpdateStatus",
    title: "Updates",
    width: "225px",
    defaultShow: true,
    customFilter: "status",
    filterable: true,
    sortable: true,
    resizable: true,
    editable: false,
    editor: "status",
    required: false,
    canBeSaved: false,
    defaultValue: "Unprocessed",
    statuses: [
      {
        value: "Unprocessed",
        color: "var(--carbon-black)",
        text: "Unprocessed"
      },
      {
        value: "Confirmed",
        color: "var(--carbon-green)",
        text: "Confirmed"
      },
      {
        value: "Processing",
        color: "var(--carbon-yellow)",
        text: "Processing"
      },
      {
        value: "Ready to Send",
        color: "var(--carbon-yellow)",
        text: "Sending"
      },
      {
        value: "Sent",
        color: "var(--carbon-yellowgreen)",
        text: "Received"
      },
      {
        value: "Delivered",
        color: "var(--carbon-yellowgreen)",
        text: "Downloading"
      },
      {
        value: "Error",
        color: "var(--carbon-red)",
        text: "Error"
      },
      {
        value: "Pending",
        color: "var(--carbon-blue)",
        text: "Scheduled"
      }
    ]
  },
  {
    field: "DisplayOrder",
    title: "Order",
    width: "185px",
    defaultShow: true,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: true,
    canBeSaved: true,
    minValue: 0
  },
  {
    field: "Orientation",
    title: "Orientation",
    width: "185px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "dropdown",
    required: false,
    canBeSaved: true,
    dropItems: [
      {
        value: "L",
        text: "Landscape"
      },
      {
        value: "P",
        text: "Portrait"
      }
    ]
  },
  {
    field: "MicroDesc",
    title: "Player Desc.",
    width: "280px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    canBeSaved: true
  },
  {
    field: "CarbonPlayerID",
    title: "Player ID",
    titlePlural: "Player IDs",
    width: "215px",
    defaultShow: true,
    filter: "text",
    editable: false,
    editor: "lookup",
    required: false,
    valueField: "MicroPlayerID",
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "MicroLastProcessed",
    title: "Last Processed",
    width: "310px",
    defaultShow: true,
    filter: "date",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "PatchVersion",
    title: "Patch Version",
    width: "270px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "NodeDisplayID",
    title: "NodeDisplayID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: -1
  },
  // {
  //   field: "NodeID",
  //   title: "NodeID",
  //   width: "150px",
  //   defaultShow: false,
  //   filter: "numeric",
  //   editable: false,
  //   required: false,
  //   canBeSaved: true,
  //   defaultValue: "[grdNodes.NodeID]"
  // },
  {
    field: "MicroHealthMessage",
    title: "MicroHealthMessage",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: true
  },
  {
    field: "MicroUpdatesMessage",
    title: "MicroUpdatesMessage",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "HResolution",
    title: "Hor. Resolution",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: false,
    canBeSaved: true,
    minValue: 0
  },
  {
    field: "VResolution",
    title: "Vert. Resolution",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: false,
    canBeSaved: true,
    minValue: 0
  },
  {
    field: "ColorDepth",
    title: "Color Depth",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: true,
    editor: "number",
    required: false,
    canBeSaved: true,
    minValue: 0
  },
  {
    field: "MicroPlayerID",
    title: "MicroPlayerID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "SerialNumber",
    title: "Player Serial #",
    width: "200px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "IsParent",
    title: "Parent",
    width: "150px",
    defaultShow: false,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true,
    defaultValue: false
  },
  {
    field: "PatchID",
    title: "PatchID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    systemHidden: true
  },
  {
    field: "MicroProperties",
    title: "Properties",
    width: "300px",
    defaultShow: false,
    filter: "text",
    editable: false,
    editor: "note",
    required: false,
    canBeSaved: false
  },
  {
    field: "NodeDisplayTypeID",
    title: "NodeDisplayTypeID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "NodeDisplayTypeName",
    title: "Display Type",
    titlePlural: "Display Types",
    width: "250px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    valueField: "NodeDisplayTypeID",
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "PlayerTypeID",
    title: "Player Type",
    width: "160px",
    defaultShow: false,
    customFilter: "dropdown",
    editable: true,
    editor: "dropdown",
    required: false,
    canBeSaved: true,
    defaultValue: null,
    dropItems: [
      {
        value: null,
        text: "Choose Type"
      },
      {
        value: 1,
        text: "PC Windows"
      },
      {
        value: 2,
        text: "CL1"
      },
      {
        value: 3,
        text: "CL2"
      },
      {
        value: 4,
        text: "Tizen"
      },
      {
        value: 5,
        text: "WebOS"
      },
      {
        value: 6,
        text: "Android-SOC"
      }
    ]
  }
];
