import React, { useEffect, useState } from "react";
import { CellRenderProps } from "./CellRender";
import { useGrid } from "../../../contexts/grid/useGrid";
import { GridActions } from "../../../constants";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";

export const SelectCheckboxRender = ({
  originalProps,
  gridId,
  td,
  colDefinition
}: CellRenderProps) => {
  const { grids, setGrid } = useGrid();
  let defaultValue =
    originalProps.dataItem[originalProps.field!] ??
    colDefinition?.defaultValue ??
    false;
  if (
    originalProps.dataItem[originalProps.field!] === null &&
    grids.get(gridId)?.state.selectAllChecked === true
  ) {
    defaultValue = true;
  }

  const [checked, setChecked] = useState<boolean>(defaultValue);

  // Effect runs when checkbox value changes in grid, from clicking the select all checkbox in the header, and when field value changed programatically (like on grid refresh).
  useEffect(() => {
    if (originalProps.dataItem[originalProps.field!] !== checked) {
      setChecked(
        originalProps.dataItem[originalProps.field!] === null
          ? defaultValue
          : originalProps.dataItem[originalProps.field!]
      );
    }
  }, [
    grids.get(gridId)?.state.selectAllChecked,
    originalProps.dataItem[originalProps.field!]
  ]);

  const onCheckChanged = (event: CheckboxChangeEvent) => {
    // console.log(`Select Checkbox Changed: `, event.value);
    const grid = grids.get(gridId)!;
    setChecked(event.value);

    // This sets the new value and text in the grid data
    const newDataItem = { ...originalProps.dataItem };
    newDataItem[originalProps.field!] = event.value;

    const editedRecordID = originalProps.dataItem[grid.dataItemKey!];
    const records = grid.records.map((record) => {
      let newRecord = record;
      if (record[grid.dataItemKey!] === editedRecordID) {
        newRecord = newDataItem;
      }
      return newRecord;
    });

    setGrid({
      type: GridActions.onItemChange,
      payload: { gridId, gridData: records }
    });

    // Build the selected pk list.
    setGrid({
      type: GridActions.buildSelectedPKList,
      payload: { gridId, gridData: null }
    });
  };

  const checkChildren: JSX.Element = (
    <div style={{ textAlign: "center", marginLeft: "-5px" }}>
      <Checkbox
        value={checked === null ? false : checked}
        onChange={onCheckChanged}
      />
    </div>
  );

  return React.cloneElement(td, td.props, checkChildren);
};
