import React, { ReactElement } from "react";
import { GridCellProps } from "@progress/kendo-react-grid";
import { PlaceholderCellRender } from "./PlaceholderCellRender";
import { CheckboxRender } from "./CheckboxRender";
import { TextboxRender } from "./TextboxRender";
import { GridColumns } from "../../../types/grid";
import { LookupRender } from "./LookupRender";
import { DropDownRender } from "./DropDownRender";
import { useGrid } from "../../../contexts/grid/useGrid";
import { ThumbnailRender } from "./ThumbnailRender";
import { DateTimeRender } from "./DateTimeRender";
import { IconRender } from "./IconRender";
import { StatusRender } from "./StatusRender";
import { OverrideTextboxRender } from "./OverrideTextboxRender";
import { NoteRender } from "./NoteRender";
import { SelectCheckboxRender } from "./SelectCheckboxRender";
import { ProgressBarRender } from "./ProgressBarRender";
import { NumberRender } from "./NumberRender";

export interface CellRenderProps {
  originalProps: GridCellProps;
  td: ReactElement<HTMLTableCellElement>;
  gridId: string;
  colDefinition?: GridColumns;
  exitEdit: (
    type: string | undefined,
    field: string,
    previousDataItem: { [p: string]: any },
    dataItem: { [p: string]: any },
    fields: string[]
  ) => Promise<string | null>;
}

export type UnMountSaveData = {
  previousDataItem: { [key: string]: any };
  dataItem: { [key: string]: any };
  isThisRowBeingEdited: boolean;
  saving: boolean;
};

export const CellRender = ({
  originalProps,
  td,
  gridId,
  colDefinition,
  exitEdit
}: CellRenderProps) => {
  const { grids } = useGrid();

  // Check for empty cell and show placeholder if empty (i.e., cell is waiting to load)
  if (
    originalProps.dataItem === undefined ||
    originalProps.dataItem[originalProps.field || ""] === undefined
  ) {
    // shows loading cell if no data
    return <PlaceholderCellRender />;
  }

  // Is this field read-only?
  // Are we in edit or insert mode?
  let editor: string = colDefinition?.editor ? colDefinition?.editor : "text";
  const isEditMode: boolean = grids.get(gridId)?.state.editMode!;
  const columns = grids.get(gridId)?.columns;
  let cellProps = originalProps;
  let colDef = colDefinition;

  // Combined col cell only shows in view-only mode. In edit mode, the actual underlying column is displayed based on the control field and value.
  //  An example of this is on the Media Library screen. When Assoc. Content Type is Media (controlValue="M"), the Media Lookup displays.
  //  When Assoc. Content Type is Package (controlValue="P"), the Package Lookup displays.
  if (editor === "combinedcolumn") {
    if (!isEditMode) {
      return (
        <TextboxRender
          originalProps={originalProps}
          td={td}
          gridId={gridId}
          exitEdit={exitEdit}
        />
      );
    } else {
      // In edit mode, we render the underlying col instead. Get all subcolumns that reference this one as their combinedCol. Then look up the
      //  controlField for these columns (it should be the same one) and get its value. Display the column where the controlValue matches the
      //  controlField's current value.
      const combinedSubCols = columns!.filter(
        (col) => col.combinedCol! === originalProps.field!
      );
      if (combinedSubCols.length < 1) {
        throw new Error(
          `Column ${originalProps.field!} is missing any subcolumns that reference it as their combinedCol!`
        );
      }

      const subCol = combinedSubCols.find(
        (col) => col.controlValue! === originalProps.dataItem[col.controlField!]
      );
      if (!subCol) {
        throw new Error(
          `Column ${originalProps.field!} is missing any subcolumns that have a controlValue matching the current value of the controlField.`
        );
      }

      // Build new props for this column so we reference the correct field. The correct field will be returned below using these new props.
      //  Also need to set the current text from the combined col text since this value is not set in the underlying lookup from the db query.
      const newDataItem = { ...originalProps.dataItem };
      newDataItem[subCol.field] = newDataItem[originalProps.field!];

      cellProps = {
        ...originalProps,
        ...{ field: subCol.field, dataItem: newDataItem }
      };
      colDef = subCol;
      editor = colDef.editor!;
    }
  }

  // Checkbox cell type
  if (editor === "boolean") {
    return (
      <CheckboxRender
        originalProps={cellProps}
        td={td}
        gridId={gridId}
        colDefinition={colDef}
        exitEdit={exitEdit}
      />
    );
  }

  // Select Checkbox cell type - used on Global Changes to select rows
  if (editor === "selectcheckbox") {
    return (
      <SelectCheckboxRender
        originalProps={cellProps}
        td={td}
        gridId={gridId}
        colDefinition={colDef}
        exitEdit={exitEdit}
      />
    );
  }

  // Textbox cell type
  if (editor === "text") {
    if (colDefinition?.overrideColumn) {
      return (
        <OverrideTextboxRender
          originalProps={cellProps}
          td={td}
          gridId={gridId}
          colDefinition={colDef}
          exitEdit={exitEdit}
        />
      );
    } else {
      return (
        <TextboxRender
          originalProps={cellProps}
          td={td}
          gridId={gridId}
          colDefinition={colDef}
          exitEdit={exitEdit}
        />
      );
    }
  }

  if (editor === "number") {
    return (
      <NumberRender
        originalProps={cellProps}
        td={td}
        gridId={gridId}
        colDefinition={colDef}
        exitEdit={exitEdit}
      />
    );
  }

  // Note cell type - basically a read-only textarea that shows in a dialog.
  if (editor === "note") {
    return (
      <NoteRender
        originalProps={cellProps}
        td={td}
        gridId={gridId}
        colDefinition={colDef}
        exitEdit={exitEdit}
      />
    );
  }

  // Lookup cell type
  if (editor === "lookup") {
    return (
      <LookupRender
        originalProps={cellProps}
        td={td}
        gridId={gridId}
        colDefinition={colDef}
        exitEdit={exitEdit}
      />
    );
  }

  // Dropdown cell type
  if (editor === "dropdown") {
    return (
      <DropDownRender
        originalProps={cellProps}
        td={td}
        gridId={gridId}
        colDefinition={colDef}
        exitEdit={exitEdit}
      />
    );
  }

  // Thumbnail cell type - use the contents as the base64-encoded image source
  if (editor === "thumbnail") {
    return (
      <ThumbnailRender
        originalProps={cellProps}
        td={td}
        gridId={gridId}
        colDefinition={colDef}
        exitEdit={exitEdit}
      />
    );
  }

  // Icon cell type - show an icon based on the column value - kind of combination between thumbnail and dropdown
  if (editor === "icon") {
    return (
      <IconRender
        originalProps={cellProps}
        td={td}
        gridId={gridId}
        colDefinition={colDef}
        exitEdit={exitEdit}
      />
    );
  }

  // Status cell type - show a colored status indicator based on the column value - kind of combination between text and dropdown
  if (editor === "status") {
    return (
      <StatusRender
        originalProps={cellProps}
        td={td}
        gridId={gridId}
        colDefinition={colDef}
        exitEdit={exitEdit}
      />
    );
  }

  // Datetime, Date, and Time cell types - actual type rendered is taken from the editor type in the column definition
  if (editor === "datetime" || editor === "date" || editor === "time") {
    return (
      <DateTimeRender
        originalProps={cellProps}
        td={td}
        gridId={gridId}
        colDefinition={colDef}
        exitEdit={exitEdit}
      />
    );
  }

  // Progress Bar cell type - show a progress indicator based off the column value
  if (editor === "progressBar") {
    return (
      <ProgressBarRender
        originalProps={cellProps}
        td={td}
        gridId={gridId}
        colDefinition={colDef}
        exitEdit={exitEdit}
      />
    );
  }

  return <td>{"Unknown Cell Type"}</td>;
};
