import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { FC } from "react";
import { Box } from "grommet";
import { CarbonIcons } from "../../../constants";

interface ITextareaDialogProps {
  title?: string;
  bodyText?: string;
  okText?: string;
  onAcceptCallback: () => void;
}

const TextareaDialog: FC<ITextareaDialogProps> = ({
  title,
  bodyText,
  okText,
  onAcceptCallback
}) => {
  const CustomTitleBar = () => {
    return <h1>{title || "Contents"}</h1>;
  };

  return (
    <Dialog title={<CustomTitleBar />} closeIcon={false}>
      <Box fill={true}>
        <textarea readOnly={true} cols={100} rows={40} value={bodyText} />
      </Box>
      <DialogActionsBar>
        <Button autoFocus={true} primary={true} onClick={onAcceptCallback}>
          {CarbonIcons.Check}
          {okText || "Close"}
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
};

export default TextareaDialog;
