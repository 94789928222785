// Types/Constants
import { Result, ResultError } from "../../types";
import { Placeholders } from "../../constants";

// Hooks
import useApi from "../../hooks/api/useApi";
import GetGridRecordApi from "../../api/grid/getRecordApi";
import { useStore } from "../../contexts/store";
import { useParams } from "react-router-dom";

const useGridRecordApiGet = () => {
  const { loading: requestIsLoading, request: getRecord } =
    useApi(GetGridRecordApi);
  const { store } = useStore();
  const {
    nodeId,
    userId,
    roleId,
    patchId,
    typeId,
    templateId,
    stencilId,
    mediaId,
    packageId,
    priceScheduleId
  } = useParams();

  const getGridRecord = async (
    getRecordEndpoint: string
  ): Promise<Result<any>> => {
    let endpoint = `${getRecordEndpoint.replace(
      Placeholders.companyID,
      `${store.activeCompany!.companyId}`
    )}`;
    endpoint = endpoint.replace(Placeholders.nodeTabNodeId, `${nodeId}`);
    endpoint = endpoint.replace(Placeholders.userTabUserId, `${userId}`);
    endpoint = endpoint.replace(Placeholders.roleTabRoleId, `${roleId}`);
    endpoint = endpoint.replace(Placeholders.patchTabPatchId, `${patchId}`);
    endpoint = endpoint.replace(
      Placeholders.userDefinedTypesTabUdtId,
      `${typeId}`
    );
    endpoint = endpoint.replace(
      Placeholders.templatesTabTemplateId,
      `${templateId}`
    );
    endpoint = endpoint.replace(
      Placeholders.stencilsTabStencilId,
      `${stencilId}`
    );
    endpoint = endpoint.replace(Placeholders.mediaTabMediaId, `${mediaId}`);
    endpoint = endpoint.replace(
      Placeholders.packagesTabPackageId,
      `${packageId}`
    );
    endpoint = endpoint.replace(
      Placeholders.priceSchedulesTabPriceScheduleId,
      `${priceScheduleId}`
    );

    let result: Result<any>;
    try {
      result = await getRecord(endpoint);
    } catch (error) {
      const result: ResultError = { type: "error", error: error };
      return result;
    }
    return result;
  };

  const isLoading = (): boolean => {
    return requestIsLoading;
  };

  return {
    getGridRecord,
    isLoading
  };
};

export default useGridRecordApiGet;
