import React from "react";
import { CellRenderProps } from "./CellRender";
import styled from "styled-components";
import { CarbonIcons, GridActions } from "../../../constants";
import { useGrid } from "../../../contexts/grid/useGrid";
import { ITextareaDialogParams } from "../../../types/grid";

export const NoteRender = ({
  originalProps,
  td,
  gridId,
  colDefinition
}: CellRenderProps) => {
  const { setGrid } = useGrid();
  let value = originalProps.dataItem[originalProps.field!];
  if (!value || value === null) value = "";

  const onShowTextareaDialog = () => {
    const params: ITextareaDialogParams = {
      title: colDefinition!.title,
      value: value
    };
    setGrid({
      type: GridActions.onSetTextareaDialogParams,
      payload: { gridId, gridData: params }
    });
  };

  return React.cloneElement(
    td,
    td.props,
    <StyledStatusDiv>
      {value !== "" && (
        <span
          onClick={onShowTextareaDialog}
          title={`View full ${colDefinition!.title}`}
        >
          {CarbonIcons.OpenFull}
        </span>
      )}
      {value}
    </StyledStatusDiv>
  );
};

const StyledStatusDiv = styled.div`
  position: relative;
  padding-left: 10px;

  span {
    margin-left: -15px;
    cursor: pointer;
    font-size: 18px;
    color: var(--carbon-blue);
    padding: 3px;
    text-align: center;
    display: inline-flex;
    vertical-align: middle;
    z-index: 10;

    &:hover {
      color: var(--carbon-black);
    }
  }
`;
